import { enUS, ja, zhCN, es } from 'date-fns/locale';

export interface InterfaceLanguage {
  label: string;
  unicode: string;
  locale: Locale;
}

export const INTERFACE_LANGUAGES: InterfaceLanguage[] = [
  { label: 'English', unicode: 'en', locale: enUS },
  { label: '中文 (简体)', unicode: 'zh', locale: zhCN },
  { label: '日本語', unicode: 'ja', locale: ja },
  { label: 'Español', unicode: 'es', locale: es },
];
