// Outer Ring Layer
import {
  CustomerPreferences,
  zoomBehavior,
} from 'services/customerPreferences';

import { MapOptions } from '../../MapOptions';

// This renders the outer ring of the Market Map
export function outerRingLayer(
  container: d3.Selection<SVGElement, any, null, undefined>,
  r: number,
  mapOptions: MapOptions,
  customerPreferences: CustomerPreferences,
) {
  const outerRing = container
    .append('circle')
    .attr('r', r * mapOptions.zoom)
    .attr('cx', -mapOptions.tx)
    .attr('cy', -mapOptions.ty)
    .style('fill-opacity', '0')
    .style('stroke', 'midnightblue')
    .style('stroke-width', (r / 240) * 2)
    .attr('pointer-events', 'none');

  if (zoomBehavior(customerPreferences) === 'panzoom') {
    outerRing
      .attr('r', r * mapOptions.zoom)
      .attr('cx', -mapOptions.tx)
      .attr('cy', -mapOptions.ty);
  } else {
    outerRing.attr('r', r);
  }
}
