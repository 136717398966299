import * as React from 'react';
import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import ReportMarketPreferencesQuery from '@graphql/queries/ReportMarketPreferencesQuery';
import { Grid } from '@mui/material';
import { ProductVersion } from 'components/Report/ProductVersion';

import ReportProductMarketPerference from '../ReportProductMarketPreferences/ReportProductMarketPerference';

interface ReportProps {
  title: string;
  reportId: string;
  productVersion: ProductVersion;
  sendMaxPercentPop?: (maxPop: number) => void;
  calculatedMaxPercentPop?: number;
}

const ReportMarketPreferenceComparison: React.FC<ReportProps> = (props) => {
  const {
    title,
    reportId,
    productVersion,
    sendMaxPercentPop,
    calculatedMaxPercentPop,
  } = props;

  const {
    loading,
    error,
    data: reportMarketPreferences,
  } = useQuery<reports.ReportMarketPreferencesResponse>(
    ReportMarketPreferencesQuery,
    {
      variables: {
        reportID: reportId,
        productID: productVersion.productId,
        version: productVersion.version,
      },
    },
  );

  function computeMaxPercentPop(productData: reports.ReportMarketPreference[]) {
    return productData
      .map((rmp) => parseFloat(rmp.percentPop))
      .reduce((a, b) => (b > a ? b : a), 0);
  }

  useEffect(() => {
    if (!loading && !error && productVersion.productId !== 0) {
      sendMaxPercentPop &&
        sendMaxPercentPop(
          computeMaxPercentPop(
            reportMarketPreferences.allRpMarketPreferences.nodes,
          ),
        );
    }
  }, [productVersion, loading, calculatedMaxPercentPop]);

  if (loading) {
    return <div />;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <ReportProductMarketPerference
          title={title}
          titleCenter={true}
          reportId={reportId}
          productId={productVersion.productId}
          productVersion={productVersion.version}
          calculatedMaxPercentPop={calculatedMaxPercentPop}
        />
      </Grid>
    </Grid>
  );
};

export default ReportMarketPreferenceComparison;
