import React, { useState } from 'react';

import { NotificationNode } from '@graphql/queries/UserNotificationsQuery';
import { InputLabel } from '@mui/material';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import 'react-mde/lib/styles/css/react-mde-all.css';

import styles from './CreateNotification.module.css';
import MaterialButton from '../../components/MaterialButton';

interface Props {
  closeModal: () => void;
  editNotification: (id: number, message: string) => Promise<void>;
  deleteNotification: (id: number) => Promise<void>;
  notification: NotificationNode;
  isAdmin: boolean;
}

const EditNotification: React.FC<Props> = ({
  closeModal,
  editNotification,
  deleteNotification,
  notification,
  isAdmin,
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>(
    isAdmin ? 'write' : 'preview',
  );

  const converter = new Showdown.Converter();

  const handleDelete = async () => await deleteNotification(notification.id);

  return (
    <div className={styles.sectionContainer}>
      <Formik
        initialValues={{
          message: notification.message,
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.message.length) {
            errors.message = 'Message cannot be empty.';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          const { message } = values;
          await editNotification(notification.id, message);
        }}
        render={({ values, setFieldValue, handleSubmit, errors }) => (
          <Form>
            <div className={styles.mainContainer}>
              <div className={styles.subContainer}>
                <div className={styles.fieldContainer}>
                  {isAdmin && (
                    <InputLabel>
                      {t('notification.editMessageInput')}
                    </InputLabel>
                  )}
                  <ReactMde
                    value={values.message}
                    onChange={(value) => {
                      if (isAdmin) setFieldValue('message', value);
                    }}
                    selectedTab={selectedTab}
                    onTabChange={(tab) => {
                      if (isAdmin) setSelectedTab(tab);
                    }}
                    generateMarkdownPreview={(markdown) =>
                      Promise.resolve(converter.makeHtml(markdown))
                    }
                    readOnly={!isAdmin}
                    childProps={{
                      writeButton: isAdmin
                        ? undefined
                        : { tabIndex: -1, style: { display: 'none' } },
                      previewButton: isAdmin
                        ? undefined
                        : { tabIndex: -1, style: { display: 'none' } },
                    }}
                  />
                  <span className={styles.error}>{errors.message}</span>
                </div>
              </div>
            </div>
            {isAdmin ? (
              <div className={styles.footer}>
                <MaterialButton
                  variant="outlined"
                  color="error"
                  soft
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you want to delete this message?',
                      )
                    ) {
                      handleDelete();
                    }
                  }}
                >
                  {'Delete'}
                </MaterialButton>
                <MaterialButton variant="outlined" soft onClick={closeModal}>
                  {'Cancel'}
                </MaterialButton>
                <MaterialButton
                  variant="outlined"
                  soft
                  teal
                  onClick={handleSubmit}
                >
                  {'Save'}
                </MaterialButton>
              </div>
            ) : (
              <div className={styles.footer}>
                <MaterialButton variant="outlined" soft onClick={closeModal}>
                  {'Close'}
                </MaterialButton>
              </div>
            )}
          </Form>
        )}
      />
    </div>
  );
};

export default EditNotification;
