import { alpha } from '@mui/material';
import { ConditionalStyles } from 'react-data-table-component';

/**
 * This can be refactored later to a customer preference or a partner
 * branding preference if configurablity is needed *
 */
export const HIGHLIGHT_COLOR = alpha('#27F5BD', 0.29);

export interface HighlightableTableRow {
  isHighlighted: boolean;
}

export const hightlightedConditionalRowStyles: ConditionalStyles<HighlightableTableRow>[] =
  [
    {
      when: (row) => row.isHighlighted,
      style: {
        backgroundColor: HIGHLIGHT_COLOR,
      },
    },
  ];
