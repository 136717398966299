import * as React from 'react';
import { useEffect, useState } from 'react';

import { Slider, Stack, TextField, Theme, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { first, last } from 'lodash';

export const ADULT_MARKS = [
  14, 18, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80,
].map((mark) => ({
  value: mark,
  label: mark,
}));

export const CHILD_MARKS = [8, 10, 12, 14, 16, 18, 20].map((mark) => ({
  value: mark,
  label: mark,
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '20px',
    },
    valueLabel: {
      fontWeight: 'normal',
      top: -6,
      backgroundColor: 'unset',
      '& *': {
        background: 'transparent',
        color: theme.palette.primary.main,
        fontSize: '14px',
      },
    },
    markLabel: {
      fontSize: '12px',
      color: theme.palette.primary.main,
    },
    thumb: {
      backgroundColor: theme.palette.secondary.main,
    },
    mark: {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
);

interface Props {
  disabled: boolean;
  value: number[];
  onChange: (newValue: number[]) => void;
  ageCategory: 'Adult' | 'Children';
}

const AgeSlider: React.FC<Props> = (props) => {
  const { disabled = false, value, onChange, ageCategory } = props;

  const classes = useStyles(useTheme());

  const minValue =
    ageCategory == 'Adult'
      ? first(ADULT_MARKS).value
      : first(CHILD_MARKS).value;
  const maxValue =
    ageCategory == 'Adult' ? last(ADULT_MARKS).value : last(CHILD_MARKS).value;

  const [bottomAge, setBottomAge] = useState<number>(value[0]);
  const [topAge, setTopAge] = useState<number>(value[1]);

  useEffect(() => {
    if (value[0] !== bottomAge) setBottomAge(value[0]);
    if (value[1] !== topAge) setTopAge(value[1]);
  }, [value]);

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <TextField
        sx={{ width: '65px' }}
        name="bottomAge"
        variant="outlined"
        id="bottomAge"
        value={bottomAge}
        onChange={(event) => {
          const intValue = parseInt(event.target.value);

          if (isNaN(intValue)) {
            setBottomAge(null);
          } else {
            setBottomAge(intValue);
            onChange([intValue, topAge]);
          }
        }}
      />
      <Slider
        step={1}
        min={minValue}
        max={maxValue}
        marks={ageCategory == 'Adult' ? ADULT_MARKS : CHILD_MARKS}
        valueLabelDisplay="on"
        value={value}
        onChange={(_event, newValue: number[]) => onChange(newValue)}
        disabled={disabled}
        size="small"
        classes={{
          root: classes.root,
          valueLabel: classes.valueLabel,
          markLabel: classes.markLabel,
          mark: classes.mark,
          thumb: classes.thumb,
        }}
      />

      <TextField
        sx={{ width: '65px' }}
        name="topAge"
        variant="outlined"
        id="topAge"
        value={topAge}
        onChange={(event) => {
          const intValue = parseInt(event.target.value);

          if (isNaN(intValue)) {
            setTopAge(null);
          } else {
            setTopAge(intValue);
            onChange([bottomAge, intValue]);
          }
        }}
      />
    </Stack>
  );
};

export default AgeSlider;
