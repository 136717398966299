import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import * as d3 from 'd3';

import { IconButton, Slider, Grid, InputLabel } from '@mui/material';
import PngFileIcon from 'components/Icons/DownloadIcons/PngFileIcon';
import SvgFileIcon from 'components/Icons/DownloadIcons/SvgFileIcon';

import { downloadSVG, downloadSVGAsPNG } from '../utils';

interface HProps {
  svgId: string;
  hdata: number[];
  quantiles?: number[];
  rangeMin?: number;
  rangeMax?: number;
}

const ReportHistogramPlot: React.FC<HProps> = (props) => {
  const { svgId, hdata, quantiles, rangeMin, rangeMax } = props;

  // defaults
  const quantiles_ = quantiles ?? [0.25, 0.5, 0.75];
  const [thresholds, setThresholds] = useState(9);

  const [width, height] = [600, 150];
  const marginTop = 20;
  const marginRight = 20;
  const marginBottom = 30;
  const marginLeft = 40;

  const _min = rangeMin || Math.min(...hdata);
  const _max = rangeMax || Math.max(...hdata);

  const barHeight = (): number => height - marginBottom - marginTop;

  // Bin the data.
  const bins = d3.bin().thresholds(
    Array.from({ length: thresholds }, (_, i) => i)
      .map(Number.call, Number)
      .map(
        (n: number) =>
          Math.min(...hdata) +
          (n / thresholds) * (Math.max(...hdata) - Math.min(...hdata)),
      ),
  )(hdata);

  // Declare the x (horizontal position) scale.
  const x = d3
    .scaleLinear()
    .domain([_min, _max])
    .range([marginLeft, width - marginRight]);

  // Declare the y (vertical position) scale.
  const y = d3
    .scaleLinear()
    .domain([0, d3.max(bins, (d) => d.length)])
    .range([height - marginBottom, marginTop]);

  const svgRef = useRef(null);

  useEffect(() => {
    // D3 Code

    // Selections
    const svg = d3
      .select(svgRef.current)
      .classed('histogram-svg', true)
      .attr('width', '100%') // width
      //.attr("height", height)
      .attr('viewBox', `0 0 ${width} ${height}`);

    // clear all previous content on refresh
    const everything = svg.selectAll('*');
    everything.remove();

    // Add a rect for each bin.
    svg
      .append('g')
      .attr('fill', 'steelblue')
      .selectAll()
      .data(bins)
      .join('rect')
      .attr('x', (d) => x(d.x0) + 1)
      .attr('width', (d) => x(d.x1) - x(d.x0) - 1)
      .attr('y', (d) => y(d.length))
      .attr('height', (d) => y(0) - y(d.length));

    // Mark quantiles in red
    svg
      .append('g')
      .attr('fill', 'red')
      .selectAll()
      .data(quantiles_)
      .join('rect')
      .attr('x', (d) => x(d3.quantile(hdata, d)) - 1)
      .attr('width', 2)
      .attr('y', (d) => marginTop + Math.abs(0.5 - d) * barHeight())
      .attr('height', (d) => barHeight() - Math.abs(0.5 - d) * barHeight());

    // Add the x-axis and label.
    svg
      .append('g')
      .attr('transform', `translate(0,${height - marginBottom})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0),
      )
      .call((g) =>
        g
          .append('text')
          .attr('x', width)
          .attr('y', marginBottom - 4)
          .attr('fill', 'currentColor')
          .attr('text-anchor', 'end')
          .text('PQ →'),
      );

    // Add the y-axis and label, and remove the domain line.
    svg
      .append('g')
      .attr('transform', `translate(${marginLeft},0)`)
      .call(d3.axisLeft(y).ticks(height / 40))
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .append('text')
          .attr('x', -marginLeft)
          .attr('y', 10)
          .attr('fill', 'currentColor')
          .attr('text-anchor', 'start')
          .text('↑ Frequency (no. of products)'),
      );
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <svg id={svgId} ref={svgRef} xmlns="http://www.w3.org/2000/svg" />
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={6}>
        <InputLabel id="hist-thresholds-slider-label" shrink>
          Bins
        </InputLabel>
        <Slider
          value={thresholds}
          getAriaValueText={() => 'Set Histogram Thresholds'}
          onChange={(_, newValue: number) => setThresholds(newValue)}
          aria-labelledby="histogram-thresholds-slider"
          valueLabelDisplay="auto"
          step={1}
          min={5}
          max={13}
          size="small"
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton
          id={svgId + 'svg-link'}
          size="small"
          onClick={(e) => downloadSVG(e, svgId)}
        >
          <SvgFileIcon fontSize="large" />
        </IconButton>
        <IconButton
          id={svgId + 'png-link'}
          size="small"
          onClick={(e) => downloadSVGAsPNG(e, svgId, width, height)}
        >
          <PngFileIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ReportHistogramPlot;
