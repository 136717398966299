import * as React from 'react';
import { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { Grid, LinearProgress } from '@mui/material';
import FieldTextInput from 'components/FieldTextInput';
import FormInput from 'components/FormInput';
import FormInputSelect from 'components/FormInputSelect';
import MaterialButton from 'components/MaterialButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Field } from 'redux-form';

import appToastAdd from '../../actions/appToastAdd';
import AllWorkspacesQuery from '../../graphql/queries/AllWorkspacesQuery';
import { PageHeader, PagePaper, PageTitle } from '../../styles/themeComponents';

// Client name can't just a number.
const validateClientName = (val) => !isNaN(+val);

interface Workspace {
  value: number;
  label: string;
}

const ExistingWorkspaces = gql`
  query existingWorkspaces {
    allParentProducers {
      nodes {
        parentProducerId
        groupName
        childProducers: childProducersByGroupId {
          nodes {
            childProducerId
          }
        }
      }
    }
  }
`;

interface Props {
  handleSubmit: MouseEvent | any;
  error: boolean;
  pristine: boolean;
  submitting: boolean;
  initialValues: any;
  node: any;
  onClose: () => void;
  change: any;
}

const GroupWorkspaceRedux: React.FC<Props> = ({
  node,
  handleSubmit,
  submitting,
  onClose,
  change,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [groupToEdit, setGroupToEdit] = useState(null);
  const [selectedChildWorkspaces, setSelectedChildWorkspaces] = useState<
    Workspace[]
  >([]);
  const [selectedParentWorkspace, setParentWorkspaces] =
    useState<Workspace>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>('');

  const allWorkspacesQuery = useQuery(AllWorkspacesQuery);
  const {
    loading,
    data: existingWorkspaces,
    refetch,
  } = useQuery(ExistingWorkspaces, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [loadedChildWorkspaces, setLoadedChildWorkspaces] = React.useState([]);
  const [incomingChildWorkspaces, setIncomingChildWorkspaces] = React.useState(
    [],
  );
  const [outgoingChildWorkspaces, setOutgoingChildWorkspaces] = React.useState(
    [],
  );

  const handleParentWorkspaceChange = (selected: Workspace) => {
    setParentWorkspaces(selected);
  };

  const handleChildWorkspace = (selected: Workspace[]) => {
    if (groupToEdit) {
      const selectedItems = selected.map((item) => item.value);
      setOutgoingChildWorkspaces(
        loadedChildWorkspaces.filter((x) => !selectedItems.includes(x)),
      );
      setIncomingChildWorkspaces(
        selectedItems.filter((x) => !loadedChildWorkspaces.includes(x)),
      );
      setSelectedChildWorkspaces(selected);
    } else {
      setSelectedChildWorkspaces(selected);
    }
    setErrorMessage(null);
  };

  useEffect(() => {
    const state: any = location.state ?? null;
    if (state) {
      const { incomingGroupEdit } = state;
      setGroupToEdit(incomingGroupEdit);
      const incomingParentWorkspace = {
        value: incomingGroupEdit.parentProducer.id,
        label: incomingGroupEdit.parentProducer.name,
      };
      handleParentWorkspaceChange(incomingParentWorkspace);
      const initialChildWorkspaces = [
        ...incomingGroupEdit.childProducers.nodes.map((item) => {
          return {
            value: item.producerByChildProducerId.id,
            label: item.producerByChildProducerId.name,
          };
        }),
      ];
      setSelectedChildWorkspaces(initialChildWorkspaces);
      setLoadedChildWorkspaces(
        initialChildWorkspaces.map((item) => item.value),
      );
      setGroupName(incomingGroupEdit.groupName);
      change('groupName', incomingGroupEdit.groupName);
      change('selectedParentWorkspace', incomingParentWorkspace);
      change('selectedChildWorkspaces', initialChildWorkspaces);
      change('oldNode', incomingGroupEdit);
      setIsEditing(true);
    }
  }, [location]);

  const hasValidateEntries = (): boolean => {
    if (!existingWorkspaces || !existingWorkspaces.allParentProducers.nodes)
      return true;

    const existingWorkspacesNodes =
      groupToEdit !== null
        ? existingWorkspaces.allParentProducers.nodes.filter(
            (item) => item.parentProducerId !== groupToEdit.parentProducerId,
          )
        : existingWorkspaces.allParentProducers.nodes;

    const duplicateWorkspace =
      existingWorkspacesNodes &&
      existingWorkspacesNodes.filter(
        (item) => item.groupName.toLowerCase() === groupName.toLowerCase(),
      );

    if (!node) {
      if (duplicateWorkspace.length) {
        const errMsg = 'Duplicate group name detected';
        setErrorMessage(errMsg);
        dispatch(
          appToastAdd({
            durationMilliseconds: 4000,
            title: errMsg,
            toastKey: `toast_${Date.now()}`,
          }),
        );
        return false;
      }

      const duplicateRelationshipValidatedByParent =
        existingWorkspacesNodes.filter(
          (wkspc) =>
            wkspc.parentProducerId === selectedParentWorkspace.value &&
            wkspc.childProducers.nodes.some((child) => {
              return (
                selectedChildWorkspaces.findIndex(
                  (selChldWrkspc) =>
                    selChldWrkspc.value === child.childProducerId,
                ) !== -1
              );
            }),
        );

      if (duplicateRelationshipValidatedByParent.length) {
        const errMsg = 'Duplicate parent <-> child relationship detected';
        setErrorMessage(errMsg);
        dispatch(
          appToastAdd({
            durationMilliseconds: 4000,
            title: errMsg,
            toastKey: `toast_${Date.now()}`,
          }),
        );
        return false;
      }

      for (const selectedChild of selectedChildWorkspaces) {
        const duplicateRelationshipValidatedByChild =
          existingWorkspacesNodes.filter(
            (wkspc) =>
              wkspc.parentProducerId === selectedChild.value &&
              wkspc.childProducers.nodes.some(
                (child) =>
                  child.childProducerId === selectedParentWorkspace.value,
              ),
          );

        if (duplicateRelationshipValidatedByChild.length) {
          const errMsg = 'Duplicate child <-> parent relationship detected';
          setErrorMessage(errMsg);
          dispatch(
            appToastAdd({
              durationMilliseconds: 4000,
              title: errMsg,
              toastKey: `toast_${Date.now()}`,
            }),
          );

          return false;
        }
      }
    }

    if (node) {
      const duplicateRelationshipValidatedByParent =
        existingWorkspacesNodes.filter(
          (wkspc) =>
            wkspc.parentProducerId === selectedParentWorkspace.value &&
            wkspc.childProducers.nodes.some((child) => {
              return (
                incomingChildWorkspaces.findIndex(
                  (selChldWrkspc) => selChldWrkspc === child.childProducerId,
                ) !== -1
              );
            }),
        );

      if (duplicateRelationshipValidatedByParent.length) {
        const errMsg = 'Duplicate parent <-> child relationship detected';
        setErrorMessage(errMsg);
        dispatch(
          appToastAdd({
            durationMilliseconds: 4000,
            title: errMsg,
            toastKey: `toast_${Date.now()}`,
          }),
        );
        return false;
      }

      for (const selectedChild of incomingChildWorkspaces) {
        const duplicateRelationshipValidatedByChild =
          existingWorkspacesNodes.filter(
            (wkspc) =>
              wkspc.parentProducerId === selectedChild &&
              wkspc.childProducers.nodes.some(
                (child) =>
                  child.childProducerId === selectedParentWorkspace.value,
              ),
          );

        if (duplicateRelationshipValidatedByChild.length) {
          const errMsg = 'Duplicate child <-> parent relationship detected';
          setErrorMessage(errMsg);
          dispatch(
            appToastAdd({
              durationMilliseconds: 4000,
              title: errMsg,
              toastKey: `toast_${Date.now()}`,
            }),
          );

          return false;
        }
      }
    }

    return true;
  };

  if (allWorkspacesQuery.loading) {
    return <LinearProgress />;
  }

  if (allWorkspacesQuery.error) {
    return <div>Error Occured!</div>;
  }

  const styles = {
    sectionHeader: {
      fontFamily: 'AlphaHeadlinePro-Bold, sans-serif',
      fontSize: '14px',
      paddingTop: '30px',
    },
  };

  return (
    <PagePaper>
      <PageHeader>{'Group Workspace'}</PageHeader>
      <PageTitle>{'Create New Workspace Group'}</PageTitle>
      {submitting && <LinearProgress />}
      {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
      <div style={styles.sectionHeader}>Parent Workspace</div>
      <div style={{ paddingTop: 5 }}>
        <form>
          <Field
            name="selectedParentWorkspace"
            component={FormInput}
            inputComponent={FormInputSelect}
            options={allWorkspacesQuery.data.allProducers.nodes.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            isSearchable={!isEditing}
            isDisabled={isEditing}
            isClearable
            value={selectedParentWorkspace}
            onChange={handleParentWorkspaceChange}
          />
          <div style={styles.sectionHeader}>Child Workspaces</div>
          <div style={{ paddingTop: 15 }}>
            <Field
              name="selectedChildWorkspaces"
              component={FormInput}
              inputComponent={FormInputSelect}
              options={allWorkspacesQuery.data.allProducers.nodes.map(
                (item) => ({
                  value: item.id,
                  label: item.name,
                }),
              )}
              value={selectedChildWorkspaces}
              isSearchable={true}
              isMulti={true}
              isClearable={true}
              onChange={handleChildWorkspace}
            />
          </div>
          <div style={styles.sectionHeader}>Group Name</div>
          <Field
            name="groupName"
            component={FieldTextInput}
            fullWidth
            placeholder={'Enter Group Name'}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            disabled={isEditing}
          />
          <div
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBlock: 10,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <MaterialButton
                  variant="outlined"
                  onClick={handleSubmit}
                  disabled={
                    submitting ||
                    !groupName ||
                    !selectedChildWorkspaces.length ||
                    !selectedParentWorkspace
                  }
                  soft
                  teal
                  fullWidth
                >
                  {isEditing ? 'Update' : 'Save'}
                </MaterialButton>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    </PagePaper>
  );
};

export default GroupWorkspaceRedux;
