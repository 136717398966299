import { gql } from '@apollo/client';

export default gql`
  query TextureMapVectors($reportID: UUID!) {
    allRpTexturemapTcvectors(condition: { reportId: $reportID }) {
      nodes {
        varnames
        ld1
        ld2
        angle
      }
    }
  }
`;
