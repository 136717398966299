import { UPPER_ALPHABET } from 'constants/alphabets';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import ReportSummaryByReportIdAndProductIdQuery from '@graphql/queries/ReportSummaryByReportIdAndProductIdQuery';
import { PointTooltipProps, ResponsiveLine, Serie } from '@nivo/line';
import { BasicTooltip } from '@nivo/tooltip';
import { getProductName } from 'components/Report/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';

import { LINE_COLORS, pqMeanLayer } from '../productMarketPreferenceUtils';

interface Props {
  projectId: number;
  title: string;
  titleCenter: boolean;
  marketPreferenceData: {
    reportId: string;
    marketPreferenceData: reports.ReportMarketPreferencesResponse;
  }[];

  calculatedMaxPercentPop?: number;
}

const ReportProductMarketPerferenceOverlay: React.FC<Props> = (props) => {
  const {
    projectId,
    title,
    titleCenter,
    marketPreferenceData,
    calculatedMaxPercentPop,
  } = props;

  const productNames = useCustomProductNames({ projectId: Number(projectId) });
  const productName = (
    productNames: reports.ColorVersionedProductInfo[],
    marketPreferenceData: reports.ReportMarketPreferencesResponse,
  ) =>
    getProductName({
      productNames,
      productId:
        marketPreferenceData.allRpMarketPreferences.nodes[0].product.id,
      version: marketPreferenceData.allRpMarketPreferences.nodes[0].version,
    });

  const [querySummaryData] = useLazyQuery<reports.ProductSummaryPQWinRate>(
    ReportSummaryByReportIdAndProductIdQuery,
  );
  interface SummaryChartData {
    id: string;
    hidden: boolean;
    data: reports.ProductSummaryPQWinRate;
  }
  const [summaryChartData, setSummaryChartData] = useState<SummaryChartData[]>(
    [],
  );
  useEffect(() => {
    const getResults = async () => {
      const results = await Promise.all(
        marketPreferenceData.map(
          async (p, i) =>
            await querySummaryData({
              variables: {
                reportId: p.reportId,
                productId:
                  p.marketPreferenceData.allRpMarketPreferences.nodes[0].product
                    .id,
              },
            }).then((res) => {
              return {
                id: [
                  UPPER_ALPHABET[i],
                  productName(productNames, p.marketPreferenceData),
                ].join(' - '),
                hidden: false,
                data: res.data,
              };
            }),
        ),
      );
      setSummaryChartData(results.sort((a, b) => b.id.localeCompare(a.id)));
    };

    getResults();
  }, [marketPreferenceData]);

  const getData = (
    marketPreferenceData: {
      reportId: string;
      marketPreferenceData: reports.ReportMarketPreferencesResponse;
    }[],
  ): Serie[] => {
    return marketPreferenceData
      .map((d, i) => ({
        id: [
          UPPER_ALPHABET[i],
          productName(productNames, d.marketPreferenceData),
        ].join(' - '),
        data: d.marketPreferenceData.allRpMarketPreferences.nodes.map(
          (reportMarketPreference) => ({
            x: reportMarketPreference.pqRating,
            y: reportMarketPreference.percentPop,
          }),
        ),
      }))
      .sort((a, b) => b.id.localeCompare(a.id));
  };

  const pqTooltip: React.FunctionComponent<PointTooltipProps> = (props) => {
    const productName = (props.point.serieId as string).split('|')[1];

    return (
      <BasicTooltip
        id={productName}
        value={`PQ: ${props.point.data.x} - ${props.point.data.y}%`}
        color={props.point.serieColor}
        enableChip
      />
    );
  };

  return (
    <div style={{ marginTop: '30px' }}>
      <h5 style={titleCenter ? { textAlign: 'center' } : {}}>{title}</h5>
      <div style={{ height: '500px' }}>
        <ResponsiveLine
          data={getData(marketPreferenceData)}
          margin={{ top: 20, right: 350, bottom: 70, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 0,
            max: calculatedMaxPercentPop || 'auto',
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'PQ Rating',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Percent of Population',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          enablePoints={false}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          tooltip={pqTooltip}
          colors={LINE_COLORS}
          layers={[
            ...summaryChartData.map((d, i) => pqMeanLayer(d.data, i, d.hidden)),
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            'lines',
            'slices',
            'points',
            'mesh',
            'legends',
          ]}
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              toggleSerie: true,
              onClick: (legendElement) =>
                setSummaryChartData((current) =>
                  current.map((summaryRow) =>
                    legendElement.id == summaryRow.id
                      ? {
                          ...summaryRow,
                          hidden: !summaryRow.hidden,
                        }
                      : summaryRow,
                  ),
                ),
              justify: false,
              translateX: 150, // Increase this to move the legends further right
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 200,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ReportProductMarketPerferenceOverlay;
