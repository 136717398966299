import * as React from 'react';

import { useQuery } from '@apollo/client';
import GastrographRadarDownloadQuery from '@graphql/queries/GastrographRadarDownloadQuery';
import ReportStackRankComparisonQuery from '@graphql/queries/ReportStackRankComparisonQuery';
import { Box } from '@mui/material';
import {
  ProductVersion,
  ProductVersionSet,
} from 'components/Report/ProductVersion';
import {
  bankersRound,
  fpDecompRowsTotalDecomp,
} from 'components/Report/ReportGastrographRadar/RadarUtils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { groupBy, keys } from 'lodash';
import { Data, LabelKeyObject } from 'react-csv/lib/core';

import { getProductName } from '../../utils';
import DataDownloadTile from '../DataDownloadTile';
import { TileType } from '../DataDownloadTile/DataDownloadTile';

const ALL_PRODUCT_FLAVOR_PROFILE_INTENSITIES_HEADER: LabelKeyObject[] = [
  { label: 'Product', key: 'product' },
  { label: 'Total Intensity', key: 'totalIntensity' },
  { label: '% Known Signatures Accounted For', key: 'pctSignatures' },
];

const getCsvRows: (
  radarData: reports.ReportGastrographRadarReponse,
  comparisonData: reports.ReportStackRankComparison,
  productNames: reports.ColorVersionedProductInfo[],
) => Data = (radarData, comparisonData, productNames) => {
  const products = groupBy(
    radarData.allRpOfps.nodes
      .filter((n) => !!n.product)
      .map((n) => ({
        ...n,
        productVersion: new ProductVersion(n.product.id, n.version),
      })),
    (n) => n.productVersion.value,
  );

  const comparisonProducts = groupBy(
    comparisonData.allRpFlavorDecomps.nodes.map((n) => ({
      ...n,
      productVersion: new ProductVersion(n.productByProductId.id, n.version),
    })),
    (n) => n.productVersion.value,
  );

  const rows = [];
  keys(products).forEach((p) => {
    const row = [];

    const productVersion = products[p].find(Boolean).productVersion;

    ALL_PRODUCT_FLAVOR_PROFILE_INTENSITIES_HEADER.forEach((h) => {
      if (h.key === 'product') {
        row.push(
          getProductName({
            productNames,
            productId: productVersion.productId,
            version: productVersion.version,
          }),
        );
      } else if (h.key === 'pctSignatures') {
        const comparisonProduct: reports.ReportFpDecompRow[] =
          comparisonProducts[p];
        const item: number = fpDecompRowsTotalDecomp(comparisonProduct);
        row.push(Number(item * 100).toFixed(0));
      } else {
        const item: number = products[p]
          .map((a) => bankersRound(a.intensity, 0))
          .reduce((a, b) => a + b, 0);
        row.push(Number(item).toFixed(0));
      }
    });

    rows.push(row);
  });
  return rows;
};
interface Props {
  projectId: number;
  reportId: string;
  products: ProductVersionSet;
}

const AllProductFlavorProfileIntensities: React.FC<Props> = (props) => {
  const { projectId, reportId, products } = props;

  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const {
    loading: radarLoading,
    error: radarError,
    data: radarData,
  } = useQuery<reports.ReportGastrographRadarReponse>(
    GastrographRadarDownloadQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: comparisonLoading,
    error: comparisonError,
    data: comparisonData,
  } = useQuery<reports.ReportStackRankComparison>(
    ReportStackRankComparisonQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  if (
    radarLoading ||
    radarError ||
    comparisonLoading ||
    comparisonError ||
    getCsvRows(radarData, comparisonData, productNames).length === 0
  ) {
    return <Box />;
  }

  return (
    <DataDownloadTile
      tileType={TileType.CSV}
      headers={ALL_PRODUCT_FLAVOR_PROFILE_INTENSITIES_HEADER.map(
        (h) => h.label,
      )}
      csvDataRows={getCsvRows(radarData, comparisonData, productNames)}
      fileName="AllProductFlavorProfileIntensities.csv"
      tileName="All Product Flavor Profile Intensities"
    />
  );
};

export default AllProductFlavorProfileIntensities;
