import React, { useRef, useEffect } from 'react';

import * as d3 from 'd3';

const BoxPlot = ({ data, pqJitter }) => {
  const width = 740; // outer width, in pixels
  const height = 400; // outer height, in pixels
  const marginTop = 20; // top margin, in pixels
  const marginRight = 30; // right margin, in pixels
  const marginBottom = 30; // bottom margin, in pixels
  const marginLeft = 40; // left margin, in pixels

  const X = d3.map(data, (d) => d.ranking);
  const Y = d3.map(data, (d) => d.mean);
  const yRange = [height - marginBottom, marginTop]; // [bottom, top]
  const thresholds = width / 40; // approximative number of thresholds
  const xType = d3.scaleLinear; // type of x-scale
  const yType = d3.scaleLinear; // type of y-scale

  // Filter undefined values.
  const I = d3.range(X.length).filter((i) => !isNaN(X[i]) && !isNaN(Y[i]));
  const xRange = [marginLeft, width - marginRight]; // [left, right]
  const barWidth = 20;
  const strokeWidth = barWidth / 5;
  const xLabel = 'Ranking';
  const yLabel = 'PQ';

  const paired_color_palette = [
    '#022950',
    '#33A02C',
    '#6A3D9A',
    '#1F78B4',
    '#FF7F00',
    '#B15928',
    '#E31A1C',
    '#B2DF8A',
    '#CAB2D6',
    '#A6CEE3',
    '#FDBF6F',
    '#FFFF99',
    '#FB9A99',
    '#4F2EB9',
    '#be38f3',
    '#00c7fc',
    '#fecb3e',
    '#008cb4',
  ];

  // Compute the bins.
  const B = d3
    .bin()
    .thresholds(thresholds)
    .value((i) => X[i])(I)
    .map((bin) => {
      const y = (i) => Y[i];
      const min = d3.min(bin, y);
      const max = d3.max(bin, y);
      const q1 = d3.quantile(bin, 0.25, y);
      const q2 = d3.quantile(bin, 0.5, y);
      const q3 = d3.quantile(bin, 0.75, y);
      const iqr = q3 - q1; // interquartile range
      const r0 = Math.max(min, q1 - iqr * 1.5);
      const r1 = Math.min(max, q3 + iqr * 1.5);
      bin.quartiles = [q1, q2, q3];
      bin.range = [r0, r1];
      bin.outliers = bin.filter((i) => Y[i] < r0 || Y[i] > r1);
      return bin;
    });

  // Compute default domains.
  const xDomain = [d3.min(B, (d) => d.x0), d3.max(B, (d) => d.x1)];
  const yDomain = [
    d3.min(B, (d) => d.range[0]) - 1,
    d3.max(B, (d) => d.range[1]) + 1,
  ];

  // Construct scales and axes.
  const xScale = xType(xDomain, xRange).interpolate(d3.interpolateRound);
  const yScale = yType(yDomain, yRange);
  const pxPerPq =
    (yScale(yDomain[0]) - yScale(yDomain[1])) / (yDomain[1] - yDomain[0]);
  const barHeight = 2 * pxPerPq * pqJitter;

  const xAxis = d3
    .axisBottom(xScale)
    .tickValues(data.map((d) => d.ranking))
    .tickSizeOuter(0)
    .tickFormat(d3.format('~'))
    .tickSize(0);
  const yAxis = d3.axisLeft(yScale).ticks(height / 40);

  const half = function (x) {
    return x / 2;
  };

  const svgRef = useRef(null);

  useEffect(() => {
    // clear all previous content on refresh

    const svg = d3
      .select(svgRef.current)
      .attr('width', width + 150)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

    const everything = svg.selectAll('*');
    everything.remove();

    svg
      .append('g')
      .attr('transform', `translate(${marginLeft},0)`)
      .call(yAxis)
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('x2', width - marginLeft - marginRight)
          .attr('stroke-opacity', 0.1),
      )
      .call((g) =>
        g
          .append('text')
          .attr('x', -(height / 2))
          .attr('y', -marginLeft)
          .attr('fill', 'currentColor')
          .attr('text-anchor', 'middle')
          .attr('font-size', '15')
          .attr('transform', 'rotate(-90)')
          .text(yLabel),
      );

    // Vertical Bar
    svg
      .append('g')
      .selectAll('rect')
      .data(I)
      .join('rect')
      .attr('x', (d, i) => xScale(X[i]) + half(barWidth) - half(strokeWidth))
      .attr('y', (d, i) => yScale(Y[i]) - half(barHeight))
      .attr('width', strokeWidth)
      .attr('height', (d, i) => barHeight)
      .attr('fill', (_, i) => paired_color_palette[i]);

    // Top Bar
    svg
      .append('g')
      .selectAll('rect')
      .data(I)
      .join('rect')
      .attr('x', (d, i) => xScale(X[i]))
      .attr('y', (d, i) => yScale(Y[i]) - half(barHeight) - half(strokeWidth))
      .attr('width', barWidth)
      .attr('height', (d, i) => strokeWidth)
      .attr('fill', (_, i) => paired_color_palette[i]);

    // Bottom Bar
    svg
      .append('g')
      .selectAll('rect')
      .data(I)
      .join('rect')
      .attr('x', (d, i) => xScale(X[i]))
      .attr('y', (d, i) => yScale(Y[i]) + half(barHeight) - half(strokeWidth))
      .attr('width', barWidth)
      .attr('height', (d, i) => strokeWidth)
      .attr('fill', (_, i) => paired_color_palette[i]);

    // Center Box
    svg
      .append('g')
      .selectAll('rect')
      .data(I)
      .join('rect')
      .attr('x', (d, i) => xScale(X[i]))
      .attr('y', (d, i) => yScale(Y[i]) - half(barWidth))
      .attr('width', barWidth)
      .attr('height', barWidth)
      .attr('fill', (_, i) => paired_color_palette[i]);

    // Letter Marker
    svg
      .append('g')
      .attr('fill', 'white')
      .attr('font-family', 'sans-serif')
      .attr('font-size', 0.8 * barWidth)
      .selectAll('text')
      .data(I)
      .join('text')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .attr('x', (d, i) => xScale(X[i]) + half(barWidth))
      .attr('y', (d, i) => yScale(Y[i]))
      .text((_, index) => data[index].symbol);

    // X-Axis
    svg
      .append('g')
      .attr('transform', `translate(0,${height - marginBottom})`)
      .call(xAxis)
      .selectAll('text')
      .attr('x', '8');

    // X-Labels
    svg.append('g').call((g) =>
      g
        .append('text')
        .attr('font-size', '15')
        .attr('transform', `translate(0,${height - marginBottom})`)
        .attr('x', width / 2)
        .attr('y', marginBottom - 4)
        .attr('fill', 'currentColor')
        .attr('text-anchor', 'middle')
        .text(xLabel),
    );
  }, [data]);

  return (
    <div className="stack-rank">
      <svg ref={svgRef} />
    </div>
  );
};

export default BoxPlot;
