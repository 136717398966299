import * as React from 'react';

import { Box } from '@mui/material';
import dataTableCsv from 'components/ReactDataTable/ReactDataTableCsv';
import DataTable, { TableColumn } from 'react-data-table-component';

import styles from './DataDashboard.module.css';

const checkRow = (row: TableRow): boolean =>
  row.userCategory == 'E3' ||
  row.userCategory == 'E2' ||
  row.userCategory == 'E1' ||
  row.userCategory == 'D2' ||
  row.userCategory == 'D1' ||
  row.userCategory == 'C2' ||
  row.userCategory == 'C1';

const getData = (panelUsers: dataquality.DataQualityDetail[]): TableRow[] =>
  panelUsers
    .filter((user) => user.userCategory !== null)
    .map((user) => ({
      email: user.userEmail,
      age: user.age,
      gender: user.gender,
      socioEcon: user.socioEconomicStatus,
      userCategory: user.userCategory,
    }));
interface Props {
  panelUsers: dataquality.DataQualityDetail[];
}

interface TableRow {
  email: string;
  age: number;
  gender: string;
  socioEcon: string;
  userCategory: string;
}

const UserCategoryTable: React.FunctionComponent<Props> = ({ panelUsers }) => {
  const columns: TableColumn<TableRow>[] = [
    {
      id: 'email',
      selector: (row: TableRow) => row.email,
      name: 'User ID',
      sortable: true,
      wrap: true,
      style: { wordBreak: 'break-all' },
    },
    {
      id: 'age',
      selector: (row: TableRow) => row.age,
      name: 'Age',
      sortable: true,
      wrap: true,
    },
    {
      id: 'gender',
      selector: (row: TableRow) => row.gender,
      name: 'Gender',
      sortable: true,
      wrap: true,
    },
    {
      id: 'socioEcon',
      selector: (row: TableRow) => row.socioEcon,
      name: 'Socioeconomic Status',
      sortable: true,
      wrap: true,
    },
    {
      id: 'userCategory',
      selector: (row: TableRow) => row.userCategory,
      name: 'User Category',
      sortable: true,
      wrap: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: TableRow) => !checkRow(row),
      style: (row: TableRow) => ({ backgroundColor: 'var(--aqua-marine)' }),
    },
    {
      when: (row: TableRow) => checkRow(row),
      style: (row: TableRow) => ({ backgroundColor: 'var(--coral-pink)' }),
    },
  ];

  if (getData(panelUsers).length == 0) {
    return <Box />;
  }

  return (
    <div>
      <h3 className={styles.panelTitle}>User Category</h3>
      <DataTable
        columns={columns}
        data={getData(panelUsers)}
        fixedHeader
        striped
        conditionalRowStyles={conditionalRowStyles}
        actions={dataTableCsv(
          'User_Category_Table.csv',
          columns,
          getData(panelUsers),
        )}
      />
    </div>
  );
};

export default UserCategoryTable;
