/**
 * Submit a conch job to the conch batch service
 *
 * @param jobname Job Name to be submitted
 * @param jobdef Job Definition
 * @param jobqueue Job Queue
 * @param params Full params JSON
 * @param params_zipped The params JSON but gzipped and b64 encoded
 * @returns
 */
export default async function submitConchBatch<T extends conch.ReportParams>(
  jobname: string,
  jobdef: conch.JobDefinition,
  jobqueue: string,
  params: T,
  params_zipped?: string,
): Promise<Response> {
  return fetch('/conchBatch/uploadSubmitJob', {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      jobname,
      jobdef,
      jobqueue,
      params: params,
      params_zipped: params_zipped,
    }),
  });
}
