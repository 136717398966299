import { gql } from '@apollo/client';

export default gql`
  mutation UploadProductNutritionalInfoImage(
    $image: UploadProductNutritionalInfoImageInput!
  ) {
    uploadProductNutritionalInfoImage(input: $image) {
      id
      url
    }
  }
`;
