export interface ParsedPQModel {
  numTrees: number;
  childNodeIds: [number[], number[]][];
  splitVarIds: number[][];
  splitValues: number[][];
  isOrdered: boolean[];
  classValues: number[];
  independentVariableNames: string[];
  levels: string[];
  terminalClassCounts: number[][][];
  treeType: string[];
}

export const getParsedPQModel = (pqModel: reports.PQModel): ParsedPQModel => ({
  numTrees: getNumTrees(pqModel),
  childNodeIds: getChildNodeIdsArray(pqModel),
  splitVarIds: getSplitVarIdsArray(pqModel),
  splitValues: getSplitValuesArray(pqModel),
  isOrdered: getIsOrdredArray(pqModel),
  classValues: getClassValuesArray(pqModel),
  independentVariableNames: getIndependentVariableNamesArray(pqModel),
  levels: getLevelsArray(pqModel),
  terminalClassCounts: getTerminalClassCountsArray(pqModel),
  treeType: getTreeTypeArray(pqModel),
});

const getNumModels = (pqModel: reports.PQModel): number =>
  pqModel['num.trees'].length;

const getNumTrees = (pqModel: reports.PQModel) => {
  const numTrees: number[] = JSON.parse(
    pqModel['num.trees'][getNumModels(pqModel) - 1],
  );
  return numTrees[getNumModels(pqModel) - 1];
};

const getChildNodeIdsArray = (
  pqModel: reports.PQModel,
): [number[], number[]][] => {
  const childNodeIds: [number[], number[]][] = JSON.parse(
    pqModel['child.nodeIDs'][getNumModels(pqModel) - 1],
  );
  return childNodeIds;
};

const getSplitVarIdsArray = (pqModel: reports.PQModel): number[][] => {
  const splitVarIds: number[][] = JSON.parse(
    pqModel['split.varIDs'][getNumModels(pqModel) - 1],
  );
  return splitVarIds;
};

const getSplitValuesArray = (pqModel: reports.PQModel): number[][] => {
  const splitVarIds: number[][] = JSON.parse(
    pqModel['split.values'][getNumModels(pqModel) - 1],
  );
  return splitVarIds;
};

const getIsOrdredArray = (pqModel: reports.PQModel): boolean[] => {
  const splitVarIds: boolean[] = JSON.parse(
    pqModel['is.ordered'][getNumModels(pqModel) - 1],
  );
  return splitVarIds;
};

const getClassValuesArray = (pqModel: reports.PQModel): number[] => {
  const classValues: number[] = JSON.parse(
    pqModel['class.values'][getNumModels(pqModel) - 1],
  );
  return classValues;
};

const getIndependentVariableNamesArray = (
  pqModel: reports.PQModel,
): string[] => {
  const independentVariableNames: string[] = JSON.parse(
    pqModel['independent.variable.names'][getNumModels(pqModel) - 1],
  );
  return independentVariableNames;
};

const getLevelsArray = (pqModel: reports.PQModel): string[] => {
  const levels: string[] = JSON.parse(
    pqModel['levels'][getNumModels(pqModel) - 1],
  );
  return levels;
};

const getTerminalClassCountsArray = (
  pqModel: reports.PQModel,
): number[][][] => {
  const terminalClassCounts: number[][][] = JSON.parse(
    pqModel['terminal.class.counts'][getNumModels(pqModel) - 1],
  );
  return terminalClassCounts;
};

const getTreeTypeArray = (pqModel: reports.PQModel): string[] => {
  const treetype: string[] = JSON.parse(
    pqModel['treetype'][getNumModels(pqModel) - 1],
  );
  return treetype;
};
