import { REQUEST_REPORT_UPDATE } from 'constants/routePaths';

import * as React from 'react';

import { EditCalendar } from '@mui/icons-material';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  handleClose: () => void;
  report: reports.AllReportsQueryRow;
}

const UpdateReportMenuItem: React.FC<Props> = ({ handleClose, report }) => {
  const { t } = useTranslation();

  return (
    <MenuItem
      onClick={handleClose}
      component={Link}
      to={{
        pathname: REQUEST_REPORT_UPDATE,
        state: { templateReport: report, initStep: '2' },
      }}
    >
      <ListItemButton>
        <ListItemIcon>
          <EditCalendar fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText primary={t('reportsQa.menu.updateReport')} />
      </ListItemButton>
    </MenuItem>
  );
};

export default UpdateReportMenuItem;
