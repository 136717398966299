import * as React from 'react';
import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Switch,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
} from '@mui/material';

interface Props {
  objectToEdit: JSON;
  explicitInputTypes: object;
  onChange: (newValue: JSON) => void;
}

const ObjectEditor: React.FC<Props> = (props) => {
  const { objectToEdit, explicitInputTypes, onChange } = props;

  const [valid, setValid] = useState<boolean>(true);

  const updateObject = (
    current: JSON,
    topLevelKey: string,
    subKey: string,
    newValue: any,
  ): void => {
    const updatedObject = current;
    updatedObject[topLevelKey][subKey] = newValue;
    onChange(updatedObject);
  };

  const numbericValue = (value: any) =>
    typeof value == 'number' || !isNaN(+value.toString());

  const titleCase = (str: string): string => {
    return str
      .replace(/_/g, ' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase()
      .replace(/\b\w/g, (s) => s.toUpperCase());
  };

  if (!objectToEdit) {
    return <div />;
  }

  return (
    <div>
      {Object.keys(objectToEdit)
        .filter((k) => typeof objectToEdit[k] == 'object')
        .map((k) => (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {titleCase(k)}
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableBody>
                  {Object.keys(objectToEdit[k]).map((d) => {
                    const objectChunk = objectToEdit[k];
                    const chunkInputTypes = explicitInputTypes[k] ?? {};
                    const key = d,
                      value = objectChunk[d];
                    return (
                      <TableRow>
                        <TableCell>{titleCase(key)}</TableCell>
                        {chunkInputTypes[key] && (
                          <TableCell>
                            {chunkInputTypes[key].inputType == 'select' && (
                              <Select
                                value={value}
                                onChange={(event) =>
                                  updateObject(
                                    objectToEdit,
                                    k,
                                    key,
                                    event.target.value,
                                  )
                                }
                              >
                                {chunkInputTypes[key].options.map((option) => (
                                  <MenuItem value={option}>{option}</MenuItem>
                                ))}
                              </Select>
                            )}
                          </TableCell>
                        )}
                        {!chunkInputTypes[key] && (
                          <TableCell>
                            {typeof value == 'boolean' && (
                              <Switch
                                checked={value}
                                onChange={(event) =>
                                  updateObject(
                                    objectToEdit,
                                    k,
                                    key,
                                    event.target.checked,
                                  )
                                }
                              />
                            )}
                            {numbericValue(value) && (
                              <TextField
                                type="number"
                                value={value}
                                onChange={(event) =>
                                  updateObject(
                                    objectToEdit,
                                    k,
                                    key,
                                    event.target.value,
                                  )
                                }
                              />
                            )}
                            {!numbericValue(value) &&
                              typeof value != 'boolean' && (
                                <TextField
                                  value={value}
                                  onChange={(event) =>
                                    updateObject(
                                      objectToEdit,
                                      k,
                                      key,
                                      event.target.value,
                                    )
                                  }
                                />
                              )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};

export default ObjectEditor;
