import { gql } from '@apollo/client';

export default gql`
  mutation CreateReferenceFlavorTranslation(
    $referenceFlavorId: Int!
    $language: String!
    $translation: String!
  ) {
    createReferenceFlavorTranslation(
      input: {
        referenceFlavorTranslation: {
          referenceFlavorId: $referenceFlavorId
          language: $language
          translation: $translation
        }
      }
    ) {
      referenceFlavorTranslation {
        id
        referenceFlavorId
        language
        translation
      }
    }
  }
`;
