import ggVars from 'constants/ggVars';
import { NULL_REPORT_ID } from 'constants/report';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import JobParamsQuery, {
  JobParamsResponse,
} from '@graphql/queries/JobParamsQuery';
import RefFlavorsByReportId, {
  RefFlavorsByReportIdResponse,
} from '@graphql/queries/RefFlavorsByReportId';
import { Container, Divider, Grid, Stack } from '@mui/material';
import FieldCheckBox from 'components/FieldCheckBox';
import * as _ from 'lodash';
import { Field } from 'redux-form';

import TuningRangeSlider from './TuningRangeSlider';
import FormInput from '../../../components/FormInput';
import FormInputSelect from '../../../components/FormInputSelect';
import { arrayify } from '../../../utils/afsUtils';
import SelectReport from '../SelectReport';
import { generateArtifactPath } from '../SelectReport/SelectReport';

export function optTargetOptions(selectedJobParams) {
  return arrayify(
    selectedJobParams?.reportJobByReportId?.params?.competitive_set_products ??
      [],
  ).map((p) => ({ label: p, value: p }));
}
export interface ReportJobData {
  jobId: string;
  startedAt: string;
  reportType: string;
  reportId: string;
  targetGroupName: string;
  clientName: string;
  projectName: string;
  workspaceId: string;
  artifact_path: string;
}

interface Props {
  change: (field: string, value: any) => void;
  displayMode: boolean;
  optimization: any;
  templateReportId: string;
}

const Optimization: React.FC<Props> = (props: Props) => {
  const { change, displayMode } = props;

  const [selectedReport, setSelectedReport] = useState<ReportJobData>(null);
  const [refFlavorOptions, setRefFlavorOptions] = useState<Array<string>>([]);

  const populateRefFlavorOptions = (field: 'lock' | 'tune' | 'remove') => {
    const toLock = props.optimization.ref_flavor_to_lock.map((rf) => rf.value);
    const toTune = props.optimization.ref_flavor_to_tune.map((rf) => rf.value);
    const toRemove = props.optimization.ref_flavor_to_remove.map(
      (rf) => rf.value,
    );

    const options =
      field == 'lock'
        ? _.difference(refFlavorOptions, toRemove, toTune)
        : field == 'tune'
          ? _.difference(refFlavorOptions, toLock, toRemove)
          : field == 'remove'
            ? _.difference(refFlavorOptions, toLock, toTune)
            : refFlavorOptions;

    return _.map(options, (rf) => ({ label: rf, value: rf }));
  };

  const updateRefFlavorKeys = () =>
    _.chain(refFlavorOptions)
      .concat(props.optimization.ref_flavor_to_lock)
      .concat(props.optimization.ref_flavor_to_remove)
      .concat(props.optimization.ref_flavor_to_tune)
      .value().length;

  const resetFields = () => {
    change('optimization.products_to_opt', []);
    change('optimization.ggvars_to_lock', []);
    change('optimization.ggvar_constraints', []);
    change('optimization.ref_flavor_to_lock', []);
    change('optimization.ref_flavor_to_remove', []);
    change('optimization.ref_flavor_to_tune', []);
    change('optimization.rangeReffRange', []);
  };

  const { data: refFlavorData } = useQuery<RefFlavorsByReportIdResponse>(
    RefFlavorsByReportId,
    {
      variables: {
        reportId:
          selectedReport?.reportId ?? props.templateReportId ?? NULL_REPORT_ID,
      },
    },
  );

  const {
    data: selectedJobParams,
    loading: jobParamsLoading,
    error,
  } = useQuery<JobParamsResponse>(JobParamsQuery, {
    variables: {
      reportId:
        selectedReport?.reportId ?? props.templateReportId ?? NULL_REPORT_ID,
    },
  });

  useEffect(() => {
    // Only set report jobs and filtered report jobs when there isn't a selected report. Only possible for first load.
    if (refFlavorData) {
      setRefFlavorOptions(
        _.chain(refFlavorData.allRpOfps.nodes)
          .flatMap((rf) => rf.attribute)
          .uniq()
          .value(),
      );
    }
  }, [refFlavorData]);

  useEffect(() => {
    if (selectedReport && selectedJobParams) {
      change(
        'optimization.artifact_path',
        generateArtifactPath(
          selectedReport,
          selectedJobParams?.reportJobByReportId?.params['project_name'],
        ),
      );
    }
  }, [selectedReport, selectedJobParams]);

  return (
    <Container>
      <Grid
        container
        spacing={3}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        {!props.templateReportId && !displayMode && (
          <div>
            <SelectReport
              selectReport={(report: ReportJobData) => {
                change('optimization.workspace_id', report.workspaceId);
                change('optimization.parent_job_id', report.jobId);
                change('optimization.artifact_path', report.artifact_path);
                change('optimization.client_name', report.clientName);
                change('optimization.project_name', report.projectName);
                change(
                  'optimization.target_group_name',
                  report.targetGroupName,
                );
                setSelectedReport(report);
              }}
              resetFields={resetFields}
            />
            <br />
          </div>
        )}
        {!jobParamsLoading &&
          (!!selectedReport || !!props.templateReportId) && (
            <Grid container spacing={1} m={1}>
              <Grid item xs={6}>
                <Field
                  key={`opt_product_picker_report_${selectedJobParams?.reportJobByReportId?.params['reportUID']}`}
                  name={'optimization.products_to_opt'}
                  component={FormInput}
                  inputComponent={FormInputSelect}
                  customLabel
                  isSearchable
                  required
                  disabled={displayMode}
                  hideSelectedOptions={false}
                  labelText="Target Product for Optimization"
                  placeholder="Select a product"
                  options={optTargetOptions(selectedJobParams)}
                  onChange={({ value }) =>
                    change('optimization.products_to_opt', value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={'optimization.ggvars_to_lock'}
                  labelText="GGVars to apply constraints to"
                  customLabel
                  hideSelectedOptions={true}
                  disabled={displayMode}
                  placeholder="Select GGVars"
                  component={FormInput}
                  inputComponent={FormInputSelect}
                  isMulti
                  value={({ value }) => value}
                  options={ggVars.map((ggvar) => ({
                    label: ggvar.label,
                    value: ggvar.key,
                  }))}
                  onChange={({ value }) =>
                    change('optimization.ggvars_to_lock', value)
                  }
                />
              </Grid>
              {props.optimization.ggvars_to_lock.map((ggvar, index) => (
                <Grid item xs={12} key={ggvar.value}>
                  <Field
                    reduxFormState={props.optimization.ggvar_constraints}
                    fieldType="GGVAR"
                    index={index}
                    name={`optimization.ggvar_constraints[${index}]`}
                    label={`Range for ${ggvar.value}`}
                    displayMode={displayMode}
                    component={TuningRangeSlider}
                    onChange={(value) => {
                      change(`optimization.ggvar_constraints[${index}]`, [
                        Math.min(value),
                        Math.max(value),
                      ]);
                    }}
                  />
                </Grid>
              ))}
              <Grid item xs={6}>
                <Field
                  key={updateRefFlavorKeys()}
                  name={'optimization.ref_flavor_to_lock'}
                  labelText="Reference Flavors to lock"
                  customLabel
                  disabled={displayMode}
                  component={FormInput}
                  inputComponent={FormInputSelect}
                  isMulti
                  value={({ value }) => value}
                  options={populateRefFlavorOptions('lock')}
                  onChange={({ value }) =>
                    change('optimization.ref_flavor_to_lock', value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  key={updateRefFlavorKeys()}
                  name={'optimization.ref_flavor_to_remove'}
                  labelText="Reference Flavors to remove"
                  customLabel
                  component={FormInput}
                  disabled={displayMode}
                  inputComponent={FormInputSelect}
                  isMulti
                  value={({ value }) => value}
                  options={populateRefFlavorOptions('remove')}
                  onChange={({ value }) =>
                    change('optimization.ref_flavor_to_remove', value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  key={updateRefFlavorKeys()}
                  name={'optimization.ref_flavor_to_tune'}
                  labelText="Reference Flavors to tune"
                  customLabel
                  component={FormInput}
                  disabled={displayMode}
                  inputComponent={FormInputSelect}
                  isMulti
                  value={({ value }) => value}
                  options={populateRefFlavorOptions('tune')}
                  onChange={({ value }) =>
                    change('optimization.ref_flavor_to_tune', value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={3}>
                  {props.optimization.ref_flavor_to_tune.map((rf, index) => (
                    <Field
                      reduxFormState={props.optimization.rangeReffRange}
                      fieldType="RFF"
                      index={index}
                      name={`optimization.rangeReffRange[${index}]`}
                      displayMode={displayMode}
                      label={`Range for ${rf.value}`}
                      component={TuningRangeSlider}
                      onChange={(value) => {
                        change(`optimization.rangeReffRange[${index}]`, [
                          Math.min(value),
                          Math.max(value),
                        ]);
                      }}
                    />
                  ))}
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', flexDirection: 'row' }}
                marginTop={10}
              >
                <Divider />
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Field
                    name="optimization.NRF"
                    label="With New Reference Flavors? (NRF)"
                    disabled={displayMode}
                    val={props.optimization.NRF}
                    onChange={({ value }) => change('optimization.NRF', value)}
                    component={FieldCheckBox}
                  />
                  <Field
                    name="optimization.NNRF"
                    label="Without New Reference Flavors? (NNRF)"
                    val={props.optimization.NNRF}
                    disabled={displayMode}
                    onChange={({ value }) => change('optimization.NNRF', value)}
                    component={FieldCheckBox}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Field
                    name="optimization.NC_constraint"
                    component={FieldCheckBox}
                    disabled={displayMode}
                    label="No Constraint"
                    onChange={({ value }) =>
                      change('optimization.NC_constraint', value)
                    }
                  />
                  <Field
                    name="optimization.ULC_constraint"
                    component={FieldCheckBox}
                    disabled={displayMode}
                    label="Ultra Low Constraint"
                    onChange={({ value }) =>
                      change('optimization.ULC_constraint', value)
                    }
                  />
                  <Field
                    name="optimization.VLC_constraint"
                    component={FieldCheckBox}
                    disabled={displayMode}
                    label="Very Low Constraint"
                    onChange={({ value }) =>
                      change('optimization.VLC_constraint', value)
                    }
                  />
                  <Field
                    name="optimization.LC_constraint"
                    component={FieldCheckBox}
                    disabled={displayMode}
                    label="Low Constraint"
                    onChange={({ value }) =>
                      change('optimization.LC_constraint', value)
                    }
                  />
                  <Field
                    name="optimization.MC_constraint"
                    disabled={displayMode}
                    component={FieldCheckBox}
                    label="Medium Constraint"
                    onChange={({ value }) =>
                      change('optimization.MC_constraint', value)
                    }
                  />
                  <Field
                    name="optimization.HC_constraint"
                    component={FieldCheckBox}
                    disabled={displayMode}
                    label="High Constraint"
                    onChange={({ value }) =>
                      change('optimization.HC_constraint', value)
                    }
                  />
                  <Field
                    name="optimization.VHC_constraint"
                    component={FieldCheckBox}
                    disabled={displayMode}
                    label="Very High Constraint"
                    onChange={({ value }) =>
                      change('optimization.VHC_constraint', value)
                    }
                  />
                  <Field
                    name="optimization.UHC_constraint"
                    component={FieldCheckBox}
                    disabled={displayMode}
                    label="Ultra High Constraint"
                    onChange={({ value }) =>
                      change('optimization.UHC_constraint', value)
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', flexDirection: 'row' }}
                marginTop={10}
              >
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Field
                    name="optimization.enable_norms"
                    component={FieldCheckBox}
                    disabled={displayMode}
                    label="Enable Category and Stack Rank Norms?"
                    onChange={({ value }) =>
                      change('optimization.enable_norms', value)
                    }
                  />
                  <Field
                    name="optimization.tuning_plots"
                    component={FieldCheckBox}
                    label="Show Tuning Plots?"
                    disabled={displayMode}
                    onChange={({ value }) =>
                      change('optimization.tuning_plots', value)
                    }
                  />
                  <Field
                    name="optimization.plot_norm"
                    component={FieldCheckBox}
                    disabled={displayMode}
                    label="Plot PQ Norms?"
                    onChange={({ value }) =>
                      change('optimization.plot_norm', value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="optimization.optimize_flavor"
                    component={FieldCheckBox}
                    disabled={displayMode}
                    label="Optimize Flavor"
                    onChange={({ value }) =>
                      change('optimization.optimize_flavor', value)
                    }
                  />
                  <Field
                    name="optimization.optimize_texture"
                    component={FieldCheckBox}
                    disabled={displayMode}
                    label="Optimize Texture"
                    onChange={({ value }) =>
                      change('optimization.optimize_texture', value)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
      </Grid>
    </Container>
  );
};

export default Optimization;
