import * as React from 'react';

import { useQuery } from '@apollo/client';
import MarketMapProductsQuery from '@graphql/queries/MarketMapProductsQuery';
import ReportPQNormsQuery from '@graphql/queries/ReportPQNormsQuery';
import { Box, Grid } from '@mui/material';
import ReportHistogram from 'components/Report/ReportHistogram';
import { toTitleCase } from 'components/Report/utils';
import useReportSummary from 'hooks/useReportSummary';

interface Props {
  reportId: string;
}

const ProjectNorms: React.FC<Props> = (props) => {
  const { reportId } = props;

  const { loading, error, data } = useQuery<reports.ReportPQNormsResponse>(
    ReportPQNormsQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: productsLoading,
    error: productsError,
    data: marketMapProductsResponse,
  } = useQuery<reports.MarketMapProductsResponse>(MarketMapProductsQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const {
    loading: summaryLoading,
    error: summaryError,
    data: reportSummary,
  } = useReportSummary(reportId);

  const getHistogramDataCategory = (
    marketMapProductsResponse: reports.MarketMapProductsResponse,
  ) =>
    marketMapProductsResponse.allRpMarketmapProductvectors.nodes.map(
      (d) => +d.pq,
    );

  const getHistogramDataStackRank = (
    marketMapProductsResponse: reports.MarketMapProductsResponse,
  ) =>
    marketMapProductsResponse.allRpMarketmapProductvectors.nodes
      .filter(
        (d) =>
          !!d.product &&
          reportSummary.allReportJobs.nodes
            .map((n) =>
              n.reportSummariesByReportId.edges.map(
                (e) => e.node.productByProductId.id,
              ),
            )
            .flat()
            .includes(d.product.id),
      )
      .map((d) => +d.pq);

  const getBaseComponents = (products: reports.MarketMapProductsResponse) =>
    products.allRpMarketmapProductvectors.nodes
      .map(
        (d) =>
          d.product?.productComponentBaseProductsByProductId.nodes.map(
            (n) => n.productComponentBaseByProductComponentBaseId.name,
          ),
      )
      .flat()
      // unique values
      .filter(
        (value, index, array) => !!value && array.indexOf(value) === index,
      );

  const getHistDataBaseComponent = (
    products: reports.MarketMapProductsResponse,
    component: string,
  ) =>
    products.allRpMarketmapProductvectors.nodes
      .filter(
        (d) =>
          !!d.product &&
          d.product.productComponentBaseProductsByProductId.nodes
            .map((pc) => pc.productComponentBaseByProductComponentBaseId.name)
            .includes(component),
      )
      .flat()
      .map((d) => +d.pq);

  const anyError = !!error || !!productsError || !!summaryError;
  const anyLoading = !!loading || !!productsLoading || !!summaryLoading;

  if (
    anyLoading ||
    anyError ||
    data.allRpCategoryNorms.nodes.length +
      data.allRpStackrankNorms.nodes.length ==
      0
  ) {
    return <Box />;
  }

  const rangeMin = Math.min(
    ...getHistogramDataCategory(marketMapProductsResponse),
  );
  const rangeMax = Math.max(
    ...getHistogramDataCategory(marketMapProductsResponse),
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <ReportHistogram
          title="Category Norms"
          histoType="category"
          dataType="report"
          quantiles={[0.25, 0.5, 0.75]}
          hdata={getHistogramDataCategory(marketMapProductsResponse)}
          rangeMin={rangeMin}
          rangeMax={rangeMax}
        />
      </Grid>
      <Grid item xs={12}>
        <ReportHistogram
          title="Stack Rank Norms"
          histoType="stackRank"
          dataType="report"
          quantiles={[0.25, 0.5, 0.75]}
          hdata={getHistogramDataStackRank(marketMapProductsResponse)}
          rangeMin={rangeMin}
          rangeMax={rangeMax}
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid key="base-component-norms" item xs={12}>
        <h4>Base Component Norms</h4>
        {getBaseComponents(marketMapProductsResponse)
          .map((component) => {
            return {
              component,
              hdata: getHistDataBaseComponent(
                marketMapProductsResponse,
                component,
              ),
            };
          })
          .filter(({ component, hdata }) => hdata.length > 5)
          .map(({ component, hdata }) => {
            return (
              <Grid key={`base-component-norms-${component}}`} item xs={12}>
                <ReportHistogram
                  title={toTitleCase(component)}
                  histoType={component}
                  dataType="component"
                  quantiles={[0.25, 0.5, 0.75]}
                  hdata={hdata}
                  rangeMin={rangeMin}
                  rangeMax={rangeMax}
                />
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};
export default ProjectNorms;
