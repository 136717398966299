import { routerReducer as router } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import appPersistenceHasLoaded from './appPersistenceHasLoaded';
import appToasts from './appToasts';
import appViewerGlossaryIsOpen from './appViewerGlossaryIsOpen';
import appViewerMenuIsOpen from './appViewerMenuIsOpen';
import appViewerNotificationIsOpen from './appViewerNotificationIsOpen';
import appWorkspaceMenuIsOpen from './appWorkspaceMenuIsOpen';
import modal from './modal';
import productFolderId from './productFolderId';
import productTablePage from './productTablePage';
import session from './session';
import updateBlindLabel from './updateBlindLabel';
import workspaceProducerId from './workspaceProducerId';

export default combineReducers({
  appPersistenceHasLoaded,
  appToasts,
  appViewerMenuIsOpen,
  appViewerNotificationIsOpen,
  appViewerGlossaryIsOpen,
  appWorkspaceMenuIsOpen,
  form,
  router,
  session,
  workspaceProducerId,
  productFolderId,
  updateBlindLabel,
  productTablePage,
  modal,
});
