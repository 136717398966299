import { LOCATION_CHANGE } from 'react-router-redux';

import { APP_VIEWER_MENU_IS_OPEN_SET } from '../actions/appViewerMenuIsOpenSet';
import { SESSION_CLEAR } from '../actions/sessionClear';

const initialState: boolean = false;

export default function appViewerMenuIsOpen(
  state: boolean = initialState,
  action: any,
) {
  switch (action.type) {
    case APP_VIEWER_MENU_IS_OPEN_SET:
      return action.payload;

    // When the router location changes, close the app viewer menu
    case LOCATION_CHANGE:
      return false;

    case SESSION_CLEAR:
      return false;

    default:
      return state;
  }
}
