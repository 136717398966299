import { makeStyles } from '@mui/styles';

import { COLORS } from '../../styles/theme';

const useStyles = makeStyles({
  label: {
    fontFamily: 'OpenSans',
    color: COLORS.MARINE,
    fontSize: 12,
    marginBottom: 0,
    marginLeft: 0,
  },
  container: {
    flex: 1,
  },
});

export const styles = {
  rows: {
    style: {
      fontSize: 12,
      fontFamily: 'OpenSans',
      color: COLORS.MARINE,
    },
  },
  headCells: {
    style: {
      fontFamily: 'AlphaHeadlinePro-Bold, sans-serif',
      fontSize: 12,
      color: COLORS.MARINE,
    },
  },
  pagination: {
    style: {
      color: COLORS.MARINE,
      fontSize: 13,
      minHeight: 56,
      borderTopWidth: 1,
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: 40,
      width: 40,
      padding: 8,
      cursor: 'pointer',
      color: COLORS.MARINE,
      fill: COLORS.MARINE,
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: COLORS.MARINE,
        fill: COLORS.MARINE,
      },
      '&:hover:not(:disabled)': {
        backgroundColor: COLORS.MARINE,
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: COLORS.MARINE,
      },
    },
  },
};

export default useStyles;
