import { gql } from '@apollo/client';

export default gql`
  query FlavorStructureQuery($reportID: UUID!, $productID: Int) {
    allRpFlavorStructures(
      condition: { reportId: $reportID, productId: $productID }
    ) {
      nodes {
        product: productByProductId {
          id
          name
        }
        members
        midpoint
        height
        edgeColor
        node
        parentNode
        label
        leaf
        nodeColor
      }
    }
  }
`;

