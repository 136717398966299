import * as React from 'react';
import { useEffect, useRef } from 'react';

import * as d3 from 'd3';

import {
  ProductVersion,
  ProductVersionSet,
} from 'components/Report/ProductVersion';
import { CustomerPreferences } from 'services/customerPreferences';

import { mapLabelVersion, simpleProductTooltip } from '../mapUtils';

interface ReportProps {
  customerPreferences: CustomerPreferences;
  productVersionSet: ProductVersionSet;
  reportTextureComposition: object[];
  reportTernaryData: reports.TextureTernaryHeatmapRow[];
  cluster_X: number;
  cluster_Y: number;
  cluster_Z: number;
  useTextureClusterName: boolean;
  tcNameData?: reports.TCNameData[];
}

const TextureTernaryPlot: React.FC<ReportProps> = (props) => {
  const {
    customerPreferences,
    productVersionSet,
    reportTextureComposition,
    reportTernaryData,
    cluster_X,
    cluster_Y,
    cluster_Z,
    useTextureClusterName,
    tcNameData,
  } = props;

  const getTCNameByIndex = (index: number) =>
    tcNameData?.find((x) => x.clusterIdx === index)?.clusterName;

  const [width, height] = [500, 500];

  const size: number = Math.min(width, height) / 2;
  const yOffset: number = (size - Math.sin((30 * Math.PI) / 180) * size) / 2;

  const [A, B, C] = [150, 30, -90].map((d) => [
    Math.cos((d * Math.PI) / 180) * size,
    Math.sin((d * Math.PI) / 180) * size + yOffset,
  ]);

  const line = function (pt1: number[], pt2: number[]) {
    return function (t: number) {
      return [pt1[0] + t * (pt2[0] - pt1[0]), pt1[1] + t * (pt2[1] - pt1[1])];
    };
  };

  const a = line(B, C);
  const b = line(C, A);
  const c = line(A, B);

  const labelOffset = 80;
  const [_A, _B, _C] = [150, 30, -90].map((d) => [
    Math.cos((d * Math.PI) / 180) * (size + labelOffset),
    Math.sin((d * Math.PI) / 180) * (size + labelOffset) + yOffset,
  ]);

  const _a = line(_B, _C);
  const _b = line(_C, _A);
  const _c = line(_A, _B);

  const ticks = d3.range(0, 100, 20).concat(100);
  const grid = d3.range(0, 1, 0.1);

  const ternaryProductData: reports.TernaryProductRow[] =
    reportTextureComposition
      .sort((a, b) => b['selected'] - a['selected'])
      .map((d, key) => {
        const scores = [d[cluster_X], d[cluster_Y], d[cluster_Z]];
        const tsum = scores[0] + scores[1] + scores[2];
        return {
          pos: [
            (A[0] * scores[2]) / tsum +
              (B[0] * scores[1]) / tsum +
              (C[0] * scores[0]) / tsum,
            (A[1] * scores[2]) / tsum +
              (B[1] * scores[1]) / tsum +
              (C[1] * scores[0]) / tsum,
          ],
          label: d['selected']
            ? mapLabelVersion(
                customerPreferences,
                productVersionSet,
                new ProductVersion(d['productId'], d['version']),
              )
            : null,
          name: d['productName'],
          productId: d['productId'],
          version: d['verison'],
        };
      });

  const colors = d3
    .scaleLinear<string>()
    .domain([-1, 0, 1])
    .range(['red', 'yellow', 'green'])
    .interpolate(d3.interpolateRgb);

  const clip_path = `M${[A[0] + width / 2, A[1] + height / 2]} L${[
    B[0] + width / 2,
    B[1] + height / 2,
  ]} L${[C[0] + width / 2, C[1] + height / 2]} Z`;

  const svgRef = useRef(null);

  useEffect(() => {
    // D3 Code

    // Selections
    const svg = d3
      .select(svgRef.current)
      .classed('ternary-plot-svg', true)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `0 0 ${width} ${height}`);

    // clear all previous content on refresh
    const everything = svg.selectAll('*');
    everything.remove();

    const container = svg
      .append('g')
      .classed('container', true)
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const tip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    container
      .selectAll('.triangle')
      .data(d3.group(reportTernaryData, (d) => d.idLabel))
      .enter()
      .append('path')
      .attr('d', (d) => {
        const pts = d[1].map((p) => [
          A[0] * p.lpoints + B[0] * p.rpoints + C[0] * p.tpoints,
          A[1] * p.rpoints + B[1] * p.lpoints + C[1] * p.tpoints,
        ]);
        return `M${pts[0]}L${pts[1]}L${pts[2]}Z`;
      })
      .attr('fill', (d) => colors(d[1][0].pq))
      .attr('fill-opacity', 0.8);

    const defs = container.append('defs');
    defs
      .append('clipPath')
      .attr('id', 'contourclip')
      .append('path')
      .attr('d', clip_path);

    const contours = d3
      .contourDensity<reports.TernaryProductRow>()
      .x((d) => d.pos[0] + width / 2)
      .y((d) => d.pos[1] + height / 2)
      .size([width, height])
      .bandwidth(30)
      .thresholds(10)(ternaryProductData);

    svg
      .append('g')
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .selectAll('path')
      .data(contours)
      .join('path')
      .attr('clip-path', 'url(#contourclip)')
      .attr('stroke-linejoin', 'round')
      .attr('d', d3.geoPath());

    // grid
    container
      .append('g')
      .selectAll('.grid')
      .data([
        grid.map((tick) => [a(tick), b(1 - tick)]),
        grid.map((tick) => [b(tick), c(1 - tick)]),
        grid.map((tick) => [c(tick), a(1 - tick)]),
      ])
      .enter()
      .append('g')
      .selectAll('.gridlines')
      .data((d) => d)
      .enter()
      .append('line')
      .attr('x1', (d) => d[0][0])
      .attr('y1', (d) => d[0][1])
      .attr('x2', (d) => d[1][0])
      .attr('y2', (d) => d[1][1])
      .attr('stroke', '#fff')
      .attr('stroke-width', (d, i) => (i & 1 ? 1 : 2));

    // ticks
    container
      .append('g')
      .attr('fill', 'black')
      .attr('font-size', 10)
      .selectAll('.axis')
      .data([
        ticks.map((tick) => ({
          tick,
          pos: a(tick / 100),
          rot: 0,
          anchor: 'start',
        })),
        ticks.map((tick) => ({
          tick,
          pos: b(tick / 100),
          rot: 60,
          anchor: 'end',
        })),
        ticks.map((tick) => ({
          tick,
          pos: c(tick / 100),
          rot: -60,
          anchor: 'end',
        })),
      ])
      .enter()
      .append('g')
      .selectAll('.ticks')
      .data((d) => d)
      .enter()
      .append('text')
      .attr('transform', (d) => `translate(${d.pos}) rotate(${d.rot})`)
      .attr('text-anchor', (d) => d.anchor)
      .attr('dx', (d) => 10 * (d.anchor === 'start' ? 1 : -1))
      .attr('dy', '.3em')
      .text((d) => d.tick);

    // label
    container
      .append('g')
      .attr('fill', 'black')
      .attr('font-size', 16)
      .selectAll('.labels')
      .data([
        {
          label: useTextureClusterName
            ? getTCNameByIndex(cluster_X) ?? `TC${cluster_X}`
            : `TC${cluster_X}`,
          pos: _a(0.5),
          rot: 60,
        },
        {
          label: useTextureClusterName
            ? getTCNameByIndex(cluster_Z) ?? `TC${cluster_Z}`
            : `TC${cluster_Z}`,
          pos: _b(0.5),
          rot: -60,
        },
        {
          label: useTextureClusterName
            ? getTCNameByIndex(cluster_Y) ?? `TC${cluster_Y}`
            : `TC${cluster_Y}`,
          pos: _c(0.5),
          rot: 0,
        },
      ])
      .enter()
      .append('text')
      .attr('transform', (d) => `translate(${d.pos}) rotate(${d.rot})`)
      .attr('text-anchor', 'middle')
      .text((d) => d.label);

    // products
    container
      .selectAll('.product')
      .data(ternaryProductData.filter((d) => d.label != null))
      .enter()
      .append('text')
      .attr('fill', 'black')
      .attr('transform', (d) => `translate(${d.pos})`)
      .attr('text-anchor', 'middle')
      .text((d) => d.label)
      .on('mouseenter', (d) =>
        tip
          .style('opacity', 1)
          .html(
            simpleProductTooltip(
              d.currentTarget.__data__.name,
              d.currentTarget.__data__.label,
            ),
          )
          .style('left', d.pageX - 25 + 'px')
          .style('top', d.pageY - 75 + 'px'),
      )
      .on('click', (d) => tip.style('opacity', 0))
      .on('mouseleave', (d) => tip.style('opacity', 0));
  });

  return (
    <div>
      <svg
        id="ternary-plot-svg"
        ref={svgRef}
        xmlns="http://www.w3.org/2000/svg"
      />
    </div>
  );
};

export default TextureTernaryPlot;
