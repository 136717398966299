import * as React from 'react';
import { useState, useRef } from 'react';

import PhotoCameraRounded from '@mui/icons-material/PhotoCameraRounded';
import { useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  ActualFileObject,
  FilePondErrorDescription,
  FilePondFile,
  FilePondInitialFile,
} from 'filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransfrom from 'filepond-plugin-image-transform';
import { Camera } from 'react-camera-pro';
import { FilePond, registerPlugin } from 'react-filepond';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';
import MaterialButton from '../MaterialButton';

interface Images {
  isReady: boolean;
  toBeAdded: FilePondFile[];
  toBeRemoved: any[];
}

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageResize,
  FilePondPluginImageTransfrom,
);

// Converts image dataURL to JavaScript File object
// Name of file object will be epoch date/time the file is created
const dataURLtoFile = (dataurl: string): File => {
  const date = new Date();
  const arr = dataurl.split(',');
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], date.getTime().toString(), { type: 'image/jpeg' });
};

interface Props {
  defaultImages: {
    id: number;
    url: string;
  }[];
  input: any;
}

const FieldImageInputA: React.FC<Props> = (props) => {
  const { defaultImages, input } = props;

  const classes = useStyles(useTheme());

  const { t } = useTranslation();

  const filePondRef = useRef(null);
  const webcamRef = useRef(null);

  const [useWebcam, setUseWebcam] = useState<boolean>(false);
  const [files, setFiles] = useState<
    (FilePondInitialFile | ActualFileObject)[]
  >(
    defaultImages && defaultImages.length
      ? defaultImages.map((image) => ({
          source: image.url,
          options: { type: 'local' },
        }))
      : [],
  );
  const [currentFiles, setCurrentFiles] = useState<Images>({
    isReady: true,
    toBeAdded: new Array<FilePondFile>(),
    toBeRemoved: [],
  });
  const [numberOfCameras, setNumberOfCameras] = useState(0);

  // Does not support multiple file upload for now
  const handleFilesUpdate = (updatedFiles: Images) => {
    input.onChange({
      ...updatedFiles,
      isReady: updatedFiles.toBeAdded
        .map((file) => !!file.getFileEncodeBase64String())
        .reduce((a, b) => a && b, true),
    });
  };

  const openWebcamDialog = () => setUseWebcam(true);
  const closeWebcamDialog = () => setUseWebcam(false);

  const takePictureOnClick = (filePondRef, webcamRef) =>
    filePondRef.current.addFile(dataURLtoFile(webcamRef.current.takePhoto()));

  return (
    <div>
      <MaterialButton onClick={openWebcamDialog}>
        Use Webcam
        <PhotoCameraRounded style={{ marginLeft: 5 }} />
      </MaterialButton>
      <text className={classes.label}>OR</text>
      <FilePond
        ref={filePondRef}
        files={files}
        allowFileEncode
        server={{
          // Setting this to null as we are not directly uploading the files using filepond
          process: null,
          // Overwriting default load function to enable loading of images via url
          load: (url, load, error) => {
            // Add a dummy query string for CORS to work (see: https://github.com/pqina/filepond/issues/371)
            fetch(`${url}?response`)
              .then((res) => res.blob())
              .then(load)
              .catch(error);
          },
        }}
        instantUpload={false}
        labelIdle={t('product.imagePlaceholder')}
        allowMultiple
        allowFileTypeValidation
        allowFileSizeValidation={false}
        acceptedFileTypes={['image/jpg', 'image/jpeg', 'image/png']}
        onremovefile={(error: FilePondErrorDescription, file: FilePondFile) => {
          // Only existing files that are already uploaded should be pushed into this array
          if (file && file.file && file.file.constructor === Blob) {
            const imageId = defaultImages.find(
              (image) => image.url === file.serverId,
            ).id;
            currentFiles.toBeRemoved.push({ file, imageId });
          }
        }}
        onupdatefiles={(fileItems) => {
          setFiles(fileItems.map((fileItem) => fileItem.file));
          setUseWebcam(false);
          setCurrentFiles({
            ...currentFiles,
            toBeAdded: fileItems.filter(
              (fileItem) => fileItem.file instanceof File,
            ),
          });
          handleFilesUpdate({
            ...currentFiles,
            toBeAdded: fileItems.filter(
              (fileItem) => fileItem.file instanceof File,
            ),
          });
        }}
        imageResizeMode={'contain'}
        maxFileSize="100MB"
      />
      <Dialog maxWidth="xl" open={useWebcam} onClose={closeWebcamDialog}>
        <DialogContent style={{ minWidth: '80vh' }}>
          <Camera
            ref={webcamRef}
            numberOfCamerasCallback={setNumberOfCameras}
            aspectRatio={1}
            errorMessages={{
              noCameraAccessible:
                'No camera device accessible. Please connect your camera or try a different browser.',
              permissionDenied:
                'Permission denied. Please refresh and give camera permission.',
              switchCamera:
                'It is not possible to switch camera to different one because there is only one video device accessible.',
              canvas: 'Canvas is not supported.',
            }}
          />
        </DialogContent>
        <DialogActions>
          {numberOfCameras > 1 && (
            <MaterialButton
              color="secondary"
              onClick={() => {
                webcamRef.current.switchCamera();
              }}
            >
              Switch Cameras
            </MaterialButton>
          )}
          <MaterialButton
            color="secondary"
            onClick={() => takePictureOnClick(filePondRef, webcamRef)}
          >
            Take Picture
          </MaterialButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FieldImageInputA;
