import { mapFromKey } from 'constants/ggVars';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import FpDecompByReportIdAndProductIDQuery from '@graphql/queries/FpDecompByReportIdAndProductIDQuery';
import { Box } from '@mui/material';
import {
  ProductVersion,
  ProductVersionSet,
} from 'components/Report/ProductVersion';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { groupBy, keys } from 'lodash';
import { Data } from 'react-csv/lib/core';

import { getProductName } from '../../utils';
import DataDownloadTile from '../DataDownloadTile';
import { TileType } from '../DataDownloadTile/DataDownloadTile';

interface Props {
  projectId: number;
  reportId: string;
  products: ProductVersionSet;
}

const AllDecompsDataDownload: React.FC<Props> = (props) => {
  const { projectId, reportId, products } = props;

  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const { loading, error, data } = useQuery<reports.ReportFpDecompResponse>(
    FpDecompByReportIdAndProductIDQuery,
    { variables: { reportID: reportId } },
  );

  function sortByGgVarRefFlavor(
    a: reports.ReportFpDecompRow,
    b: reports.ReportFpDecompRow,
  ): number {
    if (mapFromKey(a.ggVars).idx < mapFromKey(b.ggVars).idx) {
      return -1;
    } else if (mapFromKey(a.ggVars).idx > mapFromKey(b.ggVars).idx) {
      return 1;
    } else {
      return a.decomp > b.decomp ? -1 : 1;
    }
  }

  const getCsvHeader = () => [
    { label: 'Product', key: 'product' },
    { label: 'GG Var', key: 'ggVars' },
    { label: 'Reference Flavor', key: 'referenceFlavor' },
    { label: 'Decomp', key: 'decomp' },
    { label: 'Abs Decomp', key: 'absDecomp' },
  ];

  const getCsvRows = (data: reports.ReportFpDecompResponse): Data => {
    if (data.allRpFlavorDecomps.nodes.length < 1) {
      return [];
    } else {
      const products = groupBy(
        data.allRpFlavorDecomps.nodes.map((n) => ({
          ...n,
          productVersion: new ProductVersion(
            n.productByProductId.id,
            n.version,
          ),
        })),
        (n) => n.productVersion.value,
      );

      const rows = [];
      keys(products).forEach((p) => {
        const productDecomps: reports.ReportFpDecompRow[] = products[p];
        const sorted: reports.ReportFpDecompRow[] = productDecomps.sort(
          (a, b) => sortByGgVarRefFlavor(a, b),
        );
        sorted.forEach((r) => {
          const row = [];

          getCsvHeader().forEach((h) => {
            if (h.key === 'product') {
              row.push(
                getProductName({
                  productNames,
                  productId: r.productByProductId.id,
                  version: r.version,
                }),
              );
            } else {
              const item = r;
              const value = item[h.key];
              row.push(value);
            }
          });

          rows.push(row);
        });
      });
      return rows;
    }
  };

  if (loading || error || getCsvRows(data).length === 0) {
    return <Box />;
  }

  return (
    <DataDownloadTile
      tileType={TileType.CSV}
      headers={getCsvHeader().map((h) => h.label)}
      csvDataRows={getCsvRows(data)}
      fileName="AllDecomps.csv"
      tileName="All Decomps"
    />
  );
};

export default AllDecompsDataDownload;
