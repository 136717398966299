import { gql } from '@apollo/client';

export interface JobParamsResponse extends reports.ReportGraphQLReponse {
  reportJobByReportId: {
    params: JSONValue;
  };
}

export default gql`
  query jobParamsQuery($reportId: UUID!) {
    reportJobByReportId(reportId: $reportId) {
      params
    }
  }
`;
