import { gql } from '@apollo/client';

export default gql`
  query GastrographRadarDownloadQuery($reportID: UUID!) {
    allRpOfps(condition: { reportId: $reportID, ofpType: GGVAR }) {
      nodes {
        product: productByProductId {
          id
          name
        }
        ofpType
        attribute
        intensity
        version
      }
    }
  }
`;
