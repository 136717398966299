import * as React from 'react';
import { useEffect } from 'react';

import * as d3 from 'd3';

import NotifyLiftAndShift from '@graphql/mutations/NotifyLiftAndShift';
import { Grid, IconButton } from '@mui/material';
import SvgFileIcon from 'components/Icons/DownloadIcons/SvgFileIcon';
import { downloadSVG } from 'components/Report/utils';
import graphqlClient from 'consumers/graphqlClient';
import * as d3Selection from 'd3-selection';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  heatMapColors,
  useCustomerPreferences,
} from 'services/customerPreferences';

import { continents } from './continents';
import {
  appendHashPattern,
  availableCountry,
  countryHoverText,
  countryPqColor,
  extractContinents,
  missingAvailableCountry,
} from './mapUtils';
import { Legend } from './ProjectMapLegend';
import './projectmap.tooltip.css';
import { CountryMetadata } from '../../constants/country';
import selectViewerUserId from '../../selectors/viewerUserId';

const d3cm = require('d3-context-menu');

const geoJson = require('../../../public/assets/geojson/world.geojson');

const baseOpacity = 0.8;

interface Props {
  projectId: number;
  workspaceId: number;
  reportId: string;
  availableCountries: Set<CountryMetadata>;
  mapData: reports.MapDataRow[];
  minPq: number;
  meanPq: number;
  maxPq: number;
}

const ProjectMap: React.FC<Props> = (props) => {
  const {
    projectId,
    workspaceId,
    reportId,
    availableCountries,
    mapData,
    minPq,
    meanPq,
    maxPq,
  } = props;

  const viewerUserId = useSelector((state) => selectViewerUserId(state));

  const customerPreferences = useCustomerPreferences();

  const svgRef = React.useRef(null);

  const [width, height] = [1600, 1000];
  const btnRadius = 25;

  const colors: d3.ScaleLinear<string, string, never> = heatMapColors(
    customerPreferences,
    1,
    minPq,
    meanPq,
    maxPq,
  );

  const notifyLiftShiftRequest = async (
    userId: number,
    projectId: number,
    workspaceId: number,
    reportId: string,
    country: string,
    createdAt: moment.Moment,
  ) => {
    await graphqlClient.mutate({
      mutation: NotifyLiftAndShift,
      variables: {
        input: {
          userId: userId,
          projectId: projectId,
          workspaceId: workspaceId,
          reportId: reportId,
          country: country,
          created_at: createdAt,
        },
      },
    });
  };

  useEffect(() => {
    const svg = d3
      .select(svgRef.current)
      .attr('viewBox', `0 0 ${width} ${height}`);

    const everything = svg.selectAll('*');
    everything.remove();
    d3.select('#world-map-legend').remove();

    const mapMouseOver = (event: MouseEvent, d: GeoJSON.Feature) => {
      if (availableCountry(availableCountries, d)) {
        d3Selection
          .selectAll<SVGPathElement, any>('.Country')
          .style('opacity', 0.5);
        d3Selection.select<SVGPathElement, any>('#' + d.id).style('opacity', 1);

        d3.select('#projectmap-tooltip')
          .style('opacity', 1)
          .style('left', event.offsetX + 10 + 'px')
          .style('top', event.offsetY - 10 + 'px')
          .text(countryHoverText(availableCountries, mapData, d));
      }
    };

    const mapMouseLeave = (event: any, d: GeoJSON.Feature) => {
      d3Selection
        .selectAll<SVGPathElement, any>('.Country')
        .style('opacity', baseOpacity);

      d3Selection
        .select<SVGPathElement, any>('#projectmap-tooltip')
        .style('opacity', 0);
    };

    const menu = [
      {
        title: 'Request Lift & Shift',
        action: function (d, event) {
          const data = event.target.__data__ as GeoJSON.Feature;
          const country = data.properties.name;
          notifyLiftShiftRequest(
            viewerUserId,
            +projectId,
            workspaceId,
            reportId,
            country,
            moment(),
          );
          event.preventDefault();
        },
      },
    ];

    const projection: d3.GeoProjection = d3
      .geoNaturalEarth1()
      .scale(320)
      .translate([width / 2, height / 2]);

    d3.json(geoJson).then((data: GeoJSON.FeatureCollection) => {
      appendHashPattern(svg, 10, '#999999');

      const mapFeatures = svg
        .append('g')
        .selectAll('path')
        .data(data.features)
        .join('path')
        .attr('fill', (d: GeoJSON.Feature) =>
          countryPqColor(availableCountries, mapData, d, colors, meanPq),
        )
        .attr('d', d3.geoPath().projection(projection))
        .style('stroke', '#fff')
        .style('stroke', 'transparent')
        .attr('class', (d: GeoJSON.Feature) => `Country ${d.id}`)
        .attr('id', (d) => d.id)
        .style('opacity', baseOpacity)
        .style('stroke', 'black')
        .on('mouseover', mapMouseOver)
        .on('mouseleave', mapMouseLeave);

      svg
        .selectAll('path')
        .data(data.features)
        .append('div')
        .text((d: GeoJSON.Feature) => d.properties.name);

      // Tooltip
      d3.select('#world-map')
        .append('div')
        .attr('id', 'projectmap-tooltip')
        .style('position', 'absolute')
        .style('opacity', 0);
      // Legend
      d3.select('#world-map')
        .append(() =>
          Legend(
            colors,
            [minPq, meanPq, maxPq],
            [
              Math.min(...mapData.flatMap((x) => +x.pq)),
              Math.max(...mapData.flatMap((x) => +x.pq)),
            ],
            {
              title: 'PQ',
            },
          ),
        )
        .attr('id', 'world-map-legend')
        .style('margin', '0 auto');

      const navButtons = svg
        .append('g')
        .selectAll('g')
        .data(continents)
        .join('g')
        .attr(
          'transform',
          (d, i) =>
            `translate(${width - btnRadius * 2 - 5} ${
              (i + 0.5) * btnRadius * 2.4 + 5
            })`,
        );

      navButtons
        .append('circle')
        .attr('r', btnRadius * 1.2)
        .attr('cx', btnRadius)
        .attr('cy', btnRadius)
        .attr('fill', 'whitesmoke')
        .attr('id', 'world-map-nav-button');

      navButtons
        .append('image')
        .attr('xlink:href', (d) => d.imgfile)
        .attr('width', btnRadius * 2)
        .attr('height', btnRadius * 2)
        .attr('cursor', 'pointer')
        .on('click', (event, d) =>
          mapFeatures.attr(
            'transform',
            `translate(${d.transform.x} ${d.transform.y}) scale(${d.transform.k})`,
          ),
        );

      Array.from(availableCountries)
        .filter((ac) => missingAvailableCountry(ac, mapData))
        .forEach((d) => {
          d3.select(`.${d.geojsonId}`).on('contextmenu', d3cm(menu));
        });

      const zoom = d3
        .zoom<SVGSVGElement, unknown>()
        .scaleExtent([1, 4])
        .translateExtent([
          [0, 0],
          [width, height],
        ]) // Restricts panning
        .on('zoom', (event) => {
          mapFeatures.attr('transform', event.transform);
        });
      svg.call(zoom);

      // Auto zoom to continent if there is only one
      const projectContinents = extractContinents(availableCountries, mapData);
      if (projectContinents.length === 1) {
        const c = continents.find((c) => c.code === projectContinents[0]);
        mapFeatures.attr(
          'transform',
          `translate(${c.transform.x} ${c.transform.y}) scale(${c.transform.k})`,
        );
      }
    });
  }, [mapData]);

  const divStyle: React.CSSProperties = {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    verticalAlign: 'top',
    overflow: 'hidden',
  };

  if (mapData.length == 0) {
    return <div />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <div id="world-map" className="world-map" style={divStyle}>
          <svg
            id="world-map-svg"
            ref={svgRef}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/2000/xlink"
          />
        </div>
      </Grid>
      <Grid container justifyContent="flex-end">
        <IconButton
          id="svg-link"
          size="small"
          onClick={(e) => {
            const svg = d3.select(svgRef.current);
            const navbuttons = svg.selectAll('#world-map-nav-button');
            // remove gray circles from download by turning them white
            navbuttons.attr('fill', 'transparent');
            downloadSVG(e, 'world-map-svg');
            // return circles to original color
            navbuttons.attr('fill', 'whitesmoke');
          }}
        >
          <SvgFileIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ProjectMap;
