import * as React from 'react';

interface Props {
  size: number;
}

function IconFolderZip(props: Props) {
  return (
    <div style={{ height: props.size }}>
      <svg
        clipRule="evenodd"
        fillRule="evenodd"
        height={props.size}
        strokeLinejoin="round"
        strokeMiterlimit="2"
        viewBox="0 0 267 267"
        width={props.size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="m50 154.167c56.944 9.876 113.889 9.544 170.833 0v-79.167c0-.902-.292-1.779-.833-2.5l-37.5-50c-.787-1.049-2.022-1.667-3.333-1.667h-116.667c-3.315 0-6.495 1.317-8.839 3.661-2.344 2.345-3.661 5.524-3.661 8.839v95.834c0 6.188-1.451 11.433-1.451 15.614 0 5.78 1.451 9.386 1.451 9.386z"
            fill="#d8dfe3"
          />
          <path
            d="m25 143.365v89.968c0 3.315 1.317 6.495 3.661 8.839s5.524 3.661 8.839 3.661h191.667c3.315 0 6.494-1.317 8.839-3.661 2.344-2.344 3.661-5.524 3.661-8.839 0-15.966 0-50.7 0-66.666 0-3.315-1.317-6.495-3.661-8.839-2.345-2.344-5.524-3.661-8.839-3.661h-187.5c-5.316 0-16.667-10.802-16.667-10.802z"
            fill="#2d98db"
          />
          <path
            d="m37.5 154.167c-3.804 0-6.581-1.543-8.625-3.443-1.923-1.788-3.875-4.939-3.875-9.057 0-2.643 1.317-6.495 3.661-8.839s5.524-3.661 8.839-3.661h12.5v25s1.071 0-12.5 0z"
            fill="#216896"
          />
          <path
            d="m179.167 20.833v41.667c0 3.315 1.317 6.495 3.661 8.839s5.523 3.661 8.839 3.661h29.166c0-.902-.292-1.779-.833-2.5l-37.5-50c-.787-1.049-2.022-1.667-3.333-1.667z"
            fill="#afbdc7"
          />
          <g fill="#d8dfe3">
            <path d="m129.167 179.167v41.666c0 2.3 1.867 4.167 4.166 4.167 2.3 0 4.167-1.867 4.167-4.167v-41.666c0-2.3-1.867-4.167-4.167-4.167-2.299 0-4.166 1.867-4.166 4.167z" />
            <path d="m154.167 206.771h18.186c7.215 0 13.064-5.849 13.064-13.064v-5.643c0-7.215-5.849-13.064-13.064-13.064-9.238 0-22.353 0-22.353 0-2.301 0-4.167 1.865-4.167 4.167v41.666c0 2.3 1.867 4.167 4.167 4.167s4.167-1.867 4.167-4.167zm0-8.334h18.186c2.612 0 4.73-2.117 4.73-4.73v-5.643c0-2.613-2.118-4.731-4.73-4.731h-18.186z" />
            <path d="m87.5 183.333h22.917s-26.25 35-26.25 35c-.947 1.263-1.1 2.952-.394 4.364.706 1.411 2.149 2.303 3.727 2.303h31.25c2.3 0 4.167-1.867 4.167-4.167 0-2.299-1.867-4.166-4.167-4.166h-22.917s26.25-35 26.25-35c.947-1.263 1.1-2.952.394-4.364-.706-1.411-2.149-2.303-3.727-2.303h-31.25c-2.3 0-4.167 1.867-4.167 4.167 0 2.299 1.867 4.166 4.167 4.166z" />
          </g>
          <path
            d="m116.667 110.972v-16.537-.005c0-4.048 1.608-7.931 4.47-10.793s6.745-4.47 10.792-4.47h2.808c4.048 0 7.93 1.608 10.793 4.47 2.862 2.862 4.47 6.745 4.47 10.793v16.542c1.456-.716 2.798-1.67 3.967-2.839 2.796-2.795 4.366-6.587 4.366-10.541 0-.059 0-.118 0-.176 0-3.478.427-6.942 1.27-10.316l.001-.002c2.092-8.369-2.612-16.937-10.795-19.665-.656-.219-1.318-.439-1.979-.66-8.761-2.92-18.232-2.92-26.993 0-.661.221-1.323.441-1.979.66-8.184 2.728-12.887 11.296-10.795 19.665v.002c.844 3.374 1.27 6.838 1.27 10.316v.176c0 3.954 1.571 7.746 4.367 10.541 1.168 1.169 2.511 2.123 3.967 2.839z"
            fill="#2d98db"
          />
          <path
            d="m116.383 59.141.819-.273c10.471-3.491 21.792-3.491 32.263 0l.819.273c.913-10.935 4.017-27.318 14.003-38.308h-61.907c9.985 10.99 13.089 27.373 14.003 38.308z"
            fill="#2d98db"
          />
          <path
            d="m141.667 94.429v17.658c0 .275.027.548.081.818 0 0 1.635 8.178 1.635 8.178.602 3.009-.177 6.129-2.122 8.502s-4.852 3.748-7.92 3.748c-.005 0-.01 0-.015 0-3.068 0-5.975-1.375-7.92-3.748s-2.724-5.493-2.123-8.502l1.636-8.176s.081-.82.081-.82v-17.658c0-1.837.73-3.6 2.03-4.899 1.299-1.3 3.062-2.03 4.899-2.03h2.808c1.838 0 3.601.73 4.9 2.03 1.3 1.299 2.03 3.062 2.03 4.899z"
            fill="#2d98db"
          />
        </g>
      </svg>
    </div>
  );
}

export default IconFolderZip;
