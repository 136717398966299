import { useState, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';

import { ClientListOptions } from './useClientList';

interface ClientsByPartnersResults {
  allUserPartners: {
    nodes: {
      partnerByPartnerId: {
        id: number;
        name: string;
        clientsByPartnerId: {
          nodes: {
            id: number;
            name: string;
          }[];
        };
      };
    }[];
  };
}

const clientPartnersQuery = gql`
  query getClientsByUserPartner($userId: Int!) {
    allUserPartners(condition: { userId: $userId }) {
      nodes {
        partnerByPartnerId {
          id
          name
          clientsByPartnerId {
            nodes {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const useClientPartnerListByUser = (userId: string): ClientListOptions[] => {
  const [clientNames, setClientNames] = useState<ClientListOptions[]>([]);
  const { data, loading, error } = useQuery<ClientsByPartnersResults>(
    clientPartnersQuery,
    { variables: { userId: userId } },
  );

  useEffect(() => {
    if (!loading && !error && data) {
      const names = data.allUserPartners.nodes.flatMap((p) =>
        p.partnerByPartnerId.clientsByPartnerId.nodes.map((c) => ({
          label: c.name,
          value: c.name,
          partnerId: p.partnerByPartnerId.id,
        })),
      );
      setClientNames(names);
    }
  }, [data, loading, error]);

  return clientNames;
};

export default useClientPartnerListByUser;
