import { Theme } from '@mui/material';
import { TableStyles } from 'react-data-table-component';

/**
 *
 * @param theme
 * @param compat make compatiable with former react-bootstrap-table2 with fonts
 * @returns
 */
export const tableCustomStyles = (
  theme: Theme,
  compat: boolean = false,
): TableStyles => {
  return {
    rows: {
      style: {
        fontSize: '12px',
        fontFamily: 'OpenSans, sans-serif',
        color: theme.palette.primary.main,
        backgroundColor: 'white',
      },
      stripedStyle: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.tableRow.main,
      },
    },
    header: {
      style: {
        minHeight: '16px',
      },
    },
    headCells: {
      style: {
        fontFamily: compat
          ? 'AlphaHeadlinePro-Bold, sans-serif'
          : 'Roboto, Helvetica, Arial, sans-serif;',
        fontSize: '12px',
        fontWeight: 700,
        borderTop: '1px solid #dee2e6',
        color: theme.palette.primary.main,
      },
    },
  };
};
