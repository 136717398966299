import React, { useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Modal } from '@mui/material';
import { get } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import MaterialButton from '../../../components/MaterialButton';
import MultiProductSearchQuery, {
  MultiProductSearchQueryType,
  ProductSearchQueryProduct,
} from '../../../graphql/queries/MultiProductSearchQuery';
import styles from '../../RequestReport/RequestReport.module.css';

export interface Props {
  open: boolean;
  handleClose: () => any;
  returnProducts: (products: FormattedProduct[]) => any;
}

const ProductPasteModal: React.FC<Props> = ({
  open,
  handleClose,
  returnProducts,
}) => {
  const { t } = useTranslation();

  const [pastedNames, setPastedNames] = useState<string>('');
  const [productSearchNames, setProductSearchNames] = useState<string[]>(null);
  const [productValidationResult, setValidationResult] = useState<string>(null);
  const [productsToAdd, setProductsToAdd] = useState<FormattedProduct[]>(null);

  const formatProducts = (
    products: ProductSearchQueryProduct[],
  ): FormattedProduct[] => {
    return products.map((product) => ({
      // duplicates getProductData function
      id: product.id,
      image:
        get(product, 'productImages.totalCount') > 0 &&
        product.productImages.nodes[0].url,
      name: product.name,
      brand: product.brand,
      date: moment(product.createdAt).format('ll'),
      reviews: get(product, 'productReviews.totalCount'),
      lastReview:
        get(product, 'productReviews.nodes.length') > 0
          ? moment(product.productReviews.nodes[0].createdAt).format('ll')
          : '',
      category: get(product, 'category'),
      workspace: product.producer.name,
      productWorkspaceId: product.producer.id,
      productWorkspaceName: product.producer.name,
    }));
  };

  const formatProductName = (postgresName: string) =>
    postgresName
      .trim()
      .replace(/\s\s+/g, ' ')
      .replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      });

  const [searchProductNames] = useLazyQuery<MultiProductSearchQueryType>(
    MultiProductSearchQuery,
    {
      onCompleted: (data: MultiProductSearchQueryType) => {
        const validNames = data.allProducts.nodes.map((d) =>
          formatProductName(d.name),
        );
        const invalidNames = productSearchNames.filter(
          (d) => !validNames.includes(d),
        );
        if (invalidNames.length > 0) {
          setValidationResult(
            ['Invalid products:'].concat(invalidNames).join('\n'),
          );
          return;
        }
        const prodMaxReviews = {};
        data.allProducts.nodes.forEach((d) => {
          const numreviews = get(d, 'productReviews.totalCount');
          if (!prodMaxReviews[d.name]) {
            prodMaxReviews[d.name] = numreviews;
          } else if (numreviews > prodMaxReviews[d.name]) {
            prodMaxReviews[d.name] = numreviews;
          }
        });
        const lowdataProducts = productSearchNames.filter(
          (d) => prodMaxReviews[d] < 5,
        );
        if (lowdataProducts.length > 0) {
          setValidationResult(
            ['Products have insufficient data:']
              .concat(lowdataProducts)
              .join('\n'),
          );
          return;
        }
        const nocatProducts = validNames.filter(
          (d) =>
            data.allProducts.nodes.filter(
              (p) => p.name == d && !!get(p, 'category'),
            ).length == 0,
        );
        if (nocatProducts.length > 0) {
          setValidationResult(
            ['Products have no category:']
              .concat(Array.from(new Set(nocatProducts)))
              .join('\n'),
          );
          return;
        }
        const allworkspaceProducts = formatProducts(data.allProducts.nodes);
        setProductsToAdd(allworkspaceProducts);
        setValidationResult('All products are valid ✅');
      },
    },
  );

  const updateProductNames = (input: string): void => {
    setPastedNames(input);
    setProductSearchNames(input.split('\n'));
    searchProductNames({
      variables: { names: input.split('\n') },
    });
  };

  return (
    <Modal open={open} className={styles.folderModal}>
      <div className={styles.modalContainer}>
        <textarea
          rows={8}
          placeholder="Paste one product per line"
          value={pastedNames}
          onChange={(e) => {
            updateProductNames(e.target.value);
          }}
        />
        <textarea
          rows={8}
          placeholder={
            productValidationResult ?? 'Enter products above to validate'
          }
          readOnly
        />
        <div className={styles.buttonContainer}>
          <MaterialButton
            variant="outlined"
            soft
            onClick={() =>
              updateProductNames(
                pastedNames
                  .split('\n')
                  .map((d) => formatProductName(d))
                  .join('\n'),
              )
            }
          >
            Repair Names
          </MaterialButton>
          <MaterialButton variant="outlined" soft onClick={() => handleClose()}>
            {t('general.cancel')}
          </MaterialButton>
          <MaterialButton
            variant="outlined"
            soft
            teal
            disabled={productValidationResult != 'All products are valid ✅'}
            onClick={() => returnProducts(productsToAdd)}
          >
            Add Products
          </MaterialButton>
        </div>
      </div>
    </Modal>
  );
};

export default ProductPasteModal;
