import React from 'react';

import { useTheme } from '@mui/material';
import { Redirect, Route, Switch } from 'react-router-dom';

import useStyles from './useStyles';
import { AUTHENTICATION } from '../../constants/routePaths';
import AuthenticationContainer from '../Authentication';

const renderAnonymousRoutes = () => (
  <Switch>
    <Route path={AUTHENTICATION} component={AuthenticationContainer} />

    <Route render={() => <Redirect to={AUTHENTICATION} />} />
  </Switch>
);

const AppNavigatorAnonymousContainer = () => {
  const classes = useStyles(useTheme());
  return <div className={classes.container}>{renderAnonymousRoutes()}</div>;
};

AppNavigatorAnonymousContainer.displayName = 'AppNavigatorAnonymousContainer';

export default AppNavigatorAnonymousContainer;
