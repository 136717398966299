const en = {
  translation: {
    button: {
      submit: 'Submit',
    },
    partner: {
      picker: 'Partner',
    },
    navigation: {
      products: 'Products',
      panels: 'Panels',
      reports: 'Reports',
      users: 'Users',
      projects: 'Projects',
      devices: 'Manage Devices',
      adminTools: 'Admin Tools',
      editReviews: 'Edit Reviews',
      reportsQa: 'Reports QA',
      reportDashboard: 'Report Dashboard',
      customLexicons: 'Custom Lexicons',
      modelPlayground: 'Model Playground',
      notification: 'Notification',
    },
    product: {
      aroma: 'Aroma',
      gallery: 'Gallery',
      next: 'Next',
      productFilter: 'Product Filter',
      createProduct: 'Create Product',
      createProductQuick: 'Quick Create Product',
      createFolder: 'Create Folder',
      createProductHeader: 'Create A Product',
      createProductsBulk: 'Bulk Create Products',
      importCsv: 'Import CSV File',
      editProductHeader: 'Edit Product',
      productFolder: 'Product Folders',
      allProducts: 'All Products',
      productInfo: 'Product Info',
      productImage: 'Product Image',
      productName: 'Product Name',
      productLocalName: 'Local Product Name',
      productBrand: 'Product Brand',
      productClass: 'Product Class',
      productTag: 'Product Tags',
      productFeature: 'Product Features',
      productComponentBase: 'Product Component base',
      productComponentOther: 'Product Component other',
      productCategory: 'Product Category',
      productId: 'Product ID',
      panels: 'Panels',
      products: 'Products',
      productSecurity: 'Product Security',
      texture: 'Product has texture components',
      textureComponentOptions: [
        { value: 'true', label: 'Defined components' },
        { value: 'false', label: 'Undefined components' },
      ],
      undefinedComponentTotal: 'Set number of undefined components',
      componentName: 'Component Name ',
      component: 'Component ',
      allowCustomTextureComponents: 'Allow panelists to create new components',
      public: 'Public',
      publicProduct: 'Public Product',
      publicHelpText:
        'If you select public, people outside your workspace can review your product.',
      aromaHelpText:
        'If you select aroma, people will also review the aroma of product.',
      allergenInfo: 'Allergen Information',
      allergenInfoPlaceholder: 'Add allergen information',
      dietaryRestrictions: 'Dietary Restrictions',
      dietaryRestrictionsPlaceholder: 'Add dietary restriction info',
      contains: 'Contains',
      certifiedSafe: 'Certified Safe For',
      certifiedSafePlaceholder: 'Add certified safe for',
      servingVessel: 'Serving Vessel',
      servingVesselPlaceholder: 'Add serving vessel',
      physicalState: 'Physical State of Product',
      physicalStatePlaceholder: 'Add physical state of product',
      ingredients: 'Ingredients',
      ingredientsPlaceholder:
        'Please enter the ingredients separated by commas',
      countryOfOrigin: 'Country of Origin',
      countryOfPurchase: 'Country of Purchase',
      countryPlaceholder: 'Type country or choose one',
      productClassAttribute: 'Product Class Attribute',
      productOption: 'Product Option',
      prototype: 'Product is a prototype',
      prototypeProduct: 'Prototype',
      attributes: 'Attributes',
      textAttributes: 'Text Attributes',
      binaryAttributes: 'Binary Attributes',
      properties: 'Properties',
      productProperties: 'Product Properties',
      nutritionalInfo: 'Nutritional Information',
      nutritionalInfoImage: 'Nutritional Info Images',
      addToPanel: 'Panels',
      addToPanelPlaceholder: 'Add to existing panel',
      defaultAttributes: 'Default Attributes',
      imagePlaceholder: 'Drag and drop your image here',
      searchUsdaProduct: 'Search Product from USDA Database',
      usdaDisclaimer:
        '*not all data might be provided by the USDA database and some data' +
        'might be incorrect or incosistent. Please refer to the product nutritional panel and ingredient list for accurate entries',
      productCategoryDesc:
        'A category indicates a class of products with shared characteristics and similar compositions. Common categories of CPG products are chips, sauce, RTD coffee, sparkling water, etc. For example, the category of a "Cold Brew Coffee with Almond Milk" should be coffee.',
      productFeatureDesc:
        'Features are health claims, processing techniques, and specific varieties of a product. Common examples may be organic, fat-free, ultra-filtered, honeycrisp, etc. For example, the feature of a "Cold Brew Coffee with Almond Milk" should be cold brew.',
      productComponentBaseDesc:
        'Components describe a product’s compositions and flavors. Component base should be the major compositions of the product. For example, the component base of a "Cold Brew Coffee with Almond Milk" should be coffee.',
      productComponentOtherDesc:
        'Component others are minor compositions and flavors in the product. For example, the component others of a "Cold Brew Coffee with Almond Milk" should be almond milk.',
      createProductSteps: [
        'Mandatory Info',
        'Product Attributes',
        'Images',
        'Behavior Questions',
      ],
      required: 'Required',
      openFilter: 'Open',
      closeFilter: 'Close',
      filterNameLabel: 'Enter Filter Name',
      saveFilter: 'Save Filter',
      productsFound: '{{count}} Products Found',
    },
    panel: {
      expirationDate: 'Expiration',
      productionDate: 'Production',
      servingVessel: 'Serving Vessel',
      projectName: 'Project Name',
      clientName: 'Client Name',
      totalCost: 'Total Cost',
      createPanel: 'Create A Panel',
      searchProduct: 'Search Product',
      searchAddProduct: 'Start Typing to Search & Add Products',
      productNotFound: 'No Matching Products Found',
      dateTime: 'Date & Time',
      setPanelTime: 'Set Panel Time',
      startDate: 'Start Date',
      endDate: 'End Date',
      configurations: 'Configurations',
      panelName: 'Panel Name',
      optional: 'Optional',
      blindPanel: 'Blind Panel',
      hideReviewHistory: 'Hide products and reviews in history',
      publicPanel: 'Allow any user with PIN to join the panel',
      updatePanel: 'Update Panel',
      beginPanel: 'Begin Panel',
      schedulePanel: 'Schedule Panel',
      blind: 'Blind',
      mandatoryTexture: 'Make texture mandatory for all products on the panel',
      textureMandatory: 'Texture Mandatory',
      timeElapsed: 'Time Elapsed',
      timeLimit: 'Time Limit',
      startTime: 'Start Time',
      endTime: 'End Time',
      averageDuration: 'Avg Review Duration',
      panelists: 'Panelists',
      noPanelists: 'No panelists yet',
      totalReviews: 'Total Reviews',
      blindLabel: 'Blind Label',
      panelUpdateSuccess: 'Panel successfully updated.',
      panelUpdateError:
        'There was an error updating the panel, please try again.',
      panelNoBehavioralQuestions:
        'There are no behavioral questions selected for this product. Please edit the product before proceeding.',
      extendPanel: 'Extend Panel',
      endPanel: 'End Panel',
      panelTag: 'Panel Tags',
      editPanel: 'Edit Panel',
      atLeastOneProduct: 'You must add at least one product to start a panel',
      blindLabelMandatory: 'Blind Label is a mandatory field.',
      validDate: 'Please select valid start and end dates.',
      validEndDate: 'The end date must occur after the start date.',
      validStartDate: 'Start time must be in the future.',
      yourPanels: 'Your Panels',
      pastPanels: 'Past Panels',
      searchResult: 'Search Results for {{query}}',
      panelPin: 'Panel PIN',
      viewAllCurrentPanels: 'View All Current Panels',
      viewPastPanels: 'View Past Panels',
      searchPanels: 'Search Panels',
      noCurrentPanels: 'No Current Panels',
      behaviorQuestion: 'Behavioral Questions',
    },
    users: {
      users: 'Workspace Users',
      usersAdmin: 'List (Admin Restricted)',
      panelists: 'Workspace Panelists',
      list: 'List',
      partner: 'Partner',
      username: 'Username',
      email: 'Email',
      phoneNumber: 'Phone Number',
      dateOfBirth: 'DOB',
      firstLanguage: 'First Language',
      raceEthnicity: 'Race/Ethnicity',
      smokingHabits: 'Smoking Habits',
      role: 'Role',
      gender: 'Gender',
      smoke: 'Smoking',
      country: 'Country',
      dietaryRestrictions: 'Dietary Restrictions',
      nationalIdentity: 'National Identity',
      totalReviews: 'Total Reviews',
      interfaceLanguage: 'App Language',
      lastActive: 'Last Active',
      delete: 'Remove from Workspace',
      addUser: 'Add A New User',
      password: 'Password',
      createUser: 'Create User',
      editUserRole: 'Edit User Role',
      changePassword: 'Change Password',
      newUserWarning:
        'The user {{email}} does not belong to the {{workspace}} workspace.',
      existingUserWarning:
        'The user {{email}} already belongs to the {{workspace}} workspace.',
      sendAnInvite: 'Send an invite',
      invitationMessage:
        'Would you like to send them an invitation to download the Gastrograph Next app and join the workspace?',
      loadUserInfo: 'Load User Info',
    },
    project: {
      projects: 'Projects',
      create: 'Create Project',
      edit: 'Edit Project',
      name: 'Project Name',
      reports: 'Associate Reports',
      projectNameMandatory: 'You must enter a name for this project.',
      listTable: 'Projects List Table',
    },
    reportsQa: {
      reportCategory: 'Report Category',
      approvalModalTitle: 'Please confirm in order to approve:',
      country: 'Country',
      ages: 'Ages',
      gender: 'Gender',
      socioEcon: 'Socio-Econ',
      experienceRange: 'Experience Range',
      male: 'Male',
      female: 'Female',
      sansRejector: 'Sans Rejector',
      menu: {
        approveMapOnly: 'Approve Map Only',
        approveReport: 'Approve Report',
        cloneReport: 'Clone Report',
        showClassDb: 'Show Class DB',
        disappoveReport: 'Disapprove Report',
        rejectReport: 'Reject Report',
        unRejectReport: 'Un-Reject Report',
        updateReport: 'Update Report',
        viewReport: 'View Report',
      },
    },
    archiveModal: {
      title: 'Confirm Archive',
      message: 'Please confirm you wish to archive: ',
      archive: 'Archive',
      cancel: 'Cancel',
    },
    warningModal: {
      title: 'Confirm Navigation',
      'Leave this Page': 'Leave this Page',
      'Stay on this Page': 'Stay on this Page',
      info: 'Leaving this page will lose your changes. Are you sure?',
      subInfo: 'Are you sure you want to leave this page?',
      warning: 'This product cannot be removed since it has been reviewed.',
    },
    deleteUserModal: {
      "Are you sure you'd like to delete this user":
        "Are you sure you'd like to remove this user from the workspace?",
      'This action cannot be reversed': 'This action cannot be reversed',
      cancel: 'Cancel',
      delete: 'Remove',
    },
    invalidModal: {
      title: 'Following field(s) are required',
      ok: 'Ok',
    },
    invalidProductModal: {
      title: 'Product collision alert!',
      ok: 'Ok',
      cancel: 'Cancel',
    },
    projects: {
      title: 'Projects',
      projectSummary: {
        title: 'Project Summary',
        selectedReport: 'Selected Report',
        reportInformation: 'Report Information',
      },
      projectFlavorSummary: {
        back: 'Back',
        title: 'Flavor Summary',
        chartTitle: 'Flavor Effect & Tuning Plots',
        explainer:
          'This analysis shows the effect of the presence of each reference flavor on PQ for the average product shown in this report. Each reference flavor is mapped to the intensity of the Gastrograph variable under which it was marked, and the curves show the relationship between that Gastrograph variable and PQ when that reference flavor is present.',
      },
      textureFlavorSummary: {
        back: 'Back',
        chartTitle: 'Texture Tuning Plots',
      },
      projectExecutiveSummary: {
        title: 'Executive Summary',
      },
      projectTextureSummary: {
        title: 'Texture Summary',
      },
      projectOptimizationSummary: {
        title: 'Optimization Summary',
        referenceFlavor: 'Reference Flavor',
        deltaPQ: 'Delta PQ',
        deltaSignature: 'Delta Signature',
        deltaDecomp: 'Delta Decomp',
        order: 'Order',
      },
      projectNorms: {
        title: 'Norms',
      },
      dataDownload: {
        title: 'Data Download',
      },
      projectMap: {
        title: 'Map',
      },
      noveltyAnalysis: {
        title: 'Novelty Overview',
        distanceTree: {
          title: 'Distance in Relative Flavor Profile',
        },
      },
      categoryInsights: {
        title: 'Category Insights',
        flavorAttributePerceptionTitle: 'Flavor Attribute Perception Breakdown',
        flavorAttributePerceptionError:
          'Failed to load Flavor Attribute Perception Breakdown',
        impactDirectionLegendTitle: 'AI Product Liking Legend',
        impactDirectionLegendPositive: 'Positive Impact',
        impactDirectionLegendNeutral: 'Neutral Impact',
        impactDirectionLegendNegative: 'Negative Impact',
      },
      trendAnalysis: {
        title: 'Trend Analysis',
      },
      comparison: {
        back: 'Go Back',
        selectedProject: 'Selected Project',
        selectedReport: 'Selected Report',
        product: 'Product',
        flavorProfileTab: 'Flavor Profile',
        flavorCompositionTab: 'Flavor Composition',
        pqDistributionTab: 'PQ Distribution',
        stackRankTab: 'Stack Rank',
        marketMapTab: 'Market Map',
        flavorComposition: {
          referenceFlavor: 'Reference Flavor',
          delta: 'Delta',
        },
      },
    },
    reports: {
      report: 'Report',
      general: 'General',
      demographic: 'Demographic',
      experienceLevel: 'Experience Level',
      projectMemo: 'Project Memo',
      targetGroup: 'Target Group',
      submittedOn: 'Submitted On',
      reportType: 'Report Type',
      clientLabel: 'Client',
      clientPlaceholder: 'Select client',
      targetWorkspaceLabel: 'Project Workspace',
      targetWorkspacePlaceholder: 'Select project workspace',
      header: 'Panelist Review Count by Product',
      noOfReview: '# of Reviews',
      requests: 'Requested Reports',
      submitter: 'Submitter',
      workspace: 'Workspace',
      type: 'Report Type',
      dateSubmitted: 'Date Submitted',
      createReportRequest: 'Request A Report',
      status: 'Status',
      download: 'PDF Report',
      pdfLink: 'Click here to download',
      reportTypeLabel: 'What type of report would you like?',
      reportTypePlaceholder: 'Select report type',
      marketSurvey: 'Market Survey',
      optimization: 'Optimization',
      competitiveSet: 'Competitive set',
      allowedWorkSpaces: 'Allowed Workspaces',
      categories: 'Categories',
      productsToOptimize: 'Product To Optimize',
      products: 'Products',
      product: 'Product',
      constraintLevel: 'Constraint Level',
      constraintLevelPlaceholder: 'Select constraint level',
      gravityConstraint: 'Gravity Constraint',
      gravityConstraintPlaceholder: 'Select gravity constrain',
      newReferenceFlavors: 'Allow New Reference Flavors',
      productsPlaceholder: 'Add product',
      socioEcon: 'Socio-econ',
      socioEconReportTitle: 'Socio-Economic Target',
      socioEconPlaceholder: 'Select socio-economic status',
      regionTarget: 'Region Target',
      regionTargetPlaceholder: 'Select region target',
      raceEthnicitySelectCountry: 'Select a country to show race and ethnicity',
      demographics: 'Demographics',
      countries: 'Countries',
      regions: 'Regions',
      ages: 'Ages',
      addAgeRange: 'Age Range',
      raceEthnicity: 'Race & Ethnicity',
      gender: 'Gender(s)',
      smokingHabits: 'Smoking Habits Included',
      countryPlaceholder: 'Select countries',
      regionsPlaceholder: 'Select regions',
      racePlaceholder: 'Select race & ethnicity',
      agesPlaceholder: 'Select ages',
      genderPlaceholder: 'Select gender(s)',
      smokingHabitsPlaceholder: 'Select smoking habits',
      confirmation: 'Confirmation',
      projectName: 'Project Name',
      importFolder: 'Import Folder',
      productReport: 'Product',
      reportRequest: 'Report Request',
      includeTexture: 'Include Texture',
      testMode: 'Test Mode',
      noProducts: 'No Products',
      searchPreviousReport:
        'Search previous requests and load demographic info',
      requestSuccessHeader: 'Submission Successful:',
      requestSuccessSubtitle:
        'A member of the data science team will be in touch with you shortly regarding your request.',
      requestFailedHeader: 'Request Submission Failed',
      requestQuotaExceededSubtitle:
        'You have requested too many reports in the past 24 hours. Please try again later.',
      requestFailedSubtitle:
        'We are unable to process your request at the moment. Please try again or contact our team for assistance.',
      productLimitWarning:
        'Product Report is limited to one product per report. Remove the current product to replace it with another one.',
      pasteProductList: 'Paste Product List',
      evaluation: 'QA evaluation',
      commentPlaceholder: 'Give us your evaluation on this criteria...',
      formatting: 'Formatting Issue',
      comparative: 'Comparative QA Rejection',
      flavorProfile: 'Problem with Flavor Profile',
      sanityCheck: 'Sanity Check Reject',
      pqModel: 'PQ Model Problem',
      requestProblem: 'Request Problem',
      archetype: 'Archetype Problem',
      other: 'Others',
      revise: 'Revise',
      revision: 'Report Revision',
      newPdfLink: 'Updated PDF Link',
      revisionComment: 'Revision Comment',
      acceptModalDescription:
        'If you click confirm, the report will be displayed to the clients.',
      acceptModalTitle: 'Confirmation',
      dashboard: 'Dashboard',
      error: 'Error',
      insufficientData: 'Insufficient data to run the analysis.',
      pdfDownload: 'Download PDF',
      pdfReport: 'PDF Report',
      qaApproved: 'QA Approved',
      qaMapOnlyApproved: 'Approve Map Only',
      qaRejected: 'QA Rejected',
      request: {
        clientName: 'Client Name',
        projectName: 'Project Name',
        reportId: 'Report ID',
        passedQa: 'Passed QA',
        selectReport: 'Select Report',
        targetGroupName: 'Target Group Name',
        startedAt: 'Started At',
      },
      summary: {
        title: 'Report Summary',
        product: 'Product',
        pq: 'Average PQ',
        winRate: 'Win Rate',
        polarization: 'Polarization',
      },
      prodSummary: {
        title: 'Product Summary',
        product: 'Product',
        pq: 'PQ',
        winRate: 'Win Rate',
        compare: 'Compare',
        excludeAllProducts: 'Exclude All Products',
        excludeProduct: 'Exclude Product',
        originalLabel: 'Original Display Name',
        customEdit: 'Product Display Name',
        customLabelEdit: 'Market Map Label',
        customLabelColorEdit: 'Market Map Label Color',
        isHighlighted: 'Is Highlighted?',
        customEditTitle: 'Edit Custom Detail',
        displayOriginalProducts: 'Display Original Products',
      },
      actionSummary: {
        title: 'Action Summary',
        mostCommonFlavor: 'Most common flavor change:',
        bestPQChange: 'Best change in PQ flavor change:',
        importantGGVarChange: 'Most important GGVar change:',
      },
      optimizationTopThree: {
        title: 'Optimization First 3 Changes',
        topChanges: 'Top changes are for ',
        topChangesAlt: 'Top changes for ',
        increasingFlavor:
          'Increasing {{flavor}} flavor (intensity +{{intensity}})',
        decreasingFlavor:
          'Decreasing {{flavor}} flavor (intensity -{{intensity}})',
      },
      workspaceSummary: {
        reviewsConsidered: 'Reviews Considered',
        reviewsAnalyzed: 'Reviews Analyzed',
        signaturesConsidered: 'Signatures Considered',
        signaturesAnalyzed: 'Signatures Analyzed',
        dataCollected: 'Data Collected',
      },
      texturesSummary: {
        title: 'Texture',
        clusterExplain:
          'Texture Clusters consist of a set of descriptive texture words that describe the same underlying physical sensation.  A given product will typically exhibit several distinct TCs.',
        name: 'Name',
        cluster: 'Cluster',
        textures: 'Textures',
        confidence: 'Confidence',
        minConfidence: 'Min. Confidence',
        useClusterNamesLabel: 'Use Cluster Names?',
        useClusterNamesToolTip: 'Toggle between Texture Cluster Index and Name',
        confidenceExplainer:
          'With a higher confidence score, there is greater likelihood the texture is part of the  cluster.',
        boldExplainer:
          'The bolded texture cluster indicates the textures with the most impact on preference within the competitive set.',
        editCustomTextureClusterNameTitle: 'Edit Custom Texture Name',
        editCustomTextureClusterName: 'Custom Name',
      },
      texturePreference: {
        title: 'Preference Table',
        product: 'Product',
        total: 'Total',
        explainer:
          "Estimated contribution of each texture cluster to the product's preference score",
      },
      textureComposition: {
        title: 'Composition Table',
        product: 'Product',
        pq: 'PQ',
        standardError: 'TC Standard Error',
        explainer:
          'Measure of Texture Intensity on a scale of 0-5 with 0 being the least and 5 being the most intense',
      },
      textureTernary: {
        firstTC: 'First TC',
        secondTC: 'Second TC',
        thirdTC: 'Third TC',
        products: 'Products',
        triangularPixels: 'Triangular Pixels',
        contours: 'Contours',
        explainers: {
          overall:
            'This analysis projects the texture profile space into sets of 3 dimensional ternary maps on a preference gradient. Every point in the triangle represents a unique composition of the 3 texture clusters, the proportion of each cluster being given by the number on each corresponding axis, where all 3 numbers sum to 100%.',
          products:
            'are represented by a letter. The closer two products are on this map, the more similar their texture profiles are. The further apart, the more different their texture profiles are.',
          triangularPixels:
            'represent preference for different regions in texture space. Preference is indicated by ΔPQ where 0.0 is the mean preference (PQ) of the products in this competitive set.',
          contours:
            'represent the texture profile density. The inner-most contours are regions with the most common textures in this class of products, and the outer-most contours represent more novel textures in this class of products.',
        },
      },
      marketMap: {
        title: 'Market Map',
        vectors: 'Vectors',
        points: 'Points',
        products: 'Products',
        pixels: 'Pixels',
        contours: 'Contours',
        explainers: {
          overall:
            'This analysis projects the high dimensional flavor profile space into a 2 dimensional representation and maps the products on a preference gradient.',
          vectors:
            'pointing out of the origin are key attributes that are predictive of preference for the products in this competitive set. The longer the vector, the more important the GGVar is as a descriptive attribute driving these products apart on these maps.',
          points:
            'represent specific flavor signatures that exist in that point in flavor space.',
          products:
            'are represented by a letter. The closer two products are on this map the more similar they taste, the further apart the more different they taste with respect to the GGVars shown.',
          pixels:
            'represent preference for different regions in flavor space. Preference is indicated by ΔPQ where 0.0 is the mean preference (PQ) of the products in this competitive set.',
          contours:
            'represent the flavor profile density. The inner-most contours are regions with the most common flavors in this class of products, and the outer-most contours represent more novel flavors in this class of products.',
        },
      },
      marketPreferences: {
        title: 'Market Preferences',
      },
      flavorStructure: {
        title: 'Flavor Structure',
      },
      productSummary: {
        title: 'Product Summaries',
      },
      reportProductDetails: {
        back: 'Back',
        ofp: 'Objective Flavor Profile',
        predMarketPref: 'Predicted Market Preference',
        pqScore: 'PQ Score',
        winRate: 'Win Rate',
      },
      ofpDescription: {
        title: 'Product Flavor Description',
      },
      fpDecomp: {
        title: 'Flavor Profile Decomposition',
        knownSignatures1: 'Known signatures account for: ',
        knownSignatures2: '% of Flavor Profile | ',
        productFP: 'Product Flavor Profile Intensity: ',
        referenceFlavor: 'Reference Flavor',
        decomp: 'Decomp',
        legendSingleProduct:
          'Flavors with <1% flavor profile will not be displayed',
        legendComparison:
          'Flavors with <1% flavor profile will be displayed as 0%',
        explainer1:
          'This table shows the fraction of the total flavor profile decomposition for each flavor in percentage and its corresponding absolute flavor profile intensity in parentheses.',
        explainer2end:
          'is the sum of the relative intensity of the non-generic reference flavors above. The Known Signatures is the extent to which Gastrograph AI can attribute the flavor profile of the product to a specific high-resolution signature. The percentage of Known Signatures vary depending on product and category, for reasons including 1) ease of flavor identification in the category (e.g. it’s generally easier to identify flavor in a salty snack than plain white bread) 2) size of branch database 3) data quality of sensory reviews used to profile each product.',
        explainer3:
          'Signatures are reported in both relative and absolute terms. Relative intensities show each flavor signature’s contribution to the overall flavor profile. Absolute intensity scores take into account the overall intensity of the product. When comparing 2 products, especially during reformulation work, it is more accurate to compare the absolute values than the relative values. Product Flavor Profile Intensity is the sum of the Gastrograph Variables (GGVars) from around the 24-axis flavor wheel.',
      },
      noveltyFpDecomp: {
        title: 'Flavor Signatures Driving a Product’s Novelty Score',
        ggVars: 'Gastrograph Variable',
        referenceFlavor: 'Reference Flavor',
        contributionToNovelty:
          'Flavor Signature’s Contribution to Novelty Score',
        decomp: 'Decomp',
        explainer1:
          'The following table shows the list of products ranked by their standalone novelty score. This measures how novel this product is with respect to its category. For each product, we show the top signatures driving product novelty as well as the contribution each flavor has to its novelty score. Consider the empirically most novel product in this set, ',
        explainer2: 'its novelty is in-part driven by the presence of ',
        explainer3: 'this contributes ',
        explainer4:
          'to its overall novelty score. In the table below, a + indicates that the flavor signature is driving novelty and a - indicates that the product’s novelty is driven by the lack of that flavor signature.',
      },
      noveltyProductComparison: {
        title: 'Product Novelty Comparison',
      },
      noveltyIntensityPqChart: {
        title: 'Novelty and Intensity Associated with Perceived Quality',
        pq: 'Perceived Quality',
        intensity: 'Intensity',
        noveltyScore: 'Novelty Score',
        productNovelty: 'Product Novelty',
      },
      stackRanks: {
        title: 'Stack Ranks',
      },
      heatMap: {
        title: 'Head to Head Comparison',
      },
      flavorIntensityPqChart: {
        pq: 'PQ',
        intensityOf: 'Intensity of',
      },
      advancedReportConfig: 'Advanced Report Configuration',
      sansRejector: 'Sans Rejector',
      archtypeDemographics: {
        title: 'Archetype Ages',
        ageRange: 'Age Range',
        male: 'Male',
        female: 'Female',
      },
      timeSeries: {
        productTrends: 'Product Trends',
        categoryTrends: 'Category Trends',
        noData: 'No trend analysis data available!',
        deltaYoY: 'Percent change year over year',
        showError: 'Show error bands',
        compareProductCategory: 'Relative to category',
      },
    },
    reviews: {
      reviews: 'Reviews',
      userId: 'User ID',
      products: 'Products',
      userIdPlaceholder: 'Username, email or phone number',
      searchProductPlaceholder: 'Search for a product...',
      searchPanelPlaceholder: 'Enter panel pin',
      panel: 'Panel',
      intensityScale: 'Intensity Scale',
      referenceFlavorSentiment: 'Reference Flavor - Sentiment',
      selectReferenceFlavor: 'Select a reference flavor and add custom...',
      addReferenceFlavor: 'Add custom reference flavor ',
      texture: 'Texture',
      selectTexture: 'Select a texture and add custom...',
      addTexture: 'Add custom texture ',
      perceivedQuality: 'PQ',
      create: 'Create',
      userNotes: 'User Notes',
      userNotesPlaceholder: 'Enter User Notes',
      positive: 'Positive',
      neutral: 'Neutral',
      negative: 'Negative',
      lastReview: 'Last Review',
      searchReviews: 'Search Reviews',
      testMode: 'Test Mode',
      referenceFlavor: 'Reference Flavor',
    },
    device: {
      addDevice: 'Add a New Device',
      deviceId: 'Device ID',
      deviceName: 'Device Name',
      deviceUid: 'Device Unique Identifier',
      registerDevice: 'Register a Corporate Device',
      noDevice: 'No Devices Found',
    },
    general: {
      search: 'Search',
      image: 'Image',
      dateCreated: 'Date Created',
      dateUpdated: 'Date Updated',
      noData: 'No Data',
      property: 'Property',
      value: 'Value',
      unit: 'Unit',
      add: 'Add',
      writeValues: 'Write the values',
      writeHere: 'Write here',
      edit: 'Edit',
      cancel: 'Cancel',
      notSelected: 'Not Selected',
      noTagSelected: 'No Tags Selected',
      delete: 'Delete',
      view: 'View',
      download: 'Download',
      date: 'Date',
      confirm: 'Confirm',
      action: 'Action',
      workspace: 'Workspace',
      approved: 'Approved',
      pdf: 'PDF',
    },
    datepicker: {
      hour: 'Hour',
      minute: 'Minute',
      daySelection: 'AM/PM',
      timezone: 'Time Zone',
    },
    viewerMenu: {
      profile: 'Profile',
      changeLanguage: 'Change Language',
      adminDataExplorer: 'Data Explorer',
      superadminTools: 'Superadmin Tools',
      adminTools: 'Admin Tools',
      signOut: 'Sign Out',
      selectWorkspace: 'Select a Workspace',
      notification: 'Notification',
    },
    admin: {
      addUserWorkspace: 'Add User to Workspace',
      removeUserWorkspace: 'Remove User from Workspace',
      removeUserWorkspaceModalDescription:
        'Are you sure you want to remove user {{email}} from {{workspaceName}} ?',
      createClient: 'Create Client',
      selectPartner: 'Select Partner',
      mergeAccount: 'Merge Account',
      userWorkspaces: 'User Workspaces',
      viewWorkspaces: 'View Workspaces',
      viewRoles: 'View Assigned Roles',
      workspaces: 'Workspace(s)',
      allWorkspaceList: 'All Workspace List',
      createWorkspace: 'Create Workspace',
      createCategory: 'Create Product Category',
      groupWorkspace: 'Group Workspace',
      tagHeavyUser: 'Tag Heavy User',
      heavyUserInfo: 'Heavy User Info',
      tag: 'Tag',
      saveFilter: 'Save Filter',
      updateProductMetadata: 'Update Product Metadata',
      smokeTests: 'Smoke Tests',
      allSmokeTests: 'All Smoke Tests',
      moveProductWorkspace: 'Move Products & Reviews to New Workspace',
      partnerBranding: 'Partner Branding',
      producerPreference: 'Producer Preference',
      searchProduct: 'Search Products',
      productsInCategory: 'Products in Category',
      swapPanelProductReviews: 'Swap Panel/Product Review',
    },
    workspace: {
      id: 'ID',
      name: 'Name',
      slug: 'Slug',
      partner: 'Partner',
      defaultTimezone: 'Select Default Timezone',
      selectProducerName: 'Select Producer Name',
      selectParentWorkspace: 'Select Parent Workspace',
      selectChildWorkspaces: 'Select Child Workspace',
    },
    customLexicon: {
      save: 'Save',
      mapToAnExistingLexicon: 'Map To An Existing Lexicon',
      createNewLexicon: 'Create New Lexicon',
      cancel: 'Cancel',
      lexicon: 'Lexicon',
      language: 'Language',
      value: 'Value',
      label: 'Label',
      selectLexicon: 'Select lexicon',
      selectLanguage: 'Select language',
      action: 'Action',
      visible: 'Visible',
      download: 'Download',
      downloadAll: 'Download All',
    },
    forms: {
      yes: 'Yes (True)',
      no: 'No (False)',
      selectCreate: 'Select or Create...',
      other: 'Others',
    },
    nutritionalInfo: [
      {
        value: 'Serving size',
        label: 'Serving Size (on label)',
        unit: ['ml', 'l', 'g', 'kg', 'mg'],
      },
      {
        value: 'Calories',
        label: 'Calories',
        unit: ['Cal', 'kcal', 'kJ', 'None'],
      },
      {
        value: 'Calories from fat',
        label: 'Calories From Fat',
        unit: ['Cal', 'kcal', 'kJ'],
      },
      {
        value: 'Total fat',
        label: 'Total Fat',
        unit: ['g', 'mg'],
      },
      {
        value: 'Cholesterol',
        label: 'Cholesterol',
        unit: ['g', 'mg'],
      },
      {
        value: 'Sodium',
        label: 'Sodium',
        unit: ['g', 'mg'],
      },
      {
        value: 'Total Carbohydrates',
        label: 'Total Carbohydrates',
        unit: ['g', 'mg'],
      },
    ],
    dietaryRestrictions: {
      contains: [
        { value: 'None', label: 'No Restrictions' },
        { value: 'Not-Vegetarian', label: 'Meat (Not Vegetarian)' },
        { value: 'Not-Vegan', label: 'Animal Products (Not Vegan)' },
        { value: 'Not-CaffeineFree', label: 'Caffeine' },
        { value: 'Not-GlutenFree', label: 'Gluten' },
        { value: 'Not-LactoseFree', label: 'Lactose' },
        { value: 'Not-PorkFree', label: 'Pork' },
        { value: 'Not-BeefFree', label: 'Beef' },
        { value: 'Not-OnionFree', label: 'Onion' },
        { value: 'Not-GarlicFree', label: 'Garlic' },
        { value: 'Not-AlcoholFree', label: 'Alcohol' },
        { value: 'Not-SoyFree', label: 'Soybeans' },
      ],
    },
    allergenInfo: {
      contains: [
        { value: 'No Allergens', label: 'No Allergens' },
        { value: 'Dairy', label: 'Dairy' },
        { value: 'Eggs', label: 'Eggs' },
        { value: 'Fish', label: 'Fish' },
        { value: 'Nuts', label: 'Nuts' },
        { value: 'Peanuts', label: 'Peanuts' },
        { value: 'Soybeans', label: 'Soybeans' },
        { value: 'Shellfish', label: 'Shellfish' },
        { value: 'Wheat', label: 'Wheat' },
        { value: 'Seeds', label: 'Seeds' },
      ],
      safe: [
        { value: 'Gluten-Free', label: 'Gluten-Free' },
        { value: 'Nut-Free', label: 'Nut-Free' },
        { value: 'Kosher', label: 'Kosher' },
        { value: 'Halal', label: 'Halal' },
        { value: 'Vegan', label: 'Vegan' },
        { value: 'Vegetarian', label: 'Vegetarian' },
      ],
    },
    servingVessel: [
      { value: '', label: 'N/A' },
      { value: 'Original Packaging', label: 'Original Packaging' },
      { value: 'Bowl', label: 'Bowl' },
      { value: 'Can', label: 'Can' },
      { value: 'Ceramic Cup', label: 'Ceramic Cup' },
      { value: 'Flute Glass', label: 'Flute Glass' },
      { value: 'Glass', label: 'Glass' },
      { value: 'Plastic Cup', label: 'Plastic Cup' },
      { value: 'Plate', label: 'Plate' },
      { value: 'Pouch', label: 'Pouch' },
      { value: 'Thermos', label: 'Thermos' },
      { value: 'Tulip Glass', label: 'Tulip Glass' },
      { value: 'Wine Glass', label: 'Wine Glass' },
      { value: 'Other', label: 'Other' },
    ],
    physicalState: [
      { value: 'Solid', label: 'Solid' },
      { value: 'Semi-Solid', label: 'Semi-Solid' },
      { value: 'Liquid', label: 'Liquid' },
      { value: 'Carbonated Liquid', label: 'Carbonated Liquid' },
      { value: 'Gas', label: 'Gas' },
      { value: 'Frozen', label: 'Frozen' },
      { value: 'Others', label: 'Others' },
    ],
    reportType: [
      // { value: 'Product', label: 'Product Report' },
      { value: 'market_survey', label: 'Market Survey Report' },
      { value: 'Optimization', label: 'Optimization Report' },
      // { value: 'Panelist', label: 'Panelist Report' }
    ],
    constraintLevelOptions: [
      { value: 'Low', label: 'Low' },
      { value: 'Medium', label: 'Medium' },
      { value: 'High', label: 'High' },
    ],
    gravityConstraintOptions: [
      { value: 'Low', label: 'Negative' },
      { value: 'Medium', label: 'Neutral' },
      { value: 'High', label: 'Positive' },
    ],
    gender: [
      { value: 'All', label: 'All' },
      // { value: 'Census-Adjusted', label: 'Census-Adjusted' },
      { value: 'Female', label: 'Female' },
      { value: 'Male', label: 'Male' },
      { value: 'Female & Male', label: 'Female & Male' },
      { value: 'Skewed to Female (80/20)', label: 'Skewed to Female (80/20)' },
      { value: 'Skewed to Male (80/20)', label: 'Skewed to Male (80/20)' },
    ],
    socioEcon: [
      // { value: 'Census-Adjusted', label: 'Census-Adjusted' },
      { value: 'Lower Class', label: 'Lower Class' },
      { value: 'Middle Class', label: 'Middle Class' },
      { value: 'Upper Class', label: 'Upper Class' },
      {
        value: 'Lower Class - Middle Class',
        label: 'Lower Class - Middle Class',
      },
      {
        value: 'Middle Class - Upper Class',
        label: 'Middle Class - Upper Class',
      },
      {
        value: 'Lower Class - Upper Class',
        label: 'Lower Class - Upper Class',
      },
    ],
    regionTarget: [
      { value: 'Urban', label: 'Urban' },
      { value: 'Suburban', label: 'Suburban' },
      { value: 'Rural', label: 'Rural' },
    ],
    ageCategoryOptions: [
      { value: 'Children', label: 'Children' },
      { value: 'Adult', label: 'Adult' },
    ],
    smokingHabitsOptions: [
      { value: 'Smokers', label: 'Smokers' },
      { value: 'Non-Smokers', label: 'Non-Smokers' },
      { value: 'All', label: 'All' },
    ],
    ageOptions: [
      { value: '5-25', label: '5-25' },
      { value: '26-50', label: '26-50' },
      { value: '51-75', label: '51-75' },
      { value: '76-115', label: '76-115' },
    ],
    raceAndEthnicity: [
      { value: 'White', label: 'White' },
      { value: 'Hispanic/Latino/Spanish', label: 'Hispanic/Latino/Spanish' },
      {
        value: 'Mexican/Mexican American/Chicano',
        label: 'Mexican/Mexican American/Chicano',
      },
      { value: 'Puerto Rican', label: 'Puerto Rican' },
      { value: 'Cuban', label: 'Cuban' },
      { value: 'Asian', label: 'Asian' },
      { value: 'Indian', label: 'Indian' },
      { value: 'Japanese', label: 'Japanese' },
      { value: 'Chinese', label: 'Chinese' },
      { value: 'Korean', label: 'Korean' },
      { value: 'Filipino', label: 'Filipino' },
      { value: 'Vietnamese', label: 'Vietnamese' },
      {
        value: 'Black/African/African American',
        label: 'Black/African/African American',
      },
      { value: 'African', label: 'African' },
      { value: 'African American', label: 'African American' },
      { value: 'Pacific Islanders', label: 'Pacific Islanders' },
      { value: 'Guamanian/Chamorro', label: 'Guamanian/Chamorro' },
      { value: 'Native Hawaiian', label: 'Native Hawaiian' },
      { value: 'Samoan', label: 'Samoan' },
      { value: 'Canadian', label: 'Canadian' },
      { value: 'English', label: 'English' },
      { value: 'Scottish', label: 'Scottish' },
      { value: 'French', label: 'French' },
      { value: 'Irish', label: 'Irish' },
      { value: 'German', label: 'German' },
      { value: 'Italian', label: 'Italian' },
      { value: 'North American Indian', label: 'North American Indian' },
      { value: 'East Indian', label: 'East Indian' },
      { value: 'Mestizo', label: 'Mestizo' },
      { value: 'Predominantly Amerindian', label: 'Predominantly Amerindian' },
      { value: 'Amerindian', label: 'Amerindian' },
      { value: 'Mulatto', label: 'Mulatto' },
      { value: 'Black', label: 'Black' },
      { value: 'Indigenous', label: 'Indigenous' },
      { value: 'Mestizo And White', label: 'Mestizo And White' },
      { value: 'African Colombian', label: 'African Colombian' },
      { value: 'Palenquero', label: 'Palenquero' },
      { value: 'Raizal', label: 'Raizal' },
      { value: 'Romani', label: 'Romani' },
      { value: 'Spanish', label: 'Spanish' },
      { value: 'Morocco', label: 'Morocco' },
      { value: 'Romania', label: 'Romania' },
      { value: 'Mediterranean', label: 'Mediterranean' },
      { value: 'East European', label: 'East European' },
      { value: 'Brazilian', label: 'Brazilian' },
      { value: 'Han Chinese', label: 'Han Chinese' },
      { value: 'Marrano', label: 'Marrano' },
      { value: 'Fleming', label: 'Fleming' },
      { value: 'Catalan', label: 'Catalan' },
      { value: 'Basque', label: 'Basque' },
      { value: 'Algerian Arab', label: 'Algerian Arab' },
      {
        value: 'Algerian And Moroccan Berber',
        label: 'Algerian And Moroccan Berber',
      },
      { value: 'Moroccan Arab', label: 'Moroccan Arab' },
      { value: 'Portuguese', label: 'Portuguese' },
      { value: 'Jewish', label: 'Jewish' },
      { value: 'Tunisian', label: 'Tunisian' },
      { value: 'Turkish', label: 'Turkish' },
      { value: 'Dutch', label: 'Dutch' },
      { value: 'European', label: 'European' },
      { value: 'Moroccan', label: 'Moroccan' },
      { value: 'Indonesian', label: 'Indonesian' },
      { value: 'Surinamese', label: 'Surinamese' },
      { value: 'Polish', label: 'Polish' },
      { value: 'Russian', label: 'Russian' },
      { value: 'African German', label: 'African German' },
      { value: 'Arab', label: 'Arab' },
      { value: 'Romanian', label: 'Romanian' },
      { value: 'Greek', label: 'Greek' },
      { value: 'Welsh', label: 'Welsh' },
      { value: 'Northern Irish', label: 'Northern Irish' },
      { value: 'British', label: 'British' },
      { value: 'Gypsy Or Irish Traveller', label: 'Gypsy Or Irish Traveller' },
      { value: 'Mixed/Multiple', label: 'Mixed/Multiple' },
      {
        value: 'White And Black Carribean',
        label: 'White And Black Carribean',
      },
      { value: 'White And Black African', label: 'White And Black African' },
      { value: 'White And Asian', label: 'White And Asian' },
      { value: 'Asian/Asian British', label: 'Asian/Asian British' },
      { value: 'Pakistani', label: 'Pakistani' },
      { value: 'Bangladeshi', label: 'Bangladeshi' },
      {
        value: 'Black/African/Caribbean/BlackBritish',
        label: 'Black/ African / Caribbean / BlackBritish',
      },
      { value: 'Carribean', label: 'Carribean' },
      { value: 'Flemish', label: 'Flemish' },
      { value: 'Walloon', label: 'Walloon' },
      { value: 'Kurd', label: 'Kurd' },
      { value: 'Bosniak', label: 'Bosniak' },
      { value: 'Algerian', label: 'Algerian' },
      { value: 'Congolese', label: 'Congolese' },
      { value: 'Guinean', label: 'Guinean' },
      { value: 'Maghrebi Or Arabic', label: 'Maghrebi Or Arabic' },
      { value: 'Albanian', label: 'Albanian' },
      { value: 'Ukranian', label: 'Ukranian' },
      { value: 'Latin American', label: 'Latin American' },
      { value: 'Slovene-Italians', label: 'Slovene-Italians' },
      { value: 'Albanian-Italians', label: 'Albanian-Italians' },
      { value: 'Greek-Italians', label: 'Greek-Italians' },
      { value: 'Indo-Aryan', label: 'Indo-Aryan' },
      { value: 'Bengali', label: 'Bengali' },
      { value: 'Tamil', label: 'Tamil' },
      { value: 'Punjabi', label: 'Punjabi' },
      { value: 'Rajput', label: 'Rajput' },
      { value: 'Telegu', label: 'Telegu' },
      { value: 'Naga', label: 'Naga' },
      { value: 'Gujarati', label: 'Gujarati' },
      { value: 'Brahmin', label: 'Brahmin' },
      { value: 'Jat', label: 'Jat' },
      { value: 'Kashmiri', label: 'Kashmiri' },
      { value: 'Tibetan', label: 'Tibetan' },
      { value: 'Irula', label: 'Irula' },
      { value: 'Sindhi', label: 'Sindhi' },
      { value: 'Marathi', label: 'Marathi' },
      { value: 'Parsi', label: 'Parsi' },
      { value: 'Khasi', label: 'Khasi' },
      { value: 'Dogra', label: 'Dogra' },
      { value: 'Dravidian', label: 'Dravidian' },
      { value: 'Mongoloid', label: 'Mongoloid' },
      { value: 'Thai', label: 'Thai' },
      { value: 'Burmese', label: 'Burmese' },
      { value: 'Khmer', label: 'Khmer' },
      { value: 'Malay', label: 'Malay' },
      { value: 'Karen', label: 'Karen' },
      { value: 'Hmong', label: 'Hmong' },
      { value: 'Javanese', label: 'Javanese' },
      { value: 'Sundanese', label: 'Sundanese' },
      { value: 'Batak', label: 'Batak' },
      { value: 'Madurese', label: 'Madurese' },
      { value: 'Betawi', label: 'Betawi' },
      { value: 'Minangkabu', label: 'Minangkabu' },
      { value: 'Buginese', label: 'Buginese' },
      { value: 'Banjarese', label: 'Banjarese' },
      { value: 'Balinese', label: 'Balinese' },
      { value: 'Acehnese', label: 'Acehnese' },
      { value: 'Dayak', label: 'Dayak' },
      { value: 'Sasak', label: 'Sasak' },
      { value: 'Osing', label: 'Osing' },
      { value: 'Tagalog', label: 'Tagalog' },
      { value: 'Cebuano', label: 'Cebuano' },
      { value: 'Ilocano/Ilokano', label: 'Ilocano/Ilokano' },
      { value: 'Visayan/Bisaya', label: 'Visayan/Bisaya' },
      { value: 'Hiligaynon', label: 'Hiligaynon' },
      { value: 'Bikol', label: 'Bikol' },
      { value: 'Waray', label: 'Waray' },
      { value: 'Chinese Filipino', label: 'Chinese Filipino' },
      { value: 'Kapampangan', label: 'Kapampangan' },
      { value: 'Pangasinan', label: 'Pangasinan' },
      { value: 'Kalinga', label: 'Kalinga' },
      { value: 'Ifugao', label: 'Ifugao' },
      { value: 'Kankaney', label: 'Kankaney' },
      { value: 'Srilankan', label: 'Srilankan' },
      { value: 'Eurasian', label: 'Eurasian' },
      { value: 'Armenian', label: 'Armenian' },
      { value: 'Chitty', label: 'Chitty' },
      { value: 'Kristang', label: 'Kristang' },
      { value: 'Zhuang', label: 'Zhuang' },
      { value: 'Manchu', label: 'Manchu' },
      { value: 'Hui', label: 'Hui' },
      { value: 'Miao', label: 'Miao' },
      { value: 'Uyghur', label: 'Uyghur' },
      { value: 'Tujia', label: 'Tujia' },
      { value: 'Yi', label: 'Yi' },
      { value: 'Russian_CN', label: 'Russian' },
      { value: 'Mongol', label: 'Mongol' },
      { value: 'Bouyei', label: 'Bouyei' },
      { value: 'Dong', label: 'Dong' },
      { value: 'Yao', label: 'Yao' },
      { value: 'Bai', label: 'Bai' },
      { value: 'Hani', label: 'Hani' },
      { value: 'Chosen', label: 'Korean' },
      { value: 'Kazakh', label: 'Kazakh' },
      { value: 'Li', label: 'Li' },
      { value: 'Dai', label: 'Dai' },
      { value: 'She', label: 'She' },
      { value: 'Lisu', label: 'Lisu' },
      { value: 'Gelao', label: 'Gelao' },
      { value: 'Dongxiang', label: 'Dongxiang' },
      { value: 'Lahu', label: 'Lahu' },
      { value: 'Shui', label: 'Shui' },
      { value: 'Wa', label: 'Wa' },
      { value: 'Nakhi', label: 'Nakhi' },
      { value: 'Qiang', label: 'Qiang' },
      { value: 'Tu', label: 'Tu' },
      { value: 'Mulao', label: 'Mulao' },
      { value: 'Xibe', label: 'Xibe' },
      { value: 'Kyrgyz', label: 'Kyrgyz' },
      { value: 'Daur', label: 'Daur' },
      { value: 'Jingpo', label: 'Jingpo' },
      { value: 'Maonan', label: 'Maonan' },
      { value: 'Salar', label: 'Salar' },
      { value: 'Bulag', label: 'Bulag' },
      { value: 'Tajik', label: 'Tajik' },
      { value: 'Achang', label: 'Achang' },
      { value: 'Pumi', label: 'Pumi' },
      { value: 'Ewenki', label: 'Ewenki' },
      { value: 'Nu', label: 'Nu' },
      { value: 'Gin', label: 'Gin' },
      { value: 'Jino', label: 'Jino' },
      { value: 'DeAng', label: 'DeAng' },
      { value: 'Bonan', label: 'Bonan' },
      { value: 'Yugur', label: 'Yugur' },
      { value: 'Uzbek', label: 'Uzbek' },
      { value: 'Monba', label: 'Monba' },
      { value: 'Oroqen', label: 'Oroqen' },
      { value: 'Derung', label: 'Derung' },
      { value: 'Tatars', label: 'Tatars' },
      { value: 'Hezhen', label: 'Hezhen' },
      { value: 'Gaoshan', label: 'Gaoshan' },
      { value: 'Lhoba', label: 'Lhoba' },
      { value: 'American', label: 'American' },
      { value: 'Filipinos', label: 'Filipinos' },
      { value: 'Cambodian', label: 'Cambodian' },
      { value: 'Maori', label: 'Maori' },
      { value: 'Pacific People', label: 'Pacific People' },
      {
        value: 'MiddleEastern/LatinAmerican/African',
        label: 'MiddleEastern/ LatinAmerican / African',
      },
      { value: 'Australian', label: 'Australian' },
      { value: 'Arabian', label: 'Arabian' },
      { value: 'New Zealand', label: 'New Zealand' },
      { value: 'Hoklo', label: 'Hoklo' },
      { value: 'Hakka', label: 'Hakka' },
      { value: 'Mainland China', label: 'Mainland China' },
      {
        value: 'Indigenous Malayo-Polynesian',
        label: 'Indigenous Malayo-Polynesian',
      },
      { value: 'Amis', label: 'Amis' },
      { value: 'Atayal', label: 'Atayal' },
      { value: 'Bunun', label: 'Bunun' },
      { value: "Hla'alua", label: "Hla'alua" },
      { value: 'Kanakaravu', label: 'Kanakaravu' },
      { value: 'Paiwan', label: 'Paiwan' },
      { value: 'Puyuma', label: 'Puyuma' },
      { value: 'Rukai', label: 'Rukai' },
      { value: 'Sakizaya', label: 'Sakizaya' },
      { value: 'Seediq', label: 'Seediq' },
      { value: 'Thao', label: 'Thao' },
      { value: 'Truku', label: 'Truku' },
      { value: 'Tsou', label: 'Tsou' },
      { value: 'Yami', label: 'Yami' },
      { value: 'European/White', label: 'European/White' },
      { value: 'African/Black', label: 'African/Black' },
      { value: 'Tatar', label: 'Tatar' },
      { value: 'Bashkir', label: 'Bashkir' },
      { value: 'Chuvash', label: 'Chuvash' },
      { value: 'Chechen', label: 'Chechen' },
      { value: 'Belarusian', label: 'Belarusian' },
      { value: 'Estonian', label: 'Estonian' },
      { value: 'Latvian', label: 'Latvian' },
      { value: 'Lithuanian', label: 'Lithuanian' },
      { value: 'Moldavian', label: 'Moldavian' },
      { value: 'Georgian', label: 'Georgian' },
      { value: 'Azerbaijan', label: 'Azerbaijan' },
      { value: 'Turkmen', label: 'Turkmen' },
      { value: 'Kinh/Viet', label: 'Kinh/Viet' },
      { value: 'Ba Na', label: 'Ba Na' },
      { value: 'Bố Y', label: 'Bố Y' },
      { value: 'Brâu', label: 'Brâu' },
      { value: 'Chăm', label: 'Chăm' },
      { value: 'Chơ Ro', label: 'Chơ Ro' },
      { value: 'Chu Ru', label: 'Chu Ru' },
      { value: 'Chứt', label: 'Chứt' },
      { value: 'Co', label: 'Co' },
      { value: 'Cờ Ho', label: 'Cờ Ho' },
      { value: 'Cờ Lao', label: 'Cờ Lao' },
      { value: 'Cơ Tu', label: 'Cơ Tu' },
      { value: 'Dao', label: 'Dao' },
      { value: 'Ê Đê', label: 'Ê Đê' },
      { value: 'Gia Rai', label: 'Gia Rai' },
      { value: 'Giáy', label: 'Giáy' },
      { value: 'Giẻ Triêng', label: 'Giẻ Triêng' },
      { value: 'Hà Nhì', label: 'Hà Nhì' },
      { value: 'Hoa', label: 'Hoa' },
      { value: 'Hrê', label: 'Hrê' },
      { value: 'Kháng', label: 'Kháng' },
      { value: 'Khmer Krom', label: 'Khmer Krom' },
      { value: 'Khơ Mú', label: 'Khơ Mú' },
      { value: 'La Chí', label: 'La Chí' },
      { value: 'La Ha', label: 'La Ha' },
      { value: 'La Hủ', label: 'La Hủ' },
      { value: 'Lao', label: 'Lao' },
      { value: 'Lô Lô', label: 'Lô Lô' },
      { value: 'Lự', label: 'Lự' },
      { value: 'Mạ', label: 'Mạ' },
      { value: 'Mảng', label: 'Mảng' },
      { value: 'Mnông', label: 'Mnông' },
      { value: 'Mường', label: 'Mường' },
      { value: 'Ngái', label: 'Ngái' },
      { value: 'Nùng', label: 'Nùng' },
      { value: 'Ơ Đu', label: 'Ơ Đu' },
      { value: 'Pà Thẻn', label: 'Pà Thẻn' },
      { value: 'Phù Lá', label: 'Phù Lá' },
      { value: 'Phunoi', label: 'Phunoi' },
      { value: 'Pu Péo', label: 'Pu Péo' },
      { value: 'Ra Glai', label: 'Ra Glai' },
      { value: 'Rơ Măm', label: 'Rơ Măm' },
      { value: 'Sán Chay', label: 'Sán Chay' },
      { value: 'Sán Dìu', label: 'Sán Dìu' },
      { value: 'Si La', label: 'Si La' },
      { value: 'Tà Ôi', label: 'Tà Ôi' },
      { value: 'Tày', label: 'Tày' },
      { value: 'Thái', label: 'Thái' },
      { value: 'Thổ', label: 'Thổ' },
      { value: 'X’Tiêng', label: 'X’Tiêng' },
      { value: 'Xinh Mun', label: 'Xinh Mun' },
      { value: 'Xơ Đăng', label: 'Xơ Đăng' },
      { value: 'Pardo', label: 'Pardo/Parda' },
      { value: 'Latino', label: 'Latino' },
      { value: 'Other', label: 'Other' },
    ],
    country: {
      AC: 'Ascension Island',
      AD: 'Andorra',
      AE: 'United Arab Emirates',
      AF: 'Afghanistan',
      AG: 'Antigua & Barbuda',
      AI: 'Anguilla',
      AL: 'Albania',
      AM: 'Armenia',
      AO: 'Angola',
      AQ: 'Antarctica',
      AR: 'Argentina',
      AS: 'American Samoa',
      AT: 'Austria',
      AU: 'Australia',
      AW: 'Aruba',
      AX: 'Åland Islands',
      AZ: 'Azerbaijan',
      BA: 'Bosnia & Herzegovina',
      BB: 'Barbados',
      BD: 'Bangladesh',
      BE: 'Belgium',
      BF: 'Burkina Faso',
      BG: 'Bulgaria',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BL: 'St. Barthélemy',
      BM: 'Bermuda',
      BN: 'Brunei',
      BO: 'Bolivia',
      BQ: 'Caribbean Netherlands',
      BR: 'Brazil',
      BRB: 'Brazil (Bahia)',
      BRR: 'Brazil (Rio)',
      BS: 'Bahamas',
      BT: 'Bhutan',
      BV: 'Bouvet Island',
      BW: 'Botswana',
      BY: 'Belarus',
      BZ: 'Belize',
      CA: 'Canada',
      CC: 'Cocos (Keeling) Islands',
      CD: 'Congo - Kinshasa',
      CF: 'Central African Republic',
      CG: 'Congo - Brazzaville',
      CH: 'Switzerland',
      CI: 'Côte D’Ivoire',
      CK: 'Cook Islands',
      CL: 'Chile',
      CM: 'Cameroon',
      CN: 'China',
      CNC: 'Mainland China (Coastal)',
      CO: 'Colombia',
      CP: 'Clipperton Island',
      CR: 'Costa Rica',
      CU: 'Cuba',
      CV: 'Cape Verde',
      CW: 'Curaçao',
      CX: 'Christmas Island',
      CY: 'Cyprus',
      CZ: 'Czechia',
      DE: 'Germany',
      DG: 'Diego Garcia',
      DJ: 'Djibouti',
      DK: 'Denmark',
      DM: 'Dominica',
      DO: 'Dominican Republic',
      DZ: 'Algeria',
      EA: 'Ceuta & Melilla',
      EC: 'Ecuador',
      EE: 'Estonia',
      EG: 'Egypt',
      EH: 'Western Sahara',
      ER: 'Eritrea',
      ES: 'Spain',
      ET: 'Ethiopia',
      EU: 'European Union',
      FI: 'Finland',
      FJ: 'Fiji',
      FK: 'Falkland Islands',
      FM: 'Micronesia',
      FO: 'Faroe Islands',
      FR: 'France',
      GA: 'Gabon',
      GB: 'United Kingdom',
      GD: 'Grenada',
      GE: 'Georgia',
      GF: 'French Guiana',
      GG: 'Guernsey',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GL: 'Greenland',
      GM: 'Gambia',
      GN: 'Guinea',
      GP: 'Guadeloupe',
      GQ: 'Equatorial Guinea',
      GR: 'Greece',
      GS: 'South Georgia & South Sandwich Islands',
      GT: 'Guatemala',
      GU: 'Guam',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HK: 'Hong Kong Sar China',
      HM: 'Heard & Mcdonald Islands',
      HN: 'Honduras',
      HR: 'Croatia',
      HT: 'Haiti',
      HU: 'Hungary',
      IC: 'Canary Islands',
      ID: 'Indonesia',
      IE: 'Ireland',
      IL: 'Israel',
      IM: 'Isle Of Man',
      IN: 'India',
      IO: 'British Indian Ocean Territory',
      IQ: 'Iraq',
      IR: 'Iran',
      IS: 'Iceland',
      IT: 'Italy',
      ITR: 'Italy (Rome)',
      ITM: 'Italy (Milano)',
      JE: 'Jersey',
      JM: 'Jamaica',
      JO: 'Jordan',
      JP: 'Japan',
      KE: 'Kenya',
      KG: 'Kyrgyzstan',
      KH: 'Cambodia',
      KI: 'Kiribati',
      KM: 'Comoros',
      KN: 'St. Kitts & Nevis',
      KP: 'North Korea',
      KR: 'South Korea',
      KW: 'Kuwait',
      KY: 'Cayman Islands',
      KZ: 'Kazakhstan',
      LA: 'Laos',
      LB: 'Lebanon',
      LC: 'St. Lucia',
      LI: 'Liechtenstein',
      LK: 'Sri Lanka',
      LR: 'Liberia',
      LS: 'Lesotho',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      LV: 'Latvia',
      LY: 'Libya',
      MA: 'Morocco',
      MC: 'Monaco',
      MD: 'Moldova',
      ME: 'Montenegro',
      MF: 'St. Martin',
      MG: 'Madagascar',
      MH: 'Marshall Islands',
      MK: 'Macedonia',
      ML: 'Mali',
      MM: 'Myanmar (Burma)',
      MN: 'Mongolia',
      MO: 'Macau Sar China',
      MP: 'Northern Mariana Islands',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MS: 'Montserrat',
      MT: 'Malta',
      MU: 'Mauritius',
      MV: 'Maldives',
      MW: 'Malawi',
      MX: 'Mexico',
      MY: 'Malaysia',
      MZ: 'Mozambique',
      NA: 'Namibia',
      NC: 'New Caledonia',
      NE: 'Niger',
      NF: 'Norfolk Island',
      NG: 'Nigeria',
      NI: 'Nicaragua',
      NL: 'Netherlands',
      NO: 'Norway',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      NZ: 'New Zealand',
      OM: 'Oman',
      PA: 'Panama',
      PE: 'Peru',
      PF: 'French Polynesia',
      PG: 'Papua New Guinea',
      PH: 'Philippines',
      PK: 'Pakistan',
      PL: 'Poland',
      PM: 'St. Pierre & Miquelon',
      PN: 'Pitcairn Islands',
      PR: 'Puerto Rico',
      PS: 'Palestinian Territories',
      PT: 'Portugal',
      PW: 'Palau',
      PY: 'Paraguay',
      QA: 'Qatar',
      RE: 'Réunion',
      RO: 'Romania',
      RS: 'Serbia',
      RU: 'Russia',
      RUM: 'Russia (Moscow)',
      RUS: 'Russia (SPB)',
      RW: 'Rwanda',
      SA: 'Saudi Arabia',
      SB: 'Solomon Islands',
      SC: 'Seychelles',
      SD: 'Sudan',
      SE: 'Sweden',
      SG: 'Singapore',
      SH: 'St. Helena',
      SI: 'Slovenia',
      SJ: 'Svalbard & Jan Mayen',
      SK: 'Slovakia',
      SL: 'Sierra Leone',
      SM: 'San Marino',
      SN: 'Senegal',
      SO: 'Somalia',
      SR: 'Suriname',
      SS: 'South Sudan',
      ST: 'São Tomé & Príncipe',
      SV: 'El Salvador',
      SX: 'Sint Maarten',
      SY: 'Syria',
      SZ: 'Swaziland',
      TA: 'Tristan Da Cunha',
      TC: 'Turks & Caicos Islands',
      TD: 'Chad',
      TF: 'French Southern Territories',
      TG: 'Togo',
      TH: 'Thailand',
      TJ: 'Tajikistan',
      TK: 'Tokelau',
      TL: 'Timor-Leste',
      TM: 'Turkmenistan',
      TN: 'Tunisia',
      TO: 'Tonga',
      TR: 'Turkey',
      TT: 'Trinidad & Tobago',
      TV: 'Tuvalu',
      TW: 'Taiwan',
      TZ: 'Tanzania',
      UA: 'Ukraine',
      UG: 'Uganda',
      UM: 'U.S. Outlying Islands',
      UN: 'United Nations',
      US: 'United States',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VA: 'Vatican City',
      VC: 'St. Vincent & Grenadines',
      VE: 'Venezuela',
      VG: 'British Virgin Islands',
      VI: 'U.S. Virgin Islands',
      VN: 'Vietnam',
      VNC: 'Vietnam (HCMC)',
      VNH: 'Vietnam (Hanoi)',
      VU: 'Vanuatu',
      WF: 'Wallis & Futuna',
      WS: 'Samoa',
      XK: 'Kosovo',
      YE: 'Yemen',
      YT: 'Mayotte',
      ZA: 'South Africa',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
    },
    productClassAttributes: {
      Malt: {
        productionStep: [
          { value: 'Raw Barley', label: 'Raw Barley' },
          { value: 'Steeped Barley', label: 'Steeped Barley' },
          { value: 'Kilned Malt', label: 'Kilned Malt' },
          { value: 'Roasted Malt', label: 'Roasted Malt' },
          { value: 'Wort', label: 'Wort' },
        ],
      },
      Tea: {
        class: [
          { value: 'Green', label: 'Green' },
          { value: 'Yellow', label: 'Yellow' },
          { value: 'Oolong', label: 'Oolong' },
          {
            value: 'Red (English Black Tea)',
            label: 'Red (English Black Tea)',
          },
          { value: 'Ripe Puerh (Shu Puer)', label: 'Ripe Puerh (Shu Puer)' },
          { value: 'Raw Puerh (Sheng Puer)', label: 'Raw Puerh (Sheng Puer)' },
        ],
        seasonOfHarvest: [
          { value: 'Spring', label: 'Spring' },
          { value: 'Summer', label: 'Summer' },
          { value: 'Autumn', label: 'Autumn' },
          { value: 'Winter', label: 'Winter' },
        ],
        roastLevel: [
          { value: 'Low', label: 'Low' },
          { value: 'Medium', label: 'Medium' },
          { value: 'High', label: 'High' },
        ],
        roastType: [
          { value: 'Charcoal (Traditional)', label: 'Charcoal (Traditional)' },
          { value: 'Electric', label: 'Electric' },
          {
            value: 'Hard Wood (Traditional)',
            label: 'Hard Wood (Traditional)',
          },
          { value: 'Smoked (Traditional)', label: 'Smoked (Traditional)' },
          { value: 'Gas', label: 'Gas' },
          { value: 'Roller Drum', label: 'Roller Drum' },
          { value: 'Conveyor Belt', label: 'Conveyor Belt' },
          { value: 'Pine Smoked', label: 'Pine Smoked' },
        ],
        brewMethod: [
          { value: 'Yixing Teapot', label: 'Yixing Teapot' },
          { value: 'Gaiwan', label: 'Gaiwan' },
          { value: 'Ping Bi Bei', label: 'Ping Bi Bei' },
          { value: 'Side-handle Pot', label: 'Side-handle Pot' },
          { value: 'Bowl Tea', label: 'Bowl Tea' },
          { value: 'Thermos', label: 'Thermos' },
          { value: 'Tea Bag', label: 'Tea Bag' },
        ],
        cupMaterial: [
          { value: 'Dehua Porcelain', label: 'Dehua Porcelain' },
          { value: 'Jingdezhen', label: 'Jingdezhen' },
          { value: 'Clay', label: 'Clay' },
          { value: 'River Clay', label: 'River Clay' },
          { value: 'Glass', label: 'Glass' },
          { value: 'Celadon', label: 'Celadon' },
          { value: 'Plastic', label: 'Plastic' },
        ],
      },
      Spirits: {
        typeOfSpirit: [
          { value: 'Rum', label: 'Rum' },
          { value: 'Brandy', label: 'Brandy' },
          { value: 'Whiskey', label: 'Whiskey' },
          { value: 'Tequila', label: 'Tequila' },
          { value: 'Vodka', label: 'Vodka' },
          { value: 'Gin', label: 'Gin' },
          { value: 'Bourbon', label: 'Bourbon' },
          { value: 'Scotch', label: 'Scotch' },
          { value: 'Cognac', label: 'Cognac' },
          { value: 'Absinthe', label: 'Absinthe' },
          { value: 'Applejack', label: 'Applejack' },
          { value: 'Mezcal', label: 'Mezcal' },
          { value: 'Neutral Grain Spirit', label: 'Neutral Grain Spirit' },
          { value: 'Ouzo', label: 'Ouzo' },
          { value: 'Sake', label: 'Sake' },
          { value: 'Soju', label: 'Soju' },
          { value: 'Shochu', label: 'Shochu' },
          { value: 'Baijiu', label: 'Baijiu' },
          { value: 'Chhaang', label: 'Chhaang' },
          { value: 'Armagnac', label: 'Armagnac' },
          { value: 'Fruit Brandy', label: 'Fruit Brandy' },
          { value: 'Eau-de-vie', label: 'Eau-de-vie' },
          { value: 'Schnapps', label: 'Schnapps' },
        ],
        preparation: [
          { value: 'Neat', label: 'Neat' },
          { value: 'Rocks', label: 'Rocks' },
          { value: 'Soda', label: 'Soda' },
          { value: 'Watered', label: 'Watered' },
        ],
      },
      Beer: {
        ccp: [
          { value: 'Pre-boil Wort', label: 'Pre-boil Wort' },
          { value: 'Wort Chilling', label: 'Wort-Chilling' },
          { value: 'End of Fermentation', label: 'End of Fermentation' },
          { value: 'Pre-filtering', label: 'Pre-filtering' },
          { value: 'Post-filtering', label: 'Post-filtering' },
          { value: 'Brite Beer', label: 'Brite Beer' },
          { value: 'Packaged Beer', label: 'Packaged Beer' },
          { value: 'Degradation Testing', label: 'Degradation Testing' },
        ],
        style: [
          { value: 'Abby Double', label: 'Abby Double' },
          { value: 'Adjunct', label: 'Adjunct' },
          { value: 'Ale', label: 'Ale' },
          { value: 'Altbier', label: 'Altbier' },
          { value: 'Amber Ale', label: 'Amber Ale' },
          { value: 'Apple Beer', label: 'Apple Beer' },
          { value: 'Baltic Porter', label: 'Baltic Porter' },
          { value: 'Barleywine', label: 'Barleywine' },
          { value: 'Biere de Champagne', label: 'Biere de Champagne' },
          { value: 'Biere de Garde', label: 'Biere de Garde' },
          { value: 'Black Ale', label: 'Black Ale' },
          { value: 'Black and Tan', label: 'Black and Tan' },
          { value: 'Blonde Ale', label: 'Blonde Ale' },
          { value: 'Bock', label: 'Bock' },
          { value: 'Braggot', label: 'Braggot' },
          { value: 'Brown Ale', label: 'Brown Ale' },
          { value: 'California Common', label: 'California Common' },
          { value: 'Chile', label: 'Chile' },
          { value: 'Christmas Ale', label: 'Christmas Ale' },
          { value: 'Cream', label: 'Cream' },
          { value: 'Dark', label: 'Dark' },
          { value: 'Dark Ale', label: 'Dark Ale' },
          { value: 'Dark Mild', label: 'Dark Mild' },
          { value: 'Dark Wheat', label: 'Dark Wheat' },
          { value: 'Dortmunder', label: 'Dortmunder' },
          { value: 'Double Boc', label: 'Double Boc' },
          { value: 'Dubbel', label: 'Dubbel' },
          { value: 'Dunkel', label: 'Dunkel' },
          { value: 'Dunkelwiezen', label: 'Dunkelwiezen' },
          { value: 'Eisbock', label: 'Eisbock' },
          { value: 'ESA', label: 'ESA' },
          { value: 'ESB', label: 'ESB' },
          { value: 'Faro', label: 'Faro' },
          { value: 'Flanders Oud Bruin', label: 'Flanders Oud Bruin' },
          { value: 'Flanders Red', label: 'Flanders Red' },
          { value: 'Fruit Ale', label: 'Fruit Ale' },
          { value: 'Gluten Free', label: 'Gluten Free' },
          { value: 'Gose', label: 'Gose' },
          { value: 'Grand Cru', label: 'Grand Cru' },
          { value: 'Gueuze', label: 'Gueuze' },
          { value: 'Happoshu', label: 'Happoshu' },
          { value: 'Harvest Ale', label: 'Harvest Ale' },
          { value: 'Helles', label: 'Helles' },
          { value: 'Helles Bock', label: 'Helles Bock' },
          { value: 'IPA', label: 'IPA' },
          { value: 'Irish Dry Stout', label: 'Irish Dry Stout' },
          { value: 'Irish Red', label: 'Irish Red' },
          { value: 'Japanese Rice', label: 'Japanese Rice' },
          { value: 'Kellerbier', label: 'Kellerbier' },
          { value: 'Kolsch', label: 'Kolsch' },
          { value: 'Kristalweizen', label: 'Kristalweizen' },
          { value: 'Kvass', label: 'Kvass' },
          { value: 'Lager', label: 'Lager' },
          { value: 'Lambic', label: 'Lambic' },
          { value: 'Maibock', label: 'Maibock' },
          { value: 'Malt Liquor', label: 'Malt Liquor' },
          { value: 'Milk Stout', label: 'Milk Stout' },
          { value: 'Nut Brown', label: 'Nut Brown' },
          { value: 'Oatmeal Stout', label: 'Oatmeal Stout' },
          { value: 'Oktoberfest', label: 'Oktoberfest' },
          { value: 'Pale Ale', label: 'Pale Ale' },
          { value: 'Pils', label: 'Pils' },
          { value: 'Pilsner', label: 'Pilsner' },
          { value: 'Porter', label: 'Porter' },
          { value: 'Pumpkin', label: 'Pumpkin' },
          { value: 'Quadrupel', label: 'Quadrupel' },
          { value: 'Roggenbier', label: 'Roggenbier' },
          { value: 'Russian Stout', label: 'Russian Stout' },
          { value: 'SRye', label: 'SRye' },
          { value: 'Sahti', label: 'Sahti' },
          { value: 'Saison', label: 'Saison' },
          { value: 'Schwarzbier', label: 'Schwarzbier' },
          { value: 'Scotch Ale', label: 'Scotch Ale' },
          { value: 'Scottish Gruit', label: 'Scottish Gruit' },
          { value: 'Spiced Ale', label: 'Spiced Ale' },
          { value: 'Steam', label: 'Steam' },
          { value: 'Stout', label: 'Stout' },
          { value: 'Strong Ale', label: 'Strong Ale' },
          { value: 'Strong Dark Ale', label: 'Strong Dark Ale' },
          { value: 'Tripel', label: 'Tripel' },
          { value: 'Tripel IPA', label: 'Tripel IPA' },
          { value: 'Vienna', label: 'Vienna' },
          { value: 'Weissbier', label: 'Weissbier' },
          { value: 'Weizenbock', label: 'Weizenbock' },
          { value: 'Wheat', label: 'Wheat' },
          { value: 'Wheatwine', label: 'Wheatwine' },
          { value: 'Winter Ale', label: 'Winter Ale' },
          { value: 'Winter Warmer', label: 'Winter Warmer' },
          { value: 'Witte', label: 'Witte' },
        ],
        visualClarity: [
          { value: 'Too Clear', label: 'Too Clear' },
          { value: 'Too Spec', label: 'Too Spec' },
          { value: 'Too Cloudy', label: 'Too Cloudy' },
        ],
      },
      Cigar: {
        shadeLevel: [
          { value: 'Low', label: 'Low' },
          { value: 'Medium', label: 'Medium' },
          { value: 'High', label: 'High' },
        ],
      },
      Coffee: {
        ccp: [
          {
            value: 'Green Mill (sample from source)',
            label: 'Green Mill (sample from source)',
          },
          { value: 'Pre-ship Sample', label: 'Pre-ship Sample' },
          { value: 'Warehouse Sample', label: 'Warehouse Sample' },
          { value: 'Pre-release', label: 'Pre-release' },
          { value: 'Concentrate', label: 'Concentrate' },
          { value: 'Freeze-dried', label: 'Freeze-dried' },
          { value: 'Instant', label: 'Instant' },
          {
            value: 'Ready to Drink (packaged coffee)',
            label: 'Ready to Drink (packaged coffee)',
          },
          {
            value: 'Consumer Ready (brewed coffee)',
            label: 'Consumer Ready (brewed coffee)',
          },
        ],
        preparation: [
          { value: 'Chemex', label: 'Chemex' },
          { value: 'French Press', label: 'French Press' },
          { value: 'Espresso', label: 'Espresso' },
          { value: 'V60', label: 'V60' },
          { value: 'Kalita Wave', label: 'Kalita Wave' },
          { value: 'Cupping', label: 'Cupping' },
          { value: 'Siphon', label: 'Siphon' },
          { value: 'Nel Drip', label: 'Nel Drip' },
          { value: 'Cold Brew', label: 'Cold Brew' },
          { value: 'Cold Brew (Nitro)', label: 'Cold Brew (Nitro)' },
          { value: 'Bee House', label: 'Bee House' },
          { value: 'Kyoto-Style Ice Drip', label: 'Kyoto-Style Ice Drip' },
          { value: 'Aeropress', label: 'Aeropress' },
          { value: 'Melitta', label: 'Melitta' },
          { value: 'Cleaver', label: 'Cleaver' },
          { value: 'Steampunk', label: 'Steampunk' },
          { value: 'BKON', label: 'BKON' },
          { value: 'Moka Pot', label: 'Moka Pot' },
          { value: 'Auto-Drip', label: 'Auto-Drip' },
          { value: 'Cowboy Coffee', label: 'Cowboy Coffee' },
          { value: 'Percolator', label: 'Percolator' },
          { value: 'Keurig', label: 'Keurig' },
        ],
        waterType: [
          { value: 'Tap', label: 'Tap' },
          { value: 'Spring', label: 'Spring' },
          { value: 'Reverse Osmosis (RO)', label: 'Reverse Osmosis (RO)' },
          { value: 'Distilled', label: 'Distilled' },
        ],
        greenProcessing: [
          { value: 'Washed (wet)', label: 'Washed (wet)' },
          { value: 'Natural (dry)', label: 'Natural (dry)' },
          { value: 'Red Honey', label: 'Red Honey' },
          { value: 'Black Honey', label: 'Black Honey' },
          { value: 'Yellow Honey', label: 'Yellow Honey' },
          { value: 'Pulped Natural', label: 'Pulped Natural' },
          { value: 'Eco-Pulp', label: 'Eco-Pulp' },
          {
            value: 'Burundi (Single Fermentation)',
            label: 'Burundi (Single Fermentation)',
          },
          {
            value: 'Burundi (Double Fermentation)',
            label: 'Burundi (Double Fermentation)',
          },
          { value: 'Double Fermentation', label: 'Double Fermentation' },
        ],
      },
      'Non-Dairy Milk': {
        category: [
          { value: 'Nut-Milk', label: 'Nut-Milk' },
          { value: 'Plant-Milk', label: 'Plant-Milk' },
          { value: 'Blend', label: 'Blend' },
        ],
        flavor: [
          { value: 'Regular', label: 'Regular' },
          { value: 'Vanilla', label: 'Vanilla' },
          { value: 'Chocolate', label: 'Chocolate' },
        ],
        style: [
          { value: 'Almond', label: 'Almond' },
          { value: 'Cashew', label: 'Cashew' },
          { value: 'Coconut', label: 'Coconut' },
          { value: 'Hazelnut', label: 'Hazelnut' },
          { value: 'Oat', label: 'Oat' },
          { value: 'Chia', label: 'Chia' },
          { value: 'Pistachio', label: 'Pistachio' },
          { value: 'Dates', label: 'Dates' },
          { value: 'Sesame', label: 'Sesame' },
          { value: 'Brazil Nut', label: 'Brazil Nut' },
          { value: 'Walnut Flax', label: 'Walnut Flax' },
          { value: 'Pecan', label: 'Pecan' },
          { value: 'Rice', label: 'Rice' },
          { value: 'Quinoa', label: 'Quinoa' },
          { value: 'Flax', label: 'Flax' },
          { value: 'Hemp', label: 'Hemp' },
        ],
      },
    },
    productClassAttributesLabels: {
      General: {
        Establishments: 'Establishments',
        Notes: 'Notes',
      },
      Date: {
        'Date of Production': 'Date of Production',
        'Date of Expiration': 'Date of Expiration',
      },
      Batch: {
        'Batch Number': 'Batch Number',
      },
      Malt: {
        'Production Step': 'Production Step',
        'Grain Varietal': 'Grain Varietal',
        'Roast Temperature': 'Roast Temperature(s) (°F)',
        'Kiln Time': 'Kiln Time',
        'Kiln Temperature': 'Kiln Temperature(s) (°F)',
        'Moisture Content': 'Moisture Content',
      },
      Soda: {
        Syrup: 'Syrup',
        Packaging: 'Packaging',
        Container: 'Container',
        'Serving Temperature': 'Serving Temperature',
        Style: 'Style',
      },
      Tea: {
        Class: 'Class',
        Region: 'Region',
        Source: 'Source',
        'Season of Harvest': 'Season of Harvest',
        'Year of Harvest': 'Year of Harvest',
        'Roast Level': 'Roast Level',
        'Roast Type': 'Roast Type',
        Cultivar: 'Cultivar',
        CTC: 'CTC',
        'Brew Method': 'Brew Method',
        'Material of Brewing Vessel': 'Material of Brewing Vessel',
        'Brew Number': 'Brew Number',
        'Weight of Tea': 'Weight of Tea',
        'Volume of Water': 'Volume of Water',
        'Cup Material': 'Cup Material',
        Brewer: 'Brewer',
      },
      Spirits: {
        'Type of Spirit': 'Type of Spirit',
        Age: 'Age',
        Edition: 'Edition',
        ABV: 'ABV (%)',
        'Bottle Number': 'Bottle Number',
        'Barrel Number': 'Barrel Number',
        'Rick Number': 'Rick Number',
        Warehouse: 'Warehouse',
        Maturation: 'Maturation',
        Preparation: 'Preparation',
      },
      Beer: {
        None: '',
        CCP: 'CCP',
        Packaging: 'Packaging',
        ABV: 'ABV (%)',
        Nitrogen: 'Nitrogen',
        Style: 'Style',
        'Visual Clarity': 'Visual Clarity',
        'Serving Temperature': 'Serving Temperature',
      },
      Chocolate: {
        Cacao: 'Cacao (%)',
        Lecithin: 'Lecithin',
        Additions: 'Additions (hazel nut, coffee etc)',
      },
      Cigar: {
        Shape: 'Shape',
        Wrapper: 'Wrapper',
        Filler: 'Filler',
        Vintage: 'Vintage',
        'Shade Level': 'Shade Level (Full, Partial, None)',
        'Ring Gauge': 'Ring Gauge',
        Length: 'Length',
      },
      Coffee: {
        CCP: 'CCP',
        'Day of Roast': 'Day of Roast',
        Preparation: 'Preparation',
        Filter: 'Filter',
        'Harvest Date': 'Harvest Date',
        'Brew Duration': 'Brew Duration',
        'Bloom Duration': 'Bloom Duration',
        Grind: 'Grind',
        Weight: 'Weight',
        'Volume of Water': 'Volume of Water',
        'Water Type': 'Water Type',
        'Water Temperature': 'Water Temperature',
        'Brew Temperature': 'Brew Temperature',
        'Total Dissolved Solids': 'Total Dissolved Solids (PPM)',
        Elevation: 'Elevation',
        'Green Processing': 'Green Processing',
        Farm: 'Farm',
        Barista: 'Barista',
      },
      Wine: {
        Vintage: 'Vintage (Year)',
        Style: 'Style',
        ABV: 'ABV (%)',
        Sparkling: 'Sparkling',
        'Harvest Date': 'Harvest Date',
        Region: 'Region',
        'Grape Varietal': 'Grape Varietal',
        Decanted: 'Decanted',
        Aerated: 'Aerated',
      },
      Liquid: {
        Carbonated: 'Carbonated',
        Nitrogen: 'Nitrogen',
      },
      'Non-Dairy Milk': {
        Category: 'Category',
        'Limited Edition': 'Limited Edition',
        'Category (Others)': 'Category (Others)',
        'Limited Edition Notes': 'Limited Edition Notes',
        Flavor: 'Flavor',
        Carrageenan: 'Carrageenan',
        Style: 'Style',
        Sweetened: 'Sweetened',
      },
    },
    aiLiking: {
      title: 'AI Liking by GGVAR for a product',
      textureTitle: 'AI Liking by Texture for a product',
    },
    ggvar: {
      wet: 'Wet',
      roasted: 'Roasted',
      earthy: 'Earthy',
      astringent: 'Astringent',
      marine: 'Marine',
      mineral: 'Mineral',
      retronasal: 'Retronsasal',
      gamey: 'Gamey',
      woody: 'Woody',
      bitter: 'Bitter',
      meaty: 'Meaty',
      smoked: 'Smoked',
      dry: 'Dry',
      rich: 'Rich',
      dairy: 'Dairy',
      mouthfeel: 'Mouth Feel',
      nutsAndSeeds: 'Nuts and Seeds',
      herbaceous: 'Herbaceous',
      coldFinish: 'Cold Finish',
      floral: 'Floral',
      spices: 'Spices',
      sourAndAcidity: 'Sour and Acidity',
      fruits: 'Fruits',
      sugar: 'Sugar',
    },
    referenceFlavors: {
      roasted: {
        Toast: 'Toast',
        Bread: 'Bread',
        'Bread Crumbs': 'Bread Crumbs',
        'Bread Crust': 'Bread Crust',
        Pumpernickel: 'Pumpernickel',
        'Caramelized Vegetables': 'Caramelized Vegetables',
        Malt: 'Malt',
        'Malted Barley': 'Malted Barley',
        'Beer Grain': 'Beer Grain',
        'Roasted Wheat': 'Roasted Wheat',
        Pretzels: 'Pretzels',
        'Roasting Coffee': 'Roasting Coffee',
        Granola: 'Granola',
        Muesli: 'Muesli',
        'Rolled Oats': 'Rolled Oats',
        Oatmeal: 'Oatmeal',
        'Brown Rice': 'Brown Rice',
        'Rice Hulls': 'Rice Hulls',
        Caramelization: 'Caramelization',
        Rye: 'Rye',
        'Roast Nuts': 'Roast Nuts',
      },
      earthy: {
        Cellar: 'Cellar',
        Mushroom: 'Mushroom',
        Mulch: 'Mulch',
        Mold: 'Mold',
        Dust: 'Dust',
        Stone: 'Stone',
        Dirt: 'Dirt',
        'Wet Earth': 'Wet Earth',
        'Forest Floor': 'Forest Floor',
        'Damp Soil': 'Damp Soil',
        'Potting Soil': 'Potting Soil',
        Peat: 'Peat',
        'Smoked Peat': 'Smoked Peat',
        Hummus: 'Hummus',
        Morels: 'Morels',
        Clay: 'Clay',
        Mud: 'Mud',
        'Shu Puer': 'Shu Puer',
        Pond: 'Pond',
        'Wheat Bran': 'Wheat Bran',
        Potato: 'Potato',
        Tubers: 'Tubers',
        Cassava: 'Cassava',
        Taro: 'Taro',
        Beets: 'Beets',
      },
      marine: {
        Shellfish: 'Shellfish',
        Winkles: 'Winkles',
        Mussels: 'Mussels',
        Oysters: 'Oysters',
        Snails: 'Snails',
        Clams: 'Clams',
        Crab: 'Crab',
        'Florida Lobster': 'Florida Lobster',
        'Maine Lobster': 'Maine Lobster',
        Shrimp: 'Shrimp',
        Crawdads: 'Crawdads',
        Langostino: 'Langostino',
        Salmon: 'Salmon',
        Tuna: 'Tuna',
        Sushi: 'Sushi',
        Herring: 'Herring',
        Eel: 'Eel',
        'Fish Skin': 'Fish Skin',
        Iodine: 'Iodine',
        Seaweed: 'Seaweed',
        Kelp: 'Kelp',
        Kombu: 'Kombu',
        'White Fish': 'White Fish',
        Squid: 'Squid',
        Octopus: 'Octopus',
        Cuttlefish: 'Cuttlefish',
        'Craw Dads': 'Craw Dads',
        'Fish oil': 'Fish Oil',
        Lox: 'Lox',
        Mackarel: 'Mackarel',
      },
      mineral: {
        Salt: 'Salt',
        Calcium: 'Calcium',
        Iron: 'Iron',
        Carbon: 'Carbon',
        Sulfur: 'Sulfur',
        Sodium: 'Sodium',
        Iodine: 'Iodine',
        Copper: 'Copper',
        Nitrates: 'Nitrates',
        Zinc: 'Zinc',
        Potassium: 'Potassium',
        'Kosher Salt': 'Kosher Salt',
        'Sea Salt': 'Sea Salt',
        Fluoride: 'Fluoride',
        Magnesium: 'Magnesium',
        Phosphorus: 'Phosphorus',
        Metallic: 'Metallic',
        Ammonia: 'Ammonia',
        Chlorine: 'Chlorine',
      },
      gamey: {
        Leather: 'Leather',
        Tannery: 'Tannery',
        Musk: 'Musk',
        Wool: 'Wool',
        Manure: 'Manure',
        Barnyard: 'Barnyard',
        Stable: 'Stable',
        Truffle: 'Truffle',
        Mushroom: 'Mushroom',
        Puer: 'Puer',
        Goat: 'Goat',
        Venison: 'Venison',
        'Game Meat': 'Game Meat',
        Koji: 'Koji',
        'Shu Puer': 'Shu Puer',
        'Sheng Puer': 'Sheng Puer',
        Sulfur: 'Sulfur',
        Kraut: 'Kraut',
        Musty: 'Musty',
        Ammonia: 'Ammonia',
        Egg: 'Egg',
      },
      woody: {
        Potpourri: 'Potpourri',
        Sandalwood: 'Sandalwood',
        'Balsa Wood': 'Balsa Wood',
        'Pine Wood': 'Pine Wood',
        Cedar: 'Cedar',
        Mesquite: 'Mesquite',
        Oak: 'Oak',
        'French Oak': 'French Oak',
        'American Oak': 'American Oak',
        'Damp Leaves': 'Damp Leaves',
        Moss: 'Moss',
        Fern: 'Fern',
        'Tea Stems': 'Tea Stems',
        'Samovar Tea': 'Samovar Tea',
        'Twig Tea': 'Twig Tea',
        'Wood Chips': 'Wood Chips',
        'Apple Wood': 'Apple Wood',
        'Maple Wood': 'Maple Wood',
        Hickory: 'Hickory',
        Resinous: 'Resinous',
        'Bourbon Chips': 'Bourbon Chips',
      },
      meaty: {
        'Game Meat': 'Game Meat',
        Poultry: 'Poultry',
        'Cured Meats': 'Cured Meats',
        Sausage: 'Sausage',
        'Blood Sausage': 'Blood Sausage',
        Beef: 'Beef',
        Pork: 'Pork',
        Goat: 'Goat',
        Buffalo: 'Buffalo',
        Venison: 'Venison',
        'Dried Meat': 'Dried Meat',
        Prosciutto: 'Prosciutto',
        Liver: 'Liver',
        Heart: 'Heart',
        Gizzard: 'Gizzard',
        Skin: 'Skin',
        BBQ: 'BBQ',
        Bacon: 'Bacon',
        Moose: 'Moose',
        Lamb: 'Lamb',
        Sheep: 'Sheep',
        Mutton: 'Mutton',
        Kangaroo: 'Kangaroo',
        Alligator: 'Alligator',
        'Wild Boar': 'Wild Boar',
        Chicken: 'Chicken',
        Turkey: 'Turkey',
        Duck: 'Duck',
        Goose: 'Goose',
        Egg: 'Egg',
      },
      smoked: {
        'Smoked Meat': 'Smoked Meat',
        Juniper: 'Juniper',
        Mesquite: 'Mesquite',
        Charcoal: 'Charcoal',
        'Maple Wood': 'Maple Wood',
        Hickory: 'Hickory',
        Chicory: 'Chicory',
        Carbon: 'Carbon',
        BBQ: 'BBQ',
        'Camp Fire': 'Camp Fire',
        Yakitori: 'Yakitori',
        'Shao Kao': 'Shao Kao',
        Burnt: 'Burnt',
        Tobacco: 'Tobacco',
        Incense: 'Incense',
        Hardwood: 'Hardwood',
        Resinous: 'Resinous',
        Robata: 'Robata',
        'Smoked Peat': 'Smoked Peat',
        Charred: 'Charred',
        Applewood: 'Applewood',
        Acacia: 'Acacia',
        'Cherry Wood': 'Cherry Wood',
      },
      rich: {
        'Raw Cocoa': 'Raw Cocoa',
        'Milk Chocolate': 'Milk Chocolate',
        'Dark Chocolate': 'Dark Chocolate',
        Caramel: 'Caramel',
        'Caramelized Onions': 'Caramelized Onions',
        Figs: 'Figs',
        Dates: 'Dates',
        'Animal Brains': 'Animal Brains',
        Cheesecake: 'Cheesecake',
        Liver: 'Liver',
        Anchovies: 'Anchovies',
        Oil: 'Oil',
        'Hot Chocolate': 'Hot Chocolate',
        Miso: 'Miso',
        'Beef Stock': 'Beef Stock',
        'Chicken Stock': 'Chicken Stock',
        'Mushroom Stock': 'Mushroom Stock',
        Rum: 'Rum',
        Bourbon: 'Bourbon',
        Chicory: 'Chicory',
        'Cafe au lait': 'Cafe au lait',
        Koji: 'Koji',
        Olive: 'Olive',
        Tomato: 'Tomato',
        'Soy Sauce': 'Soy Sauce',
        Clove: 'Clove',
        Anise: 'Anise',
        Coconut: 'Coconut',
        Wine: 'Wine',
        Toffee: 'Toffee',
        'Sesame Oil': 'Sesame Oil',
        'Coconut Oil': 'Coconut Oil',
        'Walnut Oil': 'Walnut Oil',
        'Baby Oil': 'Baby Oil',
        Egg: 'Egg',
      },
      dairy: {
        Milk: 'Milk',
        Cream: 'Cream',
        Yogurt: 'Yogurt',
        Buttermilk: 'Buttermilk',
        'Sour Cream': 'Sour Cream',
        Cheese: 'Cheese',
        'Soft Cheese': 'Soft Cheese',
        'Cream Cheese': 'Cream Cheese',
        Ghee: 'Ghee',
        'Clarified Butter': 'Clarified Butter',
        'Dulce de Leche': 'Dulce de Leche',
        Flan: 'Flan',
        'Goat Milk': 'Goat Milk',
        'Goat Cheese': 'Goat Cheese',
        'Sheep Milk': 'Sheep Milk',
        'Sheep Cheese': 'Sheep Cheese',
        Butter: 'Butter',
        Creamy: 'Creamy',
        'Lactic Acid': 'Lactic Acid',
        'Soy Milk': 'Soy Milk',
        'Cottage Cheese': 'Cottage Cheese',
        Egg: 'Egg',
      },
      nutsAndSeeds: {
        Almonds: 'Almonds',
        Pistachios: 'Pistachios',
        'Pine Nuts': 'Pine Nuts',
        'Sesame Seeds': 'Sesame Seeds',
        Hazelnuts: 'Hazelnuts',
        Walnuts: 'Walnuts',
        Cashews: 'Cashews',
        Peanuts: 'Peanuts',
        'Ginkgo Nut': 'Ginkgo Nut',
        'Sunflower Seeds': 'Sunflower Seeds',
        'Poppy Seeds': 'Poppy Seeds',
        Chestnuts: 'Chestnuts',
        Pecans: 'Pecans',
        'Brazil Nuts': 'Brazil Nuts',
        'Macadamia Nuts': 'Macadamia Nuts',
        Yeast: 'Yeast',
        'Roast Nuts': 'Roast Nuts',
        'Kola Nut': 'Kola Nut',
        Acorn: 'Acorn',
        Candlenut: 'Candlenut',
        'Water Chestnut': 'Water Chestnut',
        'Palm Nuts': 'Palm Nuts',
        'Betel Nut': 'Betel Nut',
        'Black Walnut': 'Black Walnut',
        'Black Sesame': 'Black Sesame',
        'White Sesame': 'White Sesame',
        'Jack Nut': 'Jack Nut',
        'Soy Bean': 'Soy Bean',
        Flax: 'Flax',
      },
      herbaceous: {
        Watercress: 'Watercress',
        Pine: 'Pine',
        'Pine Tree': 'Pine Tree',
        Lemongrass: 'Lemongrass',
        Gangala: 'Gangala',
        Dill: 'Dill',
        Celery: 'Celery',
        Cilantro: 'Cilantro',
        Fenugreek: 'Fenugreek',
        Fennel: 'Fennel',
        Cabbage: 'Cabbage',
        Oregano: 'Oregano',
        Basil: 'Basil',
        'Bay Leaf': 'Bay Leaf',
        Sage: 'Sage',
        'Mustard Green': 'Mustard Green',
        Kale: 'Kale',
        'Mown Grass': 'Mown Grass',
        Hay: 'Hay',
        Sencha: 'Sencha',
        Matcha: 'Matcha',
        'Green Tea': 'Green Tea',
        Tobacco: 'Tobacco',
        Hops: 'Hops',
        Mint: 'Mint',
        Grassy: 'Grassy',
        Squash: 'Squash',
        'Green Pepper': 'Green Pepper',
        'Banana Flower': 'Banana Flower',
        Eucalyptus: 'Eucalyptus',
        'Elephant Garlic': 'Elephant Garlic',
        Onion: 'Onion',
        Garlic: 'Garlic',
        Cucumber: 'Cucumber',
        Thyme: 'Thyme',
        Artichoke: 'Artichoke',
        'Malic Acid': 'Malic Acid',
        Radicchio: 'Radicchio',
        Asparagus: 'Asparagus',
        'White Asparagus': 'White Asparagus',
        Leek: 'Leek',
        'Wheat Grass': 'Wheat Grass',
        Galangala: 'Galangala',
        Galangal: 'Galangal',
        Chrysanthemum: 'Chrysanthemum',
        Vegetable: 'Vegetable',
      },
      floral: {
        Roses: 'Roses',
        'Orange Blossom': 'Orange Blossom',
        Peony: 'Peony',
        Lily: 'Lily',
        Lilac: 'Lilac',
        Poppies: 'Poppies',
        Tulips: 'Tulips',
        Jasmine: 'Jasmine',
        Daisy: 'Daisy',
        Daffodil: 'Daffodil',
        Lavender: 'Lavender',
        'Cherry Blossom': 'Cherry Blossom',
        'Elder Flower': 'Elder Flower',
        Juniper: 'Juniper',
        Lemongrass: 'Lemongrass',
        Honeysuckle: 'Honeysuckle',
        Magnolia: 'Magnolia',
        'Rose Hips': 'Rose Hips',
        Hibiscus: 'Hibiscus',
        Frangipani: 'Frangipani',
        Bergamot: 'Bergamot',
        Hops: 'Hops',
        Anise: 'Anise',
        'Oolong Tea': 'Oolong Tea',
        Chrysanthemum: 'Chrysanthemum',
      },
      spices: {
        Ginger: 'Ginger',
        Clove: 'Clove',
        Allspice: 'Allspice',
        Nutmeg: 'Nutmeg',
        Ginseng: 'Ginseng',
        Cardamom: 'Cardamom',
        Cinnamon: 'Cinnamon',
        Anise: 'Anise',
        Vanilla: 'Vanilla',
        Coriander: 'Coriander',
        Peppercorns: 'Peppercorns',
        'Red Pepper Flakes': 'Red Pepper Flakes',
        'Ginger Bread': 'Ginger Bread',
        Turmeric: 'Turmeric',
        'Mustard Seeds': 'Mustard Seeds',
        Masala: 'Masala',
        Curry: 'Curry',
        Tagine: 'Tagine',
        Garlic: 'Garlic',
        Caraway: 'Caraway',
        Camphoric: 'Camphoric',
        'Mulling Spice': 'Mulling Spice',
        Dianhong: 'Dianhong',
      },
      fruits: {
        Citrus: 'Citrus',
        Lemon: 'Lemon',
        Orange: 'Orange',
        Tangerine: 'Tangerine',
        Grapefruit: 'Grapefruit',
        Lime: 'Lime',
        Pomelo: 'Pomelo',
        Clementine: 'Clementine',
        Kumquat: 'Kumquat',
        'Tropical Fruit': 'Tropical Fruit',
        Mango: 'Mango',
        'Passion Fruit': 'Passion Fruit',
        Guava: 'Guava',
        Lychee: 'Lychee',
        Kiwi: 'Kiwi',
        Persimmon: 'Persimmon',
        Pomegranate: 'Pomegranate',
        Pineapple: 'Pineapple',
        Mamey: 'Mamey',
        Papaya: 'Papaya',
        Berries: 'Berries',
        Huckleberries: 'Huckleberries',
        Strawberry: 'Strawberry',
        Raspberry: 'Raspberry',
        Gooseberry: 'Gooseberry',
        'Elder Berry': 'Elder Berry',
        Nectarine: 'Nectarine',
        Pear: 'Pear',
        Apple: 'Apple',
        Plum: 'Plum',
        Cherry: 'Cherry',
        Grape: 'Grape',
        Peach: 'Peach',
        Apricot: 'Apricot',
        Prune: 'Prune',
        Fig: 'Fig',
        Dates: 'Dates',
        'Black Currant': 'Black Currant',
        Raisin: 'Raisin',
        Cranberry: 'Cranberry',
        Blueberry: 'Blueberry',
        'Red Currant': 'Red Currant',
        Coconut: 'Coconut',
        Banana: 'Banana',
        Tamarind: 'Tamarind',
        'Star Fruit': 'Star Fruit',
        'White Grape': 'White Grape',
        'Green Grape': 'Green Grape',
        'Red Grape': 'Red Grape',
        'Wine Grape': 'Wine Grape',
        'Concord Grape': 'Concord Grape',
        'Green Apple': 'Green Apple',
        'Red Apple': 'Red Apple',
        Watermelon: 'Watermelon',
        Honeydew: 'Honeydew',
        Cantaloupe: 'Cantaloupe',
        'Citric Acid': 'Citric Acid',
        'Tartaric Acid': 'Tartaric Acid',
        Jam: 'Jam',
        'Malic Acid': 'Malic Acid',
        Durian: 'Durian',
        Blackberry: 'Blackberry',
        Tagine: 'Tagine',
        Pectin: 'Pectin',
        Melon: 'Melon',
        Fermented: 'Fermented',
        'Dragon Fruit': 'Dragon Fruit',
        'Jack Fruit': 'Jack Fruit',
        Soursop: 'Soursop',
      },
      sugar: {
        Honey: 'Honey',
        'White Honey': 'White Honey',
        Molasses: 'Molasses',
        'Cane Sugar': 'Cane Sugar',
        'Brown Sugar': 'Brown Sugar',
        'Sugar Beets': 'Sugar Beets',
        'Moscavado Sugar': 'Moscavado Sugar',
        Agave: 'Agave',
        'Maple Syrup': 'Maple Syrup',
        'Stewed Fruit': 'Stewed Fruit',
        'Dried Fruit': 'Dried Fruit',
        Tangiri: 'Tangiri',
        Vanilla: 'Vanilla',
        Marshmallow: 'Marshmallow',
        Cola: 'Cola',
        Tamarind: 'Tamarind',
        Lemonade: 'Lemonade',
        Jam: 'Jam',
        Toffee: 'Toffee',
        Caramel: 'Caramel',
        'Cotton Candy': 'Cotton Candy',
        'Bubble Gum': 'Bubble Gum',
        Pectin: 'Pectin',
        "Confectioner's Sugar": "Confectioner's Sugar",
      },
    },
    textures: {},
    dataQuality: {
      tooltip: {
        allGgVar: 'User marked all 24 GGVars',
        maxGgVar: 'User indicated all selected GGVar with an intensity of 5',
        noReferenceFlavor: 'User did not select any reference flavors',
        insufficientGgVar: 'User has selected 3 or less GGVars',
        excessiveReferenceFlavor:
          'User has selected more than 40 reference flavors',
        fastReview: 'User completed the review in under 30 seconds',
        buttonMashing:
          'User selected reference flavors at a rate of ≥ 1 per 5 seconds',
        allMarksAtFive:
          'User indicated all selected GGVar with an intensity of 5',
        excessiveGGVar: 'User has selected more than 15 GGVars',
        excessiveTextures: 'User has selected more than 15 textures',
        zeroMouthfeelInt: 'User gave mouthfeel intensity a 0 intensity',
        tooMany7s: 'User reported a PQ of 7 more than once per panel',
        agreementScore:
          'Ratio between user selected reference flavors that appeared more than 4 times and all selected reference flavors',
        reportedFlavors: 'Reported Flavors',
      },
    },
    languages: {
      languageSetting: 'Language Setting',
      selectLanguage: 'Select Language',
      saveSetting: 'Save Setting',
    },
    notification: {
      notificationList: 'Notification List',
      viewNotification: 'View Notification',
      notificationId: 'Notification ID',
      message: 'Message',
      sentOn: 'Sent On',
      submitter: 'Submitter',
      noNotification: 'No Notifications',
      remove: 'Remove',
      item: 'Item',
      messageQuickCreateProduct:
        'Please complete the remaining fields for product: ',
      messageCompletedPanel: 'All data is in for panel: ',
      messageMarketSurveyReportReady:
        'Market survey report - {{projectName}}, that you submitted for the {{demographic}} demographic is now ready for review.',
      messageOptimizationReportReady:
        'Optimization report - {{projectName}}, that you submitted for the {{demographic}} demographic is now ready for review.',
      seeAllNotifications: 'See all notifications',
      createNewNotification: 'Create Notification',
      messageInput: 'Type a message',
      editMessageInput: 'Edit message',
    },
    behaviorQuestion: {
      selectAll: 'Select All Questions',
      types: {
        GENERAL: 'General Behavior Questions',
        FREQUENCY: 'Frequency of Consumption',
        POINT_OF_PURCHASE: 'Point of Purchase',
        CUSTOM: 'Custom',
        DEFAULT: 'Default',
        WHISKEY: 'Whiskey',
      },
    },
    glossary: {
      ggVarTitle: 'GGVars Definitions',
      astringent:
        'Astringent: Tactile sensation of biting and/or sharp-like character in the mouth. Bitter: The sensation and taste of bitterness in the mouth.',
      coldFinish:
        'Cold Finish: The perceived cooling effect and sensation of a product. It does not directly relate to a product’s temperature but rather to the “fresh, cooling breath” after having consumed the product.',
      dairy:
        'Dairy: The flavors derived from animal milk, both cultured and non-cultured.',
      dry: 'Dry: The tactile sensation of dryness in the mouth. The opposite of ‘dry’ as a sensory attribute is not ‘wet,’ contrary to common sense. A product can be both ‘dry’ and ‘wet’ at different points of time while you taste it. ‘wet’ and ‘dry’ are not antagonistic.',
      earthy:
        'Earthy: Flavor and smell of products coming from the earth, including forest, forest-based plant-material, dirt, clay.',
      floral: 'Floral: The flavor/smell that have flower-like characteristics.',
      fruit:
        'Fruit: The flavor/smell of fruits in a product. It includes fresh fruits and dried fruits (such as dried coconut, dates or dried apricots).',
      gamey:
        'Gamey: Flavors of products that resemble the flavor and smell of game-meat and certain fermentations. It is often associate with strong, wild flavors.',
      herbaceous:
        'Herbaceous: Green-like and herb-like character. Herbaceous flavors are derived from soft pants, leaves, needles, sometimes twigs, soft stalks or tubers.',
      marine:
        'Marine: The flavor/smell of products coming from the sea, including fish, other marine animal life, marine plants and algae.',
      meaty:
        'Meaty: Flavors of products that have the taste of land animals or some plants that resemble that flavor (such as mushroom or tofu).',
      mineral:
        'Mineral: The flavor and smell of natural and inorganic substances (metals, minerals, and gasses).',
      mouthfeel:
        'Mouthfeel: The perceived viscosity and mouth-presence of a product. In liquid products, the ‘Mouthfeel’ is the resulting interaction of effervescent sensations, body, viscosity, weight, and overall presence in the mouth. In semi-solid and solid products, ‘Mouthfeel’ is best defined by presence (such as texture, elasticity, viscosity) of that product in the mouth.',
      nutsAndSeeds:
        'Fruits of certain plants, with or without a hard outer shell.',
      retronasal:
        'Retronasal: The aftertaste and presence after the product is consumed.',
      rich: 'Rich: Flavors of fats, oils, and foods often associated with satiety, or with a strong flavor intensity.',
      roasted:
        'Roasted: The flavor and smell of foods that have been heat treated at high temperatures, including Maillard Browning products.',
      smoked:
        'Smoked: Flavors of products that have the taste or smell caused by the burning of organic matter. Sour and Acidity: Perceived sour and/or acidic character within a product.',
      spice:
        'Spice: General culinary ingredient, which usually refer to various plant-based seeds, fruits, bark, roots or leaves and is generally consumed as a flavoring onto a dish. Salt and fresh herbs are not part of this category.',
      sugar: 'Sugar: The flavor/smell of sweetness in a product.',
      wet: 'Wet: The level of salivation caused by the consumption of a product. The opposite of ‘wet’ as a sensory attribute is not ‘dry,’ contrary to common sense. A product can be both ‘wet’ and ‘dry’ at different points of time while you taste it; ‘wet’ and ‘dry’ are not antagonistic.',
      woody:
        'Woody: The flavor of products that resemble the flavor and smell of wood from trees or other lignified plant material.',
      glossaryTitle: 'Glossary Terms and Acronyms',
      branch:
        'Branch: Workspace(s) used to pull the data for projects. A branch is client-specific and can’t be used by any other user.',
      categoryNorm:
        'Category Norm: The norms for the product category. It takes into consideration the PQs from all the products from the AFS database and the user branch for the product category and the target demographic. If any of those 2 parameters were to change, the category norm would change too.',
      driversOfPreference:
        'Drivers of Preference: The effect on PQ of the presence of each reference flavor at different intensities. The curves show the relationship between the PQ and the intensity of the reference flavor (when present). It shows univariate flavor profile changes, meaning that it doesn’t consider the cascading effect on other flavor profiles. For example, if you were to increase “coconut” from 1 to 2%, it could change the curves of other reference flavors and GGVars since the profile has fundamentally changed.',
      experienceRange:
        'Experience range: Ability to describe the flavors within a product, with 80% of the consumers in the 1 -3 range. A poor ability to describe flavor will be on the lower end.',
      ggVar: 'GGVar (Gastrograph Variable): Flavor attribute/category.',
      pq: 'PQ: Product preference score, measured on a 7 points hedonic scale. +/- 0.05 in PQ is normal “noise” when making predictions, and +/- 0.20 in PQ is considered a significant difference.',
      polarization:
        'Pol (Polarization): Spread of the PQ distribution. Most products fall in between 1 (low polarization, tending to be near-normally distributed) to 2 (high polarization, tending to be bi-modal distributed).',
      preferenceDistribution:
        'Preference Distribution: Predicted distribution of the PQ (on a 7 points scale).',
      referenceFlavor:
        'Reference flavor: Specific flavor present under a GGVar.',
      signature: 'Signature: Reference flavor.',
      socioEcon: 'Socio-Econ: Socio-Economic Status.',
      stackRankNorms:
        'Stack Rank Norms: The norms for the competitive set. It takes into consideration the PQs from the competitive set for the target demographic. If any of those 2 parameters were to change, the stack rank norm would change too.',
      targetDemo: 'Target Demo: Target Demographic.',
      tc: 'TC (Texture Cluster): Group of texture terms whose meanings are close to each other.',
      textureComposition:
        'Texture Composition: How many of the texture clusters are present on average in a product. The sum of the clusters doesn’t have to sum to 100%.',
      trunk: 'Trunk: AFS database. Data used to make predictions for anyone.',
      winRate:
        'Wr (Win Rate): The average percentage of target consumer demographic that prefer this product more than the rest of the other products in the competitive set.',
    },
    flavorSummary: {
      product: 'Product',
      localOnly: 'Small Changes Only',
      localOnlyTooltip: 'Limit effects to +/- 1.5 intensity',
      attributeIntensityTitle: 'Stack Rank Attribute Intensities',
    },
    stackRankComparison: {
      title: 'Stack Rank Comparison',
      pq: 'PQ',
      keyFlavors: 'Key Flavors',
      positiveDrivers: 'Positive Drivers',
      negativeDrivers: 'Negative Drivers',
    },
    explainers: {
      pqContent: ' is a measurement of preference on a 7-point hedonic scale.',
      winRateContent:
        ' is the average percentage of target consumer demographic that prefer this product more than the other products.',
      polarizationContent: ' describes the spread of PQ distribution.',
    },
    modelPlayground: {
      header: 'PQ Model',
      title: 'Model Playground',
    },
  },
};

export default en;
