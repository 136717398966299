import * as React from 'react';

import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import { lineBreak } from 'components/ReactDataTable/reactDataTableUtils';
import { format } from 'date-fns';
import DataTable, { TableColumn } from 'react-data-table-component';

import useStyles from './useStyles';
import { tableCustomStyles } from '../../components/ReactDataTable/tableCustomStyles';
import i18n from '../../i18n';

interface Props {
  workspaceReports: reports.EnrichedAllReportsQueryRow[];
  hideSearch: () => void;
  onClick: (report: reports.EnrichedAllReportsQueryRow) => void;
}

interface TableRow {
  id: number;
  reportId: string;
  projectName: string;
  reportTypeDisplay: string;
  demographic: string;
  user: string;
  date: Date;
}

const ProjectSearch: React.FC<Props> = ({
  workspaceReports,
  hideSearch,
  onClick,
}) => {
  const classes = useStyles(useTheme());

  const theme = useTheme();

  const columns: TableColumn<TableRow>[] = [
    {
      selector: (row: TableRow) => row.id,
      name: 'Id',
      sortable: true,
      omit: true,
    },
    {
      selector: (row: TableRow) => row.reportId,
      name: 'reportId',
      sortable: true,
      omit: true,
    },
    {
      selector: (row: TableRow) => row.projectName,
      name: i18n.t('panel.projectName'),
      sortable: true,
      cell: (row: TableRow) => lineBreak(row.projectName),
    },
    {
      selector: (row: TableRow) => row.reportTypeDisplay,
      name: i18n.t('reports.reportType'),
    },
    {
      selector: (row: TableRow) => row.demographic,
      name: i18n.t('reports.targetGroup'),
    },
    {
      selector: (row: TableRow) => row.user,
      name: i18n.t('reports.submitter'),
    },
    {
      selector: (row: TableRow) => format(row.date, 'LLL d, y'),
      name: i18n.t('reports.submittedOn'),
      cell: (row: TableRow) => format(row.date, 'LLL d, y'),
      sortable: true,
    },
  ];

  const rowClick = (row, event) => {
    hideSearch();
    onClick(row);
  };

  const mapToTableRow = (data: any[]): TableRow[] => {
    if (!data) {
      return [];
    }

    return data.map((r, idx) => ({
      id: r['id'],
      reportId: r['reportId'],
      projectName: r['projectName'],
      reportTypeDisplay: r['reportTypeDisplay'],
      demographic: r['demographic'],
      user: r['user'],
      date: r['date'],
    }));
  };

  return (
    <div>
      <Paper className={classes.container}>
        <div className={classes.tableContainer}>
          <DataTable
            columns={columns}
            data={mapToTableRow(workspaceReports)}
            customStyles={tableCustomStyles(theme, true)}
            striped
            onRowClicked={(row, event) => rowClick(row, event)}
          />
        </div>
      </Paper>
    </div>
  );
};

export default ProjectSearch;
