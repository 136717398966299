import * as React from 'react';

import { useTheme } from '@mui/material';
import ProductSearchTable from 'components/ProductSearchTable';
import ProducerPreference from 'containers/ProducerPreference';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AdminToolsHeader from './AdminToolsHeader';
import useStyles from './useStyles';
import AdminToolItem from '../../components/AdminToolItem';
import MaterialButton from '../../components/MaterialButton';
import { ADMIN_EDIT_USER_ROLE } from '../../constants/routePaths';
import { PagePaper } from '../../styles/themeComponents';
import AddClient from '../AddClient';
import AddUserToWorkspace from '../AddUserToWorkspace';
import ChangePassword from '../ChangePassword';
import CreateWorkspace from '../CreateWorkspace';
import PartnerBranding from '../PartnerBranding';
import UserCreate from '../UserCreate';
import ViewCategoriesAndProducts from '../ViewCategoriesAndProducts';

const AdminToolsContainer: React.FC = () => {
  const { t } = useTranslation();

  const styles = useStyles(useTheme());

  return (
    <PagePaper>
      <AdminToolsHeader />
      <br />

      <Link to={{ pathname: ADMIN_EDIT_USER_ROLE }}>
        <MaterialButton variant="outlined" soft teal>
          {t('users.editUserRole')}
        </MaterialButton>
      </Link>

      <br />
      <br />

      <AdminToolItem title={t('users.createUser')} component={<UserCreate />} />
      <AdminToolItem
        title={t('users.changePassword')}
        component={<ChangePassword />}
      />
      <AdminToolItem
        title={t('admin.addUserWorkspace')}
        component={<AddUserToWorkspace />}
      />
      <AdminToolItem
        title={t('admin.createWorkspace')}
        component={<CreateWorkspace />}
      />
      <AdminToolItem
        title={t('admin.producerPreference')}
        component={<ProducerPreference />}
      />
      <AdminToolItem
        title={t('admin.partnerBranding')}
        component={<PartnerBranding />}
      />
      <AdminToolItem
        title={t('admin.createClient')}
        component={<AddClient />}
      />
      <AdminToolItem
        title="Categories"
        component={<ViewCategoriesAndProducts />}
      />
      <AdminToolItem
        title={t('admin.searchProduct')}
        component={<ProductSearchTable />}
      />
    </PagePaper>
  );
};

export default AdminToolsContainer;
