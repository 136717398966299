import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      minWidth: 50,
    },
    selectProductView: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '60rem',
      paddingBlock: '0em 2em 1em',
    },
    select: {
      width: '80%',
      paddingRight: '5px',
    },
  }),
);

export default useStyles;
