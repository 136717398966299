import * as React from 'react';
import { ReactElement, useState } from 'react';

import { Search } from '@mui/icons-material';
import {
  Divider,
  List,
  Input,
  InputAdornment,
  Container,
  Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';

interface Props {
  onClickCloseMenu: () => any;
  onClickOpenMenu: () => any;
  menuIsOpen: boolean;
  renderWorkspaceLink: (params: {
    children: ReactElement;
    workspaceKey: number;
  }) => ReactElement;
  renderWorkspaceOption: (params: {
    workspaceKey: number;
    classes: object;
  }) => ReactElement;
  selectedWorkspaceKey: number;
  workspaceKeys: number[];
  workspaceProducerNamesByWorkspaceKey: Map<number, string>;
}

const SelectWorkspaceContainer: React.FunctionComponent<Props> = (props) => {
  const {
    onClickCloseMenu,
    onClickOpenMenu,
    menuIsOpen,
    renderWorkspaceLink,
    renderWorkspaceOption,
    selectedWorkspaceKey,
    workspaceKeys,
    workspaceProducerNamesByWorkspaceKey,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [searchString, setSearchString] = useState('');

  const filteredWorkspaceKeys = workspaceKeys
    .filter((id) =>
      workspaceProducerNamesByWorkspaceKey[id]
        .toLowerCase()
        .includes(searchString.toLowerCase()),
    )
    .sort((a, b) =>
      workspaceProducerNamesByWorkspaceKey[a]
        .toString()
        .localeCompare(workspaceProducerNamesByWorkspaceKey[b].toString()),
    );

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm">
      <Paper classes={{ root: classes.paper }}>
        <div className={classes.header}>{t('viewerMenu.selectWorkspace')}</div>
        <Input
          endAdornment={
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          }
          onChange={(e) => setSearchString(e.target.value)}
          placeholder={t('general.search')}
          value={searchString}
        />
        <Divider variant="fullWidth" />
        <List classes={{ root: classes.list }}>
          {filteredWorkspaceKeys.map((workspaceKey) =>
            renderWorkspaceLink({
              children: renderWorkspaceOption({
                workspaceKey,
                classes: classes,
              }),
              workspaceKey,
            }),
          )}
        </List>
      </Paper>
    </Container>
  );
};

SelectWorkspaceContainer.displayName = 'SelectWorkspaceContainer';

export default SelectWorkspaceContainer;
