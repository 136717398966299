import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportStackRankComparisonQuery from '@graphql/queries/ReportStackRankComparisonQuery';
import { Box } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import LoadingScreen from 'components/LoadingScreen';
import { useTranslation } from 'react-i18next';

import { ResponsivePieToolTip } from './ResponsivePieToolTip';
import { ComponentTitle } from '../../../../styles/themeComponents';
import { getFlavorDecompPieChartData } from '../utils';

interface Props {
  projectId: number;
  reportId: string;
}

const GGVarFlavorInsights: React.FC<Props> = ({ reportId }) => {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<reports.ReportStackRankComparison>(
    ReportStackRankComparisonQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <h3>{t('projects.categoryInsights.flavorAttributePerceptionError')}</h3>
    );
  }

  return (
    <Box height={500}>
      <ComponentTitle align="center">
        {t('projects.categoryInsights.flavorAttributePerceptionTitle')}
      </ComponentTitle>
      <ResponsivePie
        data={getFlavorDecompPieChartData(data.allRpFlavorDecomps.nodes)}
        colors={{ scheme: 'nivo' }}
        margin={{ top: 50, right: 100, bottom: 90, left: 100 }}
        enableArcLabels={false}
        arcLinkLabel={(datum) => `${datum.id}: ${datum.value}%`}
        arcLinkLabelsSkipAngle={5}
        arcLinkLabelsDiagonalLength={36}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        startAngle={90}
        endAngle={450}
        borderWidth={1}
        tooltip={ResponsivePieToolTip}
      />
    </Box>
  );
};

export default GGVarFlavorInsights;
