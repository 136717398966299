import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import FoldersQuery from '@graphql/queries/FoldersQuery';
import ProductsQuery from '@graphql/queries/ProductsQuery';
import { Image, Search as SearchIcon } from '@mui/icons-material';
import {
  Input,
  InputAdornment,
  LinearProgress,
  Paper,
} from '@mui/material';
import MaterialButton from 'components/MaterialButton';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import useWindowSize from 'hooks/useWindowSize';
import { get } from 'lodash';
import DataTable, {
  SortOrder,
  TableColumn,
  TableStyles,
} from 'react-data-table-component';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import selectProductFolderId from 'selectors/productFolderId';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import styles from './ProductTable.module.css';
import ProductSearch from '../../components/ProductSearch';
import {
  SEARCH_PRODUCT,
  VIEW_PRODUCT_FROM_TABLE,
} from '../../constants/googleAnalytics/actions';
import { CAT_PRODUCT_LIST } from '../../constants/googleAnalytics/categories';
import {
  PRODUCT,
  PRODUCT_CREATE,
  PRODUCT_CREATE_BULK,
} from '../../constants/routePaths';
import i18n from '../../i18n';
import { PageHeader } from '../../styles/themeComponents';
import formatPath from '../../utils/formatPath';
import { getDebouncedEventFn } from '../../utils/googleAnalyticsHelper';
import ConditionViewerRoleContainer from '../ConditionViewerRole';

const { event } = ReactGA;

const DATE_FORMAT = 'yyyy-MM-dd';

const debouncedEvent = getDebouncedEventFn(1000);

const productTableStyles: TableStyles = {
  table: {
    style: {
      maxHeight: 'none',
    },
  },
  rows: {
    style: {
      padding: '0.75rem',
    },
  },
};

const getFormattedProductData = (productResults) => {
  return (
    productResults &&
    productResults.products &&
    productResults.products.edges.map((edge) => ({
      id: edge.product.id,
      image: (
        <div className={styles.imageContainer}>
          {edge.product.productImages.totalCount ? (
            <img
              src={edge.product.productImages.nodes[0].url}
              alt={`${edge.product.id}_image`}
              className={styles.image}
            />
          ) : (
            <Image className={styles.image} />
          )}
        </div>
      ),
      name: (
        <Link
          key={edge.product.id}
          className={`restore-${edge.product.id}`}
          to={{
            pathname: formatPath(PRODUCT, { productId: edge.product.id }),
            state: edge.product.id,
          }}
          onClick={() =>
            event({
              category: CAT_PRODUCT_LIST,
              action: VIEW_PRODUCT_FROM_TABLE,
              label: edge.product.id.toString(),
            })
          }
        >
          {edge.product.name}
        </Link>
      ),
      brand: edge.product.brand,
      date: formatDate(parseISO(edge.product.createdAt), DATE_FORMAT),
      category:
        edge.product.productCategory && edge.product.productCategory.name,
      panel: edge.product.panelProducts.nodes
        .map(
          (panelProduct) =>
            panelProduct && panelProduct.panel && panelProduct.panel.pin,
        )
        .join(', '),
      reviews: edge.product.productReviews.totalCount,
      lastReview:
        get(edge.product, 'productReviews.nodes.length') > 0
          ? formatDate(
              parseISO(edge.product.productReviews.nodes[0].createdAt),
              DATE_FORMAT,
            )
          : '',
    }))
  );
};

interface TableRow {
  id: number;
  image: string;
  name: string;
  brand: string;
  date: string;
  category: string;
  panel: string;
  reviews: string;
  lastReview: string;
}

const ProductTable: React.FC = () => {
  const { t } = useTranslation();

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  const [page, setPage] = useState<number>(1);
  const [sizePerPage, setSizePerPage] = useState<number>(20);
  const [orderBy, setOrderBy] = useState({
    order: 'desc',
    column: 'lastActive',
    key: 'ID_DESC',
  });
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);

  const {
    widthBelow600,
    widthBelow700,
    widthBelow800,
    widthBelow900,
    widthBelow1000,
    widthBelow1100,
    widthBelow1400,
  } = useWindowSize();

  const producerId = useSelector((state) => selectWorkspaceProducerId(state));
  const productFolderId = useSelector((state) => selectProductFolderId(state));

  const {
    loading: productsLoading,
    error: productsError,
    data: productResults,
    fetchMore,
  } = useQuery(ProductsQuery, {
    variables: {
      offset: (page - 1) * sizePerPage,
      first: sizePerPage,
      condition: {
        producerId: producerId,
        folderId: productFolderId !== 0 ? productFolderId : undefined,
        visibility: true,
      },
      orderBy: orderBy.key,
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: foldersLoading,
    error: foldersError,
    data: folderResults,
  } = useQuery(FoldersQuery, {
    variables: {
      condition: {
        producerId: producerId,
      },
    },
  });

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    if (productResults) {
      setTotalRows(productResults.products.totalCount);
      setFormattedData(getFormattedProductData(productResults));
    }
  }, [productResults, orderBy]);

  const hideSearch = () => {
    setSearchString('');
    setShowSearch(false);
  };

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: 'Product ID',
      wrap: true,
      omit: true,
    },
    {
      id: 'image',
      selector: (row: TableRow) => row.image,
      name: <div className={styles.tableHeader}>{i18n.t('general.image')}</div>,
      wrap: true,
      omit: widthBelow1400,
    },
    {
      id: 'name',
      selector: (row: TableRow) => row.name,
      name: (
        <div className={styles.tableHeader}>
          {i18n.t('product.productName')}
        </div>
      ),
      sortable: true,
      sortField: 'NAME',
      wrap: true,
    },
    {
      id: 'brand',
      selector: (row: TableRow) => row.brand,
      name: (
        <div className={styles.tableHeader}>
          {i18n.t('product.productBrand')}
        </div>
      ),
      sortable: true,
      sortField: 'BRAND',
      wrap: true,
      omit: widthBelow600,
    },
    {
      id: 'date',
      selector: (row: TableRow) => row.date,
      name: (
        <div className={styles.tableHeader}>
          {i18n.t('general.dateCreated')}
        </div>
      ),
      sortable: true,
      sortField: 'CREATED_AT',
      wrap: true,
      omit: widthBelow1000,
    },
    {
      id: 'category',
      selector: (row: TableRow) => row.category,
      name: (
        <div className={styles.tableHeader}>
          {i18n.t('product.productCategory')}
        </div>
      ),
      wrap: true,
      omit: widthBelow700,
    },
    {
      id: 'panel',
      selector: (row: TableRow) => row.panel,
      name: (
        <div className={styles.tableHeader}>{i18n.t('product.panels')}</div>
      ),
      wrap: true,
      omit: widthBelow1100,
      style: styles.panelColumn,
    },
    {
      id: 'reviews',
      selector: (row: TableRow) => row.reviews,
      name: (
        <div className={styles.tableHeader}>{i18n.t('reviews.reviews')}</div>
      ),
      wrap: true,
      omit: widthBelow800,
    },
    {
      id: 'lastReview',
      selector: (row: TableRow) => row.lastReview,
      name: (
        <div className={styles.tableHeader}>{i18n.t('reviews.lastReview')}</div>
      ),
      wrap: true,
      omit: widthBelow900,
    },
  ];

  const handlePageChange = (newPage: number) => {
    fetchMore({
      variables: {
        offset: (newPage - 1) * sizePerPage,
        first: sizePerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
    setPage(newPage);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setSizePerPage(newPerPage);
    setPage(1);
  };

  const handleSort = async (
    column: TableColumn<TableRow>,
    sortDirection: SortOrder,
  ) => {
    setOrderBy({
      order: sortDirection,
      column: column.id as string,
      key: `${column.sortField}_${sortDirection.toUpperCase()}`,
    });
  };

  if (!producerId) {
    return <div />;
  }

  if (productsLoading || foldersLoading) {
    return <LinearProgress />;
  }

  if (productsError || foldersError) {
    return <div>ERROR: Unable to fetch data!</div>;
  }

  return (
    <Paper className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTextContainer}>
          <PageHeader>{t('navigation.products')}</PageHeader>
          <h3 className={styles.productTitle}>{t('product.gallery')}</h3>
        </div>
        <Input
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
          onChange={(event) => {
            setSearchString(event.target.value);
            setShowSearch(true);
            debouncedEvent({
              category: CAT_PRODUCT_LIST,
              action: SEARCH_PRODUCT,
              label: event.target.value,
            });
          }}
          placeholder={t('general.search')}
        />
        <div>
          <Link to={PRODUCT_CREATE}>
            <MaterialButton variant="outlined" soft teal>
              {t('product.createProduct')}
            </MaterialButton>
          </Link>
          <ConditionViewerRoleContainer
            render={(viewerRoles) =>
              (viewerRoles.viewerRoleIsSuperadmin ||
                viewerRoles.viewerRoleIsAdmin) && (
                <Link to={PRODUCT_CREATE_BULK}>
                  <MaterialButton variant="outlined" soft teal>
                    {t('product.createProductsBulk')}
                  </MaterialButton>
                </Link>
              )
            }
          />
        </div>
      </div>
      {searchString.length > 0 && showSearch && (
        <ProductSearch
          query={searchString}
          hideSearch={hideSearch}
          first={10}
        />
      )}
      {productResults.loading || folderResults.loading ? (
        <LinearProgress />
      ) : (
        <DataTable
          customStyles={productTableStyles}
          columns={columns}
          data={formattedData}
          progressPending={productsLoading || foldersLoading}
          progressComponent={<LinearProgress color="secondary" />}
          persistTableHead
          striped
          pagination
          paginationIconFirstPage={<RDTFirstPage />}
          paginationIconLastPage={<RDTLastPage />}
          paginationIconPrevious={<RDTPrevPage />}
          paginationIconNext={<RTDNextPage />}
          paginationServer
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          paginationPerPage={sizePerPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationTotalRows={totalRows}
          paginationDefaultPage={page}
          sortServer
          onSort={handleSort}
        />
      )}
    </Paper>
  );
};

export default ProductTable;
