import * as React from 'react';
import { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { Field } from 'redux-form';

import useMainStyles from './useStyles';
import FieldTextInput from '../FieldTextInput';

interface Props {
  chromeless: boolean;
  className?: string;
  required: boolean;
  descriptionText?: string;
  input?: any; // for compatibility with redux-form
  inputComponent: any;
  multiline?: boolean;
  style: any;
  labelText: string;
  customLabel: boolean;
  modalText?: string;
}

const OthersTextField = (props) => {
  return (
    <Field
      className={props.classes}
      name={`${props.name}.input`}
      component={FieldTextInput}
      placeholder={`Other ${props.placeholder}`}
    />
  );
};

const FormInput: React.FunctionComponent<Props> = ({
  chromeless,
  descriptionText,
  className,
  required,
  input,
  inputComponent,
  multiline,
  style,
  labelText,
  customLabel,
  modalText,
  ...rest
}) => {
  const InputComponent = inputComponent || (multiline ? 'textarea' : 'input');
  const { useStyles } = useMainStyles({
    chromeless,
    multiline,
    className,
    style,
  });
  const classes = useStyles(useTheme());

  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        className={classes.modal}
      >
        <div className={classes.modalContainer}>
          <h3>{labelText}</h3>
          {modalText && modalText.split('\n').map((line) => <p>{line}</p>)}
        </div>
      </Modal>

      <div className={classes.formContainer}>
        {customLabel ? (
          <div>
            <FormLabel
              className={classes.root}
              component="label"
              required={required}
            >
              {labelText}
            </FormLabel>
            {modalText && (
              <IconButton onClick={() => setShowModal(true)} size="large">
                <InfoIcon color="primary" />
              </IconButton>
            )}
          </div>
        ) : null}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <InputComponent
              className={classes.input}
              labelText={!customLabel && labelText}
              required={required}
              {...input}
              {...rest}
            />
            {descriptionText && (
              <div className="descriptionText">{descriptionText}</div>
            )}
          </div>

          {input.value.value === 'Others' ||
          (Array.isArray(input.value) &&
            input.value.some((option) => option?.value === 'Others')) ? (
            <OthersTextField
              name={`custom_${input.name}`}
              placeholder={labelText}
              classes={classes.otherField}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

FormInput.displayName = 'FormInput';

export default FormInput;
