import { connect } from 'react-redux';

import AdminDataExplorer from './adminDataExplorer';
import selectSessionToken from '../../selectors/sessionToken';

const mapStateToProps = (state) => ({
  sessionToken: selectSessionToken(state),
});

export default connect(mapStateToProps)(AdminDataExplorer);
