import { gql } from '@apollo/client';

export default gql`
query ReportStackRankQuery($reportID: UUID!) {
    allRpMarketPreferences(condition: {reportId: $reportID} ) {
      nodes {
        product: productByProductId {
          id
          name
        }
        pqRating
        percentPop
        version
      }
    }
  }
`
