import { gql } from '@apollo/client';

export default gql`
  query AllPartnersQuery{
    allPartners {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
