import * as React from 'react';

import UpsertCustomPqProductName from '@graphql/mutations/UpsertCustomPqProductName';
import { TextField, InputLabel } from '@mui/material';
import { Checkbox } from '@mui/material';
import graphqlClient from 'consumers/graphqlClient';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import selectViewerUserId from 'selectors/viewerUserId';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';
import {
  showOriginalProductName,
  useCustomerPreferences,
} from 'services/customerPreferences';

import styles from './ReportCustomProductCreateContainer.module.css';
import MaterialButton from '../../../../components/MaterialButton';

type Error = {
  name?: string;
  marketLabel?: string;
};

export const createErrors = (values: {
  canonicalName: string;
  name: string;
  marketLabel: string;
  labelColor: string;
}): Error => {
  const errors: Error = {};
  if (!values?.name?.length) {
    errors.name = 'Product name cannot be empty.';
  }
  if (values?.marketLabel?.length > 10) {
    errors.marketLabel = 'Market label must be less than 10 single characters.';
  }
  return errors;
};

interface Props {
  projectId: number;
  productId: number;
  canonicalName: string;
  productName: string;
  version: string;
  marketProductLabel: string;
  productLabelColor: string;
  closeModal: () => void;
  customList: reports.ColorVersionedProductInfo[];
  isHighlighted: boolean;
}

const ReportCustomProductCreateContainer: React.FC<Props> = ({
  productId,
  projectId,
  canonicalName,
  productName,
  version,
  marketProductLabel,
  productLabelColor,
  closeModal,
  customList,
  isHighlighted,
}) => {
  const { t } = useTranslation();

  const viewerUserId = useSelector((state) => selectViewerUserId(state));
  const workspaceId = useSelector((state) => selectWorkspaceProducerId(state));

  const customerPreferences = useCustomerPreferences();

  return (
    <div>
      <div className={styles.sectionContainer}>
        <Formik
          initialValues={{
            canonicalName: canonicalName || '',
            name: productName,
            marketLabel: marketProductLabel,
            labelColor: productLabelColor,
            isHighlighted: isHighlighted,
          }}
          validate={(values) => createErrors(values)}
          onSubmit={async (values) => {
            const { name, marketLabel, labelColor, isHighlighted } = values;
            await graphqlClient
              .mutate({
                mutation: UpsertCustomPqProductName,
                variables: {
                  input: {
                    projectId: projectId,
                    productIds: [productId],
                    productNames: [name],
                    marketProductLabels: [marketLabel],
                    versions: [version],
                    productLabelColors: [labelColor],
                    isHighlighted: [isHighlighted],
                  },
                },
              })
              .then(() => closeModal());
          }}
          render={({ values, handleChange, handleSubmit, errors, setFieldValue }) => (
            <Form>
              <div className={styles.mainContainer}>
                <div className={styles.subContainer}>
                  {showOriginalProductName(customerPreferences) && (
                    <div className={styles.fieldContainer}>
                      <InputLabel>
                        {t('reports.prodSummary.originalLabel')}
                      </InputLabel>
                      <TextField
                        variant="standard"
                        fullWidth
                        name="canonicalName"
                        disabled
                        value={values.canonicalName}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </div>
                  )}
                  <div className={styles.fieldContainer}>
                    <InputLabel>
                      {t('reports.prodSummary.customEdit')}
                    </InputLabel>
                    <TextField
                      variant="standard"
                      fullWidth
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <span className={styles.error}>
                      {errors && errors.name}
                    </span>
                  </div>
                  <div className={styles.fieldContainer}>
                    <InputLabel>
                      {t('reports.prodSummary.customLabelEdit')}
                    </InputLabel>
                    <TextField
                      variant="standard"
                      fullWidth
                      name="marketLabel"
                      value={values.marketLabel}
                      onChange={handleChange}
                    />
                    <span className={styles.error}>
                      {errors && errors.marketLabel}
                    </span>
                  </div>
                  <div className={styles.fieldContainer}>
                    <InputLabel>
                      {t('reports.prodSummary.customLabelColorEdit')}
                    </InputLabel>
                    <Field name="labelColor" type="color" />
                  </div>
                  <div className={styles.fieldContainer}>
                    <InputLabel>
                      {t('reports.prodSummary.isHighlighted')}
                    </InputLabel>
                    <Checkbox
                      name="contours"
                      color="primary"
                      checked={values.isHighlighted}
                      onChange={(e) => setFieldValue('isHighlighted', e.target.checked)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <MaterialButton variant="outlined" soft onClick={closeModal}>
                  {'Cancel'}
                </MaterialButton>
                <MaterialButton
                  variant="outlined"
                  soft
                  teal
                  onClick={handleSubmit}
                >
                  {'Save'}
                </MaterialButton>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
};

export default ReportCustomProductCreateContainer;
