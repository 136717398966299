import { gql } from '@apollo/client';

export interface CategoryTimeSeriesResponse {
  loading: boolean;
  error: any;
  allRpCategoryTimeseries: {
    nodes: CategoryTimeSeriesRow[];
  };
}

export interface CategoryTimeSeriesRow {
  id: number;
  reportId: string;
  category: string;
  year: number;
  pq: number;
  error: number;
  isFuture: boolean;
}

export default gql`
  query CategoryTimeSeriesQuery($reportID: UUID) {
    allRpCategoryTimeseries(condition: { reportId: $reportID }) {
      nodes {
        id
        reportId
        category
        year
        pq
        error
        isFuture
      }
    }
  }
`;
