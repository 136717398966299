import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '../../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontFamily: 'OpenSans, sans-serif',
      color: COLORS.MARINE,
      fontSize: 12,
    },
  }),
);

export default useStyles;
