import * as React from 'react';
import { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ParsedPQModel, getParsedPQModel } from './pqModelUtils';
import QueryProduct from './QueryProductOFP';
import { PageHeader, PagePaper, PageTitle } from '../../styles/themeComponents';

const PQModelPlayground: React.FC = () => {
  const { t } = useTranslation();
  const [reportId, setReportId] = useState<string>('');
  const [modelJson, setModelJson] = useState<reports.PQModel>(null);
  const [error, setError] = useState<Error>(null);

  const [parsedPQModel, setParsedPQModel] = useState<ParsedPQModel>(null);

  const modelUrl = (reportId: string) =>
    `https://gastrograph-models-test.s3.amazonaws.com/${reportId}/pq_model.json`;

  useEffect(() => {
    fetch(modelUrl(reportId), { mode: 'cors' })
      .then(async (response) => {
        const a = await response.json();
        setModelJson(a);
        setError(null);
      })
      .catch((error) => setError(error));
  }, [reportId]);

  useEffect(() => {
    if (modelJson) {
      setParsedPQModel(getParsedPQModel(modelJson));
    }
  }, [modelJson]);

  return (
    <PagePaper>
      <PageHeader>{t('modelPlayground.header')}</PageHeader>
      <PageTitle>{t('modelPlayground.title')}</PageTitle>
      <div>
        <Select
          variant="standard"
          id="report-id-select"
          value={reportId}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setReportId(event.target.value)
          }
        >
          <MenuItem
            key="c0479250-80cc-4664-91a3-e80d71f73883"
            value="c0479250-80cc-4664-91a3-e80d71f73883"
          >
            c0479250-80cc-4664-91a3-e80d71f73883
          </MenuItem>
          <MenuItem
            key="d2a78bca-323d-4c1c-a76a-d3dbacc5fbf4"
            value="d2a78bca-323d-4c1c-a76a-d3dbacc5fbf4"
          >
            d2a78bca-323d-4c1c-a76a-d3dbacc5fbf4
          </MenuItem>
        </Select>
      </div>

      {!error && parsedPQModel && (
        <div>
          <QueryProduct reportId={reportId} model={parsedPQModel} />

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              PQ Model JSON Details
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Property</TableCell>
                    <TableCell>Parsed Array Length</TableCell>
                    <TableCell>First 20 chars</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>num.trees</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>{parsedPQModel.numTrees}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>child.nodeIDs</TableCell>
                    <TableCell>{parsedPQModel.childNodeIds.length}</TableCell>
                    <TableCell>
                      {JSON.stringify(parsedPQModel.childNodeIds).substring(
                        0,
                        20,
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>split.varIDs</TableCell>
                    <TableCell>{parsedPQModel.splitVarIds.length}</TableCell>
                    <TableCell>
                      {JSON.stringify(parsedPQModel.splitVarIds).substring(
                        0,
                        20,
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>split.values</TableCell>
                    <TableCell>{parsedPQModel.splitValues.length}</TableCell>
                    <TableCell>
                      {JSON.stringify(parsedPQModel.splitValues).substring(
                        0,
                        20,
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>is.ordered</TableCell>
                    <TableCell>{parsedPQModel.isOrdered.length}</TableCell>
                    <TableCell>
                      {JSON.stringify(parsedPQModel.isOrdered).substring(0, 20)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>class.values</TableCell>
                    <TableCell>{parsedPQModel.classValues.length}</TableCell>
                    <TableCell>
                      {JSON.stringify(parsedPQModel.classValues).substring(
                        0,
                        20,
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>terminal.class.counts</TableCell>
                    <TableCell>
                      {parsedPQModel.terminalClassCounts.length}
                    </TableCell>
                    <TableCell>
                      {JSON.stringify(
                        parsedPQModel.terminalClassCounts,
                      ).substring(0, 20)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>levels</TableCell>
                    <TableCell>{parsedPQModel.levels.length}</TableCell>
                    <TableCell>
                      {JSON.stringify(parsedPQModel.levels).substring(0, 20)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>independent.variable.names</TableCell>
                    <TableCell>
                      {parsedPQModel.independentVariableNames.length}
                    </TableCell>
                    <TableCell>
                      {JSON.stringify(
                        parsedPQModel.independentVariableNames,
                      ).substring(0, 20)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>treetype</TableCell>
                    <TableCell>{parsedPQModel.treeType.length}</TableCell>
                    <TableCell>
                      {JSON.stringify(parsedPQModel.treeType).substring(0, 20)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </PagePaper>
  );
};

export default PQModelPlayground;
