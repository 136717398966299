import * as React from 'react';

import { Theme, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notFoundContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      marginTop: '20px',
      justifyContent: 'center',
    },
    notFoundHeader: {
      color: theme.palette.info.main,
      fontSize: '48px',
      fontWeight: 300,
    },
  }),
);

const NotFoundContainer = () => {
  const classes = useStyles(useTheme());

  return (
    <div className={classes.notFoundContainer}>
      <h1 className={classes.notFoundHeader}>Page Not Found</h1>
    </div>
  );
};

NotFoundContainer.displayName = 'NotFoundContainer';

export default NotFoundContainer;
