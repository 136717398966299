import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import flexContainer from '../../styles/flexContainer';

const shadeColor = (color: string, percent): string => {
  //https://stackoverflow.com/a/13532993/5383348

  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = R + 60;
  G = G + 50;
  B = B + 100;

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.banner.main,
      backgroundImage: `linear-gradient(to right, ${
        theme.palette.banner.main
      }, ${shadeColor(theme.palette.banner.main, 80)})`,
      flexShrink: 0,
      flexDirection: 'row',
      fontSize: '14px',
      height: '70px',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
    },
    leftItemWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: 1,
      padding: '0 16px',
    },
    logo: {
      color: theme.palette.info.main,
      fontFamily: 'Fira Sans, sans-serif',
      fontSize: '22px',
    },
    viewerMenuWrapper: {
      ...flexContainer(theme),
      justifyContent: 'center',
    },
  }),
);

export default useStyles;
