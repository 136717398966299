import * as React from 'react';

import dataTableCsv from 'components/ReactDataTable/ReactDataTableCsv';
import useWindowSize from 'hooks/useWindowSize';
import DataTable, { TableColumn } from 'react-data-table-component';

import styles from './DataDashboard.module.css';
import { totalFlagsForReview } from './dataquality.utils';

const getData = (
  panelReviews: dataquality.PanelQualityData[],
  latestAppBuild: string,
): TableRow[] =>
  Array.from(new Set(panelReviews.map((d) => d.userId))).map((userId) => {
    const userPanelReviews = panelReviews.filter((d) => d.userId == userId);
    const rowInfo: TableRow = {
      id: userPanelReviews[0].email || userPanelReviews[0].userId,
    };

    userPanelReviews.map((review) => {
      const runningFlagTotal = totalFlagsForReview(review);

      let columnString = runningFlagTotal.toString();

      if (latestAppBuild && review.appRevision !== latestAppBuild) {
        columnString += '\t❗';
      }

      let columnObject = {};

      if (review.numggvar) {
        columnObject = {
          columnString: columnString,
          pq: review.perceivedQuality,
          noGgVar: review.numggvar.toString(),
        };
      } else {
        columnObject = {
          columnString: columnString,
          pq: review.perceivedQuality,
        };
      }

      rowInfo[review.productName] = columnObject;
    });

    return rowInfo;
  });

interface Props {
  panelProducts: {
    productByProductId: {
      name: string;
    };
  }[];
  panelReviews: dataquality.PanelQualityData[];
  latestAppBuild?: string;
}

interface TableRow {
  id: number | string;
  [key: string]: any;
}

const UserProductDataQualityTable: React.FC<Props> = ({
  panelProducts,
  panelReviews,
  latestAppBuild,
}) => {
  const { width } = useWindowSize();

  const colWidth = (windowWidth: number) =>
    (windowWidth - 250) / (panelProducts.length + 1);

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: 'ID',
      sortable: true,
      wrap: true,
      width: `${colWidth(width)}px`,
    },
  ].concat(
    panelProducts.map((product) => ({
      id: product.productByProductId.name,
      selector: (row: TableRow) => row[product.productByProductId.name]?.pq,
      name: product.productByProductId.name,
      sortable: true,
      wrap: true,
      width: `${colWidth(width)}px`,
      cell: (row: TableRow) => {
        if (row[product.productByProductId.name] != undefined) {
          return (
            <div>
              {row[product.productByProductId.name].columnString && (
                <span>{row[product.productByProductId.name].columnString}</span>
              )}
              <br />
              {row[product.productByProductId.name].noGgVar && (
                <span>
                  {'No. of GGVars marked: ' +
                    row[product.productByProductId.name].noGgVar}
                </span>
              )}
              <br />
              {row[product.productByProductId.name].pq && (
                <span>{'PQ: ' + row[product.productByProductId.name].pq}</span>
              )}
            </div>
          );
        } else {
          return '';
        }
      },
    })),
  );

  return (
    <div>
      <DataTable
        customStyles={{
          responsiveWrapper: {
            style: {
              maxHeight: 'none',
            },
          },
        }}
        columns={columns}
        data={getData(panelReviews, latestAppBuild)}
        fixedHeader
        striped
        actions={dataTableCsv(
          'User_Product_Data_Quality.csv',
          columns,
          getData(panelReviews, latestAppBuild).map((row) => {
            const lcObj = {
              id: row.id,
            };
            columns.slice(1).forEach((column) => {
              if (row[column.id]) {
                lcObj[column.id] = `${
                  row[column.id].columnString || ''
                }, No. of GGVars marked: ${row[column.id].noGgVar || ''}, PQ: ${
                  row[column.id].pq || ''
                }`;
              }
            });
            return lcObj;
          }),
        )}
      />
      <span className={styles.refreshMsg}>
        Reviews with ❗ are completed using an older build of the app.
      </span>
    </div>
  );
};

export default UserProductDataQualityTable;
