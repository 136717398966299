import { nullCheck } from '../../utils/afsUtils';

const separator = '|';

export class ProductVersion {
  constructor(
    public productId: number,
    public version: string,
    public reportId?: string,
  ) {}

  get value(): string {
    return this.productId + separator + this.version;
  }

  equals(other: ProductVersion) {
    return (
      this.productId === other.productId &&
      this.version === other.version &&
      this.reportId === other.reportId
    );
  }
}

export function parseProductVersionKey(
  productVersionKey: string,
): ProductVersion {
  if (
    productVersionKey === null ||
    (typeof productVersionKey === 'string' && productVersionKey.length === 0)
  ) {
    return new ProductVersion(null, null);
  } else if (productVersionKey.indexOf(separator) < 0) {
    return new ProductVersion(parseInt(productVersionKey), null);
  } else {
    return new ProductVersion(
      parseInt(productVersionKey.split(separator)[0]),
      nullCheck(productVersionKey.split(separator)[1]),
    );
  }
}

export class ProductVersionSet {
  private productVersions: ProductVersion[] = [];

  constructor();
  constructor(input: ProductVersion[]);
  constructor(input?: ProductVersion[]) {
    if (input === undefined) {
      this.productVersions = [];
    } else {
      this.addArray(input);
    }
  }

  get size() {
    return this.productVersions.length;
  }

  get(index: number): ProductVersion {
    return this.productVersions[index];
  }

  add(productVersion: ProductVersion) {
    if (this.has(productVersion)) {
      // throw 'Duplicate point';
      return false;
    }

    this.productVersions.push(productVersion);
    return true;
  }

  addArray(productVersions: ProductVersion[]) {
    productVersions.forEach((pv) => this.add(pv));
  }

  has(productVersion: ProductVersion) {
    return this.findIndex(productVersion) > -1;
  }

  findIndex(point: ProductVersion) {
    for (let i = 0; i < this.productVersions.length; i++) {
      const existingPoint = this.productVersions[i];
      if (existingPoint.equals(point)) {
        return i;
      }
    }

    return -1;
  }

  getItems(): ProductVersion[] {
    return this.productVersions;
  }

  copy() {
    const copy = new ProductVersionSet();

    for (let i = 0; i < this.productVersions.length; i++) {
      copy.add(this.productVersions[i]);
    }

    return copy;
  }

  delete(productVersion: ProductVersion) {
    this.productVersions.forEach((item, index) => {
      if (item.equals(productVersion)) this.productVersions.splice(index, 1);
    });
  }
}
