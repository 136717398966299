import { chain, filter } from 'lodash';

import { TexturePreferenceRow } from './ReportTexturePreference';
import { ProductVersion, ProductVersionSet } from '../../ProductVersion';
import { getProductName } from '../../utils';

/**
 * Select out the appropriate cell value dynamically
 *
 * @param row row of TextureComponisitionClusterRow for a product
 * @param tcIdx the "TC1", "TC2", ... cluster name
 * @returns texture preference value
 */
export const selectCellValue = (
  row: TexturePreferenceRow,
  tcIdx: string,
): number =>
  filter(row.clusters, (x) => x.clusterIdx === parseInt(tcIdx.substring(2)))[0]
    .pqImpact;

export const getTextureProductIdSet = (
  reportTexturePreferencesResponse: reports.ReportTexturePreferencesResponse,
): ProductVersionSet => {
  return reportTexturePreferencesResponse.allRpTexturePreferences.nodes
    .map((n) => new ProductVersion(n.productByProductId.id, n.version))
    .reduce((a, c) => (a.add(c) ? a : a), new ProductVersionSet());
};

export const getReshapedData = (
  reportTexturePreferencesResponse: reports.ReportTexturePreferencesResponse,
  productVersionSet: ProductVersionSet,
  customProductNames: reports.ColorVersionedProductInfo[],
): TexturePreferenceRow[] => {
  const textureIdSets = getTextureProductIdSet(
    reportTexturePreferencesResponse,
  );

  const missingIdSet = new ProductVersionSet(
    productVersionSet.getItems().filter((x) => !textureIdSets.has(x)),
  );

  const refinedList = [
    ...reportTexturePreferencesResponse.allRpTexturePreferences.nodes,
  ];
  missingIdSet.getItems().forEach((id) => {
    let clx = 1;
    while (clx < 8) {
      refinedList.push({
        productByProductId: {
          id: id.productId,
          name: '',
        },
        version: id.version,
        clusterIdx: clx,
        pqImpact: null,
      });
      clx++;
    }
  });

  return chain(refinedList)
    .filter(
      (node) =>
        !!node.productByProductId &&
        productVersionSet.has(
          new ProductVersion(node.productByProductId.id, node.version),
        ),
    )
    .groupBy(
      (i) => new ProductVersion(i.productByProductId.id, i.version).value,
    )
    .map((value, key) => ({
      name: getProductName({
        productNames: customProductNames,
        productId: value[0].productByProductId.id,
        version: value[0].version,
      }),
      productId: value[0].productByProductId.id,
      version: value[0].version,
      total: value
        .map((cluster) => cluster.pqImpact)
        .reduce((prev, curr) => prev + curr, 0),
      clusters: value,
    }))
    .value();
};

export const numberOfClusters = (
  reportTexturePreferencesResponse: reports.ReportTexturePreferencesResponse,
) => {
  return chain(reportTexturePreferencesResponse?.allRpTexturePreferences?.nodes)
    .map((row) => row.clusterIdx)
    .uniq()
    .value();
};

export const sortPreferenceTotal = (
  a: TexturePreferenceRow,
  b: TexturePreferenceRow,
) => {
  return a.total > b.total ? -1 : 1;
};
