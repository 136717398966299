import { mapFromKey } from 'constants/ggVars';

import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import NoveltyFpDecompByReportIdAndProductIdQuery, {
  ReportFpNoveltyDecompResponse,
} from '@graphql/queries/NoveltyFpDecompByReportIdAndProductIdQuery';
import NoveltyIntensityPqByReportIdAndProductIdQuery, {
  ReportNoveltyIntensityPqResponse,
} from '@graphql/queries/NoveltyIntensityPqByReportIdAndProductIdQuery';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Modal, Typography, useTheme } from '@mui/material';
import { Grid } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { tableCustomStyles } from 'components/ReactDataTable/tableCustomStyles';
import { camelCase, first, startCase } from 'lodash';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { cleanUnderscore } from 'utils/afsUtils';

import styles from './NoveltyFlavorDecompositionTable.module.css';
import NoveltyFlavorDecompositionTableCsv from './NoveltyFlavorDecompositionTableCsv';

interface Props {
  projectId: number;
  reportId: string;
  productId: number;
  version: string;
}

const NoveltyFlavorDecompositionTable: React.FC<Props> = (props) => {
  const { projectId, reportId, productId, version } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const [explainerModalOpen, setExplainerModal] = useState<boolean>(false);

  const { loading, error, data } = useQuery<ReportFpNoveltyDecompResponse>(
    NoveltyFpDecompByReportIdAndProductIdQuery,
    {
      variables: { reportID: reportId, productID: productId, version: version },
    },
  );

  const {
    loading: pqNoveltyLoading,
    error: pqNoveltyError,
    data: pqNoveltydata,
  } = useQuery<ReportNoveltyIntensityPqResponse>(
    NoveltyIntensityPqByReportIdAndProductIdQuery,
    {
      variables: { reportID: reportId },
    },
  );

  if (loading || error || pqNoveltyLoading || pqNoveltyError) {
    return <LoadingScreen />;
  }

  if (!data.allRpNoveltyFlavorDecomps.nodes.length) {
    return (
      <div className={styles.emptyMain}>
        <div className={styles.emptySub}>
          <h5>Your selection produced an empty list!</h5>
        </div>
      </div>
    );
  }

  interface TableRow {
    ggVar: string;
    referenceFlavor: string;
    signaturePresent: boolean;
    contributionToNovelty: number;
    decomp: number;
  }

  const columns: TableColumn<TableRow>[] = [
    {
      selector: (row: TableRow) => row.ggVar,
      id: 'ggVar',
      name: t('reports.noveltyFpDecomp.ggVars'),
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.referenceFlavor,
      id: 'referenceFlavor',
      name: t('reports.noveltyFpDecomp.referenceFlavor'),
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.contributionToNovelty,
      id: 'contributionToNovelty',
      name: t('reports.noveltyFpDecomp.contributionToNovelty'),
      cell: (row: TableRow) =>
        (row.contributionToNovelty ?? 0).toFixed(0) +
        '% ' +
        (row.signaturePresent ? '+' : '-'),
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.decomp,
      id: 'decomp',
      name: t('reports.noveltyFpDecomp.decomp'),
      cell: (row: TableRow) => (row.decomp ?? 0).toFixed(0) + '% ',
      sortable: true,
    },
  ];

  const getTopProductInfo = (pqNoveltydata: ReportNoveltyIntensityPqResponse) =>
    first(pqNoveltydata.allRpNoveltyIntensityPqs.nodes);

  const getCurrentProductInfo = (data: ReportFpNoveltyDecompResponse) =>
    first(
      data.allRpNoveltyFlavorDecomps.nodes.filter(
        (row) => row.productByProductId.id == productId,
      ),
    );

  const getCurrentNoveltyIntensityPq = (
    pqNoveltydata: ReportNoveltyIntensityPqResponse,
  ) =>
    first(
      pqNoveltydata.allRpNoveltyIntensityPqs.nodes.filter(
        (row) => row.productByProductId.id == productId,
      ),
    );

  const getData = (data: ReportFpNoveltyDecompResponse): TableRow[] => {
    return data.allRpNoveltyFlavorDecomps.nodes.map((row) => ({
      ggVar: mapFromKey(row.ggVars).label,
      referenceFlavor: startCase(
        camelCase(cleanUnderscore(row.referenceFlavor)),
      ),
      signaturePresent: row.signaturePresent,
      contributionToNovelty: row.contributionToNovelPct,
      decomp: row.decomp,
    }));
  };

  return (
    <div>
      <Modal
        title="Explainer"
        className={styles.modalContainer}
        open={explainerModalOpen}
        onClose={() => setExplainerModal(false)}
      >
        <div className={styles.modalDesc}>
          <p>
            {t('reports.noveltyFpDecomp.explainer1')}{' '}
            <i>{getTopProductInfo(pqNoveltydata).productByProductId.name}</i>{' '}
            {t('reports.noveltyFpDecomp.explainer2')}
            {getCurrentProductInfo(data).referenceFlavor}{' '}
            {t('reports.noveltyFpDecomp.explainer3')}
            {getCurrentProductInfo(data).contributionToNovelPct}%{' '}
            {getCurrentProductInfo(data).signaturePresent ? '+' : '-'}{' '}
            {t('reports.noveltyFpDecomp.explainer4')}
          </p>
        </div>
      </Modal>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {getCurrentNoveltyIntensityPq(pqNoveltydata) && (
            <Typography variant="subtitle1" align="center">
              {'PQ ' +
                getCurrentNoveltyIntensityPq(pqNoveltydata).pq.toFixed(2) +
                ', Novelty Score: ' +
                getCurrentNoveltyIntensityPq(
                  pqNoveltydata,
                ).noveltyScore.toFixed(2)}
              <IconButton
                onClick={() => setExplainerModal(!explainerModalOpen)}
                size="large"
              >
                <InfoOutlinedIcon style={{ fontSize: 24, color: '#777' }} />
              </IconButton>
            </Typography>
          )}
        </Grid>
        <Grid item xs={1}>
          <NoveltyFlavorDecompositionTableCsv
            data={data.allRpNoveltyFlavorDecomps.nodes}
          />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            columns={columns}
            data={getData(data)}
            defaultSortFieldId="contributionToNovelty"
            defaultSortAsc={false}
            fixedHeader
            // actions={dataTableCsv(
            //   `Product Novelty Drivers.csv`,
            //   columns,
            //   getData(data),
            // )}
            customStyles={tableCustomStyles(theme, true)}
            pagination={false}
            paginationPerPage={data.allRpNoveltyFlavorDecomps.nodes.length}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default NoveltyFlavorDecompositionTable;
