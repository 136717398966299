import { COUNTRIES, CountryMetadata } from 'constants/country';

import * as React from 'react';
import { useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';
import { chain } from 'lodash';

import { isNiqWorkspace } from '../utils/afsUtils';

export const AVAILABLE_COUNTRIES_QUERY = gql`
  query AvailableCountries {
    allCountries {
      nodes {
        country
      }
    }
  }
`;

interface AvailableCountriesResponse {
  loading: boolean;
  error: any;
  allCountries: {
    nodes: AvailableCountry[];
  };
}

interface AvailableCountry {
  country: string;
}

const useAvailableCountries: (workspaceId: number) => {
  loading: boolean;
  error: any;
  availableCountries: Set<CountryMetadata>;
} = (workspaceId: number) => {
  const [availableCountries, setAvailableCountries] = React.useState<
    Array<CountryMetadata>
  >(new Array<CountryMetadata>());
  const [countriesLoading, setCountriesLoading] = React.useState<boolean>(true);

  const { loading, error, data } = useQuery<AvailableCountriesResponse>(
    AVAILABLE_COUNTRIES_QUERY,
  );

  useEffect(() => {
    if (!loading && !error && data) {
      const dbCountries = chain(data?.allCountries?.nodes)
        .map((c) => c.country.toLowerCase())
        .value();
      setAvailableCountries(
        COUNTRIES.filter((c) => dbCountries.includes(c.dbname)).sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      );
      setCountriesLoading(false);
    }
  }, [data, loading, error]);

  if (isNiqWorkspace(workspaceId)) {
    return {
      loading: countriesLoading,
      error,
      availableCountries: new Set(availableCountries),
    };
  } else {
    return {
      loading: countriesLoading,
      error,
      availableCountries: new Set(
        availableCountries.filter((i) => i.code !== 'NG'),
      ),
    };
  }
};

export default useAvailableCountries;
