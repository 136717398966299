import {
  CustomerPreferences,
  getDefaultZoom,
  getLabelFactor,
  zoomBehavior,
} from 'services/customerPreferences';

export const ZOOM_MIN = 1;
export const ZOOM_MAX = 4;

export const getDefaultMapOptions = (
  preferences: CustomerPreferences,
): MapOptions => ({
  zoom: getDefaultZoom(preferences),
  labelFactor: getLabelFactor(preferences),
  rotationAngle: 0,
  rotationPristine: true,
  contoursVisible: true,
  vectorsVisible: true,
  productsVisible: true,
  heatmapVisible: true,
  contoursTransparency: 1,
  vectorsTransparency: 1,
  productsTransparency: 1,
  heatmapTransparency: 1,
  tx: 0,
  ty: 0,
});

export interface MapOptions {
  zoom: number;
  labelFactor: number;
  rotationAngle: number;
  rotationPristine: boolean;
  contoursVisible: boolean;
  vectorsVisible: boolean;
  productsVisible: boolean;
  heatmapVisible: boolean;
  contoursTransparency: number;
  productsTransparency: number;
  vectorsTransparency: number;
  heatmapTransparency: number;
  tx: number;
  ty: number;
}

export const setZoom = (
  mapOptions: MapOptions,
  newZoom: number,
): MapOptions => ({
  ...mapOptions,
  zoom: newZoom,
});

export const setLabelFactor = (
  mapOptions: MapOptions,
  newLabelFactor: number,
): MapOptions => ({
  ...mapOptions,
  labelFactor: newLabelFactor,
});

export const setRotationAngle = (
  mapOptions: MapOptions,
  newRotationAngle: number,
): MapOptions => ({
  ...mapOptions,
  rotationAngle: newRotationAngle,
  rotationPristine: false,
});

export const setRotationPristine = (
  mapOptions: MapOptions,
  newRotationPristine: boolean,
): MapOptions => ({
  ...mapOptions,
  rotationPristine: newRotationPristine,
});

export const setContoursVisible = (
  mapOptions: MapOptions,
  newContoursVisible: boolean,
): MapOptions => ({
  ...mapOptions,
  contoursVisible: newContoursVisible,
});

export const setVectorsVisible = (
  mapOptions: MapOptions,
  newVectorsVisible: boolean,
): MapOptions => ({
  ...mapOptions,
  vectorsVisible: newVectorsVisible,
});

export const setProductsVisible = (
  mapOptions: MapOptions,
  newProductsVisible: boolean,
): MapOptions => ({
  ...mapOptions,
  productsVisible: newProductsVisible,
});

export const setHeatmapVisible = (
  mapOptions: MapOptions,
  newHeatmapVisible: boolean,
): MapOptions => ({
  ...mapOptions,
  heatmapVisible: newHeatmapVisible,
});

export const setContoursTransparency = (
  mapOptions: MapOptions,
  newContoursTransparency: number,
): MapOptions => ({
  ...mapOptions,
  contoursTransparency: newContoursTransparency,
});

export const setProductsTransparency = (
  mapOptions: MapOptions,
  newProductsTransparency: number,
): MapOptions => ({
  ...mapOptions,
  productsTransparency: newProductsTransparency,
});

export const setVectorsTransparency = (
  mapOptions: MapOptions,
  newVectorsTransparency: number,
): MapOptions => ({
  ...mapOptions,
  vectorsTransparency: newVectorsTransparency,
});

export const setHeatmapTransparency = (
  mapOptions: MapOptions,
  newHeatmapTransparency: number,
): MapOptions => ({
  ...mapOptions,
  heatmapTransparency: newHeatmapTransparency,
});

export const setTx = (mapOptions: MapOptions, newTx: number): MapOptions => ({
  ...mapOptions,
  tx: newTx,
});

export const setTy = (mapOptions: MapOptions, newTy: number): MapOptions => ({
  ...mapOptions,
  ty: newTy,
});

export const applyZoomEvent = (
  customerPreferences: CustomerPreferences,
  mapOptions: MapOptions,
  event: d3.D3ZoomEvent<HTMLCanvasElement, any>,
): MapOptions => {
  switch (zoomBehavior(customerPreferences)) {
    case 'panzoom':
      return setTy(
        setTx(
          setZoom(mapOptions, event.transform.k),
          -1 * (event.transform.x ?? 0),
        ),
        -1 * (event.transform.y ?? 0),
      );
    case 'microscope':
    default:
      return mapOptions;
  }
};
