import * as React from 'react';

import styles from './modal.module.css';

const PopUp = ({ open, title, children, styledTitle, modalStyle }) => {
  if (!open) return null;

  return (
    <div className={styles.shadow}>
      <div className={modalStyle ? styles.modalStyle : styles.modalContainer}>
        <h2>{!styledTitle && title}</h2>
        <div className={styles.contentContainer}>{children}</div>
      </div>
    </div>
  );
};

export default PopUp;
