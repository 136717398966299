import * as React from 'react';

interface Props {
  size: number;
}

const CSVFileIcon: React.FC<Props> = (props: Props) => {
  return (
    <div style={{ height: props.size }}>
      <svg
        clipRule="evenodd"
        fillRule="evenodd"
        height={props.size}
        width={props.size}
        strokeLinejoin="round"
        strokeMiterlimit="2"
        viewBox="0 0 267 267"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="m50 154.167c56.944 9.876 113.889 9.544 170.833 0v-79.167c0-.902-.292-1.779-.833-2.5l-37.5-50c-.787-1.049-2.022-1.667-3.333-1.667h-116.667c-3.315 0-6.495 1.317-8.839 3.661-2.344 2.345-3.661 5.524-3.661 8.839v95.834c0 6.188-1.451 11.433-1.451 15.614 0 5.78 1.451 9.386 1.451 9.386z"
            fill="#d8dfe3"
          />
          <path
            d="m25 143.365v89.968c0 3.315 1.317 6.495 3.661 8.839s5.524 3.661 8.839 3.661h191.667c3.315 0 6.494-1.317 8.839-3.661 2.344-2.344 3.661-5.524 3.661-8.839 0-15.966 0-50.7 0-66.666 0-3.315-1.317-6.495-3.661-8.839-2.345-2.344-5.524-3.661-8.839-3.661h-187.5c-5.316 0-16.667-10.802-16.667-10.802z"
            fill="#1fb35b"
          />
          <path
            d="m37.5 154.167c-3.804 0-6.581-1.543-8.625-3.443-1.923-1.788-3.875-4.939-3.875-9.057 0-2.643 1.317-6.495 3.661-8.839s5.524-3.661 8.839-3.661h12.5v25s1.071 0-12.5 0z"
            fill="#198043"
          />
          <path
            d="m179.167 20.833v41.667c0 3.315 1.317 6.495 3.661 8.839s5.523 3.661 8.839 3.661h29.166c0-.902-.292-1.779-.833-2.5l-37.5-50c-.787-1.049-2.022-1.667-3.333-1.667z"
            fill="#afbdc7"
          />
          <path
            d="m87.5 79.167h54.167c2.299 0 4.166-1.867 4.166-4.167s-1.867-4.167-4.166-4.167h-54.167c-2.3 0-4.167 1.867-4.167 4.167s1.867 4.167 4.167 4.167z"
            fill="#1fb35b"
          />
          <path
            d="m87.5 104.167h95.833c2.3 0 4.167-1.867 4.167-4.167s-1.867-4.167-4.167-4.167h-95.833c-2.3 0-4.167 1.867-4.167 4.167s1.867 4.167 4.167 4.167z"
            fill="#1fb35b"
          />
          <path
            d="m87.5 129.167h95.833c2.3 0 4.167-1.867 4.167-4.167s-1.867-4.167-4.167-4.167h-95.833c-2.3 0-4.167 1.867-4.167 4.167s1.867 4.167 4.167 4.167z"
            fill="#1fb35b"
          />
          <g fill="#d8dfe3">
            <path d="m156.548 180.714 16.667 41.667c.632 1.582 2.165 2.619 3.868 2.619 1.704 0 3.236-1.037 3.869-2.619l16.667-41.667c.854-2.135-.186-4.562-2.322-5.416-2.135-.854-4.562.186-5.416 2.321l-12.798 31.995s-12.798-31.995-12.798-31.995c-.854-2.135-3.281-3.175-5.416-2.321s-3.175 3.281-2.321 5.416z" />
            <path d="m91.963 175h-2.381c-11.506 0-20.833 9.327-20.833 20.833v8.334c0 11.506 9.327 20.833 20.833 20.833h2.381c7.433 0 14.183-4.336 17.273-11.096.431-.942.516-1.12.793-1.734 1.091-2.422-.226-4.633-1.79-5.419-2.612-1.313-4.982.142-5.789 1.954-.245.55-.793 1.735-.793 1.735-1.735 3.793-5.522 6.227-9.694 6.227h-2.381c-6.904 0-12.5-5.597-12.5-12.5v-8.334c0-6.903 5.596-12.5 12.5-12.5h2.381c4.172 0 7.959 2.434 9.694 6.227 0 0 .508 1.138.793 1.735 1.252 2.624 4.212 2.997 6.084 1.781 1.315-.856 2.557-2.923 1.495-5.246-.793-1.734-.793-1.734-.793-1.734-3.09-6.76-9.84-11.096-17.273-11.096z" />
            <path d="m116.667 210.443v-.001 2.066c0 3.313 1.316 6.491 3.658 8.833 2.343 2.343 5.52 3.659 8.833 3.659h9.392c3.313 0 6.49-1.316 8.833-3.659 2.343-2.342 3.659-5.52 3.659-8.833 0-.56 0-1.116 0-1.658 0-5.111-3.112-9.707-7.858-11.606l-15.151-6.06c-1.832-.733-3.033-2.507-3.033-4.48v-1.204c0-1.105.439-2.165 1.22-2.946.782-.782 1.841-1.221 2.946-1.221h9.376c1.105 0 2.165.439 2.946 1.221.781.781 1.22 1.841 1.22 2.946v2.058c0 1.176.52 2.24 1.043 2.78.813.923 1.981 1.412 3.124 1.412 2.028 0 4.167-1.566 4.167-4.192v-2.058c0-3.315-1.317-6.495-3.661-8.839-2.345-2.344-5.524-3.661-8.839-3.661h-9.375c-3.316 0-6.495 1.317-8.839 3.661s-3.661 5.524-3.661 8.839v1.204c0 5.38 3.276 10.219 8.271 12.217l15.151 6.061c1.582.632 2.619 2.165 2.619 3.868v1.658c0 1.103-.438 2.161-1.218 2.941-.779.78-1.837 1.218-2.94 1.218h-9.392c-1.103 0-2.16-.438-2.94-1.218s-1.218-1.838-1.218-2.94c0-.001 0-.001 0-2.067 0-2.748-2.24-4.194-4.094-4.194-1.758 0-4.239 1.363-4.239 4.169z" />
          </g>
        </g>
      </svg>
    </div>
  );
};
export default CSVFileIcon;
