import { gql } from '@apollo/client';

export interface ReferenceFlavorLanguage {
  code: string;
  name: string;
}

export interface GetReferenceFlavorLanguageResponse {
  loading: boolean;
  error: any;
  languages: {
    nodes: ReferenceFlavorLanguage[];
  };
}

export default gql`
  query GetReferenceFlavorLanguage {
    languages: allLanguages {
      nodes {
        code
        name
      }
    }
  }
`;
