import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Field } from 'redux-form';

import ProductWarnTooltip from './ProoductWarnTooltip';
import FieldCheckBox from '../../../components/FieldCheckBox';
import FieldTextInput from '../../../components/FieldTextInput';
import Tag from '../../../components/FormInputTag/Tag';
import styles from '../../RequestReport/RequestReport.module.css';

export default function CompetitiveProduct({
  product,
  deleteProduct,
  displayMode,
  handleNameChange,
  handleSpecialInterest,
  specialInterestProducts,
}) {
  const nameId = `${product.name}-${product.id}`;
  const renameId = `new-${product.name}-${product.id}`;
  return (
    <tr key={product.id} displayMode={displayMode}>
      <td className={styles.colName}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Tag
            readOnly={displayMode}
            label={product.name}
            onDelete={() => deleteProduct(product.name)}
            name={nameId}
            className="name"
            data-idx={product.id}
          />
          <ProductWarnTooltip
            condition={product.textureReviewsLowerBound < 6}
            message="Product has insufficient data for texture analysis"
          />
          <ProductWarnTooltip
            condition={product.category === null}
            message="Product has no category for analysis"
          />
        </div>
      </td>
      <td className={styles.colRename}>
        {product && (
          <Field
            style={{
              marginTop: 20,
              width: 350,
            }}
            name={renameId}
            disabled={displayMode}
            className="rename"
            data-idx={product.id}
            onChange={(e) => handleNameChange(product.name, e.target.value)}
            component={FieldTextInput}
            value={product['rename']}
            required
          />
        )}
      </td>
      <td>
        {product && (
          <Field
            name={product.id}
            disabled={displayMode}
            onChange={(event) =>
              handleSpecialInterest(product.name, event.target.checked)
            }
            component={FieldCheckBox}
            value={specialInterestProducts.includes(product.name)}
          />
        )}
      </td>
    </tr>
  );
}
