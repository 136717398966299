import { gql } from '@apollo/client';

export default gql`
  query PartnersProductSearchQuery(
    $query: String
    $first: Int
    $offset: Int
    $producerId: [Int!]!
  ) {
    productResults: allProducts(
      first: $first
      offset: $offset
      filter: {
        name: { includesInsensitive: $query }
        or: [
          { producerId: { in: $producerId } }
          { producerByProducerId: { trunk: { equalTo: true } } }
        ]
      }
    ) {
      pageInfo {
        hasNextPage
      }
      nodes {
        id
        name
        brand
        public
        aroma
        producer: producerByProducerId {
          id
          name
        }
        createdAt
        productReviews: productReviewsByProductId(
          orderBy: END_TIME_DESC
          first: 1
        ) {
          totalCount
          nodes {
            createdAt
          }
        }
        lastAlphabeticalTextureReviews: productReviewsByProductId(
          orderBy: TEXTURE_CSV_DESC
          first: 6
        ) {
          nodes {
            textureCsv
          }
        }
        productImages: productImagesByProductId {
          totalCount
          nodes {
            url
          }
        }
        panels: panelProductsByProductId {
          nodes {
            panelInfo: panelByPanelId {
              id
              startTime
              name
              pin
            }
          }
        }
        productClass
        productClasses: productClassProductsByProductId {
          nodes {
            id
            productClassByProductClassId {
              id
              name
            }
          }
        }
        defaultAttributes
        selectedProductQuestions: selectedProductQuestionsByProductId {
          totalCount
        }

        category: productCategoryByCategoryId {
          id
          name
        }
      }
      totalCount
    }
  }
`;
