import * as React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../styles/theme';

interface Props {
  input: any;
  options: any[];
}

const RenderRadioButtonCreatable: React.FunctionComponent<Props> = ({
  input,
  options,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <RadioGroup {...input} row>
      {options.map((option, index) => (
        <FormControlLabel
          control={<Radio color="secondary" />}
          label={option.label}
          value={option.value}
          key={`${index}_${option.value}`}
          classes={{ root: classes.root, label: classes.label }}
        />
      ))}
      <FormControlLabel
        control={<Radio color="secondary" />}
        label={t('forms.other')}
        value="Others"
        classes={{ root: classes.root, label: classes.label }}
      />
    </RadioGroup>
  );
};

const useStyles = makeStyles({
  root: {
    marginBottom: 0,
  },
  label: {
    fontFamily: 'OpenSans',
    fontSize: 14,
    color: COLORS.MARINE,
  },
});

export default RenderRadioButtonCreatable;
