import { gql } from '@apollo/client';

export default gql`
  mutation CreatePixelRequestMutation($pixelRequest: PixelRequestInput!) {
    createPixelRequest(input: { pixelRequest: $pixelRequest }) {
      pixelRequest {
        id
      }
    }
  }
`;
