import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '90vh',
      fontFamily: 'OpenSans, sans-serif',
      color: theme.palette.primary.main,
    },
  }),
);

export default useStyles;
