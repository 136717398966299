import * as React from 'react';
import { FunctionComponent } from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './RemoveUserFromWorkspace.module.css';
import MaterialButton from '../../components/MaterialButton';

type Props = {
  handleSubmit: () => any;
  handleClose: () => any;
  open: boolean;
  email: string;
  workspaceName: string;
};

const RemoveUserConfirmationModal: FunctionComponent<Props> = ({
  handleSubmit,
  handleClose,
  open,
  email,
  workspaceName,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} className={styles.modal}>
      <div className={styles.modalContainer}>
        <div className={styles.iconContainer}>
          <ErrorOutlineIcon className={styles.warningButton} />
        </div>
        <p>
          {t('admin.removeUserWorkspaceModalDescription', {
            email: email,
            workspaceName: workspaceName,
          })}
        </p>
        <div className={styles.modalButtonContainer}>
          <MaterialButton variant="outlined" soft onClick={handleClose}>
            {t('general.cancel')}
          </MaterialButton>
          <MaterialButton
            soft
            teal
            onClick={() => {
              handleSubmit();
              handleClose();
            }}
          >
            {t('general.confirm')}
          </MaterialButton>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveUserConfirmationModal;
