import { useEffect, useState } from 'react';

export default function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    widthBelow600: false,
    widthBelow700: false,
    widthBelow800: false,
    widthBelow900: false,
    widthBelow1000: false,
    widthBelow1100: false,
    widthBelow1300: false,
    widthBelow1400: false,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        widthBelow600: window.innerWidth < 600,
        widthBelow700: window.innerWidth < 700,
        widthBelow800: window.innerWidth < 800,
        widthBelow900: window.innerWidth < 900,
        widthBelow1000: window.innerWidth < 1000,
        widthBelow1100: window.innerWidth < 1100,
        widthBelow1300: window.innerWidth < 1300,
        widthBelow1400: window.innerWidth < 1400,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
