import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import flexContainer from '../../styles/flexContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    authenticationContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      justifyContent: 'center',
    },
    welcomePanel: {
      margin: '10px',
      width: '500px',
      backgroundColor: 'white',
      borderRadius: 4,
      border: '1px solid #ddd',
      boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05)',
      overflow: 'hidden',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0',
    },
    authenticationForm: {
      ...flexContainer(theme),
      alignSelf: 'center',
      margin: '10px 20px',
    },
    panelFooter: {
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '10px 10px 0 10px',
    },
  }),
);

export default useStyles;
