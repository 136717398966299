import * as React from 'react';

import * as d3 from 'd3';

import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from '@mui/material';
import CSVDownload from 'components/CSVDownload';

import ReportHistogramPlot from './ReportHistogramPlot';
import { toOrdinal } from '../utils';

type HistogramType = 'category' | 'stackRank' | string;
type DataType = 'report' | 'component';

interface HProps {
  title: string;
  histoType: HistogramType;
  dataType: DataType;
  hdata: number[];
  quantiles?: number[];
  rangeMin?: number;
  rangeMax?: number;
}

const ReportHistogram: React.FC<HProps> = (props) => {
  const { title, histoType, dataType, hdata, quantiles, rangeMin, rangeMax } =
    props;
  const svgId = (histoType: HistogramType, dataType: DataType) =>
    histoType + '-' + dataType + '-histogram-svg';

  const csvData = quantiles.reduce(
    (o, q) => ({
      ...o,
      [toOrdinal(q * 100)]: d3.quantile(hdata, q).toFixed(2),
    }),
    {},
  );

  return (
    <Grid container spacing={2}>
      <Grid container item xs={4} style={{ paddingTop: 30 }}>
        <Grid item xs={12}>
          <Box style={{ display: 'flex' }}>
            {dataType === 'report' ? <h4>{title}</h4> : <h5>{title}</h5>}
            <Box style={{ marginLeft: 10 }}>
              <CSVDownload
                csvData={[csvData]}
                fileName={histoType + '_norms'}
              ></CSVDownload>
            </Box>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                {quantiles.map((q) => (
                  <TableCell key={q}>{toOrdinal(q * 100)} Percentile</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={'componentnorm_' + histoType}>
                {quantiles.map((q) => (
                  <TableCell key={q}>
                    {d3.quantile(hdata, q).toFixed(2)}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid key="norm-right" container item xs={8} style={{ paddingTop: 30 }}>
        <Grid item xs={12}>
          <ReportHistogramPlot
            svgId={svgId(histoType, dataType)}
            hdata={hdata}
            quantiles={quantiles}
            rangeMin={rangeMin}
            rangeMax={rangeMax}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportHistogram;
