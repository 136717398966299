import { reduxForm } from 'redux-form';

import HeavyUser from './HeavyUser';
import formSubmit from '../../actions/formSubmit';
import { HEAVY_USER_INFO_FORM } from '../../constants/formNames';

const validation = (val) =>
  !val || (typeof val === 'object' && val.length === 0);

export default reduxForm({
  form: HEAVY_USER_INFO_FORM,
  onSubmit: (values, dispatch) => {
    dispatch(formSubmit(HEAVY_USER_INFO_FORM));
  },
  validate: (values) => {
    return {
      email: validation(values.email) && validation(values.username),
      username: validation(values.email) && validation(values.username),
    };
  },
})(HeavyUser);
