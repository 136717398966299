import { groupBy, keys } from 'lodash';

import { TimeSeriesRow } from '../../../containers/ProjectSummary/tabs/TrendAnalysis';

export const getSeriesY = (
  group: TimeSeriesRow[],
  i: number,
  deltaYoY: boolean,
): number => {
  const row = group[i];

  if (deltaYoY) {
    // Compute the % delta from previous year
    const prior = group[Math.max(i - 1, 0)];
    return (row.pq - prior.pq) / prior.pq;
  } else {
    // Deliever the absolute PQ value
    return row.pq;
  }
};

export const getChartData = (
  timeSeriesData: TimeSeriesRow[],
  deltaYoY: boolean,
  colors?: string[],
): reports.NivoDataSeries[] => {
  const groupedData = groupBy(timeSeriesData, (d) => d.seriesId);
  const chartData: reports.NivoDataSeries[] = keys(groupedData).map((k, ki) => {
    const group = groupedData[k];
    const groupPast = group.filter((d) => !d.isFuture);
    const latestPastYear = Math.max(...groupPast.map((d) => d.year));

    const seriesEnriched = group.map((row, i) => ({
      x: row.year,
      y: getSeriesY(group, i, deltaYoY),
      error: +row.error,
      isPast: row.year < latestPastYear,
      isCurrent: row.year === latestPastYear,
      isFuture: row.year > latestPastYear,
    }));
    return {
      id: k,
      data: seriesEnriched,
      color: colors[ki] ?? null,
    };
  });
  return chartData;
};

export const getYScaleMin = (
  chartData: reports.NivoDataSeries[],
  useSharedPq: boolean,
  minPq: number,
  maxPq: number,
): number | 'auto' => {
  const yValues = chartData.flatMap((i) => i.data).map((j) => j.y);

  if (yValues.length > 1) {
    if (useSharedPq) {
      return Math.floor(minPq - (maxPq - minPq) / 5);
    } else {
      return (
        Math.min(...yValues) - (Math.max(...yValues) - Math.min(...yValues)) / 5
      );
    }
  } else {
    return 'auto';
  }
};

export const getYScaleMax = (
  chartData: reports.NivoDataSeries[],
  useSharedPq: boolean,
  minPq: number,
  maxPq: number,
): number | 'auto' => {
  const yValues = chartData.flatMap((i) => i.data).map((j) => j.y);

  if (yValues.length > 1) {
    if (useSharedPq) {
      return Math.ceil(maxPq + (maxPq - minPq) / 5);
    } else {
      return (
        Math.max(...yValues) + (Math.max(...yValues) - Math.min(...yValues)) / 5
      );
    }
  } else {
    return 'auto';
  }
};
