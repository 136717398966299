import { INTERFACE_LANGUAGES } from 'constants/interfaceLanguages';

import * as React from 'react';
import { useState } from 'react';

import { Check as CheckIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import MaterialButton from 'components/MaterialButton';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import useStyles from './useStyles';
import graphqlClient from '../../consumers/graphqlClient';
import UpdateUserProfileMutation from '../../graphql/mutations/UpdateUserProfile';
import ViewerQuery from '../../graphql/queries/ViewerQuery';
import selectViewerUserId from '../../selectors/viewerUserId';
import { PageHeader, PageTitle } from '../../styles/themeComponents';

interface Props {
  title: string;
  viewerUserId: number;
}

const LanguageSelection: React.FunctionComponent<Props> = ({
  viewerUserId,
}) => {
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Paper className={classes.container}>
      <PageHeader>{t('languages.languageSetting')}</PageHeader>
      <PageTitle>{t('languages.selectLanguage')}</PageTitle>
      <div className={classes.languagesContainer}>
        {INTERFACE_LANGUAGES.map((language) => (
          <div key={language.unicode} className={classes.languageContainer}>
            <span
              className={
                currentLanguage === language.unicode
                  ? classes.languageSelected
                  : classes.languageUnselected
              }
              onClick={() => setCurrentLanguage(language.unicode)}
            >
              {language.label}
            </span>
            {currentLanguage === language.unicode && (
              <CheckIcon
                fontSize="small"
                htmlColor={theme.palette.secondary.main}
              />
            )}
          </div>
        ))}
      </div>
      <div className={classes.buttonContainer}>
        <MaterialButton
          color="secondary"
          variant="contained"
          size="large"
          onClick={() => {
            i18next.changeLanguage(currentLanguage).then(() =>
              graphqlClient.mutate({
                mutation: UpdateUserProfileMutation,
                variables: {
                  id: viewerUserId,
                  userPatch: {
                    interfaceLanguage: currentLanguage,
                  },
                },
                refetchQueries: [
                  {
                    query: ViewerQuery,
                  },
                ],
              }),
            );
          }}
        >
          {t('languages.saveSetting')}
        </MaterialButton>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  viewerUserId: selectViewerUserId(state),
});

export default connect(mapStateToProps)(LanguageSelection);
