import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import flexContainer from '../../styles/flexContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      ...flexContainer(theme),
      flex: 1,
      overflowX: 'scroll',
    },
  }),
);

export default useStyles;
