import { ProductVersionPq } from 'hooks/useReportSummary';
import { chain, filter } from 'lodash';

import { ProductVersion, ProductVersionSet } from '../../ProductVersion';
import { getProductName } from '../../utils';

/**
 * Select out the appropriate cell value dynamically
 *
 * @param row row of TextureComponisitionClusterRow for a product
 * @param tcIdx the "TC1", "TC2", ... cluster name
 * @returns texture composition value
 */
export const selectCellValue = (
  row: reports.TextureComponisitionClusterRow,
  tcIdx: string,
) =>
  filter(row.clusters, (x) => x.clusterIdx === parseInt(tcIdx.substring(2)))[0]
    .composition;

export const numberOfClusters = (
  reportTextureCompositionResponse: reports.ReportTextureCompositionResponse,
): number[] => {
  return chain([
    ...reportTextureCompositionResponse.allRpTextureCompositions.nodes,
  ])
    .map((row) => row.clusterIdx)
    .uniq()
    .value();
};

export const sortByPq = (
  a: reports.TextureComponisitionClusterRow,
  b: reports.TextureComponisitionClusterRow,
) => {
  return a.pq > b.pq ? -1 : 1;
};

export const getTCNameByIndex = (
  tcNameData: reports.ReportTextureClusterNamesResponse,
  index: number,
) => {
  if (tcNameData) {
    return (
      tcNameData?.allRpTextureClusterNames?.nodes.find(
        (x) => x.clusterIdx === index,
      )?.clusterName || ''
    );
  } else {
    return '';
  }
};

export const getTCDisplayName = (
  tcNameData: reports.ReportTextureClusterNamesResponse,
  index: number,
  useTextureClusterName: boolean,
) =>
  useTextureClusterName
    ? getTCNameByIndex(tcNameData, index) ?? 'TC' + index
    : 'TC' + index;

export const getTCStandardErrorByIndex = (
  tcStandardErrorData: reports.ReportTextureClusterStandardErrorResponse,
  index: number,
): string =>
  Number(
    tcStandardErrorData.allRpTextureStandardErrors.nodes.find(
      (x) => x.clusterIdx === index,
    )?.standardError,
  ).toFixed(2);

export const isStandardErrorRequested = (
  tcStandardErrorData: reports.ReportTextureClusterStandardErrorResponse,
): boolean => tcStandardErrorData.allRpTextureStandardErrors.nodes.length > 0;

export const getTextureProductIdSet = (
  reportTextureCompositionResponse: reports.ReportTextureCompositionResponse,
): ProductVersionSet => {
  return reportTextureCompositionResponse.allRpTextureCompositions.nodes
    .map((n) => new ProductVersion(n.productByProductId.id, n.version))
    .reduce((a, c) => (a.add(c) ? a : a), new ProductVersionSet());
};

export const getReshapedData = (
  reportTextureCompositionResponse: reports.ReportTextureCompositionResponse,
  reportProductVersionSet: ProductVersionSet,
  reportProductVersionPqs: ProductVersionPq[],
  customProductNames: reports.ColorVersionedProductInfo[],
): reports.TextureComponisitionClusterRow[] => {
  const textureIdSets = getTextureProductIdSet(
    reportTextureCompositionResponse,
  );

  const missingIdSet = new ProductVersionSet(
    reportProductVersionSet.getItems().filter((x) => !textureIdSets.has(x)),
  );

  const refinedList = [
    ...reportTextureCompositionResponse.allRpTextureCompositions.nodes,
  ];
  missingIdSet.getItems().forEach((id) => {
    let clx = 1;
    while (clx < 8) {
      refinedList.push({
        productByProductId: {
          id: id.productId,
          name: '',
        },
        version: id.version,
        clusterIdx: clx,
        composition: null,
      });
      clx++;
    }
  });

  return chain(refinedList)
    .filter(
      (node) =>
        !!node.productByProductId &&
        reportProductVersionSet.has(
          new ProductVersion(node.productByProductId.id, node.version),
        ),
    )
    .groupBy(
      (i) => new ProductVersion(i.productByProductId.id, i.version).value,
    )
    .map((value, key) => ({
      id: key,
      name: getProductName({
        productNames: customProductNames,
        productId: value[0].productByProductId.id,
        version: value[0].version,
      }),
      pq: reportProductVersionPqs.find((i) =>
        i.productVersion.equals(
          new ProductVersion(value[0].productByProductId.id, value[0].version),
        ),
      )?.pq,
      clusters: value,
    }))
    .value();
};
