import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import ProjectMapQuery from '@graphql/queries/ProjectMapQuery';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Divider,
  useTheme,
} from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { ProductVersion } from 'components/Report/ProductVersion';
import { getProductName } from 'components/Report/utils';
import ProjectMap from 'containers/ProjectMap';
import { cleanCountryNames } from 'containers/ProjectMap/mapUtils';
import useAvailableCountries from 'hooks/useAvailableCountries';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { useTranslation } from 'react-i18next';

import useStyles from '../useStyles';

interface Props {
  projectId: number;
  workspaceId: number;
  reportId: string;
}

interface ProjectDemoProductPQResponse {
  allProjectReports: {
    nodes: {
      projectId: number;
      reportId: string;
      reportJobByReportId: {
        params: object;
        targetGroupName: string;
        reportSummariesByReportId: {
          nodes: {
            productByProductId: {
              id: number;
              name: string;
            };
            version: string;
            pq: number;
          }[];
        };
      };
    }[];
  };
}

const ProjectMapSummary: React.FC<Props> = (props) => {
  const { projectId, workspaceId, reportId } = props;
  const { t } = useTranslation();
  const classes = useStyles(useTheme());

  const { loading, error, data } = useQuery<ProjectDemoProductPQResponse>(
    ProjectMapQuery,
    {
      variables: {
        projectId: +projectId,
      },
    },
  );

  const [selectedProductId, setSelectedProductId] = useState<string>('');

  const { availableCountries } = useAvailableCountries(workspaceId);
  const customProductNames = useCustomProductNames({
    reportId,
    projectId: Number(projectId),
  });

  const pqRange = (data: ProjectDemoProductPQResponse) => {
    const reports = data.allProjectReports.nodes.map(
      (n) => n.reportJobByReportId,
    );
    const pqs = reports
      .map((r) => r.reportSummariesByReportId.nodes.map((p) => +p.pq))
      .flat();
    return [
      Math.min(...pqs),
      Math.max(...pqs),
      pqs.reduce((a, b) => a + b, 0) / pqs.length,
    ];
  };

  const getMapData = (
    data: ProjectDemoProductPQResponse,
    selectedProductId: string,
  ): reports.MapDataRow[] => {
    const reports = data.allProjectReports.nodes.map(
      (n) => n.reportJobByReportId,
    );
    return reports
      .map((r) =>
        r.reportSummariesByReportId.nodes.map((p) => ({
          country: r.targetGroupName,
          cleanCountryName: cleanCountryNames(r.params, r.targetGroupName),
          productId: p.productByProductId.id,
          version: p.version,
          pq: p.pq,
        })),
      )
      .flat()
      .filter(
        (d) =>
          new ProductVersion(d.productId, d.version).value ===
          selectedProductId,
      );
  };

  if (loading || availableCountries.size === 0) {
    return <LoadingScreen />;
  } else if (error) {
    return <h3>Failed to load project demographic data.</h3>;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={10}>
            <div className={classes.selectProductView}>
              <div className={classes.select}>
                <FormControl
                  variant="standard"
                  fullWidth
                  style={{ marginBottom: '1rem' }}
                >
                  <InputLabel shrink>{t('reports.product')}</InputLabel>
                  <Select
                    variant="standard"
                    value={selectedProductId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSelectedProductId(e.target.value)
                    }
                  >
                    {customProductNames
                      .filter((i) => i.version === null)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((cpn) => new ProductVersion(cpn.id, cpn.version))
                      .map((product) => (
                        <MenuItem
                          key={product.value + 'unversioned'}
                          value={product.value}
                        >
                          {getProductName({
                            productNames: customProductNames,
                            productId: product.productId,
                            version: product.version,
                          })}
                        </MenuItem>
                      ))}
                    <Divider />
                    {customProductNames
                      .filter((i) => i.version !== null)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((cpn) => new ProductVersion(cpn.id, cpn.version))
                      .map((product) => (
                        <MenuItem
                          key={product.value + 'versioned'}
                          value={product.value}
                        >
                          {getProductName({
                            productNames: customProductNames,
                            productId: product.productId,
                            version: product.version,
                          })}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <ProjectMap
          projectId={projectId}
          workspaceId={workspaceId}
          reportId={reportId}
          availableCountries={availableCountries}
          mapData={getMapData(data, selectedProductId)}
          minPq={pqRange(data)[0]}
          maxPq={pqRange(data)[1]}
          meanPq={pqRange(data)[2]}
        />
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default ProjectMapSummary;
