import { ReportNoveltyIntensityPqResponse } from '@graphql/queries/ReportNoveltyIntensityPqQuery';
import { ScaleSpec } from '@nivo/scales';
import {
  ScatterPlotDatum,
  ScatterPlotNodeData,
  ScatterPlotTooltipProps,
} from '@nivo/scatterplot';
import { getProductName } from 'components/Report/utils';
import { regressionLinear } from 'd3-regression';
import { line } from 'd3-shape';

const nivoMin = (
  chartData: reports.NivoDataSeries[],
  select: (r: reports.NivoData) => number,
) => Math.min(...chartData.flatMap((d) => d.data).map(select));
const nivoMax = (
  chartData: reports.NivoDataSeries[],
  select: (r: reports.NivoData) => number,
) => Math.max(...chartData.flatMap((d) => d.data).map(select));

const xMin = (chartData: reports.NivoDataSeries[]) =>
  nivoMin(chartData, (r) => r.x);
const xMax = (chartData: reports.NivoDataSeries[]) =>
  nivoMax(chartData, (r) => r.x);
const xRange = (chartData: reports.NivoDataSeries[]) =>
  xMax(chartData) - xMin(chartData);

/**
 * Calculate NIVO chart range x-minimum
 * @param chartData underlying data
 * @returns safely padded min value
 */
export const xRangeMin = (chartData: reports.NivoDataSeries[]) =>
  xMin(chartData) - xRange(chartData) / 15;

/**
 * Calculate NIVO chart range x-maximum
 * @param chartData underlying data
 * @returns safely padded max value
 */
export const xRangeMax = (chartData: reports.NivoDataSeries[]) =>
  xMax(chartData) + xRange(chartData) / 15;

const yMin = (chartData: reports.NivoDataSeries[]) =>
  nivoMin(chartData, (r) => r.y);
const yMax = (chartData: reports.NivoDataSeries[]) =>
  nivoMax(chartData, (r) => r.y);
const yRange = (chartData: reports.NivoDataSeries[]) =>
  yMax(chartData) - yMin(chartData);

/**
 * Calculate NIVO chart range y-minimum
 * @param chartData underlying data
 * @returns safely padded min value
 */
export const yRangeMin = (chartData: reports.NivoDataSeries[]) =>
  yMin(chartData) - yRange(chartData) / 15;

/**
 * Calculate NIVO chart range y-maximum
 * @param chartData underlying data
 * @returns safely padded max value
 */
export const yRangeMax = (chartData: reports.NivoDataSeries[]) =>
  yMax(chartData) + yRange(chartData) / 15;

/**
 * Basic PQ scale for Nivo
 */
export const pqScale: ScaleSpec = { type: 'linear', min: 1, max: 7 };

/**
 * Nivo x ScaleSpec based on data
 * @param chartData underlying data
 * @returns Nivo ScaleSpec for x-axis
 */
export const xScale = (chartData: reports.NivoDataSeries[]): ScaleSpec => ({
  type: 'linear',
  min: xRangeMin(chartData),
  max: xRangeMax(chartData),
});

/**
 * Nivo y ScaleSpec based on data
 * @param chartData underlying data
 * @returns Nivo ScaleSpec for y-axis
 */
export const yScale = (chartData: reports.NivoDataSeries[]): ScaleSpec => ({
  type: 'linear',
  min: yRangeMin(chartData),
  max: yRangeMax(chartData),
});

/**
 * Map the graphql row to a more standard NivoDataSeries object
 *
 * @param row Map a graphql response tuning plot row to a standard NivoDataSeries row
 * @returns standardized NivoDataSeries
 */
export function mapRowToIntensityChartData(
  row: reports.ReportNoveltyIntesityPqRow,
  type: 'intesityPq' | 'noveltyPq' | 'noveltyIntensity',
  productNames: reports.ColorVersionedProductInfo[],
): reports.NivoDataSeries {
  switch (type) {
    case 'intesityPq':
      return {
        id: getProductName({
          productNames,
          productId: row.productByProductId.id,
          version: row.version,
        }),
        data: [
          {
            x: row.originalIntensity,
            y: row.pq,
          },
        ],
      };
    case 'noveltyPq':
      return {
        id: getProductName({
          productNames,
          productId: row.productByProductId.id,
          version: row.version,
        }),
        data: [
          {
            x: row.noveltyScore,
            y: row.pq,
          },
        ],
      };
    case 'noveltyIntensity':
      return {
        id: getProductName({
          productNames,
          productId: row.productByProductId.id,
          version: row.version,
        }),
        data: [
          {
            x: row.originalIntensity,
            y: row.noveltyScore,
          },
        ],
      };
  }
}

export function mapDataToNivoScatterData(
  data: ReportNoveltyIntensityPqResponse,
  type: 'intesityPq' | 'noveltyPq' | 'noveltyIntensity',
  productNames: reports.ColorVersionedProductInfo[],
): reports.NivoDataSeries[] {
  return data.allRpNoveltyIntensityPqs.nodes?.map((row) =>
    mapRowToIntensityChartData(row, type, productNames),
  );
}

export const RegressionLineLayer = ({ nodes, xScale, yScale }) => {
  const xyArr = nodes.map((v: ScatterPlotNodeData<ScatterPlotDatum>) => [
    v.xValue,
    v.yValue,
  ]);

  const lineGenerator = line()
    .x((d) => xScale(d[0]))
    .y((d) => yScale(d[1]));

  const regression = regressionLinear()
    .x((d: [number, number]) => d[0])
    .y((d: [number, number]) => d[1])
    .domain([
      Math.min(...xyArr.map((d) => d[0])),
      Math.max(...xyArr.map((d) => d[0])),
    ]);

  const regressionLine = regression(xyArr);

  return <path d={lineGenerator(regressionLine)} stroke="red" fill="none" />;
};

/**
 * Render a simple tooltip with series name
 *
 * @param node Nivo Scatterplot node
 * @param className style class name
 * @returns tooltip object
 */
export const serieIdToolip = (
  node: React.PropsWithChildren<ScatterPlotTooltipProps<reports.NivoData>>,
  className: string,
) => <div className={className}>{node.node.serieId}</div>;
