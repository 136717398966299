
import { gql } from '@apollo/client';

export default gql`
query UserWorkspaceListQueryByUserId($userId: Int!) {
  user: userById(id: $userId) {
    producerUsersByUserId {
      nodes {
        producerId
        producerByProducerId {
          name
          producerPartnerByProducerId {
            partnerByPartnerId {
              id
              name
            }
          }
        }
      }
    }
  }
}
`;
