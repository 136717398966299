import * as React from 'react';

import ProductSearchTable from 'components/ProductSearchTable';
import ProducerPreference from 'containers/ProducerPreference';
import ProductFeatureBulkAdd from 'containers/ProductFeatureBulkAdd';
import ResetPasswordContainer from 'containers/ResetPassword';
import SwapPanelProductReviews from 'containers/SwapPanelProductReviews';
import ViewCategoriesAndProducts from 'containers/ViewCategoriesAndProducts';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AdminToolsHeader from './SuperadminToolsHeader';
import AdminToolItem from '../../components/AdminToolItem';
import MaterialButton from '../../components/MaterialButton';
import {
  SUPERADMIN_PRODUCT_FILTER,
  USERS_DEMOGRAPHIC,
} from '../../constants/routePaths';
import {
  SUPERADMIN_GROUP_WORKSPACES,
  SUPERADMIN_EDIT_USER_ROLE,
} from '../../constants/routePaths';
import { PagePaper } from '../../styles/themeComponents';
import AddClient from '../AddClient';
import AddUserToWorkspace from '../AddUserToWorkspace';
import AllWorkspaceList from '../AllWorkspaceList';
import ChangePassword from '../ChangePassword';
import CreateCategory from '../CreateCategory';
import CreateWorkspace from '../CreateWorkspace';
import EditUserRoleContainer from '../EditUserRoles';
import HeavyUser from '../HeavyUser';
import HeavyUserTag from '../HeavyUserTag';
import MergeAccount from '../MergeAccount';
import PartnerBranding from '../PartnerBranding';
import ProductMove from '../ProductMove';
import RemoveUserFromWorkspace from '../RemoveUserFromWorkspace';
import SmokeTests from '../SmokeTests';
import UserCreate from '../UserCreate';
import UserWorkspaceList from '../UserWorkspaceList';

const SuperadminToolsContainer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PagePaper>
      <AdminToolsHeader />
      <br />

      <Link to={{ pathname: SUPERADMIN_PRODUCT_FILTER }}>
        <MaterialButton variant="outlined" soft teal>
          Tag Product Filter
        </MaterialButton>
      </Link>

      <Link to={{ pathname: SUPERADMIN_GROUP_WORKSPACES }}>
        <MaterialButton variant="outlined" soft teal>
          GROUP WORKSPACES
        </MaterialButton>
      </Link>

      <Link to={{ pathname: SUPERADMIN_EDIT_USER_ROLE }}>
        <MaterialButton variant="outlined" soft teal>
          {t('users.editUserRole')}
        </MaterialButton>
      </Link>

      <Link to={{ pathname: USERS_DEMOGRAPHIC }}>
        <MaterialButton variant="outlined" soft teal>
          Users Demographic
        </MaterialButton>
      </Link>

      <br />
      <br />

      <AdminToolItem title={t('users.createUser')} component={<UserCreate />} />
      <AdminToolItem
        title={'Reset Password'}
        component={<ResetPasswordContainer />}
      />
      <AdminToolItem
        title={t('users.changePassword')}
        component={<ChangePassword />}
      />
      <AdminToolItem
        title={t('users.editUserRole')}
        component={<EditUserRoleContainer />}
      />
      <AdminToolItem
        title={t('admin.addUserWorkspace')}
        component={<AddUserToWorkspace />}
      />
      <AdminToolItem
        title={t('admin.removeUserWorkspace')}
        component={<RemoveUserFromWorkspace />}
      />
      <AdminToolItem
        title={t('admin.mergeAccount')}
        component={<MergeAccount />}
      />
      <AdminToolItem
        title={t('admin.userWorkspaces')}
        component={<UserWorkspaceList />}
      />
      <AdminToolItem
        title={t('admin.allWorkspaceList')}
        component={<AllWorkspaceList />}
      />
      <AdminToolItem
        title={t('admin.createWorkspace')}
        component={<CreateWorkspace />}
      />
      <AdminToolItem
        title={t('admin.heavyUserInfo')}
        component={<HeavyUser />}
      />
      <AdminToolItem
        title={t('admin.tagHeavyUser')}
        component={<HeavyUserTag />}
      />
      <AdminToolItem
        title={t('admin.createCategory')}
        component={<CreateCategory />}
      />
      <AdminToolItem
        title={t('admin.createClient')}
        component={<AddClient />}
      />
      <AdminToolItem title={t('admin.smokeTests')} component={<SmokeTests />} />
      <AdminToolItem
        title={t('admin.moveProductWorkspace')}
        component={<ProductMove />}
      />
      <AdminToolItem
        title={t('admin.producerPreference')}
        component={<ProducerPreference />}
      />
      <AdminToolItem
        title={t('admin.partnerBranding')}
        component={<PartnerBranding />}
      />
      <AdminToolItem
        title={t('admin.searchProduct')}
        component={<ProductSearchTable />}
      />
      <AdminToolItem
        title={t('admin.productsInCategory')}
        component={<ViewCategoriesAndProducts />}
      />
      <AdminToolItem
        title={'Product Feature Bulk Edit'}
        component={<ProductFeatureBulkAdd />}
      />
      <AdminToolItem
        title={t('admin.swapPanelProductReviews')}
        component={<SwapPanelProductReviews />}
      />
    </PagePaper>
  );
};

export default SuperadminToolsContainer;
