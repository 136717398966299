import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Grid, Paper } from '@mui/material';
import { formatReportLabel } from 'containers/Project/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { nullCheck } from 'utils/afsUtils';

import styles from './ReportDashboard.module.css';
import ReportDashboardContent from './ReportDashboardContent';
import AllReportsQuery from '../../graphql/queries/AllReportsQuery';
import selectViewerUserId from '../../selectors/viewerUserId';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';

interface Props extends RouterProps {
  match: Record<string, any>;
}

interface ReportInfo {
  key: number;
  name: string;
  reportId: string;
}

const ReportDashboard: React.FC<Props> = (props) => {
  const { match } = props;

  const { t } = useTranslation();
  const workspaceProducerId = useSelector((state) =>
    selectWorkspaceProducerId(state),
  );
  const viewerUserId = useSelector((state) => selectViewerUserId(state));

  const [reportId, setReportId] = useState(
    nullCheck(match.params.reportId) || '',
  );

  const { loading, error, data } = useQuery<reports.AllReportsQueryResponse>(
    AllReportsQuery,
    {
      variables: {
        filter: {
          workspaceId: { equalTo: workspaceProducerId },
          reportStatus: { notEqualTo: 'FAILED' },
        },
        orderBy: 'ID_DESC',
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setReportId(event.target.value);

  const getReshapedData = (
    reportJobsResults: reports.AllReportsQueryResponse,
  ): ReportInfo[] =>
    reportJobsResults.reports.nodes.map(
      (report, index) =>
        ({
          key: index,
          name: formatReportLabel(
            report.projectName,
            report.targetGroupName,
            report.reportType,
            report.startedAt,
          ),
          reportId: report.reportId,
        }) as ReportInfo,
    );

  if (loading || error) {
    return <div />;
  }

  return (
    <Paper className={styles.container}>
      <h3 className={styles.productTitle}>{t('reports.dashboard')}</h3>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="report-id-select-label">
              {t('reports.report')}
            </InputLabel>
            <Select
              variant="standard"
              id="report-id-select"
              value={reportId}
              onChange={handleChange}
            >
              {getReshapedData(data).map((report) => (
                <MenuItem key={report.reportId} value={report.reportId}>
                  {report.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <ReportDashboardContent
          reportId={reportId}
          workspaceProducerId={workspaceProducerId}
          viewerUserId={viewerUserId}
        />
      </Grid>
    </Paper>
  );
};

export default ReportDashboard;
