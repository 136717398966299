import * as React from 'react';

import { useQuery } from '@apollo/client';
import PartnerAdminProductSearchQuery from '@graphql/queries/PartnerAdminProductSearchQuery';
import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import { get } from 'lodash';
import moment from 'moment';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import styles from './PartnerAdminProductSearch.module.css';
import { tableCustomStyles } from '../../components/ReactDataTable/tableCustomStyles';
import { PRODUCT } from '../../constants/routePaths';
import formatPath from '../../utils/formatPath';
import MaterialButton from '../MaterialButton';

interface TableRow {
  id: number;
  image: string;
  name: string;
  brand: string;
  date: string;
  reviews: number;
  lastReview: string;
  textureReviewsLowerBound: number;
  category: string;
  workspace: string;
  productWorkspaceId: number;
  productWorkspaceName: string;
}

interface Props {
  query: string;
  hideSearch: () => void;
  first: number;
  onClick: (row: any, node: any) => void;
}

const PartnerAdminProductSearch: React.FC<Props> = ({
  query,
  onClick,
  first,
  hideSearch,
}) => {
  const theme = useTheme();
  const node = React.useRef(null);
  const [paginateCount, setpaginateCount] = React.useState(first);

  const producerId = useSelector((state) => selectWorkspaceProducerId(state));

  const { t } = useTranslation();

  const { data, loading, fetchMore } = useQuery(
    PartnerAdminProductSearchQuery,
    {
      variables: {
        query,
        producerId: [producerId],
        first: first,
      },
      onCompleted: () => {
        setpaginateCount(paginateCount + first);
      },
    },
  );

  const handleClick = (e) => {
    // Clicking outside
    if (!(node && node.current && node.current.contains(e.target))) {
      hideSearch();
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    return () => document.removeEventListener('mousedown', handleClick, false);
  }, []);

  const getProductData = (): TableRow[] => {
    const products = get(data, 'productResults.nodes', []);
    return products.map((product) => ({
      id: product.id,
      image:
        get(product, 'productImages.totalCount') > 0 &&
        product.productImages.nodes[0].url,
      name: product.name,
      brand: product.brand,
      date: moment(product.createdAt).format('ll'),
      reviews: get(product, 'productReviews.totalCount'),
      lastReview:
        get(product, 'productReviews.nodes.length') > 0
          ? moment(product.productReviews.nodes[0].createdAt).format('ll')
          : '',
      textureReviewsLowerBound: get(product, 'lastAlphabeticalTextureReviews')
        ?.nodes.map((n) => n?.textureCsv?.length > 0)
        .reduce((a, b) => a + b, 0),
      category: product.category,
      workspace: product.producer.name,
      productWorkspaceId: product.producer.id,
      productWorkspaceName: product.producer.name,
    }));
  };

  const onFetchMore = () => {
    fetchMore({
      variables: {
        query,
        producerId: [producerId],
        first: paginateCount,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        setpaginateCount(paginateCount + first);
        return Object.assign({}, prev, {
          productResults: {
            nodes: [
              ...prev.productResults.nodes,
              ...fetchMoreResult.productResults.nodes,
            ],
          },
          ...fetchMoreResult,
        });
      },
    });
  };

  const columns: TableColumn<TableRow>[] = [
    {
      selector: (row: TableRow) => row.id,
      name: 'Product ID',
      sortable: true,
      omit: true,
    },
    {
      selector: (row: TableRow) => row.image,
      name: t('general.image'),
      cell: (row: TableRow) =>
        row.image ? (
          <div className={styles.imageContainer}>
            <img
              src={row.image}
              alt={`${row.id}_img`}
              className={styles.image}
            />
          </div>
        ) : (
          <div />
        ),
      omit: !!onClick,
    },
    {
      selector: (row: TableRow) => row.name,
      name: t('product.productName'),
      cell: (row: TableRow) => {
        if (onClick) {
          return (
            <a
              className={styles.link}
              onClick={() => onClick(row, data.productResults.nodes)}
            >
              {row.name}
            </a>
          );
        } else {
          return (
            <Link
              className={styles.link}
              to={{ pathname: formatPath(PRODUCT, { productId: row.id }) }}
            >
              {row.name}
            </Link>
          );
        }
      },
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.brand,
      name: t('product.productBrand'),
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.date,
      name: t('general.dateCreated'),
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.reviews,
      name: t('reviews.reviews'),
    },
    {
      selector: (row: TableRow) => row.workspace,
      name: 'Workspace',
    },
    {
      selector: (row: TableRow) => row.lastReview,
      name: t('reviews.lastReview'),
    },
    {
      selector: (row: TableRow) => row.category,
      name: 'Category',
      omit: true,
    },
  ];

  if (loading) return <div />;

  return (
    <div ref={node} key={paginateCount}>
      <Paper className={styles.container}>
        <div className={styles.tableContainer}>
          <DataTable
            columns={columns}
            data={getProductData()}
            customStyles={tableCustomStyles(theme, true)}
            striped
          />
          <div style={{ textAlign: 'center', marginBottom: first }}>
            {data.productResults.totalCount >
              data.productResults.nodes.length && (
              <MaterialButton
                variant="outlined"
                soft
                onClick={() => {
                  onFetchMore();
                  // onFetchMore(data.productResults.nodes.length + count)
                }}
              >
                Load More
              </MaterialButton>
            )}
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default PartnerAdminProductSearch;
