import * as React from 'react';

import { Tooltip, useTheme } from '@mui/material';
import { chain, countBy } from 'lodash';
import DataTable, {
  ConditionalStyles,
  TableColumn,
} from 'react-data-table-component';

import { totalFlagsForReview } from './dataquality.utils';
import ProductFlavorTooltip from './ProductFlavorTooltip';
import dataTableCsv from '../../components/ReactDataTable/ReactDataTableCsv';
import { tableCustomStyles } from '../../components/ReactDataTable/tableCustomStyles';

const FLAGS = {
  allggvar: 'All GGVar',
  allmarksatfive: 'All Marks at 5',
  norefflavor: 'More Flavor',
  insufficientggvar: 'Insufficient GGVar',
  excessiverefflavor: 'Excessive Flavor',
  excessiveggvar: 'Excessive GGVar',
  excessivetexture: 'Excessive Texture',
  zeroMouthfeelIntensity: 'Zero Mouthfeel Intensity',
  tooManySevens: 'Too Many 7s',
};

const minDataTooltip = (cleanReviews: number, cutOff: number) => (
  <span>
    Minimum Usable Data Required: {cutOff}
    <br />
    Current Usable Data: {cleanReviews}
  </span>
);

const productOnePlusFlags = (panelReviews: dataquality.PanelQualityData[]) =>
  panelReviews.reduce((productOnePlusFlags, review) => {
    const runningFlagTotal = totalFlagsForReview(review);
    if (runningFlagTotal > 0) {
      if (
        Object.prototype.hasOwnProperty.call(
          productOnePlusFlags,
          review.productName,
        )
      ) {
        productOnePlusFlags[review.productName] += 1;
      } else {
        productOnePlusFlags[review.productName] = 1;
      }
    }
    return productOnePlusFlags;
  }, {});

const productTotalFlags = (panelReviews: dataquality.PanelQualityData[]) =>
  panelReviews.reduce((productTotalFlags, review) => {
    const runningFlagTotal = totalFlagsForReview(review);
    if (runningFlagTotal > 0) {
      if (productTotalFlags[review.productName]) {
        productTotalFlags[review.productName] += runningFlagTotal;
      } else {
        productTotalFlags[review.productName] = runningFlagTotal;
      }
    }
    return productTotalFlags;
  }, {});

const productTotalFlagsSummary = (
  panelReviews: dataquality.PanelQualityData[],
  panelProductId: number,
) => {
  const flagsList = panelReviews
    .filter((f) => f.productId === panelProductId)
    .reduce((flagsArray, review) => {
      const filteredFlag = Object.fromEntries(
        Object.entries(review.flags).filter(([key, value]) => value),
      );
      flagsArray.push(filteredFlag);
      return flagsArray;
    }, []);

  const trueFlagValueCounts: any = {};
  flagsList.forEach((obj) => {
    for (const key in obj) {
      if (obj[key] === true) {
        if (key in trueFlagValueCounts) {
          trueFlagValueCounts[key]++;
        } else {
          trueFlagValueCounts[key] = 1;
        }
      }
    }
  });
  const computedList = [];
  for (const flag in trueFlagValueCounts) {
    computedList.push(`${FLAGS[flag]}: ${trueFlagValueCounts[flag]}`);
  }
  return computedList;
};

const getMinimumDataMet = (
  panelReviews: dataquality.PanelQualityData[],
  name: string,
  totalCount: number,
): boolean => {
  const { cleanReviews, cutOff } = hasMetMinData(
    panelReviews,
    name,
    totalCount,
  );
  return cleanReviews > 0 && cleanReviews >= cutOff;
};

const hasMetMinData = (
  panelReviews: dataquality.PanelQualityData[],
  productName: string,
  totalReviewCount: number,
) => {
  const flaggedReview = productOnePlusFlags(panelReviews)[productName]
    ? productOnePlusFlags(panelReviews)[productName]
    : 0;
  const cutOff = Math.min(totalReviewCount, 10);
  const cleanReviews = totalReviewCount - flaggedReview;

  return { cleanReviews, cutOff };
};

const getAveragePq = (panelReviews: dataquality.PanelQualityData[]) =>
  chain(panelReviews)
    .groupBy((r) => r.productName)
    .map((value, key) => {
      return {
        name: key,
        averagePq:
          value.map((i) => i.perceivedQuality).reduce((a, b) => a + b, 0) /
          value.length,
      };
    })
    .value();

const getData = (
  panelProducts: PanelProductProp[],
  panelReviews: dataquality.PanelQualityData[],
): TableRow[] =>
  panelProducts.map((panelProduct, idx) => ({
    id: panelProduct.productByProductId.id,
    idx: idx,
    name: panelProduct.productByProductId.name,
    reviewTotal: panelProduct.productReviews.totalCount,
    totalFlagsSummary: productTotalFlagsSummary(
      panelReviews,
      panelProduct.productByProductId.id,
    ),
    totalFlags:
      productTotalFlags(panelReviews)[panelProduct.productByProductId.name] ||
      0,
    averagePq:
      Math.round(
        (getAveragePq(panelReviews)
          .filter((p) => p.name === panelProduct.productByProductId.name)
          .find(Boolean)?.averagePq +
          Number.EPSILON) *
          100,
      ) / 100 || null,
    percentFlagged: productTotalFlags(panelReviews)[
      panelProduct.productByProductId.name
    ]
      ? Math.round(
          (productTotalFlags(panelReviews)[
            panelProduct.productByProductId.name
          ] /
            panelProduct.productReviews.totalCount +
            Number.EPSILON) *
            100,
        ) / 100
      : 0,
    minimumDataMet: getMinimumDataMet(
      panelReviews,
      panelProduct.productByProductId.name,
      panelProduct.productReviews.totalCount,
    ),
  }));

interface TableRow {
  id: number;
  idx: number;
  name: string;
  reviewTotal: number;
  averagePq: number;
  totalFlagsSummary: any;
  totalFlags: number;
  percentFlagged: number;
  minimumDataMet: boolean;
}

export interface PanelProductProp {
  productByProductId: {
    id: number;
    name: string;
  };
  productReviews: {
    totalCount: number;
  };
}
interface Props {
  panelProducts: PanelProductProp[];
  panelReviews: dataquality.PanelQualityData[];
  panel: {
    endTime: string;
  };
}

const PanelProductDataQualityTable: React.FunctionComponent<Props> = ({
  panel,
  panelProducts,
  panelReviews,
}) => {
  const panelEndTime = new Date(panel.endTime);

  const theme = useTheme();

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: 'Product ID',
      omit: true,
      sortable: true,
    },
    {
      id: 'name',
      selector: (row: TableRow) => row.name,
      name: 'Product Name',
      sortable: true,
      cell: (row: TableRow) => (
        <ProductFlavorTooltip
          size={700}
          productName={row.name}
          flavorData={getFlavors(panelReviews, row.id)}
        />
      ),
    },
    {
      id: 'reviewTotal',
      selector: (row: TableRow) => row.reviewTotal,
      name: 'Total Reviews',
      sortable: true,
    },
    {
      id: 'averagePq',
      selector: (row: TableRow) => row.averagePq,
      name: 'Average Raw PQ',
      sortable: true,
    },
    {
      id: 'totalFlags',
      selector: (row: TableRow) => row.totalFlags,
      name: 'Total Flags',
      sortable: true,
      cell: (row: TableRow) => {
        return (
          <Tooltip
            title={`Flags for this review are: ${row.totalFlagsSummary.map((i) => i).join(', ')}`}
          >
            <span>{row.totalFlags}</span>
          </Tooltip>
        );
      },
    },
    {
      id: 'percentFlagged',
      selector: (row: TableRow) => row.percentFlagged,
      name: 'Percent Flagged',
      sortable: true,
      cell: (row: TableRow) =>
        isNaN(row.percentFlagged)
          ? '0%'
          : (row.percentFlagged * 100).toFixed(0) + '%',
    },
    {
      id: 'minimumDataMet',
      selector: (row: TableRow) => row.minimumDataMet,
      name: '> 10 Unflag Review',
      cell: (row: TableRow) => {
        const { cleanReviews, cutOff } = hasMetMinData(
          panelReviews,
          row.name,
          row.reviewTotal,
        );
        return (
          <Tooltip title={minDataTooltip(cleanReviews, cutOff)}>
            <span>{row.minimumDataMet ? '✔' : '❌'}</span>
          </Tooltip>
        );
      },
    },
  ];

  const conditionalRowStyles: ConditionalStyles<TableRow>[] = [
    {
      when: (row) => row.percentFlagged < 0.05,
      style: {
        backgroundColor: theme.palette.lowError.main,
      },
    },
    {
      when: (row) => row.percentFlagged < 0.25 && row.percentFlagged >= 0.05,
      style: {
        backgroundColor: theme.palette.midError.main,
      },
    },
    {
      when: (row) => row.percentFlagged >= 0.25,
      style: {
        backgroundColor: theme.palette.highError.main,
      },
    },
  ];

  const conditionalRowStylesBasic: ConditionalStyles<TableRow>[] = [
    {
      when: (row) => !!row.idx,
      style: (row) => ({
        backgroundColor: row.idx % 2 ? 'white' : theme.palette.tableRow.main,
      }),
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={getData(panelProducts, panelReviews)}
      customStyles={tableCustomStyles(theme, true)}
      striped
      conditionalRowStyles={
        panelEndTime && panelEndTime < new Date()
          ? conditionalRowStyles
          : conditionalRowStylesBasic
      }
      actions={dataTableCsv(
        `Product_Quality_Data.csv`,
        columns,
        getData(panelProducts, panelReviews),
      )}
    />
  );
};

export default PanelProductDataQualityTable;

const getFlavors = (panelReviews, productId: number) => {
  if (panelReviews) {
    const a = panelReviews
      .filter((pr) => pr.productId === productId)
      .map((pr) => Object.values(pr.referenceFlavorsParsed))
      .flatMap((i) => i.reduce((a, b) => a.concat(b), []))
      .map((z) => z.referenceFlavor);
    const b = countBy(a);

    const c = chain(b)
      .map((cnt, rf) => ({
        id: rf,
        value: cnt,
      }))
      .orderBy('count', 'desc')
      .value();

    return c.slice(0, 10);
  }
};
