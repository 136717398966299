import { gql } from '@apollo/client';

export default gql`
  query ReportTextureCompPrefByReportId($reportID: UUID!) {
    allRpTextureCompositions(condition: { reportId: $reportID }) {
      nodes {
        productByProductId {
          id
          name
        }
        version
        clusterIdx
        composition
      }
    }
  }
`;
