import * as React from 'react';

import { useQuery } from '@apollo/client';
import AllTexturesQuery from '@graphql/queries/AllTexturesQuery';
import ReportTextureClustersQuery from '@graphql/queries/ReportTextureClusters';
import ReportTexturePreferenceQuery from '@graphql/queries/ReportTexturePreferenceQuery';
import { Box, useTheme } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { hightlightedConditionalRowStyles } from 'components/ReactDataTable/highlightedRowStyles';
import { lineBreak } from 'components/ReactDataTable/reactDataTableUtils';
import { tableCustomStyles } from 'components/ReactDataTable/tableCustomStyles';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary, { ProductVersionPq } from 'hooks/useReportSummary';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import { orderedTextureWords } from './utils';
import { ComponentTitle } from '../../../../styles/themeComponents';
import { ProductVersion } from '../../ProductVersion';
import { getProductName, isProductHighlighted } from '../../utils';
import { getImpactIcon, ImpactType } from '../utils';

interface ReportProps {
  projectId: number;
  reportId: string;
}

export interface TableRow {
  productName: string;
  productId: number;
  version: string;
  abrasive: ImpactType;
  adhesive: ImpactType;
  airy: ImpactType;
  biting: ImpactType;
  bounce: ImpactType;
  brittle: ImpactType;
  bubbly: ImpactType;
  bumpy: ImpactType;
  burning: ImpactType;
  buttery: ImpactType;
  chalky: ImpactType;
  chewy: ImpactType;
  choppy: ImpactType;
  chunky: ImpactType;
  clean: ImpactType;
  chamois: ImpactType;
  coagulated: ImpactType;
  saturated: ImpactType;
  coarse: ImpactType;
  'coating mouth': ImpactType;
  'coating teeth': ImpactType;
  'coating throat': ImpactType;
  coating: ImpactType;
  cohesion: ImpactType;
  cohesive: ImpactType;
  cold: ImpactType;
  congealed: ImpactType;
  cooling: ImpactType;
  crackly: ImpactType;
  creamy: ImpactType;
  crinkly: ImpactType;
  crisp: ImpactType;
  crispy: ImpactType;
  crumbly: ImpactType;
  crunchy: ImpactType;
  crusty: ImpactType;
  crystalline: ImpactType;
  curly: ImpactType;
  dense: ImpactType;
  dirty: ImpactType;
  dry: ImpactType;
  dusty: ImpactType;
  effervescent: ImpactType;
  elastic: ImpactType;
  faceted: ImpactType;
  fatty: ImpactType;
  feathery: ImpactType;
  fibrous: ImpactType;
  'film in mouth': ImpactType;
  filmy: ImpactType;
  firm: ImpactType;
  fizzy: ImpactType;
  flaky: ImpactType;
  fleshy: ImpactType;
  flimsy: ImpactType;
  floss: ImpactType;
  fluffy: ImpactType;
  fluted: ImpactType;
  foamy: ImpactType;
  frilly: ImpactType;
  frothy: ImpactType;
  fudgy: ImpactType;
  fuzzy: ImpactType;
  gauzy: ImpactType;
  gel: ImpactType;
  glazed: ImpactType;
  globular: ImpactType;
  gluey: ImpactType;
  glutinous: ImpactType;
  gooey: ImpactType;
  grainy: ImpactType;
  granular: ImpactType;
  greasy: ImpactType;
  gristly: ImpactType;
  gritty: ImpactType;
  gummy: ImpactType;
  hairy: ImpactType;
  hard: ImpactType;
  'has a skin': ImpactType;
  hearty: ImpactType;
  heat: ImpactType;
  heavy: ImpactType;
  hot: ImpactType;
  itchy: ImpactType;
  jagged: ImpactType;
  jarring: ImpactType;
  jelly: ImpactType;
  juicy: ImpactType;
  lathery: ImpactType;
  leathery: ImpactType;
  light: ImpactType;
  lingering: ImpactType;
  liquidy: ImpactType;
  malleable: ImpactType;
  mealy: ImpactType;
  meaty: ImpactType;
  melts: ImpactType;
  metallic: ImpactType;
  mild: ImpactType;
  moist: ImpactType;
  'mouth watering': ImpactType;
  mushy: ImpactType;
  numbing: ImpactType;
  oily: ImpactType;
  oozing: ImpactType;
  papery: ImpactType;
  particular: ImpactType;
  pasty: ImpactType;
  penetrable: ImpactType;
  porous: ImpactType;
  powdery: ImpactType;
  prickly: ImpactType;
  pucker: ImpactType;
  puffy: ImpactType;
  pulpy: ImpactType;
  pungent: ImpactType;
  resistance: ImpactType;
  rough: ImpactType;
  rounded: ImpactType;
  rubbery: ImpactType;
  sandpaper: ImpactType;
  sandy: ImpactType;
  scaly: ImpactType;
  scratchy: ImpactType;
  seedy: ImpactType;
  sharp: ImpactType;
  silky: ImpactType;
  slick: ImpactType;
  slimy: ImpactType;
  slippery: ImpactType;
  sloppy: ImpactType;
  smooth: ImpactType;
  snappy: ImpactType;
  soft: ImpactType;
  soggy: ImpactType;
  solid: ImpactType;
  spiky: ImpactType;
  spongy: ImpactType;
  spreadable: ImpactType;
  squishy: ImpactType;
  stale: ImpactType;
  sticky: ImpactType;
  stiff: ImpactType;
  stinging: ImpactType;
  stretchy: ImpactType;
  stringy: ImpactType;
  strong: ImpactType;
  structure: ImpactType;
  sudsy: ImpactType;
  'surface wetness': ImpactType;
  syrupy: ImpactType;
  tender: ImpactType;
  thick: ImpactType;
  thin: ImpactType;
  tingly: ImpactType;
  tough: ImpactType;
  uneven: ImpactType;
  uniform: ImpactType;
  viscous: ImpactType;
  warm: ImpactType;
  watery: ImpactType;
  waxy: ImpactType;
  weak: ImpactType;
  wet: ImpactType;
  wilted: ImpactType;
  wiry: ImpactType;
  branching: ImpactType;
  dendritic: ImpactType;
  foil: ImpactType;
  foliated: ImpactType;
  full: ImpactType;
  furry: ImpactType;
  grippy: ImpactType;
  irritation: ImpactType;
  lamellar: ImpactType;
  lattice: ImpactType;
  membrane: ImpactType;
  parching: ImpactType;
  particulate: ImpactType;
  plaster: ImpactType;
  resinous: ImpactType;
  sappy: ImpactType;
  satin: ImpactType;
  soapy: ImpactType;
  suede: ImpactType;
  supple: ImpactType;
  velvet: ImpactType;
  starchy: ImpactType;
  moistening: ImpactType;
  'not throat greasing': ImpactType;
  'throat greasing': ImpactType;
}

const getAILikingDirection = (
  productVersion: ProductVersion,
  reportTextureClusterResponse: reports.ReportTextureClusterResponse,
  reportTexturePreferences: reports.ReportTexturePreferencesResponse,
  key: string,
): ImpactType => {
  const clusterId = (
    reportTextureClusterResponse?.allRpTextureClusters?.nodes ?? []
  ).filter((n) => n.texture.replace(/TX/, '') == key)[0]?.clusterIdx;

  const pqImpactNode = (
    reportTexturePreferences?.allRpTexturePreferences?.nodes ?? []
  ).filter((n) =>
    n.productByProductId.id === productVersion.productId && clusterId
      ? n.clusterIdx === clusterId
      : null,
  );

  const pqImpact = pqImpactNode.length > 0 ? pqImpactNode[0].pqImpact : null;

  switch (true) {
    case pqImpact >= 0.15:
      return ImpactType.POSITIVE;
    case pqImpact < -0.15:
      return ImpactType.NEGATIVE;
    default:
      return ImpactType.NEUTRAL;
  }
};

export const mapToTableRow = (
  productNames,
  reportTextureClusterResponse: reports.ReportTextureClusterResponse,
  reportTexturePreferences: reports.ReportTexturePreferencesResponse,
  productVersionPqs: ProductVersionPq[],
  allTextures: any,
): any[] => {
  return productVersionPqs.map((pn, idx) => {
    const tableRow = {
      productName: getProductName({
        productNames,
        productId: pn.productVersion.productId,
        version: pn.productVersion.version,
      }),
      productId: pn.productVersion.productId,
      version: pn.productVersion.version,
      isHighlighted: isProductHighlighted({
        productNames,
        productId: pn.productVersion.productId,
        version: pn.productVersion.version,
      }),
    };

    const keysTableRows = [
      'abrasive',
      'adhesive',
      'airy',
      'biting',
      'bounce',
      'brittle',
      'bubbly',
      'bumpy',
      'burning',
      'buttery',
      'chalky',
      'chewy',
      'choppy',
      'chunky',
      'clean',
      'chamois',
      'coagulated',
      'saturated',
      'coarse',
      'coating mouth',
      'coating teeth',
      'coating throat',
      'coating',
      'cohesion',
      'cohesive',
      'cold',
      'congealed',
      'cooling',
      'crackly',
      'creamy',
      'crinkly',
      'crisp',
      'crispy',
      'crumbly',
      'crunchy',
      'crusty',
      'crystalline',
      'curly',
      'dense',
      'dirty',
      'dry',
      'dusty',
      'effervescent',
      'elastic',
      'faceted',
      'fatty',
      'feathery',
      'fibrous',
      'film in mouth',
      'filmy',
      'firm',
      'fizzy',
      'flaky',
      'fleshy',
      'flimsy',
      'floss',
      'fluffy',
      'fluted',
      'foamy',
      'frilly',
      'frothy',
      'fudgy',
      'fuzzy',
      'gauzy',
      'gel',
      'glazed',
      'globular',
      'gluey',
      'glutinous',
      'gooey',
      'grainy',
      'granular',
      'greasy',
      'gristly',
      'gritty',
      'gummy',
      'hairy',
      'hard',
      'has a skin',
      'hearty',
      'heat',
      'heavy',
      'hot',
      'itchy',
      'jagged',
      'jarring',
      'jelly',
      'juicy',
      'lathery',
      'leathery',
      'light',
      'lingering',
      'liquidy',
      'malleable',
      'mealy',
      'meaty',
      'melts',
      'metallic',
      'mild',
      'moist',
      'mouth watering',
      'mushy',
      'numbing',
      'oily',
      'oozing',
      'papery',
      'particular',
      'pasty',
      'penetrable',
      'porous',
      'powdery',
      'prickly',
      'pucker',
      'puffy',
      'pulpy',
      'pungent',
      'resistance',
      'rough',
      'rounded',
      'rubbery',
      'sandpaper',
      'sandy',
      'scaly',
      'scratchy',
      'seedy',
      'sharp',
      'silky',
      'slick',
      'slimy',
      'slippery',
      'sloppy',
      'smooth',
      'snappy',
      'soft',
      'soggy',
      'solid',
      'spiky',
      'spongy',
      'spreadable',
      'squishy',
      'stale',
      'sticky',
      'stiff',
      'stinging',
      'stretchy',
      'stringy',
      'strong',
      'structure',
      'sudsy',
      'surface wetness',
      'syrupy',
      'tender',
      'thick',
      'thin',
      'tingly',
      'tough',
      'uneven',
      'uniform',
      'viscous',
      'warm',
      'watery',
      'waxy',
      'weak',
      'wet',
      'wilted',
      'wiry',
      'branching',
      'dendritic',
      'foil',
      'foliated',
      'full',
      'furry',
      'grippy',
      'irritation',
      'lamellar',
      'lattice',
      'membrane',
      'parching',
      'particulate',
      'plaster',
      'resinous',
      'sappy',
      'satin',
      'soapy',
      'suede',
      'supple',
      'velvet',
      'starchy',
      'moistening',
      'not throat greasing',
      'throat greasing',
    ];

    keysTableRows.forEach(
      (field) =>
        (tableRow[field] = getAILikingDirection(
          pn.productVersion,
          reportTextureClusterResponse,
          reportTexturePreferences,
          field,
        )),
    );
    return tableRow;
  });
};

const CategoryInsightsTable: React.FC<ReportProps> = (props) => {
  const { reportId, projectId } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const columns = (
    data: any,
    texturesToShow: reports.AllRpTextureClustersNode[],
  ): TableColumn<TableRow>[] => {
    return [
      {
        id: 'productName',
        name: lineBreak(t('reports.prodSummary.product')),
        cell: (row: TableRow) => (
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            {lineBreak(row.productName)}
          </div>
        ),
        selector: (row: TableRow) => row.productName,
        sortable: true,
      },
    ].concat(
      texturesToShow.map((columnKey) => ({
        id: columnKey['texture'].replace(/TX/, ''),
        width: '9%',
        name: lineBreak([columnKey['texture'].replace(/TX/, '')]),
        selector: (row: TableRow) =>
          row[columnKey['texture'].replace(/TX/, '')],
        cell: (row: TableRow) =>
          getImpactIcon(row[columnKey['texture'].replace(/TX/, '')]),
        center: true,
        compact: true,
        sortable: true,
      })),
    );
  };

  const {
    loading: textureClusterloading,
    error: textureClusterError,
    data: textureClusterData,
  } = useQuery<reports.ReportTextureClusterResponse>(
    ReportTextureClustersQuery,
    {
      variables: {
        reportID: reportId,
        //confidence: 100,
      },
    },
  );

  const {
    loading: reportTexturePreferenceLoading,
    error: reportTexturePreferenceError,
    data: reportTexturePreferences,
  } = useQuery<reports.ReportTexturePreferencesResponse>(
    ReportTexturePreferenceQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: textureLoading,
    error: textureError,
    data: textureData,
  } = useQuery<reports.AllTextureResponse>(AllTexturesQuery);

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    productVersionPqs,
  } = useReportSummary(reportId, {
    fetchPolicy: 'no-cache',
  });

  if (
    reportSummaryLoading ||
    reportSummaryError ||
    textureClusterloading ||
    textureClusterError ||
    reportTexturePreferenceLoading ||
    reportTexturePreferenceError ||
    textureLoading ||
    textureError
  ) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <ComponentTitle>{t('aiLiking.textureTitle')}</ComponentTitle>
      <DataTable
        columns={columns(
          mapToTableRow(
            productNames,
            textureClusterData,
            reportTexturePreferences,
            productVersionPqs,
            textureData.allTextures.edges,
          ),
          orderedTextureWords(textureClusterData).slice(0, 8),
        )}
        data={mapToTableRow(
          productNames,
          textureClusterData,
          reportTexturePreferences,
          productVersionPqs,
          textureData.allTextures.edges,
        )}
        defaultSortAsc={false}
        customStyles={tableCustomStyles(theme)}
        striped
        conditionalRowStyles={hightlightedConditionalRowStyles}
      />
    </Box>
  );
};

export default CategoryInsightsTable;
