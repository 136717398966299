import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  data: reports.ArchetypeDemographicsResults;
}

const ReportArchetypeDemographics: React.FC<Props> = (props) => {
  const { data } = props;

  const { t } = useTranslation();

  if (!data || data.allRpArchetypesDemographics.nodes.length === 0) {
    return <div />;
  }

  return (
    <div style={{ paddingTop: 24 }}>
      <Typography variant="h5" align="center">
        {t('reports.archtypeDemographics.title')}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('reports.archtypeDemographics.ageRange')}</TableCell>
            <TableCell>{t('reports.archtypeDemographics.male')}</TableCell>
            <TableCell>{t('reports.archtypeDemographics.female')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.allRpArchetypesDemographics.nodes
            .sort((a, b) => (a.ageRangeStart > b.ageRangeStart ? 1 : -1))
            .map((n) => (
              <TableRow>
                <TableCell>
                  {n.ageRangeStart} - {n.ageRangeEnd}
                </TableCell>
                <TableCell>
                  {Math.floor(n.maleFraction * n.ageFraction * 100).toFixed(0) +
                    '%'}
                </TableCell>
                <TableCell>
                  {Math.floor(n.femaleFraction * n.ageFraction * 100).toFixed(
                    0,
                  ) + '%'}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ReportArchetypeDemographics;
