import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { countBy, values } from 'lodash';
import { intensities } from 'services/tuningPlotService';

import Bar from './Bar';
import {
  TopTriangle,
  BottomTriangle,
  LeftTriangle,
  RightTriangle,
} from './Triangles';

interface Props {
  extremaData: reports.ExtremaData[];
}

const colors: string[] = [
  '#C35D5D',
  '#FA7E79',
  '#FAA29A',
  '#C5E0B4',
  '#548235',
  '#385723',
];

const PqIntensityDiagram: React.FC<Props> = (props: Props) => {
  const { extremaData } = props;

  const pqDiagramDiv = useRef(null);

  const [divWidth, setDivWidth] = useState<number>(0);

  const updateDimensions = () => {
    if (pqDiagramDiv.current) setDivWidth(pqDiagramDiv.current.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    setDivWidth(pqDiagramDiv.current.clientWidth);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  if (!extremaData) {
    return <div></div>;
  }

  const maxCount = values(countBy(extremaData, 'maxCategory')).reduce(
    (p, c) => (p > c ? p : c),
    0,
  );
  const minCount = values(countBy(extremaData, 'minCategory')).reduce(
    (p, c) => (p > c ? p : c),
    0,
  );

  return (
    <div ref={pqDiagramDiv}>
      <div
        id="verticalDisplay"
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <TopTriangle divWidth={divWidth} />
        <div
          id="horizontalDisplay"
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            //minHeight: 150,
          }}
        >
          <LeftTriangle divWidth={divWidth} />
          <div
            id="centerBoxes"
            style={{
              display: 'flex',
              flex: 2,
              width: '100%',
              height: `${divWidth / 2 - 1}`,
            }}
          >
            {intensities
              .filter((i) => i.label != '=')
              .map((intensity: reports.Intensity, index: number) => (
                <Bar
                  key={index}
                  mostItems={Math.max(maxCount, minCount)}
                  divWidth={divWidth}
                  color={colors[index]}
                  intensity={intensity}
                  positiveList={extremaData
                    .filter((ed) => ed.maxCategory === intensity.label)
                    .sort((a, b) => b.maxExtrema - a.maxExtrema)}
                  negativeList={extremaData
                    .filter((ed) => ed.minCategory === intensity.label)
                    .sort((a, b) => a.minExtrema - b.minExtrema)}
                />
              ))}
          </div>
          <RightTriangle divWidth={divWidth} />
        </div>
        <BottomTriangle divWidth={divWidth} />
      </div>
    </div>
  );
};

export default PqIntensityDiagram;
