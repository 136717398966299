import * as React from 'react';

import { Grid, Divider } from '@mui/material';
import GGVarFlavorInsights from 'components/Report/CategoryInsights/GGVarFlavorInsights';
import ImpactDirectionByGgVar from 'components/Report/CategoryInsights/ImpactDirectionByGgVar';
import ImpactDirectionByTexture from 'components/Report/CategoryInsights/ImpactDirectionByTexture';
import ImpactDirectionLegend from 'components/Report/CategoryInsights/ImpactDirectionLegend';
import ProductCategoryNorms from 'components/Report/CategoryInsights/ProductCategoryNorms';
import ProductFlavorSummary from 'components/Report/CategoryInsights/ProductFlavorSummary';

interface Props {
  projectId: number;
  reportId: string;
}

const CategoryInsightsTab: React.FC<Props> = (props) => {
  const { projectId, reportId } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ImpactDirectionLegend />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ImpactDirectionByGgVar projectId={projectId} reportId={reportId} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ImpactDirectionByTexture projectId={projectId} reportId={reportId} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <GGVarFlavorInsights projectId={projectId} reportId={reportId} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ProductCategoryNorms reportId={reportId} projectId={projectId} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ProductFlavorSummary projectId={projectId} reportId={reportId} />
      </Grid>
    </Grid>
  );
};

export default CategoryInsightsTab;
