import React, { useState } from 'react';

import { useTheme } from '@mui/material';
import { WithOutContext as ReactTags } from 'react-tag-input';

import Tag from './Tag';
import useStyles from './useStyles';
import MaterialButton from '../MaterialButton';

function FormInputTag(props) {
  const [disableTagInput, setDisableTagInput] = useState(false);

  const toggleDisableTagInput = () => setDisableTagInput(disableTagInput);

  const handleFilterSuggestions = (textInputValue, possibleSuggestionArray) =>
    possibleSuggestionArray.filter((value) =>
      value.label.toLowerCase().includes(textInputValue.toLowerCase()),
    );

  const handleDelete = (i) => {
    const { onChange, value } = props;
    onChange(value.filter((_, index) => index !== i));
  };

  const handleAddition = (tag) => {
    const { defaultTags, onChange, value, suggestions, disableCustom, single } =
      props;
    const tags = props.value;
    const tagsToRender = defaultTags ? defaultTags : tags;
    const suggestion = suggestions.find(
      (suggestion) =>
        suggestion.label.toLowerCase() === tag.label.toLowerCase(),
    );

    if ((!suggestion && disableCustom) || (single && tagsToRender.length > 0))
      return;

    onChange([...value, tag]);
    toggleDisableTagInput();
  };

  const { defaultTags, uneditable, single, ...rest } = props;

  const tags = props.value ? props.value : [];
  const tagsToRender = defaultTags ? defaultTags : tags;
  const classes = useStyles(useTheme());

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {uneditable || !disableTagInput ? (
        <ReactTags
          tags={tagsToRender}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleFilterSuggestions={handleFilterSuggestions}
          allowDragDrop={false}
          autofocus={false}
          inputFieldPosition="inline"
          labelField="label"
          readOnly={disableTagInput}
          minQueryLength={1}
          classNames={{
            tags: classes.tagsClass,
            tagInput: classes.tagInputClass,
            tagInputField: classes.tagInputFieldClass,
            tag: classes.tagClass,
            remove: classes.removeClass,
            suggestions: classes.suggestionsClass,
            activeSuggestion: classes.activeSuggestionClass,
          }}
          {...rest}
        />
      ) : (
        tagsToRender.map((e, index) => (
          <div key={`${e.label}_${index}`}>
            <Tag
              readOnly={false}
              label={e.label}
              onDelete={() => handleDelete(index)}
            />
          </div>
        ))
      )}
      {uneditable
        ? null
        : disableTagInput &&
          (!single || tagsToRender.length < 1) && (
            <MaterialButton onClick={toggleDisableTagInput} variant="outlined">
              Add More
            </MaterialButton>
          )}
    </div>
  );
}

export default FormInputTag;
