import * as React from 'react';

import { CircularProgress, useTheme } from '@mui/material';

import useStyles from './useStyles';

const ActivityIndicator: React.FC = () => {
  const classes = useStyles(useTheme());

  return (
    <div className={classes.activityIndicator}>
      <CircularProgress color="primary" size={5} />
    </div>
  );
};

export default ActivityIndicator;
