import { ReferenceFlavorLanguage } from '@graphql/queries/GetReferenceFlavorLanguage';

import { TranslationType } from './util';

const CsvFields = (
  referenceFlavors: TranslationType[],
  languages: ReferenceFlavorLanguage[],
) => {
  const localLanguages = languages.map((lang) => lang.code);
  const list = referenceFlavors.map((l) =>
    localLanguages.reduce((acc, cur) => {
      const lang = languages.find((f) => f.code == cur);
      acc[lang.name] = l[cur];
      return acc;
    }, {}),
  );
  return list;
};

export { CsvFields };
