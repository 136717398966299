import { gql } from '@apollo/client';

export interface ReportNoveltyPairedPreferenceComparisonResponse
  extends reports.ReportGraphQLReponse {
  allRpNoveltyPairedPreferences: {
    nodes: ReportNoveltyPairedPreferenceComparison[];
  };
}

export interface ReportNoveltyPairedPreferenceComparison {
  productByProductId1: {
    id: number;
    name: string;
  };
  productByProductId2: {
    id: number;
    name: string;
  };
  version1: string;
  version2: string;
  percentPreference: number;
}

export default gql`
  query NoveltyPairedPreferencesByReportId($reportID: UUID!) {
    allRpNoveltyPairedPreferences(condition: { reportId: $reportID }) {
      nodes {
        productByProductId1 {
          id
          name
        }
        productByProductId2 {
          id
          name
        }
        version1
        version2
        percentPreference
      }
    }
  }
`;
