import { Continent, CountryMetadata } from 'constants/country';

import { Optional } from 'typescript-optional';

const AVAILABLE_BLUE = '#B5D3E7';
const UNAVAILABLE_GREY = 'grey';

/**
 * Return if the passed in country is available.
 *
 * @param availableCountries set of all available countries
 * @param country country to search for
 * @returns if country is available
 */
export const availableCountry = (
  availableCountries: Set<CountryMetadata>,
  country: GeoJSON.Feature,
): boolean => {
  return (
    Array.from(availableCountries).find(
      (ac) => ac.geojsonId === country?.id,
    ) !== undefined
  );
};

/**
 * Get all the related continents in the available map data
 *
 * @param availableCountries set of all available countries
 * @param mapData map data from the project
 * @returns list of continents covered by the mapData
 */
export const extractContinents = (
  availableCountries: Set<CountryMetadata>,
  mapData: reports.MapDataRow[],
): Continent[] => {
  return Array.from(availableCountries)
    .filter(
      (ac) =>
        mapData
          .map((md) => md.cleanCountryName)
          .indexOf(ac.dbname.toLowerCase()) > -1,
    )
    .map((c) => c.continent)
    .reduce(
      (acc, curr) => (acc.indexOf(curr) > -1 ? acc : acc.concat(curr)),
      [],
    );
};

/**
 * Figure out the country color based on availablity
 *
 * @param availableCountries set of all available countries
 * @param d country to search for
 * @returns fill color
 */
export const countryColor = (
  availableCountries: Set<CountryMetadata>,
  d: GeoJSON.Feature,
): string => {
  if (availableCountry(availableCountries, d)) {
    return AVAILABLE_BLUE;
  } else {
    return UNAVAILABLE_GREY;
  }
};

/**
 * Standardize the country name from report job information
 *
 * @param paramsJson params JSON object
 * @param targetGroupName target group name
 * @returns repaired country name
 */
export const cleanCountryNames = (
  paramsJson: object,
  targetGroupName: string,
): string => {
  const cleanTargetGroupName = targetGroupName
    .replace(/Sans Rejectors/, '')
    .trim();

  // If not params object available
  if (!paramsJson) return cleanTargetGroupName;

  const countries = paramsJson['countries'];

  // If it doesn't parse right
  if (!countries) return cleanTargetGroupName;

  // Handle string or array of strings
  return (typeof countries === 'string' ? Array(countries) : countries)[0];
};

/**
 * Check to see if the row data matches the geojson info for a given feature
 *
 * @param availableCountries
 * @param row
 * @param d
 * @returns
 */
export const matchCountry = (
  availableCountries: Set<CountryMetadata>,
  row: reports.MapDataRow,
  d: GeoJSON.Feature,
): boolean => {
  return (
    Array.from(availableCountries).find(
      (ac) => ac.dbname === row.cleanCountryName,
    )?.geojsonId === d?.id
  );
};

/**
 *
 * @param availableCountry available country for L&S to check if report already run
 * @param reportData all the already run report data
 * @returns if the availableCountry is NOT included in the reportData
 */
export const missingAvailableCountry = (
  availableCountry: CountryMetadata,
  reportData: reports.MapDataRow[],
): boolean => {
  return !reportData
    .map((data) => data.cleanCountryName)
    .includes(availableCountry.dbname);
};

/**
 *
 * @param availableCountries
 * @param mapCountries
 * @param d
 * @param colors
 * @param meanPq
 * @returns
 */
export const countryPqColor = (
  availableCountries: Set<CountryMetadata>,
  mapCountries: reports.MapDataRow[],
  d: GeoJSON.Feature,
  colors: d3.ScaleLinear<string, string, never>,
  meanPq: number,
): string => {
  if (!availableCountry(availableCountries, d)) {
    return `url(#${HASH_PATTERN_ID})`;
  }
  const countryRow = mapCountries.find((row) =>
    matchCountry(availableCountries, row, d),
  );
  if (countryRow) {
    return colors(+countryRow.pq - meanPq);
  } else {
    return AVAILABLE_BLUE;
  }
};

export const HASH_PATTERN_ID = 'hashPattern';

/**
 * Create a standard svg hash pattern to be used on unavailable countries
 *
 * @param svg parent svg object
 * @param size line size
 * @param color line color
 * @returns altered svg object
 */
export const appendHashPattern = (
  svg: d3.Selection<any, unknown, null, undefined>,
  size: number,
  color: string,
): d3.Selection<any, unknown, null, undefined> => {
  return svg
    .append('defs')
    .append('pattern')
    .attr('id', HASH_PATTERN_ID)
    .attr('patternUnits', 'userSpaceOnUse')
    .attr('width', size)
    .attr('height', size)
    .attr('patternTransform', 'rotate(45 0 0)')
    .attr('patternUnits', 'userSpaceOnUse')
    .append('line')
    .attr('x1', '0')
    .attr('y1', '0')
    .attr('x2', '0')
    .attr('y2', size)
    .attr('style', `stroke:${color}; stroke-width:1`);
};

/**
 *
 * @param availableCountries
 * @param mapCountries
 * @param d
 * @returns
 */
export const countryHoverText = (
  availableCountries: Set<CountryMetadata>,
  mapCountries: reports.MapDataRow[],
  d: GeoJSON.Feature,
): string =>
  Optional.ofNullable(
    mapCountries.find((row) => matchCountry(availableCountries, row, d)),
  )
    .map((countryRow) => `${d.properties.name}: ${+countryRow.pq}`)
    .orElseGet(() => d.properties.name);
