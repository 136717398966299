import { gql } from '@apollo/client';

export default gql`
  query ReportStackRankComparisonQuery($reportID: UUID!) {
    allRpFlavorDecomps(condition: { reportId: $reportID }) {
      nodes {
        id
        reportId
        productByProductId {
          id
          name
        }
        ggVars
        referenceFlavor
        decomp
        absDecomp
        version
      }
    }
  }
`;
