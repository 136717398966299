import { useState, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import selectViewerUserId from '../selectors/viewerUserId';
import { COLORS } from '../styles/theme';

interface PartnerByPartnerId {
  partnerByPartnerId: {
    name: string;
    partnerBrandingByPartnerId: {
      branding: GGBranding;
    };
  };
}

interface ProducerPartnerBrandingResults {
  producerById: {
    producerPartnerByProducerId: PartnerByPartnerId;
  };
}

const brandingQuery = gql`
  query UserPartnerQuery($producerId: Int!) {
    producerById(id: $producerId) {
      name
      producerPartnerByProducerId {
        partnerByPartnerId {
          id
          name
          partnerBrandingByPartnerId {
            branding
          }
        }
      }
    }
  }
`;

export interface GGBranding {
  banner_base_color: string;
  action_button_color: string;
  banner_logo_src: string;

  [x: string | number | symbol]: unknown;
}

export const defaultBranding: GGBranding = {
  banner_base_color: COLORS.MARINE,
  action_button_color: COLORS.AQUA_MARINE,
  banner_logo_src: null,
};

const usePartnerBranding = (): GGBranding => {
  const [partnerBranding, setPartnerBranding] = useState<GGBranding>(null);

  const viewerUserId = useSelector((state) => selectViewerUserId(state));
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));

  const { data, loading, error } = useQuery<ProducerPartnerBrandingResults>(
    brandingQuery,
    {
      variables: { producerId },
      skip: !viewerUserId || !producerId,
    },
  );

  useEffect(() => {
    if (!loading && !error && data) {
      const branding =
        data.producerById?.producerPartnerByProducerId?.partnerByPartnerId
          ?.partnerBrandingByPartnerId?.branding;
      if (branding) {
        setPartnerBranding(branding);
      } else {
        setPartnerBranding(defaultBranding);
      }
    }
  }, [data, loading, error]);

  return partnerBranding;
};

export default usePartnerBranding;
