import * as React from 'react';
import { useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import dataTableCsv from 'components/ReactDataTable/ReactDataTableCsv';
import { lineBreak } from 'components/ReactDataTable/reactDataTableUtils';
import { tableCustomStyles } from 'components/ReactDataTable/tableCustomStyles';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { concat, isEmpty } from 'lodash';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFormValues } from 'redux-form';

import styles from './ProductFilter.module.css';
import TagModal from './TagModal';
import useStyles from './useStyles';
import MaterialButton from '../../components/MaterialButton';
import { PRODUCT_FILTER_FORM } from '../../constants/formNames';
import { PRODUCT } from '../../constants/routePaths';
import formatPath from '../../utils/formatPath';
import { labelObjectsToValue } from '../../utils/sagaHelper';

const DATE_FORMAT = 'yyyy-MM-dd';

const FilteredProductsQuery = gql`
  query FilteredProductsQuery(
    $orderBy: [ProductsOrderBy!]
    $condition: ProductCondition
    $after: Cursor
    $before: Cursor
    $first: Int
    $last: Int
    $offset: Int
    $filter: ProductFilter
  ) {
    products: allProducts(
      orderBy: $orderBy
      condition: $condition
      first: $first
      last: $last
      after: $after
      before: $before
      offset: $offset
      filter: $filter
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      nodes {
        id
        name
        brand
        createdAt
        producerId

        features: productFeatureProductsByProductId {
          nodes {
            id
            feature: productFeatureByProductFeatureId {
              id
              name
            }
          }
        }

        componentBases: productComponentBaseProductsByProductId {
          nodes {
            id
            componentBase: productComponentBaseByProductComponentBaseId {
              id
              name
            }
          }
        }

        componentOthers: productComponentOtherProductsByProductId {
          nodes {
            id
            componentOther: productComponentOtherByProductComponentOtherId {
              id
              name
            }
          }
        }

        productCategory: productCategoryByCategoryId {
          id
          name
        }

        producer: producerByProducerId {
          id
          name
        }

        productReviews: productReviewsByProductId {
          totalCount
        }
      }
    }
  }
`;

const getIncludedValues = (values) => {
  const filtered = values.filter((v) => !v.out);
  return filtered.length > 0 ? filtered : undefined;
};
const getExcludedValues = (values) => {
  const filtered = values.filter((v) => !!v.out);
  return filtered.length > 0 ? filtered : undefined;
};

const productNameFormatter = (cell, row) => (
  <Link
    key={row.id}
    className={`restore-${row.id}`}
    to={{
      pathname: formatPath(PRODUCT, { productId: row.id }),
      state: row.id,
    }}
  >
    {cell}
  </Link>
);

interface TableRow {
  id: number;
  name: string;
  brand: string;
  date: string;
  workspace: string;
  category: string;
  feature: string;
  componentBase: string;
  componentOther: string;
  reviews: string;
  checkbox: string;
}

interface Props {
  change: any;
  savedProducts: any;
}

// eslint-disable-next-line complexity
export const ProductResult: React.FC<Props> = ({ change, savedProducts }) => {
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [orderField, setOrderField] = useState('id');
  const [orderDir, setOrderDir] = useState('desc');
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const classes = useStyles({});
  const { t } = useTranslation();

  const values = useSelector((state) =>
    getFormValues(PRODUCT_FILTER_FORM)(state),
  );

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: 'Product ID',
      sortable: true,
      omit: true,
    },
    {
      id: 'name',
      selector: (row: TableRow) => row.name,
      name: lineBreak(t('product.productName')),
      sortable: true,
      cell: (row: TableRow) => productNameFormatter(row.name, row),
    },
    {
      id: 'brand',
      selector: (row: TableRow) => row.brand,
      name: lineBreak(t('product.productBrand')),
      sortable: true,
      wrap: true,
    },
    {
      id: 'date',
      selector: (row: TableRow) => row.date,
      name: lineBreak(t('general.dateCreated')),
      sortable: true,
      wrap: true,
    },
    {
      id: 'workspace',
      selector: (row: TableRow) => row.workspace,
      name: lineBreak(t('general.workspace')),
      sortable: true,
      wrap: true,
    },
    {
      id: 'category',
      selector: (row: TableRow) => row.category,
      name: lineBreak(t('product.productCategory')),
      sortable: true,
      wrap: true,
    },
    {
      id: 'feature',
      selector: (row: TableRow) => row.feature,
      name: lineBreak(t('product.productFeature')),
      sortable: true,
      wrap: true,
    },
    {
      id: 'componentBase',
      selector: (row: TableRow) => row.componentBase,
      name: lineBreak(t('product.productComponentBase')),
      sortable: true,
      wrap: true,
    },
    {
      id: 'componentOther',
      selector: (row: TableRow) => row.componentOther,
      name: lineBreak(t('product.productComponentOther')),
      sortable: true,
      wrap: true,
    },
    {
      id: 'reviews',
      selector: (row: TableRow) => row.reviews,
      name: t('reviews.reviews'),
      sortable: true,
      wrap: true,
    },
    {
      id: 'checkbox',
      selector: (row: TableRow) => row.checkbox,
      name: '',
    },
  ];

  const actionColumns = columns.map((column: TableRow) => {
    const { checkbox, ...rest } = column;
    return rest;
  });

  const filter = savedProducts
    ? {
        id: {
          in: savedProducts,
        },
      }
    : {
        ingredients: values.ingredients && {
          like: `%${values.ingredients}%`,
        },
        country:
          values.country && values.country.length > 0
            ? {
                in: labelObjectsToValue(getIncludedValues(values.country)),
                notIn: labelObjectsToValue(getExcludedValues(values.country)),
              }
            : undefined,
        countryOfPurchase:
          values.countryOfPurchase && values.countryOfPurchase.length > 0
            ? {
                in: labelObjectsToValue(
                  getIncludedValues(values.countryOfPurchase),
                ),
                notIn: labelObjectsToValue(
                  getExcludedValues(values.countryOfPurchase),
                ),
              }
            : undefined,
        producerId:
          values.workspaces && values.workspaces.length > 0
            ? {
                in: labelObjectsToValue(getIncludedValues(values.workspaces)),
                notIn: labelObjectsToValue(
                  getExcludedValues(values.workspaces),
                ),
              }
            : undefined,
        physicalState:
          values.physicalState && values.physicalState.length > 0
            ? {
                in: labelObjectsToValue(
                  getIncludedValues(values.physicalState),
                ),
                notIn: labelObjectsToValue(
                  getExcludedValues(values.physicalState),
                ),
              }
            : undefined,
        productCategoryByCategoryId:
          values.categories && values.categories.length > 0
            ? {
                name: {
                  in: labelObjectsToValue(getIncludedValues(values.categories)),
                  notIn: labelObjectsToValue(
                    getExcludedValues(values.categories),
                  ),
                },
              }
            : undefined,
        productFeatureProductsByProductId:
          values.features && values.features.length > 0
            ? {
                some: getIncludedValues(values.features)
                  ? {
                      productFeatureByProductFeatureId: {
                        name: {
                          in: labelObjectsToValue(
                            getIncludedValues(values.features),
                          ),
                        },
                      },
                    }
                  : undefined,
                every: getExcludedValues(values.features)
                  ? {
                      productFeatureByProductFeatureId: {
                        name: {
                          notIn: labelObjectsToValue(
                            getExcludedValues(values.features),
                          ),
                        },
                      },
                    }
                  : undefined,
              }
            : undefined,
        productComponentBaseProductsByProductId:
          values.componentBases && values.componentBases.length > 0
            ? {
                some: getIncludedValues(values.componentBases)
                  ? {
                      productComponentBaseByProductComponentBaseId: {
                        name: {
                          in: labelObjectsToValue(
                            getIncludedValues(values.componentBases),
                          ),
                        },
                      },
                    }
                  : undefined,
                every: getExcludedValues(values.componentBases)
                  ? {
                      productComponentBaseByProductComponentBaseId: {
                        name: {
                          notIn: labelObjectsToValue(
                            getExcludedValues(values.componentBases),
                          ),
                        },
                      },
                    }
                  : undefined,
              }
            : undefined,
        productComponentOtherProductsByProductId:
          values.componentOthers && values.componentOthers.length > 0
            ? {
                some: getIncludedValues(values.componentOthers)
                  ? {
                      productComponentOtherByProductComponentOtherId: {
                        name: {
                          in: labelObjectsToValue(
                            getIncludedValues(values.componentOthers),
                          ),
                        },
                      },
                    }
                  : undefined,
                every: getExcludedValues(values.componentOthers)
                  ? {
                      productComponentOtherByProductComponentOtherId: {
                        name: {
                          notIn: labelObjectsToValue(
                            getExcludedValues(values.componentOthers),
                          ),
                        },
                      },
                    }
                  : undefined,
              }
            : undefined,
      };

  // Clear undefined objects
  Object.keys(filter).forEach((key) =>
    filter[key] === undefined ? delete filter[key] : {},
  );

  const {
    loading,
    data: productResults,
    fetchMore,
  } = useQuery(FilteredProductsQuery, {
    variables: {
      offset: 0,
      first: sizePerPage,
      filter: !isEmpty(filter) ? filter : undefined,
      orderBy: 'ID_DESC',
    },
  });

  const reshapeData = (data) =>
    data &&
    data.products &&
    data.products.nodes.map((node) => ({
      id: node.id,
      name: node.name,
      brand: node.brand,
      date: formatDate(parseISO(node.createdAt), DATE_FORMAT),
      workspace: node.producer && node.producer.name,
      category: node.productCategory && node.productCategory.name,
      feature:
        node.features &&
        node.features.nodes.map((f) => f.feature.name).join(', '),
      componentBase:
        node.componentBases &&
        node.componentBases.nodes.map((cb) => cb.componentBase.name).join(', '),
      componentOther:
        node.componentOthers &&
        node.componentOthers.nodes
          .map((co) => co.componentOther.name)
          .join(', '),
      reviews: node.productReviews.totalCount,
      checkbox: (
        <Checkbox
          color="secondary"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          onChange={(event) => {
            const target = event.target as HTMLInputElement;
            if (target.checked) {
              change('selectedProducts', concat(values.selectedProducts, node));
            } else {
              change(
                'selectedProducts',
                values.selectedProducts.filter((val) => val.id != node.id),
              );
            }
          }}
          checked={!!values.selectedProducts.find((val) => val.id == node.id)}
        />
      ),
    }));

  function handlePerRowsChange(
    currentRowsPerPage: number,
    currentPage: number,
  ): void {
    setSizePerPage(currentRowsPerPage);
    fetchMore({
      variables: {
        offset: (currentPage - 1) * sizePerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          products: {
            nodes: [...prev.products.nodes, ...fetchMoreResult.products.nodes],
          },
          ...fetchMoreResult,
        });
      },
    });
  }

  function handlePageChange(page: number, totalRows: number): void {
    setPage(page);
    fetchMore({
      variables: {
        offset: (page - 1) * sizePerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          products: {
            nodes: [...prev.products.nodes, ...fetchMoreResult.products.nodes],
          },
          ...fetchMoreResult,
        });
      },
    });
  }

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div className={classes.container}>
      <TagModal
        products={productResults.products}
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <div className={styles.resultHeader}>
        <MaterialButton teal soft onClick={() => setOpenModal(true)}>
          {t('admin.updateProductMetadata')}
        </MaterialButton>
        <div>
          {t('product.productsFound', {
            count: productResults.products.totalCount,
          })}
        </div>
      </div>
      <div>
        <DataTable
          columns={columns}
          data={reshapeData(productResults)}
          fixedHeader
          striped
          pagination
          paginationIconFirstPage={<RDTFirstPage />}
          paginationIconLastPage={<RDTLastPage />}
          paginationIconPrevious={<RDTPrevPage />}
          paginationIconNext={<RTDNextPage />}
          paginationServer
          paginationTotalRows={productResults.products.totalCount}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          customStyles={tableCustomStyles(theme)}
          actions={dataTableCsv(
            'Product_Result.csv',
            actionColumns,
            reshapeData(productResults).map((row) => {
              const { checkbox, ...rest } = row;
              return rest;
            }),
          )}
        />
      </div>
    </div>
  );
};

export default ProductResult;
