import { gql } from '@apollo/client';

export default gql`
  query ReportMarketPreferencesQuery(
    $reportID: UUID!
    $productID: Int
    $version: String
  ) {
    allRpMarketPreferences(
      condition: {
        reportId: $reportID
        productId: $productID
        version: $version
      }
    ) {
      nodes {
        product: productByProductId {
          id
          name
        }
        version
        pqRating
        percentPop
      }
    }
  }
`;
