import { gql } from '@apollo/client';

export default gql`
  query AllTexturesQuery {
    allTextures {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
