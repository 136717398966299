import { reduxForm } from 'redux-form';

import CreateCategory from './CreateCategory';
import formSubmit from '../../actions/formSubmit';
import { CREATE_CATEGORY_FORM } from '../../constants/formNames';

const validation = (val) =>
  !val || (typeof val === 'object' && val.length === 0);

export default reduxForm({
  form: CREATE_CATEGORY_FORM,
  onSubmit: (values, dispatch) => {
    dispatch(formSubmit(CREATE_CATEGORY_FORM));
  },
  validate: (values) => {
    return {
      name: validation(values.name),
    };
  },
})(CreateCategory);
