import { gql } from '@apollo/client';

export default gql`
    query preferencesQuery($userId: Int!, $producerId: Int!) {
        userPreferenceByUserId(userId: $userId) {
        userId
        preference
        }
        producerPartnerByProducerId(producerId: $producerId) {
        partnerByPartnerId {
            partnerPreferenceByPartnerId {
            partnerId
            preference
            }
        }
        }
        producerPreferenceByProducerId(producerId: $producerId) {
        producerId
        preference
        }
    }
`