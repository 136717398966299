import * as React from 'react';

import Grid from '@mui/material/Grid';
import GgVarFlavorIntensityPqChart from 'components/Report/FlavorIntensityCharts/GgVarFlavorIntensityPqChart';
import TextureIntensityPqChart from 'components/Report/Texture/TextureIntensityCharts/TextureIntensityPqChart';
import { useTranslation } from 'react-i18next';

interface Props {
  ggVar: constants.GGVar;
  ggVarTuningData: reports.GgVarTuningPlotRow[];
  decompData: reports.ReportTextureDecompRow[];
  pq: number;
  minPq: number;
  maxPq: number;
}

const TextureSummaryChartGroup: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { ggVarTuningData, pq, minPq, maxPq, ggVar, decompData } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <h5 style={{ textAlign: 'center', paddingTop: '2em' }}>
          <span>{t('projects.textureFlavorSummary.chartTitle')}</span>
        </h5>
      </Grid>
      {decompData && decompData.length > 0 && (
        <Grid container item xs={12}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <TextureIntensityPqChart
              decompData={decompData}
              pq={pq}
              minPq={minPq}
              maxPq={maxPq}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      )}
      {ggVarTuningData && ggVarTuningData.length > 0 && (
        <Grid container item xs={12}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <GgVarFlavorIntensityPqChart
              ggVarTuningData={ggVarTuningData}
              ggVar={ggVar}
              minPq={minPq}
              maxPq={maxPq}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TextureSummaryChartGroup;
