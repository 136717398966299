import * as React from 'react';
import { ReactElement } from 'react';

import { StyleSheet, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  tableContainer: {
    //borderCollapse: "collapse",
    //width: "100%"
  },
});

interface Props {
  items?: object[];
  renderBodyRow?: (item: object) => ReactElement;
  renderHeaderRow?: () => ReactElement;
  children?: ReactElement[];
}

const PdfTable: React.FunctionComponent<Props> = ({
  items,
  renderBodyRow,
  renderHeaderRow,
  children,
}) => (
  <View style={styles.tableContainer}>
    {renderHeaderRow && <View>{renderHeaderRow()}</View>}
    {renderBodyRow && <View>{items.map(renderBodyRow)}</View>}
    {children && children}
  </View>
);

PdfTable.defaultProps = {
  renderHeaderRow: () => null,
};
PdfTable.displayName = 'PdfTable';

export default PdfTable;
