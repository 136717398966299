import * as React from 'react';
import { useState, useEffect } from 'react';

import { CloseOutlined, KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import MaterialButton from 'components/MaterialButton';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import AutosizeInput from 'react-input-autosize';
import { Field, registerField, change } from 'redux-form';

import styles from './ProductFolderContainer.module.css';
import { ADD_FOLDER_FORM, EDIT_FOLDER_FORM } from '../../constants/formNames';
import { folderAddSvgIcon, folderSvg } from '../../constants/images';
import FormContainer from '../Form';
import './react-contextmenu.css';

const folderNameInput = (props) => {
  const { value, name, onChange } = props.input;
  return (
    <AutosizeInput
      name={name}
      value={value || props.initialValue || ''}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      inputStyle={{
        border: '1px solid rgb(156, 156, 156)',
        borderRadius: '5px',
        padding: '3px',
        paddingTop: '0px',
        paddingBottom: '0px',
        margin: '0px',
        marginRight: '10px',
        fontSize: '13px',
      }}
      placeholder={props.placeholder}
    />
  );
};

// eslint-disable-next-line complexity
const FolderSystem = (props) => {
  const {
    folderTree,
    id,
    setFolderId,
    openFolder,
    dragProps,
    dispatch,
    deleteFolder,
    products,
    expandId,
    setExpandId,
    field,
    parentId,
  } = props;
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<any[]>([]);
  const [adding, setAdding] = useState<boolean>(props.id === -1);
  const [children, setChildren] = useState<any[]>([]);

  useEffect(() => {
    if (id !== -1 && folderTree.get(id) && folderTree.get(id).children)
      setChildren(folderTree.get(id).children);
  }, [folderTree]);

  const onDragStart = (e) => {
    e.persist();
    e.dataTransfer.setData('text/html', e.target);
    e.dataTransfer.setDragImage(e.target, 20, 20);
    dragProps.setDraggedId(id);
  };

  const onDragOver = () => {
    dragProps.setDraggedOverId(id);
  };

  const onDragEnd = () => {
    const { draggedId, draggedOverId, moveFolder } = dragProps;

    // Makes sure that user isn't trying to put an ancestor into a descendant
    let current = draggedOverId;
    let update = true;
    while (current !== 0) {
      if (current === draggedId) {
        update = false;
        break;
      }
      current = folderTree.get(current).parentId;
    }

    if (update) {
      moveFolder({
        draggedId,
        draggedOverId,
      });
    }
    dragProps.setDraggedId(null);
    dragProps.setDraggedOverId(null);
  };

  const handleClear = () => {
    setCollapsed(children && children.length < 1 ? !collapsed : collapsed);
    setChildren([...children].filter((child) => child !== -1));
  };

  const newFolderSubmit = (e, handleSubmit) => {
    handleClear();
    dispatch(registerField(ADD_FOLDER_FORM, 'parentId', 'Field'));
    dispatch(change(ADD_FOLDER_FORM, 'parentId', parentId));
    handleSubmit(e);
  };

  const editFolderSubmit = (e, handleSubmit) => {
    setEditing(false);
    dispatch(registerField(EDIT_FOLDER_FORM, 'id', 'Field'));
    dispatch(change(EDIT_FOLDER_FORM, 'id', id));
    handleSubmit(e);
  };

  if (id === expandId) {
    setCollapsed(false);
    setExpandId(null);
  }

  if (!expanded) {
    const localExpanded = [];
    let pointer = openFolder;
    while (pointer !== 0) {
      pointer = folderTree.get(pointer).parentId;
      localExpanded.push(pointer);
    }
    setExpanded(localExpanded);
  }

  if (collapsed && expanded.indexOf(id) > -1) {
    setCollapsed(false);
  }

  return folderTree ? (
    (id === -1 && adding) || editing ? (
      <FormContainer
        formName={editing ? EDIT_FOLDER_FORM : ADD_FOLDER_FORM}
        render={({ handleSubmit, invalid }) => (
          <form className={styles.horizontal} onSubmit={handleSubmit}>
            <img
              src={folderSvg}
              style={{ height: 15, marginRight: 9 }}
              alt="folder-img"
            />
            <Field
              component={folderNameInput}
              key="folder-name"
              name="name"
              initialValue={editing && folderTree.get(id).name}
              placeholder="New Folder"
            />
            <MaterialButton
              variant="outlined"
              soft
              teal
              onClick={(e) =>
                editing
                  ? editFolderSubmit(e, handleSubmit)
                  : newFolderSubmit(e, handleSubmit)
              }
              size="small"
            >
              {editing ? 'Rename' : 'Add'}
            </MaterialButton>
            <span>
              <CloseOutlined
                onClick={() => (editing ? setEditing(false) : handleClear())}
              />
            </span>
          </form>
        )}
      />
    ) : id !== -1 && folderTree.get(id) ? (
      <div>
        <ContextMenuTrigger id={id + ''}>
          <div
            className={styles.horizontal}
            draggable
            onDragStart={field ? () => {} : onDragStart}
            onDragOver={field ? () => {} : onDragOver}
            onDragEnd={field ? () => {} : onDragEnd}
          >
            {folderTree.get(id).children.length > 0 ? (
              <IconButton size="small" onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? (
                  <KeyboardArrowRight fontSize="small" />
                ) : (
                  <KeyboardArrowDown fontSize="small" />
                )}
              </IconButton>
            ) : (
              <div style={{ width: 26 }} />
            )}
            {id !== 0 && (
              <img
                src={folderSvg}
                className={styles.folderIcon}
                onClick={() => setFolderId(id)}
                alt="folder-img"
              />
            )}
            <span
              className={
                openFolder === id ? styles.textSelected : styles.textUnselected
              }
              onClick={() => setFolderId(id)}
            >
              {folderTree.get(id).name}
            </span>
          </div>
        </ContextMenuTrigger>
        <ContextMenu id={id + ''}>
          <MenuItem
            onClick={() => {
              folderTree.get(id).children.push(-1);
              setCollapsed(false);
            }}
            attributes={{ className: styles.menuItem }}
          >
            Add a subfolder
          </MenuItem>
          {id !== 0 && (
            <MenuItem
              onClick={() => {
                setEditing(true);
              }}
              attributes={{ className: styles.menuItem }}
            >
              Rename folder
            </MenuItem>
          )}
          {id !== 0 && !field && (
            <MenuItem
              onClick={() => {
                deleteFolder({ folderTree, id, products });
                setFolderId(0);
              }}
              attributes={{ className: styles.menuItem }}
            >
              Delete Folder
            </MenuItem>
          )}
        </ContextMenu>
        {!collapsed &&
          children &&
          children.map((child) => (
            <div className={styles.folderList} key={child}>
              <FolderSystem
                folderTree={folderTree}
                id={child}
                setFolderId={setFolderId}
                openFolder={openFolder}
                dragProps={dragProps}
                dispatch={dispatch}
                parentId={id}
                deleteFolder={deleteFolder}
                products={products}
                field={field}
                expanded={expanded}
                expandId={expandId}
                setExpandId={setExpandId}
                handleClear={handleClear}
              />
            </div>
          ))}
        {(!collapsed || id === 0 || openFolder == id) &&
          children &&
          children.indexOf(-1) === -1 && (
            <div className={styles.folderList}>
              <div className={styles.horizontal}>
                <IconButton size="small" style={{ visibility: 'hidden' }}>
                  <KeyboardArrowRight fontSize="small" />
                </IconButton>
                <img
                  src={folderAddSvgIcon}
                  className={styles.addFolderIcon}
                  onClick={() => {
                    const localChildren = [...children];
                    localChildren.push(-1);
                    setChildren(localChildren);
                    setCollapsed(false);
                  }}
                  alt="add-folder-img"
                />
                <span
                  className={styles.addFolder}
                  onClick={() => {
                    const localChildren = [...children];
                    localChildren.push(-1);
                    setChildren(localChildren);
                    setCollapsed(false);
                  }}
                >
                  Add Folder
                </span>
              </div>
            </div>
          )}
      </div>
    ) : null
  ) : null;
};

export default FolderSystem;
