import * as React from 'react';

import { useQuery } from '@apollo/client';
import PairedPreferencesByReportId from '@graphql/queries/ReportPairedPreferences';
import { Box, Typography } from '@mui/material';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { useTranslation } from 'react-i18next';

import PairedComparisonPreference from './PairedComparisonPreference';
import { pairedPreferenceComparisonArray } from './utils';

interface Props {
  projectId?: number;
  reportId: string;
}

const PairedComparisonPreferenceComponent: React.FC<Props> = (props) => {
  const { projectId, reportId } = props;

  const { t } = useTranslation();

  const customProductNames = useCustomProductNames({ projectId, reportId });

  const { loading, error, data } =
    useQuery<reports.ReportPairedPreferenceComparisonResponse>(
      PairedPreferencesByReportId,
      {
        variables: {
          reportID: reportId,
        },
      },
    );

  if (loading || error) {
    return <div />;
  }

  return (
    <Box>
      <Typography variant="h4" color="black" align="left" padding={2}>
        {t('reports.heatMap.title')}
      </Typography>
      <PairedComparisonPreference
        productNames={customProductNames}
        pairedPreferenceComparisonArray={pairedPreferenceComparisonArray(data)}
      />
    </Box>
  );
};

export default PairedComparisonPreferenceComponent;
