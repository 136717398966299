import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import AllWorkspacesQuery, {
  AllWorkspacesQueryNode,
  AllWorkspacesQueryResponse,
} from '@graphql/queries/AllWorkspacesQuery';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import TableSearch from 'components/Table/TableSearch';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import styles from './AllWorkspaceList.module.css';

const getData = (data: AllWorkspacesQueryNode[]): TableRow[] => {
  return (
    data &&
    data.map((node) => {
      return {
        id: node.id,
        name: node.name,
      };
    })
  );
};

interface TableRow {
  id: number;
  name: string;
}

const AllWorkspaceList: React.FC = () => {
  const { t } = useTranslation();
  const [workspaces, setWorkspaces] = useState<TableRow[]>([]);

  const { loading, error, data } =
    useQuery<AllWorkspacesQueryResponse>(AllWorkspacesQuery);

  useEffect(() => {
    if (!loading && data) setWorkspaces(getData(data.allProducers.nodes));
  }, [loading, data]);

  const filterData = (term: string) => {
    if (!term.length) return setWorkspaces(getData(data.allProducers.nodes));
    const updateList =
      data &&
      data.allProducers.nodes.filter((row) =>
        JSON.stringify(row).toLowerCase().includes(term.toLowerCase()),
      );
    setWorkspaces(getData(updateList));
  };

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: <div className={styles.tableHeader}>{t('workspace.id')}</div>,
      sortable: true,
      width: '18%',
    },
    {
      id: 'name',
      selector: (row: TableRow) => row.name,
      name: <div className={styles.tableHeader}>{t('workspace.name')}</div>,
      sortable: true,
      wrap: true,
    },
  ];

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <h4>Can't load workspaces!</h4>;
  }

  return (
    <div style={{ width: '100%' }}>
      <List>
        <div className={styles.headerContainer}>
          <h4>
            {data && data.allProducers && data.allProducers.totalCount}{' '}
            {t('admin.workspaces')}
          </h4>
          <TableSearch
            onSearch={(e) => filterData(e)}
            placeholder={t('general.search')}
          />
        </div>
      </List>
      {!loading && data && data.allProducers && (
        <DataTable
          columns={columns}
          data={workspaces}
          fixedHeader
          striped
          pagination
          paginationIconFirstPage={<RDTFirstPage />}
          paginationIconLastPage={<RDTLastPage />}
          paginationIconPrevious={<RDTPrevPage />}
          paginationIconNext={<RTDNextPage />}
          style={styles}
        />
      )}
    </div>
  );
};

export default AllWorkspaceList;
