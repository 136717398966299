import { gql } from '@apollo/client';

export default gql`
  query UserWorkspaceListQuery($email: String!) {
    user: userByEmail(email: $email) {
      producerUsersByUserId {
        nodes {
          id
          producer: producerByProducerId {
            id
            name
          }
        }
        totalCount
      }
    }
  }
`;
