import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';

interface Props {
  condition: boolean;
  message: string;
}

const ProductWarnTooltip: React.FC<Props> = (props: Props) => {
  const { condition, message } = props;

  if (!condition) {
    return null;
  }

  return (
    <div
      style={{
        color: 'yellow',
      }}
    >
      <Tooltip title={message}>
        <IconButton size="large">
          <InfoOutlinedIcon style={{ fontSize: 24, color: '#edba3b' }} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ProductWarnTooltip;
