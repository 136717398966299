import * as React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import { REQUEST_REPORT_FORM } from '../../../constants/formNames';
import { MARKET_SURVEY, OPTIMIZATION } from '../../../constants/report';
import { PageHeader, PageTitle } from '../../../styles/themeComponents';
import { isAfsWorkspace } from '../../../utils/afsUtils';
import { makeReportParams } from '../../../utils/conchUtils';
import ConditionViewerRoleContainer from '../../ConditionViewerRole';
import AdvancedConfig, {
  AdvancedConfigProps,
} from '../AdvancedConfig/AdvancedConfig';
import Demographics from '../Demographics';
import { DemographicsProps } from '../Demographics/Demographics';
import MarketSurvey from '../MarketSurvey';
import { MarketSurveyProps } from '../MarketSurvey/MarketSurvey';
import Optimization from '../Optimization';
import styles from '../RequestReport.module.css';

interface Props
  extends MarketSurveyProps,
    DemographicsProps,
    AdvancedConfigProps {
  projectName: string;
  testMode: boolean;
  optimization: {
    project_name: string;
  };
  isProd: boolean;
}

const Confirmation: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const formValues = useSelector((state) =>
    getFormValues(REQUEST_REPORT_FORM)(state),
  );

  return (
    <div>
      <PageHeader>{t('navigation.reports')}</PageHeader>
      <PageTitle>{`${t('reports.createReportRequest')}: ${t(
        'reports.confirmation',
      )}`}</PageTitle>

      <div className={styles.competitiveSetTable}>
        <table>
          <tbody>
            <tr>
              <td className={styles.fieldColumn}>
                <span className={styles.fieldsLabel}>{t('reports.type')}</span>
              </td>
              <td className={styles.valueColumn}>
                <span>{props.report_type.label}</span>
              </td>
            </tr>
            <tr>
              <td className={styles.fieldColumn}>
                <span className={styles.fieldsLabel}>
                  {t('reports.projectName')}
                </span>
              </td>
              <td className={styles.valueColumn}>
                {props.report_type.value === MARKET_SURVEY && (
                  <span>{props.projectName}</span>
                )}
                {props.report_type.value === OPTIMIZATION && (
                  <span>{props.optimization.project_name}</span>
                )}
              </td>
            </tr>
            {isAfsWorkspace(props.producerId) &&
              props.report_type.value === OPTIMIZATION && (
                <tr>
                  <td className={styles.fieldColumn}>
                    <span className={styles.fieldsLabel}>
                      {t('reports.testMode')}
                    </span>
                  </td>
                  <td className={styles.valueColumn}>
                    <span>
                      {props.testMode ? t('forms.yes') : t('forms.no')}
                    </span>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>

      <div>
        {props.report_type.value === MARKET_SURVEY && (
          <MarketSurvey
            reportWorkspace={props.reportWorkspace}
            templateReportProducts={props.templateReportProducts}
            competitive_set_rich={props.competitive_set_rich}
            client_allowed_workspaces={props.client_allowed_workspaces}
            change={props.change}
            competitive_set_folders={props.competitive_set_folders}
            report_type={props.report_type}
            displayMode={true}
            {...props}
          />
        )}
        {props.report_type.value === OPTIMIZATION && (
          <Optimization
            templateReportId={undefined}
            change={undefined}
            displayMode={true}
            {...props}
          />
        )}
      </div>

      {props.report_type.value === MARKET_SURVEY && (
        <div>
          <div className={styles.sectionContainer}>
            <span className={styles.sectionHeader}>
              {t('reports.demographics')}
            </span>
          </div>
          <div className={styles.competitiveSetTable}>
            <Demographics
              include_female={formValues.include_female}
              displayMode={true}
              {...props}
            />
          </div>
          <div className={styles.sectionContainer}>
            <AdvancedConfig
              categories={formValues.competitive_set_folders}
              displayMode={true}
              {...props}
            />
          </div>
        </div>
      )}

      <ConditionViewerRoleContainer
        render={(viewerRoles) =>
          viewerRoles.viewerRoleIsSuperadmin && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="reportJson"
                id="reportJson-header"
              >
                Report Params
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.sectionContainer}>
                  <pre>
                    {JSON.stringify(makeReportParams(formValues, {}), null, 2)}
                  </pre>
                </div>
              </AccordionDetails>
            </Accordion>
          )
        }
      />
    </div>
  );
};

export default Confirmation;
