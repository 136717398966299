import * as React from 'react';
import { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import AllReportsQuery from '@graphql/queries/AllReportsQuery';
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material';
import { Grid, IconButton, Paper } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { useHistory } from 'react-router-dom';

import { handleCreateProject, handleEditProject } from './handleCreateProject';
import styles from './ProjectCreate.module.css';
import ProjectCreateForm from './ProjectCreateForm';
import { PROJECT_TO_EDIT } from './ProjectToEditQuery';
import { validateProjectForm } from './validateProjectForm';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import { PageHeader } from '../../styles/themeComponents';
import { formatReportLabel } from '../Project/utils';

interface ProjectCreateContainerProps extends RouterProps {
  match: Record<string, any>;
}
const ProjectCreateContainer: React.FC<ProjectCreateContainerProps> = (
  props,
) => {
  const { match } = props;
  const [projectReportIds, setProjectReportIds] = useState<number[]>([]);

  const { t } = useTranslation();
  const history = useHistory();

  const workspaceId = useSelector((state) => selectWorkspaceProducerId(state));

  const projectId: number = match.params.projectId
    ? parseInt(match.params.projectId)
    : -1;

  const {
    loading: reportsLoading,
    error: reportsError,
    data: reportsData,
  } = useQuery<reports.AllReportsQueryResponse>(AllReportsQuery, {
    variables: {
      filter: {
        passedQa: { equalTo: true },
        workspaceId: { equalTo: workspaceId },
      },
      orderBy: 'ID_DESC',
    },
    fetchPolicy: 'no-cache',
  });

  const {
    loading: editLoading,
    error: editError,
    data: editData,
  } = useQuery<reports.ProjectToEdit>(PROJECT_TO_EDIT, {
    variables: {
      projectId: projectId,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (editLoading && !!editData?.projectById) return;
    setProjectReportIds(
      editData?.projectById?.projectReportsByProjectId?.nodes.map((i) => i.id),
    );
  }, [editLoading, editData]);

  if (reportsLoading || reportsError || editLoading || editError) {
    return <div />;
  }

  const getProjectName = (editData: reports.ProjectToEdit) => {
    if (editLoading || editError || !editData.projectById) {
      return '';
    } else {
      return editData.projectById.name;
    }
  };

  const getProjectReports = (editData: reports.ProjectToEdit) => {
    if (editLoading || editError || !editData.projectById) {
      return [];
    } else {
      return editData.projectById.projectReportsByProjectId.nodes
        .sort((a, b) => Date.parse(b.updatedDate) - Date.parse(a.updatedDate))
        .map((n) => ({
          id: n.id.toString(),
          reportId: n.reportId,
          label: formatReportLabel(
            n.reportJobByReportId.projectName,
            n.reportJobByReportId.targetGroupName,
            n.reportJobByReportId.reportType,
            n.reportJobByReportId.startedAt,
          ),
        }));
    }
  };

  return (
    <Paper className={styles.container}>
      <Grid container>
        <Grid item xs={12}>
          <div className={styles.header}>
            <IconButton
              onClick={props.history.goBack}
              size="small"
              style={{ marginLeft: -26 }}
            >
              <KeyboardBackspaceIcon fontSize="small" />
              <PageHeader>{t('project.projects')}</PageHeader>
            </IconButton>
            <h3 className={styles.projectTitle}>
              {projectId > 0 ? t('project.edit') : t('project.create')}
            </h3>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ width: '95%' }}>
            <Formik
              initialValues={{
                name: getProjectName(editData),
                projectReports: getProjectReports(editData),
                projectId: projectId ?? null,
                isDelete: false,
              }}
              validate={validateProjectForm}
              onSubmit={
                projectId > 0
                  ? handleEditProject(history, projectReportIds)
                  : handleCreateProject(history)
              }
              render={(props) => (
                <ProjectCreateForm
                  {...props}
                  producerId={workspaceId}
                  editProject={projectId > 0}
                  editing={false}
                  workspaceReports={reportsData.reports.nodes}
                />
              )}
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProjectCreateContainer;
