import * as React from 'react';
import { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import { COL_WIDTH } from 'containers/ProjectComparison/ProjectComparison';

import MultiLineChartIcon from './MultiLineChartIcon';
import ReportMarketPreferenceComparison from './ReportMarketPreferenceComparison/ReportMarketPreferenceComparison';
import ReportMarketPreferenceOverlay from './ReportMarketPreferenceComparisonOverlay/ReportMarketPreferenceComparisonOverlay';
import SingleLineChartIcon from './SingleLineChartIcon';
import { ProductVersion } from '../ProductVersion';
import { getProductName } from '../utils';

interface ReportProps {
  projectId: number;
  customProductNames: reports.ColorVersionedProductInfo[];
  products: reports.ProductToCompare[];
}

const ComparisonProductMarketPreference: React.FC<ReportProps> = (props) => {
  const { projectId, customProductNames, products } = props;
  const [maxPercentPopCollection, setMaxPercentPopCollection] = useState<
    Map<string, number>
  >(new Map());
  const [calculatedMaxPercentPop, setCalculatedMaxPercentPop] =
    useState<number>(0);

  const [showSideBySide, setShowSideBySide] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(true);

  const updateMaxPercentPopCollection = (key: string, percentPop: number) => {
    const curr = new Map(maxPercentPopCollection);
    curr.set(key, percentPop);
    setMaxPercentPopCollection(curr);
    setCalculatedMaxPercentPop(
      Array.from(curr, ([_, pop]) => pop).reduce((a, b) => (a > b ? a : b), 0) *
        1.1,
    );
  };

  return (
    <Grid container style={{ width: '100%' }}>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={() => {
              setShowSideBySide(true);
              setShowOverlay(false);
            }}
            size="large"
          >
            <SingleLineChartIcon fontSize="small" color="primary" />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowSideBySide(false);
              setShowOverlay(true);
            }}
            size="large"
          >
            <MultiLineChartIcon fontSize="small" color="primary" />
          </IconButton>
        </Box>
      </Grid>
      {showSideBySide && (
        <Grid key="sideBySide" item xs={12}>
          <div style={{ display: 'flex', gap: '10px' }}>
            {products.map((p: reports.ProductToCompare, i: number) => (
              <div
                style={{ width: `${COL_WIDTH}px`, flexShrink: '0' }}
                key={'sideBySideProduct_' + i}
              >
                {!!p.reportId && !!p.productId ? (
                  <ReportMarketPreferenceComparison
                    title={getProductName({
                      productNames: customProductNames,
                      productId: p.productId,
                      version: p.version,
                    })}
                    reportId={p.reportId}
                    productVersion={new ProductVersion(p.productId, p.version)}
                    sendMaxPercentPop={(value: number) =>
                      updateMaxPercentPopCollection('pop' + i, value)
                    }
                    calculatedMaxPercentPop={calculatedMaxPercentPop}
                  />
                ) : null}
              </div>
            ))}
          </div>
        </Grid>
      )}
      {/* {showOverlay && <Grid item xs={3} />} */}
      {showOverlay && (
        <Grid key="overlayGraph" item xs={12} style={{ width: '100%' }}>
          {products.every((p) => !!p.reportId && !!p.productId) ? (
            <ReportMarketPreferenceOverlay
              title="Comparative PQ Distribution"
              projectId={projectId}
              products={products}
              calculatedMaxPercentPop={calculatedMaxPercentPop}
            />
          ) : null}
        </Grid>
      )}
      {showOverlay && <Grid item xs={3} />}
    </Grid>
  );
};

export default ComparisonProductMarketPreference;
