import * as React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { flowRight as compose } from 'lodash';
import DataTable, { TableColumn } from 'react-data-table-component';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import styles from './USDAProductSearch.module.css';

interface Props {
  change: any;
  data?: any;
  getNutrients: any;
  loading?: boolean;
}

interface TableRow {
  id: number;
  name: string;
  brand: string;
}

class USDAProductSearch extends React.Component<Props & WithTranslation> {
  rowClick = (row, event) => {
    this.props.getNutrients(row.id);
  };

  getProductData = (): TableRow[] => {
    const { data, getNutrients } = this.props;
    if (data && data.foods) {
      return data.foods.map((food) => ({
        id: food.fdcId,
        name: food.description,
        brand: food.brandOwner && food.brandOwner,
      }));
    }

    return [];
  };

  render() {
    const { t, loading } = this.props;

    const columns: TableColumn<TableRow>[] = [
      {
        selector: (row: TableRow) => row.id,
        name: 'User ID',
        sortable: true,
        omit: true,
      },
      {
        selector: (row: TableRow) => row.name,
        name: 'Name',
      },
      {
        selector: (row: TableRow) => row.brand,
        name: 'Brand',
      },
    ];

    return (
      <Paper className={styles.container}>
        {loading ? (
          <LinearProgress />
        ) : (
          <div className={styles.tableContainer}>
            <DataTable
              striped
              columns={columns}
              data={this.getProductData()}
              onRowClicked={(row, event) => this.rowClick(row, event)}
            />
          </div>
        )}
      </Paper>
    );
  }
}

export default compose(connect(), withTranslation())(USDAProductSearch);
