const metrics = [
  'allGgVar',
  'ggVarMax',
  'insufficientGgVar',
  'noRefFlavor',
  'excessiveRefFlavor',
  'shortReviewTime',
  'buttonMashing'
];
export default metrics;
