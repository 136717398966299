import React, { useRef, useEffect } from 'react';

import * as d3 from 'd3';

import { ReportNoveltyStackRankSplit } from '@graphql/queries/ReportNoveltyStackRankSplits';
import { wrap } from 'components/Report/utils';

import { ProductNode } from './ReportNoveltyTree';
import { appendNoveltyLegend, colorScale } from '../NoveltyScale/NoveltyScale';

interface StackRankProps {
  productNodes: ProductNode[];
  splitNodes: ReportNoveltyStackRankSplit[];
  options: any;
}

export const getLabelFontSize = (productNodes: ProductNode[]) => {
  const longestNameLength = productNodes
    .map((pn) => pn.productName?.length)
    .reduce((a, i) => (i > a ? i : a), 0);
  return Math.floor(400 / longestNameLength);
};

export const productLabel = (node: ProductNode): string => {
  if (node?.productName) {
    return node.productName;
  } else {
    return 'unknown product';
  }
};

export const productNodeY = (
  nodes: ProductNode[],
  nodeId: number,
  yScale: d3.ScaleLinear<number, number, never>,
): number => {
  const node = nodes.find((n) => n.noveltyProductNodeId == nodeId);
  const idx = nodes.indexOf(node);
  const yVal = yScale(idx);
  return yVal;
};

const nodeY = (
  productNodes: ProductNode[],
  splitNodes: ReportNoveltyStackRankSplit[],
  nodeId: number,
  yScale: d3.ScaleLinear<number, number, never>,
): number => {
  if (productNodes.map((p) => p.noveltyProductNodeId).includes(nodeId)) {
    return productNodeY(productNodes, nodeId, yScale);
  } else if (splitNodes.map((s) => s.noveltySplitNodeId).includes(nodeId)) {
    const node = splitNodes.find((n) => n.noveltySplitNodeId == nodeId);
    const leftY = nodeY(productNodes, splitNodes, node.leftNode, yScale);
    const rightY = nodeY(productNodes, splitNodes, node.rightNode, yScale);
    return (leftY + rightY) / 2;
  } else {
    throw `Data error - provided node ID (${nodeId}) not found in product nodes (${productNodes.map((n) => n.noveltyProductNodeId)}) or split nodes (${splitNodes.map((n) => n.noveltySplitNodeId)})`;
  }
};

const nodeX = (
  productNodes: ProductNode[],
  splitNodes: ReportNoveltyStackRankSplit[],
  nodeId: number,
  width: number,
  productNodeX: number,
  maxDepth: number,
): number => {
  if (productNodes.map((p) => p.noveltyProductNodeId).includes(nodeId)) {
    return productNodeX;
  } else if (splitNodes.map((s) => s.noveltySplitNodeId).includes(nodeId)) {
    const node = splitNodes.find((n) => n.noveltySplitNodeId == nodeId);
    const maxX = width - 50;
    const minX = productNodeX;
    return (node.depth / maxDepth) * (maxX - minX) + minX;
  } else {
    throw `Data error - provided node ID (${nodeId}) not found in product nodes (${productNodes.map((n) => n.noveltyProductNodeId)}) or split nodes (${splitNodes.map((n) => n.noveltySplitNodeId)})`;
  }
};

const NoveltyTree: React.FC<StackRankProps> = (props) => {
  // Inspired by https://ncoughlin.com/posts/d3-react/

  // Element References
  const svgRef = useRef(null);
  const { productNodes, splitNodes, options } = props;

  useEffect(() => {
    // D3 Code
    const {
      width: width = Math.max(50 * productNodes.length, 400),
      height: height = 75 * productNodes.length,
      paddingBottom: paddingBottom = 75,
      paddingTop: paddingTop = 10,
      scaleHeight: scaleHeight = 75,
    } = options;

    const splitNodeWidth = width / 24;
    const productNodeX = Math.max(splitNodeWidth * 4, 100);

    const minDepth = Math.min(...splitNodes.map((n) => n.depth));
    const maxDepth = Math.max(...splitNodes.map((n) => n.depth));

    const yScale = d3
      .scaleLinear()
      .range([scaleHeight + paddingTop, height - paddingBottom])
      .domain([0, productNodes.length - 1]);

    // Selections
    const svg = d3
      .select(svgRef.current)
      .classed('novelty-stack-rank-chart-svg', true)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr(
        'style',
        'max-width: 100%; height: auto; height: intrinsic; background-color: #fff',
      );

    // clear all previous content on refresh
    const everything = svg.selectAll('*');
    everything.remove();

    const yAxis = d3
      .axisLeft(yScale)
      .tickSize(0)
      .ticks(productNodes.length)
      .tickFormat((d, i) => productNodes[i]?.productName);

    svg
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${productNodeX},0)`)
      .call(yAxis)
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      .style('font-size', `${getLabelFontSize(productNodes)}px`)
      .call(
        wrap,
        productNodeX - 10,
        'left',
        getLabelFontSize(productNodes),
        8,
        0,
      );

    svg
      .append('g')
      .attr('id', 'leftEdgeLayer')
      .selectAll('line')
      .data(splitNodes)
      .enter()
      .append('line')
      .attr('x1', (d) =>
        nodeX(
          productNodes,
          splitNodes,
          d.noveltySplitNodeId,
          width,
          productNodeX,
          maxDepth,
        ),
      )
      .attr('y1', (d) =>
        nodeY(productNodes, splitNodes, d.noveltySplitNodeId, yScale),
      )
      .attr('x2', (d) =>
        nodeX(
          productNodes,
          splitNodes,
          d.leftNode,
          width,
          productNodeX,
          maxDepth,
        ),
      )
      .attr('y2', (d) => nodeY(productNodes, splitNodes, d.leftNode, yScale))
      .attr('stroke', 'black')
      .attr('stroke-width', 1);

    svg
      .append('g')
      .attr('id', 'rightEdgeLayer')
      .selectAll('line')
      .data(splitNodes)
      .enter()
      .append('line')
      .attr('x1', (d) =>
        nodeX(
          productNodes,
          splitNodes,
          d.noveltySplitNodeId,
          width,
          productNodeX,
          maxDepth,
        ),
      )
      .attr('y1', (d) =>
        nodeY(productNodes, splitNodes, d.noveltySplitNodeId, yScale),
      )
      .attr('x2', (d) =>
        nodeX(
          productNodes,
          splitNodes,
          d.rightNode,
          width,
          productNodeX,
          maxDepth,
        ),
      )
      .attr('y2', (d) => nodeY(productNodes, splitNodes, d.rightNode, yScale))
      .attr('stroke', 'black')
      .attr('stroke-width', 1);

    svg
      .append('g')
      .attr('id', 'splitNodesLayer')
      .selectAll('rect')
      .data(splitNodes)
      .enter()
      .append('rect')
      .attr(
        'x',
        (d) =>
          nodeX(
            productNodes,
            splitNodes,
            d.noveltySplitNodeId,
            width,
            productNodeX,
            maxDepth,
          ) -
          splitNodeWidth / 2,
      )
      .attr(
        'y',
        (d) =>
          nodeY(productNodes, splitNodes, d.noveltySplitNodeId, yScale) -
          splitNodeWidth / 2,
      )
      .attr('width', splitNodeWidth)
      .attr('height', splitNodeWidth)
      .attr('fill', (d) => {
        return colorScale(1 - (d.depth - minDepth) / (maxDepth - minDepth));
      })
      .attr('style', 'stroke-width:1;stroke:black');

    appendNoveltyLegend(svg, width, scaleHeight);
  }, [productNodes, splitNodes]); // redraw chart if data changes

  return (
    <div className="stack-rank-chart">
      <svg ref={svgRef} />
    </div>
  );
};

export default NoveltyTree;
