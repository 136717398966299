import * as React from 'react';

import { TextField, InputLabel } from '@mui/material';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import styles from './EditCustomTextureClusterName.module.css';
import MaterialButton from '../../../../components/MaterialButton';

type Error = {
  name?: string;
  marketLabel?: string;
};

export const createErrors = (values: { name: string }): Error => {
  const errors: Error = {};
  if (!values?.name?.length) {
    errors.name = 'Texture name cannot be empty.';
  }
  return errors;
};

interface Props {
  closeModal: () => void;
  updateTextureCustomName: (id: number, customName: string) => Promise<void>;
  textureClusterNameData: reports.TCNameData;
}

const EditCustomTextureClusterName: React.FC<Props> = ({
  closeModal,
  updateTextureCustomName,
  textureClusterNameData,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.sectionContainer}>
        <Formik
          initialValues={{
            name: textureClusterNameData?.customClusterName ?? '',
          }}
          validate={(values) => createErrors(values)}
          onSubmit={async (values) => {
            const { name } = values;
            if (!name.length || !textureClusterNameData?.id) return;
            await updateTextureCustomName(textureClusterNameData.id, name);
          }}
          render={({ values, handleChange, handleSubmit, errors }) => (
            <Form>
              <div className={styles.mainContainer}>
                <div className={styles.subContainer}>
                  <InputLabel>
                    Normal Name: {textureClusterNameData?.clusterName ?? 'N/A'}
                  </InputLabel>
                  <div className={styles.subContainer}>
                    <InputLabel>
                      {t(
                        'reports.texturesSummary.editCustomTextureClusterName',
                      )}
                    </InputLabel>
                    <TextField
                      variant="standard"
                      fullWidth
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <span className={styles.error}>
                      {errors && errors.name}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <MaterialButton variant="outlined" soft onClick={closeModal}>
                  {'Cancel'}
                </MaterialButton>
                <MaterialButton
                  variant="outlined"
                  soft
                  teal
                  onClick={handleSubmit}
                >
                  {'Save'}
                </MaterialButton>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
};

export default EditCustomTextureClusterName;
