import * as React from 'react';

import CSVDownload from 'components/CSVDownload';
import { Headers } from 'react-csv/lib/core';

interface Props {
  productName?: string;
  labels: string[];
  values: string[];
}

const ReportGastrographRadarCsv: React.FC<Props> = (props) => {
  const { productName, labels, values } = props;
  const headers = labels;
  headers.unshift('Product');

  const rowValues = values;
  rowValues.unshift(productName);

  const getCsvHeader: Headers = headers.map((item) => ({
    label: item,
    key: item.toString().toLocaleLowerCase(),
  }));

  const getCsvRow = () => {
    const rows = [];
    if (!values) return rows;
    const row = labels.reduce((acc, label, index) => {
      acc[label.toString().toLocaleLowerCase()] = rowValues[index];
      return acc;
    }, {});

    rows.push({ ...row, product: productName });
    return rows;
  };

  if (labels.length <= 1 || values.length <= 1) return <></>;

  return (
    <CSVDownload
      headers={getCsvHeader}
      csvData={getCsvRow()}
      fileName={`${productName}.csv`}
    />
  );
};

export default ReportGastrographRadarCsv;
