import { reduxForm } from 'redux-form';

import AddClient from './AddClient';
import formSubmit from '../../actions/formSubmit';
import { ADD_CLIENT_FORM } from '../../constants/formNames';

// Client name can't just a number.
const validateClientName = (val) => !isNaN(+val);

export default reduxForm({
  form: ADD_CLIENT_FORM,
  onSubmit: (_values, dispatch) => {
    dispatch(formSubmit(ADD_CLIENT_FORM));
  },
  validate: (values) => {
    return {
      clientName: validateClientName(values.clientName),
    };
  },
  initialValues: {
    clientName: null,
    partner: null,
  },
})(AddClient);
