import * as React from 'react';

import { get } from 'lodash';
import DataTable, { TableColumn } from 'react-data-table-component';

import styles from './DataDashboard.module.css';

interface Props {
  panelReviews: dataquality.PanelQualityData[];
}

interface TableRow extends dataquality.PanelQualityData {
  flagsAllggvar: string;
  flagsAllmarksatfive: string;
  flagsNorefflavor: string;
  flagsInsufficientggvar: string;
  flagsExcessiverefflavor: string;
  flagsExcessiveggvar: string;
  flagsExcessivetexture: string;
  flagsZeroMouthfeel: string;
  flagsTooManySevens: string;
}

const ProductReviewTable: React.FC<Props> = ({ panelReviews }) => {
  const reviews: TableRow[] = Object.values(panelReviews).map((item) => ({
    ...item,
    flagsAllggvar: item.flags.allggvar.toString(),
    flagsAllmarksatfive: item.flags.allmarksatfive.toString(),
    flagsNorefflavor: item.flags.norefflavor.toString(),
    flagsInsufficientggvar: item.flags.insufficientggvar.toString(),
    flagsExcessiverefflavor: item.flags.excessiverefflavor.toString(),
    flagsExcessiveggvar: item.flags.excessiveggvar.toString(),
    flagsExcessivetexture: item.flags.excessivetexture.toString(),
    flagsZeroMouthfeel: item.flags.zeroMouthfeelIntensity.toString(),
    flagsTooManySevens: item.flags.tooManySevens.toString(),
  }));
  const userIdentification: string =
    panelReviews[0].email || panelReviews[0].userId.toFixed(0);

  const checkColumn = (row: TableRow, dataField) => get(row, dataField);

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'productId',
      selector: (row: TableRow) => row.productId,
      name: 'Product ID',
      sortable: true,
      omit: true,
    },
    {
      id: 'producName',
      selector: (row: TableRow) => row.productName,
      name: 'Product Name',
      sortable: true,
      wrap: true,
    },
    {
      id: 'flagsAllggvar',
      selector: (row: TableRow) => row.flagsAllggvar,
      name: 'All 24 GGVar Marked',
      sortable: true,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row: TableRow) => checkColumn(row, 'flags.allggvar'),
          style: { color: 'red' },
        },
      ],
    },
    {
      id: 'flagsAllmarksatfive',
      selector: (row: TableRow) => row.flagsAllmarksatfive,
      name: 'Marked GGVars All 5',
      sortable: true,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row: TableRow) => checkColumn(row, 'flags.allmarksatfive'),
          style: { color: 'red' },
        },
      ],
    },
    {
      id: 'flagsNorefflavor',
      selector: (row: TableRow) => row.flagsNorefflavor,
      name: 'No Reference Flavors Marked',
      sortable: true,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row: TableRow) => checkColumn(row, 'flags.norefflavor'),
          style: { color: 'red' },
        },
      ],
    },
    {
      id: 'flagsInsufficientggvar',
      selector: (row: TableRow) => row.flagsInsufficientggvar,
      name: '<= 3 GGVar Marked',
      sortable: true,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row: TableRow) => checkColumn(row, 'flags.insufficientggvar'),
          style: { color: 'red' },
        },
      ],
    },
    {
      id: 'flagsExcessiverefflavor',
      selector: (row: TableRow) => row.flagsExcessiverefflavor,
      name: '> 40 Reference Flavors Marked',
      sortable: true,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row: TableRow) => checkColumn(row, 'flags.excessiverefflavor'),
          style: { color: 'red' },
        },
      ],
    },
    {
      id: 'flagsExcessiveggvar',
      selector: (row: TableRow) => row.flagsExcessiveggvar,
      name: '> 15 GGVars marked',
      sortable: true,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row: TableRow) => checkColumn(row, 'flags.excessiveggvar'),
          style: { color: 'red' },
        },
      ],
    },
    {
      id: 'flagsExcessivetexture',
      selector: (row: TableRow) => row.flagsExcessivetexture,
      name: '> 15 Textures',
      sortable: true,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row: TableRow) => checkColumn(row, 'flags.excessivetexture'),
          style: { color: 'red' },
        },
      ],
    },
    {
      id: 'flagsZeroMouthfeel',
      selector: (row: TableRow) => row.flagsZeroMouthfeel,
      name: '0 Mouthfeel',
      sortable: true,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row: TableRow) =>
            checkColumn(row, 'flags.zeroMouthfeelIntensity'),
          style: { color: 'red' },
        },
      ],
    },
    {
      id: 'flagsTooManySevens',
      selector: (row: TableRow) => row.flagsTooManySevens,
      name: 'Too Many 7s',
      sortable: true,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row: TableRow) => checkColumn(row, 'flags.tooManySevens'),
          style: { color: 'red' },
        },
      ],
    },
  ];

  return (
    <div
      style={{ backgroundColor: 'white', padding: '20px 10px', width: '70%' }}
    >
      <h3 className={styles.panelTitle}>{userIdentification}</h3>
      <DataTable columns={columns} data={reviews} fixedHeader striped />
    </div>
  );
};

export default ProductReviewTable;
