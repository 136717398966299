import * as React from 'react';

import UpsertCustomPqProductName from '@graphql/mutations/UpsertCustomPqProductName';
import { TextField, InputLabel } from '@mui/material';
import { getCanonicalName } from 'components/Report/utils';
import graphqlClient from 'consumers/graphqlClient';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  showOriginalProductName,
  useCustomerPreferences,
} from 'services/customerPreferences';

import styles from './BulkEditCustomNamesContainer.module.css';
import MaterialButton from '../../../MaterialButton';

type Error = {
  [k: string]: string;
};
interface Props {
  projectId: number;
  closeModal: () => void;
  customList: reports.ColorVersionedProductInfo[];
  productInformation: reports.BulkEditingProduct[];
}

const BulkEditCustomNamesContainer: React.FC<Props> = ({
  projectId,
  closeModal,
  customList,
  productInformation,
}) => {
  const { t } = useTranslation();

  const customerPreferences = useCustomerPreferences();

  const dynamicInitialValues = productInformation.map((item, index) => {
    return {
      [`product_id_${index}`]: item.id,
      [`product_canonicalname_${index}`]: getCanonicalName({
        productNames: customList,
        productId: item.id,
        productName: item.name,
        version: item.version,
      }),
      [`product_name_${index}`]: item.name,
      [`market_label_${index}`]: item.marketProductLabel,
      [`product_version_${index}`]: item.version,
      [`product_label_color_${index}`]: item.productLabelColor,
    };
  });

  const errors: Error = {};
  return (
    <div>
      <div className={styles.sectionContainer}>
        <Formik
          initialValues={dynamicInitialValues}
          validate={(values) => {
            for (let i = 0; i < values.length; i++) {
              if (!values[i][`product_name_${i}`].toString().length) {
                errors[`product_name_${i}`] = 'Product name cannot be empty.';
              }
              if (
                values[i][`market_label_${i}`] &&
                values[i][`market_label_${i}`].toString().length > 10
              ) {
                errors[`market_label_${i}`] =
                  'Market label must be less than 10 single characters.';
              }
            }

            return errors;
          }}
          onSubmit={async (values) => {
            const productIds = [];
            const productNames = [];
            const marketProductLabels = [];
            const versions = [];
            const productLabelColors = [];
            values.forEach((item, i) => {
              productIds.push(item[`product_id_${i}`]);
              productNames.push(item[`product_name_${i}`]);
              marketProductLabels.push(item[`market_label_${i}`]);
              versions.push(item[`product_version_${i}`]);
              productLabelColors.push(item[`product_label_color${i}`]);
            });
            await graphqlClient
              .mutate({
                mutation: UpsertCustomPqProductName,
                variables: {
                  input: {
                    projectId: projectId,
                    productIds: productIds,
                    productNames: productNames,
                    marketProductLabels: marketProductLabels,
                    versions: versions,
                    productLabelColors: productLabelColors,
                  },
                },
              })
              .then(() => closeModal());
          }}
          render={({ values, handleChange, handleSubmit, errors }) => (
            <Form>
              <div className={styles.subContainer}>
                {showOriginalProductName(customerPreferences) && (
                  <div className={styles.fieldContainer}>
                    <InputLabel>
                      {t('reports.prodSummary.originalLabel')}
                    </InputLabel>
                  </div>
                )}
                <div className={styles.fieldContainer}>
                  <InputLabel>{t('reports.prodSummary.customEdit')}</InputLabel>
                </div>
                <div className={styles.labelFieldContainer}>
                  <InputLabel>
                    {t('reports.prodSummary.customLabelEdit')}
                  </InputLabel>
                </div>
              </div>
              <div className={styles.mainContainer}>
                {productInformation.map((item, index) => (
                  <div className={styles.subContainer} key={index}>
                    {showOriginalProductName(customerPreferences) && (
                      <div className={styles.fieldContainer}>
                        <TextField
                          variant="standard"
                          fullWidth
                          name="canonicalName"
                          disabled
                          value={
                            values[index][`product_canonicalname_${index}`]
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </div>
                    )}
                    <div className={styles.fieldContainer}>
                      <TextField
                        variant="standard"
                        fullWidth
                        id={index.toString()}
                        name={`${index}.product_name_${index}`}
                        value={values[index][`product_name_${index}`]}
                        onChange={handleChange}
                      />
                      <span className={styles.error}>
                        {errors && errors[`product_name_${index}`]}
                      </span>
                    </div>
                    <div className={styles.labelFieldContainer}>
                      <TextField
                        variant="standard"
                        fullWidth
                        id={index.toString()}
                        name={`${index}.market_label_${index}`}
                        value={values[index][`market_label_${index}`]}
                        onChange={handleChange}
                      />
                      <span className={styles.error}>
                        {errors && errors[`market_label_${index}`]}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              <div className={styles.footer}>
                <MaterialButton variant="outlined" soft onClick={closeModal}>
                  {'Cancel'}
                </MaterialButton>
                <MaterialButton
                  variant="outlined"
                  soft
                  teal
                  onClick={handleSubmit}
                >
                  {'Save'}
                </MaterialButton>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
};

export default BulkEditCustomNamesContainer;
