import * as React from 'react';
import { useReducer, useState } from 'react';

import { CheckCircleOutline, CloseOutlined } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox, IconButton } from '@mui/material';
import NoArrowIcon from 'components/Icons/PanelIcons/NoArrowIcon';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import { get } from 'lodash';
import moment from 'moment';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './PanelCreate.module.css';
import DatePicker from '../../components/ProductClassAttributesInput/RenderDatePicker';
import WarningModal from '../../components/WarningModal';
import { PRODUCT } from '../../constants/routePaths';
import formatPath from '../../utils/formatPath';

const dragIcon = require('./img/dragIcon.png');

interface TableRow {
  id: number;
  drag: string | JSX.Element | any;
  name: string | JSX.Element | any;
  productionDate: string | JSX.Element | any;
  expirationDate: string | JSX.Element | any;
  productClass: string;
  public: boolean | JSX.Element | any;
  aroma: boolean | JSX.Element | any;
  blindLabel: string | JSX.Element | any;
  servingVessel: string | JSX.Element | any;
  clientName: string | JSX.Element | any;
  behavioralQuestions: string | JSX.Element | any;
  projectName: string | JSX.Element | any;
  totalCost: string | JSX.Element | any;
  workspaceName: string | JSX.Element | any;
  action: string | JSX.Element | any;
}

interface PanelProductTableProps {
  data: any[];
  onClickRow: (a) => void;
  swapIndicies?: (index1: number, index2: number) => void;
  blindPanel: boolean;
  afsWorkspaceBool: boolean;
  setBlindLabel: (val, product) => void;
  setServingVessel: (val, product) => void;
  setClientName: (val, product) => void;
  setProjectName: (val, product) => void;
  setTotalCost: (val, product) => void;
  setProductionDate: (val, product) => void;
  setExpirationDate: (val, product) => void;
  setBehavioralQuestions: (val, product) => void;
  blindLabels: any;
  servingVessels: any;
  behavioralQuestions: any;
  clientNames: any;
  projectNames: any;
  totalCosts: any;
  productionDates: any;
  expirationDates: any;
  editable: boolean;
  allowBehavioralQuestions: boolean;
  editing?: boolean;
}

const PanelProductTable: React.FC<PanelProductTableProps> = (props) => {
  const { data, swapIndicies, editing } = props;

  const { t } = useTranslation();

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [showModalReviewed, setShowModalReviewed] = useState<boolean>(false);
  const [showModalBehavior, setShowModalBehavior] = useState<boolean>(false);

  const closeModalReviewed = () => setShowModalReviewed(false);
  const closeModalBehavior = () => setShowModalBehavior(false);

  const yesNoFormatter = (value: boolean) =>
    value ? (
      <CheckCircleOutline color="secondary" />
    ) : (
      <CloseOutlined color="error" />
    );

  const productClassFormatter = (product: any): string => {
    const formatted =
      product.productClasses &&
      product.productClasses.nodes
        .map((pc) => pc.productClassByProductClassId.name)
        .join(', ');

    return formatted && formatted.length !== 0
      ? formatted
      : product.productClass
        ? product.productClass
        : 'Other';
  };

  const svOptions = Array<any>(t('servingVessel', { returnObjects: true }));
  const svRenderoptions =
    svOptions[0] instanceof Array &&
    svOptions[0].map((data) => (
      <option key={data.value} value={data.value}>
        {' '}
        {data.label}{' '}
      </option>
    ));

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: 'Product ID',
      sortable: false,
      wrap: true,
      omit: true,
    },
    {
      id: 'drag',
      selector: (row: TableRow) => row.drag,
      name: '',
      sortable: false,
      wrap: true,
      width: '25px',
      center: true,
      compact: true,
    },
    {
      id: 'name',
      selector: (row: TableRow) => row.name,
      name: <div className={styles.tableHeader}>Name</div>,
      sortable: false,
      wrap: true,
    },
    {
      id: 'productionDate',
      selector: (row: TableRow) => row.productionDate,
      name: <div className={styles.tableHeader}>Production Date</div>,
      sortable: false,
      wrap: true,
    },
    {
      id: 'expirationDate',
      selector: (row: TableRow) => row.expirationDate,
      name: <div className={styles.tableHeader}>Expiration Date</div>,
      sortable: false,
      wrap: true,
    },
    {
      id: 'productClass',
      selector: (row: TableRow) => row.productClass,
      name: <div className={styles.tableHeader}>Product Class</div>,
      sortable: false,
      wrap: true,
      omit: props.afsWorkspaceBool,
    },
    {
      id: 'public',
      selector: (row: TableRow) => row.public,
      name: <div className={styles.tableHeader}>Public</div>,
      sortable: false,
      wrap: true,
      width: '84px',
      center: true,
    },
    {
      id: 'aroma',
      selector: (row: TableRow) => row.aroma,
      name: <div className={styles.tableHeader}>Aroma</div>,
      sortable: false,
      wrap: true,
      width: '84px',
      center: true,
    },
    {
      id: 'blindLabel',
      selector: (row: TableRow) => row.blindLabel,
      name: <div className={styles.tableHeader}>Blind Label</div>,
      sortable: false,
      wrap: true,
    },
    {
      id: 'servingVessel',
      selector: (row: TableRow) => row.servingVessel,
      name: <div className={styles.tableHeader}>Serving Vessel</div>,
      sortable: false,
      wrap: true,
    },
    {
      id: 'clientName',
      selector: (row: TableRow) => row.clientName,
      name: <div className={styles.tableHeader}>Client Name</div>,
      sortable: false,
      wrap: true,
      omit: !props.afsWorkspaceBool,
    },
    {
      id: 'behavioralQuestions',
      selector: (row: TableRow) => row.behavioralQuestions,
      name: (
        <div className={styles.tableHeader}>{t('panel.behaviorQuestion')}</div>
      ),
      sortable: false,
      wrap: true,
      omit: !props.allowBehavioralQuestions,
      width: '110px',
      center: true,
    },
    {
      id: 'projectName',
      selector: (row: TableRow) => row.projectName,
      name: <div className={styles.tableHeader}>Project</div>,
      sortable: false,
      wrap: true,
      omit: !props.afsWorkspaceBool,
    },
    {
      id: 'totalCost',
      selector: (row: TableRow) => row.totalCost,
      name: <div className={styles.tableHeader}>Total Cost</div>,
      sortable: false,
      wrap: true,
      omit: !props.afsWorkspaceBool,
    },
    {
      id: 'workspaceName',
      selector: (row: TableRow) => row.workspaceName,
      name: <div className={styles.tableHeader}>Workspace Name</div>,
      sortable: false,
      wrap: true,
      omit: !props.afsWorkspaceBool,
    },
    {
      id: 'action',
      selector: (row: TableRow) => row.action,
      name: '',
      sortable: false,
      omit: !props.editable,
      width: '50px',
      center: true,
    },
  ];

  const getData = (data: any[]) =>
    data
      // Remove undefined entries in the array
      .filter((item) => item)
      // eslint-disable-next-line complexity
      .map((product, index) => {
        return {
          id: product.id,
          workspaceName: get(product, 'producer.name', ''),
          drag: (
            <div>
              {index !== 0 ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    swapIndicies(index - 1, index);
                    forceUpdate();
                  }}
                >
                  <KeyboardArrowUpIcon fontSize="inherit" />
                </IconButton>
              ) : (
                <NoArrowIcon />
              )}
              {index !== data.length - 1 ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    swapIndicies(index, index + 1);
                    forceUpdate();
                  }}
                >
                  <KeyboardArrowDownIcon fontSize="inherit" />
                </IconButton>
              ) : (
                <NoArrowIcon />
              )}
            </div>
          ),
          name: (
            <Link
              key={product.productId}
              // className={`restore-${product.id}`}
              to={{
                pathname: formatPath(PRODUCT, {
                  productId: product.productId ? product.productId : product.id,
                }),
                state: product.id,
              }}
            >
              {product.name}
            </Link>
          ),
          productClass: productClassFormatter(product),
          public: yesNoFormatter(!!product.public),
          aroma: yesNoFormatter(!!product.aroma),
          blindLabel: props.editable ? (
            <input
              type="text"
              onChange={(e) => props.setBlindLabel(e.target.value, product.id)}
              value={props.blindLabels[product.id] || ''}
              className={styles.blindLabelInput}
              placeholder="Blind Label"
            />
          ) : (
            <span>{props.blindLabels[product.id] || ''}</span>
          ),
          servingVessel: props.editable ? (
            <select
              style={{ width: '100%' }}
              value={props.servingVessels[product.id] || ''}
              onChange={(e) =>
                props.setServingVessel(e.target.value, product.id)
              }
            >
              {svRenderoptions}
            </select>
          ) : (
            <span>{props.servingVessels[product.id] || ''}</span>
          ),

          behavioralQuestions: props.editable ? (
            <Checkbox
              color="secondary"
              onChange={
                product &&
                product.selectedProductQuestions &&
                product.selectedProductQuestions.totalCount != null &&
                product.selectedProductQuestions.totalCount === 0
                  ? () => setShowModalBehavior(true)
                  : (e) =>
                      props.setBehavioralQuestions(e.target.checked, product.id)
              }
              disableRipple={showModalBehavior}
              value={props.behavioralQuestions[product.id] || false}
              checked={props.behavioralQuestions[product.id] || false}
            />
          ) : (
            yesNoFormatter(props.behavioralQuestions[product.id])
          ),
          clientName: props.editable ? (
            <input
              type="text"
              onChange={(e) => props.setClientName(e.target.value, product.id)}
              value={props.clientNames[product.id] || ''}
              className={styles.blindLabelInput}
              placeholder="Client Name"
            />
          ) : (
            <span>{props.clientNames[product.id] || ''}</span>
          ),

          projectName: props.editable ? (
            <input
              type="text"
              onChange={(e) => props.setProjectName(e.target.value, product.id)}
              value={props.projectNames[product.id] || ''}
              className={styles.blindLabelInput}
              placeholder="Project Name"
            />
          ) : (
            <span>{props.projectNames[product.id] || ''}</span>
          ),

          totalCost: props.editable ? (
            <input
              type="text"
              onChange={(e) => props.setTotalCost(e.target.value, product.id)}
              value={props.totalCosts[product.id] || ''}
              className={styles.blindLabelInput}
              placeholder="Total Cost"
            />
          ) : (
            <span>{props.totalCosts[product.id] || ''}</span>
          ),
          productionDate: props.editable ? (
            <DatePicker
              value={get(props.productionDates, `${product.id}`, null)}
              onChange={(date) => props.setProductionDate(date, product.id)}
              style={{ fontSize: '10' }}
              format="MM/dd/yyyy"
              placeholder=""
              disableFuture
              disablePast={false}
            />
          ) : (
            <span>
              {props.productionDates[product.id]
                ? moment(props.productionDates[product.id]).format('LL')
                : ''}
            </span>
          ),
          expirationDate: props.editable ? (
            <DatePicker
              value={get(props.expirationDates, `${product.id}`, null)}
              onChange={(date) => props.setExpirationDate(date, product.id)}
              format="MM/dd/yyyy"
              placeholder=""
              disablePast
            />
          ) : (
            <span>
              {props.expirationDates[product.id]
                ? moment(props.expirationDates[product.id]).format('LL')
                : ''}
            </span>
          ),
          action: (
            <button
              onClick={
                editing &&
                product &&
                product.productReviews &&
                product.productReviews.totalCount &&
                product.productReviews.totalCount > 0
                  ? () => setShowModalReviewed(true)
                  : () => props.onClickRow(product)
              }
              className={styles.removeBtn}
            >
              <i className="fas fa-times" />
            </button>
          ),
        };
      });

  return (
    <div>
      {props.data && props.data.length ? (
        // <BootstrapTable keyField="id" data={products} columns={columns} rowEvents={rowEvents} />
        <DataTable
          columns={columns}
          data={getData(data)}
          fixedHeader
          striped
          pagination
          paginationIconFirstPage={<RDTFirstPage />}
          paginationIconLastPage={<RDTLastPage />}
          paginationIconPrevious={<RDTPrevPage />}
          paginationIconNext={<RTDNextPage />}
        />
      ) : null}
      <WarningModal
        open={showModalReviewed}
        handleClose={() => closeModalReviewed()}
        message={t('warningModal.warning')}
      />
      <WarningModal
        open={showModalBehavior}
        handleClose={() => closeModalBehavior()}
        message={t('panel.panelNoBehavioralQuestions')}
      />
    </div>
  );
};

export default PanelProductTable;
