import ggVars from 'constants/ggVars';

export function totalFlagsForReviews(
  reviews: dataquality.PanelQualityData[],
): number {
  return reviews
    .map((review) => totalFlagsForReview(review))
    .reduce((a, b) => a + b, 0);
}

export function totalFlagsForReview(
  review: dataquality.PanelQualityData,
): number {
  return Object.values(review.flags).filter(Boolean).length;
}

export function getPanelQualityData(
  qualityData: dataquality.PanelDataQualityQueryResponse,
) {
  if (qualityData.loading) return [];
  if (qualityData.error) return [];

  return qualityData.allDataQualityViews.edges.map((d) => {
    const userReviews = qualityData.allDataQualityViews.edges
      .map((e) => e.node)
      .filter((n) => n.userId === d.node.userId);

    const review = d.node;
    const ggvarfields = ggVars.map((ggVar) => ggVar.key).sort();
    const rf = JSON.parse(review.referenceFlavors);
    const textures: any[] = JSON.parse(review.textures);
    const rfcount: number = Object.values(rf).reduce(
      (a: number, b: any[]) => a + b.length,
      0,
    ) as number;
    const durationMs =
      Date.parse(review.endTime) - Date.parse(review.startTime);

    return {
      ...review,
      ageRange: null,
      durationMs: durationMs,
      referenceFlavorsParsed: rf,
      textures: textures,
      numggvar: ggvarfields.filter((d) => !!review[d]).length,
      rfcount,
      flags: {
        allggvar: ggvarfields.filter((d) => !review[d]).length == 0,
        insufficientggvar: ggvarfields.filter((d) => !!review[d]).length <= 3,
        allmarksatfive:
          ggvarfields.filter((d) => !!review[d]).filter((d) => review[d] != 5)
            .length == 0,
        excessiverefflavor: rfcount > 25,
        norefflavor: rfcount == 0,
        excessiveggvar: ggvarfields.filter((d) => !!review[d]).length > 15,
        excessivetexture: textures.length > 15,
        zeroMouthfeelIntensity: !review.mouthfeel || review.mouthfeel === 0,
        tooManySevens:
          review.perceivedQuality === 7 &&
          userReviews.find((r) => r.perceivedQuality === 7)?.productId !==
            review.productId,
      },
    };
  });
}
