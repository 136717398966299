import * as React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import FlavorDecompositionTable from 'components/Report/FlavorDecomposition/FlavorDecompositionTable';
import ReportOfpDescription from 'components/Report/ReportOfpDescription';
import ReportProductDetails from 'components/Report/ReportProductDetails';
import useCustomProductNames from 'hooks/useCustomProductNames';

interface Props {
  reportId: string;
}

const ReportDashboardProducts: React.FC<Props> = (props) => {
  const { reportId } = props;

  const productNames = useCustomProductNames({ reportId });

  return (
    <Grid key={`report-dashboard-product-${reportId}`} container>
      {productNames.map((productInfo) => (
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {productInfo.name}{' '}
              {productInfo.version ? `| ${productInfo.version}` : ''}
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                key={`report-dashboard-product-${reportId}-${productInfo.id}-${productInfo.version}`}
                container
              >
                <Grid item xs={12}>
                  <ReportProductDetails
                    reportId={reportId}
                    productId={productInfo.id}
                    productVersion={productInfo.version}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReportOfpDescription
                    reportId={reportId}
                    productId={productInfo.id}
                    productVersion={productInfo.version}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FlavorDecompositionTable
                    projectId={null}
                    reportId={reportId}
                    productId={productInfo.id}
                    version={productInfo.version}
                    forceNoTuning={true}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
};

export default ReportDashboardProducts;
