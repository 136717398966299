import pako from 'pako';

import { MARKET_SURVEY, OPTIMIZATION } from '../../constants/report';

/**
 * Make sure all required fields are completed before allowing continues
 *
 * @param activeStep
 * @param report_type
 * @param competitive_set_rich
 * @param competitive_set_folders
 * @param formValues
 * @returns
 */
export const invalidFields = (
  activeStep: number,
  report_type: string,
  competitive_set_rich: any[],
  competitive_set_folders: any[],
  formValues: any,
) => {
  if (activeStep === 2)
    return invalidStepTwoFields(
      report_type,
      competitive_set_rich,
      competitive_set_folders,
      formValues,
    );

  return false;
};

/**
 * Return true if any part of activeStep 2 is invalid
 *
 * @param report_type
 * @param competitive_set_rich
 * @param competitive_set_folders
 * @param formValues
 * @returns
 */
const invalidStepTwoFields = (
  report_type: string,
  competitive_set_rich: any[],
  competitive_set_folders: any[],
  formValues: any,
) => {
  // If a market survey order
  if (report_type === MARKET_SURVEY) {
    // Need to have products and folders set
    return (
      competitive_set_rich.length == 0 || competitive_set_folders.length == 0
    );
  }

  if (report_type === OPTIMIZATION) {
    // Need to select a product to optimize
    return !formValues.optimization.products_to_opt?.value;
  }
};

/**
 * Returns a base 64 encoded gzipped string
 *
 * @param conch_params
 * @returns
 * */
export const compressParams = (conch_params: string) => {
  const compressed = pako.gzip(conch_params);
  const base64str = btoa(String.fromCharCode.apply(null, compressed));
  return base64str;
};

/**
 * Base 64 decodes and gzip decompresses the conch parameters
 * @param b64str
 * @returns
 * */
export const decompressParams = (b64str: string) => {
  const compressedBytes = Uint8Array.from(atob(b64str), (x) =>
    x.codePointAt(0),
  );
  const decompressed = pako.ungzip(compressedBytes, { to: 'string' });
  return decompressed;
};
