import * as React from 'react';

import { SvgIcon } from '@mui/material';

// Generated with https://react-svgr.com/playground/
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1600}
    height={1600}
    viewBox="0 0 1200 1200"
    {...props}
  >
    <path d="M688.71 964.21V795.34H582.23v168.87H464.61l172.88 204.02 172.88-204.02zM449.96 496.43c-7.629 0-12.801.738-15.508 1.477v48.977c3.2.738 7.137.984 12.555.984 19.934 0 32.238-10.094 32.238-27.07 0-15.258-10.578-24.367-29.285-24.367z" />
    <path d="M976.59 369.96v-90.73c0-4.758-1.992-9.586-5.328-12.977l-221.06-223.88c-2.879-3.602-8.707-5.484-13.133-5.484h-422.75c-10.195 0-18.461 8.265-18.461 18.46v314.6h-58v366.59h58v155.27c0 10.204 8.265 18.462 18.46 18.462h237.14v-36.922l-218.68.004v-136.81h606.89v136.81h-220.18v36.922h238.64c10.196 0 18.462-8.258 18.462-18.461v-155.27h56.05v-366.59zM755.53 100.3l158.39 160.47H755.53zM501.15 559.2c-12.801 12.055-31.746 17.473-53.895 17.473-4.922 0-9.355-.246-12.801-.738v59.312H397.29v-163.67c11.562-1.97 27.809-3.446 50.703-3.446 23.133 0 39.617 4.43 50.695 13.293 10.586 8.364 17.719 22.148 17.719 38.395-.004 16.246-5.406 30.023-15.258 39.38zm188.8 76.047h-39.379l-35.441-63.988c-9.848-17.719-20.676-39.133-28.801-58.574l-.738.246c.984 21.902 1.476 45.281 1.476 72.355v49.965H552.61l.008-165.89h43.81l34.46 60.79c9.848 17.472 19.688 38.147 27.07 56.847h.73c-2.456-21.902-3.199-44.3-3.199-69.156v-48.484h34.461zm183.38-7.637c-11.57 3.946-33.465 9.356-55.371 9.356-30.27 0-52.18-7.625-67.434-22.395-15.254-14.277-23.629-35.934-23.383-60.297.246-55.125 40.363-86.633 94.75-86.633 21.41 0 37.902 4.183 46.023 8.125l-7.871 30.023c-9.102-3.938-20.426-7.137-38.641-7.137-31.254 0-54.88 17.72-54.88 53.656 0 34.211 21.41 54.395 52.18 54.395 8.61 0 15.509-.984 18.458-2.46v-34.704h-25.594v-29.285h61.773l-.004 87.355zm66.332-257.65h-606.89V73.82h385.83v205.4c0 10.195 8.266 18.461 18.461 18.461h202.6z" />
  </svg>
);

const PngFileIcon = (props) => (
  <SvgIcon component={SvgComponent} viewBox="0 0 1200 1200" {...props} />
);

export default PngFileIcon;
