import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import * as Sentry from '@sentry/react';

import appToastAdd from '../actions/appToastAdd';
import errorAction from '../actions/error';
import sessionClearAction from '../actions/sessionClear';
import store from '../constants/store';
import selectSessionToken from '../selectors/sessionToken';
import getGraphQLRequestHeaders from '../utils/getGraphQLRequestHeaders';

const httpLink = createHttpLink({
  uri: `/graphql`,
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 30000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const middlewareLink = new ApolloLink((operation, forward) => {
  const storeState = store.getState();
  const sessionToken = selectSessionToken(storeState);

  if (!sessionToken) return;

  operation.setContext({
    headers: {
      ...getGraphQLRequestHeaders(sessionToken),
    },
  });

  return forward(operation);
});

const errorLink = onError(({ networkError = {}, graphQLErrors, operation }) => {
  if (
    graphQLErrors &&
    graphQLErrors[0].message === 'TokenExpiredError: jwt expired'
  ) {
    store.dispatch(sessionClearAction());
    return;
  }

  // Handle Error - appToast for DEV, Sentry for PROD
  if (Array.isArray(graphQLErrors)) {
    Array.from(graphQLErrors).map((error, i) => {
      if (process.env.NODE_ENV !== 'development') {
        const scope = new Sentry.Scope();
        scope.setTag('operation', operation?.operationName);
        scope.setTag('variables', JSON.stringify(operation?.variables));
        scope.setFingerprint([
          '{{ default }}',
          String(error?.message),
          String(operation?.operationName),
        ]);

        Sentry.captureException(new Error(error?.message), scope);
      } else {
        console.error('operation', operation.operationName);
        console.error('variables', JSON.stringify(operation.variables));

        store.dispatch(
          appToastAdd({
            durationMilliseconds: 4000,
            message: error?.message,
            title: 'GRAPHQL_ERROR',
            toastKey: `error_${i}_${Date.now()}`,
          }),
        );
      }
    });
  }

  store.dispatch(
    errorAction({
      error: graphQLErrors,
      title: 'GraphQL Error',
      description: String(graphQLErrors),
    }),
  );
});

const link = middlewareLink
  .concat(retryLink)
  .concat(errorLink)
  .concat(httpLink);

export default new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
