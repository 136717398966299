import { gql } from '@apollo/client';

export interface ReportNoveltyIntensityPqResponse
  extends reports.ReportGraphQLReponse {
  allRpNoveltyIntensityPqs: {
    nodes: ReportNoveltyIntesityPq[];
  };
}

export interface ReportNoveltyIntesityPq {
  id: number;
  reportId: string;
  productByProductId: {
    id: number;
    name: string;
  };
  noveltyScore: number;
  pq: number;
  originalIntensity: number;
  version: string;
}

export default gql`
  query NoveltyIntensityPqByReportId($reportID: UUID!) {
    allRpNoveltyIntensityPqs(condition: { reportId: $reportID }) {
      nodes {
        productByProductId {
          id
          name
        }
        noveltyScore
        pq
        originalIntensity
        version
      }
    }
  }
`;
