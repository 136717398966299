import * as React from 'react';
import { SVGProps } from 'react';

import { SvgIcon } from '@mui/material';

const SvgComponent: React.FC = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1600}
    height={1600}
    viewBox="0 0 1200 1200"
    {...props}
  >
    <path d="M688.71 964.21V795.34H582.23v168.87H464.61l172.88 204.02 172.88-204.02z" />
    <path d="M976.59 369.96v-90.73c0-4.758-1.992-9.586-5.328-12.977l-221.06-223.88c-2.879-3.602-8.707-5.484-13.133-5.484h-422.75c-10.195 0-18.461 8.265-18.461 18.46v314.6h-58v366.59h58v155.27c0 10.204 8.265 18.462 18.46 18.462h237.14v-36.922l-218.68.004v-136.81h606.89v136.81h-220.18v36.922h238.64c10.196 0 18.462-8.258 18.462-18.461v-155.27h56.05v-366.59zM755.53 100.3l158.39 160.47H755.53zM504.6 606.7c11.324 0 23.871-2.46 31.262-5.414l5.656 29.285c-6.89 3.445-22.395 7.137-42.574 7.137-57.348 0-86.879-35.688-86.879-82.941 0-56.61 40.363-88.11 90.566-88.11 19.445 0 34.211 3.938 40.855 7.383l-7.629 29.777c-7.629-3.199-18.215-6.152-31.5-6.152-29.777 0-52.918 17.97-52.918 54.887.004 33.227 19.691 54.148 53.16 54.148zm114.47 31.008c-18.949 0-37.656-4.93-47.008-10.098l7.629-31.008c10.094 5.168 25.602 10.332 41.594 10.332 17.23 0 26.332-7.133 26.332-17.965 0-10.34-7.875-16.246-27.816-23.379-27.562-9.602-45.531-24.863-45.531-48.977 0-28.301 23.625-49.965 62.762-49.965 18.703 0 32.484 3.937 42.332 8.371L671 505.29c-6.645-3.2-18.461-7.875-34.707-7.875-16.246 0-24.117 7.383-24.117 16 0 10.586 9.355 15.262 30.762 23.383 29.285 10.824 43.07 26.086 43.07 49.473.011 27.81-21.406 51.438-66.938 51.438zm187.56-2.46h-43.805l-53.164-165.88h41.102l20.18 70.14c5.66 19.691 10.832 38.641 14.762 59.316h.738c4.184-19.938 9.356-39.625 15.008-58.578l21.164-70.879h39.871zm133.03-265.29H332.77V73.817H718.6v205.4c0 10.194 8.266 18.46 18.461 18.46h202.6z" />
  </svg>
);

const CsvFileIcon = (props) => (
  <SvgIcon component={SvgComponent} viewBox="0 0 1200 1200" {...props} />
);

export default CsvFileIcon;
