import * as React from 'react';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './FlavorDecompositionComparisonTable.module.css';
import { cleanUnderscore } from '../../../../utils/afsUtils';
import { decompSumFn } from '../utils';

interface Props {
  ggVar: string;
  flavorComparisons: reports.FlavorDecompValues[];
  firstProductDisplayName: string;
  secondProductDisplayName: string;
}

type order = 'asc' | 'desc';
type ordered_column = keyof Pick<
  reports.FlavorDecompValues,
  'absDecomp' | 'sabsDecomp' | 'absDeltaDecomp'
>;

const FlavorCompositionTable: React.FC<Props> = (props) => {
  const {
    ggVar,
    flavorComparisons,
    firstProductDisplayName,
    secondProductDisplayName,
  } = props;

  const { t } = useTranslation();
  const [orderBy, setOrderBy] = React.useState<ordered_column>('absDecomp');
  const [order, setOrder] = React.useState<order>('desc');

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: ordered_column,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler =
    (property: ordered_column) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const [
    decompSum,
    absDecompSum,
    sdecompSum,
    sabsDecompSum,
    deltaDecompSum,
    deltaAbsDecompSum,
  ] = React.useMemo(() => {
    const decompSum: number = decompSumFn(flavorComparisons, (fc) => fc.decomp);
    const absDecompSum: number = decompSumFn(
      flavorComparisons,
      (fc) => fc.absDecomp,
    );
    const sdecompSum: number = decompSumFn(
      flavorComparisons,
      (fc) => fc.sdecomp,
    );
    const sabsDecompSum: number = decompSumFn(
      flavorComparisons,
      (fc) => fc.sabsDecomp,
    );
    const deltaDecompSum: number = sdecompSum - decompSum;
    const deltaAbsDecompSum: number = sabsDecompSum - absDecompSum;
    return [
      decompSum,
      absDecompSum,
      sdecompSum,
      sabsDecompSum,
      deltaDecompSum,
      deltaAbsDecompSum,
    ];
  }, [flavorComparisons]);

  const comparator = (
    a: reports.FlavorDecompValues,
    b: reports.FlavorDecompValues,
  ): 1 | -1 | 0 => {
    if (a[orderBy] > b[orderBy]) {
      return 1;
    }
    if (a[orderBy] < b[orderBy]) {
      return -1;
    }
    return 0;
  };

  const sortedFlavorComparisons = React.useMemo(() => {
    if (order === 'asc') {
      return flavorComparisons.sort(comparator);
    }
    if (order === 'desc') {
      return flavorComparisons.sort(comparator).reverse();
    }
    return flavorComparisons;
  }, [order, orderBy]);

  return (
    <Table>
      <TableHead>
        <TableRow className={styles.tableTopHeader}>
          <TableCell colSpan={4} align="center">
            <div className={styles.mainHeader}>
              <span className={styles.title}>{ggVar}</span>
              <span className={styles.title}>{` - ${(decompSum * 100).toFixed(
                0,
              )}% `}</span>
              <span className={styles.title}>{`(${absDecompSum.toFixed(
                2,
              )})`}</span>
              <span className={styles.title}>{` | ${(sdecompSum * 100).toFixed(
                0,
              )}% `}</span>
              <span className={styles.title}>{`(${sabsDecompSum.toFixed(
                2,
              )})`}</span>
              <span className={styles.title}>{` | ${(
                deltaDecompSum * 100
              ).toFixed(0)}% `}</span>
              <span className={styles.title}>{`(${deltaAbsDecompSum.toFixed(
                2,
              )})`}</span>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <span className={styles.subTitle}>
              {t('projects.comparison.flavorComposition.referenceFlavor')}
            </span>
          </TableCell>
          <TableCell
            align="right"
            variant="head"
            sortDirection={orderBy === 'absDecomp' ? order : false}
          >
            <TableSortLabel
              active={orderBy === 'absDecomp'}
              direction={orderBy === 'absDecomp' ? order : 'asc'}
              onClick={createSortHandler('absDecomp')}
            >
              <span className={styles.subTitle}>
                <Tooltip title={firstProductDisplayName}>
                  <div style={{ display: 'inline-block' }}>Product 1&nbsp;</div>
                </Tooltip>
              </span>
            </TableSortLabel>
          </TableCell>
          <TableCell
            align="right"
            sortDirection={orderBy === 'sabsDecomp' ? order : false}
          >
            <TableSortLabel
              active={orderBy === 'sabsDecomp'}
              direction={orderBy === 'sabsDecomp' ? order : 'asc'}
              onClick={createSortHandler('sabsDecomp')}
            >
              <span className={styles.subTitle}>
                <Tooltip title={secondProductDisplayName}>
                  <div style={{ display: 'inline-block' }}>Product 2&nbsp;</div>
                </Tooltip>
              </span>
            </TableSortLabel>
          </TableCell>
          <TableCell
            align="right"
            sortDirection={orderBy === 'absDeltaDecomp' ? order : false}
          >
            <TableSortLabel
              active={orderBy === 'absDeltaDecomp'}
              direction={orderBy === 'absDeltaDecomp' ? order : 'asc'}
              onClick={createSortHandler('absDeltaDecomp')}
            >
              <span className={styles.subTitle}>
                {t('projects.comparison.flavorComposition.delta')}
              </span>
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      {
        <TableBody>
          {sortedFlavorComparisons.map((row) => (
            <TableRow key={row.mergeId}>
              <TableCell align="left">
                {cleanUnderscore(row.referenceFlavor)}
              </TableCell>
              <TableCell align="center">{`${(row.decomp * 100).toFixed(
                0,
              )}% (${row.absDecomp.toFixed(2)})`}</TableCell>
              <TableCell align="center">{`${(row.sdecomp * 100).toFixed(
                0,
              )}% (${row.sabsDecomp.toFixed(2)})`}</TableCell>
              <TableCell align="center">{`${(
                (row.sdecomp - row.decomp) *
                100
              ).toFixed(1)}% (${(row.sabsDecomp - row.absDecomp).toFixed(
                2,
              )})`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      }
    </Table>
  );
};

export default FlavorCompositionTable;
