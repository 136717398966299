import * as React from 'react';

import { ResponsiveLine } from '@nivo/line';
import * as d3 from 'd3-shape';
import { useTranslation } from 'react-i18next';

import {
  interpolateNivoSerieses,
  interpPoint,
  mapRowToChartData,
  smoothNivoSeries,
} from '../../../../services/utils/tuningPlotUtils';

interface ChartProps {
  ggVarTuningData: reports.GgVarTuningPlotRow[];
  ggVar: constants.GGVar;
  minPq?: number;
  maxPq?: number;
}

const chartColor = '#00305d';

const circle =
  (currentIntensity: number) =>
  ({ data, points, ...info }) => {
    const middlePoint = interpPoint(points, currentIntensity);

    if (!middlePoint) return;

    return (
      <path
        d={d3.symbol(d3.symbolCircle)()}
        transform={`translate(${middlePoint.x} ${middlePoint.y})`}
        color={chartColor}
        fill={chartColor}
      />
    );
  };

const GgVarFlavorIntensityPqChart: React.FC<ChartProps> = (props) => {
  const { ggVarTuningData, ggVar, minPq, maxPq } = props;

  const { t } = useTranslation();

  const chartData = ggVarTuningData
    .map(mapRowToChartData)
    .map(smoothNivoSeries);

  const currentIntensity = ggVarTuningData.find(Boolean).currentIntensity;
  const currentIntensityData = interpolateNivoSerieses(
    chartData,
    currentIntensity,
  ).find(Boolean);

  return (
    <div
      key={
        ggVarTuningData.find(Boolean).reportId +
        ggVarTuningData.find(Boolean).productId?.toString() +
        ggVar.key
      }
      style={{ height: '200px', maxWidth: '630px' }}
    >
      <ResponsiveLine
        data={chartData}
        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
        curve="natural" // Make the curve
        // Horizontal line at current level
        markers={[
          {
            axis: 'y', // axis the marker should be perpendicular to
            value: currentIntensityData?.y, // value of the marker in axis
            lineStyle: {
              stroke: 'red',
              strokeWidth: 1,
              strokeDasharray: '12, 6', // Make line dashed
            },
          },
        ]}
        xScale={{ type: 'linear', min: -0.1, max: 5.1 }}
        yScale={{
          type: 'linear',
          min: minPq || 'auto',
          max: maxPq || 'auto',
        }}
        xFormat=" >-.1f"
        yFormat=" >-.1f"
        axisTop={null}
        axisRight={null}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'points',
          'slices',
          'mesh',
          'legends',
          circle(currentIntensity),
        ]}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: `${t('reports.flavorIntensityPqChart.intensityOf')} ${
            ggVar.label
          }`,
          legendOffset: 36,
          legendPosition: 'middle',
          tickValues: 6,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t('reports.flavorIntensityPqChart.pq'),
          legendOffset: -40,
          legendPosition: 'middle',
          tickValues: 5,
        }}
        colors={[chartColor]}
        enablePoints={false}
        pointSize={6}
        pointColor={{ from: 'color' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </div>
  );
};

export default GgVarFlavorIntensityPqChart;
