import { gql } from '@apollo/client';

export interface ReferenceFlavorTranslationsByReferenceFlavorId {
  id: number;
  referenceFlavorId: number;
  translation: string;
  language: string;
}

export interface WorkspaceReferenceFlavorForUpdate {
  id: number;
  name: string;
  flavorAttribute: string;
  visible: boolean;
  deleted: boolean;
  referenceFlavorTranslationsByReferenceFlavorId: {
    nodes: ReferenceFlavorTranslationsByReferenceFlavorId[];
  };
}

export interface GetReferenceFlavorForUpdateResponse {
  loading: boolean;
  error: any;
  workspaceReferenceFlavorForUpdate: {
    nodes: WorkspaceReferenceFlavorForUpdate[];
  };
}

export default gql`
  query GetReferenceFlavorForUpdateQuery {
    workspaceReferenceFlavorForUpdate: allReferenceFlavorNews {
      nodes {
        id
        name
        flavorAttribute
        visible
        deleted
        referenceFlavorTranslationsByReferenceFlavorId {
          nodes {
            id
            referenceFlavorId
            translation
            language
          }
        }
      }
    }
  }
`;
