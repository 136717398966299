import * as React from 'react';

import { SvgIcon } from '@mui/material';

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    {...props}
  >
    <defs id="defs1" />
    <path
      d="M 54.723207,48.073418 H 52.335865 V 18.696202 a 0.79578059,0.81603375 0 0 0 -0.79578,-0.816033 H 45.96962 V 7.2717299 A 0.79578059,0.81603375 0 0 0 45.173839,6.4556962 H 18.91308 A 0.79578059,0.81603375 0 0 0 18.1173,7.2717299 V 25.224472 h -2.387342 a 0.79578059,0.81603375 0 0 0 -0.795781,0.816034 v 8.160337 h -2.387341 a 0.79578059,0.81603375 0 0 0 -0.795781,0.816035 V 53.785653 H 9.3637126 V 6.4556962 H 7.7721519 V 54.601688 a 0.79578059,0.81603375 0 0 0 0.7957806,0.816033 H 55.518987 v -6.52827 a 0.79578059,0.81603375 0 0 0 -0.79578,-0.816033 z m -3.978903,0 h -2.387342 a 0.79578059,0.81603375 0 0 0 -0.795781,0.816033 v 4.896202 H 42.786498 V 28.488608 a 0.79578059,0.81603375 0 0 0 -0.79578,-0.816034 h -2.387342 v -8.160338 h 11.140928 z m -12.73249,-3.264136 v 8.976371 h -4.774683 v -8.976371 z m 0.795781,-1.632067 H 36.420253 V 29.304641 h 4.774684 V 53.785653 H 39.603376 V 43.993249 A 0.79578059,0.81603375 0 0 0 38.807595,43.177215 Z M 19.708861,8.0877637 h 24.669198 v 9.7924053 h -5.570464 a 0.79578059,0.81603375 0 0 0 -0.795781,0.816033 v 8.976372 h -2.387342 a 0.79578059,0.81603375 0 0 0 -0.79578,0.816034 v 14.688607 h -2.387341 a 0.79578059,0.81603375 0 0 0 -0.795781,0.816034 v 9.792404 H 22.891984 V 26.040506 A 0.79578059,0.81603375 0 0 0 22.096203,25.224472 H 19.708861 Z M 16.525738,26.85654 h 4.774684 V 53.785653 H 19.708861 V 35.016878 A 0.79578059,0.81603375 0 0 0 18.91308,34.200843 h -2.387342 z m -3.183122,8.976371 H 18.1173 v 17.952742 h -4.774684 z m 35.810127,17.952742 v -4.080168 h 4.774684 v 4.080168 z"
      id="path1"
      strokeWidth="0.805844"
      fill="black"
    />
    {/*
    <rect
      id="rect1"
      fill="white"
      width="4.3544302"
      height="62.075951"
      x="7.5949373"
      y="0"
    />
    <rect
      id="rect2"
      fill="white"
      width="63.088608"
      height="3.2405064"
      x="0"
      y="53.772152"
    />
    */}
  </svg>
);

const FlavorStructureDiagramIcon = (props) => (
  <SvgIcon component={SvgComponent} viewBox="0 0 64 64" {...props} />
);

export default FlavorStructureDiagramIcon;
