import { gql } from '@apollo/client';

export default gql`
  query TextureTernaryByReportId($reportID: UUID!) {
    allRpTextureTernaryHeatmaps(condition: {reportId: $reportID} ) {
      nodes {
        clusterCombo
        idLabel
        lpoints
        rpoints
        tpoints
        pq
        pqRaw
      }
    }
  }
`