import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import OrderOfOperationsCountQuery from '@graphql/queries/OrderOfOperationsCountQuery';
import GetProjectReports from '@graphql/queries/ProjectReports';
import ReportRpGgvarFlavorProfileCount from '@graphql/queries/ReportRpGgvarFlavorProfileCount';
import ReportTextureCount from '@graphql/queries/ReportTextureCount';
import RpNoveltyIntensityCounts, {
  RpNoveltyIntensityCountsResponse,
} from '@graphql/queries/RpNoveltyIntensityCounts';
import RpProductTimeSeriesCounts, {
  RpProductTimeSeriesCountsResponse,
} from '@graphql/queries/RpProductTimeSeriesCounts';
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { MapOptions } from 'components/Report/Maps/MapOptions';
import {
  ProductVersion,
  ProductVersionSet,
} from 'components/Report/ProductVersion';
import ReportDownloadIcon from 'components/Report/ReportDownloadIcon';
import { isArchtypeReportId } from 'components/Report/utils';
import { formatReportLabel } from 'containers/Project/utils';
import { History } from 'history';
import useIsProd from 'hooks/useIsProd';
import useReportSummary from 'hooks/useReportSummary';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { Link } from 'react-router-dom';
import sessionTokenRoles from 'selectors/sessionTokenRoles';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';
import {
  useCustomerPreferences,
  projectTabOrder,
  getDefaultMapOptions,
  ProjectTabs,
  projectTabNames,
} from 'services/customerPreferences';

import styles from './ProjectSummary.module.css';
import ImpactDirectionByGgVar from './tabs/CategoryInsightsTab';
import ProjectDataDownload from './tabs/ProjectDataDownload';
import ProjectExecutiveSummary from './tabs/ProjectExecutiveSummary';
import ProjectFlavorSummary from './tabs/ProjectFlavorSummary';
import ProjectMapSummary from './tabs/ProjectMapSummary';
import ProjectNorms from './tabs/ProjectNorms';
import ProjectNoveltyAnalysisSummary from './tabs/ProjectNoveltyAnalysisSummary';
import ProjectOptimizationSummary from './tabs/ProjectOptimizationSummary';
import ProjectReportSummary from './tabs/ProjectReportSummary';
import ProjectTextureSummary from './tabs/ProjectTextureSummary';
import TrendAnalysis from './tabs/TrendAnalysis';
import useStyles from './useStyles';
import {
  showCategoryInsights,
  showDataDownload,
  showExecutiveSummary,
  showFlavor,
  showMap,
  showNorms,
  showNoveltyAnalysis,
  showOptimizationSummary,
  showTexture,
  showTrendAnalysis,
} from './utils';
import { showMapOnly } from './utils';
import LoadingScreen from '../../components/LoadingScreen';
import {
  PROJECTS,
  PROJECT_SUMMARY_WITH_REPORT,
} from '../../constants/routePaths';
import { PageHeader } from '../../styles/themeComponents';
import { nvlReportId } from '../../utils/afsUtils';
import formatPath from '../../utils/formatPath';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return <div>{value === index && children}</div>;
};

interface Props extends RouterProps {
  match: Record<string, any>;
  location: Record<string, any>;
  projectId: number;
  workspaceId: number;
}

interface TabConfig {
  id: number;
  label: string;
  component: React.ReactNode;
  showCondition: () => boolean;
}

// eslint-disable-next-line complexity
const ProjectSummary: React.FC<Props> = (props) => {
  const { match, history, location } = props;
  const projectId = match.params.projectId;

  const { t } = useTranslation();
  const isProd = useIsProd();

  const theme = useTheme();
  const classes = useStyles(theme);

  const workspaceId = useSelector((state) => selectWorkspaceProducerId(state));
  const userRoles = useSelector((state) => sessionTokenRoles(state));

  const { loading, error, data } = useQuery<reports.ProjectReport>(
    GetProjectReports,
    {
      variables: {
        projectID: parseInt(projectId),
      },
      fetchPolicy: 'no-cache',
    },
  );
  const [selectedReportId, setSelectedReportId] = useState<string>('');

  const [parentReportId, setParentReportId] = useState<string>('');
  const [selectedReportType, setSelectedReportType] = useState<string>('');
  const [projectReports, setProjectReports] = useState<
    reports.ProjectReportSummary[]
  >([]);
  const [flavorReports, setFlavorReports] = useState<
    reports.ReportProductInfo[]
  >([]);
  const [projectName, setProjectName] = useState<string>('');
  const [value, setValue] = useState(ProjectTabs.EXECUTIVE_SUMMARY);
  const [products, setProducts] = useState<ProductVersionSet>(
    new ProductVersionSet(),
  );
  const [product, setProduct] = useState<ProductVersion>(
    new ProductVersion(null, null),
  );
  const [excludedProducts, setExcludedProducts] = useState<ProductVersionSet>(
    new ProductVersionSet(),
  );

  const customerPreferences = useCustomerPreferences();

  const tabNames = projectTabNames(t);
  const tabOrder = projectTabOrder(customerPreferences);

  const [flavorMapOptions, setFlavorMapOptions] = useState<MapOptions>(
    getDefaultMapOptions(customerPreferences),
  );
  const [textureMapOptions, setTextureMapOptions] = useState<MapOptions>(
    getDefaultMapOptions(customerPreferences),
  );

  const [displayParentReport, setDisplayParentReport] = useState(false);

  const {
    data: reportFlavorCnt,
    loading: reportFlavorLoading,
    error: reportFlavorError,
  } = useQuery<reports.ReportFlavorCountsResponse>(
    ReportRpGgvarFlavorProfileCount,
    {
      variables: {
        reportID: nvlReportId(selectedReportId),
      },
    },
  );

  const { data: reportTextureCnt } =
    useQuery<reports.ReportTextureCountResponse>(ReportTextureCount, {
      variables: {
        reportID: nvlReportId(selectedReportId),
      },
    });

  const { data: optSummaryCnt } =
    useQuery<reports.ReportOrderOfOperationsCountResponse>(
      OrderOfOperationsCountQuery,
      {
        variables: {
          reportID: nvlReportId(selectedReportId),
        },
      },
    );

  const { data: noveltyCnt } = useQuery<RpNoveltyIntensityCountsResponse>(
    RpNoveltyIntensityCounts,
    {
      variables: {
        reportID: nvlReportId(selectedReportId),
      },
    },
  );

  const { data: trendAnalysisCnt } =
    useQuery<RpProductTimeSeriesCountsResponse>(RpProductTimeSeriesCounts, {
      variables: {
        reportID: nvlReportId(selectedReportId),
      },
    });

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    data: reportSummaryData,
  } = useReportSummary(nvlReportId(selectedReportId));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    browserHistory: History,
  ) => {
    setSelectedReportId(event.target.value);
    setExcludedProducts(new ProductVersionSet());
    browserHistory.push({
      pathname: formatPath(PROJECT_SUMMARY_WITH_REPORT, {
        projectId: projectId,
        reportId: event.target.value,
      }),
    });
  };

  useEffect(() => {
    if (location.state) {
      const productId = location?.state?.productId;
      const version = location?.state?.version;

      setValue(location?.state?.tabIdx ?? tabOrder[0]);

      setProduct(
        new ProductVersion(
          productId ? parseInt(productId) : productId,
          version,
        ),
      );
    } else {
      setValue(tabOrder[0]);
    }

    if (!loading && !error) {
      setProjectName(data.project.name);
      const reports = data.project.projectReportsByProjectId.nodes
        .map((item) => ({
          reportId: item.reportJob.reportId,
          reportType: item.reportJob.reportType,
          clientName: item.reportJob.clientName,
          passedQa: item.reportJob.passedQa,
          projectName: item.reportJob.projectName,
          startedAt: item.reportJob.startedAt,
          pdfEfsUri: item.reportJob.pdfEfsUri,
          jobId: item.reportJob.jobId,
          parentJobId: item.reportJob.parentJobId,
          targetGroupName: item.reportJob.targetGroupName,
          name: formatReportLabel(
            item.reportJob.projectName,
            item.reportJob.targetGroupName,
            item.reportJob.reportType,
            item.reportJob.startedAt,
          ),
        }))
        .filter((f) => f.passedQa);
      setProjectReports(reports);

      const reportsForFlavor: reports.ReportProductInfo[] =
        data.project.projectReportsByProjectId.nodes
          .filter((f) => f.reportJob.passedQa)
          .map((item) => ({
            reportId: item.reportJob.reportId,
            reportType: item.reportJob.reportType,
            name: formatReportLabel(
              item.reportJob.projectName,
              item.reportJob.targetGroupName,
              item.reportJob.reportType,
              item.reportJob.startedAt,
            ),
            products: item.reportJob.reportSummaries.nodes.map(
              (productItem) => {
                const customName =
                  data.project.customPqProductNamesByProjectId.nodes.find(
                    (f) => f.productId === productItem.productByProductId.id,
                  );
                return {
                  id: productItem.productByProductId.id,
                  canonicalName: productItem.productByProductId.name,
                  name: customName
                    ? customName.productName
                    : productItem.productByProductId.name,
                  version: productItem.version,
                };
              },
            ),
          }));
      setFlavorReports(reportsForFlavor);

      if (match.params.reportId) {
        setSelectedReportId(match.params.reportId);
      }
    }
  }, [loading]);

  useEffect(() => {
    setSelectedReportType(
      projectReports.find((r) => r.reportId === selectedReportId)?.reportType,
    );

    setParentReportId(
      projectReports.find(
        (r) =>
          r.jobId ===
          projectReports.find((r) => r.reportId === selectedReportId)
            ?.parentJobId,
      )?.reportId,
    );
  }, [projectReports, selectedReportId]);

  useEffect(() => {
    if (reportSummaryData) {
      const productsArray = reportSummaryData.allReportJobs.nodes
        .flatMap((n) => n.reportSummariesByReportId)
        .flatMap((e) => e.edges)
        .map((n) => n.node)
        .flatMap((r) => new ProductVersion(r.productByProductId.id, r.version));
      const productsSet = new ProductVersionSet();
      productsArray.forEach((p) => productsSet.add(p));
      setProducts(productsSet);
    }
  }, [selectedReportId, reportSummaryLoading]);

  useEffect(() => {
    return () => {
      history.listen(() => {
        window.scrollTo(0, 0);
      });
    };
  }, []);

  if (loading || reportSummaryLoading) {
    return <LoadingScreen />;
  }

  if (!showExecutiveSummary(showMapOnly(selectedReportId, reportSummaryData))) {
    setValue(ProjectTabs.PROJECT_SUMMARY);
  }
  const showTabs: () => boolean = () => !!selectedReportId;

  const tabConfigs: TabConfig[] = [
    {
      id: ProjectTabs.EXECUTIVE_SUMMARY,
      label: tabNames[ProjectTabs.EXECUTIVE_SUMMARY],
      component: (
        <ProjectExecutiveSummary
          projectId={parseInt(projectId)}
          projectReports={projectReports}
          selectedReportId={selectedReportId}
        />
      ),
      showCondition: () =>
        showExecutiveSummary(showMapOnly(selectedReportId, reportSummaryData)),
    },
    {
      id: ProjectTabs.PROJECT_SUMMARY,
      label: tabNames[ProjectTabs.PROJECT_SUMMARY],
      component: (
        <ProjectReportSummary
          projectId={parseInt(projectId)}
          projectReports={projectReports}
          selectedReportId={selectedReportId}
          parentReportId={parentReportId}
          excludedProducts={excludedProducts}
          setExcludedProducts={setExcludedProducts}
          flavorMapOptions={flavorMapOptions}
          setFlavorMapOptions={setFlavorMapOptions}
          displayParentReport={displayParentReport}
          setDisplayParentReport={setDisplayParentReport}
          mapOnly={showMapOnly(selectedReportId, reportSummaryData)}
        />
      ),
      showCondition: () => true,
    },
    {
      id: ProjectTabs.OPTIMIZATION_SUMMARY,
      label: tabNames[ProjectTabs.OPTIMIZATION_SUMMARY],
      component: (
        <ProjectOptimizationSummary
          reportId={selectedReportId}
          projectId={projectId}
        />
      ),
      showCondition: () =>
        showOptimizationSummary(
          isProd,
          showMapOnly(selectedReportId, reportSummaryData),
          selectedReportType,
          optSummaryCnt,
        ),
    },
    {
      id: ProjectTabs.FLAVOR_SUMMARY,
      label: tabNames[ProjectTabs.FLAVOR_SUMMARY],
      component: (
        <ProjectFlavorSummary
          projectId={parseInt(projectId)}
          projectReports={flavorReports}
          selectedReportId={selectedReportId}
          product={product}
        />
      ),
      showCondition: () =>
        showFlavor(
          showMapOnly(selectedReportId, reportSummaryData),
          reportFlavorCnt,
        ),
    },
    {
      id: ProjectTabs.TEXTURE_SUMMARY,
      label: tabNames[ProjectTabs.TEXTURE_SUMMARY],
      component: (
        <ProjectTextureSummary
          projectId={parseInt(projectId)}
          reportId={selectedReportId}
          excludedProducts={excludedProducts}
          parentReportId={parentReportId}
          textureMapOptions={textureMapOptions}
          setTextureMapOptions={setTextureMapOptions}
          displayParentReport={displayParentReport}
          setDisplayParentReport={setDisplayParentReport}
        />
      ),
      showCondition: () =>
        showTexture(
          userRoles,
          showMapOnly(selectedReportId, reportSummaryData),
          reportTextureCnt,
        ),
    },
    {
      id: ProjectTabs.DATA_DOWNLOAD,
      label: tabNames[ProjectTabs.DATA_DOWNLOAD],
      component: (
        <ProjectDataDownload
          projectId={projectId}
          reportId={selectedReportId}
          products={products}
        />
      ),
      showCondition: () =>
        showDataDownload(
          customerPreferences,
          userRoles,
          showMapOnly(selectedReportId, reportSummaryData),
        ),
    },
    {
      id: ProjectTabs.PROJECT_MAP,
      label: tabNames[ProjectTabs.PROJECT_MAP],
      component: (
        <ProjectMapSummary
          projectId={projectId}
          workspaceId={workspaceId}
          reportId={selectedReportId}
        />
      ),
      showCondition: () =>
        showMap(
          customerPreferences,
          showMapOnly(selectedReportId, reportSummaryData),
        ),
    },
    {
      id: ProjectTabs.NOVELTY_ANALYSIS,
      label: tabNames[ProjectTabs.NOVELTY_ANALYSIS],
      component: (
        <ProjectNoveltyAnalysisSummary
          projectId={projectId}
          workspaceId={workspaceId}
          reportId={selectedReportId}
        />
      ),
      showCondition: () =>
        showNoveltyAnalysis(
          showMapOnly(selectedReportId, reportSummaryData),
          workspaceId,
          noveltyCnt,
        ),
    },
    {
      id: ProjectTabs.CATEGORY_INSIGHTS,
      label: tabNames[ProjectTabs.CATEGORY_INSIGHTS],
      component: (
        <ImpactDirectionByGgVar
          projectId={projectId}
          reportId={selectedReportId}
        />
      ),
      showCondition: () => showCategoryInsights(isProd),
    },
    {
      id: ProjectTabs.TREND_ANALYSIS,
      label: tabNames[ProjectTabs.TREND_ANALYSIS],
      component: (
        <TrendAnalysis projectId={projectId} reportId={selectedReportId} />
      ),
      showCondition: () =>
        showTrendAnalysis(
          customerPreferences,
          showMapOnly(selectedReportId, reportSummaryData),
          trendAnalysisCnt,
        ),
    },
    {
      id: ProjectTabs.NORMS,
      label: tabNames[ProjectTabs.NORMS],
      component: <ProjectNorms reportId={selectedReportId} />,
      showCondition: () =>
        showNorms(
          customerPreferences,
          userRoles,
          showMapOnly(selectedReportId, reportSummaryData),
        ),
    },
  ];

  const visibleTabs = tabOrder
    .map((tabId) => tabConfigs.find((tab) => tab.id === tabId))
    .filter((tab) => tab && tab.showCondition());

  return (
    <div>
      <Paper className={styles.container}>
        <Grid container>
          <Grid item xs={12}>
            <Link to={PROJECTS}>
              <div className={styles.header}>
                <IconButton
                  onClick={history.goBack}
                  size="small"
                  style={{ marginLeft: -26 }}
                >
                  <KeyboardBackspaceIcon fontSize="small" />
                  <PageHeader>{t('projects.title')}</PageHeader>
                </IconButton>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.headerTextContainer}>
              <h6 className={styles.summarytHeader}>
                {t('projects.projectSummary.title')}
              </h6>
              <h4 className={styles.summaryTitle}>{projectName}</h4>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.selectView}>
              <div className={styles.select}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="report-id-select-label" shrink>
                    {t('projects.projectSummary.selectedReport')}
                  </InputLabel>
                  <Select
                    variant="standard"
                    id="report-id-select"
                    value={selectedReportId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e, history)
                    }
                  >
                    {projectReports.map((report) => (
                      <MenuItem key={report.reportId} value={report.reportId}>
                        {report.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {selectedReportId && !isArchtypeReportId(selectedReportId) && (
                <div className={styles.icon}>
                  {!showMapOnly(selectedReportId, reportSummaryData) && (
                    <ReportDownloadIcon reportId={selectedReportId} />
                  )}
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            {showTabs() && (
              <Box>
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: theme.palette.secondary.main,
                      },
                    }}
                  >
                    {visibleTabs.map((tab) => (
                      <Tab
                        key={tab.id}
                        className={classes.tab}
                        value={tab.id}
                        label={tab.label}
                      />
                    ))}
                  </Tabs>
                </Box>
                {visibleTabs.map((tab) => (
                  <TabPanel key={tab.id} value={value} index={tab.id}>
                    {tab.component}
                  </TabPanel>
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ProjectSummary;
