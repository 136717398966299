import { ERROR_CODE } from 'constants/errorCode';

import * as React from 'react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../../../components/Button';
import styles from '../../UserList.module.css';

const exclamationPoint = require('../../../../../public/assets/exclamationPoint.png');

interface Props {
  email: string;
  workspace: string;
  toggleDialog: any;
  errorCode: ERROR_CODE;
  resetAddUserForm: () => void;
  change: (field: string, value: any) => void;
  handleSubmit: (e: string) => void;
}

const UserInvalidModal: React.FC<Props> = (props) => {
  const { email, workspace, toggleDialog, errorCode, resetAddUserForm } = props;
  const { change, handleSubmit } = props;

  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(true);

  if (!visible) {
    return <div />;
  } else {
    return (
      <div className={styles.fade}>
        <div className={styles.modal}>
          <div className={styles.topBottomMargin}>
            <img
              src={exclamationPoint}
              className={styles.exclamationImage}
              alt="exclamation-point"
            />
          </div>
          <div className={styles.topBottomMargin}>
            {errorCode === ERROR_CODE.ExistingUser ? (
              <p className={styles.modalText}>
                {t('users.existingUserWarning', {
                  email,
                  workspace,
                })}
              </p>
            ) : (
              <p className={styles.modalText}>
                {t('users.newUserWarning', {
                  email,
                  workspace,
                })}
              </p>
            )}
          </div>
          {errorCode === ERROR_CODE.NewUser && (
            <div className={styles.topBottomMargin}>
              <p className={styles.modalText}>{t('users.invitationMessage')}</p>
            </div>
          )}
          <div className={styles.topBottomMargin}>
            <Button
              light
              onClick={() => {
                resetAddUserForm();
                setVisible(false);
                toggleDialog();
              }}
            >
              {t('general.cancel')}
            </Button>
            {errorCode === ERROR_CODE.NewUser && (
              <Button
                dark
                onClick={(e) => {
                  setVisible(false);
                  change('email', email);
                  handleSubmit(e);
                  resetAddUserForm();
                  toggleDialog();
                }}
              >
                {t('users.sendAnInvite')}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default UserInvalidModal;
