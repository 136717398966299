import { gql } from '@apollo/client';

export interface ReportNoveltyIntensityPqResponse
  extends reports.ReportGraphQLReponse {
  allRpNoveltyIntensityPqs: {
    nodes: ReportNoveltyIntensityPqRow[];
  };
}

export interface ReportNoveltyIntensityPqRow {
  id: number;
  reportId: string;
  productByProductId: reports.ProductInfo;
  noveltyScore: number;
  pq: number;
  originalIntensity: number;
  version: string;
}

export default gql`
  query NoveltyIntensityPqByReportIdAndProductIdQuery($reportID: UUID!) {
    allRpNoveltyIntensityPqs(
      orderBy: NOVELTY_SCORE_DESC
      condition: { reportId: $reportID }
    ) {
      nodes {
        id
        reportId
        productByProductId {
          id
          name
        }
        noveltyScore
        pq
        version
      }
    }
  }
`;
