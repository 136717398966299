import * as React from 'react';

import { useTheme } from '@mui/material';

import useMainStyles from './useStyles';

const ProgressBar = (props: any) => {
  const { useStyles } = useMainStyles({ ...props });
  const classes = useStyles(useTheme());

  return (
    <div {...props}>
      <div className={classes.value} />
      <div className={classes.maximum} />
    </div>
  );
};

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
