export const APP_ROOT = '/';
export const ADMIN = '/admin';
export const SUPERADMIN = '/superadmin';
export const SUPERADMIN_DATA_EXPLORER = '/superadmin/data-explorer';
export const SUPERADMIN_PRODUCT_FILTER = '/superadmin/product-filter';
export const SUPERADMIN_GROUP_WORKSPACES = '/superadmin/group-workspaces';
export const SUPERADMIN_EDIT_GROUP_WORKSPACE =
  '/superadmin/edit-group-workspace';
export const SUPERADMIN_EDIT_USER_ROLE = '/superadmin/edit-user-roles';
export const ADMIN_EDIT_USER_ROLE = '/admin/edit-user-roles';
export const AUTHENTICATION = '/sign-in';

export const SUPERADMIN_MANAGE_DEVICES = '/superadmin/manage-devices';
export const SUPERADMIN_ADD_DEVICES = `/admin/${SUPERADMIN_MANAGE_DEVICES}/add`;

export const BATCHES = '/batches';
export const BATCH = `${BATCHES}/:batchId`;
export const BATCH_PRODUCT_REVIEWS = `${BATCHES}/:batchId`;
export const BATCH_STATE_OVERVIEW = '/';

export const DASHBOARD = '/dashboard';
export const HOME = '/';
export const SELECT_WORKSPACE = '/select-workspace';

export const FOREIGN_DATA = '/foreign-data';

export const LOGS = '/logs';

export const PANELS = '/panels';
export const PANELS_EXPIRED = '/panels-expired';
export const PANEL_CREATE = '/panels/new';

export const PANEL = `${PANELS}/:panelId`;
export const PANEL_EDIT = `${PANEL}/edit`;
export const DATA_QUALITY_DASHBOARD = `${PANEL}/data-quality-dashboard`;
export const PANEL_PRODUCT_REVIEWS = `${PANEL}/product-reviews`;
export const PANEL_USERS = `${PANEL}/users`;
export const PANEL_SEARCH = `/panels-search/:query/:startTime/:endTime`;

export const PRODUCER_ROOT = '/';
export const PRODUCTS = '/products';
export const PRODUCT_CREATE = '/products/new';
export const PRODUCT_CREATE_BULK = '/products/new-bulk';
export const PRODUCT = `${PRODUCTS}/:productId`;
export const PRODUCT_BATCHES = `${PRODUCT}/batches`;
export const PRODUCT_PRODUCT_REVIEWS = `${PRODUCT}/product-reviews`;

export const REPORTS = '/admin/reports';
export const REPORT = `${REPORTS}/:reportType/:reportId`;
export const REQUEST_REPORT = '/admin/reports/new';
export const REQUEST_REPORT_UPDATE = '/admin/reports/update';
export const REPORTS_QA = '/admin/reports-qa';
export const REPORT_DASHBOARD = '/admin/report-dashboard/:reportId';

export const PRODUCT_REVIEWS = '/product-reviews';
export const PRODUCT_REVIEW = `${PRODUCT_REVIEWS}/:reviewId`;

export const SETTINGS = '/settings';

export const SUPPORT = '/support';

export const TRUSTED_ADVISOR = '/trusted-advisor';

export const USERS = '/users';
export const USER = `${USERS}/:userId`;
export const USER_PRODUCT_REVIEWS = `${USER}/product-reviews`;
export const SUPERADMIN_CREATE_USER = '/superadmin/create-user';
export const ADMIN_CREATE_USER = '/admin/create-user';
export const USERS_DEMOGRAPHIC = '/admin/users-demographic';

export const PANELISTS = '/panelists';

export const LANGUAGES = '/languages';
export const NOTIFICATION = '/notification';

export const CUSTOM_LEXICON = '/admin/custom-lexicon';

export const PROJECTS = '/projects';
export const PROJECT_CREATE = '/projects/new';
export const PROJECT = `${PROJECTS}/:projectId`;
export const PROJECT_EDIT = `${PROJECTS}/edit/:projectId`;

export const PROJECT_SUMMARY = `${PROJECTS}/summary`;
export const PROJECT_SUMMARY_BY_ID = `${PROJECT_SUMMARY}/:projectId`;
export const PROJECT_SUMMARY_WITH_REPORT = `${PROJECT_SUMMARY}/:projectId/:reportId`;

export const PROJECT_COMPARISON = `${PROJECTS}/comparison/:projectId`;
export const PROJECT_COMPARISON_WITH_PARAMS = `${PROJECTS}/comparison/:projectId/:firstReportId/:firstProductId/:firstProductVersion/:secondReportId/:secondProductId/:secondProductVersion`;

export const REPORT_PRODUCT_SUMMARY = `/productSummary/:projectId/:reportId/:productId/:version`;
export const PROJECT_FLAVOR_SUMMARY_CHART = `/flavorChart/:projectId/:reportId/:productId/:version/:ggVarKey`;
export const PROJECT_TEXTURE_SUMMARY_CHART = `/textureChart/:projectId/:reportId/:productId/:version`;
export const PAIRED_COMPARISON_PREFERENCE = `/pairedPreference/:projectId/:reportId`;

export const PQ_MODEL_PLAYGROUND = '/pq-model-playground';
