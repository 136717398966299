import React, { Component } from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { Search as SearchIcon } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Paper,
  OutlinedInput,
  InputAdornment,
  Stepper,
  StepLabel,
  StepButton,
  Step,
  IconButton,
  Grid,
} from '@mui/material';
import i18next from 'i18next';
import { pick, capitalize } from 'lodash';
import { flowRight as compose } from 'lodash';
import ReactGA from 'react-ga4';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, FieldArray, FormSection } from 'redux-form';

import AddPanel from './AddPanel';
import ConfirmationModal from './ConfirmationModal';
import styles from './FormSectionProductConfiguration.module.css';
import NutritionalInfo from './NutritionalInfo';
import ProductAttributes from './ProductAttributes';
import ProductCategory from './ProductCategory';
import ProductClassAttributes from './ProductClassAttributes';
import RenderProductClassAttributes from './ProductClassAttributes/RenderProductClassAttributes';
import ProductComponentBase from './ProductComponentBase';
import ProductComponentOther from './ProductComponentOther';
import ProductFeature from './ProductFeature';
import ProductImageUpload from './ProductImageUpload';
import QuestionSelection from './QuestionSelection';
import FieldBinaryRadio from '../../components/FieldBinaryRadio';
import FieldCheckBox from '../../components/FieldCheckBox';
import FieldTextInput from '../../components/FieldTextInput';
import FormInput from '../../components/FormInput';
import FormInputRadio from '../../components/FormInputRadio';
import FormInputSelect from '../../components/FormInputSelect';
import FormSectionHeader from '../../components/FormSectionHeader';
import InvalidModal from '../../components/InvalidModal';
import InvalidProductModal from '../../components/InvalidProductModal';
import LoadingScreen from '../../components/LoadingScreen';
import MaterialButton from '../../components/MaterialButton';
import StepperIcon from '../../components/StepperIcon';
import USDAProductSearch from '../../components/USDAProductSearch';
import { ALL_ALLERGENS } from '../../constants/attributes';
import { COUNTRIES } from '../../constants/country';
import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_INCOMPLETE,
  CREATE_PRODUCT_NEXT,
  CREATE_PRODUCT_PREV,
  CREATE_PRODUCT_MOVE_STEP,
} from '../../constants/googleAnalytics/actions';
import { CAT_CREATE_PRODUCT } from '../../constants/googleAnalytics/categories';
import { CP_STEP_LABELS } from '../../constants/googleAnalytics/labels';
import { PRODUCT_CLASS_ATTRIBUTES } from '../../constants/productClassAttributes';
import {
  USDA_SEARCH_URI,
  USDA_NUTRIENTS_URI,
  LABEL_NUTRIENTS,
  LABEL_NUTRIENTS_USDA_MAPPING,
  SERVING_SIZE,
  SERVING_SIZE_UNIT,
  INGREDIENTS,
  NAME,
  BRAND,
} from '../../constants/usdaApi.js';
import AllProductCategoriesQuery from '../../graphql/queries/AllProductCategoriesQuery';
import AllProductComponentBasesQuery from '../../graphql/queries/AllProductComponentBasesQuery';
import AllProductComponentOthersQuery from '../../graphql/queries/AllProductComponentOthersQuery';
import AllProductFeaturesQuery from '../../graphql/queries/AllProductFeaturesQuery';
import ProducersProductQuery from '../../graphql/queries/ProducersProductQuery';
import AllQuestionQuery from '../../graphql/queries/AllQuestionQuery';
import AvailablePanelQuery from '../../graphql/queries/AvailablePanels';
import ProducerByIdQuery from '../../graphql/queries/ProducerByIdQuery';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import { PageHeader } from '../../styles/themeComponents';
import { createCORSRequest } from '../../utils/corsRequest';
import { toTitleCase } from '../Product/helper';

const { event } = ReactGA;

class FormSectionProductConfiguration extends Component {
  state = {
    productCategory: '',
    isLiquid: false,
    openAllergen: null,
    openRestrictions: null,
    search: '',
    submitSearch: false,
    activeStep: 0,
    loadingData: false,
    showConfirmationModal: false,
    showInvalidModal: false,
    showInvalidProductModal: false,
  };

  // Handler for breadcrumb
  handleBackStep = () => {
    event({
      category: CAT_CREATE_PRODUCT,
      action: CREATE_PRODUCT_PREV,
      label: CP_STEP_LABELS[this.state.activeStep], // The step the button is clicked
    });

    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  handleNextStep = () => {
    event({
      category: CAT_CREATE_PRODUCT,
      action: CREATE_PRODUCT_NEXT,
      label: CP_STEP_LABELS[this.state.activeStep], // The step the button is clicked
    });

    this.runProductCollisionCheck().shouldRaiseAlarm
      ? this.handleProductNameCollisions()
      : this.props.invalid || this.props.pristine
        ? this.handleInvalidFields()
        : this.setState({
            activeStep: this.state.activeStep + 1,
          });
  };

  handleStep = (step) => () => {
    event({
      category: CAT_CREATE_PRODUCT,
      action: CREATE_PRODUCT_MOVE_STEP,
      // The step the button is clicked - target step
      label: `${CP_STEP_LABELS[this.state.activeStep]} - ${
        CP_STEP_LABELS[step]
      }`,
    });

    this.props.invalid || this.props.pristine
      ? this.handleInvalidFields()
      : this.setState({
          activeStep: step,
        });
  };

  handleCreate = () => {
    event({
      category: CAT_CREATE_PRODUCT,
      action: CREATE_PRODUCT,
      label: CP_STEP_LABELS[this.state.activeStep],
    });

    this.runProductCollisionCheck().shouldRaiseAlarm
      ? this.handleProductNameCollisions()
      : this.props.invalid
        ? this.handleInvalidFields()
        : this.props.handleSubmit();
  };

  handleInvalidFields = () => {
    this.setState({ showInvalidModal: true }, () =>
      event({
        category: CAT_CREATE_PRODUCT,
        action: CREATE_PRODUCT_INCOMPLETE,
        label: this.props.invalidFields.join(','),
      }),
    );
  };

  runProductCollisionCheck = () => {
    const { producersProducts, name, isPrototype } = this.props;
    const { productCategory } = this.state;

    if (!producersProducts || !name)
      return {
        shouldRaiseAlarm: false,
        preventProductCollisionIfPrototype: false,
        warnWhenProductCollisionIsInSameCategory: false,
        preventProductCollisionIfInOtherCategory: false,
      };

    const existingProducts =
      (producersProducts &&
        producersProducts.allProducts &&
        producersProducts.allProducts.nodes) ||
      [];

    const matchingProduct = existingProducts.length
      ? existingProducts.find(
          (prod) =>
            prod.name.toLowerCase().trim() === name.toLowerCase().trim(),
        )
      : null;
    const matchingProductCategory =
      matchingProduct &&
      matchingProduct.productCategoryByCategoryId &&
      matchingProduct.productCategoryByCategoryId.name.toLowerCase().trim();
    const matchedProductCategory =
      matchingProduct?.productCategoryByCategoryId?.name ?? '';

    return {
      shouldRaiseAlarm: !!matchingProduct,
      preventProductCollisionIfPrototype: !!matchingProduct && isPrototype,
      warnWhenProductCollisionIsInSameCategory:
        productCategory &&
        !!matchingProductCategory &&
        matchingProductCategory === productCategory.toLowerCase().trim(),
      preventProductCollisionIfInOtherCategory:
        productCategory &&
        !!matchingProductCategory &&
        matchingProductCategory !== productCategory.toLowerCase().trim(),
      matchedProductCategory,
    };
  };

  handleProductNameCollisions = () => {
    this.setState({ showInvalidProductModal: true }, () =>
      event({
        category: CAT_CREATE_PRODUCT,
        action: CREATE_PRODUCT_INCOMPLETE,
        label: '',
      }),
    );
  };

  // Submit USDA search when user press enter
  keyPress = (e) => {
    if (e.keyCode == 13) {
      this.sendEdamamReq();
    }
  };

  setProductCategory = (productCategory) => {
    this.setState({
      productCategory,
    });
  };

  setIsLiquid = (isLiquid) => {
    this.setState({
      isLiquid,
    });
  };

  onMenuClose = () => {
    this.setState({
      openAllergen: false,
    });
  };

  onMenuOpen = () => {
    this.setState({
      openAllergen: true,
    });
  };

  onRestrictionsMenuClose = () => {
    this.setState({
      openRestrictions: false,
    });
  };

  onRestrictionsMenuOpen = () => {
    this.setState({
      openRestrictions: true,
    });
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.hasAllergens && this.props.hasAllergens) {
      this.setState({
        openAllergen: true,
      });
    }
    if (!prevProps.hasDietaryRestriction && this.props.hasDietaryRestriction) {
      this.setState({
        openRestrictions: true,
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.search === '' && this.state.submitSearch) {
      this.setState({ submitSearch: false });
    }
  }

  sendEdamamReq = () => {
    this.setState({
      submitSearch: true,
    });

    let query = this.state.search.replace(/\s/g, '+');

    const API_KEY = 'UMZ5idOVfjk2aJpuGhex0y0dahJsGGXSDMPKLuJL';

    const url = `${USDA_SEARCH_URI}api_key=${API_KEY}&generalSearchInput=${query}&includeDataTypeList=Branded`;

    const xhr = createCORSRequest('GET', url);

    const that = this;

    // Response handlers.
    xhr.onload = function () {
      // console.log(xhr.responseText);
      that.changeResult(JSON.parse(xhr.responseText));
      that.setState({ loadingData: false });
    };

    xhr.onerror = function () {
      console.log('Error!!!');
      that.setState({ loadingData: false });
    };

    xhr.onloadstart = function () {
      // console.log('HERE');
      that.setState({ loadingData: true });
    };

    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send();
  };

  changeResult = (value) => {
    this.setState({
      results: value,
    });
  };

  sendEdamamReqProd = (id) => {
    const url =
      USDA_NUTRIENTS_URI +
      id +
      '?api_key=UMZ5idOVfjk2aJpuGhex0y0dahJsGGXSDMPKLuJL';

    const xhr = createCORSRequest('GET', url);

    this.setState({ submitSearch: false });

    const that = this;

    // Response handlers.
    xhr.onload = function () {
      console.log(xhr.responseText);
      // Load data
      const resp = JSON.parse(xhr.responseText);

      // Name and brand
      that.props.change('name', resp[NAME]);
      that.props.change('brand', resp[BRAND]);

      // Serving Size
      that.props.change('nutritionalInfo.Serving size', resp[SERVING_SIZE]);
      that.props.change('nutritionalInfo.Serving size_unit', {
        label: resp[SERVING_SIZE_UNIT],
        value: resp[SERVING_SIZE_UNIT],
      });

      // Nutritional Info
      LABEL_NUTRIENTS_USDA_MAPPING.map((nutrient) => {
        if (resp[LABEL_NUTRIENTS][nutrient.usda]) {
          that.props.change(
            'nutritionalInfo.' + nutrient.field,
            resp[LABEL_NUTRIENTS][nutrient.usda].value,
          );
          console.log(nutrient.unit);
          that.props.change('nutritionalInfo.' + nutrient.field + '_unit', {
            label: nutrient.unit,
            value: nutrient.unit,
          });
        }
      });

      // Ingredients
      let capitalizedIngredients = resp[INGREDIENTS].split(' ')
        .map((item) =>
          /[^a-zA-Z0-9]/.test(item.charAt(0))
            ? item.charAt(0) + capitalize(item.slice(1))
            : capitalize(item),
        )
        .join(' ');
      that.props.change('ingredients', capitalizedIngredients);

      that.setState({ showConfirmationModal: true });
    };

    xhr.onerror = function () {
      console.log('Error!!!');
    };

    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send();
  };

  handleCancelLoadUsda = () => {
    const { change } = this.props;
    this.setState({ showConfirmationModal: false });
    // Name and brand
    change('name', '');
    change('brand', '');

    // Serving Size
    change('nutritionalInfo.Serving size', '');
    change('nutritionalInfo.Serving size_unit', '');

    // Nutritional Info
    LABEL_NUTRIENTS_USDA_MAPPING.map((nutrient) => {
      change('nutritionalInfo.' + nutrient.field, '');
      change('nutritionalInfo.' + nutrient.field + '_unit', '');
    });

    // Ingredients
    change('ingredients', '');
  };

  definedComponentNamesField = ({ fields }) => {
    const { t } = this.props;

    return (
      <div className={styles.additionalInfoContainer}>
        {fields.map((fieldName, index) => (
          <div
            key={`${fieldName}_${index}`}
            className={styles.additionalFieldContainer}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <span className={styles.definedContainerName}>
                  {t('product.component') + `${index + 1}`}
                </span>
              </Grid>
              <Grid item>
                <Field
                  name={`${fieldName}.value`}
                  className={styles.definedContainer}
                  component={FieldTextInput}
                  placeholder={t('product.componentName') + `${index + 1}`}
                  fullWidth
                  margin="none"
                  required
                />
              </Grid>
              <Grid item>
                <IconButton
                  arial-label="Cancel"
                  onClick={() => fields.splice(index, 1)}
                  href=""
                  size="large"
                >
                  <CancelIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        ))}
        {fields.length < 5 && (
          <MaterialButton
            variant="contained"
            color="secondary"
            onClick={() => fields.push({ key: null, value: null })}
          >
            {`+ ${t('general.add')}`}
          </MaterialButton>
        )}
      </div>
    );
  };

  // eslint-disable-next-line complexity
  render() {
    const {
      pristine,
      invalid,
      submitting,
      features,
      component_bases,
      component_others,
      categories,
      panels,
      questions,
      producer,
      t,
      isPrototype,
      hasAllergens,
      hasNoAllergens,
      hasDietaryRestriction,
      hasNoDietaryRestriction,
      invalidFields,
      hasTexture,
      definedComponents,
      producersProducts,
    } = this.props;

    const {
      productCategory,
      isLiquid,
      openAllergen,
      openRestrictions,
      results,
      activeStep,
      loadingData,
      showConfirmationModal,
      showInvalidModal,
      showInvalidProductModal,
    } = this.state;

    const STEPS = t('product.createProductSteps', {
      returnObjects: true,
    }).slice(
      0,
      producer.producer && producer.producer.allowBehavioralQuestions ? 4 : 3,
    );
    // Allergen Options
    // TODO: Refactor allergen info in new version of the console
    const ALLERGEN = [
      {
        label: t('product.contains'),
        options: t('allergenInfo.contains', { returnObjects: true }),
      },
    ];
    const NO_ALLERGEN = [
      {
        label: t('product.contains'),
        options: t('allergenInfo.contains', { returnObjects: true }).filter(
          (a) => a.value === 'No Allergens',
        ),
      },
    ];
    const SOME_ALLERGEN = [
      {
        label: t('product.contains'),
        options: t('allergenInfo.contains', { returnObjects: true }).filter(
          (a) => a.value != 'No Allergens',
        ),
      },
    ];

    // Dietary Restrictions Options
    // TODO: Refactor allergen info in new version of the console
    const DIETARY_RESTRICTIONS = t('dietaryRestrictions.contains', {
      returnObjects: true,
    });
    const NO_DIETARY_RESTRICTIONS = t('dietaryRestrictions.contains', {
      returnObjects: true,
    }).filter((d) => d.value === 'None');
    const SOME_DIETARY_RESTRICTIONS = t('dietaryRestrictions.contains', {
      returnObjects: true,
    }).filter((d) => d.value != 'None');

    const CERTIFIED_SAFE = t('allergenInfo.safe', { returnObjects: true });

    if (
      categories.loading ||
      features.loading ||
      component_bases.loading ||
      component_others.loading
    ) {
      return <LoadingScreen />;
    }

    if (producer.loading && panels.loading) {
      return <LoadingScreen />;
    }

    if (panels.error) {
      return `ERROR: Unable to fetch data!`;
    }

    return (
      <Paper className={styles.container}>
        <PageHeader>{t('navigation.products')}</PageHeader>
        <h3 className={styles.productFieldsTitle}>
          {t('product.createProductHeader')}
        </h3>

        <ConfirmationModal
          open={showConfirmationModal}
          handleConfirm={() => this.setState({ showConfirmationModal: false })}
          handleCancel={this.handleCancelLoadUsda}
        />

        <Stepper
          style={{ padding: 24 }}
          activeStep={activeStep}
          nonLinear
          alternativeLabel
        >
          {STEPS.map((label, index) => (
            <Step key={label}>
              <StepButton onClick={this.handleStep(index)}>
                <StepLabel StepIconComponent={StepperIcon}>{label}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <div>
            <div className={styles.sectionContainer}>
              <Field
                name="prototype"
                component={FieldBinaryRadio}
                key="prototype"
                label={t('product.productOption')}
                optionLabel={t('product.prototype')}
                required
                parse={(value) => value === 'Yes'}
                format={(value) =>
                  value ? 'Yes' : value === false ? 'No' : ''
                }
              />
            </div>

            <Field
              name="public"
              component={FieldBinaryRadio}
              key="public"
              optionLabel={t('product.public')}
              helperText={t('product.publicHelpText')}
              parse={(value) => value === 'Yes'}
              format={(value) => (value ? 'Yes' : value === false ? 'No' : '')}
            />
            <Field
              name="aroma"
              component={FieldBinaryRadio}
              key="aroma"
              optionLabel={t('product.aroma')}
              helperText={t('product.aromaHelpText')}
              parse={(value) => value === 'Yes'}
              format={(value) => (value ? 'Yes' : value === false ? 'No' : '')}
            />

            {!isPrototype && (
              <div className={styles.sectionContainer}>
                <OutlinedInput
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={this.sendEdamamReq} size="large">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(event) =>
                    this.setState({
                      ...this.state,
                      search: event.target.value,
                    })
                  }
                  onKeyDown={this.keyPress}
                  placeholder={t('product.searchUsdaProduct')}
                  className={styles.searchBar}
                  fullWidth
                />
              </div>
            )}

            {this.state.submitSearch && (
              <USDAProductSearch
                query={this.state.search}
                data={results}
                getNutrients={this.sendEdamamReqProd}
                loading={loadingData}
              />
            )}

            {!isPrototype && (
              <span className={styles.disclaimer}>
                {t('product.usdaDisclaimer')}
              </span>
            )}

            <div className={styles.sectionContainer}>
              <FormSectionHeader text={t('product.productInfo')} />
              <Field
                name="name"
                component={FieldTextInput}
                fullWidth
                label={t('product.productName')}
                required
              />
              <Field
                name="localName"
                component={FieldTextInput}
                fullWidth
                label={t('product.productLocalName')}
              />
              <Field
                name="brand"
                component={FieldTextInput}
                fullWidth
                label={t('product.productBrand')}
                required
              />
            </div>

            {!isPrototype && (
              <div className={styles.sectionContainer}>
                <NutritionalInfo />
              </div>
            )}

            <div className={styles.sectionContainer}>
              <FormSectionHeader text={t('product.ingredients')} />
              <Field
                name="ingredients"
                component={FieldTextInput}
                fullWidth
                placeholder={t('product.ingredientsPlaceholder')}
              />
            </div>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className={styles.sectionContainer}>
                  <Field
                    name="allergens"
                    component={FormInput}
                    inputComponent={FormInputSelect}
                    autoFocus={openAllergen !== null}
                    key={
                      hasAllergens
                        ? 'SOME_ALLERGEN'
                        : hasNoAllergens
                          ? 'NO_ALLERGEN'
                          : 'ALLERGEN'
                    }
                    customLabel
                    labelText={t('product.allergenInfo')}
                    options={
                      hasAllergens
                        ? SOME_ALLERGEN
                        : hasNoAllergens
                          ? NO_ALLERGEN
                          : ALLERGEN
                    }
                    isSearchable
                    isClearable
                    isMulti
                    creatable
                    hideSelectedOptions={false}
                    placeholder={t('product.allergenInfoPlaceholder')}
                    closeMenuOnSelect={false}
                    checkbox
                    menuIsOpen={openAllergen}
                    onMenuClose={this.onMenuClose}
                    onMenuOpen={this.onMenuOpen}
                    onChange={(value) =>
                      value[0] &&
                      value[0].value == 'No Allergens' &&
                      this.onMenuClose()
                    }
                    required
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.sectionContainer}>
                  <Field
                    name="restrictions"
                    component={FormInput}
                    inputComponent={FormInputSelect}
                    autoFocus={openRestrictions !== null}
                    key={
                      hasDietaryRestriction
                        ? 'SOME_DIETARY_RESTRICTIONS'
                        : hasNoDietaryRestriction
                          ? 'NO_DIETARY_RESTRICTIONS'
                          : 'DIETARY_RESTRICTIONS'
                    }
                    customLabel
                    labelText={t('product.dietaryRestrictions')}
                    options={
                      hasDietaryRestriction
                        ? SOME_DIETARY_RESTRICTIONS
                        : hasNoDietaryRestriction
                          ? NO_DIETARY_RESTRICTIONS
                          : DIETARY_RESTRICTIONS
                    }
                    isSearchable
                    isClearable
                    isMulti
                    creatable
                    hideSelectedOptions={false}
                    placeholder={t('product.dietaryRestrictionsPlaceholder')}
                    closeMenuOnSelect={false}
                    checkbox
                    menuIsOpen={openRestrictions}
                    onMenuClose={this.onRestrictionsMenuClose}
                    onMenuOpen={this.onRestrictionsMenuOpen}
                    onChange={(value) =>
                      value[0] &&
                      value[0].value == 'None' &&
                      this.onRestrictionsMenuClose()
                    }
                    required
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.sectionContainer}>
                  <Field
                    name="certifiedSafe"
                    component={FormInput}
                    inputComponent={FormInputSelect}
                    key="certifiedSafe"
                    customLabel
                    labelText={t('product.certifiedSafe')}
                    options={CERTIFIED_SAFE}
                    isSearchable
                    isClearable
                    isMulti
                    creatable
                    hideSelectedOptions={false}
                    placeholder={t('product.certifiedSafePlaceholder')}
                    closeMenuOnSelect={false}
                    checkbox
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.sectionContainer}>
                  <RenderProductClassAttributes
                    productClass="Batch"
                    currentProductClassAttributes={
                      pick(PRODUCT_CLASS_ATTRIBUTES, ['Batch'])['Batch']
                    }
                  />
                </div>
              </Grid>
            </Grid>

            <div className={styles.sectionContainer}>
              <Field
                name="physicalState"
                component={FormInput}
                inputComponent={FormInputSelect}
                key="physicalState"
                customLabel
                labelText={t('product.physicalState')}
                options={t('physicalState', { returnObjects: true })}
                isSearchable
                isClearable
                hideSelectedOptions={false}
                placeholder={t('product.physicalStatePlaceholder')}
                closeMenuOnSelect={true}
                onChange={(_, newValue) => {
                  if (newValue.find((val) => val.label === 'Liquid')) {
                    this.setIsLiquid(true);
                  } else {
                    this.setIsLiquid(false);
                  }
                }}
                required
                isMulti
                checkbox
              />
            </div>

            <div className={styles.sectionContainer}>
              <ProductCategory
                data={categories}
                setProductCategory={this.setProductCategory}
              />
            </div>

            <div className={styles.sectionContainer}>
              <ProductComponentBase data={component_bases} />
            </div>

            <div className={styles.sectionContainer}>
              <ProductComponentOther data={component_others} />
            </div>

            <div className={styles.sectionContainer}>
              <ProductFeature data={features} />
            </div>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className={styles.sectionContainer}>
                  <Field
                    name="country"
                    component={FormInput}
                    inputComponent={FormInputSelect}
                    key="country"
                    labelText={t('product.countryOfOrigin')}
                    options={COUNTRIES.map((country) => ({
                      label: `${country.emoji} ${t(`country.${country.code}`)}`,
                      value: country.code,
                    }))}
                    isSearchable
                    isClearable
                    placeholder={t('product.countryPlaceholder')}
                    customLabel
                    required
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.sectionContainer}>
                  <Field
                    name="countryOfPurchase"
                    component={FormInput}
                    inputComponent={FormInputSelect}
                    key="countryOfPurchase"
                    labelText={t('product.countryOfPurchase')}
                    options={COUNTRIES.map((country) => ({
                      label: `${country.emoji} ${t(`country.${country.code}`)}`,
                      value: country.code,
                    }))}
                    isSearchable
                    isClearable
                    placeholder={t('product.countryPlaceholder')}
                    customLabel
                    required
                  />
                </div>
              </Grid>
            </Grid>

            {/* <Field
              name="texture"
              component={FieldCheckBox}
              key="texture"
              optionLabel={t('product.texture')}
            />

            {hasTexture && (
              <Field
                name="definedComponents"
                component={FormInputRadio}
                key="definedComponents"
                options={t('product.textureComponentOptions', {
                  returnObjects: true
                })}
                onChange={() => {
                  this.props.change('undefinedComponentTotal', {
                    label: 1,
                    value: 1
                  });
                  this.props.change('definedComponentNames', null);
                }}
              />
            )}

            {hasTexture &&
              definedComponents === 'true' && (
                <FormSection name="definedComponentNames">
                  <FieldArray
                    name="component"
                    component={this.definedComponentNamesField}
                  />
                </FormSection>
              )}

            {hasTexture &&
              definedComponents === 'false' && (
                <React.Fragment>
                  <div className={styles.undefinedContainer}>
                    <Field
                      name={'undefinedComponentTotal'}
                      component={FormInput}
                      inputComponent={FormInputSelect}
                      key={'undefinedComponentTotal'}
                      options={Array.from({ length: 5 }, (v, k) => k + 1)}
                      hideSelectedOptions={false}
                      closeMenuOnSelect
                      placeholder={t('product.undefinedComponentTotal')}
                    />
                    <Field
                      name="allowCustomTextureComponents"
                      component={FieldCheckBox}
                      key="allowCustomTextureComponents"
                      optionLabel={t('product.allowCustomTextureComponents')}
                    />
                  </div>
                </React.Fragment>
              )} */}
          </div>
        )}

        {activeStep === 1 && (
          <div>
            <div className={styles.productClassAttributes}>
              <FormSectionHeader text={t('product.productClassAttribute')} />
              <div>
                <ProductClassAttributes productClass="General" />
                {toTitleCase(productCategory) === 'General' ||
                toTitleCase(productCategory) === 'Liquid' ? null : (
                  <ProductClassAttributes
                    key={`${productCategory}`}
                    productClass={toTitleCase(productCategory)}
                  />
                )}
                {isLiquid ? (
                  <ProductClassAttributes productClass="Liquid" />
                ) : null}
              </div>
            </div>

            <div className={styles.sectionContainer}>
              <RenderProductClassAttributes
                productClass="Date"
                currentProductClassAttributes={
                  pick(PRODUCT_CLASS_ATTRIBUTES, ['Date'])['Date']
                }
              />
            </div>

            <div className={styles.sectionContainer}>
              <ProductAttributes />
            </div>
          </div>
        )}

        {activeStep === 2 && (
          <div>
            <div className={styles.sectionContainer}>
              <FormSectionHeader text={t('product.productImage')} />
              <ProductImageUpload name="images" />
            </div>

            <div className={styles.sectionContainer}>
              <FormSectionHeader text={t('product.nutritionalInfoImage')} />
              <ProductImageUpload name="nutritionalInfoImages" />
            </div>

            {/* <div className={styles.sectionContainer}>
              <AddPanel data={panels} />
            </div> */}
          </div>
        )}

        {activeStep === 3 && (
          <Field
            component={QuestionSelection}
            name="questions"
            data={
              questions &&
              questions.questions &&
              questions.questions.nodes &&
              questions.questions.nodes.map((question) => ({
                ...question,
                translation:
                  question.translations &&
                  question.translations.nodes &&
                  question.translations.nodes[0] &&
                  question.translations.nodes[0].translation,
              }))
            }
            productCategory={this.state.productCategory}
          />
        )}

        <InvalidModal
          open={showInvalidModal}
          handleClose={() => this.setState({ showInvalidModal: false })}
          invalid={invalid}
          invalidFields={invalidFields}
        />

        <InvalidProductModal
          open={showInvalidProductModal}
          handleClose={() => this.setState({ showInvalidProductModal: false })}
          productCollisionData={this.runProductCollisionCheck()}
          nextAction={() => {
            this.setState({ showInvalidProductModal: false });
            this.props.invalid || this.props.pristine
              ? this.handleInvalidFields()
              : this.setState({
                  activeStep: this.state.activeStep + 1,
                });
          }}
          productName={this.props.name}
          productCategory={this.state.productCategory}
        />

        <div className={styles.buttonContainer}>
          {activeStep < STEPS.length - 1 && (
            <MaterialButton
              variant="outlined"
              disabled={pristine || submitting}
              onClick={this.handleCreate}
              soft
            >
              {t('product.createProductQuick')}
            </MaterialButton>
          )}
          {activeStep > 0 && (
            <MaterialButton
              onClick={this.handleBackStep}
              variant="outlined"
              soft
            >
              Back
            </MaterialButton>
          )}
          {activeStep < STEPS.length - 1 && (
            <MaterialButton
              onClick={this.handleNextStep}
              variant="outlined"
              soft
              teal
            >
              {t('product.next')}
            </MaterialButton>
          )}
          {activeStep === STEPS.length - 1 && (
            <MaterialButton
              variant="outlined"
              disabled={pristine || invalid || submitting}
              onClick={this.handleCreate}
              soft
              teal
            >
              {t('product.createProduct')}
            </MaterialButton>
          )}
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  const values = state.form.PRODUCT_FORM.values;
  const name = values.name;
  const brand = values.brand;
  const allergens = values.allergens;
  const restrictions = values.restrictions;
  const physicalState = values.physicalState;
  const productCategory = values.productCategory;
  const productFeature = values.productFeature;
  const productComponentBase = values.productComponentBase;
  const countryOfPurchase = values.countryOfPurchase;
  const country = values.country;
  const prototype = values.prototype;
  const requiredFields = {
    name,
    brand,
    allergens,
    restrictions,
    physicalState,
    productCategory,
    productFeature,
    productComponentBase,
    countryOfPurchase,
    country,
  };
  const invalidFields = Object.keys(requiredFields).filter(
    (field) =>
      (Array.isArray(requiredFields[field]) && !requiredFields[field].length) ||
      requiredFields[field] === undefined,
  );

  const allergenList = values.allergens;
  const hasNoAllergens =
    (allergenList &&
      allergenList.findIndex((a) => a.label === 'No Allergens') > -1) ||
    false;
  const hasAllergens =
    allergenList &&
    allergenList.findIndex((a) => ALL_ALLERGENS.indexOf(a.label) > -1) > -1;

  const restrictionList = values.restrictions;
  const hasNoDietaryRestriction =
    (restrictionList &&
      restrictionList.length > 0 &&
      restrictionList.findIndex((a) => a.label === 'No Restrictions') > -1) ||
    false;
  const hasDietaryRestriction =
    restrictionList && restrictionList.length > 0 && !hasNoDietaryRestriction;

  const isPrototype = values.prototype;
  const aroma = values.aroma;
  const hasTexture = values.texture;
  const definedComponents = values.definedComponents;

  return {
    producerId: selectWorkspaceProducerId(state),
    hasAllergens,
    hasNoAllergens,
    hasDietaryRestriction,
    hasNoDietaryRestriction,
    isPrototype,
    // aroma,
    invalidFields,
    hasTexture,
    definedComponents,
    name,
  };
};

export default compose(
  connect(mapStateToProps),
  graphql(ProducerByIdQuery, {
    name: 'producer',
    options: (props) => ({
      variables: {
        id: props.producerId,
      },
    }),
  }),
  graphql(AllProductFeaturesQuery, {
    options: (props) => ({
      variables: {
        condition: {
          producerId: props.producerId,
        },
      },
    }),
    name: 'features',
  }),
  graphql(AllProductCategoriesQuery, {
    options: (props) => ({
      variables: {
        condition: {
          producerId: props.producerId,
        },
      },
    }),
    name: 'categories',
  }),
  graphql(AllProductComponentBasesQuery, {
    options: (props) => ({
      variables: {
        condition: {
          producerId: props.producerId,
        },
      },
    }),
    name: 'component_bases',
  }),
  graphql(AllProductComponentOthersQuery, {
    options: (props) => ({
      variables: {
        condition: {
          producerId: props.producerId,
        },
      },
    }),
    name: 'component_others',
  }),
  graphql(AvailablePanelQuery, {
    options: (props) => ({
      variables: {
        producerId: props.producerId,
      },
    }),
    name: 'panels',
  }),
  graphql(ProducersProductQuery, {
    options: (props) => ({
      variables: {
        producerId: props.producerId,
      },
    }),
    name: 'producersProducts',
  }),
  graphql(AllQuestionQuery, {
    options: (props) => ({
      variables: {
        language: i18next.language.toString().toUpperCase(),
      },
    }),
    name: 'questions',
  }),
  withTranslation(),
)(FormSectionProductConfiguration);
