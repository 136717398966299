import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagContainer: {
      background: theme.palette.secondary.main,
      fontSize: '14px',
      height: '44px',
      display: 'inline-block',
      padding: '10px 14px',
      margin: '5px 5px',
      border: '1px solid #ddd',
      fontFamily: 'OpenSans, sans-serif',
      color: theme.palette.primary.main,
    },
    remove: {
      color: '#aaa',
      marginLeft: '5px',
      cursor: 'pointer',
    },
    removeClass: {
      color: '#aaa',
      marginLeft: '5px',
      cursor: 'pointer',
      border: 'none',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    tagsClass: {
      position: 'relative',
    },
    tagInputClass: {
      width: 'auto',
      margin: '0 5px',
      display: 'inline-block',
    },
    tagInputFieldClass: {
      height: '44px',
      margin: '0',
      padding: '0 10px',
      fontSize: '14px',
      width: 'auto',
      border: '1px solid #ddd',
      '& focus': {
        height: '44px',
        margin: '0',
        padding: '0 10px',
        fontSize: '14px',
        width: 'auto',
        border: '1px solid #ddd',
      },
    },
    selectedClass: {
      height: '44px',
      margin: '0',
      padding: '0 10px',
      fontSize: '14px',
      width: 'auto',
      border: '1px solid #ddd',
    },
    tagClass: {
      background: theme.palette.secondary.main,
      fontSize: '14px',
      height: '44px',
      display: 'inline-block',
      padding: '10px 14px',
      margin: '5px 5px',
      border: '1px solid #ddd',
      fontFamily: 'OpenSans, sans-serif',
      color: theme.palette.primary.main,
    },
    suggestionsClass: {
      position: 'absolute',
      zIndex: 1,
      maxHeight: '230px',
      overflowY: 'auto',
      '& ul': {
        listStyleType: 'none',
        padding: '5px 10px',
        boxShadow: '0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2)',
        background: 'white',
        width: '200px',
      },
      '& li': {
        borderBottom: '1px solid #ddd',
        padding: '5px 10px',
        margin: '0',
      },
      '& li mark': {
        textDecoration: 'none',
        padding: '0px',
        background: 'none',
        fontWeight: 'normal',
      },
    },
    editTagInput: {
      textDecoration: 'none',
      padding: '0px',
      background: 'none',
      fontWeight: 'normal',
    },
    activeSuggestionClass: {
      background: '#eeeeee',
      cursor: 'pointer',
    },
  }),
);

export default useStyles;
