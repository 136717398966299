import * as React from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import ReportByIdQuery from '@graphql/queries/ReportByIdQuery';
import ReportPQNormsQuery from '@graphql/queries/ReportPQNormsQuery';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { getReportCategoryInfo } from 'components/Report/ReportDemographics/utils';
import { getProductName } from 'components/Report/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary, {
  ReportSummaryDetailRow,
} from 'hooks/useReportSummary';
import DataTable, { TableColumn } from 'react-data-table-component';

import useStyles from './useStyles';
import { ComponentTitle } from '../../../../styles/themeComponents';

interface Props {
  reportId: string;
  projectId: number;
}

interface TableRow {
  productName: string;
  pq: number;
}
export const reportSummaryCategoryNorm = (
  productSummaries: ReportSummaryDetailRow,
  productNames: reports.ColorVersionedProductInfo[],
) => {
  const reportSummaryEdges =
    productSummaries?.reportSummariesByReportId?.edges ?? [];
  const summary = reportSummaryEdges.map(({ node }) => {
    return {
      productName: getProductName({
        productNames,
        productId: node.productByProductId.id,
        version: node.version,
      }),
      pq: Math.round(parseFloat(node.pq) * 100) / 100,
    };
  });
  return summary;
};

const ProductCategoryNorms: React.FC<Props> = ({ reportId, projectId }) => {
  const classes = useStyles(useTheme());
  const productNames = useCustomProductNames({ projectId, reportId });

  const {
    loading: quantilesLoading,
    error: quantilesError,
    data: quantilesData,
  } = useQuery<reports.ReportPQNormsResponse>(ReportPQNormsQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const {
    data: reportSummarydata,
    loading: reportSummaryLoading,
    error: reportSummaryError,
  } = useReportSummary(reportId);

  const {
    loading: reportJobLoading,
    error: reportJobError,
    data: reportJobData,
  } = useQuery<reports.ReportJobResults>(ReportByIdQuery, {
    variables: {
      reportId: reportId,
      orderBy: 'ID_DESC',
    },
  });

  if (quantilesLoading || reportSummaryLoading || reportJobLoading) {
    return <LoadingScreen />;
  }

  if (quantilesError || reportSummaryError || reportJobError) {
    <h4>Error loading Product vs Category Norms</h4>;
  }

  const quantiles = quantilesData.allRpCategoryNorms.nodes[0];
  const productSummaries = reportSummarydata.allReportJobs.nodes[0];
  const categoryDisplayName = getReportCategoryInfo(reportJobData.report);

  const columns: TableColumn<TableRow>[] = [
    {
      selector: (row: TableRow) => row.productName,
      name: 'Product Name',
      sortable: false,
    },
    {
      selector: (row: TableRow) => row.pq,
      name: 'Norm for Action Standard Comparison',
      sortable: false,
      maxWidth: '100',
      center: true,
      conditionalCellStyles: [
        {
          when: (row) => row.pq > parseFloat(quantiles.q3),
          style: {
            backgroundColor: '#2e7d32',
          },
        },
        {
          when: (row) =>
            row.pq > parseFloat(quantiles.median) &&
            row.pq < parseFloat(quantiles.q3),
          style: {
            backgroundColor: '#4caf50',
          },
        },
        {
          when: (row) => row.pq < parseFloat(quantiles.median),
          style: {
            backgroundColor: '#ef5350',
          },
        },
      ],
    },
  ];

  if (!quantiles) {
    return <div></div>;
  }

  return (
    <Grid container marginTop={10} rowSpacing={2}>
      <Grid item xs={12}>
        <ComponentTitle>Product vs Category Norms</ComponentTitle>
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardContent>
            <CardHeader
              className={classes.header}
              title={'Category Norms for ' + categoryDisplayName}
            />
            <ul>
              <li key="q1">25th Percentile: {quantiles.q1}</li>
              <li key="q2">50th Percentile: {quantiles.median}</li>
              <li key="q3">75th Percentile: {quantiles.q3}</li>
            </ul>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardContent>
            <ul>
              <li key="q1">
                <Typography variant="body1">
                  Products that are
                  <span className={classes.mainText}> below the median </span>
                  of the category are highlighted in
                  <Box
                    className={classes.colorBox}
                    sx={{ backgroundColor: '#ef5350' }}
                  />
                </Typography>
              </li>
              <li key="q2">
                <Typography variant="body1">
                  Products that are
                  <span className={classes.mainText}> above the median </span>
                  of the category are highlighted in
                  <Box
                    className={classes.colorBox}
                    sx={{ backgroundColor: '#4caf50' }}
                  />
                </Typography>
              </li>
              <li key="q3">
                <Typography variant="body1">
                  Products that are
                  <span className={classes.mainText}>
                    {' '}
                    above the 75th percentile{' '}
                  </span>
                  of the category are highlighted in
                  <Box
                    className={classes.colorBox}
                    sx={{ backgroundColor: '#2e7d32' }}
                  />
                </Typography>
              </li>
            </ul>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          data={reportSummaryCategoryNorm(productSummaries, productNames)}
          columns={columns}
        />
      </Grid>
    </Grid>
  );
};

export default ProductCategoryNorms;
