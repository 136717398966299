import { gql } from '@apollo/client';

export interface ReportFpNoveltyDecompResponse
  extends reports.ReportGraphQLReponse {
  allRpNoveltyFlavorDecomps: {
    nodes: ReportNoveltyFpDecompRow[];
  };
}

export interface ReportNoveltyFpDecompRow {
  id: number;
  reportId: string;
  productByProductId: reports.ProductInfo;
  ggVars: string;
  referenceFlavor: string;
  contributionToNovelPct: number;
  decomp: number;
  signaturePresent: boolean;
  version: string;
}

export default gql`
  query NoveltyFpDecompByReportIdAndProductIdQuery(
    $reportID: UUID!
    $productID: Int
    $version: String
  ) {
    allRpNoveltyFlavorDecomps(
      orderBy: CONTRIBUTION_TO_NOVEL_PCT_DESC
      condition: {
        reportId: $reportID
        productId: $productID
        version: $version
      }
    ) {
      nodes {
        id
        reportId
        productByProductId {
          id
          name
        }
        ggVars
        referenceFlavor
        signaturePresent
        contributionToNovelPct
        version
        decomp
      }
    }
  }
`;
