import ggVars from 'constants/ggVars';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import GastrographRadarDownloadQuery from '@graphql/queries/GastrographRadarDownloadQuery';
import { Box } from '@mui/material';
import {
  ProductVersion,
  ProductVersionSet,
} from 'components/Report/ProductVersion';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { groupBy, keys } from 'lodash';
import { Data, LabelKeyObject } from 'react-csv/lib/core';

import { getProductName } from '../../../../components/Report/utils';
import DataDownloadTile from '../DataDownloadTile';
import { TileType } from '../DataDownloadTile/DataDownloadTile';

interface Props {
  projectId: number;
  reportId: string;
  products: ProductVersionSet;
}

const AllOFPsDataDownload: React.FC<Props> = (props) => {
  const { projectId, reportId, products } = props;

  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const { loading, error, data } =
    useQuery<reports.ReportGastrographRadarReponse>(
      GastrographRadarDownloadQuery,
      {
        variables: {
          reportID: reportId,
        },
      },
    );

  const getCsvHeader: () => LabelKeyObject[] = () => {
    return [{ label: 'Product', key: 'product' }].concat(ggVars).map((gv) => ({
      label: gv.label,
      key: gv.key,
    }));
  };

  const getCsvRows: (data: reports.ReportGastrographRadarReponse) => Data = (
    data: reports.ReportGastrographRadarReponse,
  ) => {
    const products = groupBy(
      data.allRpOfps.nodes
        .filter((n) => !!n.product)
        .map((n) => ({
          ...n,
          productVersion: new ProductVersion(n.product.id, n.version),
        })),
      (n) => n.productVersion.value,
    );

    const rows = [];
    keys(products).forEach((p) => {
      const row = [];

      const productVersion = products[p].find(Boolean).productVersion;

      getCsvHeader().forEach((h) => {
        if (h.key === 'product') {
          row.push(
            getProductName({
              productNames,
              productId: productVersion.productId,
              version: productVersion.version,
            }),
          );
        } else {
          const item: number =
            products[p].find((g) => g.attribute === h.key)?.intensity || 0;
          row.push(Number(item).toFixed(1));
        }
      });

      rows.push(row);
    });
    return rows;
  };

  if (loading || error || getCsvRows(data).length === 0) {
    return <Box />;
  }

  return (
    <DataDownloadTile
      tileType={TileType.CSV}
      headers={getCsvHeader().map((h) => h.label)}
      csvDataRows={getCsvRows(data)}
      fileName="AllOfps.csv"
      tileName="All OFPs"
    />
  );
};

export default AllOFPsDataDownload;
