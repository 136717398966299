import * as React from 'react';
import { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ReportDataCollectedTable from './ReportDataCollectedTable';

interface ReportProps {
  reportId: string;
}

const ReportDataCollected: React.FC<ReportProps> = (props) => {
  const { reportId } = props;

  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <h4>{t('reports.workspaceSummary.dataCollected')}</h4>
        <IconButton
          style={{ paddingTop: 3 }}
          onClick={() => setShowModal(true)}
          size="large"
        >
          <InfoIcon color="primary" />
        </IconButton>
      </div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 10,
        }}
      >
        <ReportDataCollectedTable reportId={reportId} isModal={true} />
      </Modal>
      <div>
        <ReportDataCollectedTable reportId={reportId} isModal={false} />
      </div>
    </div>
  );
};

export default ReportDataCollected;
