import { Partner } from 'constants/partner';
import {
  adminUserRole,
  allRoles,
  partnerAdminRole,
  superadminUserRole,
} from 'constants/userRoles';

import * as React from 'react';

import FormGroup from '@mui/material/FormGroup';
import PartnerPicker from 'components/PartnerPicker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import styles from './UserCreateContainer.module.css';
import FieldCheckBox from '../../components/FieldCheckBox';
import FieldTextInput from '../../components/FieldTextInput';
import MaterialButton from '../../components/MaterialButton';
import selectTokenRoles from '../../selectors/sessionTokenRoles';

export const filterElevatedRoles = (roles: Set<string>): string[] => {
  const filterRole = (roles: Set<string>, role: string, filterRole: string) =>
    role.toLowerCase() !== filterRole.toLowerCase() ||
    roles.has(filterRole.toLowerCase());

  if (roles.has(superadminUserRole.toLowerCase())) {
    // if super admin, automatically get to create any role
    return allRoles;
  } else {
    // if not super admin, can't create users with escalated roles
    return allRoles
      .filter((r) => filterRole(roles, r, superadminUserRole))
      .filter((r) => filterRole(roles, r, adminUserRole))
      .filter((r) => filterRole(roles, r, partnerAdminRole));
  }
};

interface Props {
  submitting: boolean;
  pristine: boolean;
  invalid: boolean;
  change: (field: string, value: any) => void;
  handleSubmit?: MouseEvent | any;
}

const UserCreateContainer: React.FC<Props> = (props) => {
  const { handleSubmit, submitting, pristine, invalid, change } = props;

  const { t } = useTranslation();
  const userRoles = useSelector((state) => selectTokenRoles(state));

  // Don't allow role escalation
  const rolesToShow = filterElevatedRoles(userRoles);

  const formatRoleName = (name) => {
    return name.replace('_', ' ');
  };

  return (
    <div>
      <div className={styles.sectionContainer}>
        <Field
          name="username"
          autoComplete="username"
          component={FieldTextInput}
          fullWidth
          label={t('users.username')}
          required
        />
        <Field
          name="email"
          autoComplete="email"
          component={FieldTextInput}
          fullWidth
          label={t('users.email')}
        />
        <Field
          name="password"
          autoComplete="current-password"
          component={FieldTextInput}
          fullWidth
          label={t('users.password')}
          required
        />
        <PartnerPicker
          changePartnerValue={(value: Partner) => change('partner', value)}
        />
      </div>

      <div className={styles.addUserRoleFormParent}>
        <div className={styles.addUserRoleForm}>
          <FormGroup>
            {rolesToShow
              .slice(0, Math.floor(rolesToShow.length / 2))
              .map((role) => {
                return (
                  <Field
                    component={FieldCheckBox}
                    key={role}
                    label={formatRoleName(role)}
                    name={role.toLowerCase()}
                    checkboxContainer
                  />
                );
              })}
          </FormGroup>
        </div>
        <div className={styles.addUserRoleForm}>
          <FormGroup>
            {rolesToShow
              .slice(Math.ceil(rolesToShow.length / 2), rolesToShow.length)
              .map((role) => {
                return (
                  <Field
                    component={FieldCheckBox}
                    key={role}
                    label={formatRoleName(role)}
                    name={role.toLowerCase()}
                    checkboxContainer
                  />
                );
              })}
          </FormGroup>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <MaterialButton
          variant="outlined"
          disabled={pristine || invalid || submitting}
          onClick={handleSubmit}
          soft
          teal
        >
          {t('button.submit')}
        </MaterialButton>
      </div>
    </div>
  );
};

export default UserCreateContainer;
