import * as React from 'react';
import { useState } from 'react';

import { FetchResult, useQuery } from '@apollo/client';
import CreateProductFeatureProductMutation from '@graphql/mutations/CreateProductFeatureProduct';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import MaterialButton from 'components/MaterialButton';
import graphqlClient from 'consumers/graphqlClient';
import ProductPasteModal from 'containers/RequestReport/MarketSurvey/ProductPasteModal';
import { GraphQLError } from 'graphql';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import AllProductFeaturesQuery from '../../graphql/queries/AllProductFeaturesQuery';

const ProductFeatureBulkAdd: React.FC = () => {
  const { t } = useTranslation();
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));
  const [showProductModal, setProductModal] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<FormattedProduct[]>(
    [],
  );
  const [selectedFeature, setSelectedFeature] = useState<{
    id: number;
    name: string;
  }>(null);
  const [mutationResults, setMutationResults] = useState<FetchResult[]>([]);

  interface ProductError extends GraphQLError {
    productId: number;
  }
  const [mutationErrors, setMutationErrors] = useState<ProductError[]>([]);

  const { data, loading, error } = useQuery(AllProductFeaturesQuery, {
    variables: {
      condition: {
        producerId: producerId,
      },
    },
  });

  async function submit(productIds: number[], featureId: number) {
    let results: FetchResult[] = [];
    let errors: ProductError[] = [];
    for (const productId of productIds) {
      const productFeatureProduct = {
        productId: productId,
        productFeatureId: featureId,
      };
      const result = await graphqlClient
        .mutate({
          mutation: CreateProductFeatureProductMutation,
          variables: {
            productFeatureProduct,
          },
        })
        .then((result) => (results = results.concat([result])))
        .catch(
          (error) =>
            (errors = errors.concat([{ ...error, productId: productId }])),
        );
    }
    setMutationResults(results);
    setMutationErrors(errors);
    graphqlClient.resetStore();
  }

  const confirmProducts = (products: FormattedProduct[]) => {
    setSelectedProducts(products);
    setProductModal(false);
  };

  return (
    <div>
      <MaterialButton variant="outlined" onClick={() => setProductModal(true)}>
        {t('reports.pasteProductList')}
      </MaterialButton>
      <ProductPasteModal
        open={showProductModal}
        handleClose={() => setProductModal(false)}
        returnProducts={(validatedProducts) =>
          confirmProducts(validatedProducts)
        }
      />
      {selectedProducts.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2em',
            marginTop: '2em',
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell align="right">ID</TableCell>
                  <TableCell align="right">Brand</TableCell>
                  <TableCell align="right">Category</TableCell>
                  <TableCell align="right">Workspace</TableCell>
                  <TableCell align="right">Reviews</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProducts.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.id}</TableCell>
                    <TableCell align="right">{row.brand}</TableCell>
                    <TableCell align="right">{row.category?.name}</TableCell>
                    <TableCell align="right">
                      {row.productWorkspaceName}
                    </TableCell>
                    <TableCell align="right">{row.reviews}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <FormControl fullWidth>
            <InputLabel id="bulk-product-feature-select-label">
              Product Feature
            </InputLabel>
            <Select
              id="bulk-product-feature-select"
              label="Product Feature"
              onChange={(event) =>
                setSelectedFeature(
                  event.target.value as { id: number; name: string },
                )
              }
            >
              {data.productFeatures.nodes.map((f) => (
                <MenuItem value={f}>{f.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedFeature != null && (
            <MaterialButton
              teal
              soft
              onClick={() =>
                submit(
                  selectedProducts.map((d) => d.id),
                  selectedFeature.id,
                )
              }
            >
              Submit
            </MaterialButton>
          )}
          {mutationResults.length > 0 && (
            <div>
              {mutationResults.length} product-feature links successfully added
              ✅
            </div>
          )}
          {mutationErrors.length > 0 &&
            mutationErrors.map((e) => (
              <div
                style={{ color: 'red' }}
              >{`Product ID ${e.productId}: ${e.message}`}</div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ProductFeatureBulkAdd;
