import * as React from 'react';

import { Box } from "@mui/material";

const InsufficientDataMap: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="300px"
    >
      <h4>Not enough data for map</h4>
    </Box>
  );
}
export default InsufficientDataMap;