import { gql } from '@apollo/client';

export default gql`
  query GetProjectsReports($projectIDs: [Int!]) {
    projects: allProjects(filter: { id: { in: $projectIDs } }) {
      nodes {
        id
        name
        projectReportsByProjectId {
          nodes {
            displayName
            reportJob: reportJobByReportId {
              reportId
              clientName
              passedQa
              projectName
              startedAt
              pdfEfsUri
              targetGroupName
              reportType
              jobId
              parentJobId
              reportSummaries: reportSummariesByReportId {
                nodes {
                  version
                  productByProductId {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        customPqProductNamesByProjectId {
          nodes {
            id
            projectId
            productId
            productName
            version
          }
        }
      }
    }
  }
`;
