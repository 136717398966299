import { gql } from '@apollo/client';

export default gql`
  mutation UpdateCustomTextureName($id: Int!, $customName: String!) {
    updateRpTextureClusterNameById (input: { id: $id, rpTextureClusterNamePatch: { customClusterName: $customName }}) {
      rpTextureClusterName {
        id
        clusterName
        customClusterName
      }
    }
  }
`;
