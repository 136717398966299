import { mapFromKey } from 'constants/ggVars';

import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import FpDecompByReportIdAndProductIDQuery from '@graphql/queries/FpDecompByReportIdAndProductIDQuery';
import GastrographRadarQuery from '@graphql/queries/GastrographRadarQuery';
import ReportGgVarTuningPlotCnt, {
  ReportGgVarTuningPlotCntResponse,
} from '@graphql/queries/ReportGgVarTuningPlotCnt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Masonry } from '@mui/lab';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import {
  getProfileIntensity,
  getTotalDecomp,
} from 'components/Report/ReportGastrographRadar/RadarUtils';
import { chain } from 'lodash';
import { useTranslation } from 'react-i18next';

import FlavorDecompositionSubTable from './FlavorDecompositionSubTable';
import styles from './FlavorDecompositionTable.module.css';
import FlavorDecompositionTableCsv from './FlavorDecompositionTableCsv';

const hasTuning = (ggVarData: ReportGgVarTuningPlotCntResponse): boolean => {
  return (ggVarData?.allRpGgvarFlavorProfiles?.totalCount ?? 0) > 0;
};

const ggVars: (data: reports.ReportFpDecompResponse) => constants.GGVar[] = (
  data,
) =>
  chain([...data.allRpFlavorDecomps.nodes])
    .map((r) => r.ggVars)
    .uniq()
    .map(mapFromKey)
    .filter((i) => !!i)
    .value();

interface Props {
  projectId: number;
  reportId: string;
  productId: number;
  version: string;
  forceNoTuning: boolean;
}

const FlavorDecompositionTable: React.FC<Props> = (props) => {
  const { projectId, reportId, productId, version, forceNoTuning } = props;

  const { t } = useTranslation();
  const [explainerModalOpen, setExplainerModal] = useState<boolean>(false);

  const { loading, error, data } = useQuery<reports.ReportFpDecompResponse>(
    FpDecompByReportIdAndProductIDQuery,
    {
      variables: { reportID: reportId, productID: productId, version: version },
    },
  );

  const {
    data: ggVarData,
    loading: ggVarLoading,
    error: ggVarError,
  } = useQuery<ReportGgVarTuningPlotCntResponse>(ReportGgVarTuningPlotCnt, {
    variables: {
      reportID: reportId,
    },
    skip: forceNoTuning,
  });

  const {
    loading: reportGastrographRadarLoading,
    error: reportGastrographRadarError,
    data: reportGastrographRadarResponse,
  } = useQuery<reports.ReportGastrographRadarReponse>(GastrographRadarQuery, {
    variables: {
      reportID: reportId,
      productID: productId,
    },
  });

  if (
    loading ||
    error ||
    ggVarLoading ||
    ggVarError ||
    reportGastrographRadarLoading ||
    reportGastrographRadarError
  ) {
    return <LoadingScreen />;
  }

  if (!data.allRpFlavorDecomps.nodes.length) {
    return (
      <div className={styles.emptyMain}>
        <div className={styles.emptySub}>
          <h5>Your selection produced an empty list!</h5>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Modal
        title="Explainer"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
        }}
        open={explainerModalOpen}
        onClose={() => setExplainerModal(false)}
      >
        <div
          style={{
            flex: 1,
            background: 'white',
            maxHeight: '75%',
            maxWidth: '75%',
            overflow: 'auto',
            padding: '15px',
            fontSize: 12,
            fontFamily: 'OpenSans, sans-serif',
            color: '#022950',
          }}
        >
          <p>{t('reports.fpDecomp.explainer1')}</p>
          <p>
            The ”{t('reports.fpDecomp.knownSignatures1')}{' '}
            {(getTotalDecomp(data) * 100).toFixed(0)}%”{' '}
            {t('reports.fpDecomp.explainer2end')}
          </p>
          <p>{t('reports.fpDecomp.explainer3')}</p>
        </div>
      </Modal>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <h5 className={styles.pageSubHeader}>
              <span>{t('reports.fpDecomp.title')}</span>
            </h5>
            <IconButton
              onClick={() => setExplainerModal(!explainerModalOpen)}
              size="large"
            >
              <InfoOutlinedIcon style={{ fontSize: 24, color: '#777' }} />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <h6>
              {t('reports.fpDecomp.knownSignatures1')}
              {(getTotalDecomp(data) * 100).toFixed(0)}
              {t('reports.fpDecomp.knownSignatures2')}
              {t('reports.fpDecomp.productFP')}
              {getProfileIntensity(
                reportGastrographRadarResponse,
                version,
              ).toFixed(0)}
            </h6>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <FlavorDecompositionTableCsv data={data.allRpFlavorDecomps.nodes} />
        </Grid>
        <Grid item xs={12}>
          <Masonry columns={3} spacing={2}>
            {ggVars(data).map((ggVar) => (
              <FlavorDecompositionSubTable
                key={ggVar.key}
                ggVar={ggVar}
                data={data.allRpFlavorDecomps.nodes.filter(
                  (r: reports.ReportFpDecompRow) =>
                    mapFromKey(r.ggVars) === ggVar,
                )}
                projectId={projectId}
                reportId={reportId}
                productId={productId}
                version={version}
                hasTuning={hasTuning(ggVarData)}
              />
            ))}
          </Masonry>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="overline" gutterBottom>
              {t('reports.fpDecomp.legendSingleProduct')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default FlavorDecompositionTable;
