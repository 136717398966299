import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import ReportPQNormsQuery from '@graphql/queries/ReportPQNormsQuery';
import CreateOutlined from '@mui/icons-material/CreateOutlined';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import LoadingScreen from 'components/LoadingScreen';
import { HIGHLIGHT_COLOR } from 'components/ReactDataTable/highlightedRowStyles';
import { showOriginalProductsToggle } from 'containers/ProjectSummary/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary, {
  KeyedReportDetailRow,
  ReportSummaryResponse,
} from 'hooks/useReportSummary';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import selectViewerUserId from 'selectors/viewerUserId';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';
import {
  allowBulkLabelEdit,
  showStackRankNorms,
  useCustomerPreferences,
} from 'services/customerPreferences';

import ReportSummaryModal from './Modal/ReportSummaryModal';
import BulkEditCustomNamesContainer from './ReportCustomAllProductCreate';
import ReportCustomProductCreateContainer from './ReportCustomProductCreate';
import styles from './ReportSummary.module.css';
import ReportSummaryCsv from './ReportSummaryCsv';
import {
  PAIRED_COMPARISON_PREFERENCE,
  REPORT_PRODUCT_SUMMARY,
} from '../../../constants/routePaths';
import formatPath from '../../../utils/formatPath';
import { ProductVersion, ProductVersionSet } from '../ProductVersion';
import {
  getCanonicalName,
  getProduct,
  getProductName,
  sortByPq,
  isProductHighlighted,
} from '../utils';

interface ReportProps {
  projectId?: number;
  reportId: string;
  parentReportId?: string;
  displayParentReport?: boolean;
  setDisplayParentReport?: (value: boolean) => void;
  enableComparison?: (
    firstProduct: ProductVersion,
    secondProduct: ProductVersion,
  ) => void;
  excludedProducts: ProductVersionSet;
  setExcludedProducts: React.Dispatch<React.SetStateAction<ProductVersionSet>>;
  updateExcludedProducts: (excludedProducts: ProductVersionSet) => void;
  pageUpdated?: boolean;
  togglePageUpdate?: () => void;
  selectedReportName?: string;
  routeBackLocation?: string;
  mapOnly?: boolean;
}

const ReportSummary: React.FC<ReportProps> = (props) => {
  const {
    projectId,
    reportId,
    parentReportId,
    displayParentReport,
    setDisplayParentReport,
    enableComparison,
    excludedProducts,
    setExcludedProducts,
    updateExcludedProducts,
    pageUpdated,
    togglePageUpdate,
    selectedReportName,
    routeBackLocation,
    mapOnly,
  } = props;

  const { t } = useTranslation();

  const viewerUserId = useSelector((state) => selectViewerUserId(state));
  const workspaceId = useSelector((state) => selectWorkspaceProducerId(state));

  const customerPreferences = useCustomerPreferences();

  const customProductNames = useCustomProductNames({
    projectId,
    reportId,
    refetchNames: pageUpdated,
  });

  const [selectedProducts, setSelectedProducts] = useState<ProductVersionSet>(
    new ProductVersionSet(),
  );
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [editingProduct, setEditingProduct] = useState<reports.EditingProduct>({
    id: 0,
    name: '',
    version: null,
    marketLabel: '',
    labelColor: '#000000',
    isHighlighted: false,
  });
  const [bulkEditingProduct, setBulkEditingProduct] =
    useState<reports.BulkEditingProduct[]>();
  const [openBulkEditModal, setOpenBulkEditModal] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [editingProductVersionValue, setEditingProductVersionValue] =
    useState<string>(null);

  const getDisplayedParentReportId = (
    displayParentReport,
    parentReportId,
  ): string => (displayParentReport ? parentReportId : null);

  const toggleShowEdit = (
    editingProduct: ProductVersion,
    showEdit: boolean,
  ) => {
    if (editingProduct) {
      setEditingProductVersionValue(editingProduct.value);
    }
    setShowEdit(showEdit);
  };

  const {
    loading,
    error,
    data: reportSummary,
  } = useReportSummary(reportId, {
    fetchPolicy: 'no-cache',
  });

  const {
    loading: parentSummaryLoading,
    error: parentSummaryError,
    data: parentReportSummary,
  } = useReportSummary(
    getDisplayedParentReportId(displayParentReport, parentReportId),
    {
      skip: !getDisplayedParentReportId(displayParentReport, parentReportId),
      fetchPolicy: 'no-cache',
    },
  );

  const {
    loading: normsLoading,
    error: normsError,
    data: normsData,
  } = useQuery<reports.ReportPQNormsResponse>(ReportPQNormsQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const closeModal = () => {
    togglePageUpdate();
    setOpenCreateModal(false);
    setOpenBulkEditModal(false);
  };

  const toggleAllInclusion = (
    all: boolean,
    customProductNames: reports.ColorVersionedProductInfo[],
  ) => {
    if (all) {
      const allProducts = new ProductVersionSet();
      customProductNames.forEach((d) =>
        allProducts.add(new ProductVersion(d.id, d.version)),
      );
      setExcludedProducts(allProducts);
      updateExcludedProducts(allProducts);
    } else {
      setExcludedProducts(new ProductVersionSet());
      updateExcludedProducts(new ProductVersionSet());
    }
  };

  const handleReportInclusion = (productVersion: ProductVersion) => {
    const selected = excludedProducts.copy();

    if (excludedProducts.has(productVersion)) {
      selected.delete(productVersion);
    } else {
      selected.add(productVersion);
    }

    setExcludedProducts(selected);
    updateExcludedProducts(selected);
  };

  const handleProductSelection = (
    selectedProducts: ProductVersionSet,
    productVersion: ProductVersion,
  ) => {
    const selected = selectedProducts.copy();

    if (selectedProducts.size <= 2) {
      if (selectedProducts.has(productVersion)) {
        selected.delete(productVersion);
      } else if (selectedProducts.size <= 1) {
        selected.add(productVersion);
      }
      setSelectedProducts(selected);
    }

    if (selected.size === 2) {
      enableComparison(selected.get(0), selected.get(1));
    } else {
      enableComparison(null, null);
    }
  };

  const getReshapedData = (
    productSummary: ReportSummaryResponse,
    reportId: string,
    keyOffset?: number,
  ): KeyedReportDetailRow[] => {
    return productSummary.allReportJobs.nodes[0].reportSummariesByReportId.edges.map(
      (edge, index) => ({
        key: index + (keyOffset ?? 0),
        productVersion: new ProductVersion(
          edge.node.productByProductId.id,
          edge.node.version,
        ),
        reportId: reportId,
        productByProductId: {
          id: edge.node.productByProductId.id,
          canonicalName: getCanonicalName({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          }),
          name: getProductName({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          }),
          version: edge.node.version,
          marketProductLabel: getProduct({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          })
            .map((p) => p.marketProductLabel)
            .orNull(),
          productLabelColor: getProduct({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          })
            .map((p) => p.productLabelColor)
            .orNull(),
          isHighlighted: getProduct({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          })
            .map((p) => p.isHighlighted)
            .orElse(false),
        },
        version: edge.node.version,
        pq: edge.node.pq,
        pComp: edge.node.pComp,
        polarization: edge.node.polarization,
      }),
    );
  };

  const getReshapedBulkEditProducts = (
    productSummary: ReportSummaryResponse,
  ): reports.BulkEditingProduct[] => {
    return productSummary.allReportJobs.nodes[0].reportSummariesByReportId.edges
      .map((edge, index) => ({
        productByProductId: {
          id: edge.node.productByProductId.id,
          canonicalName: getCanonicalName({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          }),
          name: getProductName({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          }),
          version: edge.node.version,
          marketProductLabel: getProduct({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          })
            .map((p) => p.marketProductLabel)
            .orNull(),
          productLabelColor: getProduct({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          })
            .map((p) => p.productLabelColor)
            .orNull(),
        },
      }))
      .map((item) => item.productByProductId);
  };

  const EditButton = withStyles({
    root: {
      '& .show-on-hover': {
        display: 'none',
      },
      '&:hover .show-on-hover': {
        display: 'flex',
      },
    },
  })(IconButton);

  const EditButtonTableCell = withStyles({
    root: {
      padding: '16px 16px 16px 18px',
    },
  })(TableCell);

  const normsDummyDetailRows = (
    normsData: reports.ReportPQNormsResponse,
  ): KeyedReportDetailRow[] => {
    const stackRankNorms = normsData.allRpStackrankNorms.nodes[0];

    if (stackRankNorms == null || stackRankNorms == undefined) {
      return [];
    } else {
      return [
        { label: 'Product Set 25th Percentile', pq: stackRankNorms.q1 },
        { label: 'Product Set Median', pq: stackRankNorms.median },
        { label: 'Product Set 75th Percentile', pq: stackRankNorms.q3 },
      ].map((d, i) => ({
        pComp: null,
        polarization: null,
        pq: `${d.pq}`,
        productByProductId: {
          id: null,
          name: d.label,
          version: null,
          canonicalName: null,
          productLabelColor: null,
          isHighlighted: false,
        },
        version: null,
        key: -1 * (i + 1),
      }));
    }
  };

  const anyLoading = loading || normsLoading;
  const anyError = error || normsError;

  if (
    anyLoading ||
    anyError ||
    (projectId && customProductNames?.length == 0)
  ) {
    return <LoadingScreen />;
  }

  function isInArray(acc: KeyedReportDetailRow[], cur: KeyedReportDetailRow) {
    if (acc.length) {
      return acc
        .map(
          (i) =>
            i.productByProductId.id == cur.productByProductId.id &&
            i.version === cur.version,
        )
        .reduce((a, b) => a || b);
    } else {
      return false;
    }
  }

  return (
    <div>
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={5}>
          <h5>{t('reports.prodSummary.title')}</h5>
        </Grid>
        <Grid item xs={6}>
          {showOriginalProductsToggle(
            reportId,
            parentReportId,
            reportSummary,
          ) && (
            <FormControlLabel
              control={
                <Switch
                  checked={displayParentReport}
                  onChange={(e) => setDisplayParentReport(e.target.checked)}
                  name="displayParentReport"
                />
              }
              label={
                <Typography variant="caption">
                  {t('reports.prodSummary.displayOriginalProducts')}
                </Typography>
              }
            />
          )}
        </Grid>
        <Grid item xs={1}>
          <ReportSummaryCsv
            reportName={selectedReportName}
            reportSummaryData={getReshapedData(reportSummary, reportId)}
          />
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Tooltip
                    title={t('reports.prodSummary.excludeAllProducts')}
                    arrow
                  >
                    <Checkbox
                      color="secondary"
                      checked={
                        excludedProducts.size === customProductNames.length
                      }
                      onChange={(event, checked) =>
                        toggleAllInclusion(checked, customProductNames)
                      }
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {t('reports.prodSummary.product')}
                  {allowBulkLabelEdit(customerPreferences) && (
                    <EditButton
                      size="small"
                      onClick={() => {
                        setBulkEditingProduct(
                          getReshapedBulkEditProducts(reportSummary),
                        );
                        setOpenBulkEditModal(true);
                      }}
                    >
                      <CreateOutlined color="primary" fontSize="small" />
                    </EditButton>
                  )}
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      <div>
                        <em>{t('reports.prodSummary.pq')}</em>
                        {t('explainers.pqContent')}
                      </div>
                    }
                    arrow
                  >
                    <div>{t('reports.prodSummary.pq')}</div>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      <div>
                        <em>{t('reports.prodSummary.winRate')}</em>
                        {t('explainers.winRateContent')}
                      </div>
                    }
                    arrow
                  >
                    <div>{t('reports.prodSummary.winRate')}</div>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  {t('reports.prodSummary.compare')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getReshapedData(reportSummary, reportId)
                // Add parent report times if exists
                .concat(
                  parentReportSummary
                    ? getReshapedData(
                        parentReportSummary,
                        getDisplayedParentReportId(
                          displayParentReport,
                          parentReportId,
                        ),
                        reportSummary.allReportJobs.nodes[0]
                          .reportSummariesByReportId.edges.length,
                      )
                    : [],
                )
                // Filter out duplicates
                .reduce(
                  (acc: KeyedReportDetailRow[], cur: KeyedReportDetailRow) =>
                    isInArray(acc, cur) ? acc : acc.concat(cur),
                  [],
                )
                // Add stack rank norms rows
                .concat(
                  !showOriginalProductsToggle(
                    reportId,
                    parentReportId,
                    reportSummary,
                  ) && showStackRankNorms(customerPreferences)
                    ? normsDummyDetailRows(normsData)
                    : [],
                )
                // Sort by PQ descending
                .sort((a, b) => sortByPq(a, b))
                .map((row) =>
                  !row.productByProductId.id ? (
                    <TableRow key={row.key} className={styles.normRow}>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        {row.productByProductId.name}
                      </TableCell>
                      <TableCell align="right">
                        <span>{parseFloat(row.pq).toFixed(2)}</span>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) : (
                    <TableRow
                      key={row.key}
                      style={{
                        background: isProductHighlighted({
                          productNames: customProductNames,
                          productId: row.productByProductId.id,
                          version: row.version,
                        })
                          ? HIGHLIGHT_COLOR
                          : '',
                      }}
                    >
                      <TableCell align="center">
                        <Tooltip
                          title={t('reports.prodSummary.excludeProduct')}
                          arrow
                        >
                          <Checkbox
                            color="secondary"
                            checked={excludedProducts.has(
                              new ProductVersion(
                                row.productByProductId.id,
                                row.version,
                              ),
                            )}
                            onChange={() =>
                              handleReportInclusion(
                                new ProductVersion(
                                  row.productByProductId.id,
                                  row.version,
                                ),
                              )
                            }
                          />
                        </Tooltip>
                      </TableCell>
                      <EditButtonTableCell
                        onMouseEnter={() =>
                          toggleShowEdit(
                            new ProductVersion(
                              row.productByProductId.id,
                              row.version,
                            ),
                            true,
                          )
                        }
                        onMouseLeave={() => toggleShowEdit(null, false)}
                      >
                        {!mapOnly ? (
                          <Link
                            key={row.key + '-link'}
                            className={`restore-${row.key}`}
                            to={{
                              pathname: formatPath(REPORT_PRODUCT_SUMMARY, {
                                projectId: projectId,
                                reportId: row.reportId,
                                productId: row.productByProductId.id,
                                version: row.version,
                              }),
                              state: { routeBackLocation: routeBackLocation },
                            }}
                          >
                            <span
                              style={{
                                textDecoration: excludedProducts.has(
                                  new ProductVersion(
                                    row.productByProductId.id,
                                    row.version,
                                  ),
                                )
                                  ? 'line-through'
                                  : 'none',
                              }}
                            >
                              {row.productByProductId.name}
                            </span>
                          </Link>
                        ) : (
                          <span
                            style={{
                              textDecoration: excludedProducts.has(
                                new ProductVersion(
                                  row.productByProductId.id,
                                  row.version,
                                ),
                              )
                                ? 'line-through'
                                : 'none',
                            }}
                          >
                            {row.productByProductId.name}
                          </span>
                        )}
                        <EditButton
                          style={{
                            visibility:
                              showEdit &&
                              new ProductVersion(
                                row.productByProductId.id,
                                row.version,
                              ).value === editingProductVersionValue
                                ? 'visible'
                                : 'hidden',
                          }}
                          size="small"
                          onClick={() => {
                            setEditingProduct({
                              id: row.productByProductId.id,
                              name: row.productByProductId.name,
                              version: row.version,
                              marketLabel:
                                row.productByProductId.marketProductLabel,
                              labelColor:
                                row.productByProductId.productLabelColor,
                              isHighlighted:
                                row.productByProductId.isHighlighted,
                            });
                            setOpenCreateModal(true);
                          }}
                          onMouseEnter={() =>
                            toggleShowEdit(
                              new ProductVersion(
                                row.productByProductId.id,
                                row.version,
                                row.reportId,
                              ),
                              true,
                            )
                          }
                          onMouseLeave={() => toggleShowEdit(null, false)}
                        >
                          <CreateOutlined color="primary" fontSize="small" />
                        </EditButton>
                      </EditButtonTableCell>
                      <TableCell align="right">
                        <span
                          style={{
                            textDecoration: excludedProducts.has(
                              new ProductVersion(
                                row.productByProductId.id,
                                row.version,
                                row.reportId,
                              ),
                            )
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          {parseFloat(row.pq).toFixed(2)}
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={{
                            pathname: formatPath(PAIRED_COMPARISON_PREFERENCE, {
                              projectId,
                              reportId,
                            }),
                          }}
                        >
                          <span
                            style={{
                              textDecoration: excludedProducts.has(
                                new ProductVersion(
                                  row.productByProductId.id,
                                  row.version,
                                  row.reportId,
                                ),
                              )
                                ? 'line-through'
                                : 'none',
                            }}
                          >
                            {parseFloat(row.pComp).toFixed(0)}%
                          </span>
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        {!excludedProducts.has(
                          new ProductVersion(
                            row.productByProductId.id,
                            row.version,
                            row.reportId,
                          ),
                        ) && (
                          <Checkbox
                            color="secondary"
                            checked={
                              !!selectedProducts.has(
                                new ProductVersion(
                                  row.productByProductId.id,
                                  row.version,
                                  row.reportId,
                                ),
                              )
                            }
                            disabled={
                              mapOnly ||
                              (selectedProducts.size === 2 &&
                                !selectedProducts.has(
                                  new ProductVersion(
                                    row.productByProductId.id,
                                    row.version,
                                    row.reportId,
                                  ),
                                ))
                            }
                            onChange={() =>
                              handleProductSelection(
                                selectedProducts,
                                new ProductVersion(
                                  row.productByProductId.id,
                                  row.version,
                                  row.reportId,
                                ),
                              )
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ),
                )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      {projectId && (
        <ReportSummaryModal
          open={openCreateModal}
          title={t('reports.prodSummary.customEditTitle')}
        >
          <ReportCustomProductCreateContainer
            projectId={projectId}
            productId={editingProduct.id}
            canonicalName={getCanonicalName({
              productNames: customProductNames,
              productId: editingProduct.id,
              productName: editingProduct.name,
              version: editingProduct.version,
            })}
            productName={getProductName({
              productNames: customProductNames,
              productId: editingProduct.id,
              productName: editingProduct.name,
              version: editingProduct.version,
            })}
            version={editingProduct.version}
            marketProductLabel={editingProduct.marketLabel}
            productLabelColor={editingProduct.labelColor}
            isHighlighted={editingProduct.isHighlighted}
            closeModal={closeModal}
            customList={customProductNames.filter((cpn) => !!cpn.customId)}
          />
        </ReportSummaryModal>
      )}
      {projectId && (
        <ReportSummaryModal
          open={openBulkEditModal}
          title={t('reports.prodSummary.customEditTitle')}
        >
          <BulkEditCustomNamesContainer
            projectId={projectId}
            closeModal={closeModal}
            customList={customProductNames.filter((cpn) => !!cpn.customId)}
            productInformation={bulkEditingProduct}
          />
        </ReportSummaryModal>
      )}
    </div>
  );
};

export default ReportSummary;
