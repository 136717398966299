import { gql } from '@apollo/client';

export default gql`
  query TextureClusterStandardError($reportID: UUID) {
    allRpTextureStandardErrors(condition: { reportId: $reportID }) {
      nodes {
        clusterIdx
        standardError
      }
    }
  }
`;
