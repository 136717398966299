import { reduxForm } from 'redux-form';

import GroupWorkspaceRedux from './GroupWorkspaceRedux';
import formSubmit from '../../actions/formSubmit';
import { GROUP_PRODUCER_FORM } from '../../constants/formNames';

export default reduxForm({
  form: GROUP_PRODUCER_FORM,
  onSubmit: (values, dispatch) => {
    dispatch(formSubmit(GROUP_PRODUCER_FORM));
  },
  enableReinitialize: true,
})(GroupWorkspaceRedux);
