import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import headers from '../../styles/header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '95%',
      maxWidth: '80%',
      padding: '4.2rem 3.2rem',
    },
    header: {
      ...headers(theme),
      fontSize: '24px',
      color: theme.palette.primary.main,
    },
  }),
);

export default useStyles;
