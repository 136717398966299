import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';

import QueryPagination from './queryPagination';

type PageInfo = {
  startCursor: string;
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

type LinkLocation = string | object;

type ContainerProps = {
  after?: string;
  before?: string;
  getNextButtonLinkLocation: (pageInfo: PageInfo) => LinkLocation;
  getPreviousButtonLinkLocation: (pageInfo: PageInfo) => LinkLocation;
  getQueryResultNodes: (result: object) => object[];
  getQueryResultPageInfo: (result: object) => PageInfo;
  pageLength: number;
  query: any;
  queryVariables?: object;
};

const GraphQLContainer = (props: ContainerProps) => {
  const Container = graphql(props.query, {
    options: {
      variables: {
        ...props.queryVariables,
        first: !props.before ? props.pageLength : undefined,
        after: props.after,
        last: props.before ? props.pageLength : undefined,
        before: props.before,
      },
    },
    props: ({ data }) => {
      const pageInfo = props.getQueryResultPageInfo(data);

      return {
        loading: data.loading,
        nextButtonLinkLocation:
          pageInfo &&
          pageInfo.hasNextPage &&
          props.getNextButtonLinkLocation(pageInfo),
        previousButtonLinkLocation:
          pageInfo &&
          pageInfo.hasPreviousPage &&
          props.getPreviousButtonLinkLocation(pageInfo),
        resultNodes: props.getQueryResultNodes(data),
      };
    },
  })(QueryPagination);

  return <Container {...props} />;
};

GraphQLContainer.displayName = `GraphQLContainer(${QueryPagination.displayName})`;

export default GraphQLContainer;
