import { gql } from '@apollo/client';

export default gql`
  mutation CreateProductCategoryMutation(
    $productCategory: ProductCategoryInput!
  ) {
    createProductCategory(input: { productCategory: $productCategory }) {
      productCategory {
        producerId
        name
      }
    }
  }
`;
