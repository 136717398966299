import { OPTIMIZATION, PRODUCT_UPDATE } from 'constants/report';

import { gql } from '@apollo/client';
import { uniq } from 'lodash';
import moment from 'moment';
import { maximumReportRequestCount } from 'services/customerPreferences';
import { v4 as uuidv4 } from 'uuid';

import { nvlObject } from './afsUtils';
import graphqlClient from '../consumers/graphqlClient';

/**
 * Check if workspace should be selected by default.
 * Should select *AFS*, *CLT*, and target workspace name by default.
 *
 * @param workspaceName test workspace name
 * @param targetWorkspaceName report target workspace name
 * @returns boolean for if should be default allowed
 */
export const defaultAllowedWorkspace = (
  workspaceName: string,
  targetWorkspaceName: string,
): boolean => {
  return (
    workspaceName.includes('AFS') ||
    workspaceName.includes('CLT') ||
    workspaceName === targetWorkspaceName
  );
};

/**
 * Limits the number of reports that can be requested by a partner admin.
 * @param viewerUserId
 * @param workspaceID
 * @returns Boolean value, true if number of report jobs in the past 24hrs exceeded quota
 */
export const exceededRequestReportQuota = async (
  viewerUserId: number,
  workspaceID: number,
) => {
  const query = gql`
    query ReportRequestCount($workspaceID: Int, $yesterday: Datetime) {
      allReportJobs(
        condition: { workspaceId: $workspaceID }
        filter: { startedAt: { greaterThan: $yesterday } }
      ) {
        totalCount
      }
    }
  `;

  const limit = await maximumReportRequestCount(viewerUserId, workspaceID);

  const response = await graphqlClient.query({
    query,
    variables: {
      workspaceID,
      yesterday: moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
    },
  });

  return response.data.allReportJobs.totalCount > limit;
};

export const jobDefinition = (
  environment: SensoryLinkEnvironment,
): conch.JobDefinition => {
  switch (environment) {
    case 'production':
      return 'gg-report-main';
    case 'staging':
      return 'gg-report-staging';
    case 'development':
      return 'gg-report-dev';
    default:
  }
};

export const getJobQueue = (numCores: number) => {
  if (numCores <= 8) {
    return 'gg-queue-efs-2xlarge';
  } else if (numCores <= 16) {
    return 'gg-queue-efs-4xlarge';
  } else {
    return 'gg-queue-efs-8xlarge';
  }
};

/**
 * Compute the likely number of cores needed for a given report
 *
 * (https://eng-infra.gastrograph.com/metabase/question/36-product-count-by-category)
 *
 * @param competitive_set_folders categories of the report
 * @param competitive_set_products products of the report
 * @returns number of cores to assign to the job
 */
export const getNumCores = (
  competitive_set_folders: string[],
  competitive_set_products: string[],
): number => {
  if (
    competitive_set_folders.some((category) =>
      ['soda', 'chip'].includes(category),
    )
  )
    return 31;
  if (competitive_set_products.length > 25) return 31;

  // Fallthrough
  return 15;
};

/**
 * Create the conch params json for market survey reports
 *
 * @param formValues form values from UI form
 * @param viewerInfoData user information
 * @returns market survey params
 */
export const createMarketSurveyParams = (
  formValues: any,
  viewerInfoData: any,
): conch.MarketSurveyReportParams => {
  return {
    client_name: formValues.client_name.value,
    project_name: formValues.project_name,
    client_allowed_workspaces: formValues.client_allowed_workspaces,
    workspace_id:
      typeof formValues.workspace_id === 'object'
        ? formValues.workspace_id.id
        : formValues.id,
    report_type: 'market_survey',
    reportUID: uuidv4(),
    job_definition: formValues.job_definition,
    profilingEnabled: formValues.profilingEnabled,
    generate_ppt: formValues.generate_ppt,
    include_texture: formValues.include_texture,
    texture_only: formValues.texture_only,
    texture_data_only: formValues.texture_data_only,
    max_num_texture_allowed: null,
    texture_weight_threshold_list: 2,
    refresh_texture: false,
    showConfidence: formValues.showConfidence,
    show_TC_se: formValues.show_TC_se,
    texture_community_path: null,
    include_archetypes: formValues.include_archetypes,
    n_review_archetype: 2,
    n_review_archetype_max: 5,
    min_unique_reviewers: 20,
    n_cluster_archetype: 2,
    n_cluster_archetype_max: 6,
    arch_age_start: [20, 30, 40, 50, 60],
    include_market_maps: true,
    include_pairwise_comparison: true,
    include_gradients: true,
    apply_margEffect_ggvar_range: true,
    maxRowSummaryTable: 30,
    maxRowDecomSubTable: 20,
    minReviews: 5,
    alcoholLegal: false,
    apply_doofus_factor: true,
    new_reference_flavors_allowed: false,
    is_opt_test_mode: false,
    hot_patch_post_label_prop_data: false,
    plotNorm: formValues.plotNorm,
    extrema_analysis: formValues.extrema_analysis,
    combine_artificial_sweeteners: formValues.combine_artificial_sweeteners,
    simulate_pq_values: formValues.simulate_pq_values,
    n_sims: formValues.n_sims,
    competitive_set_products: uniq(
      formValues.competitive_set_rich.map((d) => d.name),
    ),
    custom_class_db_products: uniq(
      formValues.custom_class_db_rich?.map((d) => d.name) ?? null,
    ),
    custom_class_db_name: formValues.custom_class_db_name,
    SansRejector: formValues.SansRejector,
    selected_product_ids: null,
    product_rename: null,
    supplement_competitive_set_products: false,
    competitive_set_folders: uniq(formValues.competitive_set_folders),
    competitive_set_base_components: null,
    competitive_set_other_components: null,
    competitive_set_components_categories_allowed: null,
    products_to_opt: null,
    constraint_levels: ['HC', 'MC', 'LC'],
    parallelCode: true,
    opt_file_to_load: null,
    concept_base_products: null,
    ref_flavors_to_explore: null,
    target_group_name: (
      ' ' +
      formValues.countries.value +
      (formValues.SansRejector.value ? ' sans rejectors' : '')
    )
      .replace(/\s\w/g, (c) => c.toUpperCase())
      .trim(),
    countries: [formValues.countries.value],
    usa_region: formValues.usa_region,
    clts: formValues.clts,
    region: null,
    experience_range: uniq(
      formValues.experience_range.reduce((a, b) => a.concat(b), []),
    ),
    smoking_habits: formValues.smoking_habits,
    age_ranges: formValues.age_ranges,
    age_filtering_list: null,
    include_female: formValues.include_female,
    include_male: 1 - formValues.include_female,
    glp_s3_key: 'data/global_label_prop.csv',
    glp_s3_bucket: 'gastrograph-argo-artifacts',
    asian: formValues.asian,
    black: formValues.black,
    hispanic: formValues.hispanic,
    white: formValues.white,
    post_label_prop_data_path: 'N/A',
    latest_date: null,
    archetype_seed: null,
    demo_id_path: null,
    PQ_model_path: null,
    ref_flavor_path: null,
    archetype_PQ_model_path: null,
    archetype_list_path: null,
    explode_and_remove_refFlavors: null,
    pull_glp_from_s3: true,
    show_pg_gNorm: formValues.show_pg_gNorm,
    includeNoveltyScores: formValues.includeNoveltyScores,
    only_include_parents: formValues.only_include_parents,
    numCores: getNumCores(
      formValues.competitive_set_folders,
      uniq(formValues.competitive_set_rich.map((d) => d.name)),
    ),
    requestedBy: viewerInfoData.name ?? viewerInfoData.email,
    plotTextureGradients: false,
    timestamp_start: nvlObject(formValues.timestamp_start, null),
    experimental_features: formValues.experimental_features,

    // need to implement
    slack_me: true,
  };
};

/**
 * Create the conch params json for optimization reports
 *
 * @param formValues form values from UI form
 * @param viewerInfoData user information
 * @returns optimization params
 */
export const createOptimizationParams = (
  formValues: any,
  viewerInfoData: any,
): conch.OptimizationReportParams => {
  const opt_params = formValues.optimization;

  return {
    report_type: OPTIMIZATION,
    products_to_opt: [opt_params.products_to_opt.value],
    reportUID: uuidv4(),
    numCores: 15,
    ggvar_to_lock: opt_params.ggvars_to_lock.map((ggvar) => ggvar.value),
    ggvar_min_list: opt_params.ggvar_constraints.map((minmax) =>
      Math.min(...minmax),
    ),
    ggvar_max_list: opt_params.ggvar_constraints.map((minmax) =>
      Math.max(...minmax),
    ),
    constraint_levels: [
      opt_params.UHC_constraint ? 'UHC' : null,
      opt_params.VHC_constraint ? 'VHC' : null,
      opt_params.HC_constraint ? 'HC' : null,
      opt_params.MC_constraint ? 'MC' : null,
      opt_params.LC_constraint ? 'LC' : null,
      opt_params.VLC_constraint ? 'VLC' : null,
      opt_params.ULC_constraint ? 'ULC' : null,
      opt_params.NC_constraint ? 'NC' : null,
    ].filter((constraint) => !!constraint),
    new_reference_flavors_allowed: getNewReferenceFlavorsAllowed(opt_params),
    rangeReff: opt_params.ref_flavor_to_tune.map((rff) => rff.value),
    rangeReffRange: computeRangeReffRange(opt_params),
    ref_flavor_to_lock: opt_params.ref_flavor_to_lock.map((rff) => rff.value),
    flavors_to_remove: opt_params.ref_flavor_to_remove.map((rff) => rff.value),
    show_pg_gNorm: opt_params.enable_norms,
    plotNorm: opt_params.plot_norm,
    show_tuning_plots: opt_params.tuning_plots,
    include_gradients: true,
    include_archetypes: false,
    profilingEnabled: false,
    slack_me: true,
    add_or_subtract: 'lock',
    run_flavor_opt: opt_params.optimize_flavor,
    run_texture_opt: opt_params.optimize_texture,
    job_definition: formValues.job_definition,
    parent_job_id: opt_params.parent_job_id,
    artifact_path: opt_params.artifact_path,
    workspace_id: opt_params.workspace_id,
    project_name: opt_params.project_name,
    client_name: opt_params.client_name,
    target_group_name: opt_params.target_group_name,
    requestedBy: viewerInfoData.name ?? viewerInfoData.email,
    opt_file_to_load: null,
  };
};

/**
 * Create the conch params json for prdouct update reports
 *
 * @param formValues form values from UI form
 * @param viewerInfoData user information
 * @returns update report params
 */
export const createUpdateReportParams = (
  formValues: any,
  viewerInfoData: any,
): conch.UpdateReportParams => {
  const original_products = formValues.update_products.original_products;
  const original_workspaces =
    formValues.update_products.client_allowed_workspaces;
  return {
    report_type: 'update_products',
    reportUID: uuidv4(),
    client_name: formValues.client_name.value,
    project_name: formValues.project_name,
    target_group_name: formValues.target_group_name,
    workspace_id:
      typeof formValues.workspace_id === 'object'
        ? formValues.workspace_id.id
        : formValues.id,
    parent_job_id: formValues.update_products.parent_job_id,
    original_products: original_products,
    incoming_products: formValues.competitive_set_rich
      .map((d) => d.name)
      .filter((d) => !original_products.includes(d)),
    competitive_set_products: formValues.competitive_set_rich.map(
      (d) => d.name,
    ),
    client_allowed_workspaces: formValues.client_allowed_workspaces,
    incoming_workspaces: formValues.client_allowed_workspaces.filter(
      (d) => !original_workspaces.includes(d),
    ),
    artifact_path: formValues.update_products.artifact_path,
    only_new_products_on_report: false, // need to implement,
    ensure_exact_pq: formValues.update_products.ensure_exact_pq,
    plotNorm: formValues.plotNorm,
    profilingEnabled: formValues.profilingEnabled,
    job_definition: formValues.job_definition,
    numCores: getNumCores(
      formValues.competitive_set_folders,
      uniq(formValues.competitive_set_rich.map((d) => d.name)),
    ),
    slack_me: formValues.slack_me,
    requestedBy: viewerInfoData.name ?? viewerInfoData.email,
  };
};

export const makeReportParams: (
  formValues: any,
  viewerInfoData: any,
) =>
  | conch.MarketSurveyReportParams
  | conch.OptimizationReportParams
  | conch.UpdateReportParams = (formValues, viewerInfoData) => {
  switch (formValues.report_type.value) {
    case OPTIMIZATION:
      return createOptimizationParams(formValues, viewerInfoData);
    case PRODUCT_UPDATE:
      return createUpdateReportParams(formValues, viewerInfoData);
    default:
      return createMarketSurveyParams(formValues, viewerInfoData);
  }
};

/**
 * Map the rangeReffRange to conch expectations
 *
 * @param opt_params form params
 * @returns
 */
export const computeRangeReffRange = (
  opt_params: any,
): { min: number[]; max: number[] } => {
  if (
    !opt_params ||
    !opt_params.rangeReffRange ||
    opt_params.rangeReffRange.length === 0
  ) {
    return {
      max: [],
      min: [],
    };
  } else {
    return {
      max: opt_params.rangeReffRange.map(
        (tuning_range) => Math.max(...tuning_range) / 100,
      ),
      min: opt_params.rangeReffRange.map(
        (tuning_range) => Math.min(...tuning_range) / 100,
      ),
    };
  }
};

/**
 * Compute the array based on params.  If no values set, default to New Reference Flavors allowed
 * @param opt_params optimization params object
 * @returns boolean array for 'new_reference_flavors_allowed'
 */
export function getNewReferenceFlavorsAllowed(opt_params: any): string[] {
  const retArray = []
    .concat(opt_params.NRF ? ['TRUE'] : [])
    .concat(opt_params.NNRF ? ['FALSE'] : []);

  return retArray.length === 0 ? ['TRUE'] : retArray;
}

/**
 * Generate the EFS artifact path
 *
 * @param startedAt
 * @param clientName
 * @param projectName
 * @param targetGroupName
 * @returns
 */
export const constructArtifactPath = (
  startedAt: string,
  clientName: string,
  projectName: string,
  targetGroupName: string,
) => {
  const [date, timeWithMillis] = startedAt.split('T');
  const [hours, minutes, secondsWithMillis] = timeWithMillis.split(':');
  const seconds = secondsWithMillis.split('.')[0]; // Remove milliseconds
  const tstamp = `${date}_${hours}-${minutes}-${seconds}`;
  return [clientName, projectName, targetGroupName, tstamp]
    .join('/')
    .toLowerCase()
    .replace(/\s+/g, '_');
};
