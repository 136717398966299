import { reduxForm } from 'redux-form';

import AddUserDialog from './AddUserDialog';
import formSubmit from '../../../actions/formSubmit';
import { ADD_USER_PRODUCER_FORM } from '../../../constants/formNames';

export default reduxForm({
  form: ADD_USER_PRODUCER_FORM,
  onSubmit: (values, dispatch) => {
    dispatch(formSubmit(ADD_USER_PRODUCER_FORM));
  },
})(AddUserDialog);
