import { groupBy } from 'lodash';

export interface TextureCluster {
  [x: string]: { texture: string; confidence: number }[];
}

/**
 * Turn the graphql reponse into clusters, filted by min confidence
 * @param data ReportTextureClusterResponse graphql data
 * @param confidence minimum confidence expected
 * @returns Clusters
 */
export const mapAndRegroupClusterTexturesMinConfidence = (
  data: reports.ReportTextureClusterResponse,
  confidence: number,
): TextureCluster[] => {
  if (!data?.allRpTextureClusters?.nodes) return [];

  const groupedCluster = data.allRpTextureClusters.nodes.reduce(
    (acc, curr) => {
      acc[curr.clusterIdx] = acc[curr.clusterIdx] ?? [];
      if (curr.confidence >= confidence) {
        acc[curr.clusterIdx] = acc[curr.clusterIdx].concat(curr);
        acc[curr.clusterIdx].sort((t1, t2) =>
          Number(t1.confidence < Number(t2.confidence) ? 1 : -1),
        );
      }
      return acc;
    },
    {} as Record<number, reports.AllRpTextureClustersNode[]>,
  );

  return Object.keys(groupedCluster).map((key) => ({
    [`TC${key}`]: groupedCluster[key].map((item) => ({
      texture: item.texture.replace(/TX/, ''),
      confidence: Math.round(item.confidence),
    })),
  }));
};

export const computeDefaultMinConfidence = (
  data: reports.ReportTextureClusterResponse,
): number => {
  if (!data?.allRpTextureClusters?.nodes) return 100;

  const groupedByCluster = groupBy(
    data.allRpTextureClusters.nodes,
    'clusterIdx',
  );
  const largestConfidenceByCluster = Object.values(groupedByCluster).map(
    (arr) =>
      arr
        .map((i) => parseInt(i.confidence.toString()))
        .reduce((a, b) => (a > b ? a : b), 0),
  );
  return largestConfidenceByCluster.reduce((a, b) => (a > b ? b : a), 100);
};
