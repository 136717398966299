import { gql } from '@apollo/client';

export default gql`
  mutation MapReferenceFlavorMutation(
    $referenceFlavorTranslationId: Int!
    $translation: String
  ) {
    updateReferenceFlavorTranslationById(
      input: {
        id: $referenceFlavorTranslationId
        referenceFlavorTranslationPatch: { translation: $translation }
      }
    ) {
      referenceFlavorTranslation {
        id
        referenceFlavorId
        language
        translation
      }
    }
  }
`;
