interface Params {
  email: string;
  producerId: number;
}

export default function httpInviteUserEmail(params: Params) {
  return fetch('/iam/inviteUserEmail', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  }).then((response) => {
    if (response.ok) {
      return response.text();
    } else {
      return response.text().then((message) => {
        throw new Error(message);
      });
    }
  });
}
