import { gql } from '@apollo/client';

export default gql`
  query TextureDecompByReportIdAndProductIDQuery(
    $reportID: UUID!
    $productID: Int
    $version: String
  ) {
    allRpTextureDecomps(
      condition: {
        reportId: $reportID
        productId: $productID
        version: $version
      }
    ) {
      nodes {
        id
        reportId
        productId
        version
        productByProductId {
          id
          name
        }
        tcIdx
        currentIntensity
        dataJsonb
        version
      }
    }
  }
`;
