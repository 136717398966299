import { getFormValues, startSubmit, stopSubmit, destroy } from 'redux-form';
import { call, put, select, take } from 'redux-saga/effects';

import appToastAdd from '../actions/appToastAdd';
import errorAction from '../actions/error';
import { FORM_SUBMIT } from '../actions/formSubmit';
import { CREATE_USER_FORM } from '../constants/formNames';
import { allRolesLower } from '../constants/userRoles';
import createUser from '../consumers/httpCreateUser';
import viewUserRoles from '../consumers/httpViewUserRoles';
import selectSessionToken from '../selectors/sessionToken';

function parseErrorMessage(error) {
  if (error.response?.data?.detail != null) {
    return error.response?.data?.detail;
  } else {
    return error.message;
  }
}

export default function* createUserFormSubmitSaga() {
  while (true) {
    yield take(
      ({ type, payload }) =>
        type === FORM_SUBMIT && payload === CREATE_USER_FORM,
    );

    yield put(startSubmit(CREATE_USER_FORM));

    const token = yield select(selectSessionToken);

    const createUserFormValues = yield select(getFormValues(CREATE_USER_FORM));

    const newUser = {
      email:
        createUserFormValues.email && createUserFormValues.email.toLowerCase(),
      username: createUserFormValues.username.toLowerCase(),
      password: createUserFormValues.password,
      partner_id: createUserFormValues.partner?.id ?? null,
      roles: allRolesLower.filter((role) =>
        Object.keys(createUserFormValues).includes(role),
      ),
    };

    try {
      // Submit createUserFormValues via createJSONWebToken...
      const createUserResponse = yield call(createUser, newUser);
      const userId = createUserResponse.userId;

      // Return the user roles
      yield call(viewUserRoles, userId, token);

      // If this point is reached, the form was submitted without error
      yield put(stopSubmit(CREATE_USER_FORM));

      yield put(
        appToastAdd({
          durationMilliseconds: 4000,
          message: `User ${userId} Created`,
          title: 'User Creation Successful',
          toastKey: `toast_${Date.now()}`,
        }),
      );

      // Destroy the form so that it is re-rendered after the below route change
      yield put(destroy(CREATE_USER_FORM));
    } catch (error) {
      yield put(stopSubmit(CREATE_USER_FORM, error));
      yield put(
        errorAction({
          error,
          title: 'Failed to Create User',
          description: parseErrorMessage(error),
        }),
      );
    }
  }
}
