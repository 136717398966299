import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '../../../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: COLORS.RICE_WHITE,
      marginTop: 10,
      borderRadius: 0,
      boxShadow: '0 0',
    },
    cardTitle: {
      fontFamily: 'AlphaHeadlinePro-Bold',
      fontSize: 16,
      color: theme.palette.secondary.main,
    },
    cardSubheader: {
      fontSize: 12,
      color: COLORS.MARINE_FADED,
    },
  }),
);

export default useStyles;
