import * as d3 from 'd3';

import { CustomerPreferences } from 'services/customerPreferences';

import {
  computeRMax,
  rZoomBehavior,
  rotateLabelData,
  selectGgvarScale,
} from './layerUtils';

// Vectors Layer
// This renders the vector arrows
export function vectorsLayer(
  container: d3.Selection<SVGElement, any, null, undefined>,
  r: number,
  zoom: number,
  rotationAngle: number,
  tx: number,
  ty: number,
  ggVarsArray: marketmap.LabelData[],
  customerPreferences: CustomerPreferences,
  vectorTransparency: number,
) {
  if (ggVarsArray.length == 0) {
    return;
  }

  // arrowhead definition (used as marker-end below)
  const s = 12;
  const arrowPoints: [number, number][] = [
    [0, 0],
    [0, s],
    [s, s / 2],
  ];
  const refX = s;
  const refY = s / 2;

  container
    .append('defs')
    .append('marker')
    .attr('id', 'arrow')
    .attr('viewBox', [0, 0, s, s])
    .attr('refX', refX)
    .attr('refY', refY)
    .attr('markerWidth', s)
    .attr('markerHeight', s)
    .attr('orient', 'auto-start-reverse')
    .append('path')
    .attr('d', d3.line()(arrowPoints))
    .attr('fill', 'black')
    .attr('stroke', 'black')
    .attr('stroke-opacity', vectorTransparency);

  const ggvar_r_max = computeRMax(ggVarsArray);

  const ggvar_scale = selectGgvarScale(
    customerPreferences,
    r,
    zoom,
    ggvar_r_max,
  );

  container.select('#vectorLayer').remove();

  const ggvar_group = container.append('g').attr('id', 'vectorLayer');

  const rotatedGgVarsArray = rotateLabelData(ggVarsArray, rotationAngle);

  const ggvar_gi = ggvar_group
    .selectAll('g')
    .data(rotatedGgVarsArray)
    .enter()
    .append('g');

  ggvar_gi
    .append('line')
    .attr('x1', 0 - tx)
    .attr('y1', 0 - ty)
    .attr('x2', (d) => +ggvar_scale * d.ld1 - tx)
    .attr('y2', (d) => -ggvar_scale * d.ld2 - ty)
    .style('stroke', 'black')
    .style('stroke-width', r / 240)
    .attr('stroke-opacity', vectorTransparency)
    .attr('marker-end', 'url(#arrow)')
    .attr('opacity', vectorTransparency);

  ggvar_gi
    .append('line')
    .attr('x1', 0 - tx)
    .attr('y1', 0 - ty)
    .attr(
      'x2',
      (d) =>
        rZoomBehavior(r, zoom, customerPreferences) * Math.cos(-d.angle) - tx,
    )
    .attr(
      'y2',
      (d) =>
        rZoomBehavior(r, zoom, customerPreferences) * Math.sin(-d.angle) - ty,
    )
    .style('stroke', 'black')
    .style('stroke-width', r / 240)
    .attr('stroke-opacity', vectorTransparency)
    .style('stroke-dasharray', (r / 240) * 8);
}
