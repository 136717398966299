import * as React from 'react';

import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import OrderOfOperations from 'components/Report/OrderOfOperations';
import { ProductVersion } from 'components/Report/ProductVersion';
import { getProductName } from 'components/Report/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { useTranslation } from 'react-i18next';
interface Props {
  reportId: string;
  projectId: string;
}

const ProjectOptimizationSummary: React.FC<Props> = (props) => {
  const { reportId, projectId } = props;
  const { t } = useTranslation();

  const customProductNames = useCustomProductNames({
    projectId: Number(projectId),
    reportId,
  });
  const [activeProduct, setActiveProduct] = React.useState<string>();

  return (
    <Grid container>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <FormControl
          variant="standard"
          fullWidth
          style={{ marginBottom: '1rem' }}
        >
          <InputLabel shrink>{t('reports.product')}</InputLabel>
          <Select
            variant="standard"
            fullWidth
            value={activeProduct}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setActiveProduct(event.target.value)
            }
          >
            {customProductNames
              .filter((i) => i.version !== null)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((cpn) => new ProductVersion(cpn.id, cpn.version))
              .map((product) => (
                <MenuItem
                  key={product.value + 'versioned'}
                  value={product.value}
                >
                  {getProductName({
                    productNames: customProductNames,
                    productId: product.productId,
                    version: product.version,
                  })}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} />
      <Grid xs={10}>
        <OrderOfOperations
          reportID={reportId}
          activeProduct={activeProduct}
          customProductNames={customProductNames}
        />
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default ProjectOptimizationSummary;
