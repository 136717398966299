import { createElement, ReactElement } from 'react';

import { reduxForm } from 'redux-form';

import formSubmit from '../../actions/formSubmit';

interface Props {
  formConfiguration?: object;
  formName: string;
  render?: (props: any) => ReactElement;
}

const FormContainer = ({ formConfiguration, formName, render }: Props) =>
  createElement(
    reduxForm({
      form: formName,
      onSubmit: (_, dispatch) => dispatch(formSubmit(formName)),
      ...formConfiguration,
    })(render),
  );

FormContainer.displayName = 'FormContainer';

export default FormContainer;
