import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'AlphaHeadlinePro-Bold',
      color: theme.palette.primary.main,
      fontSize: 14,
    },
    label: {
      fontFamily: 'OpenSans',
      color: theme.palette.primary.main,
      fontSize: 14,
      marginBottom: 0,
    },
    helperText: {
      fontFamily: 'OpenSans',
      color: theme.palette.primary.light,
      fontSize: 12,
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkboxContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    checkboxLabel: {
      margin: 'auto',
      marginLeft: '5px',
      color: theme.palette.primary.main,
    },
    checkboxPrevLabel: {
      marginBottom: '0px',
    },
  }),
);

export default useStyles;
