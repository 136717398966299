import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionContainer: {
      paddingBottom: '10px',
      width: '30vw',
    },
    buttonContainer: {
      paddingTop: '18px',
    },
  }),
);

export default useStyles;
