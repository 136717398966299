import * as d3 from 'd3';

import {
  CustomerPreferences,
  zoomBehavior,
} from 'services/customerPreferences';

import { outerRingClippingPathR, rotateScaled } from './layerUtils';

// Contour Lines Layer
// This renders the heat map behind the map
export function contourLinesLayer(
  container: d3.Selection<SVGElement, any, null, undefined>,
  r: number,
  zoom: number,
  rotationAngle: number,
  contoursTransparency: number,
  tx: number,
  ty: number,
  width: number,
  height: number,
  productRMax: number,
  contourArray: marketmap.ContourMapData[],
  contourThreshold: number,
  contourBandwidth: number,
  customerPreferences: CustomerPreferences,
) {
  if (contourArray.length == 0) {
    return;
  }

  const r_zoom = r * zoom;

  const contourLinesData = rotateScaled(contourArray, rotationAngle);

  const cScale = d3
    .scaleLinear()
    .domain([-productRMax, productRMax])
    .range([0, 2 * r]);

  // contours
  const densityData = d3
    .contourDensity<marketmap.HeatMapData>()
    .x((d) => cScale(d.ld1) * zoom)
    .y((d) => cScale(-d.ld2) * zoom)
    .size([width * zoom, height * zoom])
    .bandwidth(contourBandwidth * zoom)
    .thresholds(contourThreshold)(contourLinesData);

  container.select('#contourLinesLayer').remove();

  const contour_group = container
    .append('g')
    .attr('id', 'contourLinesLayer')
    .attr('transform', `translate(${-r_zoom - tx},${-r_zoom - ty})`);

  contour_group
    .append('defs')
    .append('clipPath')
    .attr('id', 'clipping')
    .append('circle')
    .attr('id', 'p1')
    .attr('r', outerRingClippingPathR(r, zoom, customerPreferences))
    .attr('transform', `translate(${r_zoom - tx},${r_zoom - ty})`);

  const densityPath = contour_group
    .selectAll('path')
    .attr('style', 'display: none')
    .data(densityData)
    .enter()
    .append('path')
    .attr('d', d3.geoPath())
    .attr('fill', 'none')
    .attr('stroke', `rgba(128,128,128,${contoursTransparency})`)
    .attr('stroke-linejoin', 'round')
    .attr('pointer-events', 'none');

  if (zoomBehavior(customerPreferences) === 'microscope') {
    densityPath.attr('clip-path', 'url(#clipping)');
  }
}
