import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { bindActionCreators } from 'redux';

import FolderSystem from './FolderSystem';
import styles from './ProductFolderContainer.module.css';
import deleteFolder from '../../actions/deleteFolder';
import moveFolder from '../../actions/moveFolder';
import setProductFolderId from '../../actions/productFolderIdSet';
import FoldersQuery from '../../graphql/queries/FoldersQuery';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import { foldersToTree } from '../../utils/folderHelper';

class ProductFolderComponent extends React.Component<any, any> {
  state = {
    currentFolderId: 0,
    draggedOverId: null,
    draggedId: null,
    productDraggedId: null,
    expandId: null,
  };

  setDraggedOverId = (id) => this.setState({ draggedOverId: id });

  setDraggedId = (id) => this.setState({ draggedId: id });

  setProductDraggedId = (id) => this.setState({ productDraggedId: id });

  setExpandId = (id) => this.setState({ expandId: id });

  render() {
    const {
      products,
      dispatch,
      folderTree,
      productFolderId,
      setProductFolderId,
      producerId,
    } = this.props;

    if (!producerId) return <div />;

    const dragProps = {
      setDraggedOverId: this.setDraggedOverId,
      setDraggedId: this.setDraggedId,
      setProductDraggedId: this.setProductDraggedId,
      draggedOverId: this.state.draggedOverId,
      draggedId: this.state.draggedId,
      productDraggedId: this.state.productDraggedId,
      moveFolder: this.props.moveFolder,
      moveProduct: this.props.moveProduct,
    };

    return (
      <div className={styles.container}>
        <FolderSystem
          folderTree={folderTree}
          id={0}
          setFolderId={setProductFolderId}
          openFolder={productFolderId}
          dragProps={dragProps}
          dispatch={dispatch}
          deleteFolder={this.props.deleteFolder}
          products={products}
          expandId={this.state.expandId}
          setExpandId={this.setExpandId}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  producerId: selectWorkspaceProducerId(state),
  productTablePage: state.productTablePage,
  productFolderId: state.productFolderId,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      moveFolder,
      deleteFolder,
    },
    dispatch,
  ),
  dispatch,
  setProductFolderId: (id) => dispatch(setProductFolderId(id)),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  graphql(FoldersQuery, {
    options: (props: any) => ({
      variables: {
        condition: {
          producerId: props.producerId,
        },
      },
    }),
    name: 'folderResults',
  }),
  withProps(({ t, folderResults }) => {
    const folderTree = foldersToTree(t, folderResults.folders);
    return {
      folderTree,
    };
  }),
)(ProductFolderComponent) as React.ComponentType<any>;
