import { gql } from '@apollo/client';

export default gql`
  query MarketMapProducts($reportID: UUID!) {
    allRpMarketmapGgvarvectors(condition: { reportId: $reportID }) {
      nodes {
        ggvar
        ld1
        ld2
        angle
      }
    }
  }
`;
