import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportPQNormsQuery from '@graphql/queries/ReportPQNormsQuery';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary, {
  KeyedReportDetailRow,
  ReportSummaryResponse,
} from 'hooks/useReportSummary';
import { useTranslation } from 'react-i18next';
import {
  showStackRankNorms,
  useCustomerPreferences,
} from 'services/customerPreferences';

import styles from './ReportSummary.module.css';
import { ProductVersion } from '../ProductVersion';
import {
  getCanonicalName,
  getProduct,
  getProductName,
  sortByPq,
} from '../utils';

interface ReportProps {
  projectId?: number;
  reportId: string;
  hidePercentiles: boolean;
}

const ReportSummaryComparison: React.FC<ReportProps> = (props) => {
  const { projectId, reportId, hidePercentiles } = props;

  const { t } = useTranslation();

  const customerPreferences = useCustomerPreferences();

  const customProductNames = useCustomProductNames({
    projectId,
    reportId,
  });

  const {
    loading,
    error,
    data: reportSummary,
  } = useReportSummary(reportId, {
    fetchPolicy: 'no-cache',
  });

  const {
    loading: normsLoading,
    error: normsError,
    data: normsData,
  } = useQuery<reports.ReportPQNormsResponse>(ReportPQNormsQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const getReshapedData = (
    productSummary: ReportSummaryResponse,
    reportId: string,
    keyOffset?: number,
  ): KeyedReportDetailRow[] => {
    return productSummary.allReportJobs.nodes[0].reportSummariesByReportId.edges.map(
      (edge, index) => ({
        key: index + (keyOffset ?? 0),
        productVersion: new ProductVersion(
          edge.node.productByProductId.id,
          edge.node.version,
        ),
        reportId: reportId,
        productByProductId: {
          id: edge.node.productByProductId.id,
          isHighlighted: getProduct({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          })
            .map((p) => p.isHighlighted)
            .orElse(false),
          canonicalName: getCanonicalName({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          }),
          name: getProductName({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          }),
          version: edge.node.version,
          marketProductLabel: getProduct({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          })
            .map((p) => p.marketProductLabel)
            .orNull(),
          productLabelColor: getProduct({
            productNames: customProductNames,
            productId: edge.node.productByProductId.id,
            productName: edge.node.productByProductId.name,
            version: edge.node.version,
          })
            .map((p) => p.productLabelColor)
            .orNull(),
        },
        version: edge.node.version,
        pq: edge.node.pq,
        pComp: edge.node.pComp,
        polarization: edge.node.polarization,
      }),
    );
  };

  const normsDummyDetailRows = (
    normsData: reports.ReportPQNormsResponse,
  ): KeyedReportDetailRow[] => {
    const stackRankNorms = normsData.allRpStackrankNorms.nodes[0];

    if (stackRankNorms == null || stackRankNorms == undefined) {
      return [];
    } else {
      return [
        { label: '25th Percentile', pq: stackRankNorms.q1 },
        { label: 'Median', pq: stackRankNorms.median },
        { label: '75th Percentile', pq: stackRankNorms.q3 },
      ].map((d, i) => ({
        pComp: null,
        polarization: null,
        pq: `${d.pq}`,
        productByProductId: {
          id: null,
          name: d.label,
          version: null,
          canonicalName: null,
          productLabelColor: null,
          isHighlighted: false,
        },
        version: null,
        key: -1 * (i + 1),
      }));
    }
  };

  const anyLoading = loading || normsLoading;
  const anyError = error || normsError;

  if (
    anyLoading ||
    anyError ||
    (projectId && customProductNames?.length == 0)
  ) {
    return <LoadingScreen />;
  }

  function isInArray(acc: KeyedReportDetailRow[], cur: KeyedReportDetailRow) {
    if (acc.length) {
      return acc
        .map(
          (i) =>
            i.productByProductId.id == cur.productByProductId.id &&
            i.version === cur.version,
        )
        .reduce((a, b) => a || b);
    } else {
      return false;
    }
  }

  return (
    <div>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('reports.prodSummary.product')}</TableCell>
              <TableCell align="right">
                <Tooltip
                  title={
                    <div>
                      <em>{t('reports.prodSummary.pq')}</em>
                      {t('explainers.pqContent')}
                    </div>
                  }
                  arrow
                >
                  <div>{t('reports.prodSummary.pq')}</div>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Tooltip
                  title={
                    <div>
                      <em>{t('reports.prodSummary.winRate')}</em>
                      {t('explainers.winRateContent')}
                    </div>
                  }
                  arrow
                >
                  <div>{t('reports.prodSummary.winRate')}</div>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getReshapedData(reportSummary, reportId)
              // Filter out duplicates
              .reduce(
                (acc: KeyedReportDetailRow[], cur: KeyedReportDetailRow) =>
                  isInArray(acc, cur) ? acc : acc.concat(cur),
                [],
              )
              // Add stack rank norms rows
              .concat(
                !hidePercentiles && showStackRankNorms(customerPreferences)
                  ? normsDummyDetailRows(normsData)
                  : [],
              )
              // Sort by PQ descending
              .sort((a, b) => sortByPq(a, b))
              .map((row) =>
                !row.productByProductId.id ? (
                  <TableRow key={row.key} className={styles.normRow}>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      {row.productByProductId.name}
                    </TableCell>
                    <TableCell align="right">
                      <span>{parseFloat(row.pq).toFixed(2)}</span>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={row.key}>
                    <TableCell>{row.productByProductId.name}</TableCell>
                    <TableCell align="right">
                      {parseFloat(row.pq).toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {parseFloat(row.pComp).toFixed(0)}%
                    </TableCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      </Grid>
    </div>
  );
};

export default ReportSummaryComparison;
