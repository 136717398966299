import * as React from 'react';
import { SVGProps } from 'react';

import { SvgIcon } from '@mui/material';

const SvgComponent: React.FC = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={256}
    height={256}
    viewBox="0 0 256 256"
    {...props}
  >
    <g>
      <g>
        <path fill="#000000" d="M37.9,145.8h33v10.1h-33V145.8z" />
        <path fill="#000000" d="M37.9,120.4h33v10.1h-33V120.4z" />
        <path fill="#000000" d="M37.9,171.2h33v10.1h-33V171.2z" />
        <path fill="#000000" d="M37.9,92.5h33v10.1h-33V92.5z" />
        <path
          fill="#000000"
          d="M120,176.2c1.3,0,2.6-0.4,4.1-1.2l47-26.3c5.5-3.1,5.5-8.1,0-11.2l-47-26.3c-1.5-0.8-2.9-1.2-4.1-1.2c-3.5,0-5.9,3-5.9,8v9.9H86.1v30.3H114v9.9C114,173.2,116.5,176.2,120,176.2z"
        />
        <path
          fill="#000000"
          d="M25.2,21.4h126.9v10.1h15.2V21.4c0-8.4-6.8-15.2-15.2-15.2H10V199c0,8.4,6.8,15.2,15.2,15.2H76V199H25.2V21.4z"
        />
        <path
          fill="#000000"
          d="M185.1,41.7h-10.2H86.1v76.1h15.2V57h73.6v45.7c0,5.6,4.5,10.1,10.2,10.1h45.7v121.8H101.4v-66H86.1v66c0,8.4,6.8,15.2,15.2,15.2H246v-137v-10.1L185.1,41.7z M185.1,102.6V60.2l42,42.5L185.1,102.6L185.1,102.6z"
        />
      </g>
    </g>
  </svg>
);

const CloneReportIcon = (props) => (
  <SvgIcon component={SvgComponent} viewBox="0 0 256 256" {...props} />
);

export default CloneReportIcon;
