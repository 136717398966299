import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

interface Props {
  explainerModalOpen: boolean;
  setExplainerModalOpen: (value: boolean) => void;
}

const TextureTernaryExplainer: React.FC<Props> = (props) => {
  const { explainerModalOpen, setExplainerModalOpen } = props;

  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="right">
      <Modal
        title="Explainer"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
        }}
        open={explainerModalOpen}
        onClose={() => setExplainerModalOpen(false)}
      >
        <div
          style={{
            flex: 1,
            background: 'white',
            maxHeight: '75%',
            maxWidth: '75%',
            overflow: 'auto',
            padding: '15px',
            fontSize: 12,
            fontFamily: 'OpenSans, sans-serif',
            color: '#022950',
          }}
        >
          <p>{t('reports.textureTernary.explainers.overall')}</p>
          <p>
            <b>{t('reports.textureTernary.products')}</b>{' '}
            {t('reports.textureTernary.explainers.products')}
          </p>
          <p>
            <b>{t('reports.textureTernary.triangularPixels')}</b>{' '}
            {t('reports.textureTernary.explainers.triangularPixels')}
          </p>
          <p>
            <b>{t('reports.textureTernary.contours')}</b>{' '}
            {t('reports.textureTernary.explainers.contours')}
          </p>
        </div>
      </Modal>
      <IconButton
        onClick={() => setExplainerModalOpen(!explainerModalOpen)}
        size="large"
      >
        <InfoOutlinedIcon style={{ fontSize: 24, color: '#777' }} />
      </IconButton>
    </Box>
  );
};

export default TextureTernaryExplainer;
