import * as d3 from 'd3';

export interface RectbinBin<T> extends Array<T> {
  x?: number;
  y?: number;
  i?: number;
  j?: number;
}

export interface Rectbin<T> {
  (points: T[]): Array<RectbinBin<T>>;
  x(x: (d: T) => number): this;
  x(): (d: T) => number;
  y(y: (d: T) => number): this;
  y(): (d: T) => number;
  dx(dx: (d: T) => number): this;
  dx(): (d: T) => number;
  dy(dy: (d: T) => number): this;
  dy(): (d: T) => number;
}

export default function rectbin<T = [number, number]>(): Rectbin<T> {
  const rectbinX: (d: T) => number = (d) => d[0];
  const rectbinY: (d: T) => number = (d) => d[1];
  const idFunc: (pi: number, pj: number) => string = (pi, pj) => pi + '|' + pj;

  let dx = () => 0.1;
  let dy = () => 0.1;
  let x = rectbinX;
  let y = rectbinY;

  function rectbin(points: T[]): Array<RectbinBin<T>> {
    const binsById: { [key: string]: RectbinBin<T> } = {};

    const xExtent = d3.extent(points, function (d, i) {
      return x.call(rectbin, d, i);
    });
    const yExtent: [number, number] = d3.extent(points, function (d, i) {
      return y.call(rectbin, d, i);
    });

    d3.range(yExtent[0], yExtent[1] + dx(), dy()).forEach(function (Y) {
      d3.range(xExtent[0], xExtent[1] + dx(), dx()).forEach(function (X) {
        const py = Y / dy();
        const pj = trunc(py);
        const px = X / dx();
        const pi = trunc(px);
        const id = idFunc(pi, pj);
        const bin: RectbinBin<T> = (binsById[id] = []);
        bin.i = pi;
        bin.j = pj;
        bin.x = pi * dx();
        bin.y = pj * dy();
      });
    });
    points.forEach(function (point: T, i: number) {
      const py: number = y.call(rectbin, point, i) / dy();
      const pj: number = trunc(py);
      const px: number = x.call(rectbin, point, i) / dx();
      const pi: number = trunc(px);

      const id: string = idFunc(pi, pj);
      const bin: RectbinBin<T> = binsById[id];
      bin.push(point);
    });
    return Object.values(binsById);
  }

  rectbin.x = function (_) {
    return arguments.length ? ((x = _), rectbin) : x;
  };

  rectbin.y = function (_) {
    return arguments.length ? ((y = _), rectbin) : y;
  };

  rectbin.dx = function (_) {
    return arguments.length ? ((dx = _), rectbin) : dx;
  };

  rectbin.dy = function (_) {
    return arguments.length ? ((dy = _), rectbin) : dy;
  };

  return rectbin as Rectbin<T>;
}

function trunc(x: number) {
  return x < 0 ? Math.ceil(x) : Math.floor(x);
}
