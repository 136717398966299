import parsePostgresArray from '../utils/parsePostgresArray';

export default function httpViewUserRoles(
  userId: number,
  token: string,
): Promise<string[]> {
  return fetch(`/iam/viewUserRoles?userId=${userId}`, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then((message) => {
          throw new Error(message);
        });
      }
    })
    .then((response) => {
      return parsePostgresArray(response[0].roles);
    });
}
