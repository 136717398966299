import { ApolloError, gql, useQuery } from '@apollo/client';

const ReportTuningMinMaxPqQuery = gql`
  query ReportTuningMinMaxPqQuery($reportId: UUID) {
    getMinMaxPq(reportId: $reportId) {
      nodes {
        min
        max
      }
    }
  }
`;

interface ReportTuningMinMaxPqQueryRow {
  min: number;
  max: number;
}

interface ReportTuningMinMaxPqQueryResponse
  extends reports.ReportGraphQLReponse {
  getMinMaxPq: {
    nodes: ReportTuningMinMaxPqQueryRow[];
  };
}

export interface MinMaxPq {
  minPq: number;
  maxPq: number;
}

function computeMinMaxPq(
  minMaxPqData: ReportTuningMinMaxPqQueryResponse,
): MinMaxPq {
  if (minMaxPqData.getMinMaxPq.nodes.length > 0) {
    return minMaxPqData.getMinMaxPq.nodes
      .map((n) => ({ minPq: n.min, maxPq: n.max }))
      .find(Boolean);
  } else {
    return { minPq: 0, maxPq: 0 };
  }
}

export default function useMinMaxPq(reportId: string): {
  data: MinMaxPq;
  loading: boolean;
  error: ApolloError;
} {
  const { data, loading, error } = useQuery<ReportTuningMinMaxPqQueryResponse>(
    ReportTuningMinMaxPqQuery,
    {
      variables: {
        reportId,
      },
    },
  );

  if (loading || error) {
    return { data: null, loading, error };
  } else {
    return { data: computeMinMaxPq(data), loading, error };
  }
}
