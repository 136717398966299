import { gql } from '@apollo/client';

export default gql`
  mutation secureCreateWorkspace($workspace: secureProducerInput!) {
    secureCreateProducer(input: $workspace) {
      id
      name
    }
  }
`;
