import * as React from 'react';
import { useState, useEffect } from 'react';

import GetReferenceFlavorForUpdateQuery, {
  GetReferenceFlavorForUpdateResponse,
} from '@graphql/queries/GetReferenceFlavorForUpdateQuery';
import { ReferenceFlavorLanguage } from '@graphql/queries/GetReferenceFlavorLanguage';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Search from '@mui/icons-material/Search';
import { Select, MenuItem, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Alert from 'react-s-alert';

import CustomLexiconAddMappingContainer from './CustomLexiconAddMapping';
import CustomLexiconCreateContainer from './CustomLexiconCreate';
import { CsvFields } from './CustomLexiconCsvHelper';
import {
  capitalizeFirstLetter,
  splitCamelCase,
  reWord,
} from './CustomLexiconLanguageFormatter';
import styles from './CustomLexiconListTable.module.css';
import CustomLexiconTablePagination from './CustomLexiconTablePagination';
import { formatReferenceFlavorsList } from './util';
import LoadingScreen from '../../components/LoadingScreen';
import MaterialButton from '../../components/MaterialButton';
import CustomLexiconModal from '../../components/Modal/PopUp';
import Table from '../../components/Table';
import graphqlClient from '../../consumers/graphqlClient';
import CreateReferenceFlavorMutation from '../../graphql/mutations/CreateReferenceFlavor';
import CreateReferenceFlavorTranslationMutation from '../../graphql/mutations/CreateReferenceFlavorTranslationMutation';
import DeleteReferenceFlavorMutation from '../../graphql/mutations/DeleteReferenceFlavor';
import MapReferenceFlavorMutation from '../../graphql/mutations/MapReferenceFlavor';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';

interface Props {
  producersReferenceFlavors: any;
  languages: ReferenceFlavorLanguage[];
  groupAndUpdateLexicons: any;
}

const CustomLexiconListTable: React.FC<Props> = ({
  producersReferenceFlavors,
  languages,
  groupAndUpdateLexicons,
}) => {
  const duplicateFlag = 23505;
  const deletedFlag = 23514;

  const { t } = useTranslation();

  const workspaceProducerId = useSelector((state) =>
    selectWorkspaceProducerId(state),
  );

  const [baseData, setBaseData] = useState(producersReferenceFlavors);
  const [tableData, setTableData] = useState(producersReferenceFlavors);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('EN');
  const [selectedLanguageName, setSelectedLanguageName] =
    useState<string>('English');
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openMappingModal, setOpenMappingModal] = useState(false);
  const [changedReferenceFlavors, setChangedReferenceFlavors] = useState(
    new Map(),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [page, setPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchData, setSearchData] = useState('');
  const [csvData, setCsvData] = useState([]);

  const getPaginatedList = (list) => {
    const offset = page * pageLimit;
    return list.slice(offset, offset + pageLimit);
  };

  const renderList = () => {
    return getPaginatedList(tableData);
  };

  const resetSearchData = () => {
    setSearchData('');
    setTableData(baseData);
  };

  const AlertType = {
    success: 'success',
    error: 'error',
  };

  const PageAlert = (type, message) => {
    return Alert[AlertType[type]](message, {
      position: 'top-right',
      effect: 'slide',
      beep: false,
      timeout: 4000,
    });
  };

  const searchLanguage = (param) => {
    const sievedData = baseData.filter(
      (item) =>
        item[selectedLanguage] &&
        item[selectedLanguage]
          .toString()
          .toLowerCase()
          .includes(param.toString().toLowerCase()),
    );
    setTableData(sievedData);
  };

  const updateSearchData = (e) => {
    e.preventDefault();
    setSearchData(e.target.value);
    searchLanguage(e.target.value);
    if (page !== 0) setPage(0);
  };

  useEffect(() => {
    if (languages) {
      const eng = languages.find((f) => f.code == 'EN');
      if (eng) {
        setSelectedLanguage(eng.code);
        setSelectedLanguageName(eng.name);
      }
    }
  }, []);

  useEffect(() => {
    renderList();
  }, [pageLimit]);

  const renderPagination = () => {
    return (
      <CustomLexiconTablePagination
        currentPage={page}
        pages={tableData.length}
        setPage={setPage}
        pageLimit={pageLimit}
      />
    );
  };

  const searchBox = () => (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
    >
      <TextField
        label={`Search ${selectedLanguageName}`}
        variant="standard"
        size="small"
        value={searchData}
        onChange={updateSearchData}
      />
      {searchData && <HighlightOff onClick={resetSearchData} />}
      <Search />
    </div>
  );

  const setLanguage = (languageCode: string) => {
    setSelectedLanguage(languageCode);
    setSelectedLanguageName(
      languages.find((l) => l.code === languageCode).name,
    );
  };

  const languageSelection = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Select
        variant="standard"
        value={selectedLanguage}
        placeholder="Language"
        fullWidth
        style={{
          fontSize: '11px',
        }}
        onChange={(e) => {
          resetSearchData();
          onCancelChanges();
          setLanguage(e.target.value as string);
        }}
      >
        {languages &&
          languages.map((item) => (
            <MenuItem
              style={{
                fontSize: '11px',
              }}
              key={item.code}
              value={item.code}
            >
              {capitalizeFirstLetter(splitCamelCase(item.name))}
            </MenuItem>
          ))}
      </Select>
    </div>
  );

  const markVisibleReferenceFlavorForProducer = (id) => {
    const itemIndex = tableData.findIndex((i) => i.id === id);
    const currState = tableData[itemIndex][`${selectedLanguage}Enabled`];
    tableData[itemIndex][`${selectedLanguage}Enabled`] = !currState;

    const baseItemIndex = baseData.findIndex((i) => i.id === id);
    baseData[baseItemIndex][`${selectedLanguage}Enabled`] = !currState;
  };

  // Refresh the lexicon list from Database
  const refreshLexiconListFromDatabase = async (flavorAttribute) => {
    const lexicons =
      await graphqlClient.query<GetReferenceFlavorForUpdateResponse>({
        query: GetReferenceFlavorForUpdateQuery,
        fetchPolicy: 'no-cache',
      });
    if (!lexicons.loading) {
      const newTableData = formatReferenceFlavorsList(
        lexicons.data.workspaceReferenceFlavorForUpdate.nodes,
      ).filter((f) => f.flavorAttribute === flavorAttribute);
      const updatedData = newTableData.map((item) => {
        const itemMap = {};
        languages.forEach((language) => {
          itemMap[`${language.code}Enabled`] = !!(
            item[language.code] && item.visible
          );
        });
        return {
          ...item,
          referenceFlavorDeleted: !!item.visible,
          ...itemMap,
        };
      });
      groupAndUpdateLexicons(
        formatReferenceFlavorsList(
          lexicons.data.workspaceReferenceFlavorForUpdate.nodes,
        ),
      );
      setBaseData(updatedData);
      setTableData(updatedData);
    }
  };

  const downloadCsv = () => {
    setCsvData(CsvFields(tableData, languages));
  };

  const createReferenceFlavor = async (
    flavorAttribute = null,
    value = null,
  ) => {
    try {
      const createReference = await graphqlClient.mutate({
        mutation: CreateReferenceFlavorMutation,
        variables: {
          flavorAttribute: flavorAttribute,
          name: value,
          base: true,
          visible: true,
          version: 1,
        },
      });

      if (
        createReference &&
        createReference.data &&
        createReference.data.createReferenceFlavorNew &&
        createReference.data.createReferenceFlavorNew.referenceFlavorNew
      ) {
        await graphqlClient.mutate({
          mutation: CreateReferenceFlavorTranslationMutation,
          variables: {
            referenceFlavorId:
              createReference.data.createReferenceFlavorNew.referenceFlavorNew
                .id,
            translation: value,
            language: 'EN',
          },
        });
      }
      return createReference;
    } catch (e) {
      console.log(e);
    }
  };

  const deleteReferenceFlavor = async (existingFlavorId, deleted) => {
    try {
      const deleteReference = await graphqlClient.mutate({
        mutation: DeleteReferenceFlavorMutation,
        variables: {
          id: parseInt(existingFlavorId),
          deleted: deleted,
        },
      });
      return deleteReference;
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  const onVisibleToggled = (row) => {
    const tempChangedReferenceFlavors = new Map(changedReferenceFlavors);
    if (tempChangedReferenceFlavors.has(row.id)) {
      tempChangedReferenceFlavors.delete(row.id);
    } else {
      tempChangedReferenceFlavors.set(row.id, {
        id: row.id,
        value: !row.referenceFlavorDeleted,
      });
    }
    setChangedReferenceFlavors(tempChangedReferenceFlavors);
  };

  const createCustomLexicon = async (flavorAttribute, value) => {
    setIsLoading(true);
    const result = await createReferenceFlavor(flavorAttribute, value);
    if (result === undefined) {
      setIsLoading(false);
      closeCreateModal();
      return PageAlert(AlertType.error, 'Changes not saved. Please try again');
    }
    if (result.errors && result.errors[0].message.length) {
      setIsLoading(false);
      closeCreateModal();
      return PageAlert(AlertType.error, 'Changes not saved. Please try again');
    }
    await refreshLexiconListFromDatabase(flavorAttribute);
    setIsLoading(false);
    closeCreateModal();
    renderPagination();
    return PageAlert(AlertType.success, 'Changes saved successfully');
  };

  const findExistingLanguageValue = (flavorAttribute, value, language) => {
    const existingLangVal = baseData.find(
      (item) =>
        item.flavorAttribute === flavorAttribute && item.value === value,
    );
    const existingFlav = baseData.find(
      (item) => item.flavorAttribute === flavorAttribute,
    );
    return (existingLangVal && existingLangVal[language]) || '';
  };

  const mapLexicons = async (flavorAttribute, lexicons) => {
    if (!lexicons.length || !lexicons[0].value)
      return PageAlert(
        AlertType.error,
        'You have not made any changes. Please fill all required fields',
      );
    setIsLoading(true);
    Promise.all(
      lexicons.map(async (item) => {
        const { value, label, language } = item;
        const existingData = baseData.find(
          (item) =>
            item.flavorAttribute === flavorAttribute && item.value === value,
        );
        if (existingData[language]) {
          await graphqlClient.mutate({
            mutation: MapReferenceFlavorMutation,
            variables: {
              referenceFlavorTranslationId: existingData.referenceFlavorTransId,
              translation: label,
            },
          });
        } else {
          await graphqlClient.mutate({
            mutation: CreateReferenceFlavorTranslationMutation,
            variables: {
              referenceFlavorId: existingData.id,
              translation: label,
              language,
            },
          });
        }
      }),
    )
      .then(async () => {
        await refreshLexiconListFromDatabase(flavorAttribute);
        setIsLoading(false);
        closeMappingModal();
        renderPagination();
        return PageAlert(AlertType.success, 'Changes saved successfully');
      })
      .catch((e) => {
        setIsLoading(false);
        closeMappingModal();
        return PageAlert(
          AlertType.error,
          'Changes not saved. Please try again',
        );
      });
  };

  const onCancelChanges = () => {
    if (changedReferenceFlavors.size > 0) {
      const referenceList = Array.from(changedReferenceFlavors.values());
      referenceList.forEach((refFlavor) => {
        const itemIndex = tableData.findIndex((i) => i.id === refFlavor.id);
        const currState = tableData[itemIndex][`${selectedLanguage}Enabled`];
        tableData[itemIndex][`${selectedLanguage}Enabled`] = !currState;

        const baseItemIndex = baseData.findIndex((i) => i.id === refFlavor.id);
        baseData[baseItemIndex][`${selectedLanguage}Enabled`] = !currState;
      });
      resetSearchData();
      setChangedReferenceFlavors(new Map());
    }
  };

  const onSaveChanges = async () => {
    setIsLoading(true);
    const referenceList = Array.from(changedReferenceFlavors.values());
    Promise.all(
      referenceList.map(async (item) => {
        const { id, value } = item;
        await deleteReferenceFlavor(id, value);
      }),
    )
      .then(async () => {
        await refreshLexiconListFromDatabase(
          producersReferenceFlavors[0].flavorAttribute,
        );
        setIsLoading(false);
        setChangedReferenceFlavors(new Map());
        return PageAlert(AlertType.success, 'Changes saved successfully');
      })
      .catch((e) => {
        setIsLoading(false);
        setChangedReferenceFlavors(new Map());
        return PageAlert(
          AlertType.error,
          'Changes not saved. Please try again',
        );
      });
  };

  const closeCreateModal = () => {
    setOpenCreateModal(false);
  };

  const closeMappingModal = () => {
    setOpenMappingModal(false);
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <div>
      <div className={styles.tableFooterButtonGroup}>
        <div style={{ paddingBottom: '10px' }}>
          {`${producersReferenceFlavors[0].flavorAttribute} - Reference Flavor`}
        </div>
        <div className={styles.languageSelectionGroup}>
          {languageSelection()}
        </div>
      </div>
      <div className={styles.tableBox}>
        <Table
          renderHeaderRow={() => (
            <tr>
              <th style={{ width: 200 }}>{t('customLexicon.value')}</th>
              <th style={{ width: 200 }}>{searchBox()}</th>
              <th style={{ width: 200 }}>{t('customLexicon.visible')}?</th>
            </tr>
          )}
        >
          {renderList() &&
            renderList().map((item, index) => {
              return (
                <tr key={index}>
                  <td className="name">
                    {item.value.includes('_') ? reWord(item.value) : item.value}
                  </td>
                  <td className="attributes">{item[selectedLanguage]}</td>
                  <td className="name">
                    <Checkbox
                      color="secondary"
                      checked={item[`${selectedLanguage}Enabled`]}
                      disabled={!item[selectedLanguage]}
                      onChange={() => {
                        markVisibleReferenceFlavorForProducer(item.id);
                        onVisibleToggled(item);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
        </Table>
        <div className={styles.paginationGroup}>
          <div>
            <Select
              variant="standard"
              value={pageLimit}
              onChange={(e) => {
                setPage(0);
                setPageLimit(e.target.value as number);
              }}
            >
              <MenuItem key={1} value={5}>
                5
              </MenuItem>
              <MenuItem key={2} value={10}>
                10
              </MenuItem>
              <MenuItem key={3} value={25}>
                25
              </MenuItem>
              <MenuItem key={4} value={50}>
                50
              </MenuItem>
              <MenuItem key={5} value={100}>
                100
              </MenuItem>
            </Select>
          </div>
          {renderPagination()}
        </div>
        <div className={styles.buttonGroup}>
          <div className={styles.buttonSubGroupLeft}>
            <div className={styles.tableFooter}>
              <MaterialButton
                variant="outlined"
                soft
                disabled={changedReferenceFlavors.size < 1}
                onClick={onCancelChanges}
              >
                {t('customLexicon.cancel')}
              </MaterialButton>
            </div>
            <div className={styles.tableFooter}>
              <MaterialButton
                variant="outlined"
                soft
                teal
                disabled={changedReferenceFlavors.size < 1}
                onClick={onSaveChanges}
              >
                {t('customLexicon.save')}
              </MaterialButton>
            </div>
          </div>
          <div className={styles.buttonSubGroupRight}>
            <CSVLink
              data={csvData}
              filename={`${producersReferenceFlavors[0].flavorAttribute}_Reference_Flavors.csv`}
              asyncOnClick={true}
            >
              <MaterialButton
                variant="outlined"
                soft
                teal
                disabled={producersReferenceFlavors.length < 1}
                onClick={downloadCsv}
              >
                {isCsvLoading
                  ? 'Downloading...'
                  : `${t('customLexicon.download')}`}
              </MaterialButton>
            </CSVLink>
          </div>
        </div>
      </div>
      <div className={styles.tableFooterButtonGroup}>
        <div className={styles.tableFooterButton}>
          <MaterialButton
            variant="outlined"
            soft
            teal
            onClick={() => setOpenMappingModal(true)}
          >
            {t('customLexicon.mapToAnExistingLexicon')}
          </MaterialButton>
        </div>
        <div className={styles.tableFooterButton}>
          <MaterialButton
            variant="outlined"
            soft
            teal
            onClick={() => setOpenCreateModal(true)}
          >
            {t('customLexicon.createNewLexicon')}
          </MaterialButton>
        </div>
        <CustomLexiconModal
          open={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
          title="Create New Lexicon"
        >
          {isSaving ? (
            <LoadingScreen />
          ) : (
            <CustomLexiconCreateContainer
              flavor={producersReferenceFlavors[0].flavorAttribute}
              createCustomLexicon={createCustomLexicon}
              closeCreateModal={closeCreateModal}
            />
          )}
        </CustomLexiconModal>
        <CustomLexiconModal
          open={openMappingModal}
          onClose={() => setOpenMappingModal(false)}
          title={`${producersReferenceFlavors[0].flavorAttribute} - Add Mapping`}
        >
          <CustomLexiconAddMappingContainer
            flavor={producersReferenceFlavors[0].flavorAttribute}
            lexes={baseData.map((i) => i.value).sort()}
            languages={languages}
            mapLexicons={mapLexicons}
            closeMappingModal={closeMappingModal}
            checkDuplicateMapping={findExistingLanguageValue}
          />
        </CustomLexiconModal>
      </div>
    </div>
  );
};

export default CustomLexiconListTable;
