import * as React from 'react';

import { Grid } from '@mui/material';
import ReportNoveltyTree from 'components/Report/Novelty/NoveltyTree';
import ReportPairedProductNoveltyComparison from 'components/Report/Novelty/ReportNoveltyPairedComparisonPreference';
import ReportNoveltySignature from 'components/Report/Novelty/ReportNoveltySignature';
import NoveltyIntensityPqChartGroup from 'components/Report/NoveltyFlavorDecomposition/NoveltyIntensityPqChartGroup';
import useCustomProductNames from 'hooks/useCustomProductNames';

interface Props {
  projectId: number;
  workspaceId: number;
  reportId: string;
}

const ProjectNoveltyAnalysisSummary: React.FC<Props> = (props) => {
  const { projectId, workspaceId, reportId } = props;

  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  return (
    <Grid container style={{ paddingTop: 10 }}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <ReportPairedProductNoveltyComparison
              projectId={projectId}
              reportId={reportId}
            />
          </Grid>
          <Grid item xs={6}>
            <ReportNoveltyTree projectId={projectId} reportId={reportId} />
          </Grid>
          <Grid item xs={12}>
            <NoveltyIntensityPqChartGroup
              projectId={projectId}
              reportId={reportId}
            />
          </Grid>
          <Grid item xs={12}>
            <ReportNoveltySignature projectId={projectId} reportId={reportId} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectNoveltyAnalysisSummary;
