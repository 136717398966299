import * as React from 'react';

import { useSelector } from 'react-redux';

import AppHeader from '../../components/AppHeader';
import selectSessionToken from '../../selectors/sessionToken';
import AppHeaderViewerMenuContainer from '../AppHeaderViewerMenu';
import AppHeaderWorkspaceMenuContainer from '../AppHeaderWorkspaceMenu';

const AppHeaderContainer: React.FC = () => {
  const viewerIsAuthenticated = useSelector((state) =>
    selectSessionToken(state),
  );

  const renderLeftItem = () =>
    viewerIsAuthenticated && <AppHeaderWorkspaceMenuContainer />;

  const renderViewerMenu = () => <AppHeaderViewerMenuContainer />;

  return (
    <AppHeader
      renderLeftItem={renderLeftItem}
      renderViewerMenu={renderViewerMenu}
    />
  );
};

export default AppHeaderContainer;
