import { gql } from '@apollo/client';

export default gql`
  mutation ToggleProjectApproval($id: Int!, $approved: Boolean) {
    updateProjectById(input: {id: $id, projectPatch: {approved: $approved}}) {
      project {
        id
        approved
      }
    }
  }
`;
