import { connect } from 'react-redux';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import PanelUser from './PanelUser';

const mapStateToProps = (state) => ({
  workspaceId: selectWorkspaceProducerId(state),
});

export default connect(mapStateToProps)(PanelUser);
