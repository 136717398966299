import * as React from 'react';

import { Paper, Typography, FormLabel } from '@mui/material';

export const PagePaper: React.FC = ({ children }) => (
  <Paper style={{ width: '98%', maxWidth: '96%', padding: '4.2rem 3.2rem' }}>
    {children}
  </Paper>
);

export const PageHeader: React.FC = ({ children }) => (
  <Typography
    variant="h5"
    color="secondary"
    style={{
      fontSize: 12,
      fontFamily: 'AlphaHeadlinePro-Bold, sans-serif',
    }}
  >
    {children}
  </Typography>
);

export const PageTitle: React.FC = ({ children }) => (
  <Typography
    variant="h3"
    color="primary"
    style={{
      fontSize: 24,
      fontFamily: 'AlphaHeadlinePro-Bold, sans-serif',
    }}
  >
    {children}
  </Typography>
);

export const ComponentTitle: React.FC<
  React.PropsWithChildren<{
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  }>
> = (props) => (
  <Typography
    variant="h4"
    color="black"
    align={props.align}
    style={{
      fontSize: 22,
    }}
  >
    {props.children}
  </Typography>
);

export const SLFormLabel: React.FC = ({ children }) => (
  <FormLabel
    style={{
      margin: 'auto',
      fontSize: 14,
      color: '#022950',
      fontFamily: 'AlphaHeadlinePro-Bold',
      display: 'block',
    }}
  >
    {children}
  </FormLabel>
);

export const SLCaption: React.FC = ({ children }) => (
  <span
    style={{
      fontSize: 11,
      color: '#888',
      fontFamily: 'sans-serif',
      display: 'block',
    }}
  >
    {children}
  </span>
);
