import {
  ApolloError,
  WatchQueryFetchPolicy,
  gql,
  useQuery,
} from '@apollo/client';
import {
  ProductVersion,
  ProductVersionSet,
} from 'components/Report/ProductVersion';
import { sortReportSummaryDetailNodesByPq } from 'components/Report/utils';

const query = gql`
  query ReportSummaryByReportId($reportID: UUID!) {
    allReportJobs(condition: { reportId: $reportID }) {
      nodes {
        id
        reportId
        jobId
        startedAt
        reportStatus
        reportType
        mapOnly
        reportSummariesByReportId {
          edges {
            node {
              productByProductId {
                id
                name
              }
              pq
              pComp
              polarization
              version
            }
          }
        }
      }
    }
  }
`;

interface ReportSummaryOptions {
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export interface ReportSummaryResponse {
  loading: boolean;
  error: any;
  allReportJobs: {
    nodes: ReportSummaryDetailRow[];
  };
}

export interface ReportSummaryDetailRow {
  id: number;
  reportId: string;
  jobId: string;
  startedAt: string;
  reportStatus: string;
  reportType: string;
  mapOnly: boolean;
  reportSummariesByReportId: {
    edges: ReportSummaryDetailNode[];
  };
}

export interface ReportSummaryDetailNode {
  node: ReportDetailRow;
}

export interface ReportDetailRow {
  pComp: string;
  polarization: string;
  pq: string;
  productByProductId: reports.ColorVersionedProductInfo;
  version: string;
}
export interface KeyedReportDetailRow extends ReportDetailRow {
  key: number;
  reportId?: string;
}

export interface ProductVersionPq {
  productVersion: ProductVersion;
  pq: number;
}

export function toProductVersionSet(
  reportSummaryReponse: ReportSummaryResponse,
): ProductVersionSet {
  if (reportSummaryReponse) {
    return reportSummaryReponse.allReportJobs.nodes
      .flatMap((n) => n.reportSummariesByReportId.edges)
      .sort((a, b) => sortReportSummaryDetailNodesByPq(a, b))
      .map(
        (e) => new ProductVersion(e.node.productByProductId.id, e.node.version),
      )
      .reduce((a, c) => (a.add(c) ? a : a), new ProductVersionSet());
  } else {
    return null;
  }
}

export function toProductPqs(
  reportSummaryReponse: ReportSummaryResponse,
): ProductVersionPq[] {
  if (reportSummaryReponse) {
    return reportSummaryReponse.allReportJobs.nodes
      .map((n) => n.reportSummariesByReportId)
      .flatMap((rs) => rs.edges)
      .map((s) => s.node)
      .map((sn) => ({
        productVersion: new ProductVersion(
          sn.productByProductId.id,
          sn.version,
        ),
        pq: parseFloat(sn.pq),
      }));
  } else {
    return [];
  }
}

export function toPqRange(reportSummaryReponse: ReportSummaryResponse): number {
  if (
    reportSummaryReponse &&
    reportSummaryReponse.allReportJobs.nodes.find(Boolean)
  ) {
    const maxPq = Math.max(
      ...reportSummaryReponse.allReportJobs.nodes
        .find(Boolean)
        .reportSummariesByReportId.edges.map((a) => parseFloat(a.node.pq)),
    );
    const minPq = Math.min(
      ...reportSummaryReponse.allReportJobs.nodes
        .find(Boolean)
        .reportSummariesByReportId.edges.map((a) => parseFloat(a.node.pq)),
    );

    if (maxPq === -Infinity) return null;
    if (minPq === Infinity) return null;

    return maxPq - minPq;
  } else {
    return null;
  }
}

export function toTopProduct(
  reportSummaryReponse: ReportSummaryResponse,
): ReportSummaryDetailNode {
  if (
    reportSummaryReponse &&
    reportSummaryReponse.allReportJobs.nodes.find(Boolean)
  ) {
    const a = reportSummaryReponse.allReportJobs.nodes
      .find(Boolean)
      .reportSummariesByReportId.edges.reduce(
        (a, i) => (a?.node.pq > i?.node.pq ? a : i),
        null,
      );
    return a;
  } else {
    return null;
  }
}

export function toBottomProduct(
  reportSummaryReponse: ReportSummaryResponse,
): ReportSummaryDetailNode {
  if (
    reportSummaryReponse &&
    reportSummaryReponse.allReportJobs.nodes.find(Boolean)
  ) {
    const a = reportSummaryReponse.allReportJobs.nodes
      .find(Boolean)
      .reportSummariesByReportId.edges.reduce(
        (a, i) => (a?.node.pq < i?.node.pq ? a : i),
        null,
      );
    return a;
  } else {
    return null;
  }
}

export default function useReportSummary(
  reportId: string,
  options?: ReportSummaryOptions,
): {
  loading: boolean;
  error: ApolloError;
  data: ReportSummaryResponse;
  productVersionSet: ProductVersionSet;
  productVersionPqs: ProductVersionPq[];
  pqRange: number;
  topProduct: ReportSummaryDetailNode;
  bottomProduct: ReportSummaryDetailNode;
} {
  const { data, loading, error } = useQuery<ReportSummaryResponse>(query, {
    variables: {
      reportID: reportId,
    },
    skip: options?.skip ?? false,
    fetchPolicy: options?.fetchPolicy ?? 'cache-first',
  });

  return {
    loading,
    error,
    data,
    productVersionSet: toProductVersionSet(data),
    productVersionPqs: toProductPqs(data),
    pqRange: toPqRange(data),
    topProduct: toTopProduct(data),
    bottomProduct: toBottomProduct(data),
  };
}
