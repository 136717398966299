import * as React from 'react';

import Paper from '@mui/material/Paper';

import CustomLexicon from './CustomLexicon';
import styles from './CustomLexiconContainer.module.css';

export const CustomLexiconContainer: React.FunctionComponent = (props) => (
  <Paper className={styles.container}>
    <CustomLexicon />
  </Paper>
);

export default CustomLexiconContainer;
