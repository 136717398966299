import * as React from 'react';

import { useQuery } from '@apollo/client';
import UserPartnerUsersByIdQuery from '@graphql/queries/UserPartnerUsersByIdQuery';
import { LinearProgress } from '@mui/material';
import ConditionViewerRoleContainer from 'containers/ConditionViewerRole';
import { useSelector } from 'react-redux';

import UserRolesResult from './UserRolesResult';
import UserByEmailQuery from '../../graphql/queries/UserByEmail';
import selectViewerUserId from '../../selectors/viewerUserId';

interface Props {
  email: string;
}

const UserResult: React.FC<Props> = ({ email }: Props) => {
  const currentUserId = useSelector((state) => selectViewerUserId(state));

  const {
    loading: userLoading,
    error: userError,
    data: userData,
  } = useQuery(UserByEmailQuery, {
    variables: {
      email: email,
    },
  });

  const {
    loading: partnerLoading,
    error: partnerError,
    data: partnerData,
  } = useQuery(UserPartnerUsersByIdQuery, {
    variables: { userId: currentUserId },
  });

  if (userLoading || partnerLoading) {
    return <LinearProgress />;
  }

  if (userData.user === null || userError) {
    return <h4>User not found!</h4>;
  }

  if (userData.user.id === currentUserId) {
    return <h4>Cannot edit current user!</h4>;
  }

  return (
    <ConditionViewerRoleContainer
      render={(viewerRoles) => {
        if (!viewerRoles.viewerRoleIsSuperadmin) {
          if (partnerLoading) {
            return <LinearProgress />;
          }

          const partnerUsers =
            partnerData.user?.userPartnersByUserId?.nodes[0].partnerByPartnerId?.userPartnersByPartnerId?.nodes.map(
              (n) => n.userByUserId.email,
            );

          if (!partnerUsers.includes(email)) {
            return (
              <h4>
                User not found or not associated with this Gastrograph partner.
              </h4>
            );
          }
        }

        return <UserRolesResult userId={userData.user.id} />;
      }}
    />
  );
};

export default UserResult;
