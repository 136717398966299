import {
  availableReportTypes,
  availableUpdateReportTypes,
} from 'constants/report';

import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import ClientOptionsPartnerAdmin from './ClientOptionsPartnerAdmin';
import ClientOptionsSuperAdmin from './ClientOptionsSuperAdmin';
import WorkspaceOptionsPartnerAdmin from './WorkspaceOptionsPartnerAdmin';
import WorkspaceOptionsSuperAdmin from './WorkspaceOptionsSuperAdmin';
import FieldTextInput from '../../../components/FieldTextInput';
import FormInput from '../../../components/FormInput';
import FormInputSelect from '../../../components/FormInputSelect';
import selectTokenRoles from '../../../selectors/sessionTokenRoles';
import { PageHeader } from '../../../styles/themeComponents';
import { hasSuperAdminRole } from '../../../utils/roleUtils';
import styles from '../RequestReport.module.css';

interface Props {
  change: (field: string, value: any) => void;
  inUpdateReportMode?: boolean;
}

const required = (value) => (value ? undefined : 'Required');
const validateFirstCharacter = (value) =>
  value.length && !/^[a-zA-Z0-9]+$/.test(value[0])
    ? 'First letter can only be alphanumeric'
    : undefined;
const validateNameLength = (value) =>
  value.length > 128 ? 'Name cannot be more than 128 characters' : undefined;
const validateNameFormat = (value) =>
  value && !/^[a-zA-Z0-9_-\s]+$/.test(value)
    ? 'Project name can only contain letters, numbers, hyphens, spaces and underscores'
    : undefined;

const ReportType: React.FC<Props> = ({ change, inUpdateReportMode }) => {
  const { t } = useTranslation();
  const userRoles = useSelector((state) => selectTokenRoles(state));
  const isSuperAdmin = hasSuperAdminRole(userRoles);

  React.useEffect(() => {
    if (inUpdateReportMode)
      change('report_type', { ...availableUpdateReportTypes[0] });
  }, [inUpdateReportMode]);

  return (
    <div>
      <PageHeader>{t('navigation.reports')}</PageHeader>
      <h3 className={styles.productFieldsTitle}>
        {t('reports.createReportRequest')}
      </h3>

      <div className={styles.sectionContainer}>
        <Field
          name="project_name"
          variant="standard"
          component={FieldTextInput}
          fullWidth
          label={t('reports.projectName')}
          required
          validate={[
            required,
            validateFirstCharacter,
            validateNameLength,
            validateNameFormat,
          ]}
        />
      </div>
      <div className={styles.sectionContainer}>
        {isSuperAdmin ? (
          <ClientOptionsSuperAdmin change={change} />
        ) : (
          <ClientOptionsPartnerAdmin change={change} />
        )}
      </div>
      <div className={styles.sectionContainer}>
        {isSuperAdmin ? (
          <WorkspaceOptionsSuperAdmin change={change} />
        ) : (
          <WorkspaceOptionsPartnerAdmin change={change} />
        )}
      </div>
      <div className={styles.sectionContainer}>
        <Field
          name="report_type"
          component={FormInput}
          inputComponent={FormInputSelect}
          customLabel
          labelText={t('reports.reportTypeLabel')}
          options={
            inUpdateReportMode
              ? availableUpdateReportTypes
              : availableReportTypes
          }
          isSearchable
          hideSelectedOptions={false}
          placeholder={t('reports.reportTypePlaceholder')}
          closeMenuOnSelect={true}
          required
          value={(val) => val.value}
          onChange={(_, newValue) => {
            change('report_type', newValue.value);
          }}
        />
      </div>
    </div>
  );
};

export default ReportType;
