import React from 'react';

import {
  KeyboardBackspace as KeyboardBackspaceIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material';
import { IconButton, Paper, Grid, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MARKET_SURVEY, OPTIMIZATION } from '../../../constants/report';
import { REPORTS_QA } from '../../../constants/routePaths';
import { PageHeader } from '../../../styles/themeComponents';
import styles from '../RequestReport.module.css';

export default function RequestSent({
  submitFailed,
  submitSucceeded,
  submitting,
  project_name,
  report_type,
  limitExceeded,
}) {
  const { t } = useTranslation();
  const typeString =
    report_type === MARKET_SURVEY
      ? t('reports.marketSurvey')
      : report_type === OPTIMIZATION
        ? t('reports.optimization')
        : t('reports.marketSurvey');

  return (
    <Paper className={styles.container}>
      <div className={styles.productHeader}>
        <IconButton
          component={Link}
          to={{ pathname: REPORTS_QA }}
          size="small"
          style={{ marginLeft: -26 }}
        >
          <KeyboardBackspaceIcon fontSize="small" />
          <PageHeader>{t('navigation.reports')}</PageHeader>
        </IconButton>
      </div>
      <h3 className={styles.productFieldsTitle}>
        {t('reports.createReportRequest')}
      </h3>

      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        {submitting && (
          <Grid item xs={12}>
            <CircularProgress color="secondary" />
          </Grid>
        )}
        {!submitting && !submitFailed && submitSucceeded && (
          <Grid item xs={12}>
            <CheckCircleOutlineIcon
              style={{ fontSize: 150 }}
              color="secondary"
            />
          </Grid>
        )}
        {!submitting && !submitFailed && (
          <Grid item xs={12}>
            <span className={styles.successHeader}>
              {t('reports.requestSuccessHeader')}
            </span>
          </Grid>
        )}
        {!submitting && !submitFailed && (
          <Grid item xs={12}>
            <span
              className={styles.successHeader}
            >{`${project_name} ${typeString} ${t(
              'reports.reportRequest',
            )}`}</span>
          </Grid>
        )}
        {/* {!submitting &&
          !submitFailed && (
            <Grid item xs={12}>
              <span className={styles.successSubtitle}>
                {t('reports.requestSuccessSubtitle')}
              </span>
            </Grid>
          )} */}

        {!submitting && submitFailed && (
          <Grid item xs={12}>
            <ErrorOutlineIcon style={{ fontSize: 150 }} color="error" />
          </Grid>
        )}
        {!submitting && submitFailed && (
          <Grid item xs={12}>
            <span className={styles.successHeader}>
              {t('reports.requestFailedHeader')}
            </span>
          </Grid>
        )}
        {!submitting && limitExceeded && (
          <Grid item xs={12}>
            <span className={styles.successSubtitle}>
              {t('reports.requestQuotaExceededSubtitle')}
            </span>
          </Grid>
        )}
        {!submitting && !limitExceeded && submitFailed && (
          <Grid item xs={12}>
            <span className={styles.successSubtitle}>
              {t('reports.requestFailedSubtitle')}
            </span>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
