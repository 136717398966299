import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
        zIndex: 10, 
        opacity: 1, 
        background: 'white', 
        padding: '4px', 
        border: '2px solid', 
        borderColor: '#c4c3c3', 
        borderRadius: 5 
    },
    pqcircle: {
        borderRadius: '50%',
        height: '12px',
        width: '12px',
        maxHeight: '12px',
        maxWidth: '12px',
        margin: '4px',
        paddingTop: '4px',
        display: 'flex-start'
      }
  }),
);

export default useStyles;