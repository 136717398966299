import { gql } from '@apollo/client';

export interface ReportNoveltyStackRankSplitsResponse
  extends reports.ReportGraphQLReponse {
  allRpNoveltyStackRankingSplits: {
    nodes: ReportNoveltyStackRankSplit[];
  };
}

export interface ReportNoveltyStackRankSplit {
  noveltySplitNodeId: number;
  leftNode: number;
  rightNode: number;
  depth: number;
}

export const NoveltyStackRankSplitsByReportId = gql`
  query noveltyStackRankSplitsQuery($reportID: UUID) {
    allRpNoveltyStackRankingSplits(condition: { reportId: $reportID }) {
      nodes {
        noveltySplitNodeId
        leftNode
        rightNode
        depth
      }
    }
  }
`;
