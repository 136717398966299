import * as React from 'react';

import CSVDownload from 'components/CSVDownload/CSVDownload';
import { LabelKeyObject } from 'react-csv/lib/core';

interface Props {
  flavorComparisonData: [string, reports.FlavorDecompValues[]][];
  firstProductDisplayName: string;
  secondProductDisplayName: string;
}

interface CsvRow {
  ggVar: string;
  referenceFlavor: string;
  origProductDecomp: string;
  origProductAbsDecomp: string;
  secondProductDecomp: string;
  secondProductAbsDecomp: string;
  deltaDecomp: string;
  deltaAbsDecomp: string;
}

const FlavorDecompositionComparisonCsv: React.FC<Props> = (props) => {
  const {
    flavorComparisonData,
    firstProductDisplayName,
    secondProductDisplayName,
  } = props;

  function createCsvRow(
    ggvar: string,
    flavor: string,
    values: reports.FlavorDecompValues[],
  ): CsvRow {
    return {
      ggVar: ggvar,
      referenceFlavor: flavor,
      origProductDecomp:
        (
          parseFloat(
            values.reduce((acc, curr) => acc + curr.decomp, 0).toString(),
          ) * 100
        ).toFixed() + '%',
      origProductAbsDecomp: parseFloat(
        values.reduce((acc, curr) => acc + curr.absDecomp, 0).toString(),
      ).toFixed(2),
      secondProductDecomp:
        (
          parseFloat(
            values.reduce((acc, curr) => acc + curr.sdecomp, 0).toString(),
          ) * 100
        ).toFixed() + '%',
      secondProductAbsDecomp: parseFloat(
        values.reduce((acc, curr) => acc + curr.sabsDecomp, 0).toString(),
      ).toFixed(2),
      deltaDecomp:
        (
          (parseFloat(
            values.reduce((acc, curr) => acc + curr.sdecomp, 0).toString(),
          ) -
            parseFloat(
              values.reduce((acc, curr) => acc + curr.decomp, 0).toString(),
            )) *
          100
        ).toFixed() + '%',
      deltaAbsDecomp: (
        parseFloat(
          values.reduce((acc, curr) => acc + curr.sabsDecomp, 0).toString(),
        ) -
        parseFloat(
          values.reduce((acc, curr) => acc + curr.absDecomp, 0).toString(),
        )
      ).toFixed(2),
    };
  }

  const getCsvData = (
    data: [string, reports.FlavorDecompValues[]][],
  ): CsvRow[] => {
    const out: CsvRow[] = [];
    data.forEach((row) => {
      out.push(createCsvRow(row[0], '', row[1]));
      row[1].forEach((flavor: reports.FlavorDecompValues) => {
        out.push(createCsvRow('', flavor.referenceFlavor, [flavor]));
      });
      out.push({
        ggVar: '',
        referenceFlavor: '',
        origProductDecomp: '',
        origProductAbsDecomp: '',
        secondProductDecomp: '',
        secondProductAbsDecomp: '',
        deltaDecomp: '',
        deltaAbsDecomp: '',
      });
    });
    return out;
  };

  const getHeaders = (
    data: [string, reports.FlavorDecompValues[]][],
  ): LabelKeyObject[] => {
    return [
      { label: 'GGVar', key: 'ggVar' },
      { label: 'Reference Flavor', key: 'referenceFlavor' },
      {
        label: `${firstProductDisplayName ?? 'Product 1'} Decomp`,
        key: 'origProductDecomp',
      },
      {
        label: `${firstProductDisplayName ?? 'Product 1'} Absolute Decomp`,
        key: 'origProductAbsDecomp',
      },
      {
        label: `${secondProductDisplayName ?? 'Product 2'} Decomp`,
        key: 'secondProductDecomp',
      },
      {
        label: `${secondProductDisplayName ?? 'Product 2'} Absolute Decomp`,
        key: 'secondProductAbsDecomp',
      },
      { label: 'Delta Decomp', key: 'deltaDecomp' },
      { label: 'Delta Absolute Decomp', key: 'deltaAbsDecomp' },
    ];
  };

  return (
    <CSVDownload
      csvData={getCsvData(flavorComparisonData)}
      headers={getHeaders(flavorComparisonData)}
      fileName="Comparison_Flavor_Decomp.csv"
    />
  );
};

export default FlavorDecompositionComparisonCsv;
