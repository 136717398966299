import { saveAs } from 'file-saver';
import JSZip from 'jszip';

export const generateZipFromCloud = (
  urls: reports.ProductImageData[],
  fileName: string,
) => {
  const zip = new JSZip();
  const folder = zip.folder(fileName);

  urls.forEach((productImageData: reports.ProductImageData) => {
    const blobPromise = fetch(productImageData.url)
      .then(function (response) {
        if (response.status === 200 || response.status === 0) {
          return Promise.resolve(response.blob());
        } else {
          return Promise.resolve(new Blob([]));
        }
      })
      .catch((e) => {
        console.warn('Fetch Failed! ', e);
        return Promise.resolve(new Blob([]));
      });
    const objectName = productImageData.url.substring(
      productImageData.url.lastIndexOf('/') + 1,
    );

    folder
      .folder(productImageData.productName ?? objectName)
      .file(objectName, blobPromise);
  });

  zip
    .generateAsync({ type: 'blob' })
    .then((blob) => saveAs(blob, fileName))
    .catch((e) => console.warn('Zip Generation Failed! ', e));
};
