import { gql, useQuery } from '@apollo/client';

export const MINI_GLP_URL =
  'https://gastrograph-observable.s3.amazonaws.com/mini_glp.json';

export interface MiniGlpProduct {
  product_id: number;
  product: string;
  producer: string;
  countries: string[];
  countries_of_purchase: string[];
  category_name: string;
  reviews: number;
}

interface MiniGlpRow {
  productId: number;
  miniGlpJson: JSON;
}

interface MiniGlpResponse {
  loading: boolean;
  error: any;
  allMiniGlps: {
    nodes: MiniGlpRow[];
  };
}

function parse_db_json(mini_glp_json): MiniGlpProduct {
  const mgj = mini_glp_json;
  return {
    ...mgj,
    product_id: mgj.product_id[0],
    product: mgj.product[0],
    reviews: mgj.reviews[0],
    producer: mgj.producer[0],
    product_country: mgj.product_country[0],
    category_name: mgj.category_name[0],
  };
}

const query = gql`
  query MiniGlp {
    allMiniGlps {
      nodes {
        productId
        miniGlpJson
      }
    }
  }
`;

export default function useMiniGlp(): {
  loading: boolean;
  error: any;
  data: MiniGlpProduct[];
} {
  const { data, loading, error } = useQuery<MiniGlpResponse>(query);
  const mini_glp = data
    ? data.allMiniGlps.nodes.map((d) => parse_db_json(d.miniGlpJson))
    : [];

  return { loading, error, data: mini_glp };
}
