import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: 5,
      minHeight: 200,
      backgroundColor: theme.palette.tableRow.main,
    },
    mainText: {
      fontWeight: 'bold',
    },
    header: {
      fontSize: '2em',
      fontStyle: 'bold',
      textAlign: 'center',
    },
    colorBox: {
      width: '1em',
      height: '1em',
      marginBottom: '0.25em',
      marginLeft: '0.25em',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  }),
);

export default useStyles;
