import * as React from 'react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import UserResult from './UserResult';
import styles from './UserWorkspaceList.module.css';
import FieldTextInput from '../../components/FieldTextInput';
import MaterialButton from '../../components/MaterialButton';

const UserRolesList: React.FC = () => {
  const [email, setEmail] = useState('');
  const [showResult, setShowResult] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.sectionContainer}>
        <FieldTextInput
          label={t('users.email')}
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          fullWidth
        />
      </div>
      <div className={styles.buttonContainer}>
        <MaterialButton
          variant="outlined"
          onClick={() => setShowResult(true)}
          soft
          teal
        >
          {t('admin.viewRoles')}
        </MaterialButton>
      </div>

      {showResult && <UserResult email={email.toLocaleLowerCase()} />}
    </div>
  );
};

export default UserRolesList;
