import * as React from 'react';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { intersection, sortBy, concat, union, difference } from 'lodash';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import styles from './QuestionSelection.module.css';
import useStyles from './useStyles';
import selectWorkspaceProducerId from '../../../selectors/workspaceProducerId';

interface QuestionWithTranslation {
  category: string;
  description: string;
  id: number;
  qOrder: number;
  slug: string;
  translation: string;
  translations: {
    language: string;
    questionId: number;
    translation: string;
  }[];
}

type Props = {
  input: any;
  data: QuestionWithTranslation[];
  workspaceProducerId: number;
  productCategory: string;
};

interface TableRow {
  description: Element | any;
  question: string;
}

const extractAndInterpolateQuestion = (
  question: any,
  objectEntries: object | null,
) => {
  let displayQuestion = question;
  if (objectEntries) {
    for (const [key, value] of Object.entries(objectEntries)) {
      displayQuestion = question.replace(new RegExp(`{{${key}}}`, 'g'), value);
    }
  }
  return displayQuestion;
};

const getTypes = (data: QuestionWithTranslation[]): string[] =>
  data
    .map((q) => q.category)
    .filter((thing, i, arr) => {
      return arr.indexOf(arr.find((t) => t === thing)) === i;
    });

const QuestionSelection: React.FunctionComponent<Props> = (props) => {
  const { data, productCategory } = props;
  const { value, onChange } = props.input;
  const { t } = useTranslation();
  const { workspaceProducerId } = props;

  const classes = useStyles(props);
  const getData = (type: string) => {
    const currentTypeQuestions = data.filter((que) => que.category === type);
    const selectedTypeQuestions = intersection(
      value,
      currentTypeQuestions.map((que) => que.id),
    );

    return sortBy(currentTypeQuestions, ['qOrder']).map((que, index) => {
      const defaultQuestionLabel = extractAndInterpolateQuestion(
        que.translation || que.description,
        { product: productCategory },
      );
      const defaultQuestion = extractAndInterpolateQuestion(
        que.translation || que.description,
        { product: productCategory },
      );

      return {
        description: (
          <FormControlLabel
            control={<Checkbox color="secondary" />}
            color="secondary"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            onChange={(event) => {
              const target = event.target as HTMLInputElement;
              if (target.checked) {
                onChange(concat(value, que.id));
              } else {
                onChange(value.filter((val) => val != que.id));
              }
            }}
            label={defaultQuestionLabel}
            checked={!!selectedTypeQuestions.find((val) => val == que.id)}
            classes={{ label: classes.label, root: classes.label }}
          />
        ),
        question: defaultQuestion,
      };
    });
  };

  const columns: TableColumn<TableRow>[] = [
    {
      selector: (row: TableRow) => row.description,
      name: <div className={styles.tableHeader}>{t('Description')}</div>,
      sortable: true,
      wrap: true,
      style: { width: '7rem' },
    },
    {
      selector: (row: TableRow) => row.question,
      name: <div className={styles.tableHeader}>{t('Question')}</div>,
      sortable: true,
      wrap: true,
      style: { verticalAlign: 'middle' },
    },
  ];

  return (
    <div>
      <div>
        <FormControlLabel
          key={`selectAll`}
          control={<Checkbox color="secondary" />}
          color="secondary"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          onChange={(event) => {
            const target = event.target as HTMLInputElement;
            if (target.checked) {
              onChange(
                union(
                  value,
                  data.map((que) => que.id),
                ),
              );
            } else {
              onChange([]);
            }
          }}
          value={data.map((que) => que.id)}
          label={t(`behaviorQuestion.selectAll`)}
          checked={data.length == value.length}
          classes={{ label: classes.headerLabel, root: classes.root }}
        />
      </div>

      {workspaceProducerId !== 199 &&
        workspaceProducerId !== 284 &&
        workspaceProducerId !== 287 &&
        workspaceProducerId !== 320 &&
        workspaceProducerId !== 340 &&
        workspaceProducerId !== 25 &&
        getTypes(data).map((type, index) => {
          const currentTypeQuestions = data.filter(
            (que) => que.category === type,
          );
          const selectedTypeQuestions = intersection(
            value,
            currentTypeQuestions.map((que) => que.id),
          );

          return (
            <Accordion square key={`panel-${index}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`summary-${index}`}
              >
                <FormControlLabel
                  key={`checkbox-${index}`}
                  control={<Checkbox color="secondary" />}
                  color="secondary"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    if (target.checked) {
                      onChange(
                        union(
                          value,
                          currentTypeQuestions.map((que) => que.id),
                        ),
                      );
                    } else {
                      onChange(
                        difference(
                          value,
                          currentTypeQuestions.map((que) => que.id),
                        ),
                      );
                    }
                  }}
                  value={currentTypeQuestions.map((que) => que.id)}
                  label={t(`behaviorQuestion.types.${type}`)}
                  checked={
                    currentTypeQuestions.length == selectedTypeQuestions.length
                  }
                  classes={{ label: classes.headerLabel }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <DataTable
                  columns={columns}
                  data={getData(type)}
                  fixedHeader
                  striped
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      {(workspaceProducerId == 199 ||
        workspaceProducerId == 284 ||
        workspaceProducerId == 286 || // Pernod Ricard
        workspaceProducerId == 287 ||
        workspaceProducerId == 320 ||
        workspaceProducerId == 331 ||
        workspaceProducerId == 340 ||
        workspaceProducerId == 25) &&
        getTypes(data).map((type, index) => {
          const currentTypeQuestions = data.filter(
            (que) => que.category === type,
          );
          const selectedTypeQuestions = intersection(
            value,
            currentTypeQuestions.map((que) => que.id),
          );

          return (
            <Accordion square key={`panel-${index}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`summary-${index}`}
              >
                <FormControlLabel
                  key={`checkbox-${index}`}
                  control={<Checkbox color="secondary" />}
                  color="secondary"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    if (target.checked) {
                      onChange(
                        union(
                          value,
                          currentTypeQuestions.map((que) => que.id),
                        ),
                      );
                    } else {
                      onChange(
                        difference(
                          value,
                          currentTypeQuestions.map((que) => que.id),
                        ),
                      );
                    }
                  }}
                  value={currentTypeQuestions.map((que) => que.id)}
                  label={t(`behaviorQuestion.types.${type}`)}
                  checked={
                    currentTypeQuestions.length == selectedTypeQuestions.length
                  }
                  classes={{ label: classes.headerLabel }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <DataTable
                  columns={columns}
                  data={getData(type)}
                  fixedHeader
                  striped
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  workspaceProducerId: selectWorkspaceProducerId(state),
});

export default connect(mapStateToProps)(QuestionSelection);
