import { PROJECTS } from 'constants/routePaths';
import store from 'constants/store';

import { gql } from '@apollo/client';
import graphqlClient from 'consumers/graphqlClient';
import { History } from 'history';
import ReactGA from 'react-ga4';
import Alert from 'react-s-alert';
import workspaceProducerId from 'selectors/workspaceProducerId';

import { PROJECT_TO_EDIT } from './ProjectToEditQuery';

const CREATE_PROJECT = gql`
  mutation CreateProject($input: ProjectInput!) {
    createProject(input: { project: $input }) {
      project {
        id
      }
    }
  }
`;

const CREATE_PROJECT_REPORT = gql`
  mutation CreateProjectReport($input: ProjectReportInput!) {
    createProjectReport(input: { projectReport: $input }) {
      projectReport {
        id
      }
    }
  }
`;

const DELETE_PROJECT_REPORT = gql`
  mutation DeleteProjectReport($id: Int!) {
    deleteProjectReportById(input: { id: $id }) {
      clientMutationId
    }
  }
`;

const EDIT_PROJECT = gql`
  mutation EditProjectMutation($input: UpdateProjectByIdInput!) {
    updateProjectById(input: $input) {
      project {
        id
        name
      }
    }
  }
`;

const PROJECT_CUSTOM_PQ_PRODUCT_NAMES = gql`
  query ProjectCustomPqProductNames($projectId: Int!) {
    allCustomPqProductNames(condition: { projectId: $projectId }) {
      nodes {
        id
        productName
      }
    }
  }
`;

const DELETE_CUSTOM_PQ_PRODUCT_NAME = gql`
  mutation DeleteCustomPqProductName($Id: Int!) {
    deleteCustomPqProductNameById(input: { id: $Id }) {
      clientMutationId
      deletedCustomPqProductNameId
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation DeleteProjectMutationById($Id: Int!) {
    deleteProjectById(input: { id: $Id }) {
      project {
        id
        name
      }
    }
  }
`;

interface CreateEditProjectValues {
  projectId?: number;
  projectReports: any[];
  name: string;
  isDelete: boolean;
}

const { ga } = ReactGA;

export const handleCreateProject =
  (browserHistory: History) =>
  async (values: CreateEditProjectValues, actions) => {
    const workspaceId = workspaceProducerId(store.getState());

    // Craft the shape of the CreatePanelInput
    const projectInput = {
      workspaceId,
      name: values.name,
    };

    try {
      const createProjectResult = await graphqlClient.mutate({
        mutation: CREATE_PROJECT,
        variables: {
          input: projectInput,
        },
      });

      const projectId = createProjectResult.data.createProject.project.id;

      // Map over the project report list and add each to the project
      values.projectReports.forEach(async (rp) => {
        const projectReportResult = await graphqlClient.mutate({
          mutation: CREATE_PROJECT_REPORT,
          variables: {
            input: {
              projectId,
              reportId: rp.reportId,
            },
          },
        });
      });

      Alert.success('Project created successfully', {
        position: 'top-right',
        effect: 'slide',
        beep: false,
        timeout: 4000,
      });

      actions.setSubmitting(false);
      browserHistory.push(PROJECTS);
    } catch (e) {
      console.error(e);
      Alert.error('Project creation failed. Please try again', {
        position: 'top-right',
        effect: 'slide',
        beep: false,
        timeout: 4000,
      });
      actions.setErrors({
        submission: 'There was an error creating the project.',
      });
      actions.setSubmitting(false);

      ga('send', 'exception', {
        description: `Create Project Failed - ${e.message}`,
        fatal: false,
      });
    }
  };

export const handleEditProject =
  (browserHistory: History, projectReportIds?: number[]) =>
  async (values: CreateEditProjectValues, actions) => {
    try {
      // first delete associated reports for the project if any
      if (values.isDelete && projectReportIds?.length) {
        projectReportIds.forEach(async (rp) => {
          await graphqlClient.mutate({
            mutation: DELETE_PROJECT_REPORT,
            variables: {
              id: rp,
            },
          });
        });
      }

      if (values.isDelete) {
        const customNamesResponse = await graphqlClient.query({
          query: PROJECT_CUSTOM_PQ_PRODUCT_NAMES,
          variables: {
            projectId: values.projectId,
          },
          fetchPolicy: 'no-cache',
        });

        const customNameIds =
          customNamesResponse.data.allCustomPqProductNames.nodes.map(
            (n) => n.id,
          );
        for (const cnId of customNameIds) {
          await graphqlClient.query({
            query: DELETE_CUSTOM_PQ_PRODUCT_NAME,
            variables: {
              Id: cnId,
            },
            fetchPolicy: 'no-cache',
          });
        }

        await graphqlClient.query({
          query: DELETE_PROJECT,
          variables: {
            Id: values.projectId,
          },
          fetchPolicy: 'no-cache',
        });

        Alert.success('Project deleted successfully', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 4000,
        });

        actions.setSubmitting(false);
        browserHistory.push(PROJECTS);
      } else {
        const projectToEditResult = await graphqlClient.query({
          query: PROJECT_TO_EDIT,
          variables: {
            projectId: values.projectId,
          },
          fetchPolicy: 'no-cache',
        });

        const projectReportsToAdd = values.projectReports.filter(
          (f) =>
            !projectToEditResult.data.projectById.projectReportsByProjectId.nodes.some(
              (s) => f.reportId == s.reportId,
            ),
        );
        const projectReportsToRemove =
          projectToEditResult.data.projectById.projectReportsByProjectId.nodes.filter(
            (f) => !values.projectReports.some((s) => f.reportId == s.reportId),
          );

        // add new reports just added
        if (projectReportsToAdd.length) {
          projectReportsToAdd.forEach(async (rp) => {
            await graphqlClient.mutate({
              mutation: CREATE_PROJECT_REPORT,
              variables: {
                input: {
                  projectId: values.projectId,
                  reportId: rp.reportId,
                },
              },
            });
          });
        }

        // delete reports that were removed
        if (projectReportsToRemove.length) {
          projectReportsToRemove.forEach(async (rp) => {
            await graphqlClient.mutate({
              mutation: DELETE_PROJECT_REPORT,
              variables: {
                id: rp.id,
              },
            });
          });
        }

        // update project name if changed
        const initialname: string = projectToEditResult.data.projectById.name;
        if (initialname != values.name) {
          const editProjectResult = await graphqlClient.mutate({
            mutation: EDIT_PROJECT,
            variables: {
              input: {
                id: values.projectId,
                projectPatch: {
                  name: values.name,
                },
              },
            },
          });
        }

        Alert.success('Project edited successfully', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 4000,
        });

        actions.setSubmitting(false);
        browserHistory.push(PROJECTS);
      }
    } catch (e) {
      console.error(e);
      Alert.error('Edit project failed. Please try again', {
        position: 'top-right',
        effect: 'slide',
        beep: false,
        timeout: 4000,
      });
      actions.setErrors({
        submission: 'There was an error editing the project.',
      });
      actions.setSubmitting(false);

      ga('send', 'exception', {
        description: `Edit Project Failed - ${e.message}`,
        fatal: false,
      });
    }
  };
