import { reduxForm } from 'redux-form';

import UserInvalidModal from './UserInvalidModal';
import formSubmit from '../../../../actions/formSubmit';
import { INVITE_USER_FORM } from '../../../../constants/formNames';

export default reduxForm({
  form: INVITE_USER_FORM,
  onSubmit: (values, dispatch) => {
    dispatch(formSubmit(INVITE_USER_FORM));
  },
})(UserInvalidModal);
