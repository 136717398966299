import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';

import AppHeaderViewerMenu from './AppHeaderViewerMenu';
import appViewerGlossaryIsOpenSet from '../../actions/appViewerGlossaryIsOpenSet';
import appViewerMenuIsOpenSet from '../../actions/appViewerMenuIsOpenSet';
import appViewerNotificationIsOpenSet from '../../actions/appViewerNotificationIsOpenSet';
import sessionClear from '../../actions/sessionClear';
import ViewerQuery from '../../graphql/queries/ViewerQuery';
import selectAppViewerGlossaryIsOpen from '../../selectors/appViewerGlossaryIsOpen';
import selectAppViewerMenuIsOpen from '../../selectors/appViewerMenuIsOpen';
import selectAppViewerNotificationIsOpen from '../../selectors/appViewerNotificationIsOpen';
import selectSessionToken from '../../selectors/sessionToken';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';

const mapStateToProps = (state) => ({
  menuIsOpen: selectAppViewerMenuIsOpen(state),
  notificationIsOpen: selectAppViewerNotificationIsOpen(state),
  glossaryIsOpen: selectAppViewerGlossaryIsOpen(state),
  viewerIsAuthenticated: !!selectSessionToken(state),
  workspaceProducerId: selectWorkspaceProducerId(state),
});

const actionCreators = {
  onClickCloseMenu: () => appViewerMenuIsOpenSet(false),
  onClickOpenMenu: () => appViewerMenuIsOpenSet(true),
  onClickCloseNotification: () => appViewerNotificationIsOpenSet(false),
  onClickOpenNotification: () => appViewerNotificationIsOpenSet(true),
  onClickCloseGlossary: () => appViewerGlossaryIsOpenSet(false),
  onClickOpenGlossary: () => appViewerGlossaryIsOpenSet(true),
  onClickSignOut: sessionClear,
};

export default compose(
  graphql<any, any, any, any>(ViewerQuery, {
    props: ({ data: { viewer } }) => ({
      viewerId: viewer && viewer.id,
      viewerEmail: viewer && viewer.email,
      viewerName: viewer && viewer.name,
      viewerUsername: viewer && viewer.username,
      viewerLanguage: viewer && viewer.interfaceLanguage,
    }),
  }),
  connect(mapStateToProps, actionCreators),
)(AppHeaderViewerMenu);
