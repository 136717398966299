import { OPTIMIZATION } from 'constants/report';

import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import ReportGgVarTuningPlot from '@graphql/queries/ReportGgVarTuningPlot';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import FlavorStructureDiagramIcon from 'components/FlavorStructureDiagram/FlavorStructureDiagramIcon';
import FlavorStructureDiagramModal from 'components/FlavorStructureDiagram/FlavorStructureDiagramModal';
import SvgFileIcon from 'components/Icons/DownloadIcons/SvgFileIcon';
import LoadingScreen from 'components/LoadingScreen';
import GGVarImpact from 'components/Report/GGVarImpact';
import PqIntensityDiagram from 'components/Report/PqIntensityDiagram';
import { ProductVersion } from 'components/Report/ProductVersion';
import { getProductName } from 'components/Report/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import * as htmlToImage from 'html-to-image';
import { useTranslation } from 'react-i18next';
import { tuningPlotService } from 'services/tuningPlotService';
import { ExtremaRow } from 'services/utils/tuningPlotUtils';
import { Optional } from 'typescript-optional';

import styles from '../ProjectSummary.module.css';

export const mostPositiveWhenIncreased = (
  input: reports.ExtremaData[],
): reports.ExtremaData => {
  return input
    .filter(
      (ed) =>
        ed.maxCategory === '+' ||
        ed.maxCategory === '++' ||
        ed.maxCategory === '+++',
    )
    .reduce((a, b) => (a && a.maxExtrema >= b.maxExtrema ? a : b), null);
};

export const mostPositiveWhenDecreased = (
  input: reports.ExtremaData[],
): reports.ExtremaData => {
  return input
    .filter(
      (ed) =>
        ed.minCategory === '+' ||
        ed.minCategory === '++' ||
        ed.minCategory === '+++',
    )
    .reduce((a, b) => (a && a.minExtrema >= b.minExtrema ? a : b), null);
};

interface Props {
  projectId: number;
  projectReports: reports.ReportProductInfo[];
  selectedReportId: string;
  product?: ProductVersion;
}

const meanProduct: ProductVersion = new ProductVersion(null, null);

function getSelectedReport(
  projectReports: reports.ReportProductInfo[],
  selectedReportId: string,
) {
  return Optional.of(projectReports).map((pr) =>
    pr.find((r) => r.reportId === selectedReportId),
  );
}

function getProducts(
  projectReports: reports.ReportProductInfo[],
  selectedReportId: string,
) {
  return getSelectedReport(projectReports, selectedReportId)
    .map((report) => {
      if (report.reportType !== OPTIMIZATION) {
        return [meanProduct].concat(
          report.products.map((p) => new ProductVersion(p.id, p.version)),
        );
      } else {
        return report.products.map((p) => new ProductVersion(p.id, p.version));
      }
    })
    .orElse([]);
}

function getDefaultProduct(
  projectReports: reports.ReportProductInfo[],
  selectedReportId: string,
  product: ProductVersion,
) {
  return getSelectedReport(projectReports, selectedReportId)
    .map((report) => {
      if (report.reportType !== OPTIMIZATION) {
        return product || meanProduct;
      } else {
        if (product.equals(meanProduct)) {
          return getProducts(projectReports, selectedReportId).find(
            (p) => p.version === null,
          );
        } else {
          return product;
        }
      }
    })
    .orElse(null);
}

const ProjectFlavorSummary: React.FC<Props> = (props) => {
  const { projectReports, selectedReportId, projectId, product } = props;

  const [selectedProduct, setSelectedProduct] = useState<ProductVersion>(
    getDefaultProduct(projectReports, selectedReportId, product),
  );
  const [isLocal, setIsLocal] = useState<boolean>(false);
  const [showFSDModal, setShowFSDModal] = useState<boolean>(false);

  const { t } = useTranslation();
  const customProductNames = useCustomProductNames({
    projectId,
    reportId: selectedReportId,
  });

  const { data, loading, error } = useQuery<reports.GgVarTuningPlotResponse>(
    ReportGgVarTuningPlot,
    {
      variables: {
        reportID: selectedReportId,
        productID: selectedProduct.productId,
      },
    },
  );

  const enrichData = (row: ExtremaRow): reports.ExtremaData => ({
    ggvar: row.ggvar,
    productId: row.productId,
    version: row.version,
    productName: getProductName({
      productNames: customProductNames,
      productId: selectedProduct.productId,
      version: selectedProduct.version,
    }),
    projectId: projectId,
    reportId: row.reportId,
    minExtrema: row.minExtrema,
    maxExtrema: row.maxExtrema,
    minCategory: tuningPlotService.categorizeExtrema(row.minExtrema),
    maxCategory: tuningPlotService.categorizeExtrema(row.maxExtrema),
  });

  const downloadAsSvg = () => {
    const doc = document.getElementById('pqDiagramDivId');
    const anchorTags = doc.querySelectorAll('a');
    anchorTags.forEach((anchor) => {
      const innerContent = anchor.querySelector('span');
      if (anchor.parentNode && anchor.parentNode.replaceChild)
        anchor.parentNode.replaceChild(innerContent, anchor);
    });

    htmlToImage.toSvg(doc).then((dataUrl) => {
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'pq-intensity.svg';
      link.click();
    });
  };

  if (loading || error) {
    return <LoadingScreen />;
  }

  return (
    <div className={styles.tabContainer}>
      {projectReports && (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            {!meanProduct.equals(selectedProduct) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  id="svg-link"
                  size="small"
                  onClick={() => setShowFSDModal(true)}
                >
                  <FlavorStructureDiagramIcon fontSize="large" />
                </IconButton>
                <FlavorStructureDiagramModal
                  showFSDModal={showFSDModal}
                  setShowFSDModal={setShowFSDModal}
                  reportId={selectedReportId}
                  productId={selectedProduct.productId}
                  productName={getProductName({
                    productNames: customProductNames,
                    productId: selectedProduct.productId,
                    version: selectedProduct.version,
                  })}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={6}>
            <div className={styles.selectProductView}>
              <div className={styles.select}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="report-id-select-label">
                    {t('flavorSummary.product')}
                  </InputLabel>
                  <Select
                    variant="standard"
                    id="product-id-select"
                    labelId="product-id-select-label"
                    value={JSON.stringify(selectedProduct)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setSelectedProduct(JSON.parse(event.target.value))
                    }
                  >
                    {getProducts(projectReports, selectedReportId).map(
                      (product) => (
                        <MenuItem
                          key={product.value}
                          value={JSON.stringify(product)}
                        >
                          {product.productId
                            ? getProductName({
                                productNames: customProductNames,
                                productId: product.productId,
                                version: product.version,
                              })
                            : 'Mean Product'}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <FormGroup row>
              <Tooltip title={t('flavorSummary.localOnlyTooltip')}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isLocal}
                      onChange={(e) => setIsLocal(e.target.checked)}
                      name="isLocal"
                    />
                  }
                  label={
                    <Typography variant="caption">
                      {t('flavorSummary.localOnly')}
                    </Typography>
                  }
                />
              </Tooltip>
              <IconButton id="svg-link" size="small" onClick={downloadAsSvg}>
                <SvgFileIcon fontSize="large" />
              </IconButton>
            </FormGroup>
          </Grid>
          <Grid item xs={12} id="pqDiagramDivId">
            <Grid item xs={12}>
              {projectReports && (
                <div>
                  <PqIntensityDiagram
                    extremaData={tuningPlotService
                      .extremaData(data, selectedProduct, isLocal)
                      .map((row) => enrichData(row))}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={3}>
                  <GGVarImpact
                    type="increased"
                    extrema={mostPositiveWhenIncreased(
                      tuningPlotService
                        .extremaData(data, selectedProduct, isLocal)
                        .map((row) => enrichData(row)),
                    )}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={3}>
                  <GGVarImpact
                    type="decreased"
                    extrema={mostPositiveWhenDecreased(
                      tuningPlotService
                        .extremaData(data, selectedProduct, isLocal)
                        .map((row) => enrichData(row)),
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ProjectFlavorSummary;
