import dataQualityMetrics from 'constants/dataQualityMetrics';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import AllPanelistsQuery from '@graphql/queries/AllPanelistsQuery';
import { LinearProgress, Tooltip } from '@mui/material';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import { get } from 'lodash';
import moment from 'moment';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './PanelUser.module.css';
import { USER } from '../../constants/routePaths';
import formatPath from '../../utils/formatPath';

const panelUserCustomStyles = {
  table: {
    style: {
      maxHeight: 'none',
    },
  },
  headCells: {
    style: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  cells: {
    style: {
      fontSize: 12,
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
};

const getFlagPercentage = (reviewData) => {
  const { nodes: reviews, totalCount } = reviewData;
  let flagCount = 0;

  reviews.forEach(({ dataQuality }) => {
    if (dataQuality !== null) {
      dataQualityMetrics.forEach((metric) => {
        if (dataQuality[metric]) {
          flagCount++;
        }
      });
    }
  });

  return flagCount / totalCount;
};

const getDqdMetrics = (reviewData) => {
  const { nodes: reviews, totalCount } = reviewData;
  const flags = {};
  let totalFlagCount = 0;
  let poorReviewCount = 0;

  dataQualityMetrics.forEach((metric) => {
    flags[metric] = 0;
  });

  reviews.forEach(({ dataQuality }) => {
    let reviewFlagCount = 0;
    if (dataQuality !== null) {
      dataQualityMetrics.forEach((metric) => {
        if (dataQuality[metric]) {
          flags[metric]++;
          totalFlagCount++;
          reviewFlagCount++;
        }
      });
    }

    // If review has more than 3 flag, increment poor review count
    if (reviewFlagCount >= 3) {
      poorReviewCount++;
    }
  });

  const metrics = Object.keys(flags);
  metrics.forEach((metric) => {
    flags[metric] = formatMetric(flags[metric], totalCount);
  });

  return {
    ...flags,
    poorReviewCount: formatMetric(poorReviewCount, totalCount),
    flagPercentage: (totalFlagCount / totalCount).toFixed(2),
  };
};

const formatMetric = (flagCount, totalCount) => {
  if (!flagCount || !totalCount) {
    return '-';
  } else {
    return `${flagCount} (${((flagCount / totalCount) * 100).toFixed(0)}%)`;
  }
};

const formatData = (data) =>
  get(data, 'panelists.edges', []).map(({ node: user }) => {
    const currentFlags: any = getDqdMetrics(user.productReviews);

    return {
      id: user.id,
      username: (
        <Link to={formatPath(USER, { userId: user.id })}>
          {user.username || user.email}
        </Link>
      ),
      totalReviews: get(user, 'productReviews.totalCount'),
      lastActive: user.lastActive,
      allGgVar: currentFlags.allGgVar,
      ggVarMax: currentFlags.ggVarMax,
      insufficientGgVar: currentFlags.insufficientGgVar,
      noRefFlavor: currentFlags.noRefFlavor,
      excessiveRefFlavor: currentFlags.excessiveRefFlavor,
      shortReviewTime: currentFlags.shortReviewTime,
      buttonMashing: currentFlags.buttonMashing,
      poorReviewCount: currentFlags.poorReviewCount,
      avgFlagAll:
        get(user, 'productReviews.totalCount', 0) > 0
          ? currentFlags.flagPercentage
          : '-',
      avgFlagRecent:
        get(user, 'recentReviews.totalCount', 0) > 0
          ? getFlagPercentage(user.recentReviews).toFixed(2)
          : '-',
    };
  });
interface Props {
  workspaceId: number;
  filter: any;
}

interface TableRow {
  id: number;
  username: string;
  totalReviews: string;
  allGgVar: string;
  ggVarMax: string;
  insufficientGgVar: string;
  noRefFlavor: string;
  excessiveRefFlavor: string;
  shortReviewTime: string;
  buttonMashing: string;
  lastActive: string;
  poorReviewCount: string;
  avgFlagAll: string;
  avgFlagRecent: string;
}

const PanelUserList: React.FunctionComponent<Props> = ({
  workspaceId,
  filter,
}) => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(1);
  const [sizePerPage, setSizePerPage] = useState<number>(10);
  const [orderBy, setOrderBy] = useState({
    order: 'desc',
    column: 'lastActive',
    key: 'LAST_ACTIVE_DESC',
  });
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);

  const { loading, error, data, fetchMore } = useQuery(AllPanelistsQuery, {
    variables: {
      offset: (page - 1) * sizePerPage,
      first: sizePerPage,
      workspaceId,
      recentDate: moment().startOf('day').subtract(30, 'days').toISOString(),
      filter,
      orderBy: [orderBy.key, 'ID_ASC'],
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    if (data) {
      setTotalRows(data.panelists.totalCount);
      setFormattedData(formatData(data));
    }
  }, [data, orderBy]);

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: 'User ID',
      omit: true,
    },
    {
      id: 'username',
      selector: (row: TableRow) => row.username,
      name: <div className={styles.tableHeader}>{t('users.username')}</div>,
      sortable: true,
      sortField: 'USERNAME',
      wrap: true,
    },
    {
      id: 'totalReviews',
      selector: (row: TableRow) => row.totalReviews,
      name: <div className={styles.tableHeader}>{t('users.totalReviews')}</div>,
      wrap: true,
      minWidth: '75px',
      center: true,
    },
    {
      id: 'allGgVar',
      selector: (row: TableRow) => row.allGgVar,
      name: (
        <Tooltip title={t('dataQuality.tooltip.allGgVar')}>
          <div className={styles.tableHeader}>{t('All GGVar Marked')}</div>
        </Tooltip>
      ),
      wrap: true,
      compact: true,
      minWidth: '75px',
      center: true,
    },
    {
      id: 'ggVarMax',
      selector: (row: TableRow) => row.ggVarMax,
      name: (
        <Tooltip title={t('dataQuality.tooltip.maxGgVar')}>
          <div className={styles.tableHeader}>{t('Max GGVar')}</div>
        </Tooltip>
      ),
      wrap: true,
      minWidth: '75px',
      center: true,
    },
    {
      id: 'insufficientGgVar',
      selector: (row: TableRow) => row.insufficientGgVar,
      name: (
        <Tooltip title={t('dataQuality.tooltip.insufficientGgVar')}>
          <div className={styles.tableHeader}>{t('< 4 GGVar')}</div>
        </Tooltip>
      ),
      wrap: true,
      minWidth: '75px',
      center: true,
    },
    {
      id: 'noRefFlavor',
      selector: (row: TableRow) => row.noRefFlavor,
      name: (
        <Tooltip title={t('dataQuality.tooltip.noReferenceFlavor')}>
          <div className={styles.tableHeader}>{t('No Reference Flavors')}</div>
        </Tooltip>
      ),
      wrap: true,
      minWidth: '75px',
      center: true,
    },
    {
      id: 'excessiveRefFlavor',
      selector: (row: TableRow) => row.excessiveRefFlavor,
      name: (
        <Tooltip title={t('dataQuality.tooltip.excessiveReferenceFlavor')}>
          <div className={styles.tableHeader}>
            {t('> 40 Reference Flavors')}
          </div>
        </Tooltip>
      ),
      wrap: true,
      minWidth: '75px',
      center: true,
    },
    {
      id: 'shortReviewTime',
      selector: (row: TableRow) => row.shortReviewTime,
      name: (
        <Tooltip title={t('dataQuality.tooltip.fastReview')}>
          <div className={styles.tableHeader}>
            {t('Review Time < 30 Seconds')}
          </div>
        </Tooltip>
      ),
      wrap: true,
      minWidth: '75px',
      center: true,
    },
    {
      id: 'buttonMashing',
      selector: (row: TableRow) => row.buttonMashing,
      name: (
        <Tooltip title={t('dataQuality.tooltip.buttonMashing')}>
          <div className={styles.tableHeader}>{t('Button Mashing')}</div>
        </Tooltip>
      ),
      wrap: true,
      minWidth: '75px',
      center: true,
    },
    {
      id: 'lastActive',
      selector: (row: TableRow) => row.lastActive,
      name: <div className={styles.tableHeader}>{t('users.lastActive')}</div>,
      wrap: true,
      cell: (row) =>
        row.lastActive ? moment(row.lastActive).format('L') : 'N.A',
    },
    {
      id: 'poorReviewCount',
      selector: (row: TableRow) => row.poorReviewCount,
      name: <div className={styles.tableHeader}>{t('Poor Reviews')}</div>,
      wrap: true,
      minWidth: '75px',
      center: true,
    },
    {
      id: 'avgFlagAll',
      selector: (row: TableRow) => row.avgFlagAll,
      name: <div className={styles.tableHeader}>{t('Average Flag (All)')}</div>,
      wrap: true,
      minWidth: '75px',
      center: true,
    },
    {
      id: 'avgFlagRecent',
      selector: (row: TableRow) => row.avgFlagRecent,
      name: (
        <div className={styles.tableHeader}>{t('Average Flag (Recent)')}</div>
      ),
      wrap: true,
      minWidth: '75px',
      center: true,
    },
  ];

  const handlePageChange = (newPage: number) => {
    fetchMore({
      variables: {
        offset: (newPage - 1) * sizePerPage,
        first: sizePerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
    setPage(newPage);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setSizePerPage(newPerPage);
    setPage(1);
  };

  const handleSort = async (
    column: TableColumn<TableRow>,
    sortDirection: SortOrder,
  ) => {
    setOrderBy({
      order: sortDirection,
      column: column.id as string,
      key: `${column.sortField}_${sortDirection.toUpperCase()}`,
    });
  };

  if (error) {
    return <div>ERROR: Unable to user data!</div>;
  }

  return (
    <DataTable
      customStyles={panelUserCustomStyles}
      columns={columns}
      data={formattedData}
      progressPending={loading}
      progressComponent={<LinearProgress color="secondary" />}
      persistTableHead
      striped
      pagination
      paginationIconFirstPage={<RDTFirstPage />}
      paginationIconLastPage={<RDTLastPage />}
      paginationIconPrevious={<RDTPrevPage />}
      paginationIconNext={<RTDNextPage />}
      paginationServer
      paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
      paginationPerPage={sizePerPage}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      paginationTotalRows={totalRows}
      paginationDefaultPage={page}
      sortServer
      onSort={handleSort}
    />
  );
};

export default PanelUserList;
