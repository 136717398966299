import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'gray',
    },
    active: {
      color: theme.palette.secondary.main,
    },
  }),
);

export default useStyles;
