import * as d3 from 'd3';

export const colorScale = d3.interpolatePlasma;

export const appendNoveltyLegend = (
  svg: d3.Selection<any, unknown, null, undefined>,
  width: number,
  scaleHeight: number,
) => {
  const legendRects = 20,
    rectWidth = width / 100,
    rectHeight = scaleHeight / 3;

  const g = svg
    .append('g')
    .attr('transform', `translate(${width / 2},${scaleHeight / 3})`)
    .attr('id', 'noveltyStackRankLegend');

  g.selectAll('rect')
    .data(Array.from(Array(legendRects).keys()))
    .enter()
    .append('rect')
    .attr('width', rectWidth)
    .attr('height', rectHeight)
    .attr(
      'transform',
      (_, i) => `translate(${-(rectWidth * (i - legendRects / 2))},${0})`,
    )
    .attr('fill', (d) => colorScale(d / legendRects));

  g.selectAll('text')
    .data([
      { x: (-rectWidth * legendRects) / 2, label: 'More similar' },
      { x: (rectWidth * legendRects) / 2, label: 'More different' },
    ])
    .enter()
    .append('text')
    .attr('dx', (d) => d.x)
    .attr('dy', `${scaleHeight * (2 / 3)}`)
    .attr('text-anchor', 'middle')
    .attr('font-size', 10)
    .attr('fill', 'black')
    .text((d) => d.label);

  return g;
};
