import { gql } from '@apollo/client';
import graphqlClient from 'consumers/graphqlClient';
import { getFormValues, startSubmit, stopSubmit, destroy } from 'redux-form';
import { put, select, take } from 'redux-saga/effects';

import appToastAdd from '../actions/appToastAdd';
import errorAction from '../actions/error';
import { FORM_SUBMIT } from '../actions/formSubmit';
import { PARTNER_BRANDING_FORM } from '../constants/formNames';

const updatePartnerBrandingMutation = gql`
  mutation updatePartnerBranding(
    $updatePartnerBrandingPayload: UpdatePartnerBrandingByPartnerIdInput!
  ) {
    updatePartnerBrandingByPartnerId(input: $updatePartnerBrandingPayload) {
      partnerByPartnerId {
        name
      }
      partnerBranding {
        branding
      }
    }
  }
`;

function parseErrorMessage(error) {
  if (error.response?.data?.detail != null) {
    return error.response?.data?.detail;
  } else {
    return error.message;
  }
}

export default function* updatePartnerBrandingSaga() {
  while (true) {
    yield take(
      ({ type, payload }) =>
        type === FORM_SUBMIT && payload === PARTNER_BRANDING_FORM,
    );

    yield put(startSubmit(PARTNER_BRANDING_FORM));
    const formValues = yield select(getFormValues(PARTNER_BRANDING_FORM));

    try {
      const payload = {
        partnerId: formValues.partner.id,
        partnerBrandingPatch: {
          partnerId: formValues.partner.id,
          branding: {
            banner_base_color: formValues.banner_base_color,
            action_button_color: formValues.action_button_color,
            banner_logo_src: formValues.banner_logo_src,
          },
        },
      };

      const updateResult = yield graphqlClient.mutate({
        mutation: updatePartnerBrandingMutation,
        variables: { updatePartnerBrandingPayload: payload },
      });
      console.log(updateResult);

      // If this point is reached, the form was submitted without error
      yield put(stopSubmit(PARTNER_BRANDING_FORM));

      yield put(
        appToastAdd({
          durationMilliseconds: 4000,
          message: `${formValues.partner.name} Branding Updated`,
          title: 'Partner Branding Update Successful',
          toastKey: `toast_${Date.now()}`,
        }),
      );

      // Destroy the form so that it is re-rendered after the below route change
      yield put(destroy(PARTNER_BRANDING_FORM));
    } catch (error) {
      yield put(stopSubmit(PARTNER_BRANDING_FORM, error));
      yield put(
        errorAction({
          error,
          title: 'Failed to Update Branding',
          description: parseErrorMessage(error),
        }),
      );
    }
  }
}
