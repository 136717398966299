import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
    cardContent: {
      backgroundColor: theme.palette.tableRow.main,
    },
    container: {
      flex: 1,
      padding: 10,
    },
    h6: {
      fontSize: '16px',
      fontWeight: 'normal',
    },
    mainText: {
      fontWeight: 'bold',
    },
    header: {
      fontSize: '2em',
      fontStyle: 'bold',
      textAlign: 'center',
    },
  }),
);

export default useStyles;
