import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { AllDevicesResponse } from '@graphql/queries/AllDevicesQuery';
import { HighlightOffOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import TableSearch from 'components/Table/TableSearch';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './DeviceList.module.css';
import MaterialButton from '../../components/MaterialButton';
import { SUPERADMIN_ADD_DEVICES } from '../../constants/routePaths';
import AllDevicesQuery from '../../graphql/queries/AllDevicesQuery';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import { PageHeader } from '../../styles/themeComponents';

interface Props {
  handleDeleteDevice: (deviceId: number) => void;
  producerId?: number;
}

interface TableRow {
  id: number;
  name: string;
  deviceUid: string;
  action: string;
}

const DeviceListContainer: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const producerId = useSelector((state) => selectWorkspaceProducerId(state));

  const dispatch = useDispatch();
  const handleDeleteDevice = (deviceId) =>
    dispatch({ type: 'DELETE_DEVICE', payload: deviceId });

  const [currentData, setCurrentData] = useState<TableRow[]>([]);

  const { data, loading, error } = useQuery<AllDevicesResponse>(
    AllDevicesQuery,
    {
      variables: {
        condition: {
          producerId: producerId,
        },
      },
    },
  );

  useEffect(() => {
    if (data) {
      setCurrentData(getDevices(data.allCorporateDevices.nodes));
    }
  }, [data]);

  const getDevices = (data): TableRow[] =>
    data &&
    data.map((device) => ({
      id: device.id,
      name: device.deviceName && device.deviceName,
      deviceUid: device.deviceUid,
      action: (
        <IconButton size="small" onClick={() => handleDeleteDevice(device.id)}>
          <HighlightOffOutlined color="primary" fontSize="small" />
        </IconButton>
      ),
    }));

  const filterData = (devicesResult, term) => {
    const filteredList = devicesResult.allCorporateDevices.nodes.filter((row) =>
      JSON.stringify(row).toLowerCase().includes(term.toLowerCase()),
    );
    setCurrentData(getDevices(filteredList));
  };

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: t('device.deviceId'),
      sortable: true,
    },
    {
      id: 'name',
      selector: (row: TableRow) => row.name,
      name: <div className={styles.tableHeader}>{t('device.deviceName')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'deviceUid',
      selector: (row: TableRow) => row.deviceUid,
      name: <div className={styles.tableHeader}>{t('device.deviceUid')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'action',
      selector: (row: TableRow) => row.action,
      name: <div className={styles.tableHeader}>{t('general.delete')}</div>,
      wrap: true,
    },
  ];

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <h3>Failed to load Devices.</h3>;
  }

  return (
    <Paper className={styles.deviceList}>
      <div className={styles.actionContainer}>
        <div className={styles.headerTextContainer}>
          <PageHeader>Manage Devices</PageHeader>
          <h3 className={styles.deviceTitle}>Devices</h3>
        </div>
        <TableSearch
          onSearch={(term) => filterData(data, term)}
          placeholder={t('general.search')}
        />
        <Link to={SUPERADMIN_ADD_DEVICES}>
          <MaterialButton variant="outlined" soft>
            {t('device.addDevice')}
          </MaterialButton>
        </Link>
      </div>
      <DataTable
        columns={columns}
        data={currentData}
        fixedHeader
        striped
        pagination
        paginationIconFirstPage={<RDTFirstPage />}
        paginationIconLastPage={<RDTLastPage />}
        paginationIconPrevious={<RDTPrevPage />}
        paginationIconNext={<RTDNextPage />}
      />
    </Paper>
  );
};

export default DeviceListContainer;
