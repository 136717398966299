import * as React from 'react';

import { useTheme } from '@mui/material';

import useStyles from './useStyles';

const crossStr = String.fromCharCode(215);

const RemoveComponent = (props) => {
  const { readOnly, onClick, className } = props;
  if (readOnly) {
    return <span />;
  }

  return (
    <a onClick={onClick} className={className} onKeyDown={onClick}>
      {crossStr}
    </a>
  );
};

const Tag = (props) => {
  const { readOnly, label } = props;

  const classes = useStyles(useTheme());

  return (
    <span className={classes.tagContainer}>
      {label}
      <RemoveComponent
        tag={props.tag}
        className={classes.remove}
        onClick={props.onDelete}
        readOnly={readOnly}
      />
    </span>
  );
};
export default Tag;
