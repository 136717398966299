import { GgVarAttributes, mapFromKey } from 'constants/ggVars';
import {
  PROJECT_FLAVOR_SUMMARY_CHART,
  REPORT_PRODUCT_SUMMARY,
} from 'constants/routePaths';

import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import ReportStackRankComparisonQuery from '@graphql/queries/ReportStackRankComparisonQuery';
import { useTheme, Box } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import {
  HighlightableTableRow,
  hightlightedConditionalRowStyles,
} from 'components/ReactDataTable/highlightedRowStyles';
import dataTableCsv from 'components/ReactDataTable/ReactDataTableCsv';
import { lineBreak } from 'components/ReactDataTable/reactDataTableUtils';
import { tableCustomStyles } from 'components/ReactDataTable/tableCustomStyles';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary, {
  ReportSummaryDetailNode,
} from 'hooks/useReportSummary';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useStyles from './useStyles';
import { ProjectTabs } from '../../../services/customerPreferences';
import { ComponentTitle } from '../../../styles/themeComponents';
import formatPath from '../../../utils/formatPath';
import { ProductVersion, parseProductVersionKey } from '../ProductVersion';
import { bankersRound } from '../ReportGastrographRadar/RadarUtils';
import { getProductName, isProductHighlighted } from '../utils';

interface ReportProps {
  reportId: string;
  projectId: number;
}

interface VersionedReportFpDecompRow extends reports.ReportFpDecompRow {
  productId: number;
  productVersion: string;
}

export interface TableRow extends HighlightableTableRow, GgVarAttributes {
  productName: string;
  productId: number;
  version: string;
  pq: number;
  isHighlighted: boolean;
}

const formatNum = (row, key) =>
  row[key] ? bankersRound(row[key] ?? 0, 2) : null;

export const mapToTableRow = (productNames, data: any[]): TableRow[] => {
  if (!data) {
    return [];
  }

  return data.map((r, idx) => ({
    productName: getProductName({
      productNames,
      productId: parseProductVersionKey(r['productVersionKey']).productId,
      version: parseProductVersionKey(r['productVersionKey']).version,
    }),
    isHighlighted: isProductHighlighted({
      productNames,
      productId: parseProductVersionKey(r['productVersionKey']).productId,
      version: parseProductVersionKey(r['productVersionKey']).version,
    }),
    productId: parseProductVersionKey(r['productVersionKey']).productId,
    version: parseProductVersionKey(r['productVersionKey']).version,
    pq: r['pq'],
    wet: formatNum(r, 'wet'),
    roasted: formatNum(r, 'roasted'),
    earthy: formatNum(r, 'earthy'),
    astringent: formatNum(r, 'astringent'),
    marine: formatNum(r, 'marine'),
    mineral: formatNum(r, 'mineral'),
    retronasal: formatNum(r, 'retronasal'),
    gamey: formatNum(r, 'gamey'),
    woody: formatNum(r, 'woody'),
    bitter: formatNum(r, 'bitter'),
    meaty: formatNum(r, 'meaty'),
    smoked: formatNum(r, 'smoked'),
    dry: formatNum(r, 'dry'),
    rich: formatNum(r, 'rich'),
    dairy: formatNum(r, 'dairy'),
    mouthfeel: formatNum(r, 'mouthfeel'),
    nutsAndSeeds: formatNum(r, 'nutsAndSeeds'),
    herbaceous: formatNum(r, 'herbaceous'),
    coldFinish: formatNum(r, 'coldFinish'),
    floral: formatNum(r, 'floral'),
    spices: formatNum(r, 'spices'),
    sourAndAcidity: formatNum(r, 'sourAndAcidity'),
    fruits: formatNum(r, 'fruits'),
    sugar: formatNum(r, 'sugar'),
  }));
};

const FlavorSummaryAttributeIntensity: React.FC<ReportProps> = (props) => {
  const { reportId, projectId } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const [groupedData, setGroupedData] = useState<any>();
  const [columnKeys, setColumnKeys] = useState<constants.GGVar[]>();

  const {
    loading: comparisonLoading,
    error: comparisonError,
    data: comparisonData,
  } = useQuery<reports.ReportStackRankComparison>(
    ReportStackRankComparisonQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    data: reportSummaryData,
    productVersionPqs,
  } = useReportSummary(reportId, {
    fetchPolicy: 'no-cache',
  });

  const renderEmptyList = () => {
    return (
      <div className={classes.emptyMain}>
        <div className={classes.emptySub}>
          <h5>Your selection produced an empty list!</h5>
        </div>
      </div>
    );
  };

  const groupData = (
    list: VersionedReportFpDecompRow[],
  ): {
    [x: string]: {
      [y: string]: number;
    };
  } => {
    return list.reduce((acc, item) => {
      const version = new ProductVersion(item.productId, item.productVersion)
        .value;
      const cleanGGVar = mapFromKey(item.ggVars);

      if (!acc[version]) {
        acc[version] = {};
      }
      if (!acc[version][cleanGGVar.key]) {
        acc[version] = { ...acc[version], [cleanGGVar.key]: 0 };
      }
      acc[version][cleanGGVar.key] =
        parseFloat(item.decomp) + acc[version][cleanGGVar.key];
      return acc;
    }, {});
  };

  useEffect(() => {
    if (
      !comparisonLoading &&
      !comparisonError &&
      !reportSummaryLoading &&
      !reportSummaryError
    ) {
      const retData: VersionedReportFpDecompRow[] =
        comparisonData.allRpFlavorDecomps.nodes.map((item) => ({
          ...item,
          productId: item.productByProductId.id,
          productVersion: item.version,
        }));

      if (!retData) return;

      const pqData: ReportSummaryDetailNode[] =
        reportSummaryData.allReportJobs.nodes.find(Boolean)
          .reportSummariesByReportId.edges;

      if (!pqData) return;

      const presentGGVars: constants.GGVar[] = Array.from(
        new Set(retData.map((item) => item.ggVars).map(mapFromKey)),
      );
      const grpData = groupData(retData);
      const larr = [];
      for (const productVersionKey in grpData) {
        const rowData = grpData[productVersionKey];
        larr.push({
          ...rowData,
          productVersionKey,
          pq: productVersionPqs.find(
            (i) => i.productVersion.value === productVersionKey,
          )?.pq,
        });
      }
      const topGGVars = presentGGVars
        .map((g) => ({
          ggVar: g,
          sum: retData
            .filter((i) => i.ggVars === g.key)
            .reduce((a, b) => parseFloat(b.decomp ?? '0') + a, 0),
        }))
        .sort((a, b) => (a.sum - b.sum < 0 ? 1 : -1))
        .slice(0, 8)
        .map((i) => i.ggVar);
      setColumnKeys(topGGVars);
      setGroupedData(larr);
    }
  }, [comparisonLoading, reportSummaryLoading, reportId]);

  const columns = (
    columnKeys: constants.GGVar[] = [],
  ): TableColumn<TableRow>[] =>
    [
      {
        id: 'productName',
        name: lineBreak(t('reports.prodSummary.product')),
        width: null,
        selector: (row: TableRow) => row.productName,
        cell: (row: TableRow) => (
          <Link
            key={row.productName + '-link'}
            to={{
              pathname: formatPath(REPORT_PRODUCT_SUMMARY, {
                projectId: projectId,
                reportId: reportId,
                productId: row.productId,
                version: row.version,
              }),
              state: { routeBackLocation: ProjectTabs.EXECUTIVE_SUMMARY },
            }}
          >
            {row.productName}
          </Link>
        ),
        sortable: true,
      },
      {
        id: 'pq',
        name: lineBreak(t('reports.flavorIntensityPqChart.pq')),
        width: '8%',
        selector: (row: TableRow) => row.pq,
        cell: (row: TableRow) => <span>{row.pq ? row.pq.toFixed(2) : ''}</span>,
        sortable: true,
      },
    ].concat(
      columnKeys.map((columnKey) => ({
        id: columnKey.key,
        width: '8%',
        name: lineBreak(columnKey.label),
        selector: (row: TableRow) => row[columnKey.key],
        cell: (row: TableRow) => (
          <Link
            key={row.productName + columnKey.key + '-link'}
            className={classes.dataCellLink}
            to={{
              pathname: formatPath(PROJECT_FLAVOR_SUMMARY_CHART, {
                projectId: projectId,
                reportId: reportId,
                productId: row.productId,
                version: row.version,
                ggVarKey: columnKey.key,
              }),
              state: { routeBackLocation: ProjectTabs.EXECUTIVE_SUMMARY },
            }}
          >
            {row[columnKey.key]
              ? bankersRound(row[columnKey.key] * 100, 0) + '%'
              : null}
          </Link>
        ),
        center: true,
        compact: true,
        sortable: true,
      })),
    );

  if (comparisonLoading || comparisonError) {
    return <LoadingScreen />;
  }

  if (reportSummaryLoading || reportSummaryError) {
    return <LoadingScreen />;
  }

  if (!comparisonData || !reportSummaryData) {
    return renderEmptyList();
  }

  return (
    <Box>
      <ComponentTitle>
        {t('flavorSummary.attributeIntensityTitle')}
      </ComponentTitle>
      <DataTable
        columns={columns(columnKeys)}
        data={mapToTableRow(productNames, groupedData)}
        defaultSortFieldId="pq"
        defaultSortAsc={false}
        actions={dataTableCsv(
          t('flavorSummary.attributeIntensityTitle') + '.csv',
          columns(columnKeys),
          mapToTableRow(productNames, groupedData).sort((a, b) => b.pq - a.pq),
        )}
        customStyles={tableCustomStyles(theme)}
        striped
        conditionalRowStyles={hightlightedConditionalRowStyles}
      />
    </Box>
  );
};

export default FlavorSummaryAttributeIntensity;
