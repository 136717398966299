import * as React from 'react';

import useGgEnvironment from 'hooks/useGgEnvironment';
import { useTranslation } from 'react-i18next';

import styles from './SuperadminToolsContainer.module.css';

const AdminToolsHeader: React.FC = (props) => {
  const { t } = useTranslation();

  const environment = useGgEnvironment();

  return (
    <h3 className={styles.header}>
      {t('navigation.adminTools')} - {environment}
    </h3>
  );
};

export default AdminToolsHeader;
