import * as React from 'react';

import * as d3 from 'd3';

import { useQuery } from '@apollo/client';
import FpDecompByReportIdAndProductIDQuery from '@graphql/queries/FpDecompByReportIdAndProductIDQuery';
import { Table, TableBody, TableRow, TableCell, Grid } from '@mui/material';
import { ProductVersion } from 'components/Report/ProductVersion';
import { getProductName } from 'components/Report/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary from 'hooks/useReportSummary';
import { chain, chunk } from 'lodash';

import { ComponentTitle } from '../../../../styles/themeComponents';
import { cleanUnderscore } from '../../../../utils/afsUtils';

interface Props {
  projectId: number;
  reportId: string;
}

interface ProductFlavor {
  referenceFlavor: string;
  flavorDecompSum: number;
}

const ProductFlavorSummary: React.FC<Props> = (props) => {
  const { projectId, reportId } = props;
  const colorScale = d3.schemeGnBu[9] as string[];

  const productNames = useCustomProductNames({ projectId, reportId });

  const { loading, error, data } = useQuery<reports.ReportFpDecompResponse>(
    FpDecompByReportIdAndProductIDQuery,
    {
      variables: { reportID: reportId },
    },
  );

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    productVersionPqs,
  } = useReportSummary(reportId, {
    fetchPolicy: 'no-cache',
  });

  const productFlavorDisplay = (flavor: ProductFlavor) =>
    `${(flavor.flavorDecompSum * 100).toFixed(0)}% ${cleanUnderscore(flavor.referenceFlavor)}`;

  const getFlavorSummaryData = (
    nodes: reports.ReportFpDecompRow[],
  ): {
    productVersion: ProductVersion;
    productByProductId: reports.ProductInfo;
    pq: number;
    flavors: ProductFlavor[];
  }[] =>
    chain(
      nodes.map((n) => ({
        ...n,
        productVersion: new ProductVersion(n.productByProductId.id, n.version),
      })),
    )
      .groupBy((n) => n.productVersion.value)
      .map((productGroup) => ({
        productVersion: productGroup[0].productVersion,
        productByProductId: productGroup[0].productByProductId,
        pq: productVersionPqs.filter((d) =>
          d.productVersion.equals(productGroup[0].productVersion),
        )[0]?.pq,
        flavors: chain(productGroup)
          .groupBy((n) => n.referenceFlavor)
          .map((productFlavorGroup) => ({
            referenceFlavor: productFlavorGroup[0].referenceFlavor,
            flavorDecompSum: productFlavorGroup
              .map((d) => +d.decomp)
              .reduce((a, b) => a + b),
          }))
          .sort((a, b) => b.flavorDecompSum - a.flavorDecompSum)
          .value(),
      }))
      .value();

  if (loading || error || reportSummaryLoading || reportSummaryError || !data) {
    return <div />;
  }

  const flavorColor = (flavor: ProductFlavor) =>
    colorScale[Math.ceil(Math.floor(flavor.flavorDecompSum * 100) / 2) + 1];

  return (
    <Grid container marginTop={10}>
      {getFlavorSummaryData(data.allRpFlavorDecomps.nodes).map(
        (productFlavorSummary) => (
          <>
            <Grid item xs={12}>
              <ComponentTitle>
                {getProductName({
                  productNames,
                  productId: productFlavorSummary.productVersion.productId,
                  version: productFlavorSummary.productVersion.version,
                })}
              </ComponentTitle>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={8} key={productFlavorSummary.productVersion.value}>
              <Table
                size="small"
                style={{ marginBottom: '2em', textAlign: 'center' }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Product</TableCell>
                    <TableCell align="center" colSpan={2}>
                      {getProductName({
                        productNames,
                        productId:
                          productFlavorSummary.productVersion.productId,
                        version: productFlavorSummary.productVersion.version,
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">PQ</TableCell>
                    <TableCell align="center" colSpan={2}>
                      {productFlavorSummary.pq}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="center"
                      rowSpan={Math.ceil(
                        productFlavorSummary.flavors.length / 2,
                      )}
                    >
                      Signatures
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: flavorColor(
                          productFlavorSummary.flavors[0],
                        ),
                      }}
                    >
                      {productFlavorDisplay(productFlavorSummary.flavors[0])}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: flavorColor(
                          productFlavorSummary.flavors[1],
                        ),
                      }}
                    >
                      {productFlavorDisplay(productFlavorSummary.flavors[1])}
                    </TableCell>
                  </TableRow>
                  {chunk(productFlavorSummary.flavors.slice(2), 2).map((c) => (
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: flavorColor(c[0]),
                        }}
                      >
                        {productFlavorDisplay(c[0])}
                      </TableCell>
                      {c[1] && (
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: flavorColor(c[1]),
                          }}
                        >
                          {productFlavorDisplay(c[1])}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={2} />
          </>
        ),
      )}
    </Grid>
  );
};

export default ProductFlavorSummary;
