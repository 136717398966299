import * as React from 'react';

import { Cancel as CancelIcon } from '@mui/icons-material';
import { IconButton, Select, MenuItem, TextField } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

import styles from './CustomLexiconAddMappingContainer.module.css';
import MaterialButton from '../../../components/MaterialButton';
import {
  capitalizeFirstLetter,
  splitCamelCase,
} from '../CustomLexiconLanguageFormatter';

interface Props {
  flavor: any;
  lexes: any;
  languages: any;
  mapLexicons: any;
  closeMappingModal: any;
  checkDuplicateMapping: any;
}

const CustomLexiconAddMappingContainer: React.FC<Props> = ({
  flavor,
  lexes,
  languages,
  mapLexicons,
  closeMappingModal,
  checkDuplicateMapping,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Formik
        initialValues={{ lexicons: [{ value: '', label: '', language: '' }] }}
        onSubmit={async (values) => {
          await mapLexicons(flavor, values.lexicons);
        }}
        render={({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form>
            <FieldArray
              validateOnChange={false}
              name="lexicons"
              render={({ push, remove }) => (
                <div className={styles.mainContainer}>
                  <div className={styles.buttonContainer}>
                    <MaterialButton
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        push({ value: '', label: '', language: '' })
                      }
                    >
                      +
                    </MaterialButton>
                  </div>
                  <div>
                    <div className={styles.subContainer}>
                      <div className={styles.fieldContainer}>
                        {t('customLexicon.selectLexicon')}
                      </div>
                      <div className={styles.fieldContainer}>
                        {t('customLexicon.selectLanguage')}
                      </div>
                      <div className={styles.fieldContainer}>
                        {t('customLexicon.value')}
                      </div>
                      <div>{t('customLexicon.action')}</div>
                    </div>
                    {values.lexicons.map((lexic, index) => {
                      return (
                        <div className={styles.subContainer} key={index}>
                          <div className={styles.fieldContainer}>
                            <Select
                              variant="standard"
                              name={`lexicons[${index}].value`}
                              value={lexic.value}
                              placeholder="Choose Lexicon"
                              fullWidth
                              style={{
                                fontSize: '11px',
                              }}
                              onChange={(e) => {
                                handleChange(e);
                                const res = checkDuplicateMapping(
                                  flavor,
                                  e.target.value,
                                  lexic.language,
                                );
                                lexic.label = res;
                              }}
                            >
                              {lexes &&
                                lexes.map((item) => (
                                  <MenuItem
                                    style={{
                                      fontSize: '11px',
                                    }}
                                    key={item}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                ))}
                            </Select>
                          </div>
                          <div className={styles.fieldContainer}>
                            <Select
                              variant="standard"
                              name={`lexicons[${index}].language`}
                              value={lexic.language}
                              placeholder="Choose language"
                              fullWidth
                              style={{
                                fontSize: '11px',
                              }}
                              onChange={(e) => {
                                handleChange(e);
                                const res = checkDuplicateMapping(
                                  flavor,
                                  lexic.value,
                                  e.target.value,
                                );
                                lexic.label = res;
                              }}
                            >
                              {languages &&
                                languages.map((item) => (
                                  <MenuItem
                                    style={{
                                      fontSize: '11px',
                                    }}
                                    key={item.name}
                                    value={item.code}
                                  >
                                    {capitalizeFirstLetter(
                                      splitCamelCase(item.name),
                                    )}
                                  </MenuItem>
                                ))}
                            </Select>
                          </div>
                          <div className={styles.fieldContainer}>
                            <TextField
                              variant="standard"
                              fullWidth
                              name={`lexicons[${index}].label`}
                              value={lexic.label}
                              onChange={handleChange}
                              inputProps={{ style: { fontSize: 11 } }}
                              InputLabelProps={{ style: { fontSize: 11 } }}
                            />
                          </div>
                          <div>
                            <IconButton
                              arial-label="Cancel"
                              onClick={() => {
                                remove(index);
                              }}
                              href=""
                              size="large"
                            >
                              <CancelIcon />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            />
            <div className={styles.footer}>
              <MaterialButton
                variant="outlined"
                soft
                onClick={closeMappingModal}
              >
                {'Cancel'}
              </MaterialButton>
              <MaterialButton
                variant="outlined"
                soft
                teal
                onClick={handleSubmit}
              >
                {'Save'}
              </MaterialButton>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default CustomLexiconAddMappingContainer;
