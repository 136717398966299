import * as React from 'react';

import { CircularProgress, LinearProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    marginLeft: '2em',
    marginRight: '2em',
    padding: '4.2rem 3.2rem',
  },
});

interface Props {
  circular?: boolean;
}

const LoadingScreen: React.FC<Props> = ({ circular = false }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {circular ? (
        <div
          style={{
            // do your styles depending on your needs.
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <LinearProgress color="secondary" />
      )}
    </Paper>
  );
};

export default LoadingScreen;
