import { OPTIMIZATION } from 'constants/report';

import { isValid } from 'date-fns';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

export const formatReportLabel = (
  projectName: string,
  targetGroup: string,
  reportType: string,
  startedAt: string,
) => {
  const labelArray = new Array<string>();

  if (projectName) labelArray.push(projectName);
  if (targetGroup) labelArray.push(targetGroup);

  if (reportType === OPTIMIZATION) labelArray.push('Optimization');
  if (!!startedAt && isValid(parseISO(startedAt)))
    labelArray.push(format(parseISO(startedAt), ' yyyy-MM-dd'));

  return labelArray.join(' ');
};
