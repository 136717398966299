import * as React from 'react';

import CSVDownload from 'components/CSVDownload/CSVDownload';

interface Props {
  data: reports.ReportFpDecompRow[];
}

const FlavorDecompositionTableCsv: React.FC<Props> = ({ data }) => {
  const getCsvHeader = () => [
    { label: 'Product', key: 'product' },
    { label: 'GG Var', key: 'ggVar' },
    { label: 'Reference Flavor', key: 'referenceFlavor' },
    { label: 'Decomp', key: 'decomp' },
    { label: 'Abs Decomp', key: 'absDecomp' },
  ];

  const getCsvRow = () => {
    if (data.length < 1) return [];
    return data.map((row) => ({
      product: row.productByProductId.name,
      ggVar: row.ggVars,
      referenceFlavor: row.referenceFlavor,
      decomp: parseFloat(row.decomp).toFixed(2),
      absDecomp: parseFloat(row.absDecomp).toFixed(2),
    }));
  };

  const getCsvFileName = () => {
    return (
      data
        .map((d) => d.productByProductId)
        .map((p) => p.name + '-')
        .find(Boolean) + 'FlavorDecomposition.csv'
    );
  };

  if (data.length < 1) return <></>;

  return (
    <CSVDownload
      headers={getCsvHeader()}
      csvData={getCsvRow()}
      fileName={getCsvFileName()}
    />
  );
};

export default FlavorDecompositionTableCsv;
