import { gql } from '@apollo/client';

export default gql`
  query MarketMapLdas($reportID: UUID!) {
    allRpMarketmapLdas(condition: { reportId: $reportID }) {
      nodes {
        rowid
        pq
        ld1
        ld2
      }
    }
  }
`;
