import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import MultiProductSearchByIdQuery from '@graphql/queries/MultiProductSearchByIdQuery';
import { Modal } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import useMiniGlp, { MiniGlpProduct } from 'hooks/useMiniGlp';
import { get } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import MaterialButton from '../../../components/MaterialButton';
import {
  MultiProductSearchQueryType,
  ProductSearchQueryProduct,
} from '../../../graphql/queries/MultiProductSearchQuery';
import styles from '../RequestReport.module.css';

export const findMatchingProducts = (
  miniglp: MiniGlpProduct[],
  productSearchNames: string[],
) => {
  // Filter miniGLP by desired product names
  const foundProducts = miniglp.filter((d) =>
    productSearchNames.includes(d.product),
  );

  // Simplified set of found names
  const foundNames = foundProducts.map((d) => d.product);

  // Unfound names
  const invalidNames = productSearchNames.filter(
    (d) => !foundNames.includes(d),
  );

  return { foundNames, foundProducts, invalidNames };
};

export const findLowDataProducts = (
  miniglp: MiniGlpProduct[],
  foundNames: string[],
) => {
  const prodMaxReviews = {};
  miniglp.forEach((d) => {
    const numreviews = d.reviews;
    if (!prodMaxReviews[d.product]) {
      prodMaxReviews[d.product] = numreviews;
    } else if (numreviews > prodMaxReviews[d.product]) {
      prodMaxReviews[d.product] = numreviews;
    }
  });
  const lowdataProducts = foundNames.filter((d) => prodMaxReviews[d] < 5);
  return lowdataProducts;
};

export const findNoCategoryProducts = (
  miniglp: MiniGlpProduct[],
  foundNames: string[],
) => {
  return foundNames.filter(
    (d) =>
      miniglp.filter((p) => p.product == d && !!p.category_name).length == 0,
  );
};

export const repairProductName = (postgresName: string) => {
  return postgresName
    .trim()
    .replace(/\s\s+/g, ' ')
    .replace(/\u3000/g, ' ')
    .split(' ')
    .map((txt) => {
      if (/^[A-Z]+$/.test(txt)) return txt;
      else return txt.charAt(0).toUpperCase() + txt.substring(1);
    })
    .join(' ');
};

export const formatProducts = (
  products: ProductSearchQueryProduct[],
): FormattedProduct[] => {
  return products.map((product) => ({
    // duplicates getProductData function
    id: product.id,
    image:
      get(product, 'productImages.totalCount') > 0 &&
      product.productImages.nodes[0].url,
    name: product.name,
    brand: product.brand,
    date: moment(product.createdAt).format('ll'),
    reviews: get(product, 'productReviews.totalCount'),
    lastReview:
      get(product, 'productReviews.nodes.length') > 0
        ? moment(product.productReviews.nodes[0].createdAt).format('ll')
        : '',
    category: get(product, 'category'),
    workspace: product.producer.name,
    productWorkspaceId: product.producer.id,
    productWorkspaceName: product.producer.name,
  }));
};

export interface Props {
  open: boolean;
  handleClose: () => any;
  returnProducts: (products: FormattedProduct[], name?: string) => any;
  nameSet?: boolean;
  cloned_custom_class_db_rich?: Partial<FormattedProduct>[];
}

const ProductPasteModal: React.FC<Props> = ({
  open,
  handleClose,
  returnProducts,
  nameSet,
  cloned_custom_class_db_rich,
}) => {
  const { t } = useTranslation();

  const { data: miniglp, loading, error } = useMiniGlp();

  const [pastedNames, setPastedNames] = useState<string>('');
  const [productSearchNames, setProductSearchNames] = useState<string[]>(null);
  const [productValidationResult, setValidationResult] = useState<string>(null);
  const [productsToAdd, setProductsToAdd] = useState<FormattedProduct[]>(null);
  const [productSetName, setProductSetName] = useState<string>('');

  useEffect(() => {
    if (!productSearchNames) {
      return;
    }

    // Find matching products
    const { foundNames, foundProducts, invalidNames } = findMatchingProducts(
      miniglp,
      productSearchNames,
    );

    if (invalidNames.length > 0) {
      setValidationResult(
        ['Invalid products:'].concat(invalidNames).join('\n'),
      );
      return;
    }

    // Flag low data products
    const lowdataProducts = findLowDataProducts(miniglp, foundNames);

    if (lowdataProducts.length > 0) {
      setValidationResult(
        ['Products have insufficient data:'].concat(lowdataProducts).join('\n'),
      );
      return;
    }

    // Flag no category products
    const nocatProducts = findNoCategoryProducts(miniglp, foundNames);

    if (nocatProducts.length > 0) {
      setValidationResult(
        ['Products have no category:']
          .concat(Array.from(new Set(nocatProducts)))
          .join('\n'),
      );
      return;
    }

    // Manage when all valid
    setValidationResult('All products are valid ✅');
    searchProductNames({
      variables: { ids: foundProducts.map((d) => d.product_id) },
    });
  }, [productSearchNames]);

  useEffect(() => {
    if (cloned_custom_class_db_rich?.length > 0) {
      updateProductNames(
        cloned_custom_class_db_rich
          .reduce((acc, cur) => acc.concat(cur.name, '\n'), '')
          .trimEnd(),
      );
    }
  }, []);

  const [searchProductNames] = useLazyQuery<MultiProductSearchQueryType>(
    MultiProductSearchByIdQuery,
    {
      onCompleted: (data: MultiProductSearchQueryType) => {
        const allworkspaceProducts = formatProducts(data.allProducts.nodes);
        setProductsToAdd(allworkspaceProducts);
      },
    },
  );

  const updateProductNames = (input: string): void => {
    setPastedNames(input);
    setProductSearchNames(input.split('\n'));
  };

  return (
    <Modal open={open} className={styles.folderModal}>
      {loading || error ? (
        <LoadingScreen circular />
      ) : (
        <div className={styles.modalContainer}>
          {nameSet && (
            <input
              placeholder="Name for this product set"
              onChange={(event) => setProductSetName(event.target.value)}
            ></input>
          )}
          <textarea
            rows={8}
            placeholder="Paste one product per line"
            value={pastedNames}
            onChange={(e) => {
              updateProductNames(e.target.value);
            }}
          />
          <textarea
            rows={8}
            placeholder={
              productValidationResult ?? 'Enter products above to validate'
            }
            readOnly
          />
          <div className={styles.buttonContainer}>
            <MaterialButton
              variant="outlined"
              soft
              onClick={() =>
                updateProductNames(
                  pastedNames
                    .split('\n')
                    .map((d) => repairProductName(d))
                    .join('\n'),
                )
              }
            >
              Repair Names
            </MaterialButton>
            <MaterialButton
              variant="outlined"
              soft
              onClick={() => handleClose()}
            >
              {t('general.cancel')}
            </MaterialButton>
            <MaterialButton
              variant="outlined"
              soft
              teal
              disabled={!productsToAdd}
              onClick={() => returnProducts(productsToAdd, productSetName)}
            >
              Add Products
            </MaterialButton>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ProductPasteModal;
