import * as React from 'react';

import CSVDownload from 'components/CSVDownload';
import { KeyedReportDetailRow } from 'hooks/useReportSummary';

import { sortByPq } from '../utils';

interface ReportProps {
  reportName: string;
  reportSummaryData: KeyedReportDetailRow[];
}

const ReportSummaryCsv: React.FC<ReportProps> = (props) => {
  const { reportName, reportSummaryData } = props;

  const getCsvHeader = () => [
    { label: 'Product', key: 'product' },
    { label: 'PQ', key: 'pq' },
    { label: 'Win Rate', key: 'winRate' },
    { label: 'Polarization', key: 'polarization' },
  ];

  const getCsvData = (data: KeyedReportDetailRow[]) =>
    data
      .sort((a, b) => sortByPq(a, b))
      .map((row) => ({
        product: row.productByProductId.name,
        pq: parseFloat(row.pq).toFixed(2),
        winRate: `${parseFloat(row.pComp).toFixed(0)}%`,
        polarization: parseFloat(row.polarization).toFixed(2),
      }));

  return (
    <CSVDownload
      headers={getCsvHeader()}
      csvData={getCsvData(reportSummaryData)}
      fileName={`${reportName}-StackRank.csv`}
    />
  );
};

export default ReportSummaryCsv;
