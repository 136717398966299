import * as React from 'react';
import { useState } from 'react';

import Assignment from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import { IconButton, Divider, Grid, Dialog, Box } from '@mui/material';
import {
  ProductVersion,
  ProductVersionSet,
} from 'components/Report/ProductVersion';
import ReportDataCollectedTable from 'components/Report/ReportDataCollected/ReportDataCollectedTable';
import ApprovalModal from 'containers/ReportQAList/ApprovalModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LoadingScreen from '../../../components/LoadingScreen';
import MaterialButton from '../../../components/MaterialButton';
import { MapOptions } from '../../../components/Report/Maps/MapOptions';
import ReportMarketMap from '../../../components/Report/Maps/ReportMarketMap';
import ReportSummary from '../../../components/Report/ReportSummary';
import { PROJECT_COMPARISON_WITH_PARAMS } from '../../../constants/routePaths';
import selectViewerUserId from '../../../selectors/viewerUserId';
import selectWorkspaceProducerId from '../../../selectors/workspaceProducerId';
import formatPath from '../../../utils/formatPath';
import styles from '../ProjectSummary.module.css';

interface Props {
  projectId: number;
  projectReports: reports.ProjectReportSummary[];
  selectedReportId: string;
  parentReportId: string;
  excludedProducts: ProductVersionSet;
  setExcludedProducts: React.Dispatch<React.SetStateAction<ProductVersionSet>>;
  flavorMapOptions: MapOptions;
  setFlavorMapOptions: (newMapOptions: MapOptions) => void;
  displayParentReport: boolean;
  setDisplayParentReport: (b: boolean) => void;
  mapOnly?: boolean;
}

const ProjectReportSummary: React.FC<Props> = (props) => {
  const {
    projectReports,
    projectId,
    selectedReportId,
    parentReportId,
    excludedProducts,
    setExcludedProducts,
    flavorMapOptions,
    setFlavorMapOptions,
    displayParentReport,
    setDisplayParentReport,
    mapOnly,
  } = props;

  const { t } = useTranslation();

  const producerId = useSelector((state) => selectWorkspaceProducerId(state));
  const viewerUserId = useSelector((state) => selectViewerUserId(state));

  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [firstProduct, setFirstProduct] = useState<ProductVersion>(null);
  const [secondProduct, setSecondProduct] = useState<ProductVersion>(null);
  const [pageUpdated, setPageUpdated] = useState<boolean>(false);
  const [modalReportId, setModalReportId] = useState<string>(null);

  const togglePageUpdate = () => setPageUpdated(!pageUpdated);

  const updateComparisonSelection = (
    firstProduct: ProductVersion,
    secondProduct: ProductVersion,
  ): void => {
    setFirstProduct(firstProduct);
    setSecondProduct(secondProduct);
  };

  const updateExcludedProducts = (excludedSet: ProductVersionSet) =>
    setExcludedProducts(excludedSet);

  const compareDisabled = (
    firstProduct: ProductVersion,
    secondProduct: ProductVersion,
  ): boolean => {
    return firstProduct == null && secondProduct == null;
  };

  if (!projectReports || !projectReports.length) {
    return <LoadingScreen />;
  }

  return (
    <div>
      {!!modalReportId && (
        <ApprovalModal
          reportId={modalReportId}
          displayOnly={true}
          cancelButtonText={null}
          cancelFunction={() => {
            setModalReportId(null);
          }}
          confirmButtonText={null}
          confirmFunction={() => {
            setModalReportId(null);
          }}
        />
      )}
      <div className={styles.tabContainer}>
        <Grid container>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6} sx={{ minWidth: 430 }}>
              {selectedReportId && (
                <div>
                  <h5>
                    {t('projects.projectSummary.reportInformation')}
                    <IconButton
                      onClick={() => setShowSummaryModal(true)}
                      size="large"
                    >
                      <Assignment color="primary" />
                    </IconButton>
                    <IconButton
                      onClick={() => setModalReportId(selectedReportId)}
                      size="large"
                    >
                      <PeopleIcon color="primary" />
                    </IconButton>
                  </h5>
                  <Dialog
                    open={showSummaryModal}
                    onClose={() => setShowSummaryModal(false)}
                    PaperProps={{
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 10,
                      },
                    }}
                  >
                    <ReportDataCollectedTable
                      reportId={selectedReportId}
                      isModal={true}
                    />
                  </Dialog>
                </div>
              )}
              {selectedReportId && (
                <Box sx={{ paddingBottom: '2rem' }}>
                  <Divider />
                </Box>
              )}
              {selectedReportId && (
                <div>
                  <ReportSummary
                    projectId={projectId}
                    reportId={selectedReportId}
                    parentReportId={parentReportId}
                    displayParentReport={displayParentReport}
                    setDisplayParentReport={setDisplayParentReport}
                    enableComparison={updateComparisonSelection}
                    excludedProducts={excludedProducts}
                    setExcludedProducts={setExcludedProducts}
                    updateExcludedProducts={updateExcludedProducts}
                    pageUpdated={pageUpdated}
                    togglePageUpdate={togglePageUpdate}
                    selectedReportName={
                      projectReports.find(
                        (rep) => rep.reportId === selectedReportId,
                      ).name
                    }
                    mapOnly={mapOnly}
                  />
                  {!compareDisabled(firstProduct, secondProduct) &&
                    !mapOnly && (
                      <div className={styles.compareButton}>
                        <Link
                          to={{
                            pathname: formatPath(
                              PROJECT_COMPARISON_WITH_PARAMS,
                              {
                                projectId: projectId,
                                firstReportId:
                                  firstProduct.reportId ?? selectedReportId,
                                firstProductId: firstProduct.productId,
                                firstProductVersion: firstProduct.version,
                                secondReportId: secondProduct.reportId,
                                secondProductId: secondProduct.productId,
                                secondProductVersion: secondProduct.version,
                              },
                            ),
                          }}
                          style={{
                            pointerEvents: compareDisabled(
                              firstProduct,
                              secondProduct,
                            )
                              ? 'none'
                              : 'auto',
                          }}
                        >
                          <MaterialButton
                            soft
                            teal
                            disabled={compareDisabled(
                              firstProduct,
                              secondProduct,
                            )}
                          >
                            Compare Products
                          </MaterialButton>
                        </Link>
                      </div>
                    )}
                </div>
              )}
            </Grid>
            <Grid item xs={6} sx={{ minWdith: 430 }}>
              {selectedReportId && (
                <ReportMarketMap
                  projectId={projectId}
                  reportId={selectedReportId}
                  parentReportId={displayParentReport ? parentReportId : null}
                  workspaceId={producerId}
                  userId={viewerUserId}
                  showTitle={false}
                  showLegend={false}
                  excludedProducts={excludedProducts}
                  pageUpdated={pageUpdated}
                  flavorMapOptions={flavorMapOptions}
                  setFlavorMapOptions={setFlavorMapOptions}
                  setExcludedProducts={setExcludedProducts}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProjectReportSummary;
