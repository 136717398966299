import { matchPath } from 'react-router';

import selectRouterLocation from './routerLocation';
import { PRODUCER_ROOT } from '../constants/routePaths';

export default function routerLocationProducerSlug(state) {
  const routerLocation = selectRouterLocation(state);
  const routerLocationPathname = routerLocation && routerLocation.pathname;
  const routerLocationProducerRootMatch = matchPath(routerLocationPathname, {
    path: PRODUCER_ROOT,
  });

  return (
    routerLocationProducerRootMatch &&
    routerLocationProducerRootMatch.params &&
    routerLocationProducerRootMatch.params.producerSlug
  );
}
