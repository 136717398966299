import { mapLabel } from 'constants/ggVars';

import * as d3 from 'd3';
import { SimulationNodeDatum } from 'd3';

import {
  CustomerPreferences,
  getLabelColor,
  getLabelSize,
  getLabelStroke,
} from 'services/customerPreferences';
import { Optional } from 'typescript-optional';

import boundedBox from './boundaryForce';
import ellipseCollide from './ellipseCollision';
import {
  computeRMax,
  rZoomBehavior,
  rotateld1,
  rotateld2,
  vectorLen,
} from './layerUtils';
import { vectorTooltip } from '../../mapUtils';

/** Turn on debug circles around labels */
const DEBUG_LABELS = false;

export enum LabelType {
  CENTER = 'center',
  REF_FLAVOR = 'rf',
  GGVAR = 'ggvar',
}

interface LabelDatum extends SimulationNodeDatum {
  angle: number;
  label: string;
  type: LabelType;
  length: number;
}

/**
 * Generate the label's id string
 * @param d label datum object
 * @returns label id string
 */
const labelId = (d: LabelDatum): string => {
  return d.label
    .toLowerCase()
    .replace(/[^a-z0-9_\s-]/g, '')
    .replace(/[\s-]+/, ' ')
    .replace(/[\s_]/, '-');
};

/**
 * Generate the default distance the label should place at radially
 * @param lt label type (ggvar or refflavor)
 * @param r market map radius
 * @returns radial distance of label
 */
const labelDist = (
  lt: LabelType,
  r: number,
  zoom: number,
  customerPreferences: CustomerPreferences,
) => {
  switch (lt) {
    case LabelType.CENTER:
      return 0;
    case LabelType.REF_FLAVOR:
      return Math.floor(1.2 * rZoomBehavior(r, zoom, customerPreferences));
    case LabelType.GGVAR:
      return Math.floor(1.4 * rZoomBehavior(r, zoom, customerPreferences));
  }
};

/**
 * Generate the label's rough radius from text and size
 * @param customerPreferences customer preferences object
 * @param d label datum object
 * @param r market map radius
 * @returns label radius
 */
const labelRadius = (
  customerPreferences: CustomerPreferences,
  d: LabelDatum,
  r: number,
) => {
  switch (d.type) {
    case LabelType.CENTER:
      return r;
    case LabelType.REF_FLAVOR:
      return 0.3 * getLabelSize(customerPreferences, d.type) * d.label.length;
    case LabelType.GGVAR:
      return 0.3 * getLabelSize(customerPreferences, d.type) * d.label.length;
  }
};

const ellipseSize = (
  customerPreferences: CustomerPreferences,
  d: LabelDatum,
  r: number,
) => {
  switch (d.type) {
    case LabelType.CENTER:
      return [r, r];
    case LabelType.REF_FLAVOR:
      return [
        labelRadius(customerPreferences, d, r),
        getLabelSize(customerPreferences, d.type),
      ];
    case LabelType.GGVAR:
      return [
        labelRadius(customerPreferences, d, r),
        getLabelSize(customerPreferences, d.type),
      ];
  }
};

const translate = (input: number, translationAmount: number) =>
  input - translationAmount;

const angularToX = (
  d: LabelDatum,
  r: number,
  zoom: number,
  customerPreferences: CustomerPreferences,
): number => rZoomBehavior(r, zoom, customerPreferences) * Math.cos(-d.angle);
const angularToY = (
  d: LabelDatum,
  r: number,
  zoom: number,
  customerPreferences: CustomerPreferences,
): number => rZoomBehavior(r, zoom, customerPreferences) * Math.sin(-d.angle);

const forceX = (
  d: LabelDatum,
  r: number,
  zoom: number,
  tx: number,
  customerPreferences: CustomerPreferences,
) => {
  switch (d.type) {
    case LabelType.CENTER:
      return translate(0, tx);
    default:
      return translate(1.2 * angularToX(d, r, zoom, customerPreferences), tx);
  }
};

const forceY = (
  d: LabelDatum,
  r: number,
  zoom: number,
  ty: number,
  customerPreferences: CustomerPreferences,
) => {
  switch (d.type) {
    case LabelType.CENTER:
      return translate(0, ty);
    default:
      return translate(1.1 * angularToY(d, r, zoom, customerPreferences), ty);
  }
};

const getBBoxx = (d: LabelDatum): DOMRect => {
  return Optional.of(
    d3.select(`#${labelId(d)}`) as d3.Selection<
      SVGSVGElement,
      object,
      HTMLElement,
      any
    >,
  )
    .map((e) => e.node())
    .map((n: SVGSVGElement) => n.getBBox())
    .orElse({
      x: null,
      y: null,
      width: null,
      height: null,
      bottom: null,
      left: null,
      right: null,
      top: null,
      toJSON: null,
    });
};

// Reference Flavors Layer
// This renders the locations of the reference flavor and ggvar labels around the circle with
// blue dots and labels connected for reference flavor labels
export function labelsLayer(
  container: d3.Selection<SVGElement, any, null, undefined>,
  r: number,
  zoom: number,
  rotationAngle: number,
  tx: number,
  ty: number,
  width: number,
  height: number,
  isProd: boolean,
  refFlavorArray: marketmap.LabelData[],
  ggVarsArray: marketmap.LabelData[],
  labelFont: string,
  customerPreferences: CustomerPreferences,
) {
  const scaledR = r / 240;

  const label_group = container.append('g');

  const maxGGVar = computeRMax(ggVarsArray);

  const transX = (inputX: number) => translate(inputX, tx);
  const transY = (inputY: number) => translate(inputY, ty);

  const labels: LabelDatum[] = [
    {
      angle: 0,
      label: null,
      type: LabelType.CENTER,
      x: 0,
      y: 0,
      fx: 0,
      fy: 0,
      length: 0,
    },
  ]
    .concat(
      refFlavorArray.map((d) => ({
        ...d,
        angle: d.angle + rotationAngle,
        label: d.label.split('_').join(' '),
        type: LabelType.REF_FLAVOR,
        x: rotateld1(d.ld1, d.ld2, rotationAngle),
        y: rotateld2(d.ld2, d.ld2, rotationAngle),
        fx: null,
        fy: null,
        length: 1,
      })),
    )
    .concat(
      ggVarsArray.map((d) => ({
        angle: d.angle + rotationAngle,
        label: mapLabel(d.label),
        type: LabelType.GGVAR,
        x: rotateld1(d.ld1, d.ld2, rotationAngle),
        y: rotateld2(d.ld2, d.ld2, rotationAngle),
        fx: null,
        fy: null,
        length: vectorLen(d) / maxGGVar,
      })),
    )
    .filter(
      (d) =>
        d.type === LabelType.CENTER ||
        (Math.abs(transX(angularToX(d, r, zoom, customerPreferences))) <
          (width / 2) * 0.8 &&
          Math.abs(transY(angularToY(d, r, zoom, customerPreferences))) <
            (height / 2) * 0.8),
    );

  label_group
    .selectAll('circle')
    .data(labels.filter((d: LabelDatum) => d.type === LabelType.REF_FLAVOR))
    .enter()
    .append('circle')
    .attr('cx', (d) => transX(angularToX(d, r, zoom, customerPreferences)))
    .attr('cy', (d) => transY(angularToY(d, r, zoom, customerPreferences)))
    .attr('r', scaledR * 6)
    .style('fill', 'steelblue');

  label_group
    .selectAll('.labelLine')
    .data(labels.filter((d: LabelDatum) => !(d.type === LabelType.CENTER)))
    .enter()
    .append('line')
    .classed('labelLine', true)
    .attr('x1', (d) => transX(angularToX(d, r, zoom, customerPreferences)))
    .attr('y1', (d) => transY(angularToY(d, r, zoom, customerPreferences)))
    .style('stroke', 'gray')
    .style('stroke-width', scaledR * 1);

  label_group
    .append('g')
    .selectAll('.backRect')
    .data(labels.filter((d: LabelDatum) => !(d.type === LabelType.CENTER)))
    .enter()
    .append('rect')
    .classed('backRect', true)
    .attr(
      'x',
      (d) =>
        forceX(d, r, zoom, tx, customerPreferences) -
        ellipseSize(customerPreferences, d, r)[0] / 2,
    )
    .attr(
      'y',
      (d) =>
        forceY(d, r, zoom, ty, customerPreferences) -
        ellipseSize(customerPreferences, d, r)[1],
    )
    .attr('width', (d) => ellipseSize(customerPreferences, d, r)[0])
    .attr('height', (d) => ellipseSize(customerPreferences, d, r)[1])
    .attr('stroke', (d) => getLabelStroke(customerPreferences, d.type))
    .style('fill', 'white');

  if (!isProd && DEBUG_LABELS) {
    label_group
      .append('g')
      .selectAll('.debugEllipse')
      .data(labels) //).filter((d: LabelDatum) => !(d.type === LabelType.CENTER)))
      .enter()
      .append('ellipse')
      .classed('debugEllipse', true)
      .attr('rx', (d) => ellipseSize(customerPreferences, d, r)[0])
      .attr('ry', (d) => ellipseSize(customerPreferences, d, r)[1])
      .attr('cx', (d) => forceX(d, r, zoom, tx, customerPreferences))
      .attr('cy', (d) => forceY(d, r, zoom, ty, customerPreferences))
      .style('fill', 'rgba(255, 128, 0, 0.5)');
  }

  const tip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('opacity', 0);

  label_group
    .selectAll('.label')
    .data(labels.filter((d: LabelDatum) => !(d.type === LabelType.CENTER)))
    .enter()
    .append('text')
    .classed('label', true)
    .text((d) => d.label)
    .attr('id', (d) => labelId(d))
    .attr('text-anchor', 'middle')
    .style('font-family', labelFont)
    .style(
      'font-size',
      (d) => `${scaledR * getLabelSize(customerPreferences, d.type)}px`,
    )
    .style('fill', (d) => getLabelColor(customerPreferences, d.type))
    .on('mouseleave', (d) => tip.style('opacity', 0))
    .on(
      'mouseenter',
      (d) =>
        d.currentTarget.__data__.type === LabelType.GGVAR &&
        tip
          .style('opacity', 1)
          .html(
            vectorTooltip(
              customerPreferences,
              d.currentTarget.__data__.label,
              d.currentTarget.__data__.length,
              zoom,
            ),
          )
          .style('left', d.pageX - 25 + 'px')
          .style('left', d.pageX - 25 + 'px')
          .style('top', d.pageY - 75 + 'px'),
    );

  function dragStarted(this: Element, e: any, d: LabelDatum): void {
    if (!e.active) simulation.alphaTarget(0.1).restart();
    d3.select(this).raise().classed('active', true);
  }
  function dragged(this: Element, e: any, d: LabelDatum): void {
    d3.select(this)
      .attr('dx', (d.x = e.x))
      .attr('dy', (d.y = e.y));
  }
  function dragEnded(this: Element, e: any, d: LabelDatum): void {
    if (!e.active) simulation.alphaTarget(0);
    d3.select(this).classed('active', false);
  }

  label_group
    .selectAll('text')
    .call(
      d3
        .drag()
        .on('start', dragStarted)
        .on('drag', dragged)
        .on('end', dragEnded),
    );

  const simulation = d3
    .forceSimulation<LabelDatum>(labels)
    .force(
      'x',
      d3
        .forceX((d: LabelDatum) => forceX(d, r, zoom, tx, customerPreferences))
        .strength(0.3),
    )
    .force(
      'y',
      d3
        .forceY((d: LabelDatum) => forceY(d, r, zoom, ty, customerPreferences))
        .strength(0.3),
    )
    //.force('manybody', d3.forceManyBody<LabelDatum>().strength(1))
    .force(
      'collide',
      ellipseCollide().radius((d: LabelDatum) =>
        ellipseSize(customerPreferences, d, r),
      ),
    )
    .force(
      'boundary',
      boundedBox()
        .bounds(getBoundaryBounds(height, width, tx, ty))
        .size((d: LabelDatum) => ellipseSize(customerPreferences, d, r)),
    )
    // .force(
    //   'radial',
    //   d3
    //     .forceRadial<LabelDatum>((d: LabelDatum) => labelDist(d.type, r, zoom), transX(0), transY(0))
    //     .strength(0.3),
    // )
    .tick(10);
  simulation.on('tick', () => {
    label_group
      .selectAll('.label')
      .attr('dx', (d: LabelDatum) => d.x)
      .attr('dy', (d: LabelDatum) => d.y);
    label_group
      .selectAll('.labelLine')
      .attr('x2', (d: LabelDatum) => 0.95 * d.x)
      .attr('y2', (d: LabelDatum) => 0.95 * d.y);
    label_group
      .selectAll('.backRect')
      .attr('x', (d: LabelDatum) => getBBoxx(d)?.x - 2)
      .attr('y', (d: LabelDatum) => getBBoxx(d)?.y - 2)
      .attr('width', (d: LabelDatum) => getBBoxx(d)?.width + 4)
      .attr('height', (d: LabelDatum) => getBBoxx(d)?.height + 4);
    label_group
      .selectAll('.debugEllipse')
      .attr('cx', (d: LabelDatum) =>
        d.type === LabelType.CENTER ? transX(d.x) : d.x,
      )
      .attr('cy', (d: LabelDatum) =>
        d.type === LabelType.CENTER ? transY(d.y) : d.y,
      );
  });
}
function getBoundaryBounds(
  height: number,
  width: number,
  tx: number,
  ty: number,
): any {
  const limit = (tx: number, width: number) => {
    if (tx < -width / 5) return -width / 5;
    else if (tx > width / 5) return width / 5;
    else tx;
  };

  const bounds = [
    [
      0.95 * (-width / 2) + limit(tx, width),
      0.9 * (-height / 2) + limit(ty, height),
    ],
    [
      0.9 * (width / 2) + limit(tx, width),
      0.9 * (height / 2) + limit(ty, height),
    ],
  ];

  return bounds;
}
