import { gql } from '@apollo/client';

export default gql`
  query ArchetypeDemographics($reportId: UUID) {
    allRpArchetypesDemographics(condition: { reportId: $reportId }) {
      nodes {
        id
        reportId
        groupIdx
        groupFraction
        ageRangeStart
        ageRangeEnd
        ageFraction
        maleFraction
        femaleFraction
        reportJobByReportId {
          targetGroupName
          params
        }
      }
    }
  }
`;
