import { ERROR_CODE } from 'constants/errorCode';

import * as React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import UserInvalidModal from './UserInvalidModal';
import FieldTextInput from '../../../components/FieldTextInput';
import MaterialButton from '../../../components/MaterialButton';
import styles from '../UserList.module.css';

interface Props {
  pristine: boolean;
  invalid: boolean;
  error: {
    email: string;
    code: ERROR_CODE;
  };
  submitting: boolean;
  handleSubmit: any;
  addUser: boolean;
  toggleAddUserDialog: () => void;
  producerName: string;
  changeEmail: (email: string) => void;
  reset: () => void;
}

const AddUserDialog: React.FC<Props> = (props) => {
  const { error, submitting, handleSubmit, reset } = props;
  const { addUser, toggleAddUserDialog, producerName, changeEmail } = props;

  const { t } = useTranslation();

  return (
    <div>
      <Dialog open={addUser} onClose={toggleAddUserDialog}>
        <DialogTitle>{t('users.addUser')}</DialogTitle>
        <DialogContent className={styles.addUserForm}>
          {submitting && <strong>Adding user...</strong>}
          <Field
            component={FieldTextInput}
            style={{ width: '65%' }}
            key="user-list-email"
            label={t('users.email')}
            name="email"
            placeholder="you@example.com"
          />

          {error && (
            <div>
              <UserInvalidModal
                email={error.email}
                changeEmail={changeEmail}
                workspace={producerName}
                toggleDialog={toggleAddUserDialog}
                errorCode={error.code}
                resetAddUserForm={reset}
              />
            </div>
          )}
        </DialogContent>
        {!error && (
          <DialogActions>
            <MaterialButton
              color="secondary"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              {t('users.addUser')}
            </MaterialButton>
            <MaterialButton color="primary" onClick={toggleAddUserDialog}>
              {t('general.cancel')}
            </MaterialButton>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default AddUserDialog;
