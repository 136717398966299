import { folderSvg } from 'constants/images';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import { IconButton, Grid } from '@mui/material';
import MaterialButton from 'components/MaterialButton';
import { connect, useSelector } from 'react-redux';
import { registerField, change, Field } from 'redux-form';

import styles from './ProductCard.module.css';
import RenderProductText from './RenderProductText';
import FieldFolderSelect from '../../../components/FieldFolderSelect';
import {
  MOVE_PRODUCT_FORM,
  REMOVE_PRODUCT_FORM,
} from '../../../constants/formNames';
import FoldersQuery from '../../../graphql/queries/FoldersQuery';
import selectWorkspaceProducerId from '../../../selectors/workspaceProducerId';
import FormContainer from '../../Form';

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const RenderProductFolder = (props) => {
  const {
    folder,
    folderId,
    addingFolder,
    setAddingFolder,
    dispatch,
    productId,
  } = props;
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));
  const folderResults = useQuery(FoldersQuery, {
    variables: {
      condition: {
        producerId: producerId,
      },
    },
  });
  const addProductToFolderSubmit = (e, handleSubmit) => {
    dispatch(registerField(MOVE_PRODUCT_FORM, 'productId', 'Field'));
    dispatch(change(MOVE_PRODUCT_FORM, 'productId', productId));
    setAddingFolder(false);
    handleSubmit(e);
  };

  const removeProductFromFolderSubmit = (e, handleSubmit) => {
    dispatch(registerField(REMOVE_PRODUCT_FORM, 'productId', 'Field'));
    dispatch(change(REMOVE_PRODUCT_FORM, 'productId', productId));
    handleSubmit(e);
  };

  if (folderResults && folderResults.loading) return <></>;

  return folderId && folderId != 0 ? (
    <Grid container spacing={0} alignItems="center">
      <Grid item xs={12} sm={6}>
        <RenderProductText property={folder} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormContainer
          formName={REMOVE_PRODUCT_FORM}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <MaterialButton
                type="submit"
                onClick={(e) => removeProductFromFolderSubmit(e, handleSubmit)}
                style={{ padding: 0 }}
              >
                <IconButton size="small">
                  <Close fontSize="small" color="error" />
                </IconButton>
              </MaterialButton>
            </form>
          )}
        />
      </Grid>
    </Grid>
  ) : addingFolder ? (
    <FormContainer
      formName={MOVE_PRODUCT_FORM}
      render={({ handleSubmit, invalid, submitting, error }) => (
        <form onSubmit={handleSubmit}>
          <Field
            component={FieldFolderSelect}
            key="folder"
            name="folder"
            folderResults={{
              ...folderResults,
              folders: folderResults.data.folders,
            }}
            label="Folder"
          />
          <MaterialButton
            type="submit"
            onClick={(e) => addProductToFolderSubmit(e, handleSubmit)}
            style={{ width: 140 }}
            soft
            variant="outlined"
          >
            Add Product to Folder
          </MaterialButton>
        </form>
      )}
    />
  ) : (
    <div className={styles.addText} onClick={() => setAddingFolder(true)}>
      Add to Folder
      <img src={folderSvg} className={styles.addSymbol} alt="add-folder" />
    </div>
  );
};

export default connect(null, mapDispatchToProps)(RenderProductFolder);
