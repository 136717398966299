type Params = {
  email: string;
  password: string;
  username: string;
};

export default function httpChangePassword(
  params: Params,
  token: string,
): Promise<string> {
  return fetch('/iam/changePassword', {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  }).then((response) => {
    if (response.ok) {
      return response.text();
    } else {
      return response.text().then((message) => {
        throw new Error(message);
      });
    }
  });
}
