import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import flexContainer from '../../styles/flexContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageWrapper: {
      marginBottom: '10px',
    },
    message: {
      color: theme.palette.info.main,
      fontSize: '36px',
      fontWeight: 300,
      margin: '24px',
      textAlign: 'center',
    },
    pagination: {
      ...flexContainer(theme),
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    button: {
      border: '1px solid #ddd',
      borderRadius: '4px',
      color: 'white',
      padding: '10px',
      marginRight: '10px',
      background: 'linear-gradient(0deg, #282f3b, #303847)',
      marginBottom: '1rem',
      fontWeight: 'bold',
    },
  }),
);

export default useStyles;
