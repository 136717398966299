import { SUPERADMIN_EDIT_GROUP_WORKSPACE } from 'constants/routePaths';

import * as React from 'react';

import { gql, useQuery } from '@apollo/client';
import CreateOutlined from '@mui/icons-material/CreateOutlined';
import HighlightOffOutlined from '@mui/icons-material/HighlightOffOutlined';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './GroupWorkspace.module.css';

const FilteredGroupsQuery = gql`
  query FilteredGroupsQuery($filter: ParentProducerFilter) {
    parentProducers: allParentProducers(filter: $filter) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      nodes {
        id
        parentProducerId
        groupName
        childProducers: childProducersByGroupId {
          nodes {
            childProducerId
            producerByChildProducerId {
              id
              name
            }
          }
        }
        parentProducer: producerByParentProducerId {
          id
          name
        }
      }
    }
  }
`;

interface TableRow {
  id: number;
  groupName: string;
  parentWorkspaceName: string;
  childWorkspaceName: string;
  action: string;
  edit: string;
}

interface Props {
  reload: boolean;
}

const AllGroups: React.FC<Props> = ({ reload }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleDeleteGroup = (
    groupId,
    childProducerId,
    parentProducerId,
    reloadFunction,
  ) =>
    dispatch({
      type: 'DELETE_GROUP',
      payload: [groupId, childProducerId, parentProducerId, reloadFunction],
    });

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: 'ID',
      sortable: true,
      omit: true,
    },
    {
      id: 'groupName',
      selector: (row: TableRow) => row.groupName,
      name: <div className={styles.tableHeader}>{t('Group Name')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'parentWorkspaceName',
      selector: (row: TableRow) => row.parentWorkspaceName,
      name: <div className={styles.tableHeader}>{t('Parent Workspace')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'childWorkspaceName',
      selector: (row: TableRow) => row.childWorkspaceName,
      name: <div className={styles.tableHeader}>{t('Child Workspaces')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'action',
      selector: (row: TableRow) => row.action,
      name: <div className={styles.tableHeader}>{t('general.delete')}</div>,
      wrap: true,
    },
    {
      id: 'edit',
      selector: (row: TableRow) => row.edit,
      name: <div className={styles.tableHeader}>{t('general.edit')}</div>,
      wrap: true,
    },
  ];

  const setLoadedGroups = React.useCallback((parentProducers) => {
    return (
      parentProducers &&
      parentProducers.parentProducers &&
      parentProducers.parentProducers.nodes &&
      parentProducers.parentProducers.nodes
        .map((node) => ({
          id: node.id,
          groupName: node.groupName,
          parentWorkspaceName: node.parentProducer && node.parentProducer.name,
          childWorkspaceName:
            node.childProducers &&
            node.childProducers.nodes
              .map((item) => item.producerByChildProducerId.name)
              .join(','),
          action: (
            <IconButton
              size="small"
              onClick={() => {
                handleDeleteGroup(
                  node.id,
                  node.childProducers &&
                    node.childProducers.nodes
                      .map((item) => item.producerByChildProducerId.id)
                      .join(','),
                  node.parentProducer && node.parentProducer.id,
                  refetch,
                );
              }}
            >
              <HighlightOffOutlined color="primary" fontSize="small" />
            </IconButton>
          ),
          edit: (
            <Link
              to={{
                pathname: SUPERADMIN_EDIT_GROUP_WORKSPACE,
                state: { incomingGroupEdit: node },
              }}
            >
              <IconButton size="small">
                <CreateOutlined color="primary" fontSize="small" />
              </IconButton>
            </Link>
          ),
        }))
        .sort((a, b) => b.id - a.id)
    );
  }, []);

  const {
    loading,
    error,
    data: parentProducers,
    refetch,
  } = useQuery(FilteredGroupsQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    if (reload) refetch();
  }, [reload]);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <h4>Can't load groups!</h4>;
  }

  return (
    <div>
      <DataTable
        columns={columns}
        data={parentProducers && setLoadedGroups(parentProducers)}
        fixedHeader
        striped
        pagination
        paginationIconFirstPage={<RDTFirstPage />}
        paginationIconLastPage={<RDTLastPage />}
        paginationIconPrevious={<RDTPrevPage />}
        paginationIconNext={<RTDNextPage />}
        paginationPerPage={25}
        style={styles}
      />
    </div>
  );
};

export default AllGroups;
