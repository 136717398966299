import * as React from 'react';

import { SvgIcon } from '@mui/material';

// Generated with https://react-svgr.com/playground/
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1600}
    height={1600}
    viewBox="0 0 1200 1200"
    {...props}
  >
    <path d="M688.71 964.21V795.34H582.23v168.87H464.61l172.88 204.02 172.88-204.02z" />
    <path d="M976.59 369.96v-90.73c0-4.758-1.992-9.586-5.328-12.977l-221.06-223.88c-2.879-3.602-8.707-5.484-13.133-5.484h-422.75c-10.195 0-18.461 8.265-18.461 18.46v314.6h-58v366.59h58v155.27c0 10.204 8.265 18.462 18.46 18.462h237.14v-36.922l-218.68.004v-136.81h606.89v136.81h-220.18v36.922h238.64c10.196 0 18.462-8.258 18.462-18.461v-155.27h56.05v-366.59zM755.53 100.3l158.39 160.47H755.53zM450.58 637.71c-18.949 0-37.656-4.93-47.008-10.098l7.629-31.008c10.094 5.168 25.602 10.332 41.594 10.332 17.227 0 26.332-7.133 26.332-17.965 0-10.34-7.875-16.246-27.81-23.379-27.561-9.602-45.53-24.863-45.53-48.977 0-28.301 23.625-49.965 62.762-49.965 18.703 0 32.484 3.937 42.324 8.371l-8.363 30.27c-6.645-3.2-18.461-7.875-34.703-7.875-16.238 0-24.117 7.383-24.117 16 0 10.586 9.355 15.262 30.762 23.383 29.285 10.824 43.07 26.086 43.07 49.473.007 27.81-21.41 51.438-66.941 51.438zm187.56-2.46h-43.809l-53.164-165.88h41.102l20.18 70.14c5.66 19.691 10.832 38.641 14.77 59.316h.738c4.184-19.938 9.356-39.625 15.008-58.578l21.164-70.879H694zm222.51-7.638c-11.57 3.946-33.473 9.356-55.379 9.356-30.27 0-52.18-7.625-67.434-22.395-15.254-14.277-23.625-35.934-23.379-60.297.246-55.125 40.363-86.633 94.75-86.633 21.41 0 37.895 4.183 46.023 8.125l-7.875 30.023c-9.102-3.938-20.426-7.137-38.633-7.137-31.254 0-54.887 17.72-54.887 53.656 0 34.211 21.41 54.395 52.18 54.395 8.617 0 15.508-.984 18.461-2.46V569.54h-25.594v-29.285h61.773v87.355zm79.008-257.65h-606.89V73.822h385.83v205.4c0 10.195 8.266 18.461 18.461 18.461h202.6z" />
  </svg>
);

const SvgFileIcon = (props) => (
  <SvgIcon component={SvgComponent} viewBox="0 0 1200 1200" {...props} />
);

export default SvgFileIcon;
