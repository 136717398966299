import { gql } from '@apollo/client';

export interface RpProductTimeSeriesCountsResponse
  extends reports.ReportGraphQLReponse {
  allRpProductTimeseries: {
    totalCount: number;
  };
}

export default gql`
  query RpProductTimeSeriesCounts($reportID: UUID!) {
    allRpProductTimeseries(filter: { reportId: { equalTo: $reportID } }) {
      totalCount
    }
  }
`;
