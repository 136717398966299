import { gql } from '@apollo/client';

export default gql`
  query ReportGgVarTuningPlot(
    $reportID: UUID!
    $productID: Int
    $ggVar: String
  ) {
    allRpGgvarFlavorProfiles(
      condition: { reportId: $reportID, productId: $productID, ggvar: $ggVar }
    ) {
      nodes {
        reportId
        productId
        ggvar
        currentIntensity
        dataJsonb
        version
      }
    }
  }
`;
