import { mapLabel } from 'constants/ggVars';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { chain, max } from 'lodash';

import buildTree from './BuildTree';
import Dendrogram from './Dendrogram';
import ReportFlavorStructureQuery from '../../../graphql/queries/ReportFlavorStructureQuery';

interface ReportProps {
  reportId: string;
  productId: number;
}

interface DendrogramRow {
  productName: string;
  height: number;
  node?: number;
  parentNode?: number;
  size?: number;
  name?: string;
  isLeaf: boolean;
  nodeColor: string;
  children?: DendrogramRow[];
}

const ReportFlavorStructure: React.FC<ReportProps> = (props) => {
  const { reportId, productId } = props;

  const { loading, error, data } =
    useQuery<reports.ReportFlavorStructureResponse>(
      ReportFlavorStructureQuery,
      {
        variables: {
          reportID: reportId,
          productID: productId,
        },
      },
    );

  const getReshapedData = (
    reportFlavorStructureResponse: reports.ReportFlavorStructureResponse,
  ): DendrogramRow[][] => {
    return (
      chain(reportFlavorStructureResponse?.allRpFlavorStructures?.nodes)
        .groupBy('product.id')
        .values()
        .value()
        .map((flavorRows) =>
          flavorRows.map((flavorRow) => ({
            productName: flavorRow.product.name,
            height: flavorRow.height,
            node: flavorRow.node,
            size: flavorRow.members,
            parentNode: flavorRow.parentNode,
            name: mapLabel(flavorRow.label),
            isLeaf: flavorRow.leaf,
            nodeColor: flavorRow.nodeColor,
          })),
        )
        // Append a root node
        .map((flavorRows) => {
          const rootNode: DendrogramRow = {
            productName: flavorRows[0].productName,
            height: max(flavorRows.map((row) => row.height)) * 1.5,
            node: 0,
            size: flavorRows.length,
            name: flavorRows[0].productName,
            isLeaf: false,
            nodeColor: null,
            children: [],
          };

          return [rootNode].concat(flavorRows);
        })
    );
  };

  if (loading || error || !data) {
    return <div />;
  }

  return (
    <Box>
      <Dendrogram
        dataArray={buildTree(getReshapedData(data)[0], 'node', 'parentNode')}
      />
    </Box>
  );
};

export default ReportFlavorStructure;
