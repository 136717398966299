import React from 'react';

import { useTheme } from '@mui/material';

import useStyles from './useStyles';

type Props = {
  children: any
};

const LayoutMetricsGroup = ({ children, ...rest }: Props) => {
  const classes = useStyles(useTheme());

  return <div className={classes.container}>{children}</div>;
};

LayoutMetricsGroup.displayName = 'LayoutMetricsGroup';

export default LayoutMetricsGroup;
