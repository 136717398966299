export const myProfilePng = require('../../public/assets/my_profile.png');

export const viewSvgIcon = require('../../public/assets/panel_search/View.svg');
export const downloadSvgIcon = require('../../public/assets/panel_search/Download.svg');

export const profileIcon = require('../../public/assets/viewer_menu/profile.svg');
export const dataExplore = require('../../public/assets/viewer_menu/data_explore.svg');
export const adminIcon = require('../../public/assets/viewer_menu/admin.svg');
export const languageIcon = require('../../public/assets/viewer_menu/language.svg');
export const signOutIcon = require('../../public/assets/viewer_menu/sign_out.svg');

export const folderSvg = require('../../public/assets/folder/folder.svg');
export const folderAddSvgIcon = require('../../public/assets/folder/folder_add.svg');

export const ContactIconSvg = require('../../public/assets/project/chat-left-text.svg');

export const productSvg = require('../../public/assets/navigation_bar/Products.svg');
export const panelSvg = require('../../public/assets/navigation_bar/Panels.svg');
export const userSvg = require('../../public/assets/navigation_bar/Users.svg');
export const reportSvg = require('../../public/assets/navigation_bar/Reports.svg');
export const reviewSvg = require('../../public/assets/navigation_bar/Review.svg');
export const deviceSvg = require('../../public/assets/navigation_bar/Devices.svg');