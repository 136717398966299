import * as React from 'react';
import { useEffect, useState } from 'react';

import { Grid, Stack, TextField } from '@mui/material';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { first, last } from 'lodash';

interface Props {
  index: number;
  input: any;
  meta: any;
  label: string;
  initialValues: number[];
  reduxFormState: Array<any>;
  fieldType: 'GGVAR' | 'RFF';
  displayMode: boolean;
}

const TuningRangeSlider: React.FC<Props> = ({
  index,
  input,
  meta,
  label,
  fieldType,
  reduxFormState,
  displayMode,
}) => {
  const initialValues = fieldType === 'GGVAR' ? [0, 5] : [0, 25];

  const [bottom, setBottom] = useState<number>(first(initialValues));
  const [top, setTop] = useState<number>(last(initialValues));

  const handleChange = (_event, newValue: number[]) => {
    input.onChange(newValue);
  };

  useEffect(() => {
    handleChange(null, reduxFormState[index] ?? initialValues);
  }, []);

  useEffect(() => {
    setBottom(input.value[0]);
    setTop(input.value[1]);
  }, [input.value]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={8}>
        <Stack spacing={1}>
          <Typography gutterBottom>{label}</Typography>
          <Stack spacing={2} direction="row" alignItems="center">
            <Stack spacing={1}>
              <TextField
                sx={{ width: '65px' }}
                name="bottom"
                variant="outlined"
                id="bottom"
                size="small"
                value={bottom}
                label="Min"
                onChange={(event) => {
                  const intValue = parseInt(event.target.value);

                  if (isNaN(intValue)) {
                    setBottom(null);
                  } else {
                    setBottom(intValue);
                    input.onChange([intValue, input.value[1]]);
                  }
                }}
              />{' '}
              <TextField
                sx={{ width: '65px' }}
                name="top"
                variant="outlined"
                id="top"
                size="small"
                value={top}
                label="Max"
                onChange={(event) => {
                  const intValue = parseInt(event.target.value);

                  if (isNaN(intValue)) {
                    setTop(null);
                  } else {
                    setTop(intValue);
                    input.onChange([input.value[0], intValue]);
                  }
                }}
              />
            </Stack>
            <Slider
              min={first(initialValues)}
              max={last(initialValues)}
              step={1}
              valueLabelDisplay="auto"
              value={input.value}
              onChange={handleChange}
              disabled={displayMode}
              size="small"
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TuningRangeSlider;
