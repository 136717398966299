import React from 'react';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Field } from 'redux-form';

import FieldCheckBox from '../../../components/FieldCheckBox';
import FieldTextInput from '../../../components/FieldTextInput';
import { MARKET_SURVEY, PRODUCT_UPDATE } from '../../../constants/report';
import {
  PageHeader,
  SLFormLabel,
  SLCaption,
} from '../../../styles/themeComponents';
import { nvlObject } from '../../../utils/afsUtils';
import styles from '../RequestReport.module.css';

export interface AdvancedConfigProps {
  simulate_pq_values: any;
  include_texture: any;
  n_sims: any;
  change: (field: string, value: any) => void;
  displayMode: boolean;
  report_type: SelectItem;
  timestamp_start: number;
  isProd: boolean;
  categories: any[];
}

const AdvancedConfig: React.FC<AdvancedConfigProps> = ({
  simulate_pq_values,
  include_texture,
  n_sims,
  change,
  displayMode,
  report_type,
  timestamp_start,
  isProd,
  categories,
}) => {
  const { t } = useTranslation();

  const advanced_config_checkbox_options = [
    {
      param: 'include_texture',
      label: 'Include Texture Analysis',
      value: true,
      group: 'texture',
    },
    {
      param: 'texture_only',
      label: 'Texture Only Report',
      disabled: !include_texture,
      group: 'texture',
    },
    {
      param: 'texture_data_only',
      label: 'Only Texture Data Available',
      disabled: !include_texture,
      group: 'texture',
    },
    { param: 'profilingEnabled', label: 'Profiling Enabled' },
    { param: 'generate_ppt', label: 'Generate Powerpoint' },
    {
      param: 'showConfidence',
      label: 'Show Texture Confidence Thresholds',
      disabled: !include_texture,
      group: 'texture',
    },
    {
      param: 'show_TC_se',
      label: 'Show Texture Cluster Uncertainty',
      disabled: !include_texture,
      group: 'texture',
    },
    { param: 'extrema_analysis', label: 'Extrema Analysis' },
    { param: 'includeNoveltyScores', label: 'Include Novelty Scores' },
    {
      param: 'plotNorm',
      label: 'Plot Norm PQ Gradients (plot vertical dashed lines)',
    },
    {
      param: 'show_pg_gNorm',
      label:
        'Enable Category and Stack Rank Norms (Supplementary table in Report Summary section)',
    },
    {
      param: 'combine_artificial_sweeteners',
      label: 'Combine Artificial Sweeteners',
    },
    { param: 'override_invalid', label: 'Override Invalid Products' },
    {
      param: 'only_include_parents',
      label: 'Select only parents in the target demographic',
    },
    {
      param: 'experimental_features',
      label: 'Enable Experimental Features',
      value: true,
    },
  ];

  return (
    <div>
      {!displayMode && (
        <div>
          <PageHeader>{t('navigation.reports')}</PageHeader>
          <h3 className={styles.productFieldsTitle}>
            {`${t('reports.createReportRequest')}: ${
              report_type.value === MARKET_SURVEY
                ? t('reports.marketSurvey')
                : t('reports.productReport')
            }`}
          </h3>
        </div>
      )}

      <h4 className={styles.productFieldsTitle}>
        {t('reports.advancedReportConfig')}
      </h4>

      <div className={styles.sectionContainer}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Textures
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.sectionContainer}>
              {advanced_config_checkbox_options
                .filter((d) => d.group == 'texture')
                .map((d, i) => (
                  <div
                    className={styles.sectionContainer}
                    key={i + '-texture-' + d.value}
                  >
                    <Field
                      name={d.param}
                      component={FieldCheckBox}
                      label={d.label}
                      disabled={d.disabled || displayMode}
                      value={d.value ?? false}
                    />
                  </div>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Archetypes
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.sectionContainer}>
              <Field
                name="include_archetypes"
                component={FieldCheckBox}
                label="Include Archetypes"
                onChange={(newValue) =>
                  change('include_archetypes', newValue?.value)
                }
                disabled={displayMode}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {t('projects.trendAnalysis.title')}
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.sectionContainer}>
              <SLFormLabel>Trend Analysis Starting From Year</SLFormLabel>
              {uniq<string>(categories).length > 1 && (
                <SLCaption>(disabled for multi-category reports)</SLCaption>
              )}
              <Select
                options={[null, 2019, 2020, 2021, 2022].map((d) => ({
                  label: d,
                  value: d,
                }))}
                onChange={(newValue) =>
                  change('timestamp_start', newValue?.value)
                }
                isDisabled={displayMode || uniq<string>(categories).length > 1}
                value={{
                  label: nvlObject<number>(timestamp_start, null),
                  value: nvlObject<number>(timestamp_start, null),
                }}
              ></Select>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Other Advanced Configuration Options
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.sectionContainer}>
              <Field
                name="simulate_pq_values"
                component={FieldCheckBox}
                label="Simulate PQ Values"
                disabled={displayMode}
              />
              <Field
                name="n_sims"
                style={{
                  width: '50%',
                }}
                disabled={!simulate_pq_values || displayMode}
                component={FieldTextInput}
                value={n_sims}
                label="Number of simulations (comma-separated)"
                onChange={(e) =>
                  change('n_sims', e.currentTarget.value.split(','))
                }
                required
              />
              {advanced_config_checkbox_options
                .filter((d) => d.group != 'texture')
                .map((d, i) => (
                  <div
                    className={styles.sectionContainer}
                    key={i + '-not-texture-' + d.value}
                  >
                    <Field
                      name={d.param}
                      component={FieldCheckBox}
                      label={d.label}
                      disabled={d.disabled || displayMode}
                      value={d.value ?? false}
                    />
                  </div>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
        {report_type.value === PRODUCT_UPDATE && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Report Update
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.sectionContainer}>
                <Field
                  name="update_products.ensure_exact_pq"
                  component={FieldCheckBox}
                  label="Ensure Exact PQ"
                  disabled={displayMode}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default AdvancedConfig;
