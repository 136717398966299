import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalHeader: {
      writingMode: 'vertical-lr',
      alignItems: 'right',
    },
  }),
);

export default useStyles;
