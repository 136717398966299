import { VIEW_PRODUCT_FROM_SEARCH } from 'constants/googleAnalytics/actions';
import { CAT_PRODUCT_LIST } from 'constants/googleAnalytics/categories';

import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import ProductSearchQuery from '@graphql/queries/ProductSearchQuery';
import Paper from '@mui/material/Paper';
import { flowRight as compose, get } from 'lodash';
import moment from 'moment';
import DataTable, { TableColumn } from 'react-data-table-component';
import ReactGA from 'react-ga4';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import styles from './ProductSearch.module.css';
import MaterialButton from '../../components/MaterialButton';
import { PRODUCT } from '../../constants/routePaths';
import formatPath from '../../utils/formatPath';

interface Props {
  query: string;
  data?: any;
  producerId?: number;
  first: number;
  hideSearch: () => any;
  onClick?: any;
}

interface TableRow {
  id: number;
  image: string;
  name: string;
  localName: string;
  brand: string;
  date: string;
  reviews: number;
  lastReview: number;
  textureReviewsLowerBound: number;
  category: string;
  workspace: string;
  productWorkspaceId: number;
  productWorkspaceName: string;
}

interface State {
  count: number;
}

const { event } = ReactGA;

class ProductSearch extends React.Component<Props & WithTranslation, State> {
  node: HTMLDivElement = null;

  constructor(props) {
    super(props);
    this.state = {
      count: props.first,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    // Clicking outside
    if (!(this.node && this.node.contains(e.target))) {
      this.props.hideSearch();
    }
  };

  productNameFormatter = (cell, row) => {
    if (this.props.onClick) {
      return (
        <a className={styles.link} onClick={() => this.props.onClick(row)}>
          {cell}
        </a>
      );
    }
    return (
      <Link
        className={styles.link}
        to={{ pathname: formatPath(PRODUCT, { productId: row.id }) }}
        onClick={() =>
          event({
            category: CAT_PRODUCT_LIST,
            action: VIEW_PRODUCT_FROM_SEARCH,
            label: row.id.toString(),
          })
        }
      >
        {cell}
      </Link>
    );
  };

  getProductData = (data: any): TableRow[] => {
    const products = get(data, 'productResults.nodes', []);

    return products.map((product) => ({
      id: product.id,
      image:
        get(product, 'productImages.totalCount') > 0 &&
        product.productImages.nodes[0].url,
      name: product.name,
      localName: product.localName,
      brand: product.brand,
      date: moment(product.createdAt).format('ll'),
      reviews: get(product, 'productReviews.totalCount'),
      lastReview:
        get(product, 'productReviews.nodes.length') > 0
          ? moment(product.productReviews.nodes[0].createdAt).format('ll')
          : '',
      category: product.category,
    }));
  };

  onFetchMore = (count) => {
    this.props.data.fetchMore({
      variables: {
        first: count,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        this.setState({
          count: this.state.count + fetchMoreResult.productResults.nodes.length,
        });
        return Object.assign({}, prev, {
          productResults: {
            nodes: [
              ...prev.productResults.nodes,
              ...fetchMoreResult.productResults.nodes,
            ],
          },
          ...fetchMoreResult,
        });
      },
    });
  };

  render() {
    const { t, data, first, onClick } = this.props;

    const columns: TableColumn<TableRow>[] = [
      {
        selector: (row: TableRow) => row.id,
        name: <div className={styles.tableHeader}>'Product ID'</div>,
        sortable: true,
        omit: true,
      },
      {
        id: 'image',
        selector: (row: TableRow) => row.image,
        name: <div className={styles.tableHeader}>{t('general.image')}</div>,
        cell: (row: TableRow) =>
          row.image ? (
            <div>
              <img
                src={row.image}
                alt={`${row.id}_img`}
                className={styles.image}
              />
            </div>
          ) : (
            <div />
          ),
        omit: !!onClick,
      },
      {
        selector: (row: TableRow) => row.name,
        name: (
          <div className={styles.tableHeader}>{t('product.productName')}</div>
        ),
        cell: (row: TableRow) => (
          <Link
            className={styles.link}
            to={{ pathname: formatPath(PRODUCT, { productId: row.id }) }}
            onClick={() =>
              event({
                category: CAT_PRODUCT_LIST,
                action: VIEW_PRODUCT_FROM_SEARCH,
                label: row.id.toString(),
              })
            }
          >
            {row.localName ? `${row.name} (${row.localName})` : `${row.name}`}
          </Link>
        ),
        sortable: true,
      },
      {
        selector: (row: TableRow) => row.brand,
        name: (
          <div className={styles.tableHeader}>{t('product.productBrand')}</div>
        ),
        sortable: true,
      },
      {
        selector: (row: TableRow) => row.date,
        name: (
          <div className={styles.tableHeader}>{t('general.dateCreated')}</div>
        ),
        sortable: true,
      },
      {
        selector: (row: TableRow) => row.reviews,
        name: <div className={styles.tableHeader}>{t('reviews.reviews')}</div>,
      },
      {
        selector: (row: TableRow) => row.workspace,
        name: <div className={styles.tableHeader}>Workspace</div>,
      },
      {
        selector: (row: TableRow) => row.lastReview,
        name: (
          <div className={styles.tableHeader}>{t('reviews.lastReview')}</div>
        ),
      },
      {
        selector: (row: TableRow) => row.category,
        name: <div className={styles.tableHeader}>Category</div>,
        omit: true,
      },
    ];

    if (data.loading) return <div />;

    return (
      <div ref={(node) => (this.node = node)}>
        <Paper className={styles.container}>
          <div className={styles.tableContainer}>
            <div>
              <DataTable
                columns={columns}
                data={this.getProductData(data)}
                fixedHeader
                striped
              />
            </div>
            <div style={{ textAlign: 'center', marginBottom: first }}>
              {data.productResults.totalCount >
                data.productResults.nodes.length && (
                <MaterialButton
                  variant="outlined"
                  soft
                  onClick={() =>
                    this.onFetchMore(data.productResults.nodes.length + first)
                  }
                >
                  Load More
                </MaterialButton>
              )}
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  producerId: selectWorkspaceProducerId(state),
});

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  graphql(ProductSearchQuery, {
    options: ({ query, producerId, first }: Props) => ({
      variables: {
        query,
        producerId,
        first: first,
      },
    }),
  }),
)(ProductSearch);
