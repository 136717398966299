import * as React from 'react';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CSVDownload from 'components/CSVDownload';
import LoadingScreen from 'components/LoadingScreen';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  table: {
    minWidth: 900,
    '& .MuiTableCell-root': {
      padding: '4px',
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    '& .MuiTableCell-head': {
      border: '1px solid rgba(224, 224, 224, 1)',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
  },
  summary: {
    textAlign: 'center',
    verticalAlign: 'middle',
    wordBreak: 'break-all',
    fontSize: 15,
    padding: 7,
  },
  detail: {
    textAlign: 'center',
    verticalAlign: 'middle',
    wordBreak: 'break-all',
    fontSize: 11,
    padding: 7,
  },
  downloadButton: {
    textAlign: 'right',
  },
});

const getAgeBreaks = (ageBreakStr: string) =>
  [0]
    .concat(
      ageBreakStr
        .split(',')
        .map((s) => parseInt(s))
        .filter(Boolean),
    )
    .concat([100]);

// String representation of the age ranges, based on ageBreaks array
const getAgeRanges = (ageBreaks: number[]) =>
  ageBreaks
    .sort((n1, n2) => n1 - n2)
    .map((a) => `${a}-${ageBreaks[ageBreaks.findIndex((e) => e > a)] - 1}`)
    .slice(0, -1);

const getAgeRange = (ageBreaks, ageRanges: string[], age: number): string => {
  const ageIdx = ageBreaks.findIndex((e) => age < e);
  return ageRanges[ageIdx - 1];
};

const reshapeData = (
  panelData: dataquality.DataQualityDetail[],
  ageBreaksStr: string,
  showDetails: boolean,
) => {
  const ageBreaks: number[] = getAgeBreaks(ageBreaksStr);
  const ageRanges: string[] = getAgeRanges(ageBreaks);

  const genders = panelData
    .map((p) => p.gender)
    .reduce((a, b) => (a.indexOf(b) !== -1 ? a : a.concat(b)), []);

  let data = [];
  genders.forEach((gender) => {
    // Gender Summary Rows
    data = data.concat({
      gender: gender,
      summary: true,
    });

    // Fill in summary data for gender summary row
    ageRanges.forEach((ageRange) => {
      const rangedValues = panelData
        .filter((d) => d.gender === gender)
        .filter((d) => getAgeRange(ageBreaks, ageRanges, d.age) === ageRange);

      Object.defineProperty(data[data.length - 1], ageRange + 'Pin', {
        value:
          rangedValues.length > 0
            ? rangedValues.map((d) => d.pin).reduce((a, b) => (a ? a : b), null)
            : '',
        writable: true,
        enumerable: true,
        configurable: true,
      });
      Object.defineProperty(data[data.length - 1], ageRange + 'Id', {
        value:
          rangedValues.length > 0
            ? rangedValues.map((d) => 1).reduce((a, b) => a + b, 0)
            : '',
        writable: true,
        enumerable: true,
        configurable: true,
      });
      Object.defineProperty(data[data.length - 1], ageRange + 'Flags', {
        value:
          rangedValues.length > 0
            ? rangedValues.map((d) => d.allFlagCount).reduce((a, b) => a + b, 0)
            : '',
        writable: true,
        enumerable: true,
        configurable: true,
      });
    });

    // If showDetails box is selected, construct a row for each matching user
    if (showDetails) {
      const genderUsers = panelData.filter((d) => d.gender === gender);

      genderUsers.forEach((genderUser) => {
        data = data.concat({
          gender: '',
          summary: false,
        });

        const ageRange = getAgeRange(ageBreaks, ageRanges, genderUser.age);

        Object.defineProperty(data[data.length - 1], ageRange + 'Pin', {
          value: genderUser.pin,
          writable: true,
          enumerable: true,
          configurable: true,
        });
        Object.defineProperty(data[data.length - 1], ageRange + 'Id', {
          value: genderUser.userRef,
          writable: true,
          enumerable: true,
          configurable: true,
        });
        Object.defineProperty(data[data.length - 1], ageRange + 'Flags', {
          value: genderUser.allFlagCount,
          writable: true,
          enumerable: true,
          configurable: true,
        });
      });
    }
  });
  return data;
};

interface Props {
  panelData: dataquality.DataQualityDetail[];
}

interface TableRow {
  gender: string;
  [key: string]: string;
}

const PanelDemographicsTable: React.FC<Props> = (props) => {
  const { panelData } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [showDetails, setShowDetails] = React.useState(false);
  const [ageBreaksStr, setAgeBreaksStr] = React.useState('20, 30, 40, 50');

  const flatMap = (xs: any[], f: (x: any) => any[]): any[] =>
    xs.reduce((r, x) => r.concat(f(x)), []);

  // Dymamically create the columns for the bootstrap table
  function getColumns(ageBreaksStr: string) {
    const ageRanges = getAgeRanges(getAgeBreaks(ageBreaksStr));
    return [
      {
        dataField: 'gender',
        text: 'Gender',
        sort: true,
        csvExport: true,
        headerStyle: () => {
          return { width: '100px', textAlign: 'center' };
        },
      },
    ].concat(
      flatMap(ageRanges, (ageRange: string) => [
        {
          dataField: `${ageRange}Pin`,
          text: 'PIN',
          sort: true,
          csvExport: true,
          headerFormatter: () => {
            return (
              <div>
                <br></br>PIN
              </div>
            );
          },
          style: (row, cell, rowIndex) =>
            rowIndex != null && {
              textAlign: 'center',
              verticalAlign: 'middle',
              wordBreak: 'break-all',
              fontSize: cell?.summary ? 16 : 11,
              padding: 7,
            },
        },
        {
          dataField: `${ageRange}Id`,
          text: `${ageRange} User(s)`,
          sort: true,
          csvExport: true,
          headerFormatter: () => {
            return (
              <div>
                {ageRange}
                <br></br>Users
              </div>
            );
          },
          headerStyle: (colum, colIndex) => {
            return { width: '100px', textAlign: 'center' };
          },
          style: (row, cell, rowIndex) =>
            rowIndex != null && {
              textAlign: 'center',
              verticalAlign: 'middle',
              wordBreak: 'break-all',
              fontSize: cell?.summary ? 16 : 11,
              padding: 7,
            },
        },
        {
          dataField: `${ageRange}Flags`,
          text: 'Flags',
          sort: true,
          csvExport: true,
          headerFormatter: () => {
            return (
              <div>
                <br></br>Flags
              </div>
            );
          },
          style: (row, cell, rowIndex) =>
            rowIndex != null && {
              textAlign: 'center',
              verticalAlign: 'middle',
              wordBreak: 'break-all',
              fontSize: cell?.summary ? 16 : 11,
              padding: 7,
            },
        },
      ]),
    );
  }

  if (!panelData) {
    return <LoadingScreen />;
  }

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <label>
            Show Detail &nbsp;
            <input
              type="checkbox"
              onClick={() => setShowDetails(!showDetails)}
            />
          </label>
        </Grid>
        <Grid item xs={4}>
          <label>
            Age Breaks &nbsp;
            <input
              type="text"
              name="name"
              value={ageBreaksStr}
              onChange={(event) => setAgeBreaksStr(event.target.value)}
            />
          </label>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.downloadButton}>
            <CSVDownload
              csvData={reshapeData(panelData, ageBreaksStr, showDetails)}
              fileName="All_Reference_Flavors.csv"
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {getColumns(ageBreaksStr).map((col, idx) => (
                  <TableCell key={col.text + idx.toFixed(0)}>
                    {col.text}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reshapeData(panelData, ageBreaksStr, showDetails).map(
                (row, idx) => (
                  <TableRow key={row.gender + idx.toFixed(0)}>
                    {getColumns(ageBreaksStr).map((col) => (
                      <TableCell>
                        <div
                          className={
                            row.summary ? classes.summary : classes.detail
                          }
                        >
                          {row[col.dataField]}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PanelDemographicsTable;
