import { gql } from '@apollo/client';

export interface AllRpOfpDescriptionsResponse {
  loading: boolean;
  error: any;
  descriptions: {
    nodes: {
      description: string;
    }[];
  };
}

export default gql`
  query AllRpOfpDescriptions($filter: RpOfpDescriptionFilter) {
    descriptions: allRpOfpDescriptions(filter: $filter) {
      nodes {
        description
      }
    }
  }
`;
