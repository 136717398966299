import { gql } from '@apollo/client';

export default gql`
  query MultiProductSearchQuery($ids: [Int!]) {
    allProducts(filter: { id: { in: $ids } }) {
      nodes {
        id
        name
        brand
        public
        aroma
        producer: producerByProducerId {
          id
          name
        }
        createdAt
        productReviews: productReviewsByProductId(
          orderBy: END_TIME_DESC
          first: 1
        ) {
          totalCount
          nodes {
            createdAt
          }
        }
        productImages: productImagesByProductId {
          totalCount
          nodes {
            url
          }
        }
        panels: panelProductsByProductId {
          nodes {
            panelInfo: panelByPanelId {
              id
              startTime
              name
              pin
            }
          }
        }
        productClass
        productClasses: productClassProductsByProductId {
          nodes {
            id
            productClassByProductClassId {
              id
              name
            }
          }
        }
        defaultAttributes
        selectedProductQuestions: selectedProductQuestionsByProductId {
          totalCount
        }
        category: productCategoryByCategoryId {
          id
          name
        }
      }
    }
  }
`;
