const classes = [
  'Chips',
  'Crisps',
  'Tortilla',
  'Juice',
  'Soup',
  'Bread',
  'Pastries',
  'Crackers',
  'Cookies',
  'Milk',
  'Gum',
  'Gummies',
  'Vegetables',
  'Fruit',
  'Ranch',
  'Blue Cheese Dressing',
  'Fruit Preserves',
  'Bars',
  'Granola',
  'Chocolate',
  'Mint',
  'Seltzer',
  'Cider',
  'Yogurt',
  'Cheese',
  'Soda',
  'Coffee',
  'Tea',
  'Energy Drink',
  'Sports Drink',
  'Nuts',
  'Salsa',
  'Beer',
  'Popcorn',
  'Pretzel',
  'Jerky',
  'Cereal',
  'Whiskey',
  'Scotch',
  'Meat',
  'Nut Butter',
  'Marshmallow',
  'Corn',
  'Rice',
  'Seafood',
  'Shellfish',
  'Candy',
  'Flavored Water',
  'Ice Cream',
  'Wine',
  'Nuts',
  'Milk',
  'Goat Milk',
  'Sheep Milk',
  'Beans',
  'Oats',
  'Bourbon',
  'Scotch',
  'Hazelnut',
  'Pea',
  'Chickpea',
  'Pepper',
  'Pickle',
  'Apple',
  'Olives',
  'Capers',
  'Coconut Water',
  'Hummus',
  'Plantain',
  'Cake',
  'Tarts',
  'Sweets',
  'Snack',
  'Drink',
  'Salt',
  'Egg',
  'Lemonade',
  'Limeade',
  'Latte',
  'Mocha',
  'Cold Brew',
  'Kombucha',
  'Frappuccino',
  'Cacao',
  'Macchiato',
  'Marzipan',
  'Espresso',
  'Cider',
  'Vinegar',
  'Macadamia Nuts',
  'Chia Seeds',
  'Mezcal',
  'Mint',
  'Onions',
  'Mix',
  'Pudding',
  'Dips',
  'Breakfast',
  'Seeds',
  'Cassava',
  'Spices',
  'Sauces',
  'Spreads',
  'Organic',
  'Dairy-Free',
  'Allergen-Free',
  'Gluten-Free',
  'Non-GMO',
  'BPA-Free',
  'Cold-Pressed',
  'High Pressure Certified',
  'Vegan',
  'Kosher',
  'Probiotic',
  'No Added Sweeteners',
  'Fortified',
  'Vitamin',
  'No Artificial Flavors',
  'No Artificial Colors',
  'Substitute',
  'No High Fructose Corn Syrup',
  'No Added Sugar',
  'Low Calorie',
  'Sodium-Free',
  'No Preservatives',
  'All Natural',
  'Cholesterol-Free',
  'Halal',
  'Soy-Free',
  'Egg-Free',
  'Fish-Free',
  'Shellfish-Free',
  'Nut-Free',
  'Caffeine-Free',
  'Reduced Fat',
  'Decaffeinated',
  'No Caffeine',
  '100% Juice',
  'USDA Organic',
  'Zero Calories',
  'Carrageenan-Free',
  'RSPO Certified',
  'CCOF Organic',
  'Dried',
  'Dehydrated',
  'Freeze-Dried',
  'Tetra Pak',
  'Powdered',
  'Frozen',
  'Canned',
  'Bottled',
  'Cupped',
  'Jarred',
  'Kettle-Cooked',
  'Carbonated',
  'Fresh',
  'Nitro',
  'Pasteurized',
  'Ultra-Pasteurized',
  'Fried',
  'Filtered',
  'Distilled',
  'Fermented',
  'Carton',
  'Plastic',
  'Glass',
  'Unfiltered',
  'Roasted',
  'Raw',
  'Pulp',
  'Pulp-Free',
  'Pureed',
  'In Olive Oil',
  'Medicinal',
  'Added Protein',
  'Kegged',
  'From Concentrate',
  'Alcoholic',
  'Ale',
  'Lager',
  'Stout',
  'Porter',
  'Malts',
  'Amber',
  'Dark',
  'IPA',
  'Light',
  'Pale',
  'Pilsner',
  'Wheat',
  'Belgian',
  'Red Wine',
  'White Wine',
  'Rosé Wine',
  'Ginger Beer',
  'Gin'
];

export default classes;
