import * as React from 'react';

import { useTheme } from '@mui/material';
import useGgEnvironment from 'hooks/useGgEnvironment';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';

const AdminToolsHeader: React.FC = (props) => {
  const { t } = useTranslation();

  const styles = useStyles(useTheme());

  const environment = useGgEnvironment();

  return (
    <h3 className={styles.header}>
      {t('navigation.adminTools')} - {environment}
    </h3>
  );
};

export default AdminToolsHeader;
