import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import AllWorkspaceGroupQuery from '@graphql/queries/AllWorkspaceGroupQuery';
import { Formik } from 'formik';
import { flowRight as compose } from 'lodash';
import { get } from 'lodash';
import moment from 'moment';
import * as momentTZ from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isAfsWorkspace } from 'utils/afsUtils';

import { handleStartPanel } from './handleStartPanel';
import PanelCreateForm from './PanelCreateForm';
import { validatePanelForm } from './validatePanelForm';
import AllTagsQuery from '../../graphql/queries/AllTagsQuery';
import ProducerByIdQuery from '../../graphql/queries/ProducerByIdQuery';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';

export function PanelContainer({
  workspaceId,
  panelTagResults,
  producer,
  workspaceGroupResults,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  if (!workspaceId) {
    return <div>ERROR: No workspace selected!</div>;
  }

  const afsWorkspaceBoolean = isAfsWorkspace(workspaceId);

  const workspaceGroups = get(
    workspaceGroupResults,
    'allParentProducers.nodes',
    null,
  );

  if (producer.loading) return <div />;

  const reformatTime = (value) => {
    if (value.minutes() % 15 != 0) {
      value = value.minutes(Math.round(value.minutes() / 15) * 15);
    }

    value =
      !producer.loading &&
      producer.producer &&
      producer.producer.defaultTimezone
        ? momentTZ.tz(value, producer.producer.defaultTimezone)
        : momentTZ.tz(
            value.format('YYYY-MM-DD HH:mm'),
            'YYYY-MM-DD HH:mm',
            momentTZ.tz.guess(),
          );

    return value;
  };

  return (
    <div style={{ width: '95%', marginBottom: '2rem' }}>
      <h1>{t('panel.createPanel')}</h1>
      <Formik
        initialValues={{
          products: [],
          name: '',
          startTime: reformatTime(moment()),
          endTime: reformatTime(moment().add('7', 'day')),
          blindPanel: false,
          afsWorkspaceBool: afsWorkspaceBoolean,
          hideReviews: false,
          public: false,
          productSearch: '',
          blindLabels: {},
          panelTags: [],
          showConfirmation: false,
          servingVessels: {},
          behavioralQuestions: {},
          clientNames: {},
          projectNames: {},
          totalCosts: {},
          productionDates: {},
          expirationDates: {},
        }}
        validate={validatePanelForm}
        validateOnBlur={true}
        onSubmit={handleStartPanel(history)}
        render={(props) => (
          <PanelCreateForm
            {...props}
            producerId={workspaceId}
            panelTagResults={panelTagResults}
            editing={false}
            allowBehavioralQuestions={
              producer.producer && producer.producer.allowBehavioralQuestions
            }
            workspaceGroups={workspaceGroups}
          />
        )}
      />
    </div>
  );
}

type Props = {
  workspaceId: any;
};

const mapStateToProps = (state) => {
  return {
    workspaceId: selectWorkspaceProducerId(state),
  };
};

export default compose(
  connect(mapStateToProps),
  graphql(ProducerByIdQuery, {
    name: 'producer',
    options: (props: Props) => ({
      variables: {
        id: props.workspaceId,
      },
    }),
  }),
  graphql(AllTagsQuery, {
    options: (props: Props) => ({
      variables: {
        condition: {
          producerId: props.workspaceId,
        },
      },
    }),
    name: 'panelTagResults',
  }),
  graphql(AllWorkspaceGroupQuery, {
    options: (props: Props) => ({
      variables: {
        workspaceId: props.workspaceId,
      },
    }),
    name: 'workspaceGroupResults',
  }),
)(PanelContainer);
