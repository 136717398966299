/**
 * Check if environment is production
 * 
 * @param env current sensory link environment
 * @returns isProd
 */
export function isProd(env: SensoryLinkEnvironment): boolean {
  return env === "production";
}

/**
 * Check if environment is staging
 * 
 * @param env current sensory link environment
 * @returns isStaging
 */
export function isStaging(env: SensoryLinkEnvironment): boolean {
  return env === "staging";
}