import ggVars from 'constants/ggVars';

import * as React from 'react';

import { Grid, Slider, Typography } from '@mui/material';

import { ForestInput, PQPredictionInput } from './pqModelTypes';
import { ParsedPQModel } from './pqModelUtils';
import { getForestPQ } from './tranverseTree';

const forestify = (
  ggVarInput: PQPredictionInput[],
  refFlInput: PQPredictionInput[],
): ForestInput => {
  return []
    .concat(ggVarInput)
    .concat(refFlInput)
    .reduce((obj: ForestInput, i: PQPredictionInput) => {
      if (i.intensity) {
        obj[i.attribute] = i.intensity;
      }
      return obj;
    }, {});
};

interface Props {
  inputRow: PQPredictionInput[];
  model: ParsedPQModel;
  originalPQ: number;
}

const OFPTable: React.FC<Props> = ({ inputRow, model, originalPQ }) => {
  const ggVarInput: PQPredictionInput[] = inputRow.filter((r) =>
    ggVars.map((g) => g.key).includes(r.attribute),
  );
  const refFlInput: PQPredictionInput[] = inputRow.filter(
    (r) => !ggVars.map((g) => g.key).includes(r.attribute),
  );

  const delta =
    originalPQ - getForestPQ(model, forestify(ggVarInput, refFlInput));

  const [ofpVariables, setOFPVariables] =
    React.useState<PQPredictionInput[]>(ggVarInput);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} alignContent="center">
        <Typography variant="h5" align="center">
          {`PQ: ${(getForestPQ(model, forestify(ofpVariables, refFlInput)) + delta).toFixed(3)}`}
        </Typography>
      </Grid>
      {ofpVariables.map((ggvar) => (
        <Grid item xs={6} key={ggvar.attribute}>
          <Typography gutterBottom>{ggvar.attribute}</Typography>
          <Slider
            min={0.01}
            max={4.99}
            step={0.05}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => value.toPrecision(3)}
            value={ggvar.intensity}
            onChange={(_event, value) => {
              setOFPVariables(
                ofpVariables.map((i) =>
                  i.attribute === ggvar.attribute
                    ? {
                        attribute: ggvar.attribute,
                        intensity: Array.isArray(value) ? value[0] : value,
                      }
                    : i,
                ),
              );
            }}
            size="small"
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default OFPTable;
