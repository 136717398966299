import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import ReportTextureCompPrefQuery from '@graphql/queries/ReportTextureCompositionQuery';
import RpTextureTernaryHeatmapQuery from '@graphql/queries/RpTextureTernaryHeatmapQuery';
import textureClusterNamesQuery from '@graphql/queries/textureClusterNamesQuery';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { ProductVersionSet } from 'components/Report/ProductVersion';
import { getProductName } from 'components/Report/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary from 'hooks/useReportSummary';
import { chain } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import selectViewerUserId from 'selectors/viewerUserId';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';
import { useCustomerPreferences } from 'services/customerPreferences';

import styles from './ReportTextureTernary.module.css';
import TextureTernaryExplainer from './TextureTernaryExplainer';
import TextureTernaryPlot from './TextureTernaryPlot';

const numberOfClusters = (
  reportTextureCompositionResponse: reports.ReportTextureCompositionResponse,
): number => {
  return Math.max(
    ...[...reportTextureCompositionResponse.allRpTextureCompositions.nodes].map(
      (row) => row.clusterIdx,
    ),
  );
};
const range = (start: number, end: number, length = end - start) =>
  Array.from({ length }, (_, i) => start + i);

const chooseThree = (n: number): number[][] => {
  if (n < 3) {
    return [];
  }
  return range(1, n + 1)
    .map((i) =>
      range(i + 1, n + 1).map((j) => range(j + 1, n + 1).map((k) => [i, j, k])),
    )
    .reduce((accumulator, value) => accumulator.concat(value), [])
    .reduce((accumulator, value) => accumulator.concat(value), []);
};

const allcombos = (
  reportTextureComposition: reports.ReportTextureCompositionResponse,
) => chooseThree(numberOfClusters(reportTextureComposition));

interface ReportProps {
  projectId?: number;
  reportId: string;
  useTextureClusterName?: boolean;
}

const ReportTextureTernary: React.FC<ReportProps> = (props) => {
  const { projectId, reportId, useTextureClusterName } = props;

  const { t } = useTranslation();
  const customProductNames = useCustomProductNames({ projectId, reportId });
  const [explainerModalOpen, setExplainerModalOpen] = useState<boolean>(false);

  const viewerUserId = useSelector((state) => selectViewerUserId(state));
  const workspaceId = useSelector((state) => selectWorkspaceProducerId(state));

  const customerPreferences = useCustomerPreferences();

  const [selectedTC1, setSelectedTC1] = useState<number>(1);
  const [selectedTC2, setSelectedTC2] = useState<number>(2);
  const [selectedTC3, setSelectedTC3] = useState<number>(3);

  useEffect(() => {
    return () => {
      setSelectedTC1(1);
      setSelectedTC2(2);
      setSelectedTC3(3);
    };
  }, [reportId]);

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    data: reportSummary,
    productVersionSet,
  } = useReportSummary(reportId);

  const {
    loading: reportTextureCompositionLoading,
    error: reportTextureCompositionError,
    data: reportTextureComposition,
  } = useQuery<reports.ReportTextureCompositionResponse>(
    ReportTextureCompPrefQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: reportTernaryLoading,
    error: reportTernaryError,
    data: reportTernaryData,
  } = useQuery<reports.ReportTextureTernaryHeatmapResponse>(
    RpTextureTernaryHeatmapQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    data: tcNameData,
    loading: tcNameLoading,
    error: tcNameError,
  } = useQuery<reports.ReportTextureClusterNamesResponse>(
    textureClusterNamesQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  if (
    reportSummaryLoading ||
    reportSummaryError ||
    reportTextureCompositionLoading ||
    reportTextureCompositionError ||
    reportTernaryLoading ||
    reportTernaryError
  ) {
    return <LoadingScreen />;
  }

  const getTCNameByIndex = (index: number) =>
    tcNameData.allRpTextureClusterNames.nodes.find(
      (x) => x.clusterIdx === index,
    )?.clusterName;

  const getReshapedData = (
    productIds: ProductVersionSet,
    reportTextureCompositionResponse: reports.ReportTextureCompositionResponse,
  ): object[] => {
    return chain(
      reportTextureCompositionResponse?.allRpTextureCompositions?.nodes,
    )
      .groupBy('productByProductId.id')
      .map((value, key) =>
        value.reduce(
          (acc, cur) => ({
            ...acc,
            selected:
              !!cur.productByProductId &&
              productIds
                .getItems()
                .map((p) => p.productId)
                .includes(cur.productByProductId.id),
            productName: getProductName({
              productNames: customProductNames,
              productId: cur.productByProductId?.id,
              version: cur.version,
            }),
            productId: cur.productByProductId?.id,
            version: cur.version,
            [cur.clusterIdx]: cur.composition,
          }),
          {},
        ),
      )
      .value();
  };

  const getTernaryHeatmapData = (
    reportTextureComposition: reports.ReportTextureCompositionResponse,
    reportTernaryData: reports.ReportTextureTernaryHeatmapResponse,
    tc1: number,
    tc2: number,
    tc3: number,
  ): reports.AllRpTextureTernaryHeatmapsNodes[] => {
    const combo: number[] = [tc1, tc2, tc3].sort();
    const allCombos = allcombos(reportTextureComposition).map((v, idx) => ({
      v: v,
      idx: idx,
    }));
    const comboIdx = allCombos.find(
      (i) => i.v[0] === combo[0] && i.v[1] === combo[1] && i.v[2] === combo[2],
    )?.idx;

    if (comboIdx === null) {
      return [];
    } else {
      return reportTernaryData.allRpTextureTernaryHeatmaps.nodes.filter(
        (r) => r.clusterCombo === comboIdx + 1,
      );
    }
  };

  if (
    getReshapedData(productVersionSet, reportTextureComposition).length === 0
  ) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        Not enough clusters to render!
      </Box>
    );
  }

  if (
    getTernaryHeatmapData(
      reportTextureComposition,
      reportTernaryData,
      selectedTC1,
      selectedTC2,
      selectedTC3,
    ).length === 0
  ) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        Not enough data to render ternary!
      </Box>
    );
  }

  return (
    <div className={styles.ternaryWithPicker}>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <div className={styles.selectView}>
            <div className={styles.select}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="tc1-picker-select-label" shrink>
                  {t('reports.textureTernary.firstTC')}
                </InputLabel>
                <Select
                  variant="standard"
                  id="tc1-picker-select"
                  value={selectedTC1}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSelectedTC1(parseInt(e.target.value));
                  }}
                >
                  {Array.from(
                    new Set(
                      allcombos(reportTextureComposition)
                        .filter((combo) => combo[1] === selectedTC2)
                        .filter((combo) => combo[2] === selectedTC3)
                        .map((combo) => combo[0]),
                    ),
                  ).map((combo) => (
                    <MenuItem key={`tc1-picker-${combo}`} value={combo}>
                      {useTextureClusterName
                        ? getTCNameByIndex(combo) ?? combo
                        : combo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={styles.select}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="tc2-picker-select-label" shrink>
                  {t('reports.textureTernary.secondTC')}
                </InputLabel>
                <Select
                  variant="standard"
                  id="tc2-picker-select"
                  value={selectedTC2}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSelectedTC2(parseInt(e.target.value));
                  }}
                >
                  {Array.from(
                    new Set(
                      allcombos(reportTextureComposition)
                        .filter((combo) => combo[0] === selectedTC1)
                        .filter((combo) => combo[2] === selectedTC3)
                        .map((combo) => combo[1]),
                    ),
                  ).map((combo) => (
                    <MenuItem key={`tc2-picker-${combo}`} value={combo}>
                      {useTextureClusterName
                        ? getTCNameByIndex(combo) ?? combo
                        : combo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={styles.select}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="tc3-picker-select-label" shrink>
                  {t('reports.textureTernary.thirdTC')}
                </InputLabel>
                <Select
                  variant="standard"
                  id="tc3-picker-select"
                  value={selectedTC3}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSelectedTC3(parseInt(e.target.value));
                  }}
                >
                  {Array.from(
                    new Set(
                      allcombos(reportTextureComposition)
                        .filter((combo) => combo[0] === selectedTC1)
                        .filter((combo) => combo[1] === selectedTC2)
                        .map((combo) => combo[2]),
                    ),
                  ).map((combo) => (
                    <MenuItem key={`tc3-picker-${combo}`} value={combo}>
                      {useTextureClusterName
                        ? getTCNameByIndex(combo) ?? combo
                        : combo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.selectView}>
            <TextureTernaryExplainer
              explainerModalOpen={explainerModalOpen}
              setExplainerModalOpen={setExplainerModalOpen}
            />
          </div>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xs={10}>
          {allcombos(reportTextureComposition).length > 0 && (
            <TextureTernaryPlot
              customerPreferences={customerPreferences}
              productVersionSet={productVersionSet}
              reportTextureComposition={getReshapedData(
                productVersionSet,
                reportTextureComposition,
              )}
              reportTernaryData={getTernaryHeatmapData(
                reportTextureComposition,
                reportTernaryData,
                selectedTC1,
                selectedTC2,
                selectedTC3,
              )}
              cluster_X={selectedTC1}
              cluster_Y={selectedTC2}
              cluster_Z={selectedTC3}
              useTextureClusterName={useTextureClusterName}
              tcNameData={tcNameData.allRpTextureClusterNames.nodes}
            />
          )}
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </div>
  );
};

export default ReportTextureTernary;
