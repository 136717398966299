import * as React from 'react';
import { ReactElement } from 'react';

import { useTheme } from '@mui/material';

import useStyles from './useStyles';
import { APP_ROOT } from '../../constants/routePaths';

interface Props {
  renderLeftItem: () => ReactElement;
  renderViewerMenu: () => ReactElement;
}

const AppHeader: React.FunctionComponent<Props> = ({
  renderLeftItem,
  renderViewerMenu,

  ...rest
}) => {
  const classes = useStyles(useTheme());

  return (
    <div className={classes.root}>
      <div className={classes.leftItemWrapper}>{renderLeftItem()}</div>
      <div className={classes.viewerMenuWrapper}>{renderViewerMenu()}</div>
    </div>
  );
};

const noOp = () => null;

AppHeader.defaultProps = {
  renderLeftItem: () => (
    <a className="logo" href={APP_ROOT}>
      Gastrograph
    </a>
  ),
  renderViewerMenu: noOp,
};
AppHeader.displayName = 'AppHeader';

export default AppHeader;
