import * as React from 'react';

import { useQuery } from '@apollo/client';
import AllProductImagesDownloadQuery from '@graphql/queries/AllProductImagesDownloadQuery';
import { Box } from '@mui/material';
import { ProductVersionSet } from 'components/Report/ProductVersion';
import { getProduct } from 'components/Report/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { failsafeFilename } from 'utils/afsUtils';

import DataDownloadTile from '../DataDownloadTile';
import { TileType } from '../DataDownloadTile/DataDownloadTile';

interface Props {
  projectId: number;
  projectName: string;
  reportId: string;
  products: ProductVersionSet;
}

const AllProductImageDownload: React.FC<Props> = (props) => {
  const { projectId, projectName, products } = props;

  const customProductNames = useCustomProductNames({
    projectId: Number(projectId),
    refetchNames: true,
  });

  const { loading, error, data } =
    useQuery<reports.AllProductImagesDownloadQueryResponse>(
      AllProductImagesDownloadQuery,
      {
        variables: {
          productIds: products.getItems().map((p) => p.productId),
        },
      },
    );

  const reshapeData = (
    data: reports.AllProductImagesDownloadQueryResponse,
  ): reports.ProductImageData[] => {
    const prodImages = data?.allProductImages?.nodes;
    const nutImages = data?.allProductNutritionalInfoImages?.nodes;
    return [].concat(prodImages).concat(nutImages);
  };

  const getUrls = (
    data: reports.AllProductImagesDownloadQueryResponse,
  ): reports.ProductImageData[] => {
    const a = reshapeData(data)
      .map((image, idx) => {
        const product = products
          .getItems()
          .find((p) => p.productId === image.productId && p.version === null);

        if (product) {
          return {
            id: idx,
            productId: product.productId,
            productName: getProduct({
              productNames: customProductNames,
              productId: product.productId,
              version: product.version,
            })
              .map((p) => p.name)
              .orElse(null),
            url: image.url,
          };
        } else {
          return null;
        }
      })
      .filter((p) => p !== null && p.productName !== null);

    return a;
  };

  if (loading || error || getUrls(data).length === 0) {
    return <Box />;
  }

  return (
    <DataDownloadTile
      fileName={failsafeFilename(projectName)}
      tileType={TileType.ZIP}
      tileName="Download Product Images"
      urls={getUrls(data)}
    />
  );
};

export default AllProductImageDownload;
