import * as React from 'react';

import { useQuery } from '@apollo/client';
import ArchetypeDemographicsQuery from '@graphql/queries/ArchetypeDemographicsQuery';
import ReportByIdQuery from '@graphql/queries/ReportByIdQuery';
import { Box } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { isArchtypeReportId } from 'components/Report/utils';

import ReportDemographics from '../../../components/Report/ReportDemographics';
import ReportArchetypeDemographics from '../../../components/Report/ReportDemographics/ReportArchetypeDemographics';

interface Props {
  reportId: string;
}

const DemographicsGroup: React.FC<Props> = (props) => {
  const { reportId } = props;

  const {
    loading: reportJobLoading,
    error: reportJobError,
    data: reportJobData,
  } = useQuery<reports.ReportJobResults>(ReportByIdQuery, {
    variables: {
      reportId: reportId,
      orderBy: 'ID_DESC',
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const {
    loading: archetypeDemographicsLoading,
    error: archetypeDemographicsError,
    data: archetypeDemographicsData,
  } = useQuery<reports.ArchetypeDemographicsResults>(
    ArchetypeDemographicsQuery,
    {
      variables: {
        reportId: reportId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
  );

  if (archetypeDemographicsLoading || reportJobLoading) {
    return <LoadingScreen circular />;
  }

  return (
    <Box>
      {reportJobError ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          Error loading report demographics!
        </Box>
      ) : (
        <ReportDemographics
          data={reportJobData}
          isArchetypeReport={isArchtypeReportId(reportId)}
        />
      )}

      {archetypeDemographicsError ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          Error loading archetype demographics!
        </Box>
      ) : (
        <ReportArchetypeDemographics data={archetypeDemographicsData} />
      )}
    </Box>
  );
};

export default DemographicsGroup;
