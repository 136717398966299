import { gql } from '@apollo/client';

export interface AllWorkspacesQueryResponse {
  loading: boolean;
  error: any;
  allProducers: {
    nodes: AllWorkspacesQueryNode[];
    totalCount: number;
  };
}

export interface AllWorkspacesQueryNode {
  id: number;
  name: string;
  producerPartnerByProducerId: {
    partnerId: number;
    partnerByPartnerId: {
      name: string;
    };
  };
}

export default gql`
  query AllWorkspacesQuery {
    allProducers {
      nodes {
        id
        name
        producerPartnerByProducerId {
          partnerId
          partnerByPartnerId {
            name
          }
        }
      }
      totalCount
    }
  }
`;
