import * as React from 'react';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { cleanUnderscore } from 'utils/afsUtils';

import { PROJECT_FLAVOR_SUMMARY_CHART } from '../../../../../constants/routePaths';
import formatPath from '../../../../../utils/formatPath';
import styles from '../FlavorDecompositionTable.module.css';

interface Props {
  ggVar: constants.GGVar;
  data: reports.ReportFpDecompRow[];
  hasTuning: boolean;
  projectId: number;
  reportId: string;
  productId: number;
  version: string;
}

const FlavorDecompositionSubTable: React.FC<Props> = (props) => {
  const { ggVar, data, hasTuning, projectId, reportId, productId, version } =
    props;

  const { t } = useTranslation();

  const totalDecomp = data
    .map((r: reports.ReportFpDecompRow) => parseFloat(r.decomp))
    .reduce((a, b) => a + b, 0);
  const totalAbsDecomp = data
    .map((r: reports.ReportFpDecompRow) => parseFloat(r.absDecomp))
    .reduce((a, b) => a + b, 0);

  return (
    <Table>
      <TableHead>
        <TableRow className={styles.tableTopHeader}>
          <TableCell colSpan={2} align="center">
            <div className={styles.mainHeader}>
              {hasTuning && projectId && reportId && productId ? (
                <Link
                  to={{
                    pathname: formatPath(PROJECT_FLAVOR_SUMMARY_CHART, {
                      projectId,
                      reportId,
                      productId,
                      version,
                      ggVarKey: ggVar.key,
                    }),
                  }}
                >
                  <span className={styles.title} style={{ color: 'black' }}>
                    {ggVar.label}
                  </span>
                </Link>
              ) : (
                <span className={styles.title}>{ggVar.label}</span>
              )}
              <span className={styles.title}>{` - ${(totalDecomp * 100).toFixed(
                0,
              )}% `}</span>
              <span className={styles.title}>{`(${totalAbsDecomp.toFixed(
                2,
              )})`}</span>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <span className={styles.subTitle}>
              {t('reports.fpDecomp.referenceFlavor')}
            </span>
          </TableCell>
          <TableCell>
            <span className={styles.subTitle}>
              {t('reports.fpDecomp.decomp')}
            </span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row) => (
          <TableRow key={row.ggVars + row.referenceFlavor}>
            <TableCell align="left">
              {hasTuning && projectId && reportId && productId ? (
                <Link
                  to={{
                    pathname: formatPath(PROJECT_FLAVOR_SUMMARY_CHART, {
                      projectId,
                      reportId,
                      productId,
                      version,
                      ggVarKey: ggVar.key,
                    }),
                  }}
                >
                  <span style={{ color: 'black' }}>
                    {cleanUnderscore(row.referenceFlavor)}
                  </span>
                </Link>
              ) : (
                <span>{cleanUnderscore(row.referenceFlavor)}</span>
              )}
            </TableCell>
            <TableCell align="center">{`${(
              parseFloat(row.decomp.toString()) * 100
            ).toFixed(0)}% (${parseFloat(row.absDecomp.toString()).toFixed(
              2,
            )})`}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default FlavorDecompositionSubTable;
