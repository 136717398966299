import * as React from 'react';

import { Grid } from '@mui/material';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { useTranslation } from 'react-i18next';

import ProductSummaryTable from './ProductSummaryTable';
import ReportProductMarketPerference from '../ProductMarketPreference/ReportProductMarketPreferences/ReportProductMarketPerference';
import GastrographRadar from '../ReportGastrographRadar/ReportGastrographRadar';
import { getProductName } from '../utils';

interface ReportProps {
  projectId?: number;
  reportId: string;
  productId: number;
  productVersion: string;
}

const ReportProductDetails: React.FC<ReportProps> = (props) => {
  const { projectId, reportId, productId, productVersion } = props;

  const { t } = useTranslation();

  const productNames = useCustomProductNames({
    productId,
    projectId,
    version: productVersion,
  });
  const productName = getProductName({
    productNames,
    productId,
    version: productVersion,
  });

  return (
    <Grid key={productId} container justifyContent="center">
      <Grid item xs={12}>
        <h4>{productName}</h4>
      </Grid>
      <Grid item xs={7}>
        <div style={{ marginTop: '30px' }}>
          <GastrographRadar
            title={t('reports.reportProductDetails.ofp')}
            titleCenter={true}
            reportId={reportId}
            productId={productId}
            productVersion={productVersion}
            productDisplayName={productName}
          />
        </div>
      </Grid>
      <Grid item xs={5}>
        <ReportProductMarketPerference
          title={t('reports.reportProductDetails.predMarketPref')}
          titleCenter={true}
          reportId={reportId}
          productId={productId}
          productVersion={productVersion}
        />
        <ProductSummaryTable
          reportId={reportId}
          productId={productId}
          version={productVersion}
        />
      </Grid>
    </Grid>
  );
};

export default ReportProductDetails;
