export default function httpEditUserRoles(
  userId: number,
  roles: string,
  token: string,
) {
  return fetch('/iam/updateUserRoles', {
    method: 'POST',
    headers: {
      Authorization: token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId: userId,
      roles: roles,
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then((message) => {
        throw new Error(message);
      });
    }
  });
}
