import * as React from 'react';

import { useLazyQuery } from '@apollo/client';
import GetReportDownloadUrl from '@graphql/queries/GetReportDownloadUrl';
import { PictureAsPdf, CloudDownload } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export function downloadPDF(event: React.MouseEvent, url) {
  // from https://codepen.io/Alexander9111/pen/VwLaaPe?editors=1010
  //get svg element.
  const a = document.createElement('a');
  const e = new MouseEvent('click');
  a.download = 'download.pdf';
  a.href = url;
  a.dispatchEvent(e);
}

interface ReportProps {
  reportId: string;
  pdfIcon?: boolean;
}

const ReportDownloadIcon: React.FC<ReportProps> = (props) => {
  const { reportId, pdfIcon = false } = props;
  const [reportAvailable, setReportAvailable] = React.useState<boolean | null>(
    null,
  );

  const [getReport] = useLazyQuery(GetReportDownloadUrl, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    context: {
      fetchOptions: {
        headers: {
          'Cache-Control': 'no-store',
        },
      },
    },
  });

  React.useEffect(() => {
    const checkReportAvailability = async () => {
      if (reportId) {
        const reportUrl = await getReport({
          variables: {
            reportId: reportId,
          },
        });

        try {
          const response = await fetch(reportUrl.data.reportPdfUrl, {
            method: 'GET',
            headers: {
              Range: 'bytes=0-0',
            },
          });
          setReportAvailable(response.ok && response.status === 206);
        } catch (error) {
          setReportAvailable(false);
        }
      }
    };

    checkReportAvailability();
  }, [reportId, getReport]);

  const handleClick = async (e: React.MouseEvent, reportId: string) => {
    const reportUrl = await getReport({
      variables: {
        reportId: reportId,
      },
    });
    downloadPDF(e, reportUrl.data.reportPdfUrl);
  };

  if (reportId === null || reportAvailable === null) {
    return null;
  }

  return (
    <IconButton
      id="pdf-report-link"
      size="small"
      onClick={(e) => handleClick(e, reportId)}
      disabled={!reportAvailable}
    >
      {pdfIcon ? (
        <PictureAsPdf
          fontSize="medium"
          color={reportAvailable ? 'primary' : 'disabled'}
        />
      ) : (
        <CloudDownload
          fontSize="medium"
          color={reportAvailable ? 'primary' : 'disabled'}
        />
      )}
    </IconButton>
  );
};

export default ReportDownloadIcon;
