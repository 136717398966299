import { gql } from '@apollo/client';

export default gql`
  query getWorkspacesByProducerUser($userId: Int!) {
    allProducerUsers(condition: { userId: $userId }) {
      nodes {
        producerByProducerId {
          id
          name
        }
      }
    }
  }
`;
