import { gql } from '@apollo/client';

export interface ProductTimeSeriesResponse {
  loading: boolean;
  error: any;
  allRpProductTimeseries: {
    nodes: ProductTimeSeriesRow[];
  };
}

export interface ProductTimeSeriesRow {
  id: number;
  reportId: string;
  productId: number;
  productByProductId: {
    id: number;
    name: string;
  };
  year: number;
  pq: number;
  error: number;
  version: string;
  isFuture: boolean;
}

export default gql`
  query ProductTimeSeriesQuery($reportID: UUID) {
    allRpProductTimeseries(condition: { reportId: $reportID }) {
      nodes {
        id
        reportId
        productId
        productByProductId {
          id
          name
        }
        version
        year
        pq
        error
        isFuture
      }
    }
  }
`;
