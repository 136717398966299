import { gql } from '@apollo/client';

export default gql`
  mutation UploadProductImageMutation($image: UploadProductImageInput!) {
    uploadProductImage(input: $image) {
      id
      url
    }
  }
`;
