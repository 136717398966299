import * as React from 'react';

import { SvgIcon } from '@mui/material';

// Generated with https://react-svgr.com/playground/
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1600}
    height={1600}
    viewBox="0 0 1200 1200"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M150 112.5c18.41 0 33.719 13.266 36.895 30.758L187.5 150l-.05 457.49c27.047-28.52 59.984-45.762 96.383-49.402 59.035-5.902 91.52 15.914 139.52 75.477l25.434 31.926c25.68 30.81 40.082 40.117 60.328 40.117 34.12 0 63.879-42.93 114.44-163.34L660.8 452.17c60.656-142.09 98.703-189.67 175.37-189.67 107.05 0 204.47 148.03 250.6 379.13 4.054 20.312-9.121 40.062-29.434 44.117-20.31 4.055-40.06-9.125-44.113-29.434-39.86-199.68-118.26-318.81-177.05-318.81-35.672 0-66.574 46.918-121.47 180.24l-22.824 55.688c-65.066 155.71-103.03 207.18-182.76 207.18-52.098 0-80.945-20.922-125.06-75.805l-10.09-12.78c-38.43-49.43-54.86-62.087-82.672-59.306-54.523 5.453-103.79 78.98-103.79 206.62V975c0 18.41 13.266 33.72 30.758 36.895l6.742.606h825c20.71 0 37.5 16.789 37.5 37.5 0 18.41-13.266 33.719-30.758 36.895l-6.742.605h-825c-58.477 0-106.54-44.617-111.98-101.66l-.516-10.836v-825c0-20.71 16.79-37.5 37.5-37.5z"
    />
  </svg>
);

const SingleLineChartIcon = (props) => (
  <SvgIcon component={SvgComponent} viewBox="0 0 1200 1200" {...props} />
);

export default SingleLineChartIcon;
