import * as React from 'react';
import { useEffect, useState } from 'react';

import { Search } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Card,
  CardContent,
  FormGroup,
  Grid,
  IconButton,
  useTheme,
} from '@mui/material';
import { Input, InputAdornment } from '@mui/material';
import { lineBreak } from 'components/ReactDataTable/reactDataTableUtils';
import { getReportParam } from 'components/Report/ReportDemographics/utils';
import { format, parseISO } from 'date-fns';
import { Field } from 'formik';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Optional } from 'typescript-optional';

import styles from './ProjectCreate.module.css';
import FormikTextField from '../../components/FormikTextInput';
import MaterialButton from '../../components/MaterialButton';
import ProjectSearch from '../../components/ProjectSearch';
import { tableCustomStyles } from '../../components/ReactDataTable/tableCustomStyles';
import { getCountryWithEmoji } from '../../constants/country';
import { formatReportType } from '../../constants/report';
import { formatReportLabel } from '../../containers/Project/utils';
import i18n from '../../i18n';

interface TableRow extends reports.AllReportsQueryRow {
  id: number;
  reportId: string;
  projectName: string;
  reportTypeDisplay: string;
  demographic: string;
  user: string;
  date: Date;
  action: any;
}

interface Props {
  handleSubmit: () => void;
  values: any;
  workspaceReports: reports.AllReportsQueryRow[];
  setFieldValue: (field: string, value: any) => void;
  errors: any;
  isValid: boolean;
  isSubmitting: boolean;
  producerId: number;
  editing: boolean;
  editProject: boolean;
  dirty: boolean;
}

function enrich(
  report: reports.AllReportsQueryRow,
): reports.EnrichedAllReportsQueryRow {
  return {
    ...report,
    reportTypeDisplay: formatReportType(report.reportType),
    date: parseISO(report.startedAt),
    demographic: getCountryWithEmoji(
      getReportParam<string | string[]>(
        Optional.of(report.params),
        'countries',
      ),
    ),
    user: report.requestedThrough,
  };
}

const ProjectCreateForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    values,
    workspaceReports,
    setFieldValue,
    errors,
    isValid,
    isSubmitting,
    producerId,
    editing,
    editProject,
    dirty,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState<string>('');
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [reportsData, setReportsData] = useState<
    reports.EnrichedAllReportsQueryRow[]
  >(workspaceReports.map(enrich));
  const [selectedReports, setSelectedReports] = useState<
    reports.EnrichedAllReportsQueryRow[]
  >([]);

  useEffect(() => {
    if (values && values.projectReports && values.projectReports.length) {
      const includedIds = new Set(
        values.projectReports.map((report) => report.reportId),
      );
      setSelectedReports(
        workspaceReports
          .map(enrich)
          .filter((report) => includedIds.has(report.reportId)),
      );
    }
  }, []);

  const columns: TableColumn<TableRow>[] = [
    {
      selector: (row: TableRow) => row.id,
      name: 'Id',
      sortable: true,
      omit: true,
    },
    {
      selector: (row: TableRow) => row.reportId,
      name: 'reportId',
      sortable: true,
      omit: true,
    },
    {
      selector: (row: TableRow) => row.projectName,
      name: i18n.t('panel.projectName'),
      sortable: true,
      cell: (row: TableRow) => lineBreak(row.projectName),
    },
    {
      selector: (row: TableRow) => row.reportTypeDisplay,
      name: i18n.t('reports.reportType'),
    },
    {
      selector: (row: TableRow) => row.demographic,
      name: i18n.t('reports.targetGroup'),
    },
    {
      selector: (row: TableRow) => row.user,
      name: i18n.t('reports.submitter'),
    },
    {
      selector: (row: TableRow) => format(row.date, 'LLL d, y'),
      name: i18n.t('reports.submittedOn'),
      cell: (row: TableRow) => format(row.date, 'LLL d, y'),
      sortable: true,
    },
    {
      name: t('general.action'),
      cell: (row: TableRow) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="small"
            onClick={() => removeFromReportList(selectedReports, row)}
          >
            <CancelIcon
              style={{ marginLeft: -10, color: 'red' }}
              fontSize="small"
            />
          </IconButton>
        </div>
      ),
    },
  ];

  const filter = (
    workspaceReports: reports.EnrichedAllReportsQueryRow[],
    term: string,
  ) => {
    setReportsData(
      workspaceReports.filter((report: reports.EnrichedAllReportsQueryRow) => {
        const values = Object.values(report);
        return values.some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(term.trim().toLowerCase()),
        );
      }),
    );
  };

  const formatReport = (report: reports.AllReportsQueryRow) => {
    return {
      label: formatReportLabel(
        report.projectName,
        report.targetGroupName,
        report.reportType,
        report.startedAt,
      ),
      id: report.id.toString(),
      reportId: report.reportId.toString(),
    };
  };

  const addToReportList = (
    selectedReports: reports.EnrichedAllReportsQueryRow[],
    report: reports.EnrichedAllReportsQueryRow,
  ) => {
    const list = [...selectedReports, report];
    setSelectedReports(list);
    setFieldValue(
      'projectReports',
      list.map((report) => formatReport(report)),
    );
  };

  const removeFromReportList = (
    selectedReports: reports.EnrichedAllReportsQueryRow[],
    report: reports.EnrichedAllReportsQueryRow,
  ) => {
    const list = selectedReports.filter((rep) => rep.id !== report.id);
    setSelectedReports(list);
    setFieldValue(
      'projectReports',
      list.map((report) => formatReport(report)),
    );
  };

  const addOrRemoveReport = (
    selectedReports: reports.EnrichedAllReportsQueryRow[],
    report: reports.EnrichedAllReportsQueryRow,
  ) => {
    const existingReport = selectedReports.find((rep) => rep.id === report.id);
    existingReport
      ? removeFromReportList(selectedReports, report)
      : addToReportList(selectedReports, report);
  };

  const onSelectReport = (
    selectedReports: reports.EnrichedAllReportsQueryRow[],
    report: reports.EnrichedAllReportsQueryRow,
  ) => {
    addOrRemoveReport(selectedReports, report);
    setSearchString('');
  };

  const preFilteredReportList = (
    reportsData: reports.EnrichedAllReportsQueryRow[],
    selectedReports: reports.EnrichedAllReportsQueryRow[],
  ) => {
    const excludedIds = new Set(selectedReports.map((report) => report.id));
    return reportsData.filter((report) => !excludedIds.has(report.id));
  };

  const getSelectedReportsData = (
    selectedReports: reports.EnrichedAllReportsQueryRow[],
  ): TableRow[] => {
    return selectedReports.map((r) => ({
      ...r,
      action: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="small"
            onClick={() => removeFromReportList(selectedReports, r)}
          >
            <CancelIcon
              style={{ marginLeft: -10, color: 'red' }}
              fontSize="small"
            />
          </IconButton>
        </div>
      ),
    }));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card style={{ minHeight: '400px' }}>
          <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
            <FormGroup>
              <Field
                component={FormikTextField}
                name="name"
                label={t('project.name')}
              />
              <Input
                style={{ paddingTop: 20, marginLeft: 50, marginRight: 50 }}
                value={searchString}
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
                onChange={(event) => {
                  filter(workspaceReports.map(enrich), event.target.value);
                  setSearchString(event.target.value);
                  setShowSearch(true);
                }}
                placeholder={t('general.search')}
              />
              {searchString && searchString.length > 0 && showSearch && (
                <ProjectSearch
                  workspaceReports={preFilteredReportList(
                    reportsData,
                    selectedReports,
                  ).map((report) => ({
                    ...report,
                    reportTypeDisplay: formatReportType(report.reportType),
                    date: parseISO(report.startedAt),
                    demographic: getCountryWithEmoji(
                      getReportParam<string | string[]>(
                        Optional.of(report.params),
                        'countries',
                      ),
                    ),
                    user: report.requestedThrough,
                  }))}
                  hideSearch={() => setShowSearch(false)}
                  onClick={(report) => onSelectReport(selectedReports, report)}
                />
              )}
            </FormGroup>
          </CardContent>
          <div style={{ paddingTop: 20 }}>
            <DataTable
              columns={columns}
              data={getSelectedReportsData(selectedReports)}
              customStyles={tableCustomStyles(theme, true)}
              striped
              persistTableHead={true}
            />
          </div>
        </Card>
        <div className={styles.buttonContainer}>
          {selectedReports.length == 0 && (
            <MaterialButton
              onClick={() => {
                setFieldValue('isDelete', true);
                handleSubmit();
              }}
              disabled={isSubmitting || (!isValid && dirty)}
              variant="outlined"
              color="secondary"
              soft
            >
              Delete
            </MaterialButton>
          )}
          <MaterialButton
            onClick={() => handleSubmit()}
            disabled={isSubmitting || (!isValid && dirty)}
            variant="outlined"
            soft
            teal
          >
            Submit
          </MaterialButton>
        </div>
      </Grid>
    </Grid>
  );
};

export default ProjectCreateForm;
