import * as React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import ReportFlavorStructure from 'components/Report/ReportFlavorStructure';

interface Props {
  showFSDModal: boolean;
  setShowFSDModal: (value: boolean) => void;
  reportId: string;
  productId: number;
  productName: string;
}

const FlavorStructureDiagramModal: React.FC<Props> = (props) => {
  const { showFSDModal, setShowFSDModal, reportId, productId, productName } =
    props;

  return (
    <Dialog open={showFSDModal} onClose={() => setShowFSDModal(false)}>
      <DialogTitle textAlign="center">{productName}</DialogTitle>
      <ReportFlavorStructure reportId={reportId} productId={productId} />
    </Dialog>
  );
};

export default FlavorStructureDiagramModal;
