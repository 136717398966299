import * as React from 'react';
import { useRef, useEffect } from 'react';

import * as d3 from 'd3';

import { wrap } from 'components/Report/utils';

import { NoveltyPairedPreferenceComparison, biggestDiff } from './utils';
import { appendNoveltyLegend, colorScale } from '../NoveltyScale/NoveltyScale';

interface Props {
  noveltyPairedPreferenceComparisonArray: NoveltyPairedPreferenceComparison[];
}

const PairedProductNoveltyComparison: React.FC<Props> = (props) => {
  const { noveltyPairedPreferenceComparisonArray } = props;

  const comparisonArrayFormated = noveltyPairedPreferenceComparisonArray.map(
    (d) => ({
      ...d,
      nameVersion1: d.product1,
      nameVersion2: d.product2,
    }),
  );

  const svgRef = useRef(null);

  const longestName = comparisonArrayFormated
    .map((d) => d.nameVersion1.length)
    .reduce((a, b) => (a > b ? a : b), 0);

  const productNameVersions = [...comparisonArrayFormated]
    .filter(
      (i) =>
        i.nameVersion1 === biggestDiff(noveltyPairedPreferenceComparisonArray),
    )
    .sort((a, b) => a.noveltyValue - b.noveltyValue)
    .map((i) => i.nameVersion2)
    .concat(biggestDiff(noveltyPairedPreferenceComparisonArray))
    .reverse();

  useEffect(() => {
    const scaleHeight = 75;
    const itemsCount = Math.sqrt(noveltyPairedPreferenceComparisonArray.length);
    const maxVal = Math.max(
      ...comparisonArrayFormated.map((n) => n.noveltyValue),
    );

    // D3 Code
    const yScale = d3
      .scaleBand()
      .range([0, scaleHeight * 7])
      .domain(productNameVersions);

    const xScale = d3
      .scaleBand()
      .range([0, scaleHeight * 7])
      .domain(productNameVersions);

    const margin = {
      top: yScale.bandwidth() + scaleHeight,
      bottom: yScale.bandwidth(),
      left: xScale.bandwidth(),
      right: xScale.bandwidth(),
    };

    const svg = d3
      .select(svgRef.current)
      .classed('novelty-product-paired', true)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr(
        'viewBox',
        `0 0 ${scaleHeight * 7 + margin.left + margin.right} ${scaleHeight * 7 + margin.top + margin.bottom}`,
      );

    // clear all previous content on refresh
    const everything = svg.selectAll('*');
    everything.remove();

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    g.selectAll('rect')
      .data(comparisonArrayFormated)
      .enter()
      .append('rect')
      .attr('y', (d) => yScale(d.nameVersion1))
      .attr('x', (d) => xScale(d.nameVersion2))
      .attr('height', yScale.bandwidth())
      .attr('width', xScale.bandwidth())
      .attr('fill', (d) => colorScale(1 - d.noveltyValue / maxVal));

    g.selectAll('.percentLabel')
      .data(comparisonArrayFormated)
      .enter()
      .append('text')
      .attr('class', 'percentLabel')
      .attr('y', (d) => yScale(d.nameVersion1) + yScale.bandwidth() / 2)
      .attr('x', (d) => xScale(d.nameVersion2) + xScale.bandwidth() / 2)
      .attr('fill', 'white')
      .style('text-anchor', 'middle')
      .style('dominant-baseline', 'central')
      .style('font-size', yScale.bandwidth() / 4 + 'px')
      .style('text-shadow', '2px 2px 4px #000000')
      .text((d) => (d.noveltyValue == 0 ? '' : d.noveltyValue.toFixed(2)));

    const labelPad = 10;
    const labelFontSize = Math.min(
      (scaleHeight * 7) / noveltyPairedPreferenceComparisonArray.length,
      longestName / 3,
    );

    const xAxis = d3.axisTop(xScale);

    svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'middle')
      .style('font-size', `${labelFontSize}px`)
      .call(
        wrap,
        yScale.bandwidth() - labelPad,
        'top',
        labelFontSize,
        0,
        labelPad,
      );

    const yAxis = d3.axisLeft(yScale);

    svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .call(yAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .style('font-size', `${labelFontSize.toFixed(0)}px`)
      .attr('transform', `translate(-${labelPad},0)`)
      .call(
        wrap,
        yScale.bandwidth() - labelPad,
        'left',
        labelFontSize,
        labelPad,
        0,
      );

    appendNoveltyLegend(
      svg,
      scaleHeight * 7 + margin.left + margin.right,
      scaleHeight,
    );
  }, [noveltyPairedPreferenceComparisonArray]); // redraw chart if data changes

  const divStyle: React.CSSProperties = {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    verticalAlign: 'top',
    overflow: 'hidden',
  };

  return (
    <div style={divStyle}>
      {noveltyPairedPreferenceComparisonArray.length > 0 && (
        <svg ref={svgRef} />
      )}
    </div>
  );
};

export default PairedProductNoveltyComparison;
