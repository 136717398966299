import { gql } from '@apollo/client';

export default gql`
  query ReportRefFlavorTuningPlot(
    $reportID: UUID!
    $productID: Int
    $ggVar: String
  ) {
    allRpRefFlavorDecomps(
      condition: { reportId: $reportID, productId: $productID, ggvar: $ggVar }
    ) {
      nodes {
        reportId
        productId
        version
        ggvar
        referenceFlavor
        currentPq
        decompJsonb
        absDecompJsonb
      }
    }
  }
`;
