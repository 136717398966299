import * as React from 'react';
import { ReactElement } from 'react';

import styles from './ReportSummaryModal.module.css';

interface Props {
  open: boolean;
  title?: string;
  children: ReactElement;
}

const ReportSummaryModal: React.FC<Props> = ({ open, title, children }) => {
  if (!open) {
    return null;
  }

  return (
    <div className={styles.shadow}>
      <div className={styles.modalContainer}>
        <h1>{title}</h1>
        <div className={styles.contentContainer}>{children}</div>
      </div>
    </div>
  );
};

export default ReportSummaryModal;
