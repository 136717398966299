import { PRODUCTS, PROJECTS } from 'constants/routePaths';

import { analysisViewRole, panelManageRole } from '../constants/userRoles';

export function entryPath(userRoles: Set<string>): string {
  // Safety check the roles
  const safeUserRoles = userRoles == null ? new Set<string>() : userRoles;

  if (safeUserRoles.has(panelManageRole.toLocaleLowerCase())) return PRODUCTS;
  if (safeUserRoles.has(analysisViewRole.toLocaleLowerCase())) return PROJECTS;

  return PRODUCTS;
}
