import * as React from 'react';

interface Props {
  divWidth: number;
  onClick?: () => void;
}

const topBottomWidth = (divWidth: number) => (divWidth / 5) * 0.8;
const topBottomHeight = (divWidth: number) => (divWidth / 7) * 0.8;
const leftRightWidth = (divWidth: number) => (divWidth / 6.5) * 0.8;

const fontSize = (divWidth: number) => Math.floor(divWidth / 70);

export const TopTriangle: React.FC<Props> = ({ divWidth, onClick }) => {
  return (
    <div
      id="topTriangle"
      style={{
        display: 'flex',
        paddingBottom: 5,
        justifyContent: 'center',
        alignItems: 'flex-end',
        color: 'white',
      }}
      onClick={onClick}
    >
      <div
        style={{
          width: '0',
          height: '0',
          borderLeft: `${topBottomWidth(divWidth) / 2}px solid transparent`,
          borderRight: `${topBottomWidth(divWidth) / 2}px solid transparent`,
          borderBottom: `${topBottomHeight(divWidth)}px solid grey`,
          top: '125px',
          left: '250px',
        }}
      >
        <span
          style={{
            position: 'relative',
            top: `${topBottomHeight(divWidth) - 3 * fontSize(divWidth)}px`,
            width: `${topBottomWidth(divWidth) / 2}px`,
            left: `${topBottomWidth(divWidth) / -4}px`,
            textAlign: 'center',
            display: 'block',
            fontSize: fontSize(divWidth),
          }}
        >
          Increase Intensity
        </span>
      </div>
    </div>
  );
};

export const BottomTriangle: React.FC<Props> = ({ divWidth, onClick }) => (
  <div
    id="bottomTriangle"
    style={{
      display: 'flex',
      paddingTop: 5,
      justifyContent: 'center',
      alignItems: 'flex-start',
      color: 'white',
    }}
    onClick={onClick}
  >
    <div
      style={{
        width: '0',
        height: '0',
        borderLeft: `${topBottomWidth(divWidth) / 2}px solid transparent`,
        borderRight: `${topBottomWidth(divWidth) / 2}px solid transparent`,
        borderTop: `${topBottomHeight(divWidth)}px solid grey`,
        top: '-25px',
        left: '250px',
      }}
    >
      <span
        style={{
          position: 'relative',
          top: `${topBottomWidth(divWidth) / -1.5}px`,
          width: `${topBottomWidth(divWidth) / 2}px`,
          left: `${topBottomWidth(divWidth) / -4}px`,
          textAlign: 'center',
          display: 'block',
          fontSize: fontSize(divWidth),
        }}
      >
        Decrease Intensity
      </span>
    </div>
  </div>
);

export const LeftTriangle: React.FC<Props> = ({ divWidth, onClick }) => (
  <div
    id="leftTriangle"
    style={{
      display: 'flex',
      paddingRight: 5,
      justifyContent: 'flex-end',
      alignItems: 'center',
      color: 'white',
    }}
    onClick={onClick}
  >
    <div
      style={{
        width: '0',
        height: '0',
        borderTop: `${(divWidth / 12) * 0.8}px solid transparent`,
        borderBottom: `${(divWidth / 10) * 0.8}px solid transparent`,
        borderRight: `${(divWidth / 6.5) * 0.8}px solid grey`,
        top: '25px',
        left: '300px',
      }}
    >
      <span
        style={{
          position: 'relative',
          top: `${-(fontSize(divWidth) * 1.5)}px`,
          width: `${leftRightWidth(divWidth) / 2}px`,
          left: `${leftRightWidth(divWidth) / 2 - 15}px`,
          textAlign: 'center',
          display: 'block',
          fontSize: fontSize(divWidth),
        }}
      >
        Decrease PQ
      </span>
    </div>
  </div>
);

export const RightTriangle: React.FC<Props> = ({ divWidth, onClick }) => (
  <div
    id="rightTriangle"
    style={{
      display: 'flex',
      flex: 1,
      paddingLeft: 5,
      justifyContent: 'flex-start',
      alignItems: 'center',
      color: 'white',
    }}
    onClick={onClick}
  >
    <div
      style={{
        width: '0',
        height: '0',
        borderTop: `${(divWidth / 12) * 0.8}px solid transparent`,
        borderBottom: `${(divWidth / 10) * 0.8}px solid transparent`,
        borderLeft: `${(divWidth / 6.5) * 0.8}px solid grey`,
        top: `${divWidth / 30}px`, //"25px",
        left: '200px',
      }}
    >
      <span
        style={{
          position: 'relative',
          top: `${-(fontSize(divWidth) * 1.5)}px`,
          width: `${leftRightWidth(divWidth) / 2}px`,
          left: `${10 - leftRightWidth(divWidth)}px`,
          textAlign: 'center',
          display: 'block',
          fontSize: fontSize(divWidth),
        }}
      >
        Increase PQ
      </span>
    </div>
  </div>
);
