import React from 'react';

import { useTheme } from '@mui/material';

import useMainStyles from './useStyles';

type Props = {
  children: any
};

const LayoutSectionHeader = ({ children, ...rest }: Props) => {
  const { useStyles } = useMainStyles({ ...rest });
  const classes = useStyles(useTheme());
  return <div className={classes.container}>{children}</div>;
};

LayoutSectionHeader.displayName = 'LayoutSectionHeader';

export default LayoutSectionHeader;
