import { gql } from '@apollo/client';

export default gql`
  query pqPredictionInputQuery(
    $reportID: UUID
    $productId: Int
    $version: String
  ) {
    allRpOfps(
      condition: {
        reportId: $reportID
        productId: $productId
        version: $version
      }
    ) {
      nodes {
        attribute
        intensity
      }
    }
  }
`;
