import * as React from 'react';

import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import { useTheme } from '@mui/material';
import clsx from 'clsx';

import useStyles from './useStyles';

interface Props {
  active?: boolean;
  completed?: boolean;
}

const StepperIcon: React.FC<Props> = (props) => {
  const { active, completed } = props;

  const classes = useStyles(useTheme());

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <RadioButtonChecked />
    </div>
  );
};

export default StepperIcon;
