import React from 'react';

import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import 'react-hot-loader';
import './i18n';
import ReactGA from 'react-ga4';

import { APPVERSION } from './constants/appVersion';
import { TRACKING_ID } from './constants/googleAnalytics';
import RootElement from './root';

import './index.css';

Sentry.init({
  dsn: 'https://120eeda110744570b28b98e085d32acc@sentry.io/1230000',
  release: APPVERSION,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV !== 'development',
});

const { initialize } = ReactGA;

initialize(TRACKING_ID, {
  debug: process.env.NODE_ENV !== 'production',
  titleCase: false,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<RootElement />);

if (module.hot) {
  module.hot.accept();
}
