import {
  ReferenceFlavorTranslationsByReferenceFlavorId,
  WorkspaceReferenceFlavorForUpdate,
} from '@graphql/queries/GetReferenceFlavorForUpdateQuery';
import { groupBy } from 'lodash';

export interface TranslationType {
  id: number;
  flavorAttribute: string;
  value: string;
  visible: boolean;
  deleted: boolean;
  referenceFlavorId: number;
  referenceFlavorTransId: number;
  translations: {
    [x: string]: string;
  };
}

export const mapItemsByLanguage = (
  nodes: ReferenceFlavorTranslationsByReferenceFlavorId[],
) => {
  const languageMap = new Map();
  nodes.forEach((item) => {
    languageMap.set('referenceFlavorId', item.referenceFlavorId);
    languageMap.set('referenceFlavorTransId', item.id);
    languageMap.set(item.language, item.translation);
  });
  return Object.fromEntries(languageMap);
};

export const formatReferenceFlavorsList = (
  nodes: WorkspaceReferenceFlavorForUpdate[],
): TranslationType[] => {
  return nodes.map((node) => {
    return {
      id: node.id,
      flavorAttribute: node.flavorAttribute,
      value: node.name,
      visible: node.visible,
      deleted: node.deleted,
      ...mapItemsByLanguage(
        node.referenceFlavorTranslationsByReferenceFlavorId.nodes,
      ),
    };
  });
};

export const reshapeLexicons = (referenceFlavorNodes: TranslationType[]) => {
  const groupedReferenceFlavors = groupBy(
    referenceFlavorNodes,
    'flavorAttribute',
  );
  // Shape data like translation files in i18n
  const listOfReferenceFlavors =
    groupedReferenceFlavors &&
    Object.entries(groupedReferenceFlavors).map(([key, value]) => {
      return { key, value };
    });
  return listOfReferenceFlavors;
};

export const getUpdatedReferenceFlavorsByAttributeLexicon = <T>(
  lexicons: {
    key: string;
    value: T[];
  }[],
  attribute: string,
): T[] => {
  if (!lexicons) return [];
  const filteredLexicon = lexicons.find((f) => f.key === attribute);
  return filteredLexicon ? filteredLexicon.value : [];
};
