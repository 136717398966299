import * as d3 from 'd3';

// https://observablehq.com/@d3/color-legend
export const Legend = (
  color: d3.ScaleLinear<string, string, never>,
  projectPqs: [minPq: number, meanPq: number, maxPq: number],
  productPqs: [minPq: number, maxPq: number],
  {
    title = "",
    width = 600,
    height = 62,
    marginTop = 24,
    marginRight = 50,
    marginBottom = 24,
    marginLeft = 50,
    tickFormat = (d: number) => d.toPrecision(3)
  }: {
    title?: string;
    tickSize?: number;
    width?: number;
    height?: number;
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
    tickFormat?: any;
  } = {}
) => {
  function ramp(color: d3.ScaleLinear<any, any>, projectPqs: number[], n: number = 256) {
    const canvas = document.createElement('canvas');
    canvas.width = n;
    canvas.height = 1;
    const context = canvas.getContext('2d');
    for (let i = 0; i < n; ++i) {
      context.fillStyle = color((projectPqs[0] + (i * ((projectPqs[2] - projectPqs[0]) / n)) - projectPqs[1]));
      context.fillRect(i, 0, 1, 1);
    }
    return canvas;
  }


  const barWidth = width - marginLeft - marginRight;
  const barHeight = height - marginTop - marginBottom;
  const tickPadding = Math.min(marginTop, marginBottom) / 3;
  const titleFontSize = Math.min(marginLeft / 2, barHeight);

  const svg = d3
    .create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .style('overflow', 'visible')
    .style('display', 'block');

  svg
    .append('image')
    .attr('id', "")
    .attr('x', marginLeft)
    .attr('y', marginTop)
    .attr('width', barWidth)
    .attr('height', barHeight)
    .attr('preserveAspectRatio', 'none')
    .attr('href', ramp(color, projectPqs).toDataURL());

  svg.append('g')
    .append('text')
    .attr('x', 0)
    .attr('y', (barHeight / 2) + marginTop + (titleFontSize / 2))
    .attr('fill', 'currentColor')
    .attr('text-anchor', 'start')
    .attr('font-weight', 'bold')
    .attr('font-size', titleFontSize)
    .attr('class', 'title')
    .text(title);

  const axisScale = d3
    .scaleLinear<number, number>()
    .domain([Math.min(...projectPqs), Math.max(...projectPqs)])
    .range([marginLeft, width - marginRight]);
  const bottomTickAdjust = g => g.selectAll('.tick line')
    .attr('y1', 0 - barHeight)
    .attr('y2', tickPadding);
  const topTickAdjust = g => g.selectAll('.tick line')
    .attr('y1', 0 - barHeight - tickPadding)
    .attr('y2', 0)

  svg
    .append('g')
    .attr('transform', `translate(0,${height - marginBottom})`)
    .call(
      d3
        .axisBottom(axisScale)
        .tickPadding(tickPadding)
        .tickFormat(tickFormat)
        .tickValues(projectPqs)
    )
    .call(bottomTickAdjust)
    .call(g => g.select('.domain').remove());

  svg
    .append('g')
    .attr('transform', `translate(0,${height - marginBottom})`)
    .call(
      d3
        .axisTop(axisScale)
        .tickPadding(barHeight + tickPadding)
        .tickFormat(tickFormat)
        .tickValues(productPqs)
    )
    .call(topTickAdjust)
    .call(g => g.select('.domain').remove());

  return svg.node();
}
