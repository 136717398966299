import * as React from 'react';
import { Fragment } from 'react';

import { Paper, Step, StepLabel, Stepper } from '@mui/material';
import useAvailableCountries from 'hooks/useAvailableCountries';
import useGgEnvironment from 'hooks/useGgEnvironment';
import useRegionsList from 'hooks/useRegionsList';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getFormValues } from 'redux-form';

import MarketSurvey from './MarketSurvey/MarketSurvey';
import MaterialButton from '../../components/MaterialButton';
import StepperIcon from '../../components/StepperIcon';
import { REQUEST_REPORT_FORM } from '../../constants/formNames';
import {
  REQUEST_REPORT_NEXT,
  REQUEST_REPORT_PREV,
  REQUEST_REPORT_SUBMIT,
} from '../../constants/googleAnalytics/actions';
import { CAT_REQUEST_REPORT } from '../../constants/googleAnalytics/categories';
import {
  PRODUCT_UPDATE,
  PRODUCT,
  availableUpdateReportTypes,
} from '../../constants/report';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import { constructArtifactPath, jobDefinition } from '../../utils/conchUtils';
import { isProd } from '../../utils/environmentUtils';
import ConditionViewerRoleContainer from '../ConditionViewerRole';
import AdvancedConfig from '../RequestReport/AdvancedConfig/AdvancedConfig';
import ClassDBTable from '../RequestReport/ClassDBTable';
import Confirmation from '../RequestReport/Confirmation/Confirmation';
import Demographics from '../RequestReport/Demographics';
import ReportType from '../RequestReport/ReportType/ReportType';
import styles from '../RequestReport/RequestReport.module.css';
import RequestSent from '../RequestReport/RequestSent';
import { invalidFields } from '../RequestReport/utils';

const { event } = ReactGA;

const STEPS = [
  'Select Report Type',
  'Target Demographics',
  'Update Product Target',
  'Advanced Configuration',
  'Confirm',
];

export function artifact_path(record) {
  return constructArtifactPath(
    record.startedAt,
    record.clientName,
    record.projectName,
    record.targetGroupName,
  );
}

const RequestReportUpdate: React.FC = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));

  const { availableCountries } = useAvailableCountries(producerId);
  const regions = useRegionsList();
  const environment = useGgEnvironment();

  const formValues = useSelector((state) =>
    getFormValues(REQUEST_REPORT_FORM)(state),
  );
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [submitted, setSubmitted] = React.useState(false);
  const [openClassDB, setOpenClassDB] = React.useState<boolean>(false);
  const [templateReport, setTemplateReport] = React.useState<any>(null);

  const formProps = { ...props, ...formValues };

  const {
    competitive_set_rich,
    competitive_set_folders,
    report_type,
    change,
    pristine,
    invalid,
    submitting,
    submitFailed,
    limitExceeded,
    submitSucceeded,
    submittedProjectName,
    submittedType,
    handleSubmit,
    displayMode = false,
  } = formProps;

  React.useEffect(() => {
    const state: any = location?.state;
    const initStep: string = state?.initStep;
    if (initStep) setActiveStep(Number(initStep));
    const templateReport = state?.templateReport;
    setTemplateReport(templateReport);
  }, [location]);

  React.useEffect(() => {
    change('job_definition', jobDefinition(environment));
  }, [environment]);

  React.useEffect(() => {
    if (templateReport) {
      const temp = templateReport;
      change('slack_me', true);
      change('report_type', availableUpdateReportTypes[0]);
      change('project_name', temp.projectName);
      change('client_name', {
        label: temp.clientName,
        value: temp.clientName,
      });
      change('target_group_name', temp.targetGroupName);
      change('workspace_id', {
        id: temp.workspaceId,
        name: temp.producerByWorkspaceId.name,
        label: temp.producerByWorkspaceId.name,
      });

      change('update_products.profilingEnabled', false);
      change(
        'update_products.original_products',
        temp.params.competitive_set_products,
      );
      change(
        'update_products.client_allowed_workspaces',
        temp.params.client_allowed_workspaces,
      );
      change('update_products.parent_job_id', temp.jobId);
      change('update_products.artifact_path', artifact_path(temp));
      change('update_products.only_new_products_on_report', false);
      change('update_products.ensure_exact_pq', true);

      const templateCountry = Array.from(availableCountries).find(
        (c) => c.dbname === temp.params.countries,
      );

      change(
        'countries',
        templateCountry
          ? {
              label: `${templateCountry.emoji} ${templateCountry.name}`,
              value: templateCountry.dbname,
            }
          : null,
      );
      change('clts', temp.params.clts ?? []);
      change('include_female', temp.params.include_female);
      change('asian', temp.params.asian);
      change('black', temp.params.black);
      change('hispanic', temp.params.hispanic);
      change('white', temp.params.white);
      change(
        'usa_region',
        temp.params.usa_region
          ? { label: temp.params.usa_region, value: temp.params.usa_region }
          : null,
      );
      change('ageCategory', temp.params.ageCategory);
      change('age_ranges', temp.params.age_ranges);
      (temp.params.age_ranges ?? []).forEach((age_range, i) => {
        change(`age_range_weight_${i}`, age_range.fraction * 100);
      });
      change('smoking_habits', temp.params.smoking_habits);
      change('SansRejector', temp.params.SansRejector);
      change('only_include_parents', temp.params.only_include_parents);
      change('include_texture', temp.params.include_texture);
      change('texture_only', temp.params.texture_only);
      change('texture_data_only', temp.params.texture_data_only);
      change('showConfidence', temp.params.showConfidence);
      change('show_TC_se', temp.params.show_TC_se);
      change('include_archetypes', temp.params.include_archetypes);
      change('n_cluster_archetype', temp.params.n_cluster_archetype);
      change('n_cluster_archetype_max', temp.params.n_cluster_archetype_max);
      change('simulate_pq_values', temp.params.simulate_pq_values);
      change('n_sims', temp.params.n_sims);
      change('profilingEnabled', temp.params.profilingEnabled);
      change('generate_ppt', temp.params.generate_ppt);
      change('extrema_analysis', temp.params.extrema_analysis);
      change('includeNoveltyScores', temp.params.includeNoveltyScores);
      change('plotNorm', temp.params.plotNorm);
      change('show_pg_gNorm', temp.params.show_pg_gNorm);
      change(
        'combine_artificial_sweeteners',
        temp.params.combine_artificial_sweeteners,
      );
      change('override_invalid', temp.params.override_invalid);
      change('timestamp_start', temp.params.timestamp_start);
      change('experimental_features', temp.params.experimental_features);
    }
  }, [templateReport]);

  const handleBackStep = () => {
    event({
      category: CAT_REQUEST_REPORT,
      action: REQUEST_REPORT_PREV,
      label: STEPS[activeStep],
    });

    if (report_type.value === PRODUCT_UPDATE) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNextStep = () => {
    event({
      category: CAT_REQUEST_REPORT,
      action: REQUEST_REPORT_NEXT,
      label: STEPS[activeStep],
    });

    if (report_type.value === PRODUCT_UPDATE) {
      setActiveStep(activeStep + 1);
    }
  };

  if (submitted) {
    return (
      <RequestSent
        submitFailed={submitFailed}
        limitExceeded={limitExceeded}
        submitSucceeded={submitSucceeded}
        submitting={submitting}
        report_type={submittedType && submittedType.value}
        project_name={submittedProjectName}
      />
    );
  }

  return (
    <Fragment>
      <Paper className={styles.container}>
        <Stepper
          style={{ padding: 24 }}
          activeStep={activeStep}
          nonLinear
          alternativeLabel
        >
          {STEPS.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepperIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && <ReportType change={change} inUpdateReportMode />}

        {activeStep === 1 && report_type.value == PRODUCT_UPDATE && (
          <div>
            <Demographics
              displayMode={displayMode}
              allCountries={availableCountries}
              allRegions={regions}
              countries={formProps.initialValues.countries}
              include_female={formValues.include_female}
              change={change}
              {...formValues}
            />
          </div>
        )}

        {activeStep === 2 &&
          (report_type.value === PRODUCT_UPDATE ||
            report_type.value === PRODUCT) && (
            <MarketSurvey
              reportWorkspace={formValues.workspace_id}
              templateReportProducts={
                templateReport?.params.competitive_set_products
              }
              change={change}
              displayMode={displayMode}
              producerId={producerId}
              {...formValues}
            />
          )}

        {activeStep === 3 && (
          <AdvancedConfig
            isProd={isProd(environment)}
            categories={formValues.competitive_set_folders}
            {...formProps}
          />
        )}

        {activeStep === 4 && (
          <Confirmation
            isProd={isProd(environment)}
            report_type={report_type}
            {...formProps}
          />
        )}

        <div className={styles.buttonContainer}>
          <ConditionViewerRoleContainer
            render={(viewerRoles) =>
              viewerRoles.viewerRoleIsSuperadmin &&
              activeStep === 2 &&
              (report_type.value === PRODUCT_UPDATE ||
                report_type.value === PRODUCT) &&
              competitive_set_rich.length > 0 && (
                <MaterialButton
                  onClick={() => setOpenClassDB(!openClassDB)}
                  variant="outlined"
                  soft
                  teal
                >
                  View Class DB
                </MaterialButton>
              )
            }
          />

          {activeStep > 0 && (
            <MaterialButton
              onClick={() => handleBackStep()}
              variant="outlined"
              soft
            >
              Back
            </MaterialButton>
          )}
          {activeStep < STEPS.length - 1 && (
            <MaterialButton
              onClick={() => handleNextStep()}
              variant="outlined"
              soft
              teal
              disabled={
                invalid ||
                invalidFields(
                  activeStep,
                  report_type.value,
                  competitive_set_rich,
                  competitive_set_folders,
                  formValues,
                )
              }
            >
              Next
            </MaterialButton>
          )}
          {activeStep === STEPS.length - 1 && (
            <MaterialButton
              variant="outlined"
              disabled={pristine || invalid || submitting}
              onClick={() => {
                event({
                  category: CAT_REQUEST_REPORT,
                  action: REQUEST_REPORT_SUBMIT,
                });
                setSubmitted(true);
                handleSubmit();
              }}
              soft
              teal
            >
              {t('general.confirm')}
            </MaterialButton>
          )}
        </div>
      </Paper>

      {openClassDB &&
        activeStep === 2 &&
        (report_type.value === PRODUCT_UPDATE ||
          report_type.value === PRODUCT) && (
          <Paper className={styles.container}>
            <ClassDBTable
              productIds={competitive_set_rich.map((product) => product.id)}
              categories={competitive_set_rich
                .filter((product) => !!product.category)
                .map((product) => product.category)}
            />
          </Paper>
        )}
    </Fragment>
  );
};

export default React.memo(RequestReportUpdate);
