import { reduxForm } from 'redux-form';

import HeavyUserTag from './HeavyUserTag';
import formSubmit from '../../actions/formSubmit';
import { TAG_HEAVY_USER_FORM } from '../../constants/formNames';

const validation = (val) =>
  !val || (typeof val === 'object' && val.length === 0);

export default reduxForm({
  form: TAG_HEAVY_USER_FORM,
  onSubmit: (values, dispatch) => {
    dispatch(formSubmit(TAG_HEAVY_USER_FORM));
  },
  validate: (values) => {
    return {
      usersFromCsv: validation(values.usersFromCsv),
      tag: validation(values.tag),
      categories:
        validation(values.categories) &&
        validation(values.features) &&
        validation(values.componentBases) &&
        validation(values.componentOthers),
      features:
        validation(values.categories) &&
        validation(values.features) &&
        validation(values.componentBases) &&
        validation(values.componentOthers),
      componentBases:
        validation(values.categories) &&
        validation(values.features) &&
        validation(values.componentBases) &&
        validation(values.componentOthers),
      componentOthers:
        validation(values.categories) &&
        validation(values.features) &&
        validation(values.componentBases) &&
        validation(values.componentOthers),
    };
  },
})(HeavyUserTag);
