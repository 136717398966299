import { NULL_REPORT_ID } from 'constants/report';

import { isNumber } from 'lodash';

/**
 * Null Value replacement function
 *
 * @param value original value
 * @param ifNullValue replacement value if value is null
 * @returns
 */
export const nvl = <T>(value: T, ifNullValue: T) => {
  if (value == null) {
    return ifNullValue;
  } else {
    return value;
  }
};

/**
 * Check to see if value is the literal empty object.  If so, give
 * ifEmptyObject return, otherwise pass value through
 *
 * @param value test value
 * @param ifEmptyObject value to return in value is empty object {}
 * @returns correct return
 */
export const nvlObject = <T>(value: any, ifEmptyObject: T): T => {
  if (JSON.stringify(value) === '{}') {
    return ifEmptyObject;
  } else {
    return value;
  }
};

/**
 * Return NULL report id if value is empty, null,or undefined
 *
 * @param reportId passed in reportId, potentially null or blank from pickers
 * @returns Actual UUID
 */
export const nvlReportId = (reportId: string): string => {
  if (reportId) {
    return reportId;
  } else {
    return NULL_REPORT_ID;
  }
};

/**
 * Empty Object replacement function
 *
 * @param value original value
 * @param ifEmptyValue replacement value if value is empty object
 * @returns
 */
export const emptyVal = <T>(value: T, ifNullValue: T) => {
  if (value == null) {
    return value;
  } else if (Object.keys(value).length === 0) {
    return ifNullValue;
  } else {
    return value;
  }
};

/**
 * Check for the null string and return a type safe null object
 *
 * @param value original value
 * @returns typesafe null value
 */
export const nullCheck = (value: string): string => {
  if (value === 'null') {
    return null;
  } else {
    return value;
  }
};

/**
 * Failsafe filenames for potentially bad characters
 *
 * @param fileName input (possibly unsafe) filename
 * @returns safer filename
 */
export const failsafeFilename = (fileName: string): string => {
  return fileName.replace(new RegExp('[\\/:"*?<>.|]+', 'g'), '_');
};

/**
 * Clean up underscore separated values
 *
 * @param value input value
 * @returns cleaned value
 */
export const cleanUnderscore = (value: string): string => {
  if (nullCheck(value)) {
    return nullCheck(value).replace(/_/g, ' ');
  } else {
    return value;
  }
};

/**
 * Check for AFS / AFS Panels workspace
 * @param workspaceId test workspace id
 * @returns is an AFS workspace
 */
export const isAfsWorkspace = (workspaceId: number): boolean => {
  return workspaceId === 25 || workspaceId === 192;
};

/**
 * Check for Demo workspace
 * @param workspaceId test workspace id
 * @returns is the Demo workspace
 */
export const isDemoWorkspace = (workspaceId: number): boolean => {
  return workspaceId === 403 || workspaceId === 435;
};

/**
 * Check for NIQ workspace
 * @param workspaceId test workspace id
 * @returns is the Demo workspace
 */
export const isNiqWorkspace = (workspaceId: number): boolean => {
  return (
    workspaceId === 273 ||
    workspaceId === 362 ||
    workspaceId === 422 ||
    workspaceId === 434
  );
};

/**
 * Turn the undefined input into an array
 * @param input type unsafe input
 * @returns array output
 */
export const arrayify = (input: any): any[] => {
  if (Array.isArray(input)) {
    return input;
  }

  if (typeof input === 'string') {
    return [input];
  }

  return [];
};

/**
 * Test the truth/falsehood of a string
 * @param input string input
 * @returns boolean
 */
export const truthyString = (input: string): boolean => {
  if (!input) {
    return false;
  } else if (['f', 'false'].includes(input.trim().toLowerCase())) {
    return false;
  } else if (['n', 'no'].includes(input.trim().toLowerCase())) {
    return false;
  } else if (['yes', 'y'].includes(input.trim().toLowerCase())) {
    return true;
  } else {
    return true;
  }
};

/**
 * Test if value is parsible Integer, if not use defaultInt
 *
 * @param testValue test value to check if a parsible integer
 * @param defaultInt default integer value to use when needed
 * @returns return parseInt test value, or else defaultInt value
 */
export function nvlParseInt(testValue: any, defaultInt: number): number {
  if (testValue === null || testValue === undefined) return defaultInt;

  const attemptedParse = parseInt(testValue);
  if (isNaN(attemptedParse)) return defaultInt;
  if (isNumber(attemptedParse)) return attemptedParse;

  return defaultInt;
}

/**
 * Test if a value is a valid DATE object
 * @param testValue value to test
 * @returns boolean if valid date
 */
export function isValidDate(testValue: any): boolean {
  return testValue instanceof Date && !isNaN(testValue.valueOf());
}
