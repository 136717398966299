import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import UserListQuery from '@graphql/queries/UserList';
import { Search as SearchIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Input, InputAdornment } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import { lineBreak } from 'components/ReactDataTable/reactDataTableUtils';
import useWindowSize from 'hooks/useWindowSize';
import { get } from 'lodash';
import moment from 'moment';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AddUserDialog from './AddUserDialog';
import styles from './UserList.module.css';
import { renderRaceAndEthnicity, renderDate } from './userTableConfig';
import MaterialButton from '../../components/MaterialButton';
import { tableCustomStyles } from '../../components/ReactDataTable/tableCustomStyles';
import UserSearch from '../../components/UserSearch';
import { PANELISTS, USER } from '../../constants/routePaths';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import selectWorkspaceProducerName from '../../selectors/workspaceProducerName';
import { PageHeader, PagePaper } from '../../styles/themeComponents';
import formatPath from '../../utils/formatPath';
import ConditionViewerRoleContainer from '../ConditionViewerRole';

interface TableRow {
  id: number;
  userId: number;
  username: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  firstLanguage: string;
  raceEthnicity: string;
  totalReviews: number;
  lastActive: string;
}

const UserList: React.FC = () => {
  const theme = useTheme();

  const {
    widthBelow600,
    widthBelow700,
    widthBelow900,
    widthBelow1100,
    widthBelow1400,
  } = useWindowSize();

  const [addUser, setAddUser] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const toggleAddUserDialog = () => setAddUser(!addUser);

  const { t } = useTranslation();

  const producerId = useSelector((state) => selectWorkspaceProducerId(state));
  const producerName = useSelector((state) =>
    selectWorkspaceProducerName(state),
  );

  const { data, loading, error } = useQuery(UserListQuery, {
    variables: {
      producerId: Number(producerId),
    },
  });

  const columns: TableColumn<TableRow>[] = [
    {
      name: 'userId',
      selector: (row: TableRow) => row.id,
      sortable: true,
      omit: true,
    },
    {
      id: 'username',
      name: lineBreak(t('users.username')),
      selector: (row: TableRow) => row.username,
      sortable: true,
      cell: (row: TableRow) =>
        lineBreak(
          <Link key={row.id} to={formatPath(USER, { userId: row.userId })}>
            {row.username}
          </Link>,
        ),
    },
    {
      id: 'email',
      name: lineBreak(t('users.email')),
      width: '25%',
      selector: (row: TableRow) => row.email,
      sortable: true,
      cell: (row: TableRow) => lineBreak(row.email),
      omit: widthBelow600,
    },
    {
      id: 'phoneNumber',
      name: lineBreak(t('users.phoneNumber')),
      selector: (row: TableRow) => row.phoneNumber,
      sortable: true,
      cell: (row: TableRow) => lineBreak(row.phoneNumber),
      omit: widthBelow700,
    },
    {
      name: lineBreak(t('users.firstLanguage')),
      selector: (row: TableRow) => row.firstLanguage,
      sortable: true,
      cell: (row: TableRow) => lineBreak(row.firstLanguage),
      omit: widthBelow1100,
    },
    {
      name: lineBreak(t('users.raceEthnicity')),
      selector: (row: TableRow) => row.raceEthnicity,
      sortable: true,
      cell: (row: TableRow) => lineBreak(row.raceEthnicity),
      omit: widthBelow1400,
    },
    {
      name: lineBreak(t('users.totalReviews')),
      selector: (row: TableRow) => row.totalReviews,
      sortable: true,
      cell: (row: TableRow) => lineBreak(row.totalReviews),
      omit: widthBelow900,
    },
    {
      name: lineBreak(t('users.lastActive')),
      minWidth: '150px',
      selector: (row: TableRow) =>
        row.lastActive ? moment(row.lastActive).format('LL') : 'N.A',
      sortable: true,
      cell: (row: TableRow) =>
        lineBreak(row.lastActive ? moment(row.lastActive).format('LL') : 'N.A'),
      omit: widthBelow900,
    },
  ];

  const reshapedData = (data): TableRow[] =>
    get(data, 'producer.producerUsers.nodes', []).map(({ id, user }) => ({
      id: id,
      userId: user.id,
      username: user.username,
      email: user.email,
      phoneNumber: user.phoneNumber,
      dateOfBirth: renderDate(user.dateOfBirth),
      firstLanguage: user.firstLanguage,
      raceEthnicity: renderRaceAndEthnicity(user.race),
      totalReviews: get(user, 'productReviews.totalCount'),
      lastActive: user.lastActive,
    }));

  if (loading || error) {
    return <LoadingScreen />;
  }

  return (
    <PagePaper>
      <div className={styles.headerContainer}>
        <div className={styles.headerTextContainer}>
          <PageHeader>{t('users.users')}</PageHeader>
          <h3 className={styles.userTitle}>{t('users.list')}</h3>
        </div>
        <Input
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
          onChange={(event) => {
            setSearchString(event.target.value);
            setShowSearch(true);
          }}
          placeholder={t('general.search')}
        />
        <ConditionViewerRoleContainer
          render={(viewerRoles) =>
            viewerRoles.viewerRoleIsAdmin ||
            viewerRoles.viewerRoleIsSuperadmin ? (
              <div>
                <MaterialButton
                  variant="outlined"
                  soft
                  teal
                  onClick={toggleAddUserDialog}
                >
                  {t('users.addUser')}
                </MaterialButton>
                <Link to={PANELISTS}>
                  <MaterialButton variant="outlined" soft teal>
                    {t('users.panelists')}
                  </MaterialButton>
                </Link>
              </div>
            ) : viewerRoles.viewerRoleIsPartnerAdmin ? (
              <div>
                <Link to={PANELISTS}>
                  <MaterialButton variant="outlined" soft teal>
                    {t('users.panelists')}
                  </MaterialButton>
                </Link>
              </div>
            ) : (
              <div />
            )
          }
        />
      </div>
      {showSearch && searchString.length > 0 && (
        <UserSearch
          query={searchString}
          hideSearch={() => setShowSearch(false)}
          first={10}
        />
      )}
      <div>
        <DataTable
          columns={columns}
          data={reshapedData(data)}
          customStyles={tableCustomStyles(theme, true)}
          striped
          pagination
          paginationIconFirstPage={<RDTFirstPage />}
          paginationIconLastPage={<RDTLastPage />}
          paginationIconPrevious={<RDTPrevPage />}
          paginationIconNext={<RTDNextPage />}
        />
      </div>
      <AddUserDialog
        addUser={addUser}
        toggleAddUserDialog={toggleAddUserDialog}
        producerName={producerName}
      />
    </PagePaper>
  );
};

export default UserList;
