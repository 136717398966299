import React, { useState } from 'react';

import { InputLabel } from '@mui/material';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import 'react-mde/lib/styles/css/react-mde-all.css';

import styles from './CreateNotification.module.css';
import MaterialButton from '../../components/MaterialButton';


interface Props {
  closeModal: () => void;
  createNotification: (message: string) => Promise<void>;
}

const CreateNotification: React.FC<Props> = ({ closeModal, createNotification }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

  const converter = new Showdown.Converter();

  return (
    <div className={styles.sectionContainer}>
      <Formik
        initialValues={{
          message: '',
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.message.length) {
            errors.message = 'Message cannot be empty.';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          const { message } = values;
          await createNotification(message);
        }}
        render={({ values, setFieldValue, handleSubmit, errors }) => (
          <Form>
            <div className={styles.mainContainer}>
              <div className={styles.subContainer}>
                <div className={styles.fieldContainer}>
                  <InputLabel>{t('notification.messageInput')}</InputLabel>
                  <ReactMde
                    value={values.message}
                    onChange={(value) => setFieldValue('message', value)}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={(markdown) =>
                      Promise.resolve(converter.makeHtml(markdown))
                    }
                  />
                  <span className={styles.error}>{errors.message}</span>
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <MaterialButton variant="outlined" soft onClick={closeModal}>
                {'Cancel'}
              </MaterialButton>
              <MaterialButton variant="outlined" soft teal onClick={handleSubmit}>
                {'Save'}
              </MaterialButton>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default CreateNotification;
