import { chain } from 'lodash';

import { getProductName } from '../utils';

export interface PairedPreferenceComparison {
  productId1: number;
  productId2: number;
  version1: string;
  version2: string;
  percentPop: number;
}

export const pairedPreferenceComparisonArray = (
  reportPairedPreferenceComparisonResponse: reports.ReportPairedPreferenceComparisonResponse,
): PairedPreferenceComparison[] => {
  return chain(
    reportPairedPreferenceComparisonResponse?.allRpPairedPreferences?.nodes,
  )
    .map((item: reports.ReportPairedPreferenceComparison) => ({
      productId1: item.productByProductId1.id,
      productId2: item.productByProductId2.id,
      version1: item.version1,
      version2: item.version2,
      percentPop: item.percentPreference,
    }))
    .value();
};

export const formatComparisonArray = (
  productNames: reports.ColorVersionedProductInfo[],
  pairedPreferenceComparisonArray: PairedPreferenceComparison[],
) =>
  (pairedPreferenceComparisonArray ?? []).map((d) => ({
    ...d,
    nameVersion1: getProductName({
      productNames,
      productId: d.productId1,
      version: d.version1 ? d.version1 : null,
    }),
    nameVersion2: getProductName({
      productNames,
      productId: d.productId2,
      version: d.version2 ? d.version2 : null,
    }),
  }));

export const longestName = (
  productNames: reports.ColorVersionedProductInfo[],
  pairedPreferenceComparisonArray: PairedPreferenceComparison[],
) =>
  formatComparisonArray(productNames, pairedPreferenceComparisonArray)
    .map((d) => d?.nameVersion1?.length ?? 0)
    .reduce((a, b) => (a > b ? a : b), 0);

export const productNameVersions = (
  productNames: reports.ColorVersionedProductInfo[],
  pairedPreferenceComparisonArray: PairedPreferenceComparison[],
) =>
  [...formatComparisonArray(productNames, pairedPreferenceComparisonArray)]
    .sort((a, b) => b.percentPop - a.percentPop)
    .map((i) => i.nameVersion2)
    .reverse();
