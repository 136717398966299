import { BasicTooltip } from '@nivo/tooltip';

const ProductTimeSeriesPqTooltip = ({ point }) => {
  return (
    <BasicTooltip
      id={
        <div>
          <div>
            <strong>{point.data.x}</strong> -- PQ:{' '}
            <strong>{point.data.yFormatted}</strong>
          </div>
          <div style={{ textAlign: 'center', fontSize: '1.5cqh' }}>
            <span>&#40;+/-{(point.data.error as number).toFixed(2)}&#41;</span>
          </div>
        </div>
      }
      enableChip={true}
      color={point.serieColor}
    />
  );
};

export default ProductTimeSeriesPqTooltip;
