import {
  superadminUserRole,
  partnerAdminRole,
  adminUserRole,
  workspaceAdminRole,
} from 'constants/userRoles';

/**
 * Check if the user roles has Admin role
 *
 * @param userRoles current user roles
 * @returns if includes adminRole
 */
export const hasAdminRole = (userRoles: Set<string>): boolean => {
  return (
    userRoles &&
    new Set(Array.from(userRoles).map((i) => i.toLowerCase())).has(
      adminUserRole.toLocaleLowerCase(),
    )
  );
};

/**
 * Check if the user roles has Super Admin role
 *
 * @param userRoles current user roles
 * @returns if includes superAdminRole
 */
export const hasSuperAdminRole = (userRoles: Set<string>): boolean => {
  return (
    userRoles &&
    new Set(Array.from(userRoles).map((i) => i.toLowerCase())).has(
      superadminUserRole.toLocaleLowerCase(),
    )
  );
};

/**
 * Check if the user roles has Partner Admin role
 * @param userRoles current user roles
 * @returns if includes partnerAdminRole
 */
export const hasPartnerAdminRole = (userRoles: Set<string>): boolean => {
  return (
    userRoles &&
    new Set(Array.from(userRoles).map((i) => i.toLowerCase())).has(
      partnerAdminRole.toLocaleLowerCase(),
    )
  );
};

/**
 * Check if the user roles has Workspace Admin role
 * @param userRoles current user roles
 * @returns if includes workspaceAdminRole
 */
export const hasWorkspaceAdminRole = (userRoles: Set<string>): boolean => {
  return (
    userRoles &&
    new Set(Array.from(userRoles).map((i) => i.toLowerCase())).has(
      workspaceAdminRole.toLocaleLowerCase(),
    )
  );
};
