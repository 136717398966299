import React from 'react';

import { useTheme } from '@mui/material';

import useMainStyles from './useStyles';

type Props = {
  children: any,
  title: ?string
};

const Metric = ({ children, title, ...rest }: Props) => {
  const { useStyles } = useMainStyles({ ...rest });
  const classes = useStyles(useTheme());

  return (
    <div>
      <div className={classes.title}>{title}</div>
      <div className={classes.contentContainer}>{children}</div>
    </div>
  );
};

Metric.displayName = 'Metric';

export default Metric;
