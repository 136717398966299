import * as React from 'react';

import { useQuery } from '@apollo/client';
import ProjectByIdQuery from '@graphql/queries/ProjectByIdQuery';
import { Box, Grid } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import AllDecompsDataDownload from 'components/Report/DataDownload/AllDecompsDataDownload';
import AllProductFlavorProfileIntensities from 'components/Report/DataDownload/AllProductFlavorProfileIntensities';
import OrderOfOperationsDownload from 'components/Report/DataDownload/OrderOfOperationsDownload';
import PairedComparisonDownload from 'components/Report/DataDownload/PairComparisonDownload';
import PQNormsDataDownload from 'components/Report/DataDownload/PQNormsDataDownload';
import { ProductVersionSet } from 'components/Report/ProductVersion';
import useCustomProductNames from 'hooks/useCustomProductNames';
import {
  orderOfOptsDownloadEnabled,
  useCustomerPreferences,
} from 'services/customerPreferences';

import AllOFPsDataDownload from '../../../components/Report/DataDownload/AllOFPsDataDownload/index';
import AllProductImageDownload from '../../../components/Report/DataDownload/AllProductImageDownload/index';
import TextureClusterSummaryTable from '../../../components/Report/DataDownload/TextureClusterSummaryDownload/TextureClusterSummaryDownload';

interface Props {
  projectId: number;
  reportId: string;
  products: ProductVersionSet;
}

const ProjectDataDownload: React.FC<Props> = (props) => {
  const { projectId, reportId, products } = props;

  const customerPreferences = useCustomerPreferences();
  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const { loading, error, data } = useQuery(ProjectByIdQuery, {
    variables: {
      projectId: new Number(projectId),
    },
  });

  if (loading || error) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box display="flex">
            <PQNormsDataDownload reportId={reportId} />
            <AllOFPsDataDownload
              projectId={projectId}
              reportId={reportId}
              products={products}
            />
            <AllDecompsDataDownload
              projectId={projectId}
              reportId={reportId}
              products={products}
            />
            <AllProductFlavorProfileIntensities
              projectId={projectId}
              reportId={reportId}
              products={products}
            />
            <TextureClusterSummaryTable
              projectId={projectId}
              reportId={reportId}
              products={products}
            />
            <PairedComparisonDownload
              projectId={projectId}
              reportId={reportId}
            />
            <AllProductImageDownload
              projectId={projectId}
              projectName={data?.project?.name}
              reportId={reportId}
              products={products}
            />
            {orderOfOptsDownloadEnabled(customerPreferences) && (
              <OrderOfOperationsDownload
                projectId={projectId}
                reportId={reportId}
                productNames={productNames}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectDataDownload;
