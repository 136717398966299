import * as React from 'react';

import { capitalize } from 'lodash';

import styles from './ProductCard.module.css';
import FormInputTag from '../../../components/FormInputTag/FormInputTag';

interface Props {
  productComponentOthers: any[];
}

const RenderProductComponentOther: React.FunctionComponent<Props> = ({
  productComponentOthers,
}) => (
  <div className={styles.tag}>
    <FormInputTag
      defaultTags={productComponentOthers.map((productComponentOther) => ({
        label: capitalize(
          productComponentOther.productComponentOtherByProductComponentOtherId
            .name,
        ),
        id: productComponentOther.productComponentOtherByProductComponentOtherId.id.toString(),
      }))}
      readOnly
      uneditable
    />
  </div>
);

export default RenderProductComponentOther;
