import * as React from 'react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './RemoveUserFromWorkspace.module.css';
import Result from './Result';
import FieldTextInput from '../../components/FieldTextInput';
import MaterialButton from '../../components/MaterialButton';

const RemoveUserFromWorkspace = () => {
  const [email, setEmail] = useState('');
  const [showResult, setShowResult] = useState(false);
  const { t } = useTranslation();

  return (
    <div style={{ width: '100%' }}>
      <div className={styles.sectionContainer}>
        <FieldTextInput
          label={t('users.email')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className={styles.buttonContainer}>
        <MaterialButton
          variant="outlined"
          onClick={() => setShowResult(true)}
          soft
          teal
        >
          {t('admin.viewWorkspaces')}
        </MaterialButton>
      </div>

      {showResult && <Result email={email?.toLowerCase()} />}
    </div>
  );
};

export default RemoveUserFromWorkspace;
