import { gql } from '@apollo/client';

export interface RefFlavorData {
  attribute: string;
}

export interface RefFlavorsByReportIdResponse
  extends reports.ReportGraphQLReponse {
  allRpOfps: {
    nodes: Array<RefFlavorData>;
  };
}

export default gql`
  query refFlavorsByReportId($reportId: UUID) {
    allRpOfps(
      orderBy: ATTRIBUTE_ASC
      condition: { reportId: $reportId, ofpType: REFFLAVOR }
    ) {
      nodes {
        attribute
      }
    }
  }
`;
