import { gql } from '@apollo/client';

export const PROJECT_TO_EDIT = gql`
query ProjectReportsQuery($projectId: Int!) {
  projectById(id: $projectId) {
    name
    projectReportsByProjectId {
      nodes {
        reportId
        id
        updatedDate
        reportJobByReportId {
          clientName
          projectName
          reportType
          startedAt
          targetGroupName
        }
      }
    }
  }
}
`;