import * as React from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { ProductVersionSet } from 'components/Report/ProductVersion';

import ReportTextureClustersQuery from '../../../../graphql/queries/ReportTextureClusters';
import {
  TextureCluster,
  mapAndRegroupClusterTexturesMinConfidence,
} from '../../../Report/Texture/ReportTextureCluster/textureClusterUtils';
import DataDownloadTile from '../DataDownloadTile';
import { TileType } from '../DataDownloadTile/DataDownloadTile';

interface Props {
  projectId: number;
  reportId: string;
  products: ProductVersionSet;
}

interface TextureClusterSummaryRow {
  Cluster: string;
  Textures: string;
}

export const csvData = (
  textureClusters: TextureCluster[],
): TextureClusterSummaryRow[] => {
  if (!textureClusters) return [];

  return textureClusters.map((row, i) => {
    const rowKey = Object.keys(row)[0];

    return {
      Cluster: rowKey,
      Textures: row[rowKey].map((d) => d.texture).join(', '),
    };
  });
};

const TextureClusterSummaryTable: React.FC<Props> = (props) => {
  const { reportId } = props;

  const { loading, error, data } =
    useQuery<reports.ReportTextureClusterResponse>(ReportTextureClustersQuery, {
      variables: {
        reportID: reportId,
      },
    });

  if (
    loading ||
    error ||
    csvData(mapAndRegroupClusterTexturesMinConfidence(data, 0)).length == 0
  ) {
    return <Box />;
  }

  return (
    <DataDownloadTile
      tileType={TileType.CSV}
      headers={['Cluster', 'Textures']}
      csvDataRows={csvData(mapAndRegroupClusterTexturesMinConfidence(data, 0))}
      fileName="TextureClusterSummaryTable.csv"
      tileName="Texture Cluster Summary Table"
    />
  );
};
export default TextureClusterSummaryTable;
