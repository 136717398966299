import React, { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { hasCustomClassDbProducts, isSuccessful } from 'utils/reportUtils';

import CustomClassDbModal from './CustomClassDbModal';
import ApproveMapOnlyMenuItem from './items/ApproveMapOnlyMenuItem';
import ApproveReportMenuItem from './items/ApproveReportMenuItem';
import CloneReportMenuItem from './items/CloneReportMenuItem';
import CustomClassDbReportMenuItem from './items/CustomClassDbReportMenuItem';
import DisapproveReportMenuItem from './items/DisapproveReportMenuItem';
import RejectReportMenuItem from './items/RejectReportMenuItem';
import UnRejectReportMenuItem from './items/UnRejectReportMenuItem';
import UpdateReportMenuItem from './items/UpdateReportMenuItem';
import ViewReportMenuItem from './items/ViewReportMenuItem';
import useStyles from './useStyles';
import i18n from '../../i18n';

interface Props {
  updateReportJobStatus: (
    id: number,
    status: boolean,
    mapOnly: boolean,
    currentData: any[],
  ) => Promise<void>;
  res: reports.AllReportsQueryRow[];
  localData: any;
  setShowApprovalModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMapOnlyApprovalModal: React.Dispatch<React.SetStateAction<boolean>>;
  setReportId: React.Dispatch<React.SetStateAction<string>>;
  setReportRowId: React.Dispatch<React.SetStateAction<number>>;
}

const ReportViewerMenu: React.FC<Props> = ({
  updateReportJobStatus,
  res,
  localData,
  setShowApprovalModal,
  setShowMapOnlyApprovalModal,
  setReportId,
  setReportRowId,
}) => {
  const classes = useStyles(useTheme());
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showCustomClassDbModal, setShowCustomClassDbModal] =
    useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
        {res[0].passedQa === true && (
          <span className={classes.text}>{i18n.t('reports.qaApproved')}</span>
        )}
        {res[0].passedQa === false && (
          <span className={classes.text}>{i18n.t('reports.qaRejected')}</span>
        )}
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* Always allow cloning */}
        <CloneReportMenuItem handleClose={handleClose} report={res[0]} />

        {
          /* If successful and not reviewed, allow viewing */
          isSuccessful(res[0]) && res[0].passedQa === null && (
            <ViewReportMenuItem handleClose={handleClose} report={res[0]} />
          )
        }

        {
          /* If successful and not reviewed, allow approving */
          isSuccessful(res[0]) && res[0].passedQa === null && (
            <ApproveReportMenuItem
              handleClose={handleClose}
              report={res[0]}
              setShowApprovalModal={setShowApprovalModal}
              setReportId={setReportId}
              setReportRowId={setReportRowId}
            />
          )
        }

        {
          /* If successful and not reviewed, allow rejecting */
          isSuccessful(res[0]) && res[0].passedQa === null && (
            <RejectReportMenuItem
              handleClose={handleClose}
              report={res[0]}
              updateReportJobStatus={updateReportJobStatus}
              localData={localData}
            />
          )
        }

        {
          /* If successful and rejected, allow un-rejecting */
          isSuccessful(res[0]) && res[0].passedQa === false && (
            <UnRejectReportMenuItem
              handleClose={handleClose}
              report={res[0]}
              updateReportJobStatus={updateReportJobStatus}
              localData={localData}
            />
          )
        }

        {
          /* If approved , allow disappoving */
          res[0].passedQa && res[0].noOfProjectsAddedTo === 0 && (
            <DisapproveReportMenuItem
              handleClose={handleClose}
              report={res[0]}
              updateReportJobStatus={updateReportJobStatus}
              localData={localData}
            />
          )
        }

        {
          /* If successful, allow running update products*/
          isSuccessful(res[0]) && (
            <UpdateReportMenuItem handleClose={handleClose} report={res[0]} />
          )
        }

        {
          /* If successful and not reviewed, allow approving only market map */
          isSuccessful(res[0]) && res[0].passedQa === null && (
            <ApproveMapOnlyMenuItem
              handleClose={handleClose}
              report={res[0]}
              setShowMapOnlyApprovalModal={setShowMapOnlyApprovalModal}
              setReportId={setReportId}
              setReportRowId={setReportRowId}
            />
          )
        }

        {
          /* If has customClassDb , allow modal */
          hasCustomClassDbProducts(res[0]) && (
            <CustomClassDbReportMenuItem
              handleClose={handleClose}
              report={res[0]}
              setShowCustomClassDbModal={setShowCustomClassDbModal}
            />
          )
        }
      </Menu>

      {/* Modals */}
      <CustomClassDbModal
        show={showCustomClassDbModal}
        close={() => setShowCustomClassDbModal(false)}
        report={res[0]}
      />
    </div>
  );
};

ReportViewerMenu.displayName = 'ReportViewerMenu';

export default ReportViewerMenu;
