interface AuthenticationFormValues {
  email?: string;
  password?: string;
}

export default function validateAuthenticationForm(values: AuthenticationFormValues = {}) {
  return {
    email: !values.email && 'Email is missing',
    password: !values.password && 'Password is missing'
  };
}
