import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '../../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootLabel: {
      fontFamily: 'OpenSans',
      fontSize: 14,
      color: COLORS.MARINE_FADED,
    },
    root: {
      flex: 1,
      '&$focused $notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },
    },
    underline: {
      '&:before': {
        borderBottomColor: COLORS.MARINE_FADED,
      },
      '&:after': {
        borderBottomColor: theme.palette.secondary.main,
      },
    },
    notchedOutline: {
      borderColor: COLORS.MARINE_FADED,
    },
    focused: {},
    input: {
      fontSize: 15,
      '&::placeholder': {
        fontFamily: 'OpenSans',
        fontSize: 14,
        color: COLORS.MARINE_FADED,
      },
    },
  }),
);

export default useStyles;
