import { Continent } from "constants/country";

export const continents: { code: Continent | 'WW', imgfile: string, transform: { k: number, x: number, y: number } }[] = [
  {
    code: 'WW',
    imgfile: require('../../../public/assets/continent_icons/noun-default.svg'),
    transform: { k: 1, x: -1, y: -1 }
  },
  {
    code: 'NA',
    imgfile: require('../../../public/assets/continent_icons/noun-north-america-3353557.svg'),
    transform: { k: 2.5811, x: -316.5134, y: -140.4954 }
  },
  {
    code: 'SA',
    imgfile: require('../../../public/assets/continent_icons/noun-south-america-3353553.svg'),
    transform: { k: 2.2038, x: -404.9976, y: -873.8509 }
  },
  {
    code: 'EU',
    imgfile: require('../../../public/assets/continent_icons/noun-europe-2409654.svg'),
    transform: { k: 4, x: -2751.8195, y: -355.9229 }
  },
  {
    code: 'AF',
    imgfile: require('../../../public/assets/continent_icons/noun-africa-3353552.svg'),
    transform: { k: 2.45, x: -1424.6553, y: -723.1631 }
  },
  {
    code: 'AS',
    imgfile: require('../../../public/assets/continent_icons/noun-asia-3353555.svg'),
    transform: { k: 2.2038, x: -1853.9427, y: -245.9554 }
  },
  {
    code: 'OC',
    imgfile: require('../../../public/assets/continent_icons/noun-australia-1449426.svg'),
    transform: { k: 4, x: -4800, y: -2084.4933 }
  },
];