import * as React from 'react';
import { useState } from 'react';

import { Box, LinearProgress, useTheme } from '@mui/material';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import dataTableCsv from 'components/ReactDataTable/ReactDataTableCsv';
import TableSearch from 'components/Table/TableSearch';
import useMiniGlp, { MiniGlpProduct } from 'hooks/useMiniGlp';
import useWindowSize from 'hooks/useWindowSize';
import { debounce } from 'lodash';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import { tableCustomStyles } from '../ReactDataTable/tableCustomStyles';

const ProductSearchTable: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { width, widthBelow1300 } = useWindowSize();

  const { data: miniglp, loading, error } = useMiniGlp();
  const [term, setTerm] = useState<string>('');

  const filterData = (miniglp: MiniGlpProduct[], term: string) => {
    if (!term.length) return miniglp;
    const updateList =
      miniglp &&
      miniglp.filter((row) =>
        JSON.stringify(row).toLowerCase().includes(term.toLowerCase()),
      );
    return updateList;
  };

  const columns: TableColumn<MiniGlpProduct>[] = [
    {
      id: 'product_id',
      selector: (row: MiniGlpProduct) => row.product_id,
      name: 'ID',
      sortable: true,
      width: '80px',
    },
    {
      id: 'product',
      selector: (row: MiniGlpProduct) => row.product,
      name: 'Name',
      sortable: true,
      wrap: true,
    },
    {
      id: 'producer',
      selector: (row: MiniGlpProduct) => row.producer,
      name: 'Producer',
      sortable: true,
      wrap: true,
      width: '175px',
    },
    {
      id: 'countries_of_purchase',
      selector: (row: MiniGlpProduct) => {
        return row.countries_of_purchase
          .filter((i) => i !== null && i !== undefined)
          .join(', ');
      },
      name: 'Origin',
      sortable: true,
      wrap: true,
      width: '110px',
      omit: widthBelow1300,
    },
    {
      id: 'countries',
      selector: (row: MiniGlpProduct) => {
        return row.countries
          .filter((i) => i !== null && i !== undefined)
          .join(', ');
      },
      name: 'Countries',
      sortable: true,
      wrap: true,
      width: '110px',
      omit: widthBelow1300,
    },
    {
      id: 'category_name',
      selector: (row: MiniGlpProduct) => row.category_name,
      name: 'Category',
      sortable: true,
      wrap: true,
      width: '125px',
    },
    {
      id: 'reviews',
      selector: (row: MiniGlpProduct) => row.reviews,
      name: 'Reviews',
      sortable: true,
      width: '100px',
    },
  ];

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <h4>Can't load products!</h4>;
  }

  return (
    <Box>
      <TableSearch
        onSearch={debounce((e) => setTerm(e), 300)}
        placeholder={t('general.search')}
      />
      <DataTable
        columns={columns}
        data={filterData(miniglp, term)}
        customStyles={tableCustomStyles(theme, true)}
        actions={dataTableCsv(
          `product-search-result.csv`,
          columns,
          filterData(miniglp, term),
        )}
        dense
        striped
        defaultSortFieldId="product_id"
        defaultSortAsc={false}
        pagination
        paginationIconFirstPage={<RDTFirstPage />}
        paginationIconLastPage={<RDTLastPage />}
        paginationIconPrevious={<RDTPrevPage />}
        paginationIconNext={<RTDNextPage />}
        paginationPerPage={20}
        paginationComponentOptions={{
          selectAllRowsItem:
            filterData(miniglp, term).length < 200 ? true : false,
        }}
      />
    </Box>
  );
};

export default ProductSearchTable;
