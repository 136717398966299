import { gql } from '@apollo/client';

export default gql`
  query MarketMapProducts($reportID: UUID!) {
    allRpMarketmapProductvectors(condition: { reportId: $reportID }) {
      nodes {
        product: productByProductId {
          id
          name
          productComponentBaseProductsByProductId {
            nodes {
              productComponentBaseByProductComponentBaseId {
                name
              }
            }
          }
          productFeatureProductsByProductId {
            nodes {
              productFeatureByProductFeatureId {
                name
              }
            }
          }
        }
        version
        pq
        ld1
        ld2
      }
    }
  }
`;
