import { gql } from '@apollo/client';

export default gql`
  query TextureMapProducts($reportID: UUID!) {
    allRpTexturemapProductvectors(condition: { reportId: $reportID }) {
      nodes {
        product
        productId
        version
        ld1
        ld2
        pq
        weight
      }
    }
  }
`;
