import { gql } from '@apollo/client';

export default gql`
query GetReportTextureCount($reportID: UUID!) {
  reportJobByReportId(reportId: $reportID) {
    id
    rpTextureClustersByReportId {
      totalCount
    }
  }
}
`;
