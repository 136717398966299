import { useState } from 'react';

import { Search as SearchIcon, Close } from '@mui/icons-material';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

const TableSearch: React.FC<{
  onSearch: (term: string) => void;
  placeholder: string;
}> = ({ onSearch, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const searchItem = (term: string) => {
    onSearch(term);
  };

  return (
    <Input
      endAdornment={
        <InputAdornment position="end">
          {searchTerm.length ? (
            <Close
              onClick={() => {
                setSearchTerm('');
                onSearch('');
              }}
            />
          ) : (
            <SearchIcon />
          )}
        </InputAdornment>
      }
      onChange={(event) => {
        setSearchTerm(event.target.value);
        onSearch(event.target.value);
      }}
      value={searchTerm}
      placeholder={placeholder}
    />
  );
};

export default TableSearch;
