import { groupBy } from 'lodash';

/**
 * Orders the found textures by confidence, but picking one from each cluster first
 *
 * @param reportTextureClusterResponse graphQL response of texture cluster words
 * @returns sorted array of texture cluster terms
 */
export const orderedTextureWords = (
  reportTextureClusterResponse: reports.ReportTextureClusterResponse,
): reports.AllRpTextureClustersNode[] => {
  const safeNodes: reports.AllRpTextureClustersNode[] =
    reportTextureClusterResponse?.allRpTextureClusters?.nodes ?? [];

  const groupedNodes = groupBy(
    [...safeNodes].sort((a, b) => b.confidence - a.confidence),
    (i: reports.AllRpTextureClustersNode) => i.clusterIdx,
  );

  const maxIdx: number = safeNodes
    .map((i) => i.clusterIdx)
    .reduce((a, b) => (a > b ? a : b), 1);

  const ret: reports.AllRpTextureClustersNode[] = [];
  for (let j = 0; j < safeNodes.length; j++) {
    for (let i = 1; i <= maxIdx; i++) {
      const item = groupedNodes[i][j];

      if (item) {
        ret.push(item);
      }
    }
  }

  return ret;
};
