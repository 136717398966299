import * as React from 'react';

import { Modal, Theme, Typography, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MaterialButton from 'components/MaterialButton';
import { useTranslation } from 'react-i18next';

import DemographicsGroup from './DemographicsGroup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      minWidth: '30%',
      maxWidth: '60%',
      maxHeight: '80%',
      backgroundColor: '#ffffff',
      borderRadius: '5px',
      padding: '1rem',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
      overflow: 'auto',
    },
    buttonTray: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',
      marginTop: '10px',
    },
  }),
);

interface Props {
  reportId: string;
  displayOnly: boolean;
  cancelButtonText: string;
  cancelFunction: () => void;
  confirmButtonText: string;
  confirmFunction: () => void;
}

const ApprovalModal: React.FC<Props> = (props) => {
  const {
    reportId,
    displayOnly,
    cancelButtonText,
    cancelFunction,
    confirmButtonText,
    confirmFunction,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles(useTheme());

  return (
    <Modal className={classes.modal} open={!!reportId} onClose={cancelFunction}>
      <React.Fragment>
        {!!reportId && (
          <div className={classes.modalContainer}>
            <Typography variant="h4" align="center">
              {displayOnly
                ? t('reports.report') + ' ' + t('reports.demographics')
                : t('reportsQa.approvalModalTitle')}
            </Typography>

            <DemographicsGroup reportId={reportId} />

            {!displayOnly && (
              <div className={classes.buttonTray}>
                <MaterialButton
                  variant="outlined"
                  soft
                  onClick={cancelFunction}
                >
                  {cancelButtonText}
                </MaterialButton>
                <MaterialButton
                  variant="outlined"
                  soft
                  teal
                  onClick={confirmFunction}
                >
                  {confirmButtonText}
                </MaterialButton>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    </Modal>
  );
};

export default ApprovalModal;
