import { mapFromKey } from 'constants/ggVars';

import AddIcon from '@mui/icons-material/Add';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RemoveIcon from '@mui/icons-material/Remove';

/**
 * Create impact type enum
 */
export enum ImpactType {
  NEGATIVE = -1,
  NEUTRAL = 0,
  POSITIVE = 1,
}

export interface PieChartData {
  id: string;
  value: number;
}

export const getImpactIcon = (impactType: ImpactType) => {
  switch (impactType) {
    case ImpactType.POSITIVE:
      return <AddIcon style={{ color: 'green' }} />;
    case ImpactType.NEGATIVE:
      return <RemoveIcon style={{ color: 'red' }} />;
    case ImpactType.NEUTRAL:
    default:
      return <DragHandleIcon style={{ color: 'black' }} />;
  }
};

export const getFlavorDecompPieChartData = (
  nodes: reports.ReportFpDecompRow[],
) => {
  const overallDecompValue = nodes
    .map((n) => n.decomp)
    .reduce((a, b) => a + parseFloat(b), 0);

  // TODO Use groupBy once upgraded to node 21
  const groupedData = nodes.reduce<Record<string, number>>(
    (acc, current) => {
      const ggvar = mapFromKey(current.ggVars);
      if (!acc[ggvar.label]) {
        acc[ggvar.label] = parseFloat(current.decomp);
      } else {
        acc[ggvar.label] += parseFloat(current.decomp);
      }
      return acc;
    },
    {} as Record<string, number>,
  );

  const pieChartData = Object.entries(groupedData)
    .map(([ggvar, decomp]) => ({
      id: ggvar,
      value: Math.round((decomp / overallDecompValue) * 100),
    }))
    .reduce((a: PieChartData[], b: PieChartData) => {
      if (b.value > 1) {
        return a.concat({
          id: b.id,
          value: b.value,
        });
      } else {
        const others = a.find((i: PieChartData) => i.id === 'Other GGVars');
        const rest = a.filter((i) => i.id !== 'Other GGVars');
        return rest.concat([
          {
            id: 'Other GGVars',
            value: (others?.value ?? 0) + b.value,
          },
        ]);
      }
    }, []);

  return pieChartData;
};
