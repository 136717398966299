import * as React from 'react';

import { useQuery } from '@apollo/client';
import PQPredictionRpOfpQuery from '@graphql/queries/PQPredictionRpOfpQuery';
import { Grid, MenuItem, Select } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import useReportSummary, { ProductVersionPq } from 'hooks/useReportSummary';

import OFPTable from './OFPTable';
import { PQPredictionRPOfpResponse } from './pqModelTypes';
import { ParsedPQModel } from './pqModelUtils';

interface Props {
  reportId: string;
  model: ParsedPQModel;
}

const QueryProduct: React.FC<Props> = ({ reportId, model }) => {
  const [selectedProduct, setSelectedProduct] =
    React.useState<ProductVersionPq>(null);
  const [productIdToQuery, setProductIdToQuery] = React.useState<string>('');

  const {
    data: ofpData,
    loading: ofpLoading,
    error: ofpError,
  } = useQuery<PQPredictionRPOfpResponse>(PQPredictionRpOfpQuery, {
    variables: {
      reportID: reportId,
      productId: parseInt(productIdToQuery),
    },
  });

  const {
    productVersionPqs,
    loading: summaryLoading,
    error: summaryError,
  } = useReportSummary(reportId);

  if (ofpLoading || ofpError || summaryLoading || summaryError) {
    return <LoadingScreen />;
  }

  const originalPQ = productVersionPqs.find(
    (productVersionPQ) =>
      productVersionPQ.productVersion.productId === parseInt(productIdToQuery),
  )?.pq;

  return (
    <Grid container>
      <Grid item xs={4}>
        <Select
          variant="standard"
          id="product-id-select"
          value={productIdToQuery?.toString()}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setProductIdToQuery(event.target.value)
          }
        >
          {productVersionPqs.map((productVersionPq) => (
            <MenuItem
              key={productVersionPq.productVersion.value}
              value={productVersionPq.productVersion.productId}
            >
              {productVersionPq.productVersion.productId}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        {!!ofpError && <h3>Error in loading OFP, product ID likely invalid</h3>}
        {!!summaryError && (
          <h3>Error in loading summary, product ID likely invalid</h3>
        )}
        {!ofpLoading && !summaryLoading && ofpData && (
          <OFPTable
            model={model}
            inputRow={ofpData.allRpOfps.nodes}
            originalPQ={originalPQ}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default QueryProduct;
