import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      height: '60px',
      '&:hover': {
        '> .text': {
          color: theme.palette.primary.main,
        },
        '> .icon': {
          color: theme.palette.primary.main,
        },
        '> .accessory': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
    linkActive: {
      '& $text': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
      '& $icon': {
        color: theme.palette.primary.main,
      },
      '& $accessory': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      marginRight: '20px',
      width: '24px',
    },
    text: {
      color: theme.palette.primary.main,
      fontSize: '16px',
      fontWeight: 'normal',
    },
    accessory: {
      width: '3px;',
      height: '40px',
      marginRight: '20px',
    },
  }),
);

export default useStyles;
