import { gql } from '@apollo/client';

export default gql`
  mutation DeleteReferenceFlavorMutation($id: Int!, $deleted: Boolean!) {
    updateReferenceFlavorNewById(
      input: { id: $id, referenceFlavorNewPatch: { visible: $deleted } }
    ) {
      referenceFlavorNew {
        id
        visible
      }
    }
  }
`;
