import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    highestScore: {
      fontWeight: 'bold',
      fontSize: 15,
    },
  }),
);

export default useStyles;
