export default function httpGetPartnerAdmins(partnerId: number, token: string) {
  return fetch(`/iam/partnerAdminUserIds?partnerId=${partnerId}`, {
    method: 'GET',
    headers: {
      Authorization: token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.text().then((message) => {
        throw new Error(message);
      });
    }
  });
}
