import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import AllRpCategoryTimeSeriesQuery, {
  CategoryTimeSeriesResponse,
} from '@graphql/queries/AllRpCategoryTimeSeriesQuery';
import AllRpProductTimeSeriesQuery, {
  ProductTimeSeriesResponse,
} from '@graphql/queries/AllRpProductTimeSeriesQuery';
import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { getProductName } from 'components/Report/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { useTranslation } from 'react-i18next';

import TimeSeriesPlot from '../../../components/TimeSeries/ProductTimeSeriesPqChart';

export interface TimeSeriesRow {
  seriesId: string;
  year: number;
  pq: number;
  error: number;
  isFuture: boolean;
}
interface Props {
  projectId: number;
  reportId: string;
}

const TrendAnalysis: React.FC<Props> = (props) => {
  const { projectId, reportId } = props;

  const { t } = useTranslation();
  const [pqDeltaYoY, setPqDeltaYoY] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(true);
  const [productCategoryComparison, setProductCategoryComparison] =
    useState<boolean>(false);

  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const {
    loading: productsLoading,
    error: productsError,
    data: productsData,
  } = useQuery<ProductTimeSeriesResponse>(AllRpProductTimeSeriesQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const {
    loading: categoryLoading,
    error: categoryError,
    data: categoryData,
  } = useQuery<CategoryTimeSeriesResponse>(AllRpCategoryTimeSeriesQuery, {
    variables: {
      reportID: reportId,
    },
  });

  const getTimeSeriesProductData = (
    data: ProductTimeSeriesResponse,
    customProductNames: reports.ColorVersionedProductInfo[],
  ) => {
    return data.allRpProductTimeseries.nodes.map((n) => ({
      ...n,
      seriesId: getProductName({
        productNames: customProductNames,
        productId: n.productByProductId.id,
        version: n.version,
      }),
    }));
  };

  const getCategoryTimeSeriesData = (
    categoryData: CategoryTimeSeriesResponse,
  ) => {
    return categoryData.allRpCategoryTimeseries.nodes.map((n) => ({
      ...n,
      seriesId: n.category,
    }));
  };

  const productCategoryTimeSeriesComparison = (
    productData: ProductTimeSeriesResponse,
    categoryData: CategoryTimeSeriesResponse,
    customProductNames: reports.ColorVersionedProductInfo[],
  ) => {
    const productSeries = getTimeSeriesProductData(
      productData,
      customProductNames,
    );
    const categorySeries = getCategoryTimeSeriesData(categoryData);
    const productYears = productSeries.map((d) => d.year);
    const categoryYears = categorySeries.map((d) => d.year);
    const missingCategoryYears = productYears.filter(
      (d) => !categoryYears.includes(d),
    );
    if (missingCategoryYears.length > 0) {
      throw Error(
        `Time series data missing for category (but present for product) for years: ${missingCategoryYears}`,
      );
    }
    return productSeries.map((d) => ({
      ...d,
      pq: d.pq - categorySeries.filter((c) => c.year == d.year)[0].pq,
    }));
  };

  const getTimeSeriesData = (
    productData: ProductTimeSeriesResponse,
    customProductNames: reports.ColorVersionedProductInfo[],
    categoryData?: CategoryTimeSeriesResponse,
    productCategoryComparison?: boolean,
  ) => {
    if (productCategoryComparison) {
      return productCategoryTimeSeriesComparison(
        productData,
        categoryData,
        customProductNames,
      );
    }
    return getTimeSeriesProductData(productData, customProductNames);
  };

  const getMinPq = (
    data: ProductTimeSeriesResponse,
    cateData: CategoryTimeSeriesResponse,
    customProductNames: reports.ColorVersionedProductInfo[],
  ) => {
    return Math.min(
      ...getTimeSeriesData(data, customProductNames).map((d) => d.pq),
      ...getCategoryTimeSeriesData(cateData).map((d) => d.pq),
    );
  };

  const getMaxPq = (
    data: ProductTimeSeriesResponse,
    cateData: CategoryTimeSeriesResponse,
    customProductNames: reports.ColorVersionedProductInfo[],
  ) => {
    return Math.max(
      ...getTimeSeriesData(data, customProductNames).map((d) => d.pq),
      ...getCategoryTimeSeriesData(cateData).map((d) => d.pq),
    );
  };

  if (productsLoading || productsError || categoryLoading || categoryError) {
    return <LoadingScreen />;
  }

  if (
    getTimeSeriesData(productsData, productNames).length === 0 &&
    getCategoryTimeSeriesData(categoryData)
  ) {
    return (
      <Typography variant="h4" align="center">
        {t('reports.timeSeries.noData')}
      </Typography>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">
          {t('reports.timeSeries.productTrends')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={showError}
              onChange={(e) => setShowError(e.target.checked)}
              name="showError"
            />
          }
          label={
            <Typography variant="caption">
              {t('reports.timeSeries.showError')}
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Switch
              checked={pqDeltaYoY}
              onChange={(e) => setPqDeltaYoY(e.target.checked)}
              name="pqDeltaYoY"
            />
          }
          label={
            <Typography variant="caption">
              {t('reports.timeSeries.deltaYoY')}
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Switch
              checked={productCategoryComparison}
              onChange={(e) => setProductCategoryComparison(e.target.checked)}
              name="productCategoryComparison"
            />
          }
          label={
            <Typography variant="caption">
              {t('reports.timeSeries.compareProductCategory')}
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TimeSeriesPlot
          timeSeriesData={getTimeSeriesData(
            productsData,
            productNames,
            categoryData,
            productCategoryComparison,
          )}
          minPQ={getMinPq(productsData, categoryData, productNames)}
          maxPQ={getMaxPq(productsData, categoryData, productNames)}
          useSharedPQ={!(pqDeltaYoY || productCategoryComparison)}
          minYear={Math.min(
            ...getTimeSeriesData(productsData, productNames).map((d) => d.year),
          )}
          maxYear={Math.max(
            ...getTimeSeriesData(productsData, productNames).map((d) => d.year),
          )}
          showError={showError}
          deltaYoY={pqDeltaYoY}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">
          {t('reports.timeSeries.categoryTrends')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TimeSeriesPlot
          timeSeriesData={getCategoryTimeSeriesData(categoryData)}
          minPQ={getMinPq(productsData, categoryData, productNames)}
          maxPQ={getMaxPq(productsData, categoryData, productNames)}
          useSharedPQ={true}
          minYear={Math.min(
            ...getCategoryTimeSeriesData(categoryData).map((d) => d.year),
          )}
          maxYear={Math.max(
            ...getCategoryTimeSeriesData(categoryData).map((d) => d.year),
          )}
          showError={showError}
        />
      </Grid>
    </Grid>
  );
};
export default TrendAnalysis;
