import * as React from 'react';

import { useQuery } from '@apollo/client';
import OrderOfOperationsQuery from '@graphql/queries/OrderOfOperationsQuery';
import { Card, CardContent, CardHeader, useTheme } from '@mui/material';
import { Box } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary, {
  ReportSummaryDetailNode,
} from 'hooks/useReportSummary';
import { useTranslation } from 'react-i18next';

import ProductTop3Steps from './ProductTop3Steps';
import useStyles from '../useStyles';

interface Props {
  projectId: number;
  reportId: string;
}

const getTopProductOOO = (
  ofpData: reports.OrderOfOperationsData,
  topProduct: ReportSummaryDetailNode,
) =>
  ofpData.allRpOptSteps.nodes
    .filter(
      (f) =>
        f.productId === topProduct.node.productByProductId.id &&
        f.version === topProduct.node.version,
    )
    .sort((a: reports.OrderOfOperationNode, b: reports.OrderOfOperationNode) =>
      a.stepIdx > b.stepIdx ? 1 : -1,
    )
    .filter((f) => !f.refFlavor.includes('generic_'))
    .slice(0, 3);

const getTopProductAltVersionsOOO = (
  ofpData: reports.OrderOfOperationsData,
  topProduct: ReportSummaryDetailNode,
) => {
  const productOptStepNodes = ofpData.allRpOptSteps.nodes.filter(
    (f) =>
      f.productId === topProduct.node.productByProductId.id &&
      f.version != topProduct.node.version,
  );
  const versions = Array.from(
    new Set(productOptStepNodes.map((f) => f.version)),
  );
  return versions.map((v) =>
    productOptStepNodes
      .filter((f) => f.version === v)
      .sort(
        (a: reports.OrderOfOperationNode, b: reports.OrderOfOperationNode) =>
          a.stepIdx > b.stepIdx ? 1 : -1,
      )
      .filter((f) => !f.refFlavor.includes('generic_'))
      .slice(0, 3),
  );
};

const OptimizationTop3Steps: React.FC<Props> = (props) => {
  const { projectId, reportId } = props;

  const { t } = useTranslation();
  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const classes = useStyles(useTheme());

  const {
    loading: ofpLoading,
    error: ofpError,
    data: ofpData,
  } = useQuery<reports.OrderOfOperationsData>(OrderOfOperationsQuery, {
    variables: { reportID: reportId },
  });

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    data: reportSummaryData,
    topProduct,
  } = useReportSummary(reportId);

  if (reportSummaryLoading || ofpLoading) {
    return <LoadingScreen />;
  }

  if (!reportSummaryData || reportSummaryError || !ofpData || ofpError) {
    return <Box />;
  }

  if (!topProduct) {
    return <div></div>;
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <CardHeader
          title={t('reports.optimizationTopThree.title')}
          className={classes.header}
        />
        <ProductTop3Steps
          nodes={getTopProductOOO(ofpData, topProduct)}
          productNames={productNames}
          isTop
        />
        {getTopProductAltVersionsOOO(ofpData, topProduct)
          .filter((altProductNodes) => altProductNodes.length > 0)
          .map((altProductNodes) => {
            return (
              <ProductTop3Steps
                nodes={altProductNodes}
                productNames={productNames}
              />
            );
          })}
      </CardContent>
    </Card>
  );
};

export default OptimizationTop3Steps;
