import * as React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Paper } from '@mui/material';
import MaterialButton from 'components/MaterialButton';
import TableSearch from 'components/Table/TableSearch';
import ConditionViewerRoleContainer from 'containers/ConditionViewerRole';
import { uniq } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './Project.module.css';
import ProjectListTable from './ProjectListTable';
import LoadingScreen from '../../components/LoadingScreen';
import { PROJECT_CREATE } from '../../constants/routePaths';
import GetProjectsForWorkspace from '../../graphql/queries/WorkspaceProjects';
import selectTokenRoles from '../../selectors/sessionTokenRoles';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import { PageHeader } from '../../styles/themeComponents';
import {
  hasAdminRole,
  hasPartnerAdminRole,
  hasWorkspaceAdminRole,
} from '../../utils/roleUtils';

export interface ProjectInformation {
  projectId: number;
  projectName: string;
  productsIncluded: string;
  dateCreated: string;
  lastReportStarted: number; //millis since epoch
  status: string;
  approved: boolean;
}

const Project: React.FC = (props) => {
  const { t } = useTranslation();

  const [currentSearchTerm, setCurrentSearchTeam] = React.useState<string>('');

  const workspaceProducerId = useSelector((state) =>
    selectWorkspaceProducerId(state),
  );
  const userRoles = useSelector((state) => selectTokenRoles(state));

  const { loading, error, data, refetch } =
    useQuery<reports.ProjectsForWorkspaceResponse>(GetProjectsForWorkspace, {
      variables: {
        workspaceId: workspaceProducerId ?? -1,
      },
      fetchPolicy: 'no-cache',
    });

  const flattenProducts = (
    projectReports: reports.ProjectReportRow[],
    customNames: reports.CustomProductNamesDetailRow[],
  ): string => {
    if (projectReports.filter((pr) => !!pr).length > 0) {
      // Get all the products contained in the project reports
      const origProjectProducts = projectReports
        .map((report: reports.ProjectReportRow) => report.reportJobByReportId)
        .map((rj) => rj.reportSummariesByReportId.nodes)
        .map((summary) => summary.map((s) => s.productByProductId))
        .reduce((a, b) => a.concat(b), []);

      // Replace any product names with custom names for the project
      const finalProductNames = origProjectProducts.map((originalProduct) => {
        if (
          customNames.map((cn) => cn.productId).includes(originalProduct.id)
        ) {
          return customNames.find(
            (customName) => customName.productId === originalProduct.id,
          ).productName;
        } else {
          return originalProduct.name;
        }
      });

      // Return the comma separated unique list
      return uniq(finalProductNames).sort().join(', ');
    } else {
      return '';
    }
  };

  const hasAnyReports = (project) => {
    return (
      project &&
      project.projectReportsByProjectId &&
      project.projectReportsByProjectId.nodes &&
      project.projectReportsByProjectId.nodes.length > 0
    );
  };

  const getReshapedData = (
    workspaceProjects: reports.ProjectsForWorkspaceResponse,
  ): ProjectInformation[] => {
    if (workspaceProjects) {
      return workspaceProjects?.projectsForWorkspace?.nodes.map(
        (project: reports.ProjectsForWorkspaceRow) => ({
          projectId: project.id,
          projectName: project.name,
          productsIncluded: flattenProducts(
            project.projectReportsByProjectId.nodes,
            project.customPqProductNamesByProjectId.nodes,
          ),
          dateCreated: moment(project.createdDate).format('MM/DD/YYYY'),
          lastReportStarted: moment(
            project.projectReportsByProjectId.nodes
              .map((r) => r.reportJobByReportId.startedAt)
              .sort()
              .reverse()[0],
          ).valueOf(),
          status: hasAnyReports(project) ? 'SUCCESS' : 'PENDING',
          approved: project.approved,
        }),
      );
    } else {
      return [];
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (error !== undefined) {
    return <div>Projects Data Not Found!</div>;
  }

  return (
    <Paper className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTextContainer}>
          <PageHeader>{t('project.projects')}</PageHeader>
          <h3 className={styles.projectTitle}>{t('project.listTable')}</h3>
        </div>
        <Box justifyContent="center" alignItems="center" display="flex">
          <TableSearch
            onSearch={(e) => setCurrentSearchTeam(e)}
            placeholder={t('general.search')}
          />
        </Box>
        <ConditionViewerRoleContainer
          render={(viewerRoles) =>
            viewerRoles.viewerRoleIsAdmin ||
            viewerRoles.viewerRoleIsPartnerAdmin ? (
              <div>
                <Link to={PROJECT_CREATE}>
                  <MaterialButton soft teal>
                    Create New Project
                  </MaterialButton>
                </Link>
              </div>
            ) : null
          }
        />
      </div>

      <ProjectListTable
        currentSearchTerm={currentSearchTerm}
        projectReports={getReshapedData(data)}
        refetchProjects={refetch}
        viewerRoleIsAdmin={hasAdminRole(userRoles)}
        viewerRoleIsWorkspaceAdmin={hasWorkspaceAdminRole(userRoles)}
        viewerRoleIsPartnerAdmin={hasPartnerAdminRole(userRoles)}
        {...props}
      />
    </Paper>
  );
};

export default Project;
