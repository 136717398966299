import { connect } from 'react-redux';

import ProductReviewList from './productReviewList';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';

const mapStateToProps = (state) => ({
  producerId: selectWorkspaceProducerId(state),
});

export default connect(mapStateToProps)(ProductReviewList);
