import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import useStyles from 'components/FieldCheckBox/useStyles';

interface Props {
  input: any;
  label?: string;
  optionLabel: string;
  helperText?: string;
  val?: boolean;
  value?: string;
  checkboxContainer?: any;
}

const FieldCheckBox: React.FunctionComponent<Props> = ({
  input,
  label,
  optionLabel,
  helperText,
  val,
  value,
  checkboxContainer,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div>
      <div
        className={
          checkboxContainer
            ? classes.checkboxContainer
            : classes.actionContainer
        }
      >
        <FormLabel
          component="label"
          className={checkboxContainer ? classes.checkboxLabel : classes.root}
        >
          {label && label}
        </FormLabel>
        <FormControlLabel
          style={
            checkboxContainer
              ? { margin: 'auto', marginLeft: 5 }
              : { marginBottom: 0 }
          }
          className={checkboxContainer ? classes.checkboxLabel : classes.label}
          control={
            <Checkbox
              {...input}
              {...rest}
              checked={val ? val : !!input.value}
              color="secondary"
              value={value ? value : 'public'}
            />
          }
          label={optionLabel}
        />
      </div>
      {helperText ? (
        <FormHelperText className={classes.helperText}>
          {helperText}
        </FormHelperText>
      ) : null}
    </div>
  );
};

export default FieldCheckBox;
