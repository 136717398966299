import { gql } from '@apollo/client';

export default gql`
  query PanelDataQualityQuery($panelPin: String!) {
    allDataQualityViews(filter: { pin: { in: [$panelPin] } }) {
      edges {
        node {
          email
          userId
          totalReviews
          age
          gender
          userCategory
          socioEconomicStatus
          interfaceLanguage
          pin
          appRevision
          productId
          productName
          perceivedQuality
          referenceFlavors
          textures
          startTime
          endTime
          astringent
          bitter
          coldFinish
          dairy
          dry
          earthy
          floral
          fruits
          gamey
          herbaceous
          marine
          meaty
          mineral
          mouthfeel
          nutsAndSeeds
          retronasal
          rich
          roasted
          smoked
          sourAndAcidity
          spices
          sugar
          wet
          woody
        }
      }
    }
  }
`;
