import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import UserWorkspaceListQuery from '@graphql/queries/UserWorkspaceListQuery';
import CancelIcon from '@mui/icons-material/Cancel';
import { LinearProgress, List, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import TableSearch from 'components/Table/TableSearch';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import Alert from 'react-s-alert';

import RemoveUserConfirmationModal from './RemoveUserConfirmationModal';
import styles from './RemoveUserFromWorkspace.module.css';
import { tableCustomStyles } from '../../components/ReactDataTable/tableCustomStyles';
import graphqlClient from '../../consumers/graphqlClient';
import DeleteProducerUser from '../../graphql/mutations/DeleteProducerUser';

interface Props {
  email: string;
}

interface TableRow {
  id: number;
  producerId: number;
  name: string;
  remove: any;
}

const Result: React.FC<Props> = ({ email }) => {
  const { loading, error, data } = useQuery(UserWorkspaceListQuery, {
    variables: {
      email: email,
    },
  });

  const [workspaces, setWorkspaces] = useState<TableRow[]>([]);
  const [workspaceName, setSelectedWorkspaceName] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { t } = useTranslation();
  const theme = useTheme();

  const getData = (data): TableRow[] => {
    return data.map((node) => {
      return {
        id: node.id,
        producerId: node.producer.id,
        name: node.producer.name,
        remove: (
          <IconButton arial-label="Cancel">
            <CancelIcon
              className={styles.cancelIcon}
              onClick={(e) => {
                setOpen(true);
                setSelectedId(node.id);
                setSelectedWorkspaceName(node.producer.name);
              }}
            />
          </IconButton>
        ),
      };
    });
  };

  const AlertType = {
    success: 'success',
    error: 'error',
  };

  const PageAlert = (type, message) => {
    return Alert[AlertType[type]](message, {
      position: 'top-right',
      effect: 'slide',
      beep: false,
      timeout: 4000,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeUserFromWorkspaceConfirmation = async () => {
    const id = selectedId;
    await graphqlClient
      .mutate({
        mutation: DeleteProducerUser,
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: UserWorkspaceListQuery,
            variables: { email: email },
          },
        ],
      })
      .then((res) => {
        handleClose();
        PageAlert(AlertType.success, 'User removed successfully');
      })
      .catch((error) => {
        console.error(error);
        PageAlert(
          AlertType.error,
          'Cound not remove the user. Please try again',
        );
      });
  };

  useEffect(() => {
    if (!loading && data)
      setWorkspaces(getData(data.user.producerUsersByUserId.nodes));
  }, [loading, data]);

  const filterData = (term: string) => {
    if (!term.length)
      return setWorkspaces(getData(data.user.producerUsersByUserId.nodes));
    const updateList =
      data &&
      data.user.producerUsersByUserId.nodes.filter((row) =>
        JSON.stringify(row).toLowerCase().includes(term.toLowerCase()),
      );
    setWorkspaces(getData(updateList));
  };

  const columns: TableColumn<TableRow>[] = [
    {
      selector: (row: TableRow) => row.id,
      name: 'ID',
      omit: true,
    },
    {
      selector: (row: TableRow) => row.producerId,
      name: 'Producer Id',
    },
    {
      selector: (row: TableRow) => row.name,
      name: 'Name',
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.remove,
      name: 'Remove',
      sortable: false,
    },
  ];

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <h4>Can't load workspaces!</h4>;
  }

  if (data.user === null) {
    return <h4>User not found!</h4>;
  }

  return (
    <div>
      <RemoveUserConfirmationModal
        open={open}
        handleSubmit={removeUserFromWorkspaceConfirmation}
        handleClose={handleClose}
        email={email}
        workspaceName={workspaceName}
      />
      <div style={{ width: '100%' }}>
        <List>
          <div className={styles.headerContainer}>
            <h4>
              {data && data.user && data.user.producerUsersByUserId.totalCount}{' '}
              {t('admin.workspaces')}
            </h4>
            <TableSearch
              onSearch={(e) => filterData(e)}
              placeholder={t('general.search')}
            />
          </div>
        </List>
      </div>
      <DataTable
        columns={columns}
        data={workspaces}
        customStyles={tableCustomStyles(theme, true)}
        striped
        pagination
        paginationIconFirstPage={<RDTFirstPage />}
        paginationIconLastPage={<RDTLastPage />}
        paginationIconPrevious={<RDTPrevPage />}
        paginationIconNext={<RTDNextPage />}
      />
    </div>
  );
};

export default Result;
