import * as React from 'react';
import { useState } from 'react';

import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import NoveltyFlavorDecompositionTable from 'components/Report/Novelty/FlavorDecompositionTable';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';
import { ProductVersion } from '../../ProductVersion';
import { getProductName } from '../../utils';

interface ReportProps {
  projectId: number;
  reportId: string;
}

const ReportNoveltySignature: React.FC<ReportProps> = (props) => {
  const { projectId, reportId } = props;

  const [selectedProductId, setSelectedProductId] = useState<string>('');

  const { t } = useTranslation();
  const customProductNames = useCustomProductNames({
    projectId: Number(projectId),
    reportId,
  });

  const classes = useStyles(useTheme());

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          {t('reports.noveltyFpDecomp.title')}
        </Typography>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={8}>
        <Box alignContent="center" alignItems="center">
          <FormControl
            variant="standard"
            fullWidth
            style={{ marginBottom: '1rem' }}
          >
            <InputLabel shrink>{t('reports.product')}</InputLabel>
            <Select
              variant="standard"
              value={selectedProductId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedProductId(e.target.value)
              }
            >
              {customProductNames
                .filter((i) => i.version === null)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((cpn) => new ProductVersion(cpn.id, cpn.version))
                .map((product) => (
                  <MenuItem
                    key={product.value + 'unversioned'}
                    value={product.value}
                  >
                    {getProductName({
                      productNames: customProductNames,
                      productId: product.productId,
                      version: product.version,
                    })}
                  </MenuItem>
                ))}
              <Divider />
              {customProductNames
                .filter((i) => i.version !== null)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((cpn) => new ProductVersion(cpn.id, cpn.version))
                .map((product) => (
                  <MenuItem
                    key={product.value + 'versioned'}
                    value={product.value}
                  >
                    {getProductName({
                      productNames: customProductNames,
                      productId: product.productId,
                      version: product.version,
                    })}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={12}>
        {selectedProductId && (
          <NoveltyFlavorDecompositionTable
            projectId={projectId}
            reportId={reportId}
            productId={Number(selectedProductId.split('|')[0])}
            version={
              selectedProductId.split('|')[1] == 'null'
                ? null
                : selectedProductId.split('|')[1]
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ReportNoveltySignature;
