import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      zIndex: 2,
      maxHeight: '385px',
      width: '100%',
      border: '2px solid var(--marine)',
    },
    tableContainer: {
      maxHeight: '320px',
      overflow: 'auto',
    },
    tableHeader: {
      fontFamily: 'AlphaHeadlinePro-Bold, sans-serif',
      fontSize: '12px',
      color: 'var(--marine)',
    },
    tableRow: {
      fontSize: '12px',
      fontFamily: 'OpenSans, sans-serif',
      color: 'var(--marine)',
      wordWrap: 'break-word',
    },
  }),
);

export default useStyles;
