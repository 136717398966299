import { gql } from '@apollo/client';

export default gql`
  mutation CreatePanelMutation($panel: PanelInput!) {
    createPanel(input: { panel: $panel }) {
      panel {
        id
      }
    }
  }
`;
