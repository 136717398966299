import { STATUS_ENUM } from 'constants/report';

export function isSuccessful(
  reportResultRow: reports.AllReportsQueryRow,
): boolean {
  return (
    reportResultRow.reportStatus &&
    reportResultRow.reportStatus === STATUS_ENUM.SUCCESS
  );
}

export function getCustomClassDbProducts(
  reportResultRow: reports.AllReportsQueryRow,
): string[] {
  const item =
    reportResultRow.params && reportResultRow.params.custom_class_db_products;

  if (!item) return [];
  if (typeof item === 'string') return [item];

  return item;
}

export function hasCustomClassDbProducts(
  reportResultRow: reports.AllReportsQueryRow,
): boolean {
  return !!getCustomClassDbProducts(reportResultRow).length;
}
