import * as React from 'react';

import { round } from 'lodash';
import { CSVLink } from 'react-csv';

import { computeRMax, vectorLen } from './layers/layerUtils';

interface Props {
  ggvarArray: marketmap.LabelData[];
  csvLink: any;
}

interface CsvRow {
  ggvar: string;
  length: number;
}

const DownloadMarketMapVectors: React.FC<Props> = (props) => {
  const { ggvarArray, csvLink } = props;
  const RMax = computeRMax(ggvarArray);

  const csvData: CsvRow[] = ggvarArray.map((n) => ({
    ggvar: n.label,
    length: round(vectorLen({ ld1: n.ld1, ld2: n.ld2 }) / RMax, 2),
  }));

  return (
    <CSVLink
      color="rgba(0, 0, 0, 0.54)"
      data={csvData}
      filename="marketmap_vectors.csv"
      className="hidden"
      ref={csvLink}
      target="_blank"
    ></CSVLink>
  );
};

export default DownloadMarketMapVectors;
