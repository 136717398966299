import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import ProductNamesByCategoryQuery, {
  ProductNamesByCategoryQueryResponse,
} from '@graphql/queries/ProductNamesByCategoryQuery';
import { Box, LinearProgress, useTheme } from '@mui/material';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import dataTableCsv from 'components/ReactDataTable/ReactDataTableCsv';
import TableSearch from 'components/Table/TableSearch';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import { tableCustomStyles } from '../ReactDataTable/tableCustomStyles';

interface Props {
  categoryName: string;
}

interface TableRow {
  id: number;
  name: string;
}

const ProductCategoryTable: React.FC<Props> = (props) => {
  const { categoryName } = props;

  const [products, setProducts] = useState<TableRow[]>([]);
  const { t } = useTranslation();
  const theme = useTheme();

  const { loading, error, data } =
    useQuery<ProductNamesByCategoryQueryResponse>(ProductNamesByCategoryQuery, {
      variables: {
        categoryName,
      },
    });

  const mapQueryResults = (data: ProductNamesByCategoryQueryResponse) =>
    data.allProductCategories.nodes
      .flatMap((i) => i.productsByCategoryId)
      .flatMap((e) => e.edges)
      .flatMap((n) => n.node);

  useEffect(() => {
    if (!loading && data) setProducts(mapQueryResults(data));
  }, [loading, data]);

  const filterData = (term: string) => {
    if (!term.length) return setProducts(mapQueryResults(data));
    const updateList =
      data &&
      mapQueryResults(data).filter((row) =>
        JSON.stringify(row).toLowerCase().includes(term.toLowerCase()),
      );
    setProducts(updateList);
  };

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: 'ID',
      omit: true,
    },
    {
      id: 'name',
      selector: (row: TableRow) => row.name,
      name: 'Name',
      sortable: true,
    },
  ];

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <h4>Can't load products!</h4>;
  }

  return (
    <Box marginTop={10}>
      <TableSearch
        onSearch={(e) => filterData(e)}
        placeholder={t('general.search')}
      />
      <DataTable
        columns={columns}
        data={products}
        customStyles={tableCustomStyles(theme, true)}
        actions={dataTableCsv(
          `${categoryName}-products.csv`,
          columns,
          products,
        )}
        striped
        pagination
        paginationIconFirstPage={<RDTFirstPage />}
        paginationIconLastPage={<RDTLastPage />}
        paginationIconPrevious={<RDTPrevPage />}
        paginationIconNext={<RTDNextPage />}
      />
    </Box>
  );
};

export default ProductCategoryTable;
