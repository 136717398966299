import * as React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './ProductCard.module.css';
import { getCountryText } from '../helper';

interface Props {
  countryCode?: string;
}

const RenderProductCountry: React.FunctionComponent<Props> = ({
  countryCode,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.productPropertyContainer}>
      {countryCode ? getCountryText(t, countryCode) : t('general.notSelected')}
    </div>
  );
};

export default RenderProductCountry;
