export default function parsePostgresArray(postgresArray: string): string[] {
  try {
    if (postgresArray.match(/\{.*\}/)) {
      const matches = postgresArray.match(/[\w.-]+/g);
      
      if (matches != null) {
        return matches.map(String);
      } else {
        return [];
      }
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}