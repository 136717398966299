import * as React from 'react';

import styles from './GGVarImpact.module.css';

type IncDec = 'increased' | 'decreased';

interface Props {
  type: IncDec;
  extrema: reports.ExtremaData;
}

const GGVarImpact: React.FC<Props> = (props) => {
  const { type, extrema } = props;

  return (
    <div className={styles.box}>
      <div className={styles.desc}>Most <span style={{ color: '#00b050' }} >positive</span> impact when {type}:</div>
      <div className={extrema?.ggvar?.label ? styles.ggVar : styles.ggVarNone}>{extrema?.ggvar?.label || '-'}</div>
    </div>
  );
};

export default GGVarImpact;
