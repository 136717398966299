import { gql } from '@apollo/client';

export interface ReportGgVarTuningPlotCntResponse {
  loading: boolean;
  error: any;
  allRpGgvarFlavorProfiles: {
    totalCount: number;
  };
}

export default gql`
  query ReportGgVarTuningPlotCnt($reportID: UUID!) {
    allRpGgvarFlavorProfiles(condition: { reportId: $reportID }) {
      totalCount
    }
  }
`;
