import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      bottom: 0,
      left: 0,
      alignItems: 'center',
      backgroundColor: 'transparent',
      color: theme.palette.info.main,
      fontSize: '14px',
      marginTop: '20px',
      padding: '20px',
      textAlign: 'center',
    },
  }),
);

export default useStyles;
