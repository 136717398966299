import { gql } from '@apollo/client';

export interface ReportNoveltyStackRankProductsResponse
  extends reports.ReportGraphQLReponse {
  allRpNoveltyStackRankingProducts: {
    nodes: ReportNoveltyStackRankProduct[];
  };
}

export interface ReportNoveltyStackRankProduct {
  productByProductId: {
    id: number;
    name: string;
  };
  version: string;
  noveltyProductNodeId: number;
}

export const NoveltyStackRankProductsByReportId = gql`
  query noveltyStackRankProductsQuery($reportID: UUID) {
    allRpNoveltyStackRankingProducts(condition: { reportId: $reportID }) {
      nodes {
        productByProductId {
          id
          name
        }
        version
        noveltyProductNodeId
      }
    }
  }
`;
