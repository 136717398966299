import { gql } from '@apollo/client';

export default gql`
query TextureMapLda($reportID: UUID) {
  allRpTexturemapLdas(condition: {
    reportId: $reportID
  }) {
    nodes {
      product
      productId
      version
      ld1
      ld2
      pq
      weight
    }
  }
}
`;
