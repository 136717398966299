import { gql } from '@apollo/client';

export default gql`
  query AllProductImagesQuery($condition: ProductImageCondition!) {
    productImages: allProductImages(condition: $condition) {
      nodes {
        productId
        url
      }
    }
  }
`;
