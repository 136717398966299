import * as React from 'react';

import * as d3 from 'd3';

import { Defs, useTheme } from '@nivo/core';
import { ComputedDatum, ResponsiveLine } from '@nivo/line';
import { Chip, TableTooltip } from '@nivo/tooltip';
import { TimeSeriesRow } from 'containers/ProjectSummary/tabs/TrendAnalysis';
import { area, curveMonotoneX } from 'd3-shape';
import { useTranslation } from 'react-i18next';

import ProductTimeSeriesPqTooltip from './ProductTimeSeriesPqTooltop';
import { getChartData, getYScaleMax, getYScaleMin } from './utils';

const SliceTooltip = ({ slice, axis }) => {
  const theme = useTheme();
  const otherAxis = axis === 'x' ? 'y' : 'x';

  return (
    <TableTooltip
      rows={slice.points.map((point) => [
        <Chip key="chip" color={point.serieColor} style={theme.tooltip.chip} />,
        point.serieId,
        <span key="value" style={theme.tooltip.tableCellValue}>
          {point.data[`${otherAxis}Formatted`]}
        </span>,
      ])}
    />
  );
};

interface ChartProps {
  timeSeriesData: TimeSeriesRow[];
  minPQ: number;
  maxPQ: number;
  useSharedPQ: boolean;
  minYear: number;
  maxYear: number;
  showError: boolean;
  deltaYoY?: boolean;
}

const colors = d3.schemeCategory10 as string[];

const ProductTimeSeriesPqChart: React.FC<ChartProps> = (props) => {
  const {
    timeSeriesData,
    minPQ,
    maxPQ,
    useSharedPQ,
    minYear,
    maxYear,
    showError,
    deltaYoY,
  } = props;

  const { t } = useTranslation();

  const chartData: reports.NivoDataSeries[] = getChartData(
    timeSeriesData,
    deltaYoY,
    colors,
  );

  const yAxisLabel = (deltaYoY: boolean): string => {
    let label = t('reports.flavorIntensityPqChart.pq');
    if (deltaYoY) {
      label += ' (% year over year)';
    }
    return label;
  };

  const yAxisFormat = (deltaYoY: boolean): string => {
    if (deltaYoY) {
      return '.2%';
    } else {
      return ' >-.1f';
    }
  };

  const AreaLayer = ({ series, xScale, yScale, innerHeight }) => {
    const areaGenerator = area<ComputedDatum>()
      .x((d) => xScale(d.data.x))
      .y0((d) =>
        Math.min(innerHeight, yScale((d.data.y as number) - d.data.error)),
      )
      .y1((d) => yScale((d.data.y as number) + d.data.error))
      .curve(curveMonotoneX);

    return (
      <>
        <Defs
          defs={[
            {
              id: 'pattern',
              type: 'patternLines',
              background: 'transparent',
              color: '#3daff7',
              lineWidth: 1,
              spacing: 6,
              rotation: -45,
            },
          ]}
        />
        {series.map((s) => (
          <path
            d={areaGenerator(s.data)}
            fill={s.color}
            fillOpacity={showError ? 0.2 : 0}
            stroke={s.color}
            strokeWidth={0}
          />
        ))}
      </>
    );
  };

  const CustomLinesLayer = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }) => (
      <g>
        <path
          key={id + 'line1'}
          d={lineGenerator(
            data
              .filter((d) => d.data.isPast || d.data.isCurrent)
              .map((d) => ({
                x: xScale(d.data.x),
                y: yScale(d.data.y),
              })),
          )}
          fill="none"
          stroke={color}
          style={{
            strokeWidth: '2',
          }}
        />
        <path
          key={id + 'line2'}
          d={lineGenerator(
            data
              .filter((d) => d.data.isFuture || d.data.isCurrent)
              .map((d) => ({
                x: xScale(d.data.x),
                y: yScale(d.data.y),
              })),
          )}
          fill="none"
          stroke={color}
          style={{
            pointerEvents: 'none',
            strokeWidth: '1',
            strokeDasharray: '4, 4',
          }}
        />
      </g>
    ));
  };

  return (
    <div
      key={`timeseries-${timeSeriesData.map((d) => d.seriesId).join('-')}`}
      style={{ height: '400px' }}
    >
      <ResponsiveLine
        data={chartData}
        margin={{ top: 50, right: 180, bottom: 50, left: 80 }}
        curve="catmullRom" // Make the curve
        // Horizontal line at current level
        xScale={{
          type: 'linear',
          min: minYear - 1 || 2018,
          max: maxYear + 1 || new Date().getFullYear(),
        }}
        yScale={{
          type: 'linear',
          min: getYScaleMin(chartData, useSharedPQ, minPQ, maxPQ),
          max: getYScaleMax(chartData, useSharedPQ, minPQ, maxPQ),
        }}
        xFormat=" >-.1f"
        yFormat={yAxisFormat(deltaYoY)}
        axisTop={null}
        axisRight={null}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          deltaYoY ? null : AreaLayer,
          'crosshair',
          CustomLinesLayer,
          'points',
          'slices',
          'mesh',
          'legends',
        ]}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Year',
          legendOffset: 36,
          legendPosition: 'middle',
          tickValues: 6,
        }}
        axisLeft={{
          format: yAxisFormat(deltaYoY),
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yAxisLabel(deltaYoY),
          legendOffset: -60,
          legendPosition: 'middle',
          tickValues: 5,
        }}
        colors={chartData.map((d) => d.color)}
        enablePoints={false}
        pointSize={6}
        pointColor={{ from: 'color' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            toggleSerie: true,
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 120,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        // default props added to prevent warnings that popped up when custom AreaLayer was added
        enableGridX={true}
        enableGridY={true}
        enablePointLabel={false}
        enableArea={false}
        areaOpacity={0.2}
        areaBlendMode="normal"
        areaBaselineValue={0}
        lineWidth={2}
        isInteractive={true}
        debugMesh={false}
        enableSlices={false}
        debugSlices={false}
        enableCrosshair={true}
        crosshairType="bottom-left"
        role=""
        defs={[]}
        fill={[]}
        tooltip={ProductTimeSeriesPqTooltip}
        sliceTooltip={SliceTooltip}
      />
    </div>
  );
};

export default ProductTimeSeriesPqChart;
