import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';

import { COLORS } from '../../styles/theme';

export default function RenderSwitch(props) {
  const { input, value, boolean, options, ...rest } = props;
  const classes = useStyles();

  return (
    <RadioGroup
      style={{ justifyContent: 'flex-start' }}
      {...input}
      {...rest}
      row
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          style={{ flex: 1 }}
          control={
            <Radio
              {...input}
              {...rest}
              value={option.value}
              color="secondary"
            />
          }
          label={option.label}
          classes={{ root: classes.root, label: classes.label }}
        />
      ))}
    </RadioGroup>
  );
}

const useStyles = makeStyles({
  root: {
    marginBottom: 0,
  },
  label: {
    fontFamily: 'OpenSans',
    fontSize: 14,
    color: COLORS.MARINE,
  },
});
