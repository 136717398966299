import * as React from 'react';

import { Box, Link } from '@mui/material';
import CSVFileIcon from 'components/Icons/FileIcons/CSVFileIcon';
import IconFolderZip from 'components/Icons/FileIcons/IconFolderZip';
import { generateZipFromCloud } from 'components/Report/DataDownload/AllProductImageDownload/generateZip';
import { CSVLink } from 'react-csv';
import { Data } from 'react-csv/lib/core';

interface Props {
  tileType: TileType;
  headers?: string[];
  csvDataRows?: Data;
  fileName?: string;
  tileName: string;
  urls?: reports.ProductImageData[];
}

export enum TileType {
  CSV,
  ZIP,
}

const DataDownloadTile: React.FC<Props> = (props) => {
  const { tileType, headers, csvDataRows, fileName, tileName, urls } = props;

  function getFileIcon(tileType: TileType) {
    switch (+tileType) {
      case TileType.ZIP:
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconFolderZip size={100} />
          </Box>
        );
      case TileType.CSV:
      default:
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CSVFileIcon size={100} />
          </Box>
        );
    }
  }

  function getTile(tileType: TileType) {
    switch (+tileType) {
      case TileType.ZIP:
        return (
          <Link onClick={() => generateZipFromCloud(urls, fileName)}>
            {getFileIcon(tileType)}

            <h5 style={{ textAlign: 'center' }}>{tileName}</h5>
          </Link>
        );
      case TileType.CSV:
      default:
        return (
          <CSVLink
            headers={headers}
            data={csvDataRows}
            filename={fileName}
            asyncOnClick={true}
          >
            {getFileIcon(tileType)}

            <h5 style={{ textAlign: 'center' }}>{tileName}</h5>
          </CSVLink>
        );
    }
  }

  return <Box sx={{ m: 2, width: 150 }}>{getTile(tileType)}</Box>;
};

export default DataDownloadTile;
