import { gql } from '@apollo/client';

export default gql`
  query ProjectMapQuery($projectId: Int) {
    allProjectReports(condition: {projectId: $projectId}) {
      nodes {
        projectId
        reportId
        reportJobByReportId {
          params
          targetGroupName
          reportSummariesByReportId {
            nodes {
              productByProductId {
                id
                name
              }
              version
              pq
            }
          }
        }
      }
    }
  }
`