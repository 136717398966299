import * as React from 'react';

import { SvgIcon } from '@mui/material';

// Generated with https://react-svgr.com/playground/
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1600}
    height={1600}
    viewBox="0 0 1200 1200"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M150 112.5c18.41 0 33.719 13.266 36.895 30.758L187.5 150l-.02 288.09c45.548-69.547 104.98-100.59 176.35-100.59 73.126 0 128.98 52.832 256.32 221.54l17.649 23.633 23.004-55.504c60.656-142.09 98.703-189.67 175.37-189.67 107.05 0 204.47 148.03 250.6 379.13 4.055 20.312-9.121 40.062-29.434 44.117-20.31 4.055-40.06-9.125-44.113-29.434-39.86-199.68-118.26-318.81-177.05-318.81-35.672 0-66.574 46.918-121.47 180.24l-22.824 55.688-1.805 4.219 22.043 29.52c59.328 78.281 93.594 118.15 127.92 147.43l7.367 6.121c16.098 13.031 18.578 36.645 5.547 52.742-13.03 16.098-36.645 18.582-52.742 5.547-42.37-34.305-78.949-75.734-144.63-162.25l-.64-.75c-45.31 91.957-82.316 124.61-145.82 124.61-52.098 0-80.945-20.922-125.06-75.805l-10.09-12.781c-38.43-49.43-54.86-62.086-82.672-59.305-54.523 5.453-103.79 78.98-103.79 206.62v60.664c0 18.41 13.266 33.719 30.758 36.895l6.742.605h825c20.71 0 37.5 16.79 37.5 37.5 0 18.41-13.266 33.72-30.758 36.895l-6.742.606h-825c-58.477 0-106.54-44.617-111.98-101.66l-.516-10.836v-825c0-20.711 16.79-37.5 37.5-37.5zm213.83 300c-81.238 0-140.58 61.102-177.05 243.81l.719-3.738-.051 29.918c27.047-28.52 59.984-45.762 96.383-49.402 59.035-5.903 91.52 15.914 139.52 75.477l25.434 31.926c25.68 30.809 40.082 40.117 60.328 40.117 29.43 0 55.613-31.938 94.699-117.98l-41.23-55.215c-106.91-141.12-160.74-194.91-198.75-194.91z"
    />
  </svg>
);

const MultiLineChartIcon = (props) => (
  <SvgIcon component={SvgComponent} viewBox="0 0 1200 1200" {...props} />
);

export default MultiLineChartIcon;
