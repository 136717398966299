import * as React from 'react';
import { useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import ProductSearchQuery from '@graphql/queries/ProductSearchQuery';
import CloseIcon from '@mui/icons-material/Close';
import { LinearProgress, Grid } from '@mui/material';
import AutoComplete from 'containers/PanelCreate/AutoComplete';
import { useSelector } from 'react-redux';
import Alert from 'react-s-alert';
import Select from 'react-select';

import styles from './ProductMove.module.css';
import MaterialButton from '../../components/MaterialButton';
import TransferProductToOtherProducer from '../../graphql/mutations/TransferProductToOtherProducer';
import AllWorkspacesQuery from '../../graphql/queries/AllWorkspacesQuery';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';

interface Workspace {
  value: number;
  label: string;
}

const ProductMove: React.FC = () => {
  const workspaceProducerId = useSelector((state) =>
    selectWorkspaceProducerId(state),
  );
  const [
    makeProductTransfer,
    { loading: trLoading, data: trData, error: trError },
  ] = useMutation(TransferProductToOtherProducer);
  const { data, loading, error } = useQuery(AllWorkspacesQuery);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [sourceWorkspace, setSourceWorkspace] = useState<Workspace | null>();
  const [destinationWorkspace, setDestinationWorkspace] =
    useState<Workspace | null>();
  const [selectedProducts, setSelectedProducts] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);

  const handleSourceWorkspaceChange = (selected: Workspace) => {
    setSourceWorkspace(selected);
    setErrorMessage(null);
  };

  const handleDestinationWorkspaceChange = (selected: Workspace) => {
    setDestinationWorkspace(selected);
    setErrorMessage(null);
  };

  const resetSelections = () => {
    setSourceWorkspace(null);
    setDestinationWorkspace(null);
    setSelectedProducts([]);
  };

  const AlertType = {
    success: 'success',
    error: 'error',
  };

  const PageAlert = (type, message) => {
    return Alert[AlertType[type]](message, {
      position: 'top-right',
      effect: 'slide',
      beep: false,
      timeout: 4000,
    });
  };

  const transferProducts = async () => {
    if (
      !selectedProducts ||
      !sourceWorkspace ||
      !sourceWorkspace.value ||
      !destinationWorkspace ||
      !destinationWorkspace.value
    )
      return PageAlert(
        AlertType.error,
        'Invalid selections! Please check your selections and try again',
      );
    for (const product of selectedProducts) {
      const res = await makeProductTransfer({
        variables: {
          input: {
            product: product.id,
            sourceProducerId: sourceWorkspace.value,
            destinationProducerId: destinationWorkspace.value,
          },
        },
      });
      if (
        !res ||
        !res.data ||
        !res.data.transferProductToOtherProducer ||
        !res.data.transferProductToOtherProducer.boolean
      )
        return PageAlert(
          AlertType.error,
          'Changes not all saved. Please try again',
        );
    }
    resetSelections();
    return PageAlert(
      AlertType.success,
      'Products have been moved successfully.',
    );
  };

  if (loading || trLoading) {
    return <LinearProgress />;
  }

  if (error || trError) {
    return PageAlert(
      AlertType.error,
      'An error occured! Please check your selections and try again',
    );
  }

  if (!data || !data.allProducers) {
    return <div>Error Occured!</div>;
  }

  return (
    <div className={styles.container}>
      <Grid container>
        {isSaving && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
        {!isSaving && (
          <Grid item xs={6}>
            <div>
              <div className={styles.sectionHeader}>Source Workspace</div>
              <Select
                name="sourceWorkspace"
                options={data.allProducers.nodes
                  .filter(
                    (f) =>
                      f.id !==
                      ((destinationWorkspace && destinationWorkspace.value) ||
                        ''),
                  )
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                isSearchable
                //isDisabled={isEditing}
                isClearable
                value={sourceWorkspace}
                onChange={handleSourceWorkspaceChange}
              />
              <div className={styles.sectionHeader}>Destination Workspace</div>
              <Select
                name="destinationWorkspace"
                options={data.allProducers.nodes
                  .filter(
                    (f) =>
                      f.id !==
                      ((sourceWorkspace && sourceWorkspace.value) || ''),
                  )
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                isSearchable
                //isDisabled={isEditing}
                isClearable
                value={destinationWorkspace}
                onChange={handleDestinationWorkspaceChange}
              />
              <div className={styles.sectionHeader}>Products</div>
              <div>
                {sourceWorkspace && (
                  <AutoComplete
                    query={ProductSearchQuery}
                    onClick={(p) =>
                      setSelectedProducts((currentProducts) =>
                        currentProducts.map((d) => d.id).includes(p.id)
                          ? currentProducts
                          : currentProducts.concat({ id: p.id, name: p.name }),
                      )
                    }
                    products={[]}
                    producerId={sourceWorkspace.value}
                  />
                )}
              </div>
              <div>
                {selectedProducts.map((p) => (
                  <div className={styles.containerProduct}>
                    <Grid container spacing={1}>
                      <Grid item xs={11}>
                        <span>{p.name}</span>
                      </Grid>
                      <Grid item xs={1}>
                        <CloseIcon
                          onClick={() =>
                            setSelectedProducts((currentProducts) =>
                              currentProducts.filter((d) => d != p),
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </div>
              <div
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingBlock: 10,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <MaterialButton
                      variant="outlined"
                      onClick={transferProducts}
                      disabled={isSaving || !destinationWorkspace}
                      soft
                      teal
                      fullWidth
                    >
                      Save
                    </MaterialButton>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ProductMove;
