import { viewSvgIcon } from 'constants/images';
import { PRODUCT, PANEL_SEARCH, PANEL } from 'constants/routePaths';

import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import PanelSearchQuery from '@graphql/queries/PanelSearchQuery';
import Paper from '@mui/material/Paper';
import dataTableCsv from 'components/ReactDataTable/ReactDataTableCsv';
import { flowRight as compose } from 'lodash';
import moment from 'moment';
import DataTable, { TableColumn } from 'react-data-table-component';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';
import formatPath from 'utils/formatPath';

import styles from './PanelSearch.module.css';
import MaterialButton from '../../components/MaterialButton';

interface TableRow {
  key: string;
  id: number;
  productName: string;
  brandName: string;
  date: string;
  startTime: string;
  panelId: number;
  panelPin: string;
  panelName: string;
  csvExport: boolean;
}

interface Props {
  query?: string;
  data?: any;
  producerId?: number;
  isFloating?: boolean;
  first: number;
  hideSearch?: () => any;
  startTime?: Date;
  endTime?: Date;
}

interface State {
  count: number;
}

class PanelSearch extends React.Component<Props & WithTranslation, State> {
  node = null;

  constructor(props) {
    super(props);
    this.state = {
      count: props.first,
    };
  }

  // Add listener to close the table when it's visible
  componentDidUpdate() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    // Clicking outside
    if (
      !(this.node && this.node.current && this.node.current.contains(e.target))
    ) {
      this.props.hideSearch();
    }
  };

  productNameFormatter = (row) => (
    <Link
      target="_blank"
      key={row.id}
      to={formatPath(PRODUCT, { productId: row.id })}
      className={styles.productName}
    >
      {row.productName}
    </Link>
  );

  panelFormatter = (row) => (
    <Link
      to={formatPath(PANEL, { panelId: row.panelId })}
      key={row.panelId}
      className={styles.productName}
    >
      {row.panelPin}
    </Link>
  );

  csvNullFormatter = (cell) => (cell === null ? '' : cell);

  getPanelData = (): TableRow[] => {
    const { data } = this.props;

    if (!data.panelResults || data.panelResults.totalCount === 0) {
      return [];
    }

    return data.panelResults.nodes.map((node) => ({
      key: `${node.id}`,
      id: node.product.id,
      productName: node.product.name,
      brandName: node.product.brand,
      date: moment(node.panel.startTime).format('ll'),
      startTime: moment(node.panel.startTime).format('LT'),
      panelId: node.panel.id,
      panelPin: node.panel.pin,
      panelName: node.panel.name,
    }));
  };

  onFetchMore = (count) => {
    this.props.data.fetchMore({
      variables: {
        first: count,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        this.setState({
          count: this.state.count + fetchMoreResult.panelResults.nodes.length,
        });
        return Object.assign({}, prev, {
          panelResults: {
            nodes: [
              ...prev.panelResults.nodes,
              ...fetchMoreResult.panelResults.nodes,
            ],
          },
          ...fetchMoreResult,
        });
      },
    });
  };

  render() {
    const { query, isFloating, data, t, first, startTime, endTime } =
      this.props;
    const columns: TableColumn<TableRow>[] = [
      {
        id: 'key',
        selector: (row: TableRow) => row.key,
        omit: true,
      },
      {
        id: 'id',
        selector: (row: TableRow) => row.id,
        name: <div className={styles.tableHeader}>Product ID</div>,
        omit: true,
      },
      {
        id: 'productName',
        selector: (row: TableRow) => row.productName,
        name: (
          <div className={styles.tableHeader}>{t('product.productName')}</div>
        ),
        cell: (row: TableRow) => this.productNameFormatter(row),
      },
      {
        id: 'brandName',
        selector: (row: TableRow) => row.brandName,
        name: (
          <div className={styles.tableHeader}>{t('product.productBrand')}</div>
        ),
        //csvFormatter: this.csvNullFormatter,
        //csvExport: 'Product Brand',
      },
      {
        id: 'date',
        selector: (row: TableRow) => row.date,
        name: <div className={styles.tableHeader}>{t('panel.startDate')}</div>,
      },
      {
        id: 'startTime',
        selector: (row: TableRow) => row.startTime,
        name: <div className={styles.tableHeader}>{t('panel.startTime')}</div>,
      },
      {
        id: 'panelId',
        selector: (row: TableRow) => row.panelId,
        name: <div className={styles.tableHeader}>Panel ID</div>,
        omit: true,
      },
      {
        id: 'panelPin',
        selector: (row: TableRow) => row.panelPin,
        name: <div className={styles.tableHeader}>{t('panel.panelPin')}</div>,
        cell: (row: TableRow) => this.panelFormatter(row),
      },
      {
        id: 'panelName',
        selector: (row: TableRow) => row.panelName,
        name: <div className={styles.tableHeader}>{t('panel.panelName')}</div>,
        cell: (row: TableRow) => (
          <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
            {row.panelName}
          </div>
        ),
      },
    ];

    if (data.loading) return <div />;

    return (
      <div ref={(node) => (this.node = node)}>
        <Paper className={isFloating && styles.container}>
          <div className={isFloating && styles.tableContainer}>
            <DataTable
              columns={columns}
              data={this.getPanelData()}
              fixedHeader
              striped
              actions={dataTableCsv(
                `Panel_Search_${query}.csv`,
                columns,
                this.getPanelData(),
              )}
            />

            <div style={{ textAlign: 'center', marginBottom: first }}>
              {data.panelResults &&
                data.panelResults.totalCount >
                  data.panelResults.nodes.length && (
                  <MaterialButton
                    variant="outlined"
                    soft
                    onClick={() =>
                      this.onFetchMore(data.panelResults.nodes.length + first)
                    }
                  >
                    Load More
                  </MaterialButton>
                )}
            </div>
          </div>
          <div className={styles.iconContainer}>
            {isFloating && (
              <Link
                target="_blank"
                to={formatPath(PANEL_SEARCH, {
                  query: encodeURI(query),
                  startTime: startTime && startTime.toISOString(),
                  endTime: endTime && endTime.toISOString(),
                })}
                className={styles.icon}
              >
                <img src={viewSvgIcon} alt="view-icon" />
                <span className={styles.iconText}>{t('general.view')}</span>
              </Link>
            )}
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  producerId: selectWorkspaceProducerId(state),
});

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  graphql(PanelSearchQuery, {
    options: ({ query, producerId, first, startTime, endTime }: Props) => ({
      variables: {
        query,
        producerId,
        first,
        startTime: startTime && new Date(startTime.setUTCHours(0, 0, 0, 0)),
        endTime: endTime && new Date(endTime.setUTCHours(23, 59, 59, 99)),
      },
    }),
  }),
)(PanelSearch) as React.ComponentType<Props>;
