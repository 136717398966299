import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import flexContainer from '../../styles/flexContainer';

const useMainStyles = (otherStyles: any) => {
  const { value } = otherStyles;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        ...flexContainer(theme),
        flexDirection: 'row',
        backgroundColor: '#f5f5f5',
        height: '2px',
      },
      value: {
        backgroundColor: theme.palette && theme.palette.secondary && theme.palette.secondary.main || '',
        height: '2px',
        flex: value,
      },
      maximum: {
        flex: 1 - value,
      },
    }),
  );

  return {
    useStyles,
  };
};

export default useMainStyles;
