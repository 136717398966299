import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontWeight: 'bold',
      width: '100%',
      paddingTop: '40px',
      paddingBottom: '20px',
    },
    emptyMain: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    emptySub: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: 10,
      marginInline: '25%',
      paddingBlock: 20,
    },
    tableRow: {
      fontSize: '12px',
      fontFamily: 'OpenSans, sans-serif',
      color: theme.palette.primary.main,
      wordWrap: 'break-word',
    },
    tableHeader: {
      fontFamily: 'AlphaHeadlinePro-Bold, sans-serif',
      fontSize: '16px',
      color: theme.palette.primary.main,
    },
    dataCell: {
      cursor: 'pointer',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    dataCellLink: {
      color: 'black',
    },
    nameCell: {
      cursor: 'pointer',
      textAlign: 'left',
      verticalAlign: 'middle',
    },
  }),
);

export default useStyles;
