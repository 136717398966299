import { gql } from '@apollo/client';

export default gql`
query ReportPositiveNegativeDriversQuery($reportID: UUID!) {
    allRpFlavorStructures(
      condition: { reportId: $reportID} 
    ) {
      nodes {
        product: productByProductId {
          id
          name
        }
        label
        nodeColor
      }
    }
  }
`;