import { gql } from '@apollo/client';

export default gql`
  mutation UpdateReportWorkspace($jobId: UUID!, $workspaceId: Int!) {
    updateReportJobByJobId(input: {
      jobId: $jobId,
      reportJobPatch: {
        workspaceId: $workspaceId
      }
    }) {
      producerByWorkspaceId {
        id
        name
      }
    }
  }
`;