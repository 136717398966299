import { OPTIMIZATION } from 'constants/report';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import OrderOfOperationsCountQuery from '@graphql/queries/OrderOfOperationsCountQuery';
import { Masonry } from '@mui/lab';
import { Grid } from '@mui/material';
import ActionSummary from 'components/ExecutiveSummary/ActionSummary';
import CategoryNorms from 'components/ExecutiveSummary/CategoryNorms';
import OptimizationTop3Steps from 'components/ExecutiveSummary/OptimizationTop3Steps';
import PositiveNegativeDrivers from 'components/ExecutiveSummary/PositiveNegativeDrivers';
import StackRankPqRange from 'components/ExecutiveSummary/StackRankPqRange';
import FlavorSummaryAttributeIntensity from 'components/Report/FlavorSummaryAttribute';
import StackRankDriversTable from 'components/Report/StackRankComparison';
import {
  showStackRankDrivers,
  showTopThreeOpt,
  useCustomerPreferences,
} from 'services/customerPreferences';
import { nvlReportId } from 'utils/afsUtils';

interface Props {
  projectId: number;
  projectReports: reports.ProjectReportSummary[];
  selectedReportId: string;
}

const ProjectExecutiveSummary: React.FC<Props> = (props: Props) => {
  const { selectedReportId, projectReports, projectId } = props;

  const customerPreferences = useCustomerPreferences();

  const isOptimizationReport = (
    projectReports: any[],
    selectedReportId: string,
  ): boolean => {
    return (
      projectReports.find((i) => i.reportId === selectedReportId).reportType ===
      OPTIMIZATION
    );
  };

  const { data: optSummaryCnt } =
    useQuery<reports.ReportOrderOfOperationsCountResponse>(
      OrderOfOperationsCountQuery,
      {
        variables: {
          reportID: nvlReportId(selectedReportId),
        },
      },
    );

  return (
    selectedReportId && (
      <Grid container spacing={2} style={{ marginTop: 50 }}>
        <Masonry columns={2} spacing={2}>
          <div style={{ flexShrink: 0 }}>
            <StackRankPqRange
              projectId={projectId}
              reportId={selectedReportId}
            />
          </div>
          {!isOptimizationReport(projectReports, selectedReportId) && (
            <div style={{ flexShrink: 0 }}>
              <PositiveNegativeDrivers
                projectId={projectId}
                reportId={selectedReportId}
              />
            </div>
          )}
          <div style={{ flexShrink: 0 }}>
            <CategoryNorms projectId={projectId} reportId={selectedReportId} />
          </div>
          {showTopThreeOpt(customerPreferences) &&
            isOptimizationReport(projectReports, selectedReportId) &&
            optSummaryCnt &&
            (optSummaryCnt?.allRpOptSteps?.totalCount ?? 0) > 0 && (
              <div style={{ flexShrink: 0 }}>
                <OptimizationTop3Steps
                  projectId={projectId}
                  reportId={selectedReportId}
                />
              </div>
            )}
          {isOptimizationReport(projectReports, selectedReportId) && (
            <div style={{ flexShrink: 0 }}>
              <ActionSummary
                projectId={projectId}
                reportId={selectedReportId}
              />
            </div>
          )}
        </Masonry>
        <Grid item xs={12}>
          <FlavorSummaryAttributeIntensity
            projectId={projectId}
            reportId={selectedReportId}
          />
        </Grid>
        {showStackRankDrivers(customerPreferences) && (
          <Grid item xs={12} marginTop={10}>
            <StackRankDriversTable
              projectId={projectId}
              reportId={selectedReportId}
            />
          </Grid>
        )}
      </Grid>
    )
  );
};

export default ProjectExecutiveSummary;
