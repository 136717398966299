import { gql } from '@apollo/client';

export default gql`
  mutation TransferProductToOtherProducerMutation($input: TransferProductToOtherProducerInput!) {
    transferProductToOtherProducer(input: $input) {
      clientMutationId
      boolean
    }
  }
`;
