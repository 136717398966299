import { gql } from '@apollo/client';

export type MultiProductSearchQueryType = {
  loading: true;
  error: any;
  allProducts: {
    nodes: ProductSearchQueryProduct[];
  };
};

export type ProductSearchQueryProduct = {
  id: number;
  name: string;
  brand: string;
  public: boolean;
  aroma: boolean;
  producer: {
    id: number;
    name: string;
  };
  createdAt: string;
  productReviews: {
    totalCount: number;
    nodes: [
      {
        createdAt: string;
      },
    ];
  };
  lastAlphabeticalTextureReviews: {
    nodes: [
      {
        textureCsv: string;
      },
    ];
  };
  productImages: {
    totalCount: number;
    nodes: [
      {
        url: string;
      },
    ];
  };
  panels: {
    nodes: [
      {
        panelInfo: {
          id: number;
          startTime: string;
          name: string;
          pin: string;
        };
      },
    ];
  };
  productClass: any;
  productClasses: {
    nodes: any[];
  };
  defaultAttributes: any;
  selectedProductQuestions: {
    totalCount: number;
  };
  category: {
    id: number;
    name: string;
  };
};

export default gql`
  query MultiProductSearchQuery($names: [String!]) {
    allProducts(filter: { name: { inInsensitive: $names } }) {
      nodes {
        id
        name
        brand
        public
        aroma
        producer: producerByProducerId {
          id
          name
        }
        createdAt
        productReviews: productReviewsByProductId(
          orderBy: END_TIME_DESC
          first: 1
        ) {
          totalCount
          nodes {
            createdAt
          }
        }
        productImages: productImagesByProductId {
          totalCount
          nodes {
            url
          }
        }
        panels: panelProductsByProductId {
          nodes {
            panelInfo: panelByPanelId {
              id
              startTime
              name
              pin
            }
          }
        }
        productClass
        productClasses: productClassProductsByProductId {
          nodes {
            id
            productClassByProductClassId {
              id
              name
            }
          }
        }
        defaultAttributes
        selectedProductQuestions: selectedProductQuestionsByProductId {
          totalCount
        }
        category: productCategoryByCategoryId {
          id
          name
        }
      }
    }
  }
`;
