import { gql } from '@apollo/client';

export default gql`
  query PairedPreferencesByReportId($reportID: UUID!) {
    allRpPairedPreferences(condition: { reportId: $reportID }) {
      nodes {
        productByProductId1 {
          id
          name
        }
        productByProductId2 {
          id
          name
        }
        version1
        version2
        percentPreference
      }
    }
  }
`;
