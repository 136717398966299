import * as React from 'react';

import { useTranslation } from 'react-i18next';

import PanelSearch from '../../components/PanelSearch';
import { PageHeader, PagePaper, PageTitle } from '../../styles/themeComponents';

interface Props {
  match?: any;
}

const PanelSearchResults: React.FunctionComponent<Props> = ({ match }) => {
  const query = decodeURI(match.params.query);
  const startTime = decodeURI(match.params.startTime);
  const endTime = decodeURI(match.params.endTime);
  const { t } = useTranslation();
  console.log();

  return (
    <PagePaper>
      <PageHeader>{t('navigation.panels')}</PageHeader>
      <PageTitle>{t('panel.searchResult', { query: query })}</PageTitle>
      <PanelSearch
        first={10}
        query={query}
        startTime={startTime !== 'null' ? new Date(startTime) : null}
        endTime={endTime !== 'null' ? new Date(endTime) : null}
      />
    </PagePaper>
  );
};

export default PanelSearchResults;
