import * as React from 'react';
import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { MapOptions } from 'components/Report/Maps/MapOptions';
import ReportMarketMap from 'components/Report/Maps/ReportMarketMap/ReportMarketMap';
import ReportTextureMap from 'components/Report/Maps/ReportMarketMap/ReportTextureMap';
import { ProductVersionSet } from 'components/Report/ProductVersion';
import ReportDataCollected from 'components/Report/ReportDataCollected';
import ReportDownloadIcon from 'components/Report/ReportDownloadIcon';
import PairedComparisonPreferenceComponent from 'components/Report/ReportPairedComparisonPreference/PairedComparisonPreferenceComponent';
import ReportStackRanks from 'components/Report/ReportStackRanks';
import ReportSummary from 'components/Report/ReportSummary';
import ReportTitle from 'components/Report/ReportTitle';
import ProjectTextureSummary from 'containers/ProjectSummary/tabs/ProjectTextureSummary';
import DemographicsGroup from 'containers/ReportQAList/ApprovalModal/DemographicsGroup';
import {
  useCustomerPreferences,
  getDefaultMapOptions,
} from 'services/customerPreferences';

import styles from './ReportDashboard.module.css';
import ReportDashboardProducts from './ReportDashboardProducts';

interface Props {
  reportId: string;
  workspaceProducerId: number;
  viewerUserId: number;
}

const ReportDashboardContent: React.FC<Props> = React.memo((props) => {
  const { reportId, workspaceProducerId, viewerUserId } = props;

  const customerPreferences = useCustomerPreferences();

  const [flavorMapOptions, setFlavorMapOptions] = useState<MapOptions>(
    getDefaultMapOptions(customerPreferences),
  );
  const [textureMapOptions, setTextureMapOptions] = useState<MapOptions>(
    getDefaultMapOptions(customerPreferences),
  );

  if (!reportId) {
    return <div />;
  }

  return (
    <Grid container className={styles.gridContainer}>
      {/*
              Build the elements that have to happen overall per report
            */}
      <Grid item xs={11}>
        <ReportTitle reportId={reportId} />
      </Grid>
      <Grid item xs={1}>
        <ReportDownloadIcon reportId={reportId} />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <DemographicsGroup reportId={reportId} />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Summary
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} className={styles.gridContainer}>
              <Grid item xs={11}>
                <ReportDataCollected reportId={reportId} />
              </Grid>
              <Grid item xs={11}>
                <ReportSummary
                  reportId={reportId}
                  excludedProducts={new ProductVersionSet()}
                  setExcludedProducts={() => new ProductVersionSet()}
                  updateExcludedProducts={() => {}}
                  routeBackLocation={'/admin/report-dashboard'}
                />
              </Grid>
              <Grid item xs={11}>
                <ReportStackRanks reportId={reportId} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Textures
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} className={styles.gridContainer}>
              <Grid item xs={11}>
                <ProjectTextureSummary
                  projectId={0}
                  reportId={reportId}
                  excludedProducts={new ProductVersionSet()}
                  textureMapOptions={textureMapOptions}
                  setTextureMapOptions={setTextureMapOptions}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Maps
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4} className={styles.gridContainer}>
              <Grid item xs={11}>
                <ReportMarketMap
                  reportId={reportId}
                  workspaceId={workspaceProducerId}
                  userId={viewerUserId}
                  showTitle={true}
                  showLegend={true}
                  excludedProducts={new ProductVersionSet()}
                  flavorMapOptions={flavorMapOptions}
                  setFlavorMapOptions={setFlavorMapOptions}
                  setExcludedProducts={() => new ProductVersionSet()}
                />
              </Grid>
              <Grid item xs={11}>
                <PairedComparisonPreferenceComponent reportId={reportId} />
              </Grid>
              <Grid item xs={11}>
                <ReportTextureMap
                  reportId={reportId}
                  showTitle={false}
                  showLegend={false}
                  excludedProducts={new ProductVersionSet()}
                  viewerUserId={viewerUserId}
                  textureMapOptions={textureMapOptions}
                  setTextureMapOptions={setTextureMapOptions}
                  setExcludedProducts={() => new ProductVersionSet()}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {/*
              Build the elements that have to happen for each product
            */}
      <Grid item xs={12}>
        <ReportDashboardProducts reportId={reportId} />
      </Grid>
    </Grid>
  );
});

export default ReportDashboardContent;
