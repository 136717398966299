import { gql } from '@apollo/client';

export default gql`
  mutation CreatePanelProductMutation($panelProduct: PanelProductInput!) {
    createPanelProduct(input: { panelProduct: $panelProduct }) {
      panelProduct {
        id
      }
    }
  }
`;
