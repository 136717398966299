import * as React from 'react';

import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RouterProps } from 'react-router';

import PairedComparisonPreferenceComponent from './PairedComparisonPreferenceComponent';
import { PageHeader, PagePaper } from '../../../styles/themeComponents';

interface Props extends RouterProps {
  match: Record<string, any>;
}

const ReportPairedComparisonPreference: React.FC<Props> = (props) => {
  const { match, history } = props;
  const { projectId, reportId }: { projectId: number; reportId: string } =
    match.params;

  const { t } = useTranslation();

  return (
    <PagePaper>
      <IconButton
        onClick={history.goBack}
        size="small"
        style={{ marginLeft: -26 }}
      >
        <KeyboardBackspaceIcon fontSize="small" />
        <PageHeader>{t('projects.projectFlavorSummary.back')}</PageHeader>
      </IconButton>
      <PairedComparisonPreferenceComponent
        reportId={reportId}
        projectId={projectId}
      />
    </PagePaper>
  );
};

export default ReportPairedComparisonPreference;
