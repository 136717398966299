
import { gql } from '@apollo/client';

export default gql`
mutation CreateClient($client: ClientInput!) {
    createClient(input: {client: $client }) {
      client {
        id
        name
        partnerId
      }
    }
  }
`;
