type Params = {
  email: string;
  password: string;
  username: string;
  partner_id: number;
  roles: [];
};

export default function httpCreateUser(params: Params) {
  return fetch('/iam/createUser', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((message) => {
        throw new Error(message.detail);
      });
    }
  });
}
