import * as React from 'react';

import { Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { PROJECT_FLAVOR_SUMMARY_CHART } from '../../../constants/routePaths';
import formatPath from '../../../utils/formatPath';

interface GGVarBarProps {
  list: reports.ExtremaData[];
  color: string;
}

const BarTop = (props: GGVarBarProps) => {
  const { list, color } = props;
  const location = useLocation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '45%',
        background: color,
      }}
    >
      {list &&
        list.map((item) => (
          <Link
            key={item.ggvar.idx}
            className={`restore-${item.ggvar.label}`}
            to={{
              pathname: formatPath(PROJECT_FLAVOR_SUMMARY_CHART, {
                projectId: item.projectId,
                reportId: item.reportId,
                productId: item.productId,
                version: item.version,
                ggVarKey: item.ggvar.key,
              }),
              state: {
                fromFlavorSummary:
                  location.pathname.includes('/projects/summary'),
              },
            }}
          >
            <span style={{ color: 'white' }}>{item.ggvar.label}</span>
          </Link>
        ))}
    </div>
  );
};

const BarMid = ({ color, intensity }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10%',
        background: color,
        borderColor: 'red',
        borderBlock: 'solid',
        borderBlockColor: 'grey',
      }}
    >
      <Tooltip title={intensity.tooltip}>
        <span>{intensity.label}</span>
      </Tooltip>
    </div>
  );
};

const BarBottom = (props: GGVarBarProps) => {
  const { list, color } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '45%',
        background: color,
      }}
    >
      {list &&
        list.map((item) => (
          <Link
            key={item.ggvar.idx}
            className={`restore-${item.ggvar.label}`}
            to={{
              pathname: formatPath(PROJECT_FLAVOR_SUMMARY_CHART, {
                projectId: item.projectId,
                reportId: item.reportId,
                productId: item.productId,
                version: item.version,
                ggVarKey: item.ggvar.key,
              }),
              state: {
                fromFlavorSummary:
                  location.pathname.includes('/projects/summary'),
              },
            }}
          >
            <span style={{ color: 'white' }}>{item.ggvar.label}</span>
          </Link>
        ))}
    </div>
  );
};

interface BarProps {
  mostItems: number;
  divWidth: number;
  color: string;
  positiveList: reports.ExtremaData[];
  negativeList: reports.ExtremaData[];
  intensity: reports.Intensity;
}

const Bar = (props: BarProps) => {
  const { mostItems, divWidth, color, positiveList, negativeList, intensity } =
    props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: 'transparent',
        color: 'white',
        height: divWidth / 2.5,
        minWidth: divWidth / 10,
        maxWidth: divWidth / 10,
        fontSize: mostItems > 9 ? divWidth / mostItems / 9 : divWidth / 75,
      }}
    >
      <BarTop list={positiveList} color={color} />
      <BarMid color={color} intensity={intensity} />
      <BarBottom list={negativeList} color={color} />
    </div>
  );
};

export default Bar;
