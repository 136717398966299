import React from 'react';

const RDTLastPage: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    aria-hidden="true"
    role="presentation"
  >
    <path
      fill="black"
      d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"
    />
    <path fill="none" d="M0 0h24v24H0V0z" />
  </svg>
);

export default RDTLastPage;
