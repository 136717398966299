import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 0,
      boxShadow: '0 0',
      minHeight: 44,
      textTransform: 'none',
      fontFamily: 'OpenSans',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 12,
    },
    softRoot: {
      borderRadius: 5,
      backgroundColor: 'white',
      boxShadow: '0 0',
      minHeight: 44,
      textTransform: 'none',
      borderColor: '#e4e3e3',
      fontFamily: 'OpenSans',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 12,
    },
    softTeal: {
      borderRadius: 5,
      backgroundColor: theme.palette.secondary.main,
      boxShadow: '0 0',
      minHeight: 44,
      textTransform: 'none',
      borderColor: '#e4e3e3',
      fontFamily: 'OpenSans',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 12,
    },
    small: {
      minHeight: 15,
    },
    outlinedSecondary: {
      backgroundColor: 'var(--coral-pink)',
    },
  }),
);

export default useStyles;
