import { gql } from '@apollo/client';

export default gql`
  mutation CreateReferenceFlavorMutation(
    $flavorAttribute: String!
    $name: String!
    $base: Boolean
    $visible: Boolean
    $version: Int
  ) {
    createReferenceFlavorNew(
      input: {
        referenceFlavorNew: {
          name: $name
          flavorAttribute: $flavorAttribute
          base: $base
          visible: $visible
          version: $version
        }
      }
    ) {
      referenceFlavorNew {
        id
        name
        flavorAttribute
      }
    }
  }
`;
