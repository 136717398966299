import React, { Component } from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

export default class RenderDatePicker extends Component {
  render() {
    const { input, placeholder, value, onChange, ...rest } = this.props;
    const valueToPass = input ? input.value || null : value || null;

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box paddingBottom={2}>
          <DatePicker
            value={valueToPass}
            renderInput={(props) => <TextField variant="standard" {...props} />}
            onChange={input ? input.onChange : onChange}
            inputFormat="dd/MM/yyyy"
            label={placeholder || 'dd/mm/yyyy'}
            disablePast
            minDateMessage=""
            InputAdornmentProps={{
              variant: 'standard',
            }}
            {...rest}
          />
        </Box>
      </LocalizationProvider>
    );
  }
}
