import { gql } from '@apollo/client';

export default gql`
  query ProducersProductQuery($producerId: Int!) {
    allProducts(condition: { producerId: $producerId }) {
      nodes {
        name
        productCategoryByCategoryId {
          id
          name
        }
      }
    }
  }
`;
