import { gql } from '@apollo/client';

export default gql`
  query PanelQuery($panelId: Int!) {
    panel: panelById(id: $panelId) {
      id
      pin
      name
      blind
      texture
      startTime
      endTime
      timeLimitSeconds
      public
      hideReviews
      producerId

      averageReviewDurationSeconds
      totalReviewDurationSeconds

      panelProducts: panelProductsByPanelId {
        nodes {
          productReviews {
            totalCount
          }
          productByProductId {
            id
            name
          }
        }
      }

      productReviews: productReviewsByPanelId {
        totalCount
        nodes {
          userId
          startTime
          endTime
          astringent
          bitter
          coldFinish
          dairy
          dry
          earthy
          floral
          fruits
          gamey
          herbaceous
          marine
          meaty
          mineral
          mouthfeel
          nutsAndSeeds
          retronasal
          rich
          roasted
          smoked
          sourAndAcidity
          spices
          sugar
          wet
          woody
          referenceFlavors
        }
      }
    }
  }
`;
