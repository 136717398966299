import { gql } from '@apollo/client';

export default gql`
  query RpNorms($reportID: UUID!) {
    allRpStackrankNorms(condition: {reportId: $reportID}) {
      nodes {
        id
        q1
        median
        q3
      }
    }
    allRpCategoryNorms(condition: {reportId: $reportID}) {
      nodes {
        id
        q1
        median
        q3
      }
    }
  }
`
