import * as React from 'react';

import { useQuery } from '@apollo/client';
import { formatReportLabel } from 'containers/Project/utils';

import ReportByIdQuery from '../../../graphql/queries/ReportByIdQuery';

interface ReportProps {
  reportId: string;
}

const ReportTitle: React.FC<ReportProps> = (props) => {
  const { reportId } = props;

  const { loading, error, data } = useQuery(ReportByIdQuery, {
    variables: {
      reportId: reportId,
      filter: { passedQa: { equalTo: true } },
      orderBy: 'ID_DESC',
    },
    notifyOnNetworkStatusChange: true,
  });

  const getReshapedData = (
    reportJobsResults: reports.ReportJobResults,
  ): reports.ReportInfo => ({
    name: formatReportLabel(
      reportJobsResults.report.clientName,
      reportJobsResults.report.projectName,
      reportJobsResults.report.reportType,
      reportJobsResults.report.startedAt,
    ),
    reportId: reportJobsResults.report.reportId,
    reportType: reportJobsResults.report.reportType,
  });

  if (loading || error) {
    return <div />;
  }

  return <h3>{getReshapedData(data).name}</h3>;
};

export default ReportTitle;
