import * as React from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';

import { MapOptions, setTx, setTy, setZoom } from '../MapOptions';

interface Props {
  mapOptions: MapOptions;
  setMapOptions: (newMapOptions: MapOptions) => void;
}

const MarketMapRefresh: React.FC<Props> = (props) => {
  const { mapOptions, setMapOptions } = props;

  return (
    <IconButton
      onClick={() => setMapOptions(setTx(setTy(setZoom(mapOptions, 1), 0), 0))}
      size="large"
    >
      <RefreshIcon style={{ fontSize: 24, color: '#777' }} />
    </IconButton>
  );
};

export default MarketMapRefresh;
