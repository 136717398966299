import { ContactIconSvg } from 'constants/images';

import * as React from 'react';

import { ApolloQueryResult } from '@apollo/client';
import ToggleProjectApprovalMutation from '@graphql/mutations/ToggleProjectApprovalMutation';
import CreateOutlined from '@mui/icons-material/CreateOutlined';
import { Checkbox, Tooltip, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import { tableCustomStyles } from 'components/ReactDataTable/tableCustomStyles';
import graphqlClient from 'consumers/graphqlClient';
import { format } from 'date-fns';
import useGgEnvironment from 'hooks/useGgEnvironment';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { partnerPortal } from 'services/partnerPortal';

import { ProjectInformation } from './Project';
import styles from './ProjectListTable.module.css';
import {
  PROJECT_SUMMARY_BY_ID,
  PROJECT_EDIT,
  PROJECT_COMPARISON,
} from '../../constants/routePaths';
import formatPath from '../../utils/formatPath';

const toggleProjectApproval = async (id: number, approved: boolean) => {
  await graphqlClient.mutate({
    mutation: ToggleProjectApprovalMutation,
    variables: {
      id: id,
      approved: approved,
    },
  });
};

const renderProductsIncluded = (item: TableRow) => {
  return (
    <Tooltip
      title={
        <div style={{ whiteSpace: 'pre-line' }}>
          {item.productsIncluded.split(',').join('\n')}
        </div>
      }
    >
      <div
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {item.productsIncluded}
      </div>
    </Tooltip>
  );
};

const renderStatus = (item: TableRow) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <div
        style={{
          backgroundColor: item.status === 'SUCCESS' ? 'green' : '#ffa321',
          borderRadius: 50,
          width: 18,
          marginRight: 10,
        }}
      >
        .
      </div>
      <div>
        {item.status === 'SUCCESS' ? (
          <Link
            key={item.projectId}
            className={`restore-${item.projectId}`}
            to={{
              pathname: formatPath(PROJECT_SUMMARY_BY_ID, {
                projectId: item.projectId,
              }),
            }}
          >
            Ready to view
          </Link>
        ) : (
          'Pending'
        )}
      </div>
    </div>
  );
};

const renderCompare = (item: TableRow) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <div>
        {item.status === 'SUCCESS' ? (
          <Link
            key={item.projectId}
            className={`restore-${item.projectId}`}
            to={{
              pathname: formatPath(PROJECT_COMPARISON, {
                projectId: item.projectId,
              }),
            }}
          >
            Compare
          </Link>
        ) : (
          'Pending'
        )}
      </div>
    </div>
  );
};

const renderEdit = (item: TableRow) => {
  return (
    <Link
      key={item.projectId}
      to={formatPath(PROJECT_EDIT, { projectId: item.projectId })}
      className={styles.productName}
    >
      <IconButton size="small">
        <CreateOutlined color="primary" fontSize="small" />
      </IconButton>
    </Link>
  );
};

interface TableRow {
  projectId: number;
  projectName: string;
  productsIncluded: string;
  dateCreated: string;
  lastReportStarted: number; //millis since epoch
  status: string;
  compare: string;
  edit: boolean;
  approved: boolean;
}

interface Props {
  currentSearchTerm: string;
  projectReports: ProjectInformation[];
  refetchProjects: () => Promise<
    ApolloQueryResult<reports.ProjectsForWorkspaceResponse>
  >;
  viewerRoleIsAdmin: boolean;
  viewerRoleIsWorkspaceAdmin: boolean;
  viewerRoleIsPartnerAdmin: boolean;
}

const ProjectListTable: React.FC<Props> = (props) => {
  const {
    currentSearchTerm,
    projectReports,
    refetchProjects,
    viewerRoleIsAdmin,
    viewerRoleIsWorkspaceAdmin,
    viewerRoleIsPartnerAdmin,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const environ = useGgEnvironment();

  const getTableData = (
    currentSearchTerm: string,
    projectReports: ProjectInformation[],
    environ: SensoryLinkEnvironment,
    viewerRoleIsAdmin: boolean,
    viewerRoleIsPartnerAdmin: boolean,
    viewerRoleIsWorkspaceAdmin: boolean,
  ): TableRow[] => {
    const rows =
      projectReports &&
      projectReports
        .sort(
          (a, b) =>
            new Date(b.lastReportStarted).getTime() -
            new Date(a.lastReportStarted).getTime(),
        )
        .filter(
          (item) =>
            // Don't filter projects for approval if partner portal not released yet
            !partnerPortal.partnerPortalReleased(environ) ||
            // Otherwise base on roles
            item.approved ||
            viewerRoleIsAdmin ||
            viewerRoleIsWorkspaceAdmin ||
            viewerRoleIsPartnerAdmin,
        )
        .map((item) => ({
          ...item,
          compare: item.status,
          edit: true,
        }));

    if (!currentSearchTerm) {
      return rows;
    } else {
      return rows.filter((row) =>
        JSON.stringify(row)
          .toLowerCase()
          .includes(currentSearchTerm.toLowerCase()),
      );
    }
  };

  const renderEmptyList = () => {
    return (
      <div
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            borderRadius: 10,
            marginInline: '25%',
            paddingBlock: 20,
          }}
        >
          <h5>Looking to start a new project?</h5>
          <h5 style={{ color: '#ffa321', paddingLeft: 5 }}>Contact Us</h5>
          <img
            src={ContactIconSvg}
            alt="contact-icon"
            style={{ paddingLeft: 5 }}
          />
        </div>
      </div>
    );
  };

  const dtColumns: TableColumn<TableRow>[] = [
    {
      name: 'Project',
      selector: (row) => row.projectName,
      sortable: true,
    },
    {
      name: 'Products',
      selector: (row) => row.productsIncluded,
      cell: (row) => renderProductsIncluded(row),
      sortable: true,
      width: '300px',
    },
    {
      name: (
        <div
          style={{
            wordBreak: 'break-word',
          }}
        >
          Last Report Started
        </div>
      ),
      selector: (row) => row.lastReportStarted,
      format: (row) => format(new Date(row.lastReportStarted), 'MM/dd/yyyy'),
      sortable: true,
      width: '150px',
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => renderStatus(row),
      sortable: true,
      width: '150px',
    },
    {
      name: 'Comparison',
      selector: (row) => row.compare,
      cell: (row) => renderCompare(row),
      width: '100px',
    },
    (viewerRoleIsAdmin || viewerRoleIsPartnerAdmin) && {
      name: t('general.edit'),
      selector: (row) => true,
      cell: (row) => renderEdit(row),
      width: '60px',
    },
    viewerRoleIsWorkspaceAdmin && {
      name: t('general.approved'),
      selector: (row) => row.approved,
      cell: (row) => (
        <Checkbox
          color="secondary"
          checked={row.approved}
          onChange={(event, checked) =>
            toggleProjectApproval(row.projectId, checked).then(() =>
              refetchProjects(),
            )
          }
        />
      ),
      width: '100px',
    },
  ];

  return (
    <DataTable
      columns={dtColumns}
      data={getTableData(
        currentSearchTerm,
        projectReports,
        environ,
        viewerRoleIsAdmin,
        viewerRoleIsPartnerAdmin,
        viewerRoleIsWorkspaceAdmin,
      )}
      customStyles={tableCustomStyles(theme, true)}
      noDataComponent={renderEmptyList()}
      striped
      pagination
      paginationIconFirstPage={<RDTFirstPage />}
      paginationIconLastPage={<RDTLastPage />}
      paginationIconPrevious={<RDTPrevPage />}
      paginationIconNext={<RTDNextPage />}
    />
  );
};

export default ProjectListTable;
