import * as React from 'react';
import { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import FormLabel from '@mui/material/FormLabel';
import FieldTextInput from 'components/FieldTextInput';
import LoadingScreen from 'components/LoadingScreen';
import MaterialButton from 'components/MaterialButton';
import PartnerPicker from 'components/PartnerPicker';
import { GGBranding } from 'hooks/usePartnerBranding';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

interface Props {
  submitting: boolean;
  pristine: boolean;
  invalid: boolean;
  change: (field: string, value: any) => void;
  handleSubmit?: MouseEvent | any;
}

interface PartnerBrandingResults {
  partnerBrandingByPartnerId: {
    branding: GGBranding;
  };
}

const partnerBrandingQuery = gql`
  query PartnerBrandingQuery($partnerId: Int!) {
    partnerBrandingByPartnerId(partnerId: $partnerId) {
      branding
    }
  }
`;

const PartnerBrandingContainer: React.FC<Props> = (props) => {
  const { handleSubmit, submitting, pristine, invalid, change } = props;
  const { t } = useTranslation();

  const [partnerId, setPartnerId] = useState<number>(null);
  const [showPrefs, setShowPrefs] = useState<boolean>(false);

  const defaultPartnerBranding: GGBranding = {
    banner_base_color: '',
    action_button_color: '',
    banner_logo_src: '',
  };

  const [currentBranding, setCurrentBranding] = useState<GGBranding>(null);

  const { loading, error, data, refetch } = useQuery<PartnerBrandingResults>(
    partnerBrandingQuery,
    {
      variables: { partnerId: partnerId ?? -1 },
    },
  );

  useEffect(() => {
    if (data) {
      Object.keys(defaultPartnerBranding).forEach((k) =>
        change(k, data.partnerBrandingByPartnerId?.branding[k] ?? ''),
      );
      setCurrentBranding(data.partnerBrandingByPartnerId?.branding);
      setShowPrefs(true);
    }
  }, [partnerId, data]);

  if (loading || error || !showPrefs) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <PartnerPicker
        changePartnerValue={(value) => {
          change('partner', value);
          setPartnerId(value.id);
        }}
      />
      {Object.keys(defaultPartnerBranding)
        .sort()
        .map((k) =>
          k.includes('color') ? (
            <div key={k}>
              <FormLabel>{k.replace(/_/g, ' ').toUpperCase()}</FormLabel>
              <br />
              <input
                type="color"
                value={
                  ((currentBranding && currentBranding[k]) as string) ?? ''
                }
                onChange={(event) => {
                  const newVal = {};
                  newVal[k] = event.target.value;
                  setCurrentBranding({ ...currentBranding, ...newVal });
                  change(k, event.target.value);
                }}
              />
            </div>
          ) : (
            <div key={k}>
              <FormLabel>{k.replace(/_/g, ' ').toUpperCase()}</FormLabel>
              <Field name={k} component={FieldTextInput} fullWidth />
            </div>
          ),
        )}
      <MaterialButton
        variant="outlined"
        disabled={pristine || invalid || submitting}
        onClick={() => {
          handleSubmit();
        }}
        soft
        teal
      >
        {t('general.confirm')}
      </MaterialButton>
    </div>
  );
};

export default PartnerBrandingContainer;
