import React, { useRef, useEffect } from 'react';

import * as d3 from 'd3';

const Dendrogram = ({ dataArray, options = {} }) => {
  // Inspired by https://ncoughlin.com/posts/d3-react/

  // Element References
  const svgRef = useRef(null);

  useEffect(() => {
    // D3 Code
    const {
      width: width = 700,
      height: height = 600,
      hideLabels: hideLabels = false,
      paddingBottom: paddingBottom = hideLabels ? 20 : 200,
      innerHeight = height - paddingBottom,
      innerWidth = width - 10,
      paddingLeft = 30,
      h: cutHeight = 2.5,
      colors: colors = d3.schemeTableau10,
      fontFamily: fontFamily = 'sans',
      linkColor: linkColor = 'grey',
      fontSize: fontSize = height / 40,
      strokeWidth: strokeWidth = 2,
    } = options;

    // Selections
    const svg = d3
      .select(svgRef.current)
      .classed('liner-chart-svg', true)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, innerHeight])
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

    // clear all previous content on refresh
    const everything = svg.selectAll('*');
    everything.remove();

    var clusterLayout = d3
      .cluster()
      .size([width - paddingLeft * 2, innerHeight]);

    const root = d3.hierarchy(dataArray);
    const maxHeight = root.data.height;

    function transformY(data) {
      const height = hideLabels ? innerHeight - 15 : innerHeight;
      return height - (data.data.height / maxHeight) * height;
    }

    // traverse through first order children and assign colors
    if (cutHeight) {
      let curIndex = -1;
      root.each((child) => {
        if (
          child.data.height <= cutHeight &&
          child.data.height > 0 &&
          child.parent &&
          !child.parent.color
        ) {
          curIndex++;
          child.color = colors[curIndex];
        } else if (child.parent && child.parent.color) {
          child.color = child.parent.color;
        }
      });
    }

    clusterLayout(root);

    // y-axis
    svg
      .append('g')
      .attr('transform', `translate(0, ${hideLabels ? 20 : 0})`)
      .append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(${paddingLeft},${hideLabels ? 20 : 0})`)
      .call((g) => g.select('.domain').remove())

      .selectAll('.tick')
      .classed('baseline', (d) => d == 0)
      .style('font-size', `${fontSize}px`)
      .style('font-family', fontFamily);

    // Links
    root.links().forEach((link) => {
      svg
        .append('path')
        .attr('class', 'link')
        .attr('stroke', link.source.color || linkColor)
        .attr('stroke-width', `${strokeWidth}px`)
        .attr('fill', 'none')
        .attr('transform', `translate(${paddingLeft}, ${hideLabels ? 20 : 0})`)
        .attr('d', elbow(link));
    });

    // Nodes
    root.descendants().forEach((desc) => {
      if (desc.data.isLeaf && !hideLabels) {
        svg
          .append('text')
          //.attr("x", desc.x)
          .attr('dx', -5)
          .attr('dy', 3)
          .attr('text-anchor', 'end')
          .style('font-size', `${fontSize}px`)
          .style('font-family', fontFamily)
          .style('fill', desc.data.nodeColor)
          .attr(
            'transform',
            `translate(${desc.x + paddingLeft},${transformY(
              desc,
            )}) rotate(270)`,
          )
          .text(desc.data.name || desc.data.index);
      }
    });

    // Custom path generator
    function elbow(d) {
      return (
        'M' +
        d.source.x +
        ',' +
        transformY(d.source) +
        'H' +
        d.target.x +
        'V' +
        transformY(d.target)
      );
    }
  }, [dataArray]); // redraw chart if data changes

  return (
    <div className="liner-chart">
      <svg ref={svgRef} />
    </div>
  );
};

export default Dendrogram;
