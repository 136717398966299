import { connect } from 'react-redux';

import AppToastContainer from './appToastContainer';
import appToastRemove from '../../actions/appToastRemove';
import selectAppPersistenceHasLoaded from '../../selectors/appToasts';

const mapStateToProps = (state) => ({
  toasts: selectAppPersistenceHasLoaded(state),
});

const actionCreators = {
  onRequestRemoveToast: appToastRemove,
};

export default connect(mapStateToProps, actionCreators)(AppToastContainer);
