import { gql } from '@apollo/client';

export interface RpNoveltyIntensityCountsResponse
  extends reports.ReportGraphQLReponse {
  allRpNoveltyIntensityPqs: {
    totalCount: number;
  };
}

export default gql`
  query RpNoveltyIntensityCounts($reportID: UUID!) {
    allRpNoveltyIntensityPqs(filter: { reportId: { equalTo: $reportID } }) {
      totalCount
    }
  }
`;
