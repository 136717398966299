import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '95%',
      maxWidth: '80%',
      padding: '4.2rem 3.2rem',
    },
    languagesContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      border: 'solid 1px var(--pale-grey)',
    },
    languageContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '5px 0',
    },
    languageSelected: {
      fontSize: '12px',
      color: theme.palette.secondary.main,
      fontFamily: 'OpenSans, sans-serif',
    },
    languageUnselected: {
      fontSize: '12px',
      color: theme.palette.primary.main,
      fontFamily: 'OpenSans, sans-serif',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '30px',
    },
  }),
);

export default useStyles;
