import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 10,
      width: 45,
      height: 45,
    },
    usernameText: {
      fontFamily: 'OpenSans, sans-serif',
      fontWeight: 'bold',
      color: 'white',

      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.secondary.main,
      },
    },
    glossaryIcon: {
      position: 'relative',
      right: '120px',
      top: '50px',
    },
    glossaryIsOpen: {
      height: '50px',
      display: 'flex',
      flexDirection: 'row',
      marginRight: '15px',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
