import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '../../styles/theme';

const useMainStyles = (otherStyles: any) => {
  const { multiline, chromeless } = otherStyles;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        fontFamily: 'AlphaHeadlinePro-Bold',
        color: COLORS.MARINE,
        fontSize: 14,
        overflow: multiline ? 'hidden' : 'visible',
      },
      label: {
        color: '#212121',
        display: 'block',
        fontSize: '14px',
        fontWeight: 400,
      },
      input: {
        flex: 1,
        borderRadius: '3px',
        color: '#212121',
        fontSize: '14px',
        lineHeight: '20px',
        minHeight: '34px',
        outline: 'none',
        padding: chromeless ? 'none' : '4px 0',
        verticalAlign: 'middle',
        '&.Mui-focused': {
          border: '1px solid #2196f3',
          boxShadow:
            'inset 0 1px 2px rgba(27, 31, 35, 0.075), 0 0 0 0.2em rgba(3, 102, 214, 0.3)',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        '&.MuiCheckbox-root': {
          appearance: 'none',
          background: '#fafafa',
          border: '1px solid #ddd',
          borderRadius: '4px',
          boxShadow: 'inset 0 1px 4px rgba(0, 0, 0, 0.075)',
          cursor: 'pointer',
          height: '34px',
          marginTop: '6px',
          width: '34px',
          '& .Mui-checked': {
            background: '#2196f3',
            border: '1px solid white',
            boxShadow: '0 0 0 1px #ddd',
          },
        },
        descriptionText: {
          color: ' #757575',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '18px',
          minHeight: '17px',
          margin: '4px 0 2px',
        },
      },
      formContainer: {
        display: 'flex',
        flexDirection: 'column',
      },
      otherField: {
        marginBottom: '0 !important',
        flex: 1,
      },
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        minWidth: '30%',
        maxWidth: '60%',
        maxHeight: '60%',
        backgroundColor: '#fff',
        borderRadius: '5px',
        padding: '1rem',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
      },
    }),
  );

  return {
    useStyles,
  };
};

export default useMainStyles;
