import { gql } from '@apollo/client';

export default gql`
  query MarketMapRefFlavors($reportID: UUID!) {
    allRpMarketmapRfvectors(condition: { reportId: $reportID }) {
      nodes {
        referenceflavor
        angle
      }
    }
  }
`;
