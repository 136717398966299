import { useState, useCallback } from 'react';

import UpdateCustomTextureName from '@graphql/mutations/UpdateCustomTextureName';
import textureClusterNamesQuery from '@graphql/queries/textureClusterNamesQuery';

import graphqlClient from '../consumers/graphqlClient';


const useCustomTextureName = (reportId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateTextureCustomName = useCallback(async (id: number, customName: string) => {
    setIsLoading(true);
    await graphqlClient.mutate({
      mutation: UpdateCustomTextureName,
      variables: {
        id,
        customName
      },
      refetchQueries: [
        {
          query: textureClusterNamesQuery,
          variables: {
            reportID: reportId,
          },
        },
      ],
    }).finally(() => setIsLoading(false));
  }, []);

  return {
    updateTextureCustomName,
    isLoading
  };
};

export default useCustomTextureName;
