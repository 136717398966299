import * as d3 from 'd3';

import {
  ReportDetailRow,
  ReportSummaryDetailNode,
} from 'hooks/useReportSummary';
import { Optional } from 'typescript-optional';

import { ProductVersion, ProductVersionSet } from './ProductVersion';
import { nvl } from '../../utils/afsUtils';

/*
 *
 * UTILITY FUNCTIONS
 *
 */

/**
 * Convert a number to an ordinal string
 *
 * @param i input number
 * @returns ordinal string
 */
export const toOrdinal = (i: number) => {
  const j = i % 10,
    k = i % 100;

  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
};

/**
 * Convert sting to title case
 *
 * @param input string input
 * @returns title case version of string
 */
export const toTitleCase = (input: string): string => {
  if (input == null || input.length == 0) {
    return '';
  } else {
    return input
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  }
};

/**
 * Sort function - ReportDetailRows by PQ descending
 *
 * @param a reports.ReportDetailRow
 * @param b reports.ReportDetailRow
 * @returns sort ordinal
 */
export const sortByPq = (a: ReportDetailRow, b: ReportDetailRow): number => {
  if (!a) return 0;
  if (!b) return 0;

  return parseFloat(b.pq) - parseFloat(a.pq);
};

/**
 * Sort function - ReportSummaryDetailNode by PQ descending
 *
 * @param a reports.ReportSummaryDetailNode
 * @param b reports.ReportSummaryDetailNode
 * @returns sort ordinal
 */
export const sortReportSummaryDetailNodesByPq = (
  a: ReportSummaryDetailNode,
  b: ReportSummaryDetailNode,
) => {
  return sortByPq(a.node, b.node);
};

/**
 * Sort function (with excluded products) - ReportDetailRows by PQ descending
 *
 * @param a reports.ReportDetailRow
 * @param b reports.ReportDetailRow
 * @param excludedProducts the excluded ProductVersionSet
 * @returns sort ordinal
 */
export const sortByPqAndExclusion = (
  a: ReportDetailRow,
  b: ReportDetailRow,
  excludedProducts: ProductVersionSet,
): number => {
  if (!a) return 0;
  if (!b) return 0;

  const safeExcludedProducts = excludedProducts ?? new ProductVersionSet();

  if (
    safeExcludedProducts.has(
      new ProductVersion(a.productByProductId.id, a.version),
    )
  ) {
    if (
      safeExcludedProducts.has(
        new ProductVersion(b.productByProductId.id, b.version),
      )
    ) {
      return sortByPq(a, b);
    } else {
      return 1;
    }
  } else {
    if (
      safeExcludedProducts.has(
        new ProductVersion(b.productByProductId.id, b.version),
      )
    ) {
      return -1;
    } else {
      return sortByPq(a, b);
    }
  }
};

interface ProductNameParams {
  productNames: reports.ColorVersionedProductInfo[];
  productId: number;
  version: string;
  productName?: string;
}

export const getProduct = ({
  productNames,
  productId,
  version,
}: ProductNameParams): Optional<reports.ColorVersionedProductInfo> => {
  return Optional.ofNullable(
    productNames
      .filter((p) => p.id === productId && p.version === version)
      .find(Boolean),
  );
};

/**
 * Return the standard name of the product after managed from any project custom names
 *
 * @param ProductNameParams
 * @returns Standard name
 */
export const getProductName = ({
  productNames,
  productId,
  version,
  productName,
}: ProductNameParams): string => {
  if (productNames.length === 0) {
    return '';
  } else {
    const prettyPrint = (name: string, version: string): string =>
      version ? name + ' - ' + version : name;

    return getProduct({ productNames, productId, version })
      .map((p) =>
        p.customId ? p.name : prettyPrint(p.name, nvl(p.version, version)),
      )
      .orElseGet(() => prettyPrint(productName, version));
  }
};

/**
 * Return the isHighlighted status of the product after managed from any project custom names
 *
 * @param ProductNameParams
 * @returns boolean
 */
export const isProductHighlighted = ({
  productNames,
  productId,
  version,
  productName,
}: ProductNameParams): boolean => {
  if (productNames.length === 0) {
    return false;
  } else {
    return getProduct({ productNames, productId, version })
      .map((p) => p.isHighlighted)
      .orElseGet(() => false);
  }
};

/**
 * Return the canonical name of the product
 *
 * @param ProductNameParams
 * @returns canonical name
 */
export const getCanonicalName = ({
  productNames,
  productId,
  version,
  productName,
}: ProductNameParams): string => {
  const prettyPrint = (name: string, version: string): string =>
    version ? name + ' - ' + version : name;

  return getProduct({ productNames, productId, version })
    .map((p) => p.canonicalName)
    .orElseGet(() => prettyPrint(productName, version));
};

export const getProductLabel = ({
  productNames,
  productId,
  version,
}: ProductNameParams): string => {
  return getProduct({ productNames, productId, version })
    .map((p) => p.marketProductLabel)
    .orNull();
};

export function downloadSVG(event: React.MouseEvent, svgid) {
  // from https://codepen.io/Alexander9111/pen/VwLaaPe?editors=1010
  //get svg element.
  const svg = document.getElementById(svgid);
  const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));
  const a = document.createElement('a');
  const e = new MouseEvent('click');
  a.download = 'download.svg';
  a.href = 'data:image/svg+xml;base64,' + base64doc;
  a.dispatchEvent(e);
}

export function downloadSVGAsPNG(e: React.MouseEvent, svgid, width, height) {
  const canvas = document.createElement('canvas');
  const svg = document.getElementById(svgid);
  const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));
  const img_to_download = document.createElement('img');
  img_to_download.src = 'data:image/svg+xml;base64,' + base64doc;

  img_to_download.onload = function () {
    console.log('img loaded');
    canvas.setAttribute('width', width.toFixed(0));
    canvas.setAttribute('height', height.toFixed(0));
    const context = canvas.getContext('2d');
    context.drawImage(img_to_download, 0, 0, width, height);
    const dataURL = canvas.toDataURL('image/png');
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(canvas.msToBlob(), 'download.png');
      event.preventDefault();
    } else {
      const a = document.createElement('a');
      const my_evt = new MouseEvent('click');
      a.download = 'download.png';
      a.href = dataURL;
      a.dispatchEvent(my_evt);
    }
  };
}

/**
 * Check if reportId is a archtype reportId - i.e. uuidv5
 * @returns boolean of if ArchtypeReport
 */
export function isArchtypeReportId(reportId: string): boolean {
  const uuidv5 = new RegExp(
    '[a-f0-9]{8}-[a-f0-9]{4}-5[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}',
  );
  return uuidv5.test(reportId);
}

export const wrap = (
  input: d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>,
  width: number,
  axis: 'left' | 'top',
  labelFontSize: number,
  rightPad: number,
  bottomPad: number,
) => {
  input.each(function () {
    const text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      lineHeight = 1.1, // ems
      y = text.attr('y'),
      dy = parseFloat(text.attr('dy'));

    let word,
      line = [],
      lineNumber = 0,
      tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'em');

    /* eslint-disable no-cond-assign */
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(' '));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        switch (axis) {
          case 'left':
            /* eslint-disable no-case-declarations */
            const adjDy = ++lineNumber === 0 ? dy : lineHeight + dy;
            tspan = text
              .append('tspan')
              .attr('x', 0)
              .attr('y', y)
              .attr('dy', `${adjDy}em`)
              .text(word);
            text.attr(
              'transform',
              `translate(${-1 * rightPad},${(labelFontSize * lineNumber) / -2})`,
            );
            break;
          case 'top':
            tspan = text
              .append('tspan')
              .attr('x', 0)
              .attr('y', y)
              .attr('dy', `${++lineNumber * lineHeight + dy}em`)
              .text(word);
            text.attr(
              'transform',
              `translate(0,${-(labelFontSize * lineNumber + bottomPad)})`,
            );
            break;
        }
      }
    }
  });
};
