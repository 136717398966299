import { TFunction } from 'i18next';

export const foldersToTree = (t: TFunction, folders: Folders): Map<number, Folder> => {
  const ret = new Map<number,Folder>();
  ret.set(0, {
    name: t('product.allProducts'),
    id: 0,
    parentId: undefined,
    children: [],
    products: []
  })

  if (folders) {
    folders.nodes.forEach(folder => {
      ret.set(folder.id, {
        name: folder.name,
        id: folder.id,
        parentId: folder.parentId,
        children: [],
        products: []
      })
    });
    folders.nodes.map(e => addFolderToTree(ret, e.id));
  }

  return ret;
};

export const addFolderToTree = (foldersById: Map<number, Folder>, id: number) => {
  if (id === 0) {
    return;
  }

  const parent: Folder = foldersById.get(foldersById.get(id).parentId);
  
  addFolderToTree(foldersById, foldersById.get(id).parentId);
  
  if (parent.children?.includes(id)) {
    return;
  }
  
  parent.children.push(id);
};
