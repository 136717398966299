import * as React from 'react';

import { useTheme } from '@mui/material';

import useStyles from './useStyles';

const AppFooter: React.FC = (props) => {
  const classes = useStyles(useTheme());

  return <div className={classes.root}></div>;
};

AppFooter.displayName = 'AppFooter';

export default AppFooter;
