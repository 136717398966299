import { all, spawn, takeEvery } from 'redux-saga/effects';

import authenticationFormSubmit from './authenticationFormSubmit';
import batchInitializationFormSubmit from './batchInitializationFormSubmit';
import changePassword from './changePassword';
import clearSessionIfJWTExpired from './clearSessionIfJWTExpired';
import createCategory from './createCategory';
import createClient from './createClient';
import createProducerUser from './createProducerUser';
import createUser from './createUser';
import createWorkspace from './createWorkspace';
import defaultProductClass from './defaultProductClass';
import deleteCorporateDevice from './deleteCorporateDevice';
import deleteGroup from './deleteGroup';
import deleteProduct from './deleteProduct';
import deleteUser from './deleteUser';
import deviceFormSubmit from './deviceFormSubmit';
import editProductFormSubmit from './editProductFormSubmit';
import error from './error';
import googleAnalyticsSetSessionSaga from './googleAnalyticsSetSession';
import graphqlClientStoreReset from './graphqlClientStoreReset';
import { groupWorkspaceFormSubmit } from './groupWorkspaceFormSubmit';
import heavyUserInfoFormSubmit from './heavyUserInfo';
import inviteUserFormSubmit from './inviteUserFormSubmit';
import log from './log';
import mergeAccount from './mergeAccount';
import moveItem from './moveItem';
import panelFormSubmit from './panelFormSubmit';
import {
  productFilterFormSubmitSaga,
  productTagFeatureSaga,
} from './productFilterFormSubmit';
import productFormSubmit from './productFormSubmit';
import requestReportFormSubmit from './requestReportFormSubmit';
import segmentActionTrack from './segmentActionTrack';
import segmentUserIdentify from './segmentUserIdentify';
import sentrySetSessionSaga from './sentrySetSession';
import sessionClear from './sessionClear';
import tagHeavyUserFormSubmit from './tagHeavyUser';
import updateNotification from './updateNotification';
import updatePanelSettings from './updatePanelSettings';
import updatePartnerBranding from './updatePartnerBranding';
import { FORM_SUBMIT } from '../actions/formSubmit';
import { EDIT_PRODUCT_FORM } from '../constants/formNames';

const allSagas = [
  authenticationFormSubmit,
  batchInitializationFormSubmit,
  clearSessionIfJWTExpired,
  defaultProductClass,
  error,
  graphqlClientStoreReset,
  inviteUserFormSubmit,
  log,
  panelFormSubmit,
  productFormSubmit,
  segmentActionTrack,
  segmentUserIdentify,
  sessionClear,
  createProducerUser,
  deviceFormSubmit,
  createUser,
  changePassword,
  mergeAccount,
  createWorkspace,
  createCategory,
  createClient,
  requestReportFormSubmit,
  productFilterFormSubmitSaga,
  productTagFeatureSaga,
  groupWorkspaceFormSubmit,
  googleAnalyticsSetSessionSaga,
  sentrySetSessionSaga,
  tagHeavyUserFormSubmit,
  heavyUserInfoFormSubmit,
  updatePartnerBranding,
  ...moveItem,
];

export default function* rootSaga() {
  yield all(allSagas.map((saga) => spawn(saga)));

  yield all([
    takeEvery(FORM_SUBMIT, updatePanelSettings),
    takeEvery(
      ({ type, payload }) =>
        type === FORM_SUBMIT && payload === EDIT_PRODUCT_FORM,
      editProductFormSubmit,
    ),
    takeEvery('DELETE_GROUP', deleteGroup),
    takeEvery('DELETE_PRODUCT', deleteProduct),
    takeEvery('DELETE_USER', deleteUser),
    takeEvery('DELETE_DEVICE', deleteCorporateDevice),
    takeEvery('UPDATE_NOTIFICATION', updateNotification),
  ]);
}
