import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '20px',
    },
    valueLabel: {
      fontWeight: 'normal',
      top: -16,
    },
    markLabel: {
      fontSize: '12px',
      color: theme.palette.primary.main,
    },
    thumb: {
      backgroundColor: theme.palette.secondary.main,
    },
    mark: {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
);

export default useStyles;
