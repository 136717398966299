import { useState, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';

const query = gql`
  query getEnvironment {
    ggEnvironment
  }
`;

interface Environment {
  ggEnvironment: SensoryLinkEnvironment;
}

export default function useGgEnvironment(): SensoryLinkEnvironment {
  const [environment, setEnvironment] = useState<SensoryLinkEnvironment>(null);

  const { data, loading, error } = useQuery<Environment>(query);

  useEffect(() => {
    if (!loading && !error) {
      setEnvironment(data?.ggEnvironment);
    }
  }, [data]);

  return environment;
}
