import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import flexContainer from '../../styles/flexContainer';

const colorsByStatus = {
  primary: '#42a5f5',
  positive: '#7cb342',
  warn: '#ff9800',
  negative: '#f44336',
  neutral: '#78909c',
};

const useMainStyles = (otherStyles: any) => {
  const { status } = otherStyles;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        ...flexContainer(theme),
        backgroundColor: 'white',
        borderRadius: 4,
        border: '1px solid #e4edie5',
        padding: '10px',
        margin: '8px',
      },
      contentContainer: {
        color: status ? colorsByStatus[status] : colorsByStatus.primary,
        paddingTop: '8px',
      },
      title: {
        color: '#78909C',
        fontSize: '12px',
        fontWeight: 'normal',
      },
      h1: {
        fontSize: '24px',
        fontWeight: 400,
      },
      h2: {
        fontSize: '18px',
        fontWeight: 300,
      },
      h3: {
        fontSize: '13px',
        fontWeight: 'normal',
      },
    }),
  );

  return {
    useStyles,
  };
};

export default useMainStyles;
