import * as React from 'react';

import { useQuery } from '@apollo/client';
import UserWorkspaceListQuery from '@graphql/queries/UserWorkspaceListQuery';
import { Search as SearchIcon } from '@mui/icons-material';
import { Input, InputAdornment, LinearProgress, List, useTheme } from '@mui/material';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import styles from './UserWorkspaceList.module.css';
import { tableCustomStyles } from '../../components/ReactDataTable/tableCustomStyles';

interface Props {
  email: string;
}

interface TableRow {
  id: number;
  name: string;
}

const Result: React.FC<Props> = ({ email }) => {
  const { loading, error, data } = useQuery(UserWorkspaceListQuery, {
    variables: {
      email: email,
    },
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const MySearch = (props) => {
    return (
      <Input
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
        onChange={(event) => props.onSearch(event.target.value)}
        placeholder={t('general.search')}
      />
    );
  };

  const getData = (): TableRow[] => {
    return data.user.producerUsersByUserId.nodes.map((node) => {
      return {
        id: node.producer.id,
        name: node.producer.name,
      };
    });
  };

  const columns: TableColumn<TableRow>[] = [
    {
      selector: (row: TableRow) => row.id,
      name: 'ID',
    },
    {
      selector: (row: TableRow) => row.name,
      name: 'Name',
      sortable: true,
    },
  ];

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <h4>Can't load workspaces!</h4>;
  }

  if (data.user === null) {
    return <h4>User not found!</h4>;
  }

  return (
    <div>
      {(props) => (
        <div style={{ width: '100%' }}>
          <List>
            <div className={styles.headerContainer}>
              <h4>
                {data &&
                  data.user &&
                  data.user.producerUsersByUserId.totalCount}{' '}
                {t('admin.workspaces')}
              </h4>
              <MySearch {...props.searchProps} />
            </div>
          </List>
        </div>
      )}
      <DataTable
        columns={columns}
        data={getData()}
        customStyles={tableCustomStyles(theme, true)}
        striped
        pagination
        paginationIconFirstPage={<RDTFirstPage />}
        paginationIconLastPage={<RDTLastPage />}
        paginationIconPrevious={<RDTPrevPage />}
        paginationIconNext={<RTDNextPage />}
      />
    </div>
  );
};

export default Result;
