import { gql } from '@apollo/client';

export default gql`
    mutation DeleteProducerUserMutation($id: Int!) {
        deleteProducerUserById(input: {id: $id}) {
        producerUser{
            userId
            producerId
            }
        }
    }
`;
