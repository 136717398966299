import { schemePaired } from 'd3';

import { CustomLayer, CustomLayerProps } from '@nivo/line';
import { line, area } from 'd3-shape';

export const MEAN_COLORS = schemePaired
  .filter((x, i) => i % 2 == 0)
  .map((c) => c + 'B9');
export const LINE_COLORS = schemePaired.filter((x, i) => i % 2 == 1);

/**
 * Return the mean PQ from a set of summary data
 * @param summaryData summary data to be used
 * @returns mean pq
 */
export const getMeanPq = (
  summaryData: reports.ProductSummaryPQWinRate,
): number =>
  summaryData?.allReportSummaries?.nodes
    .map((n) => parseFloat(n.pq))
    .reduce((a, b) => (a ? a : b), 0 as number);

/**
 * Return the polarization from a set of summary data
 * @param summaryData summary data to be used
 * @returns polarization
 */
export const getPolarization = (
  summaryData: reports.ProductSummaryPQWinRate,
): number =>
  summaryData?.allReportSummaries?.nodes
    .map((n) => parseFloat(n.polarization))
    .reduce((a, b) => (a ? a : b), 0 as number);

/**
 * Render a pq mean layer with polarization box in nivo
 * @param meanpq mean pq to render as a vertical line
 * @param polarization polarization to render as a transparent box around pq mean
 * @param idx index of layer for coloring
 * @returns nivo custom layer
 */
export const pqMeanLayer: (
  summaryData: reports.ProductSummaryPQWinRate,
  idx: number,
  hidden?: boolean,
) => CustomLayer = (summaryData, idx, hidden?) => (props: CustomLayerProps) => {
  if (hidden) {
    return <g></g>;
  }

  const { xScale, innerHeight } = props;

  const meanpq = getMeanPq(summaryData);
  const polarization = getPolarization(summaryData);
  const pqSpread = polarization / 2;

  const slope =
    (xScale(Math.ceil(meanpq)) as number) -
    (xScale(Math.floor(meanpq)) as number);
  const xLocation =
    (xScale(Math.floor(meanpq)) as number) +
    (meanpq - Math.floor(meanpq)) * slope;

  const areaGenerator = area();
  const lineGenerator = line();
  return (
    <g>
      <path
        d={areaGenerator([
          [xLocation - slope * pqSpread, 0],
          [xLocation + slope * pqSpread, 0],
          [xLocation + slope * pqSpread, innerHeight],
          [xLocation - slope * pqSpread, innerHeight],
        ])}
        fill={MEAN_COLORS[idx]}
        stroke={MEAN_COLORS[idx]}
        style={{ pointerEvents: 'none', strokeWidth: '2' }}
      />
      <path
        d={lineGenerator([
          [xLocation, 0],
          [xLocation, innerHeight],
        ])}
        fill="none"
        stroke={LINE_COLORS[idx]}
        style={{
          pointerEvents: 'none',
          strokeWidth: '2',
          strokeDasharray: '4, 4',
        }}
      />
    </g>
  );
};
