import * as React from 'react';
import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import AllRpOfpDescriptions, {
  AllRpOfpDescriptionsResponse,
} from '@graphql/queries/ReportAllOfpDescriptionsQuery';
import { AutoAwesome } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import useGgEnvironment from 'hooks/useGgEnvironment';
import { useTranslation } from 'react-i18next';
import {
  showLLMOfpDescription,
  useCustomerPreferences,
} from 'services/customerPreferences';

interface Props {
  reportId: string;
  productId: number;
  productVersion: string;
}

const char92ReplaceString = 'DDDD';
const char92SplitString = 'DDDDDDDD';

const removeChar92 = (str: string) => {
  let newStr = '';
  for (let i = 0; i < str.split('').length - 1; i++) {
    if (str.charCodeAt(i) == 92 && (str[i + 1] == 'n' || str[i + 1] == 'r')) {
      i++;
      newStr += char92ReplaceString;
    } else newStr += str[i];
  }
  return newStr;
};

const removeChar92WithoutN = (str: string) => {
  let newStr = '';
  for (let i = 0; i < str.split('').length - 1; i++) {
    if (str.charCodeAt(i) == 92) {
      i++;
      newStr += '';
    } else newStr += str[i];
  }
  return newStr;
};

const removeIrrelevantStrings = (newStr: string) => {
  const str = removeChar92WithoutN(newStr);
  return str.trim();
};

const ReportOfpDescription: React.FC<Props> = (props) => {
  const { reportId, productId, productVersion } = props;

  const { t } = useTranslation();
  const customerPreferences = useCustomerPreferences();
  const environment = useGgEnvironment();

  const [descriptionArray, setDescriptionArray] = useState<string[]>([]);

  const { loading, error, data } = useQuery<AllRpOfpDescriptionsResponse>(
    AllRpOfpDescriptions,
    {
      variables: {
        filter: {
          reportId: { equalTo: reportId },
          productId: { equalTo: productId },
          version: { equalTo: productVersion ?? '' },
        },
      },
    },
  );

  useEffect(() => {
    if (!loading && data) {
      const str = data.descriptions.nodes.find(Boolean)?.description ?? '';

      if (str.length > 0) {
        setDescriptionArray(
          removeChar92(str.slice(1, str.length - 1)).split(char92SplitString),
        );
      }
    }
  }, [loading, data]);

  // Currently disable for prod environments
  if (!showLLMOfpDescription(customerPreferences, environment)) {
    return null;
  }

  if (
    !reportId ||
    !productId ||
    loading ||
    error ||
    descriptionArray.length === 0
  ) {
    return null;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <AutoAwesome />
        <Typography variant="h5">
          {t('reports.ofpDescription.title')}
        </Typography>
      </Box>
      <Box>
        {descriptionArray &&
          !!descriptionArray.length &&
          descriptionArray.map((text, index) => (
            <p key={index}>{removeIrrelevantStrings(text)}</p>
          ))}
      </Box>
    </Box>
  );
};

export default ReportOfpDescription;
