import * as React from 'react';

import { ClientListOptions } from 'hooks/useClientList';
import useClientPartnerListByUser from 'hooks/useClientPartnerList';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import FormInput from '../../../components/FormInput';
import FormInputSelect from '../../../components/FormInputSelect';
import selectViewerUserId from '../../../selectors/viewerUserId';

interface Props {
  change: (field: string, value: any) => void;
}

const ClientOptionsPartnerAdmin: React.FC<Props> = ({ change }) => {
  const { t } = useTranslation();
  const currentUserId = useSelector((state) => selectViewerUserId(state));
  const [clientOptions, setClientOptions] = React.useState<ClientListOptions[]>(
    [],
  );

  const clientOptionsData = useClientPartnerListByUser(currentUserId);

  React.useEffect(() => {
    if (clientOptionsData) {
      setClientOptions(clientOptionsData);
    }
  }, [clientOptionsData]);

  return (
    <Field
      key={clientOptions.length}
      name="client_name"
      component={FormInput}
      inputComponent={FormInputSelect}
      customLabel
      labelText={t('reports.clientLabel')}
      options={clientOptions}
      isSearchable
      hideSelectedOptions={false}
      placeholder={t('reports.clientPlaceholder')}
      closeMenuOnSelect={true}
      required
      value={(val) => val.value}
      onChange={(_, newValue) => {
        change('client_name', newValue.value);
      }}
    />
  );
};

export default ClientOptionsPartnerAdmin;
