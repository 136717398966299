import { gql } from '@apollo/client';

export interface AllDevicesResponse {
  loading: boolean;
  error: any;
  allCorporateDevices: {
    nodes: allCorporateDeviceNode[];
  };
}

export interface allCorporateDeviceNode {
  id: number;
  deviceName: string;
  deviceUid: string;
}

export default gql`
  query AllDevicesQuery(
    $orderBy: [CorporateDevicesOrderBy!]
    $condition: CorporateDeviceCondition!
  ) {
    allCorporateDevices(orderBy: $orderBy, condition: $condition) {
      nodes {
        id
        deviceName
        deviceUid
      }
    }
  }
`;
