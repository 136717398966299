import * as React from 'react';
import { ReactElement, useState } from 'react';

import Search from '@mui/icons-material/Search';
import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import usePartnerBranding from 'hooks/usePartnerBranding';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';

const sensoryLinkLogo = require('../../../public/assets/logo_white.png');

interface Props {
  onClickCloseMenu: () => any;
  onClickOpenMenu: () => any;
  menuIsOpen: boolean;
  renderWorkspaceLink: (params: {
    children: ReactElement;
    workspaceKey: number;
    clearSearch: () => void;
  }) => ReactElement;
  renderWorkspaceOption: (params: {
    workspaceKey: number;
    classes: object;
  }) => ReactElement;
  renderCurrentWorkspace: (params: {
    workspaceKey: number;
    workspaceOptionClassName: string;
    workspaceCurrentTextClassName: string;
  }) => ReactElement;
  selectedWorkspaceKey: number;
  workspaceKeys: number[];
  workspaceProducerNamesByWorkspaceKey: Map<number, string>;
}

const AppWorkspaceMenu: React.FC<Props> = (props) => {
  const {
    onClickCloseMenu,
    onClickOpenMenu,
    menuIsOpen,
    renderWorkspaceLink,
    renderCurrentWorkspace,
    renderWorkspaceOption,
    selectedWorkspaceKey,
    workspaceKeys,
    workspaceProducerNamesByWorkspaceKey,
    ...rest
  } = props;

  const { t } = useTranslation();
  const classes = useStyles(useTheme());
  const branding = usePartnerBranding();
  const [searchString, setSearchString] = useState('');

  const filteredWorkspaceKeys = [...(workspaceKeys ?? [])]
    .filter((id) =>
      workspaceProducerNamesByWorkspaceKey[id]
        .toLowerCase()
        .includes(searchString.toLowerCase()),
    )
    .sort((a, b) =>
      workspaceProducerNamesByWorkspaceKey[a]
        .toString()
        .localeCompare(workspaceProducerNamesByWorkspaceKey[b].toString()),
    );

  const onClose = () => {
    setSearchString('');
    onClickCloseMenu();
  };

  return (
    <div className={classes.root}>
      {menuIsOpen && (
        <div className={classes.backdrop} onClick={onClose} tabIndex={-1} />
      )}
      <div className={classes.relativeWrapper}>
        <img
          src={branding?.banner_logo_src ?? sensoryLinkLogo}
          alt="header-logo"
          width={165}
        />
        <a
          className={classes.toggleMenuIsOpenButton}
          onClick={menuIsOpen ? onClose : onClickOpenMenu}
          tabIndex={-1}
        >
          {renderCurrentWorkspace({
            workspaceKey: selectedWorkspaceKey,
            workspaceOptionClassName: classes.workspaceOptions,
            workspaceCurrentTextClassName: classes.workspaceCurrentText,
          })}
        </a>
        <Drawer
          anchor="top"
          open={!selectedWorkspaceKey || menuIsOpen}
          onClose={onClose}
        >
          <List>
            <div className={classes.headerContainer}>
              <span className={classes.selectWorkspaceText}>
                {t('viewerMenu.selectWorkspace')}
              </span>
              <Input
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
                onChange={(e) => setSearchString(e.target.value)}
                placeholder={t('general.search')}
                value={searchString}
                autoFocus
                classes={{ root: classes.searchBar }}
              />
            </div>
            <Divider />
            {filteredWorkspaceKeys.map((workspaceKey) =>
              renderWorkspaceLink({
                children: renderWorkspaceOption({
                  workspaceKey,
                  classes,
                }),
                workspaceKey,
                clearSearch: () => setSearchString(''),
              }),
            )}
          </List>
        </Drawer>
      </div>
    </div>
  );
};

AppWorkspaceMenu.displayName = 'AppWorkspaceMenu';

export default AppWorkspaceMenu;
