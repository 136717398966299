import * as React from 'react';

import { SvgIcon } from '@mui/material';

// Generated with https://react-svgr.com/playground/
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  ></svg>
);

const SvgFileIcon = (props) => (
  <SvgIcon component={SvgComponent} viewBox="0 0 1200 1200" {...props} />
);

export default SvgFileIcon;
