import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import DescriptionIcon from '@mui/icons-material/Description';
import { LinearProgress } from '@mui/material';
import CSVReader from 'react-csv-reader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import styles from './HeavyUserTag.module.css';
import FieldTextInput from '../../components/FieldTextInput';
import FormInput from '../../components/FormInput';
import FormInputSelect from '../../components/FormInputSelect';
import MaterialButton from '../../components/MaterialButton';
import { TAG_HEAVY_USER_FORM } from '../../constants/formNames';
import AllProductCategoriesQuery from '../../graphql/queries/AllProductCategoriesQuery';
import AllProductComponentBasesQuery from '../../graphql/queries/AllProductComponentBasesQuery';
import AllProductComponentOthersQuery from '../../graphql/queries/AllProductComponentOthersQuery';
import AllProductFeaturesQuery from '../../graphql/queries/AllProductFeaturesQuery';

interface Props {
  handleSubmit: any;
  submitting: any;
  pristine: any;
  invalid: any;
  change: any;
}

const HeavyUserTag: React.FC<Props> = ({
  handleSubmit,
  submitting,
  pristine,
  invalid,
  change,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);

  const producerId = useSelector((state) => selectWorkspaceProducerId(state));

  const formValues = useSelector((state) =>
    getFormValues(TAG_HEAVY_USER_FORM)(state),
  );

  const notFoundUsers = formValues && formValues.notFoundUsers;

  const allProductCategoriesQuery = useQuery(AllProductCategoriesQuery, {
    variables: {
      condition: {
        producerId: producerId,
      },
    },
  });
  const allProductFeaturesQuery = useQuery(AllProductFeaturesQuery, {
    variables: {
      condition: {
        producerId: producerId,
      },
    },
  });
  const allProductComponentBasesQuery = useQuery(
    AllProductComponentBasesQuery,
    {
      variables: {
        condition: {
          producerId: producerId,
        },
      },
    },
  );
  const allProductComponentOthersQuery = useQuery(
    AllProductComponentOthersQuery,
    {
      variables: {
        condition: {
          producerId: producerId,
        },
      },
    },
  );

  if (
    allProductCategoriesQuery.loading ||
    allProductComponentBasesQuery.loading ||
    allProductFeaturesQuery.loading ||
    allProductComponentOthersQuery.loading
  ) {
    return <LinearProgress />;
  }

  return (
    <div style={{ width: '100%' }}>
      <div className={styles.sectionContainer}>
        <div className={styles.sampleFileContainer}>
          <a
            href={require('../../../public/assets/heavy_users_sample.csv')}
            download="heavy_users_sample"
          >
            <DescriptionIcon />
            <u>Sample CSV File</u>
          </a>
        </div>

        {error && (
          <div className={styles.fileError}>
            File is not in the right format!
          </div>
        )}

        <Field
          name="usersFromCsv"
          component={CSVReader}
          onFileLoaded={(data, fileInfo) => {
            if (!data[0].user) {
              change('usersFromCsv', []);
              setError(true);
            } else {
              setError(false);
              change('usersFromCsv', data);
            }
          }}
          parserOptions={{
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: (header) =>
              header.toLowerCase().replace(/\W/g, '_'),
          }}
        />

        <Field
          name="tag"
          component={FieldTextInput}
          fullWidth
          label={t('admin.tag')}
          required
        />

        <Field
          name="categories"
          component={FormInput}
          inputComponent={FormInputSelect}
          key="categories"
          labelText={t('product.productCategory')}
          options={allProductCategoriesQuery.data.productCategories.nodes.map(
            (item) => ({
              value: item.id,
              label: item.name,
            }),
          )}
          isSearchable
          isClearable
          isMulti
          placeholder={t('product.productCategory')}
          customLabel
          checkbox
        />
        <Field
          name="features"
          component={FormInput}
          inputComponent={FormInputSelect}
          key="features"
          labelText={t('product.productFeature')}
          options={allProductFeaturesQuery.data.productFeatures.nodes.map(
            (item) => ({
              value: item.id,
              label: item.name,
            }),
          )}
          isSearchable
          isClearable
          isMulti
          placeholder={t('product.productFeature')}
          customLabel
          checkbox
        />
        <Field
          name="componentBases"
          component={FormInput}
          inputComponent={FormInputSelect}
          key="componentBases"
          labelText={t('product.productComponentBase')}
          options={allProductComponentBasesQuery.data.productComponentBases.nodes.map(
            (item) => ({
              value: item.id,
              label: item.name,
            }),
          )}
          isSearchable
          isClearable
          isMulti
          placeholder={t('product.productComponentBase')}
          customLabel
          checkbox
        />
        <Field
          name="componentOthers"
          component={FormInput}
          inputComponent={FormInputSelect}
          key="componentOthers"
          labelText={t('product.productComponentOther')}
          options={allProductComponentOthersQuery.data.productComponentOthers.nodes.map(
            (item) => ({
              value: item.id,
              label: item.name,
            }),
          )}
          isSearchable
          isClearable
          isMulti
          placeholder={t('product.productComponentOther')}
          customLabel
          checkbox
        />
      </div>

      <div className={styles.buttonContainer}>
        <MaterialButton
          variant="outlined"
          disabled={pristine || invalid || submitting}
          onClick={handleSubmit}
          soft
          teal
        >
          Submit
        </MaterialButton>
      </div>
      {notFoundUsers && notFoundUsers.length > 0 && (
        <div>
          <div>These following users are not found:</div>
          <table className={styles.notFoundTable}>
            {notFoundUsers.map((user, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{user}</td>
              </tr>
            ))}
          </table>
        </div>
      )}
    </div>
  );
};

export default HeavyUserTag;
