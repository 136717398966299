import * as React from 'react';

import { ThemeProvider as AppThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import usePartnerBranding from 'hooks/usePartnerBranding';

import { GenerateMuiTheme } from './styles/theme';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const ThemeProvider: React.FC = (props) => {
  const branding = usePartnerBranding();

  return (
    <StyledEngineProvider injectFirst>
      <AppThemeProvider theme={GenerateMuiTheme(branding)}>
        {props.children}
      </AppThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
