import { useEffect } from 'react';

// Hide the chat box
export default function useHideOlark() {
  useEffect(() => {
    const scriptOff = document.createElement('script');
    scriptOff.append("olark('api.box.hide');");
    document.body.appendChild(scriptOff);

    return () => {
      document.body.removeChild(scriptOff);

      const scriptOn = document.createElement('script');
      scriptOn.append("olark('api.box.show');");
      document.body.appendChild(scriptOn);
    };
  }, []);
}
