import jwt_decode from 'jwt-decode';
import { connect } from 'react-redux';

import ConditionViewerRole from './conditionViewerRole';
import {
  adminUserRole,
  superadminUserRole,
  workspaceAdminRole,
  panelManageRole,
  analysisViewRole,
  productReviewRole,
  partnerAdminRole,
  textureViewerRole,
} from '../../constants/userRoles';

const mapStateToProps = (state) => {
  const authToken = state.session && state.session.token;
  const userRolesJwt = jwt_decode<IAMToken>(authToken).roles;

  // Safety check the roles
  const userRoles = userRolesJwt == null ? [] : userRolesJwt;

  return {
    viewerRoles: {
      viewerRoleIsAdmin: userRoles.includes(adminUserRole.toLocaleLowerCase()),
      viewerRoleIsSuperadmin: userRoles.includes(
        superadminUserRole.toLocaleLowerCase(),
      ),
      viewerRoleIsWorkspaceAdmin: userRoles.includes(
        workspaceAdminRole.toLocaleLowerCase(),
      ),
      viewerRoleIsPanelManage: userRoles.includes(
        panelManageRole.toLocaleLowerCase(),
      ),
      viewerRoleIsTextureAnalysis: userRoles.includes(
        textureViewerRole.toLocaleLowerCase(),
      ),
      viewerRoleIsAnalysisView: userRoles.includes(
        analysisViewRole.toLocaleLowerCase(),
      ),
      viewerRoleIsProductReview: userRoles.includes(
        productReviewRole.toLocaleLowerCase(),
      ),
      viewerRoleIsPartnerAdmin: userRoles.includes(
        partnerAdminRole.toLocaleLowerCase(),
      ),
    },
  };
};

export default connect(mapStateToProps)(ConditionViewerRole);
