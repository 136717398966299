import * as React from 'react';
import { useState } from 'react';

import { CheckCircleRounded, ErrorRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import syncValidate from './syncValidate';
import useStyles from './useStyles';
import FieldTextInput from '../../components/FieldTextInput';
import FormFieldSet from '../../components/FormFieldSet';
import MaterialButton from '../../components/MaterialButton';
import { AUTHENTICATION_FORM } from '../../constants/formNames';
import FormContainer from '../Form';

const AuthenticationContainer = () => {
  const classes = useStyles(useTheme());
  const welcomeMessage =
    'Welcome to ' +
    (window.location.href.includes('gastrograph.com')
      ? 'Gastrograph'
      : 'SensoryLink');
  const failedLogin = useSelector(
    (state) => (state as any).session && (state as any).session.failedLogin,
  );
  const failedLoginEmail = useSelector(
    (state) =>
      (state as any).session && (state as any).session.failedLoginEmail,
  );
  const [resetSubmitResult, setResetSubmitResult] = useState<{ ok: boolean }>(
    null,
  );

  return (
    <div className={classes.authenticationContainer}>
      <FormContainer
        formConfiguration={{ validate: syncValidate }}
        formName={AUTHENTICATION_FORM}
        render={({ handleSubmit, invalid, submitting }) => (
          <div className={classes.welcomePanel}>
            <div className={classes.header}>
              <span>{welcomeMessage}</span>
            </div>
            <form
              className={classes.authenticationForm}
              onSubmit={handleSubmit}
            >
              {submitting && <p>Signing In...</p>}
              <FormFieldSet
                renderInputs={({ inputClassName }) => [
                  <Field
                    component={FieldTextInput}
                    className={inputClassName}
                    fullWidth
                    margin="none"
                    variant="outlined"
                    key="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    placeholder="you@example.com"
                    style={{ marginBottom: 20 }}
                  />,
                  <Field
                    component={FieldTextInput}
                    className={inputClassName}
                    fullWidth
                    variant="outlined"
                    margin="none"
                    key="password"
                    label="Password"
                    name="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    type="password"
                  />,
                ]}
              />
              <div className={classes.panelFooter}>
                <MaterialButton
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={invalid}
                  type="submit"
                >
                  Sign In
                </MaterialButton>
              </div>
              {failedLogin && (
                <div>
                  <div
                    className={classes.panelFooter}
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <MaterialButton
                      variant="outlined"
                      onClick={() => {
                        setResetSubmitResult(null);
                        fetch('/iam/requestPasswordResetEmail', {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ email: failedLoginEmail }),
                        })
                          .then((result) => setResetSubmitResult(result))
                          .catch((error) => setResetSubmitResult(error));
                      }}
                      soft
                    >
                      Reset Password
                    </MaterialButton>
                    {!!resetSubmitResult && (
                      <div
                        style={{
                          color: resetSubmitResult.ok ? 'green' : 'red',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {resetSubmitResult.ok ? (
                          <CheckCircleRounded
                            fontSize="small"
                            htmlColor="green"
                          />
                        ) : (
                          <ErrorRounded fontSize="small" htmlColor="red" />
                        )}
                        &nbsp;
                        {resetSubmitResult.ok
                          ? 'Reset email sent'
                          : 'An error occurred'}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        )}
      />
    </div>
  );
};

AuthenticationContainer.displayName = 'AuthenticationContainer';

export default AuthenticationContainer;
