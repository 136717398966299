import { NonePartner } from 'constants/partner';

import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import CreateWorkspace from './CreateWorkspace';
import formSubmit from '../../actions/formSubmit';
import { CREATE_WORKSPACE_FORM } from '../../constants/formNames';

const validation = (val) =>
  !val || (typeof val === 'object' && val.length === 0);

const mapStateToProps = (state, props) => ({
  initialValues: {
    partner: NonePartner,
  },
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: CREATE_WORKSPACE_FORM,
    onSubmit: (values, dispatch) => {
      dispatch(formSubmit(CREATE_WORKSPACE_FORM));
    },
    validate: (values) => {
      return {
        name: validation(values.name),
        slug: validation(values.slug),
        defaultTimezone: validation(values.defaultTimezone),
        partner: validation(values.partner),
      };
    },
  }),
)(CreateWorkspace);
