import * as React from 'react';
import { useEffect, useState } from 'react';

import jwt_decode from 'jwt-decode';
import { flowRight as compose } from 'lodash';
import { remove } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import styles from './UserWorkspaceList.module.css';
import FieldCheckBox from '../../components/FieldCheckBox';
import MaterialButton from '../../components/MaterialButton';
import { EDIT_USER_ROLE_FORM } from '../../constants/formNames';
import { allRoles } from '../../constants/userRoles';
import httpEditUserRoles from '../../consumers/httpEditUserRoles';
import httpViewUserRoles from '../../consumers/httpViewUserRoles';
import sessionToken from '../../selectors/sessionToken';

interface Props {
  userId: number;
  handleSubmit: any;
  error: boolean;
  pristine: boolean;
  submitting: boolean;
  initialValues: any;
}

const UserRolesResult: React.FC<Props> = ({ userId, handleSubmit }: Props) => {
  const [roles, setRoles] = useState({});
  const [edit, setEdit] = useState(false);
  const [viewError, setError] = useState('');
  const authToken = useSelector((state) => sessionToken(state));
  const userRoles = jwt_decode<{ roles: string[] }>(authToken).roles || [];

  const notSuperAdmin = (role) =>
    ['GASTROGRAPH_SUPERADMIN', 'GASTROGRAPH_ADMIN', 'TEXTURE_VIEWER'].includes(
      role,
    );
  if (!userRoles.includes('gastrograph_superadmin')) {
    remove(allRoles, notSuperAdmin);
  }

  useEffect(() => {
    async function fetchUserRoles() {
      await httpViewUserRoles(userId, authToken).then((response) => {
        if (!response) {
          setError(
            'Roles for this user were not found. Do you have permission to view this?',
          );
          return;
        }
        response.forEach((element) => {
          setRoles((existingValues) => ({
            ...existingValues,
            [element]: true,
          }));
        });
      });
    }

    fetchUserRoles();
  }, []);

  const { t } = useTranslation();

  const formatRoleName = (name) => {
    return name.replace('_', ' ');
  };

  const formatRoleVal = (name) => {
    return name.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  };

  const handleEditRole = async () => {
    let updatedRoles = Object.keys(roles)
      .filter((r) => roles[r])
      .join(',');
    updatedRoles = '{' + updatedRoles + '}';
    const response = await httpEditUserRoles(
      userId,
      updatedRoles,
      authToken,
    ).then((response) => {
      setEdit(!edit);
    });
  };

  const handleCheckboxChange = (name) => {
    setRoles((existingValues) => ({
      ...existingValues,
      [name]: !roles[name],
    }));
  };

  const getUserRoles = () => {
    return (
      <div className={styles.addUserRoleFormParent}>
        <div className={styles.addUserRoleForm}>
          {allRoles.map((role) => {
            return (
              <Field
                component={FieldCheckBox}
                val={roles[formatRoleVal(role)]}
                key={role}
                label={formatRoleName(role)}
                name={formatRoleVal(role)}
                disabled={!edit}
                value={roles[formatRoleVal(role)]}
                onChange={() => handleCheckboxChange(formatRoleVal(role))}
                checkboxContainer
              />
            );
          })}
        </div>
      </div>
    );
  };

  if (viewError != '') {
    return <h4>{viewError}</h4>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        {getUserRoles()}
        {!edit && (
          <MaterialButton
            variant="outlined"
            onClick={() => setEdit(true)}
            soft
            teal
          >
            Edit
          </MaterialButton>
        )}
        {edit && (
          <MaterialButton
            variant="outlined"
            onClick={() => {
              handleEditRole();
            }}
            soft
            teal
          >
            Save
          </MaterialButton>
        )}
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: EDIT_USER_ROLE_FORM,
  }),
)(UserRolesResult);
