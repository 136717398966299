import * as React from 'react';

import { useQuery } from '@apollo/client';
import NoveltyPairedPreferencesByReportId, {
  ReportNoveltyPairedPreferenceComparisonResponse,
} from '@graphql/queries/ReportNoveltyPairedPreferences';
import { Box, Typography } from '@mui/material';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { useTranslation } from 'react-i18next';

import PairedProductNoveltyComparison from './PairedProductNoveltyComparison';
import { noveltyPairedPreferenceComparisonArray } from './utils';

interface ReportProps {
  projectId: number;
  reportId: string;
}

const ReportPairedProductNoveltyComparison: React.FC<ReportProps> = (props) => {
  const { projectId, reportId } = props;

  const { t } = useTranslation();

  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const { loading, error, data } =
    useQuery<ReportNoveltyPairedPreferenceComparisonResponse>(
      NoveltyPairedPreferencesByReportId,
      {
        variables: {
          reportID: reportId,
        },
      },
    );

  if (loading || error) {
    return <div />;
  }

  return (
    <Box>
      <Typography variant="h6" align="center">
        {t('reports.noveltyProductComparison.title')}
      </Typography>
      <PairedProductNoveltyComparison
        noveltyPairedPreferenceComparisonArray={noveltyPairedPreferenceComparisonArray(
          data,
          productNames,
        )}
      />
    </Box>
  );
};

export default ReportPairedProductNoveltyComparison;
