import { NonePartner, Partner } from 'constants/partner';

import { useEffect, useState } from 'react';
import * as React from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormInput from 'components/FormInput';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import selectViewerUserId from 'selectors/viewerUserId';

import AllPartnersQuery from '../../graphql/queries/AllPartnersQuery';
import GetUserPartners from '../../graphql/queries/UserPartnersQuery';
import selectTokenRoles from '../../selectors/sessionTokenRoles';
import { hasSuperAdminRole } from '../../utils/roleUtils';
import FormInputSelect from '../FormInputSelect';

interface Props {
  changePartnerValue: (value: Partner) => void;
  className?: any;
}

const useStyles = makeStyles({
  root: {
    paddingTop: '18px',
  },
});

const PartnerPicker: React.FC<Props> = (props) => {
  const { changePartnerValue, className } = props;

  const [partnerOptions, setPartnerOptions] = useState<Partner[]>([]);

  const userRoles = useSelector((state) => selectTokenRoles(state));
  const viewerUserId = useSelector((state) => selectViewerUserId(state));

  const { t } = useTranslation();

  const classes = useStyles();

  const {
    loading,
    error,
    data: partnerData,
  } = hasSuperAdminRole(userRoles)
    ? useQuery(AllPartnersQuery)
    : useQuery(GetUserPartners, { variables: { userId: viewerUserId } });

  useEffect(() => {
    if (!!partnerData && !hasSuperAdminRole(userRoles)) {
      const options: Partner[] = partnerData.allUserPartners.nodes.map((e) => ({
        label: e.partnerByPartnerId.name,
        id: e.partnerByPartnerId.id,
        name: e.partnerByPartnerId.name,
      }));
      setPartnerOptions(options);
      if (options.length > 0) {
        changePartnerValue(options[0]);
      }
    }

    if (!!partnerData && hasSuperAdminRole(userRoles)) {
      const options = [NonePartner].concat(
        partnerData.allPartners.edges.map((e) => ({
          label: e.node.name,
          id: e.node.id,
          name: e.node.name,
        })),
      );
      setPartnerOptions(options);
    }
  }, [partnerData]);

  if (loading || error || partnerOptions.length == 0) {
    return <Box />;
  }

  return (
    <Field
      required
      className={className ? className : classes.root}
      component={FormInput}
      inputComponent={FormInputSelect}
      options={partnerOptions}
      labelText={t('partner.picker')}
      placeholder={t('partner.picker')}
      name="partner"
      onChange={(value) => changePartnerValue(value)}
    />
  );
};

export default PartnerPicker;
