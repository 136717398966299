import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ImpactDirectionLegend: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box border="1px solid #E0E0E0" borderRadius="8px" m={2}>
      <Grid item xs={12} bgcolor={'tableRow.main'}>
        <Typography variant="h6" textAlign={'center'}>
          {t('projects.categoryInsights.impactDirectionLegendTitle')}
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid
          item
          container
          xs={12}
          sm={4}
          direction="column"
          alignItems="center"
        >
          <Typography variant="body1">
            {t('projects.categoryInsights.impactDirectionLegendPositive')}
          </Typography>
          <AddIcon style={{ color: 'green' }} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={4}
          direction={'column'}
          alignItems="center"
        >
          <Typography variant="body1">
            {t('projects.categoryInsights.impactDirectionLegendNeutral')}
          </Typography>
          <DragHandleIcon style={{ color: 'black' }} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={4}
          direction={'column'}
          alignItems="center"
        >
          <Typography variant="body1">
            {t('projects.categoryInsights.impactDirectionLegendNegative')}
          </Typography>
          <RemoveIcon style={{ color: 'red' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImpactDirectionLegend;
