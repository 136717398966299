import * as React from 'react';

import { useQuery } from '@apollo/client';
import NoveltyIntensityPqByReportId, {
  ReportNoveltyIntensityPqResponse,
} from '@graphql/queries/ReportNoveltyIntensityPqQuery';
import { Grid, Typography } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { ProductVersion } from 'components/Report/ProductVersion';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { useTranslation } from 'react-i18next';

import IntensityNoveltyScoreChart from './IntensityNoveltyScoreChart';
import IntensityPqChart from './IntensityPqChart';
import NoveltyScorePqChart from './NoveltyScorePqChart';
import { mapDataToNivoScatterData } from './utils';

export interface ProductLabelData {
  productId: number;
  productVersion: ProductVersion;
  name: string;
  version: string;
  idx: number;
  label: string;
  productLabelColor: string;
}
interface Props {
  reportId: string;
  projectId: number;
}

const NoveltyIntensityPqChartGroup: React.FC<Props> = (props) => {
  const { reportId, projectId } = props;

  const { t } = useTranslation();

  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const {
    loading,
    error,
    data: noveltyIntensityPqData,
  } = useQuery<ReportNoveltyIntensityPqResponse>(NoveltyIntensityPqByReportId, {
    variables: {
      reportID: reportId,
    },
  });

  if (loading || error) {
    return <LoadingScreen />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          {t('reports.noveltyIntensityPqChart.title')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <IntensityPqChart
          chartData={mapDataToNivoScatterData(
            noveltyIntensityPqData,
            'intesityPq',
            productNames,
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <NoveltyScorePqChart
          chartData={mapDataToNivoScatterData(
            noveltyIntensityPqData,
            'noveltyPq',
            productNames,
          )}
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <IntensityNoveltyScoreChart
          chartData={mapDataToNivoScatterData(
            noveltyIntensityPqData,
            'noveltyIntensity',
            productNames,
          )}
        />
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};

export default NoveltyIntensityPqChartGroup;
