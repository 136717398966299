import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ResponsivePie } from '@nivo/pie';
import { lineBreak } from 'components/ReactDataTable/reactDataTableUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  size: number;
  productName: string;
  flavorData: {
    id: string;
    value: number;
  }[];
}

const ProductFlavorTooltip: React.FC<Props> = (props) => {
  const { size, productName, flavorData } = props;

  const { t } = useTranslation();

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f5E5',
      border: '2px solid black',
      maxWidth: size,
    },
  });

  return (
    <CustomWidthTooltip
      placement="right"
      title={
        <Box height={(5 / 7) * size} width={size}>
          <Typography variant="h3" color="black" align="center">
            {t('dataQuality.tooltip.reportedFlavors')}
          </Typography>
          <ResponsivePie
            data={flavorData}
            colors={{ scheme: 'nivo' }}
            margin={{ top: 75, right: 100, bottom: 75, left: 100 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
          />
        </Box>
      }
    >
      {lineBreak(<div>{productName}</div>)}
    </CustomWidthTooltip>
  );
};

export default ProductFlavorTooltip;
