import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import flexContainer from '../../styles/flexContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      ...flexContainer(theme),
      alignItems: 'flex-start',
      backgroundColor: '#fafafa',
      borderBottom: '1px solid #e4ede5',
      color: '#9e9e9e',
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: '8px',
    },
  }),
);

export default useStyles;
