import * as React from 'react';

import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button';

import useStyles from './useStyles';

interface Props extends ButtonProps {
  children: string | any;
  soft?: boolean;
  teal?: boolean;
  onClick?: MouseEvent | any;
}

const MaterialButton: React.FunctionComponent<Props> = ({
  children,
  variant,
  color,
  onClick,
  disabled,
  soft,
  teal,
  ...rest
}) => {
  const classes = useStyles(useTheme());

  return (
    <Button
      classes={{
        root: soft
          ? teal
            ? classes.softTeal
            : classes.softRoot
          : classes.root,
        sizeSmall: classes.small,
        outlinedSecondary: classes.outlinedSecondary,
      }}
      color={color}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default MaterialButton;
