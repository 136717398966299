import { gql } from '@apollo/client';

export default gql`
  query FpDecompByReportIdAndProductIDQuery($reportID: UUID!, $productID: Int, $version: String) {
    allRpFlavorDecomps(
      condition: { reportId: $reportID, productId: $productID, version: $version }
    ) {
      nodes {
        id
        reportId
        productByProductId {
          id
          name
        },
        ggVars
        referenceFlavor
        decomp
        absDecomp
        version
      }
    }
  }
`;
