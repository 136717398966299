import { INTERFACE_LANGUAGES } from 'constants/interfaceLanguages';

import * as React from 'react';

import { LinearProgress, Tooltip, useTheme } from '@mui/material';
import dataTableCsv from 'components/ReactDataTable/ReactDataTableCsv';
import formatDate from 'date-fns/format';
import { enUS } from 'date-fns/locale';
import useWindowSize from 'hooks/useWindowSize';
import { round } from 'lodash';
import DataTable, {
  ConditionalStyles,
  TableColumn,
  TableStyles,
} from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';

interface TableRow extends dataquality.DataQualityDetail {
  rowIndex: number;
  rowId: number;
  userId: string;
  userRef: string;
}

const customStyles: TableStyles = {
  responsiveWrapper: {
    style: {
      maxHeight: 'none',
    },
  },
  table: {
    style: {
      maxHeight: 'none',
    },
  },
  headRow: {
    style: {
      alignItems: 'end',
    },
  },
  headCells: {
    style: {
      fontSize: 12,
      fontFamily: 'AlphaHeadlinePro-Bold, sans-serif',
      color: 'var(--marine)',
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  cells: {
    style: {
      fontSize: 11,
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
};

interface Props {
  loading: boolean;
  panelData: dataquality.DataQualityDetail[];
}

const PanelReviewsTable: React.FC<Props> = (props) => {
  const { loading, panelData } = props;

  const { width } = useWindowSize();
  const { t, i18n } = useTranslation();
  const styles = useStyles(useTheme());

  const allPanelists = new Set();

  const flagConditionalCellStyle = (
    selector: (row: TableRow) => number,
  ): ConditionalStyles<TableRow>[] => [
    {
      when: (row) => row.rowIndex == allPanelists.size - 1,
      style: (row) => ({
        backgroundColor: selector(row) > 0 ? 'var(--coral-pink)' : '',
        color: selector(row) > 0 ? 'white' : '',
        borderBottom: '2px solid',
        verticalAlign: 'middle',
        borderBottomColor: 'black',
        fontSize: 11,
      }),
    },
    {
      when: (row) => row.rowIndex != allPanelists.size - 1,
      style: (row) => ({
        backgroundColor: selector(row) > 0 ? 'var(--coral-pink)' : '',
        color: selector(row) > 0 ? 'white' : '',
        verticalAlign: 'middle',
        borderLeftColor: 'black',
        fontSize: 11,
      }),
    },
  ];

  const getLocale = (currentLanguage: string): Locale => {
    if (INTERFACE_LANGUAGES.find((il) => il.unicode === currentLanguage)) {
      return INTERFACE_LANGUAGES.find((il) => il.unicode === currentLanguage)
        .locale;
    } else {
      return enUS;
    }
  };

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'rowId',
      selector: (row: TableRow) => row.rowId,
      name: '',
      sortable: true,
      center: true,
      omit: true,
    },
    {
      id: 'rowIndex',
      selector: (row: TableRow) => row.rowIndex,
      name: '',
      sortable: true,
      center: true,
      omit: true,
    },
    {
      id: 'userId',
      selector: (row: TableRow) => row.userId,
      name: 'User ID',
      sortable: true,
      center: true,
      omit: true,
    },
    {
      id: 'userRef',
      selector: (row: TableRow) => row.userRef,
      name: 'User Email',
      sortable: true,
      center: true,
      omit: true,
    },
    {
      id: 'runningPanelistCount',
      selector: (row: TableRow) => row.runningPanelistCount,
      name: '',
      sortable: true,
      center: true,
      omit: true,
    },
    {
      id: 'runningPanelReviewCount',
      selector: (row: TableRow) => row.runningPanelReviewCount,
      name: '',
      sortable: true,
      center: true,
      omit: true,
    },
    {
      id: 'userIdentification',
      selector: (row: TableRow) => row.userIdentification,
      name: <div>User ID</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'totalReviewCount',
      selector: (row: TableRow) => row.totalReviewCount,
      name: <div>Total # of Reviews</div>,
      sortable: true,
      center: true,
      omit: width < 1350,
      width: '80px',
    },
    {
      id: 'panelReviewCount',
      selector: (row: TableRow) => row.panelReviewCount,
      name: <div># of Reviews this Panel</div>,
      sortable: true,
      center: true,
      width: '80px',
    },
    {
      id: 'mostRecentSubmission',
      selector: (row: TableRow) => row.mostRecentSubmission.getTime(),
      format: (row: TableRow) =>
        formatDate(row.mostRecentSubmission, 'yyyy-MM-dd', {
          locale: getLocale(i18n.language),
        }),
      name: <div>Most Recent</div>,
      sortable: true,
      center: true,
      omit: width < 1250,
      width: '90px',
    },
    {
      id: 'interfaceLanguage',
      selector: (row: TableRow) => row.interfaceLanguage,
      name: <div>Current Interface Language</div>,
      sortable: true,
      center: true,
      omit: true,
    },
    {
      id: 'averageTimeTakenSecs',
      selector: (row: TableRow) => row.averageTimeTakenSecs,
      name: <div>Mean Time of Review (s)</div>,
      sortable: true,
      center: true,
      omit: width < 1250,
      width: '80px',
    },
    {
      id: 'averageReferenceFlavor',
      selector: (row: TableRow) => row.averageReferenceFlavor,
      format: (row: TableRow) => round(row.averageReferenceFlavor, 1),
      name: <div>Avg # Ref. Flav / Review</div>,
      sortable: true,
      center: true,
      omit: width < 1250,
      style: {
        borderRight: '2px solid',
        borderRightColor: 'black',
      },
      width: '80px',
    },
    {
      id: 'allGgVar',
      selector: (row: TableRow) => row.allGgVar,
      name: (
        <Tooltip title={t('dataQuality.tooltip.allGgVar')}>
          <div className={styles.verticalHeader}>All 24 GGVar Marked</div>
        </Tooltip>
      ),
      sortable: true,
      center: true,
      // sortFunction: sortFloat,
      omit: width < 1250,
      width: '56px',
      conditionalCellStyles: flagConditionalCellStyle((row) => row.allGgVar),
    },
    {
      id: 'allMarksAtFive',
      selector: (row: TableRow) => row.allMarksAtFive,
      name: (
        <Tooltip title={t('dataQuality.tooltip.allMarksAtFive')}>
          <div className={styles.verticalHeader}>Marked GGVars All 5</div>
        </Tooltip>
      ),
      sortable: true,
      center: true,
      omit: width < 1000,
      width: '56px',
      conditionalCellStyles: flagConditionalCellStyle(
        (row: TableRow) => row.allMarksAtFive,
      ),
    },
    {
      id: 'noReferenceFlavor',
      selector: (row: TableRow) => row.noReferenceFlavor,
      name: (
        <Tooltip title={t('dataQuality.tooltip.noReferenceFlavor')}>
          <div className={styles.verticalHeader}>
            No Reference Flavors Marked
          </div>
        </Tooltip>
      ),
      sortable: true,
      center: true,
      omit: width < 1000,
      width: '56px',
      conditionalCellStyles: flagConditionalCellStyle(
        (row) => row.noReferenceFlavor,
      ),
    },
    {
      id: 'insufficientGgVar',
      selector: (row: TableRow) => row.insufficientGgVar,
      name: (
        <Tooltip title={t('dataQuality.tooltip.insufficientGgVar')}>
          <div className={styles.verticalHeader}>{'< 4 GGVar Marked'}</div>
        </Tooltip>
      ),
      sortable: true,
      center: true,
      omit: width < 1000,
      width: '56px',
      conditionalCellStyles: flagConditionalCellStyle(
        (row) => row.insufficientGgVar,
      ),
    },
    {
      id: 'excessiveReferenceFlavor',
      selector: (row: TableRow) => row.excessiveReferenceFlavor,
      name: (
        <Tooltip title={t('dataQuality.tooltip.excessiveReferenceFlavor')}>
          <div className={styles.verticalHeader}>
            {'> 25 Reference Flavors Marked'}
          </div>
        </Tooltip>
      ),
      sortable: true,
      center: true,
      omit: width < 1000,
      width: '56px',
      conditionalCellStyles: flagConditionalCellStyle(
        (row) => row.excessiveReferenceFlavor,
      ),
    },
    {
      id: 'excessiveGGVar',
      selector: (row: TableRow) => row.excessiveGGVar,
      name: (
        <Tooltip title={t('dataQuality.tooltip.excessiveGGVar')}>
          <div className={styles.verticalHeader}>{'> 15 GGVars marked'}</div>
        </Tooltip>
      ),
      sortable: true,
      center: true,
      omit: width < 1000,
      width: '56px',
      conditionalCellStyles: flagConditionalCellStyle(
        (row) => row.excessiveGGVar,
      ),
    },
    {
      id: 'excessiveTextures',
      selector: (row: TableRow) => row.excessiveTextures,
      name: (
        <Tooltip title={t('dataQuality.tooltip.excessiveTextures')}>
          <div className={styles.verticalHeader}>{'> 15 Textures'}</div>
        </Tooltip>
      ),
      sortable: true,
      center: true,
      omit: width < 1000,
      width: '56px',
      conditionalCellStyles: flagConditionalCellStyle(
        (row) => row.excessiveTextures,
      ),
    },
    {
      id: 'zeroMouthfeelIntensity',
      selector: (row: TableRow) => row.zeroMouthfeelIntensity,
      name: (
        <Tooltip title={t('dataQuality.tooltip.zeroMouthfeelInt')}>
          <div className={styles.verticalHeader}>{'0 Mouthfeel'}</div>
        </Tooltip>
      ),
      sortable: true,
      center: true,
      omit: width < 1000,
      width: '56px',
      conditionalCellStyles: flagConditionalCellStyle(
        (row) => row.zeroMouthfeelIntensity,
      ),
    },
    {
      id: 'tooMany7s',
      selector: (row: TableRow) => row.tooMany7s,
      name: (
        <Tooltip title={t('dataQuality.tooltip.tooMany7s')}>
          <div className={styles.verticalHeader}>{'Too Many 7s'}</div>
        </Tooltip>
      ),
      sortable: true,
      center: true,
      omit: width < 1000,
      width: '56px',
      conditionalCellStyles: flagConditionalCellStyle((row) => row.tooMany7s),
    },
    {
      id: 'age',
      selector: (row: TableRow) => row.age,
      name: <div>{'Age'}</div>,
      sortable: true,
      center: true,
      omit: width < 1350,
      width: '56px',
      style: {
        borderLeft: '2px solid',
        borderLeftColor: 'black',
      },
    },
    {
      id: 'gender',
      selector: (row: TableRow) => row.gender,
      name: <div>{'Gender'}</div>,
      sortable: true,
      center: true,
      omit: width < 1350,
      width: '75px',
    },
    {
      id: 'userCategory',
      selector: (row: TableRow) => row.userCategory,
      name: <div>{'User Category'}</div>,
      sortable: true,
      center: true,
      omit: width < 1600,
      width: '56px',
    },
    {
      id: 'allFlagCount',
      selector: (row: TableRow) => row.allFlagCount,
      name: <div>{'Flag Total'}</div>,
      sortable: true,
      center: true,
      omit: width < 1350,
      width: '60px',
      style: {
        fontWeight: 'bold',
      },
    },
  ];

  return (
    <DataTable
      dense
      customStyles={customStyles}
      columns={columns}
      data={panelData}
      progressPending={loading}
      progressComponent={<LinearProgress color="secondary" />}
      fixedHeader
      striped
      defaultSortFieldId={'mostRecentSubmission'}
      defaultSortAsc={false}
      actions={dataTableCsv(
        'Data_Quality_Dashboard_Review_List.csv',
        columns,
        panelData,
      )}
    />
  );
};

export default PanelReviewsTable;
