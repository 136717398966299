import { gql } from '@apollo/client';

export default gql`
  query GetProjectsForWorkspace($workspaceId: Int!) {
    projectsForWorkspace: allProjects(
      filter: { workspaceId: { equalTo: $workspaceId } }
    ) {
      nodes {
        id
        name
        workspaceId
        createdDate
        approved
        customPqProductNamesByProjectId {
          nodes {
            productId
            productName
            version
          }
        }
        projectReportsByProjectId {
          nodes {
            reportJobByReportId {
              reportId
              reportStatus
              startedAt
              projectName
              reportSummariesByReportId {
                nodes {
                  productByProductId {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
