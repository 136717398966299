import { getFormValues, startSubmit, stopSubmit, destroy } from 'redux-form';
import { put, select, take } from 'redux-saga/effects';

import appToastAdd from '../actions/appToastAdd';
import errorAction from '../actions/error';
import { FORM_SUBMIT } from '../actions/formSubmit';
import { CREATE_CATEGORY_FORM } from '../constants/formNames';
import graphqlClient from '../consumers/graphqlClient';
import CreateProductCategoryMutation from '../graphql/mutations/CreateProductCategory';

export default function* createCategoryFormSubmitSaga() {
  while (true) {
    yield take(
      ({ type, payload }) =>
        type === FORM_SUBMIT && payload === CREATE_CATEGORY_FORM,
    );

    yield put(startSubmit(CREATE_CATEGORY_FORM));

    const createCategoryFormValues = yield select(
      getFormValues(CREATE_CATEGORY_FORM),
    );

    const categoryToCreate = {
      name: createCategoryFormValues.name,
      producerId: 25,
    };

    try {
      const createQuery = yield graphqlClient.mutate({
        mutation: CreateProductCategoryMutation,
        variables: {
          productCategory: categoryToCreate,
        },
      });

      const { name } = createQuery.data.createProductCategory.productCategory;

      // If this point is reached, the form was submitted without error
      yield put(stopSubmit(CREATE_CATEGORY_FORM));

      yield put(
        appToastAdd({
          durationMilliseconds: 4000,
          message: `Category ${name} Created`,
          title: 'Category Creation Successful',
          toastKey: `toast_${Date.now()}`,
        }),
      );

      // Destroy the form so that it is re-rendered after the below route change
      yield put(destroy(CREATE_CATEGORY_FORM));
    } catch (error) {
      yield put(stopSubmit(CREATE_CATEGORY_FORM, error));
      if (error.message.includes('unique_category_producer')) {
        yield put(
          errorAction({
            error,
            title: 'Failed to Create Category',
            description: `Category already exists`,
          }),
        );
      } else {
        yield put(
          errorAction({
            error,
            title: 'Failed to Create Category',
            description: error.message,
          }),
        );
      }
    }
  }
}
