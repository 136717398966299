import { COUNTRIES } from 'constants/country';

import { useState, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';

export interface RegionListOptions {
  label: string;
  value: string;
  slug: string;
  country: string;
}

interface RegionRecord {
  name: string;
  slug: string;
  country: string;
}

interface RegionResults {
  allProducers: {
    nodes: Array<RegionRecord>;
  };
}

const query = gql`
  query AllCountriesAndRegions {
    allProducers(filter: { country: { notEqualTo: "null" } }) {
      nodes {
        name
        slug
        country
      }
    }
  }
`;

const useRegionsList = (): RegionListOptions[] => {
  const [regions, setRegions] = useState<RegionListOptions[]>([]);
  const { data, loading, error } = useQuery<RegionResults>(query);

  useEffect(() => {
    if (!loading && !error && data) {
      const regions: RegionListOptions[] = data.allProducers.nodes.map(
        (d: RegionRecord) => ({
          label: `${COUNTRIES.find((c) => c.dbname === d.country)?.emoji} ${
            d.name
          }`,
          value: d.name,
          slug: d.slug,
          country: d.country,
        }),
      );
      setRegions(regions);
    }
  }, [data, loading, error]);

  return regions;
};

export default useRegionsList;
