import { Point } from '@nivo/line';
import Loess from 'loess';

export interface SmoothedRow {
  ggvar: constants.GGVar;
  productId: number;
  version: string;
  reportId: string;
  currentIntensity: number;
  data: reports.NivoData[];
}

export interface ExtremaRow extends SmoothedRow {
  minExtrema: number;
  maxExtrema: number;
}

/**
 * Smooth out the original data according to the LOESS algo
 * 
 * @param data original series of data to be smoothed
 * @param smoothingSample number of samples to use for smoothing with loess

 * @returns smoothed series of data
 */
export function loessSmooth(
  data: reports.NivoData[],
  smoothingSample: number,
  pqWeight: number,
  currentValue?: reports.NivoData,
): reports.NivoData[] {
  const xMin = data.map((p) => p.x).reduce((a, b) => Math.min(a, b), null);
  const xMax = data.map((p) => p.x).reduce((a, b) => Math.max(a, b), null);

  const model = new Loess(weightData(data, currentValue, pqWeight));
  const newData = model.grid([smoothingSample + 1]);
  const fit = model.predict(newData);
  const fitted: [number] = fit.fitted;

  const smoothData = fitted.map((fy, idx) => ({
    x: xMin + idx / (smoothingSample / xMax),
    y: fy,
  }));

  return smoothData;
}

/**
 * Weight the data with the current value (intensity/pq data point) if present
 *
 * @param data tuning plot data
 * @param currentValue current value of the intesity/pq for the series
 * @returns weighted arrays for Loess
 */
export function weightData(
  data: reports.NivoData[],
  currentValue: reports.NivoData,
  pqWeight: number,
) {
  // Add the weighted value for the current intensity PQ if present to drag the chart to match
  if (currentValue) {
    return {
      x: data.map((p) => p.x).concat(currentValue.x),
      y: data.map((p) => p.y).concat(currentValue.y),
      w: data.map((_p) => 1).concat(pqWeight),
    };
  } else {
    return {
      x: data.map((p) => p.x),
      y: data.map((p) => p.y),
      w: data.map((_p) => 1),
    };
  }
}

/**
 * Map a jsonB array data into NivoData array
 *
 * @param data from database jsonB data blob
 * @returns NivoData array
 */
export function mapJsonB(
  data: [[x: number], [y: number]][],
): reports.NivoData[] {
  return data.map((v) => ({
    x: v[0][0],
    y: v[1][0],
  }));
}

/**
 * Map the graphql row to a more standard NivoDataSeries object
 *
 * @param row Map a graphql response tuning plot row to a standard NivoDataSeries row
 * @returns standardized NivoDataSeries
 */
export function mapRowToChartData(
  row: reports.GgVarTuningPlotRow,
): reports.NivoDataSeries {
  return {
    id: row.ggvar,
    data: row.dataJsonb.map((v) => ({
      x: v[0][0],
      y: v[1][0],
    })),
  };
}

/**
 * Smooth out a NivoDataSeries object with the LOESS algo
 *
 * @param series unsmooth data
 * @returns smoothed data
 */
export function smoothNivoSeries(
  series: reports.NivoDataSeries,
): reports.NivoDataSeries {
  return {
    id: series.id,
    color: series.color,
    data: loessSmooth(series.data, 23, 10),
  };
}

/**
 * Compute the supremium (greatest less than) data point based on an arbitrary X value
 *
 * @param data input data
 * @param midX arbitrary X value
 * @returns supremium NivoData point
 */
function supNivoData(data: reports.NivoData[], midX: number): reports.NivoData {
  return [...data]
    .filter((p) => p.x <= midX)
    .sort((n1, n2) => n2.x - n1.x)
    .find(Boolean);
}

/**
 * Compute the infimum (least greater than) data point based on an arbitrary X value
 * @param data input data
 * @param midX arbitrary X value
 * @returns infimum NivoData point
 */
function infNivoData(data: reports.NivoData[], midX: number): reports.NivoData {
  return [...data]
    .filter((p) => p.x >= midX)
    .sort((n1, n2) => n1.x - n2.x)
    .find(Boolean);
}

/**
 * Compute the interpolated NivoData point based on an arbitrary X value
 *
 * @param series input NivoDataSeries
 * @param midX arbitrary X value
 * @returns interpolated NivoData point
 */
function interpolateNivoSeries(
  series: reports.NivoDataSeries,
  midX: number,
): reports.NivoData {
  const p1 = supNivoData(series.data, midX);
  const p2 = infNivoData(series.data, midX);

  const segX = p2.x - p1.x;
  const distX = midX - p1.x;
  const slope = (p2.y - p1.y) / segX;

  return {
    x: segX === 0 ? p1.x : p1.x + (p2.x - p1.x) * (distX / segX),
    y: segX === 0 ? p1.y : p1.y + slope * distX,
  };
}

/**
 * Compute the interpolated NivoData point array on an arbitrary X value
 *
 * @param data input NivoDataSeries array
 * @param midX artbitrary X value
 * @returns interpolated NivoData array
 */
export function interpolateNivoSerieses(
  data: reports.NivoDataSeries[],
  midX: number,
): reports.NivoData[] {
  return data.map((s) => interpolateNivoSeries(s, midX));
}

/**
 * Compute the supremium (greatest less than) data point based on an arbitrary X value
 *
 * @param points input data
 * @param midX arbitrary X value
 * @returns supremium Point
 */
function supPoint(points: Point[], midX: number): Point {
  const sup = [...points]
    .filter((p) => (p.data.x as number) <= midX)
    .sort((n1, n2) => n2.index - n1.index)
    .find(Boolean);
  if (sup) {
    return sup;
  } else {
    return [...points].reduce((a, b) =>
      a ? (a.data.x < b.data.x ? a : b) : b,
    );
  }
}

/**
 * Compute the infimum (least greater than) data point based on an arbitrary X value
 * @param points input data
 * @param midX arbitrary X value
 * @returns infimum Point
 */
function infPoint(points: Point[], midX: number): Point {
  const inf = [...points]
    .filter((p) => (p.data.x as number) >= midX)
    .sort((n1, n2) => n1.index - n2.index)
    .find(Boolean);
  if (inf) {
    return inf;
  } else {
    return [...points].reduce((a, b) =>
      a ? (a.data.x > b.data.x ? a : b) : b,
    );
  }
}

/**
 * Compute the interpolated Point based on an arbitrary X value
 *
 * @param points input Point array
 * @param midX arbitrary X value
 * @returns interpolated NivoData point
 */
export function interpPoint(points: Point[], midX: number): reports.NivoData {
  if (points.length === 0) return null;

  const p1 = supPoint(points, midX);
  const p2 = infPoint(points, midX);

  const segX = (p2.data.x as number) - (p1.data.x as number);
  const distX = midX - (p1.data.x as number);
  const slope = ((p2.data.y as number) - (p1.data.y as number)) / segX;

  return {
    x: segX === 0 ? p1.x : p1.x + (p2.x - p1.x) * (distX / segX),
    y: segX === 0 ? p1.y : p1.y + slope * distX,
  };
}

/**
 * Compute the minimum Y value from an array of NivoDataSeries
 *
 * @param series input NivoDataSeries array
 * @param adj adjustment value
 * @returns minimum Y adjusted lower by adj
 */
export function minY(series: reports.NivoDataSeries[], adj: number): number {
  return (
    series
      .map((s) => s.data)
      .reduce((a, b) => a.concat(b), [])
      .map((p) => p.y)
      .reduce((a, b) => (a ? (a < b ? a : b) : b), null) - adj
  );
}

/**
 * Compute the maximum Y value from an array of NivoDataSeries
 *
 * @param series  input NivoDataSeries array
 * @param adj adjustment value
 * @returns maximum Y adjusted higher by adj
 */
export function maxY(series: reports.NivoDataSeries[], adj: number): number {
  return (
    series
      .map((s) => s.data)
      .reduce((a, b) => a.concat(b), [])
      .map((p) => p.y)
      .reduce((a, b) => (a ? (a > b ? a : b) : b), null) + adj
  );
}

/**
 * Compute the minimum X value from an array of NivoDataSeries
 *
 * @param series  input NivoDataSeries array
 * @returns minimum X value
 */
export function minX(series: reports.NivoDataSeries[]): number | 'auto' {
  return series
    .map((s) => s.data)
    .reduce((a, b) => a.concat(b), [])
    .map((p) => p.x)
    .reduce((a, b) => (a ? (a < b ? a : b) : b), null);
}

/**
 * Compute the maximum X value from an array of NivoDataSeries
 *
 * @param series  input NivoDataSeries array
 * @returns maximum X value
 */
export function maxX(series: reports.NivoDataSeries[]): number | 'auto' {
  return series
    .map((s) => s.data)
    .reduce((a, b) => a.concat(b), [])
    .map((p) => p.x)
    .reduce((a, b) => (a ? (a > b ? a : b) : b), null);
}

/**
 * Return the smallest value of X from an array of NivoData
 * @param input the array of interest
 * @returns single NivoData point with smallest X
 */
export function minData(input: reports.NivoData[]): reports.NivoData {
  return input.reduce((a, b) => (a ? (a.x < b.x ? a : b) : b), null);
}

/**
 * Return the largest value of X from an array of NivoData
 * @param input the array of interest
 * @returns single NivoData point with largest X
 */
export function maxData(input: reports.NivoData[]): reports.NivoData {
  return input.reduce((a, b) => (a ? (a.x > b.x ? a : b) : b), null);
}

/**
 * Return the nearest value of X from an array of NivoData
 * @param input the array of interest
 * @returns single NivoData point with nearest X
 */
export function nearData(
  input: reports.NivoData[],
  targetX: number,
): reports.NivoData {
  return input.reduce(
    (a, b) =>
      a ? (Math.abs(a.x - targetX) < Math.abs(b.x - targetX) ? a : b) : b,
    null,
  );
}

/**
 * Compute the relevant extrema delta value for min depending on extrema/local selector
 * @param row
 * @param isLocal
 * @returns
 */
export function computeMinExtrema(row: SmoothedRow, isLocal: boolean): number {
  if (isLocal) {
    return (
      minData(
        row.data.filter(
          (d) =>
            Math.abs(nearData(row.data, row.currentIntensity)?.x - d.x) < 1.5,
        ),
      )?.y - nearData(row.data, row.currentIntensity)?.y
    );
  } else {
    return minData(row.data)?.y - nearData(row.data, row.currentIntensity)?.y;
  }
}

/**
 * Compute the relevant extrema delta value for max depending on extrema/local selector
 * @param row
 * @param isLocal
 * @returns
 */
export function computeMaxExtrema(row: SmoothedRow, isLocal: boolean): number {
  if (isLocal) {
    return (
      maxData(
        row.data.filter(
          (d) =>
            Math.abs(nearData(row.data, row.currentIntensity)?.x - d.x) < 1.5,
        ),
      )?.y - nearData(row.data, row.currentIntensity)?.y
    );
  } else {
    return maxData(row.data)?.y - nearData(row.data, row.currentIntensity)?.y;
  }
}
