import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportJobQuery from '@graphql/queries/ReportJobQuery';
import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { TextField } from '@mui/material';
import { Pagination } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import * as _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import sessionTokenRoles from 'selectors/sessionTokenRoles';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import { constructArtifactPath } from '../../../utils/conchUtils';
import { hasSuperAdminRole } from '../../../utils/roleUtils';

export const generateArtifactPath = (
  record: ReportJobData,
  efsProjectName: string,
) => {
  return constructArtifactPath(
    record.startedAt,
    record.clientName,
    efsProjectName ?? record.projectName,
    record.targetGroupName,
  );
};

export interface ReportJobData {
  jobId: string;
  startedAt: string;
  reportType: string;
  reportId: string;
  targetGroupName: string;
  clientName: string;
  projectName: string;
  workspaceId: string;
  artifact_path: string;
}

interface ReportJobsResponse {
  allReportJobs: {
    nodes: Array<ReportJobData>;
  };
}

interface ReportJobsQueryVariables {
  workspaceID: string;
  notReportType: 'market_survey' | 'optimization';
  passedQA?: boolean;
  orderBy: string;
}

type AutocompleteOption = {
  id: number;
  name: string;
  value: number;
};

interface Props {
  selectReport: (report: ReportJobData) => void;
  resetFields: () => void;
}

const isAutoCompleteOption = (obj: any): obj is AutocompleteOption =>
  obj &&
  typeof obj.id === 'number' &&
  typeof obj.name === 'string' &&
  typeof obj.value === 'number';

const SelectReport: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const workspaceID = useSelector((state) => selectWorkspaceProducerId(state));

  const userRoles = useSelector((state) => sessionTokenRoles(state));
  const isSuperAdmin = hasSuperAdminRole(userRoles);

  const [passedQA, setPassedQA] = React.useState<boolean>(true);
  const [selectedReport, setSelectedReport] = React.useState<ReportJobData>();
  const [reportJobs, setReportJobs] = React.useState<Array<ReportJobData>>([]);
  const [filteredReportJobs, setFilteredReportJobs] = React.useState<
    Array<ReportJobData>
  >([]);
  const [page, setPage] = React.useState<number>(1);

  const { data: reportJobsData, loading } = useQuery<
    ReportJobsResponse,
    ReportJobsQueryVariables
  >(ReportJobQuery, {
    variables: {
      notReportType: 'optimization',
      passedQA: passedQA,
      orderBy: 'STARTED_AT_DESC',
      ...(!isSuperAdmin && { workspaceID: workspaceID }),
    },
  });

  React.useEffect(() => {
    // Only set report jobs and filtered report jobs when there isn't a selected report. Only possible for first load.
    if (!!reportJobsData && !selectedReport) {
      setReportJobs(reportJobsData.allReportJobs.nodes);
      setFilteredReportJobs(reportJobsData.allReportJobs.nodes);
    }
  }, [reportJobsData]);

  if (loading || reportJobs.length == 0) {
    return <div />;
  }

  return (
    <Container>
      <Grid
        key={selectedReport?.reportId}
        container
        spacing={3}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={11}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '2em',
            }}
          >
            <Autocomplete
              id="client-name-field-autocomplete"
              getOptionLabel={(option: {
                id: number;
                name: string;
                value: number;
              }) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={_.chain(reportJobs)
                .uniqBy('clientName')
                .map(
                  (job, index): AutocompleteOption => ({
                    id: index,
                    name: job.clientName,
                    value: index,
                  }),
                )
                .value()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('reports.request.clientName')}
                  variant="outlined"
                  style={{ minWidth: 250 }}
                />
              )}
              onChange={(_event, value) =>
                setFilteredReportJobs(
                  reportJobs.filter((job) =>
                    job.clientName
                      .toLowerCase()
                      .includes(
                        (isAutoCompleteOption(value)
                          ? value.name
                          : ''
                        ).toLowerCase(),
                      ),
                  ),
                )
              }
            />
            <Autocomplete
              id="project-name-field-autocomplete"
              getOptionLabel={(option: {
                id: number;
                name: string;
                value: number;
              }) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={_.chain(reportJobs)
                .uniqBy('projectName')
                .map(
                  (job, index): AutocompleteOption => ({
                    id: index,
                    name: job.projectName,
                    value: index,
                  }),
                )
                .value()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('reports.request.projectName')}
                  variant="outlined"
                  style={{ minWidth: 250 }}
                />
              )}
              onChange={(_event, value) =>
                setFilteredReportJobs(
                  reportJobs.filter((job) =>
                    job.projectName
                      .toLowerCase()
                      .includes(
                        (isAutoCompleteOption(value)
                          ? value.name
                          : ''
                        ).toLowerCase(),
                      ),
                  ),
                )
              }
            />
            <Autocomplete
              id="reportid-field-autocomplete"
              getOptionLabel={(option: {
                id: number;
                name: string;
                value: number;
              }) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={_.chain(reportJobs)
                .uniqBy('reportId')
                .map(
                  (job, index): AutocompleteOption => ({
                    id: index,
                    name: job.reportId,
                    value: index,
                  }),
                )
                .value()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('reports.request.reportId')}
                  variant="outlined"
                  style={{ minWidth: 250 }}
                />
              )}
              onChange={(_event, value) =>
                setFilteredReportJobs(
                  reportJobs.filter((job) =>
                    job.reportId
                      .toLowerCase()
                      .includes(
                        (isAutoCompleteOption(value)
                          ? value.name
                          : ''
                        ).toLowerCase(),
                      ),
                  ),
                )
              }
            />
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              padding: '2em',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  defaultChecked={passedQA}
                  value={passedQA}
                  onChange={(_event, value) => {
                    setSelectedReport(null);
                    setPassedQA(value ? true : null);
                    setPage(1);
                  }}
                />
              }
              label={t('reports.request.passedQa')}
            />
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('reports.request.selectReport')}</TableCell>
                  <TableCell>{t('reports.request.clientName')}</TableCell>
                  <TableCell>{t('reports.request.projectName')}</TableCell>
                  <TableCell>{t('reports.request.targetGroupName')}</TableCell>
                  <TableCell>{t('reports.request.startedAt')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredReportJobs
                  .slice((page - 1) * 5, page * 5)
                  .map((job) => (
                    <TableRow key={job.reportId}>
                      <TableCell>
                        <Tooltip title="Select report." arrow>
                          <Checkbox
                            color="secondary"
                            checked={job.reportId == selectedReport?.reportId}
                            onChange={(_event, _checked) => {
                              setSelectedReport(job);
                              props.selectReport(job);
                              props.resetFields();
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell>{job.clientName}</TableCell>
                      <TableCell>{job.projectName}</TableCell>
                      <TableCell>{job.targetGroupName}</TableCell>
                      <TableCell>
                        {moment(job.startedAt).format('LLL')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Pagination
              count={Math.ceil(filteredReportJobs.length / 5)}
              page={page}
              onChange={(_event, newPage) => setPage(newPage)}
              showFirstButton
              showLastButton
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SelectReport;
