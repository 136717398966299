import * as React from 'react';

import { Close } from '@mui/icons-material';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { getCustomClassDbProducts } from 'utils/reportUtils';

interface Props {
  show: boolean;
  close: () => void;
  report: reports.AllReportsQueryRow;
}

const CustomClassDbModal: React.FC<Props> = ({ show, close, report }) => {
  return (
    <Dialog open={show} onClose={close}>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{ position: 'absolute', top: 0, right: 0 }}
      >
        <Close />
      </IconButton>
      <Box sx={{ width: '90%', margin: 'auto' }}>
        <Typography variant="h4">{report.projectName}</Typography>
        {getCustomClassDbProducts(report).map((item: string, index: number) => (
          <p key={index}>{item}</p>
        ))}
      </Box>
    </Dialog>
  );
};

export default CustomClassDbModal;
