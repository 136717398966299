import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportMarketPreferencesQuery from '@graphql/queries/ReportMarketPreferencesQuery';
import ReportSummaryByReportIdAndProductIdQuery from '@graphql/queries/ReportSummaryByReportIdAndProductIdQuery';
import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import { BasicTooltip } from '@nivo/tooltip';

import { LINE_COLORS, pqMeanLayer } from '../productMarketPreferenceUtils';

interface Props {
  reportId: string;
  productId: number;
  productVersion?: string;
  title: string;
  titleCenter: boolean;
  calculatedMaxPercentPop?: number;
}

const ReportProductMarketPerference: React.FC<Props> = (props) => {
  const {
    title,
    titleCenter,
    reportId,
    productId,
    productVersion,
    calculatedMaxPercentPop,
  } = props;

  function getProductName(
    productData: reports.ReportMarketPreference[],
  ): string {
    return productData
      .map((rmp) => rmp.product.name)
      .reduce((a, b) => (a ? a : b), '');
  }

  const {
    loading: prefLoading,
    error: prefError,
    data: prefData,
  } = useQuery<reports.ReportMarketPreferencesResponse>(
    ReportMarketPreferencesQuery,
    {
      variables: {
        reportID: reportId,
        productID: productId,
      },
    },
  );

  const {
    loading: summaryLoading,
    error: summaryError,
    data: summaryData,
  } = useQuery<reports.ProductSummaryPQWinRate>(
    ReportSummaryByReportIdAndProductIdQuery,
    {
      variables: {
        reportId: reportId,
        productId: productId,
      },
    },
  );

  if (prefLoading || prefError || summaryLoading || summaryError) {
    return <div />;
  }

  const getData = (
    reportMarketPreferenceArray: reports.ReportMarketPreference[],
  ) => [
    {
      id: getProductName(reportMarketPreferenceArray),
      data: reportMarketPreferenceArray
        .filter(
          (p) => p.product.id === productId && p.version == productVersion,
        )
        .map((reportMarketPreference) => ({
          x: reportMarketPreference.pqRating,
          y: reportMarketPreference.percentPop,
        })),
    },
  ];

  const pqTooltip: React.FunctionComponent<PointTooltipProps> = (props) => {
    return (
      <BasicTooltip
        id={''}
        value={`PQ: ${props.point.data.x} - ${props.point.data.y}%`}
        color={LINE_COLORS[0]}
        enableChip
      />
    );
  };

  return (
    <div style={{ marginTop: '30px' }}>
      <h5 style={titleCenter ? { textAlign: 'center' } : {}}>{title}</h5>
      <div style={{ height: '500px' }}>
        <ResponsiveLine
          data={getData(prefData?.allRpMarketPreferences?.nodes)}
          margin={{ top: 20, right: 20, bottom: 70, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 0,
            max: calculatedMaxPercentPop || 'auto',
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'PQ Rating',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Percent of Population',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          enablePoints={false}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          colors={LINE_COLORS[0]}
          tooltip={pqTooltip}
          layers={[
            pqMeanLayer(summaryData, 0),
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            'lines',
            'slices',
            'points',
            'mesh',
            'legends',
          ]}
        />
      </div>
    </div>
  );
};

export default ReportProductMarketPerference;
