import { mapFromKey } from 'constants/ggVars';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportPositiveNegativeDriversQuery from '@graphql/queries/ReportPositiveNegativeDriversQuery';
import ReportStackRankComparisonQuery from '@graphql/queries/ReportStackRankComparisonQuery';
import { Box, useTheme } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import {
  HighlightableTableRow,
  hightlightedConditionalRowStyles,
} from 'components/ReactDataTable/highlightedRowStyles';
import { lineBreak } from 'components/ReactDataTable/reactDataTableUtils';
import { tableCustomStyles } from 'components/ReactDataTable/tableCustomStyles';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary, { ProductVersionPq } from 'hooks/useReportSummary';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import { ComponentTitle } from '../../../../styles/themeComponents';
import { ProductVersion } from '../../ProductVersion';
import { getProductName, isProductHighlighted } from '../../utils';
import { getImpactIcon, ImpactType } from '../utils';

interface ReportProps {
  projectId: number;
  reportId: string;
}

export interface TableRow extends HighlightableTableRow {
  productName: string;
  productId: number;
  version: string;
  wet: ImpactType;
  roasted: ImpactType;
  earthy: ImpactType;
  astringent: ImpactType;
  marine: ImpactType;
  mineral: ImpactType;
  retronasal: ImpactType;
  gamey: ImpactType;
  woody: ImpactType;
  bitter: ImpactType;
  meaty: ImpactType;
  smoked: ImpactType;
  dry: ImpactType;
  rich: ImpactType;
  dairy: ImpactType;
  mouthfeel: ImpactType;
  nutsAndSeeds: ImpactType;
  herbaceous: ImpactType;
  coldFinish: ImpactType;
  floral: ImpactType;
  spices: ImpactType;
  sourAndAcidity: ImpactType;
  fruits: ImpactType;
  sugar: ImpactType;
}

const getAILikingDirection = (
  productVersion: ProductVersion,
  reportFlavorStructureResponse: reports.ReportPositiveNegativeDriversResponse,
  key: string,
): ImpactType => {
  const node = (
    reportFlavorStructureResponse?.allRpFlavorStructures?.nodes ?? []
  ).filter((n) => n.product.id === productVersion.productId && n.label === key);

  const nodeColor = node.length > 0 ? node[0].nodeColor : null;
  switch (nodeColor) {
    case 'green':
      return ImpactType.POSITIVE;
    case 'red':
      return ImpactType.NEGATIVE;
    default:
      return ImpactType.NEUTRAL;
  }
};

export const mapToTableRow = (
  productNames,
  reportFlavorStructureResponse: reports.ReportPositiveNegativeDriversResponse,
  productVersionPqs: ProductVersionPq[],
): TableRow[] => {
  return productVersionPqs.map((pn, idx) => ({
    productName: getProductName({
      productNames,
      productId: pn.productVersion.productId,
      version: pn.productVersion.version,
    }),
    productId: pn.productVersion.productId,
    version: pn.productVersion.version,
    isHighlighted: isProductHighlighted({
      productNames,
      productId: pn.productVersion.productId,
      version: pn.productVersion.version,
    }),
    wet: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'wet',
    ),
    roasted: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'roasted',
    ),
    earthy: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'earthy',
    ),
    astringent: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'astringent',
    ),
    marine: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'marine',
    ),
    mineral: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'mineral',
    ),
    retronasal: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'retronasal',
    ),
    gamey: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'gamey',
    ),
    woody: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'woody',
    ),
    bitter: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'bitter',
    ),
    meaty: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'meaty',
    ),
    smoked: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'smoked',
    ),
    dry: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'dry',
    ),
    rich: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'rich',
    ),
    dairy: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'dairy',
    ),
    mouthfeel: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'mouthfeel',
    ),
    nutsAndSeeds: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'nutsAndSeeds',
    ),
    herbaceous: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'herbaceous',
    ),
    coldFinish: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'coldFinish',
    ),
    floral: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'floral',
    ),
    spices: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'spices',
    ),
    sourAndAcidity: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'sourAndAcidity',
    ),
    fruits: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'fruits',
    ),
    sugar: getAILikingDirection(
      pn.productVersion,
      reportFlavorStructureResponse,
      'sugar',
    ),
  }));
};

const CategoryInsightsTable: React.FC<ReportProps> = (props) => {
  const { reportId, projectId } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const columns = (data: TableRow[]): TableColumn<TableRow>[] => {
    const nonNeutralCounts = data.reduce(
      (a, b) => ({
        wet: a.wet + Math.abs(b.wet),
        roasted: a.roasted + Math.abs(b.roasted),
        earthy: a.earthy + Math.abs(b.earthy),
        astringent: a.astringent + Math.abs(b.astringent),
        marine: a.marine + Math.abs(b.marine),
        mineral: a.mineral + Math.abs(b.mineral),
        retronasal: a.retronasal + Math.abs(b.retronasal),
        gamey: a.gamey + Math.abs(b.gamey),
        woody: a.woody + Math.abs(b.woody),
        bitter: a.bitter + Math.abs(b.bitter),
        meaty: a.meaty + Math.abs(b.meaty),
        smoked: a.smoked + Math.abs(b.smoked),
        dry: a.dry + Math.abs(b.dry),
        rich: a.rich + Math.abs(b.rich),
        dairy: a.dairy + Math.abs(b.dairy),
        mouthfeel: a.mouthfeel + Math.abs(b.mouthfeel),
        nutsAndSeeds: a.nutsAndSeeds + Math.abs(b.nutsAndSeeds),
        herbaceous: a.herbaceous + Math.abs(b.herbaceous),
        coldFinish: a.coldFinish + Math.abs(b.coldFinish),
        floral: a.floral + Math.abs(b.floral),
        spices: a.spices + Math.abs(b.spices),
        sourAndAcidity: a.sourAndAcidity + Math.abs(b.sourAndAcidity),
        fruits: a.fruits + Math.abs(b.fruits),
        sugar: a.sugar + Math.abs(b.sugar),
      }),
      {
        wet: 0,
        roasted: 0,
        earthy: 0,
        astringent: 0,
        marine: 0,
        mineral: 0,
        retronasal: 0,
        gamey: 0,
        woody: 0,
        bitter: 0,
        meaty: 0,
        smoked: 0,
        dry: 0,
        rich: 0,
        dairy: 0,
        mouthfeel: 0,
        nutsAndSeeds: 0,
        herbaceous: 0,
        coldFinish: 0,
        floral: 0,
        spices: 0,
        sourAndAcidity: 0,
        fruits: 0,
        sugar: 0,
      },
    );

    const nonNeutralGgVars = Object.entries(nonNeutralCounts)
      .filter(([k, v]) => v !== 0)
      .map(([k, x]) => mapFromKey(k));
    const neutralGgVars = Object.entries(nonNeutralCounts)
      .filter(([k, v]) => v === 0)
      .map(([k, x]) => mapFromKey(k));

    return [
      {
        id: 'productName',
        name: lineBreak(t('reports.prodSummary.product')),
        cell: (row: TableRow) => (
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            {lineBreak(row.productName)}
          </div>
        ),
        selector: (row: TableRow) => row.productName,
        sortable: true,
      },
    ].concat(
      nonNeutralGgVars
        .concat(neutralGgVars)
        .slice(0, 8)
        .map((columnKey) => ({
          id: columnKey.key,
          width: '9%',
          name: lineBreak(columnKey.label),
          selector: (row: TableRow) => row[columnKey.key],
          cell: (row: TableRow) => getImpactIcon(row[columnKey.key]),
          center: true,
          compact: true,
          sortable: true,
        })),
    );
  };

  const {
    loading: reportFlavorStructureLoading,
    error: reportFlavorStructureError,
    data: reportFlavorStructureResponse,
  } = useQuery<reports.ReportPositiveNegativeDriversResponse>(
    ReportPositiveNegativeDriversQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    productVersionPqs,
  } = useReportSummary(reportId, {
    fetchPolicy: 'no-cache',
  });

  const {
    loading: comparisonLoading,
    error: comparisonError,
    data: comparisonData,
  } = useQuery<reports.ReportStackRankComparison>(
    ReportStackRankComparisonQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  if (
    reportFlavorStructureLoading ||
    reportFlavorStructureError ||
    reportSummaryLoading ||
    reportSummaryError ||
    comparisonLoading ||
    comparisonError
  ) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <ComponentTitle>{t('aiLiking.title')}</ComponentTitle>
      <DataTable
        columns={columns(
          mapToTableRow(
            productNames,
            reportFlavorStructureResponse,
            productVersionPqs,
          ),
        )}
        data={mapToTableRow(
          productNames,
          reportFlavorStructureResponse,
          productVersionPqs,
        )}
        defaultSortAsc={false}
        customStyles={tableCustomStyles(theme)}
        striped
        conditionalRowStyles={hightlightedConditionalRowStyles}
      />
    </Box>
  );
};

export default CategoryInsightsTable;
