import { gql } from '@apollo/client';

export default gql`
  query ReportTexturePreferenceByReportId($reportID: UUID!) {
    allRpTexturePreferences(condition: { reportId: $reportID }) {
      nodes {
        productByProductId {
          id
          name
        }
        version
        clusterIdx
        pqImpact
      }
    }
  }
`;
