import { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { keyBy, merge, mergeWith, values } from 'lodash';

import { NULL_REPORT_ID } from '../constants/report';
import { nvlParseInt } from '../utils/afsUtils';

const query = gql`
  query CustomProjectProducts(
    $projectId: Int!
    $reportId: UUID!
    $productId: Int!
  ) {
    allProjects(condition: { id: $projectId }) {
      nodes {
        id
        name
        customPqProductNamesByProjectId {
          edges {
            node {
              id
              productId
              productName
              marketProductLabel
              version
              productLabelColor
              isHighlighted
            }
          }
        }
        projectReportsByProjectId {
          edges {
            node {
              reportJobByReportId {
                reportSummariesByReportId {
                  edges {
                    node {
                      productByProductId {
                        id
                        name
                      }
                      version
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allReportJobs(condition: { reportId: $reportId }) {
      nodes {
        reportSummariesByReportId {
          edges {
            node {
              productByProductId {
                id
                name
              }
              version
            }
          }
        }
      }
    }
    allProducts(condition: { id: $productId }) {
      nodes {
        id
        name
      }
    }
  }
`;

interface CustomProjectProductsResponse {
  allProjects: {
    nodes: {
      id: number;
      name: string;
      customPqProductNamesByProjectId: {
        edges: CustomPqProductNamesByProjectIdRow[];
      };
      projectReportsByProjectId: {
        edges: ReportJobByReportIdRow[];
      };
    }[];
  };
  allReportJobs: {
    nodes: {
      reportSummariesByReportId: {
        edges: ReportSummariesByReportIdRow[];
      };
    }[];
  };
  allProducts: {
    nodes: reports.ProductInfo[];
  };
}

interface CustomPqProductNamesByProjectIdRow {
  node: {
    id: number;
    productId: number;
    productName: string;
    marketProductLabel: string;
    version: string;
    productLabelColor: string;
    isHighlighted: boolean;
  };
}

interface ReportJobByReportIdRow {
  node: {
    reportJobByReportId: {
      reportSummariesByReportId: {
        edges: ReportSummariesByReportIdRow[];
      };
    };
  };
}

interface ReportSummariesByReportIdRow {
  node: {
    productByProductId: reports.ProductInfo;
    version: string;
  };
}

const getStandardProducts = (
  data: CustomProjectProductsResponse,
  version: string,
  reportId: string,
): reports.ColorVersionedProductInfo[] => {
  const byProductId: reports.VersionedProductInfo[] =
    data.allProducts.nodes.map((n) => ({
      id: n.id,
      name: n.name,
      version: version ?? null,
      canonicalName: n.name,
    }));

  const byProjectId: reports.ColorVersionedProductInfo[] =
    data.allProjects.nodes
      .flatMap((n) => n.projectReportsByProjectId.edges.map((e) => e.node))
      .flatMap((r) =>
        r.reportJobByReportId.reportSummariesByReportId.edges.map(
          (e) => e.node,
        ),
      )
      .map((n) => ({
        id: n.productByProductId.id,
        name: n.productByProductId.name,
        version: n.version,
        productLabelColor: null,
        canonicalName: n.productByProductId.name,
        isHighlighted: false,
      }));

  const byReportId: reports.ColorVersionedProductInfo[] =
    data.allReportJobs.nodes
      .flatMap((n) => n.reportSummariesByReportId.edges.map((e) => e.node))
      .map((n) => ({
        id: n.productByProductId.id,
        name: n.productByProductId.name,
        version: n.version,
        productLabelColor: null,
        canonicalName: n.productByProductId.name,
        isHighlighted: false,
      }));

  const standardNames: reports.ColorVersionedProductInfo[] = values(
    merge(
      keyBy(
        byProductId,
        (vpi: reports.ColorVersionedProductInfo) => vpi.id + '|' + vpi.version,
      ),
      keyBy(
        reportId === NULL_REPORT_ID ? byProjectId : byReportId,
        (vpi: reports.ColorVersionedProductInfo) => vpi.id + '|' + vpi.version,
      ),
    ),
  );

  return standardNames;
};

const getCustomProducts = (
  data: CustomProjectProductsResponse,
  _version: string,
): reports.ColorVersionedProductInfo[] => {
  const customNames: reports.ColorVersionedProductInfo[] =
    data.allProjects.nodes
      .flatMap((n) =>
        n.customPqProductNamesByProjectId.edges.map((e) => e.node),
      )
      .map((n) => ({
        id: n.productId,
        name: n.productName,
        version: n.version,
        marketProductLabel: n.marketProductLabel,
        productLabelColor: n.productLabelColor,
        customId: n.id,
        canonicalName: null,
        isHighlighted: n.isHighlighted,
      }));

  return customNames;
};

export const mergeAllProducts = (
  data: CustomProjectProductsResponse,
  version: string,
  reportId?: string,
): reports.ColorVersionedProductInfo[] => {
  const standardProducts = getStandardProducts(
    data,
    version,
    reportId ?? NULL_REPORT_ID,
  );
  const customProducts = getCustomProducts(data, version);
  return values(
    mergeWith(
      keyBy(
        standardProducts,
        (vpi: reports.ColorVersionedProductInfo) => vpi.id + '|' + vpi.version,
      ),
      keyBy(
        customProducts
          .filter((cp) => standardProducts.map((sp) => sp.id).includes(cp.id))
          .map((i) => ({
            ...i,
            isHighlighted: i.isHighlighted,
          })),
        (vpi: reports.ColorVersionedProductInfo) => vpi.id + '|' + vpi.version,
      ),
      (a, b) => (b === null ? a : undefined), // don't merge nulls over (particularly on canonical names)
    ),
  );
};

interface CustomNameParams {
  productId?: number;
  reportId?: string;
  projectId?: number;
  version?: string;
  refetchNames?: boolean;
}

// default product / project number is to goto an unmatching value if not provided
export default function useCustomProductNames({
  productId = -1,
  reportId = NULL_REPORT_ID,
  projectId = -1,
  version,
  refetchNames,
}: CustomNameParams): reports.ColorVersionedProductInfo[] {
  const [productNames, setProductNames] = useState<
    reports.ColorVersionedProductInfo[]
  >([]);

  const { data, loading, error, refetch } =
    useQuery<CustomProjectProductsResponse>(query, {
      variables: {
        projectId: nvlParseInt(projectId, -1),
        reportId,
        productId: nvlParseInt(productId, -1),
      },
    });

  useEffect(() => {
    refetch();
  }, [refetchNames]);

  useEffect(() => {
    if (!loading && !error) {
      setProductNames(mergeAllProducts(data, version, reportId));
    }
  }, [data]);

  return productNames;
}

export function multiProjectCustomNames(
  projectIds: number[],
): reports.ColorVersionedProductInfo[] {
  return [
    ...projectIds,
    ...Array.from({ length: 11 - projectIds.length }, () => -1),
  ]
    .map((projectId) => useCustomProductNames({ projectId }))
    .flat();
}
