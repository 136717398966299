import * as React from 'react';

import { Preview } from '@mui/icons-material';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  handleClose: () => void;
  report: reports.AllReportsQueryRow;
  setShowCustomClassDbModal: (value: boolean) => void;
}

const CustomClassDbReportMenuItem: React.FC<Props> = ({
  handleClose,
  report,
  setShowCustomClassDbModal,
}) => {
  const { t } = useTranslation();

  return (
    <MenuItem onClick={handleClose}>
      <ListItemButton onClick={() => setShowCustomClassDbModal(true)}>
        <ListItemIcon>
          <Preview fontSize="small" color="error" />
        </ListItemIcon>
        <ListItemText primary={t('reportsQa.menu.showClassDb')} />
      </ListItemButton>
    </MenuItem>
  );
};

export default CustomClassDbReportMenuItem;
