import * as React from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import UserPartnersQuery from '@graphql/queries/UserPartnersQuery';
import FormInput from 'components/FormInput';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import useStyles from './useStyles';
import FieldTextInput from '../../components/FieldTextInput';
import FormInputSelect from '../../components/FormInputSelect';
import MaterialButton from '../../components/MaterialButton';
import selectViewerUserId from '../../selectors/viewerUserId';

// Only partner admin creating a client should populate the partner id column in client table
const AddClient: React.FC<any> = (props) => {
  const styles = useStyles(useTheme());

  const { t } = useTranslation();

  const viewerUserId = useSelector((state) => selectViewerUserId(state));
  const [partnerOptions, setPartnerOptions] = React.useState(null);

  const { data, loading } = useQuery(UserPartnersQuery, {
    variables: { userId: viewerUserId },
  });

  React.useEffect(() => {
    if (data) {
      const partners = data.allUserPartners.nodes.map((n) => ({
        label: n.partnerByPartnerId.name,
        value: n.partnerByPartnerId.id,
      }));
      setPartnerOptions(partners);

      if (!!partners && partners.length == 1) {
        props.change('partner', partners[0]);
      }
    }
  }, [data, loading]);

  return (
    <div>
      <div className={styles.sectionContainer}>
        <Field
          name="clientName"
          label={t('admin.createClient')}
          component={FormInput}
          inputComponent={FieldTextInput}
          closeMenuOnSelect={true}
          required
          value={(val) => val.value}
        />
      </div>
      {!!partnerOptions && partnerOptions.length > 1 && (
        <div className={styles.sectionContainer}>
          <Field
            name="partner"
            placeholder={t('admin.selectPartner')}
            component={FormInput}
            inputComponent={FormInputSelect}
            options={partnerOptions}
            val={(val) => val.value}
            isMulti={false}
            isClearable={true}
            closeMenuOnSelect={true}
          />
        </div>
      )}
      <div className={styles.buttonContainer}>
        <MaterialButton
          variant="outlined"
          disabled={props.pristine || props.invalid || props.submitting}
          onClick={props.handleSubmit}
          soft
          teal
        >
          Submit
        </MaterialButton>
      </div>
    </div>
  );
};

export default AddClient;
