import { gql } from '@apollo/client';

export default gql`
  query AllQuestionQuery($language: String!) {
    questions: allQuestions {
      nodes {
        id
        slug
        qOrder
        category
        description
        translations: questionTranslationsByQuestionId (condition: { language: $language }) {
          nodes {
            questionId
            language
            translation
          }
        }
      }
    }
  }
`