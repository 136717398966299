import React, { Component } from 'react';

import { useTheme } from '@mui/material';
import GraphiQL from 'graphiql';

import useStyles from './useStyles';
import getGraphQLRequestHeaders from '../../utils/getGraphQLRequestHeaders';

const RenderExplorer = ({ fetcher }) => {
  const classes = useStyles(useTheme());

  return (
    <div className={classes.container}>
      <GraphiQL fetcher={fetcher} />
    </div>
  );
};

export default class AdminDataExplorerContainer extends Component {
  fetchGraphQL = (graphQLParams) => {
    const { sessionToken } = this.props;

    return fetch('/graphql', {
      method: 'post',
      headers: {
        ...getGraphQLRequestHeaders(sessionToken),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(graphQLParams),
    }).then((response) => response.json());
  };

  render() {
    return <RenderExplorer fetcher={this.fetchGraphQL} />;
  }
}
