import { gql } from '@apollo/client';

export default gql`
  query getUserPartners($userId: Int!) {
    allUserPartners(filter: {userId: {equalTo: $userId}}) {
      nodes {
        userId
        partnerByPartnerId {
          id
          name
        }
      }
    }
  }
`