import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import flexContainer from '../../styles/flexContainer';

const useMainStyles = (otherStyles: any) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        ...flexContainer(theme),
      },
      h1: {
        color: '#42a5f5',
        fontSize: '24px',
        fontWeight: 400,
      },
      h2: {
        color: '#607d8b',
        fontSize: '18px',
        fontWeight: 300,
      },
      h3: {
        color: '#78909c',
        fontSize: '13px',
        fontWeight: 'normal',
      },
    }),
  );

  return {
    useStyles,
  };
};

export default useMainStyles;
