import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';

import Dashboard from './dashboard';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';

const mapStateToProps = (state) => ({
  producerId: selectWorkspaceProducerId(state),
});

const ProducerOverviewQuery = gql`
  query ProducerOverviewQuery($producerId: Int!) {
    producer: producerById(id: $producerId) {
      name
      primaryProductClass

      createdAt
      updatedAt

      users: producerUsersByProducerId {
        totalCount
      }
      userProductReviews: producerUserProductReviews {
        totalCount
      }
      products: productsByProducerId {
        totalCount
      }
    }
  }
`;

export default compose(
  connect(mapStateToProps),
  graphql(ProducerOverviewQuery, {
    options: ({ producerId }) => ({
      variables: {
        producerId,
      },
    }),
    props: ({ data: { producer } }) =>
      producer && {
        name: producer.name,
        primaryProductClass: producer.primaryProductClass,

        usersCount: producer.users && producer.users.totalCount,
        userProductReviewsCount:
          producer.userProductReviews && producer.userProductReviews.totalCount,
        productsCount: producer.products && producer.products.totalCount,

        createdAt: producer.createdAt,
        updatedAt: producer.updatedAt,
      },
  }),
)(Dashboard);
