// Products Layer
// This renders the product letters into the circle space
import * as d3 from 'd3';

import './layers.tooltip.css';
import {
  ProductVersion,
  ProductVersionSet,
} from 'components/Report/ProductVersion';
import { History } from 'history';
import {
  CustomerPreferences,
  productCutoff,
} from 'services/customerPreferences';

import {
  computeLabelFontSize,
  getHoveredProducts,
  meanPq,
  rotate,
  rotateScaled,
  vectorLen,
} from './layerUtils';
import { productTooltip } from '../../mapUtils';

export function productsLayer(
  browserHistory: History,
  container: d3.Selection<SVGElement, any, null, undefined>,
  r: number,
  zoom: number,
  rotationAngle: number,
  tx: number,
  ty: number,
  labelFactor: number,
  labelFont: string,
  productRMax: number,
  productVectorArray: marketmap.ProductVectorData[],
  heatmapArray: marketmap.HeatMapData[],
  excludedProducts: ProductVersionSet,
  customerPreferences: CustomerPreferences,
  projectId: number,
  reportId: string,
  setExcludedProducts: React.Dispatch<React.SetStateAction<ProductVersionSet>>,
) {
  if (productVectorArray.length == 0) {
    return;
  }
  if (heatmapArray.length == 0) {
    return;
  }

  const handleReportInclusion = (productVersion: ProductVersion) => {
    const selected = excludedProducts.copy();

    if (excludedProducts.has(productVersion)) {
      selected.delete(productVersion);
    } else {
      selected.add(productVersion);
    }

    setExcludedProducts(selected);
  };

  const product_scale = (r * zoom) / productRMax;

  const r_cutoff = productRMax / Math.sqrt(2);

  const productData = rotate(productVectorArray, rotationAngle);
  const marginaleffects = rotateScaled(heatmapArray, rotationAngle, r_cutoff);

  const meanpq = meanPq(marginaleffects);

  container.select('#productsLayer').remove();

  const tip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('opacity', 0);

  const colors: d3.ScaleLinear<string, string, never> = d3
    .scaleLinear<string, number>()
    .domain([-1, 0, 1])
    .range(['red', 'lightgrey', 'green'])
    .interpolate(d3.interpolateRgb);

  const visibleProducts: marketmap.ProductVectorData[] = productData
    .filter(
      (d) => !excludedProducts.has(new ProductVersion(d.productId, d.version)),
    )
    .filter(
      (d) =>
        product_scale * vectorLen(d) <=
          productCutoff(customerPreferences) * r + 1, // Add 1 just to make rounding issues better
    );

  container
    .append('g')
    .attr('id', 'productsLayer')
    .selectAll('text')
    .data(visibleProducts)
    .enter()
    .append('text')
    .attr('dx', (d) => +product_scale * d.ld1 - tx)
    .attr('dy', (d) => -product_scale * d.ld2 - ty)
    .attr('ld1', (d) => d.ld1)
    .attr('ld2', (d) => d.ld2)
    .attr('text-anchor', 'middle')
    // TODO probably should be middle everywhere, but for now only apply to edge labels
    .attr('dominant-baseline', (d) =>
      product_scale * vectorLen(d) > 0.95 * r ? 'middle' : 'auto',
    )
    .style('font-family', labelFont)
    .style('font-size', `${computeLabelFontSize(r, zoom, labelFactor)}px`)
    .text((d) => d.label)
    .style('fill', (d) => d.productLabelColor ?? 'black')
    .on('mouseleave', (d) => tip.style('opacity', 0))
    .on('mouseenter', (d) =>
      tip
        .style('opacity', 1)
        .html(
          productTooltip(
            getHoveredProducts(
              visibleProducts,
              d,
              computeLabelFontSize(r, zoom, labelFactor),
            ),
            meanpq,
            colors,
          ),
        )
        .style('left', d.pageX + 25 + 'px')
        .style('top', d.pageY + 'px'),
    )
    .on('mouseleave', (d) => tip.style('opacity', 0))
    .on('click', (d) => {
      tip.style('opacity', 0);
      const {productId, version } = d.target.__data__;
      handleReportInclusion(
        new ProductVersion(
          productId,
          version,
        ),
      )
    });
}
