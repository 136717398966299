import { gql } from '@apollo/client';

export default gql`
  query AllReportsQuery(
    $condition: ReportJobCondition
    $first: Int
    $orderBy: [ReportJobsOrderBy!]
    $filter: ReportJobFilter
    $offset: Int
  ) {
    reports: allReportJobs(
      condition: $condition
      orderBy: $orderBy
      first: $first
      filter: $filter
      offset: $offset
    ) {
      nodes {
        id
        jobId
        parentJobId
        projectName
        reportType
        targetGroupName
        requestedThrough
        startedAt
        reportStatus
        reportId
        pdfEfsUri
        clientName
        passedQa
        workspaceId
        params
        producerByWorkspaceId {
          name
        }
        noOfProjectsAddedTo
        producerByWorkspaceId {
          producerPartnerByProducerId {
            partnerByPartnerId {
              id
              name
            }
          }
        }
      }
    }
  }
`;
