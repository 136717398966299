import { FunctionComponent, useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './InvalidProductModal.module.css';
import MaterialButton from '../MaterialButton';

type Props = {
  handleClose: () => any;
  open: boolean;
  productCollisionData: reports.ProductCollisionResult;
  nextAction: () => void;
  productName?: string;
  productCategory?: string;
};

const InvalidProductModal: FunctionComponent<Props> = ({
  handleClose,
  open,
  productCollisionData,
  nextAction,
  productName,
  productCategory,
}) => {
  const { t } = useTranslation();
  const {
    preventProductCollisionIfInOtherCategory,
    preventProductCollisionIfPrototype,
    warnWhenProductCollisionIsInSameCategory,
    matchedProductCategory,
  } = productCollisionData;
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (preventProductCollisionIfPrototype)
      return setMessage(`${productName} is a prototype.`);
    if (preventProductCollisionIfInOtherCategory)
      return setMessage(
        `${productName} exists in another category - '${matchedProductCategory}'.`
      );
    if (warnWhenProductCollisionIsInSameCategory)
      return setMessage(
        `${productName} exists in '${productCategory}' category. Do you want to continue?`
      );
    return setMessage(
       `${productName} exists in another category - '${matchedProductCategory}'.`
    );
  }, [productCollisionData]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <DialogTitle className={styles.dialogHeader}>
        <h4>{t('invalidProductModal.title')}</h4>
      </DialogTitle>
      <DialogContent>
        <div className={styles.modalContainer}>
          <div className={styles.sectionContainer}>{message}</div>
          <div className={styles.buttonContainer}>
            {warnWhenProductCollisionIsInSameCategory && (
              <MaterialButton variant="outlined" soft onClick={handleClose}>
                {t('invalidProductModal.cancel')}
              </MaterialButton>
            )}
            <MaterialButton
              variant="outlined"
              soft
              teal
              onClick={
                warnWhenProductCollisionIsInSameCategory &&
                !preventProductCollisionIfPrototype &&
                !preventProductCollisionIfInOtherCategory
                  ? nextAction
                  : handleClose
              }
            >
              {t('invalidProductModal.ok')}
            </MaterialButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InvalidProductModal;
