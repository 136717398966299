import { gql } from '@apollo/client';

export default gql`
  query AllProductImagesDownloadQuery($productIds: [Int!]) {
    allProductImages(filter: { productId: { in: $productIds } }) {
      nodes {
        id
        productId
        url
      }
    }
    allProductNutritionalInfoImages(filter: { productId: { in: $productIds } }) {
      nodes {
        id
        productId
        url
      }
    }
  }
`;
