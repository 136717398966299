import { ReportNoveltyPairedPreferenceComparisonResponse } from '@graphql/queries/ReportNoveltyPairedPreferences';
import { getProductName } from 'components/Report/utils';
import { chain } from 'lodash';

export interface NoveltyPairedPreferenceComparison {
  product1: string;
  product2: string;
  noveltyValue: number;
}

export const noveltyPairedPreferenceComparisonArray = (
  reportNoveltyPairedPreferenceComparisonResponse: ReportNoveltyPairedPreferenceComparisonResponse,
  productNames: reports.ColorVersionedProductInfo[],
): NoveltyPairedPreferenceComparison[] => {
  return chain(
    reportNoveltyPairedPreferenceComparisonResponse
      ?.allRpNoveltyPairedPreferences?.nodes,
  )
    .map((item: reports.ReportPairedPreferenceComparison) => ({
      product1: getProductName({
        productNames,
        productId: item.productByProductId1.id,
        version: item.version1,
      }),
      product2: getProductName({
        productNames,
        productId: item.productByProductId2.id,
        version: item.version2,
      }),
      noveltyValue: item.percentPreference,
    }))
    .value();
};

export const biggestDiff = (
  pairedPreferenceComparisonArray: NoveltyPairedPreferenceComparison[],
): string =>
  pairedPreferenceComparisonArray.reduce(
    (a, b) => (a.noveltyValue > b.noveltyValue ? a : b),
    { noveltyValue: null, product1: null },
  ).product1;
