export const ADD_USER_FORM = 'ADD_USER_FORM';
export const EDIT_PRODUCT_FORM = 'EDIT_PRODUCT_FORM';
export const AUTHENTICATION_FORM = 'AUTHENTICATION_FORM';
export const BATCH_INITIALIZATION_FORM = 'BATCH_INITIALIZATION_FORM';
export const PANEL_FORM = 'PANEL_FORM';
export const PRODUCT_FORM = 'PRODUCT_FORM';
export const ADD_USER_PRODUCER_FORM = 'ADD_USER_PRODUCER_FORM';
export const ADD_DEVICE_FORM = 'ADD_DEVICE_FORM';
export const ADD_FOLDER_FORM = 'ADD_FOLDER_FORM';
export const EDIT_FOLDER_FORM = 'EDIT_FOLDER_FORM';
export const MOVE_PRODUCT_FORM = 'MOVE_PRODUCT_FORM';
export const REMOVE_PRODUCT_FORM = 'REMOVE_PRODUCT_FORM';
export const INVITE_USER_FORM = 'INVITE_USER_FORM';
export const CREATE_USER_FORM = 'CREATE_USER_FORM';
export const CHANGE_PASSWORD_FORM = 'CHANGE_PASSWORD_FORM';
export const MERGE_ACCOUNT_FORM = 'MERGE_ACCOUNT_FORM';
export const CREATE_WORKSPACE_FORM = 'CREATE_WORKSPACE_FORM';
export const REQUEST_REPORT_FORM = 'REQUEST_REPORT_FORM';
export const REPORT_QA_FORM = 'REPORT_QA_FORM';
export const REPORT_REVISION_FORM = 'REPORT_REVISION_FORM';
export const PRODUCT_FILTER_FORM = 'PRODUCT_FILTER_FORM';
export const PRODUCT_FEATURE_TAG_FORM = 'PRODUCT_FEATURE_TAG_FORM';
export const GROUP_PRODUCER_FORM = 'GROUP_PRODUCER_FORM';
export const TAG_HEAVY_USER_FORM = 'TAG_HEAVY_USER_FORM';
export const HEAVY_USER_INFO_FORM = 'HEAVY_USER_INFO_FORM';
export const CREATE_CATEGORY_FORM = 'CREATE_CATEGORY_FORM';
export const EDIT_USER_ROLE_FORM = 'EDIT_USER_ROLE_FORM';
export const PARTNER_BRANDING_FORM = 'PARTNER_BRANDING_FORM';
export const ADD_CLIENT_FORM = 'ADD_CLIENT_FORM';
