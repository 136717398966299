import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import MaterialButton from '../../../components/MaterialButton';
import { PRODUCT } from '../../../constants/routePaths';
import { SUPERADMIN_PRODUCT_FILTER } from '../../../constants/routePaths';
import formatPath from '../../../utils/formatPath';
import styles from '../RequestReport.module.css';

const DATE_FORMAT = 'yyyy-MM-dd';

const MIN_REVIEW = 1;

const ClassDBProductsQuery = gql`
  query ClassDBProductsQuery(
    $categoryIds: [Int!]!
    $productIds: [Int!]!
    $after: Cursor
    $before: Cursor
    $first: Int
    $last: Int
    $offset: Int
    $minReview: Int!
  ) {
    products: productsWithMinReviews(
      first: $first
      last: $last
      after: $after
      before: $before
      offset: $offset
      filter: { id: { notIn: $productIds }, categoryId: { in: $categoryIds } }
      minReview: $minReview
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      nodes {
        id
        name
        brand
        createdAt
        producerId

        features: productFeatureProductsByProductId {
          nodes {
            id
            feature: productFeatureByProductFeatureId {
              id
              name
            }
          }
        }

        componentBases: productComponentBaseProductsByProductId {
          nodes {
            id
            componentBase: productComponentBaseByProductComponentBaseId {
              id
              name
            }
          }
        }

        componentOthers: productComponentOtherProductsByProductId {
          nodes {
            id
            componentOther: productComponentOtherByProductComponentOtherId {
              id
              name
            }
          }
        }

        productCategory: productCategoryByCategoryId {
          id
          name
        }

        producer: producerByProducerId {
          id
          name
        }

        productReviews: productReviewsByProductId {
          totalCount
        }
      }
    }
  }
`;

const ClassDbStatisticsQuery = gql`
  query ClassDbStatisticsQuery($categoryIds: [Int!]!, $minReview: Int!) {
    classDbStatistics(
      input: { categoryIds: $categoryIds, minReview: $minReview }
    )
  }
`;

const productNameFormatter = (cell, row) => (
  <Link
    key={row.id}
    className={`restore-${row.id}`}
    to={{
      pathname: formatPath(PRODUCT, { productId: row.id }),
      state: row.id,
    }}
  >
    {cell}
  </Link>
);

interface TableRow {
  id: number;
  name: string;
  brand: string;
  date: string;
  workspace: string;
  category: string;
  feature: string;
  componentBase: string;
  componentOther: string;
  reviews: number;
}

export const ClassDBTable = ({ categories, productIds }) => {
  const [sizePerPage, setSizePerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [orderField, setOrderField] = useState('id');
  const [orderDir, setOrderDir] = useState('desc');
  const { t } = useTranslation();

  const columns: TableColumn<TableRow>[] = [
    {
      selector: (row: TableRow) => row.id,
      name: 'Product ID',
      sortable: true,
      omit: true,
    },
    {
      selector: (row: TableRow) => row.name,
      name: (
        <div className={styles.tableHeader}>{t('product.productName')}</div>
      ),
      sortable: true,
      cell: (row: TableRow) => productNameFormatter(row.name, row),
      wrap: true,
    },
    {
      selector: (row: TableRow) => row.brand,
      name: (
        <div className={styles.tableHeader}>{t('product.productBrand')}</div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      selector: (row: TableRow) => row.date,
      name: (
        <div className={styles.tableHeader}>{t('general.dateCreated')}</div>
      ),
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.workspace,
      name: <div className={styles.tableHeader}>{t('general.workspace')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      selector: (row: TableRow) => row.category,
      name: (
        <div className={styles.tableHeader}>{t('product.productCategory')}</div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      selector: (row: TableRow) => row.feature,
      name: (
        <div className={styles.tableHeader}>{t('product.productFeature')}</div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      selector: (row: TableRow) => row.componentBase,
      name: (
        <div className={styles.tableHeader}>
          {t('product.productComponentBase')}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      selector: (row: TableRow) => row.componentOther,
      name: (
        <div className={styles.tableHeader}>
          {t('product.productComponentOther')}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      selector: (row: TableRow) => row.reviews,
      name: <div className={styles.tableHeader}>{t('reviews.reviews')}</div>,
      sortable: true,
    },
  ];

  const {
    loading,
    data: productResults,
    fetchMore,
  } = useQuery(ClassDBProductsQuery, {
    variables: {
      first: sizePerPage,
      categoryIds: categories.map((c) => c.id),
      productIds: productIds,
      minReview: MIN_REVIEW,
    },
  });

  const classDbStatisticsQuery = useQuery(ClassDbStatisticsQuery, {
    variables: {
      categoryIds: categories.map((c) => c.id),
      minReview: MIN_REVIEW,
    },
  });

  const handleTableChange = (
    type,
    { page: newPage, sizePerPage: newSize, sortField, sortOrder },
  ) => {
    const newOrderBy = 'ID_ASC';
    if (type === 'sort') {
      switch (sortField) {
        // TODO: allow ordering of the whole set
        // case 'name':
        //   newOrderBy = sortOrder === 'asc' ? 'NAME_ASC' : 'NAME_DESC';
        //   break;

        // case 'brand':
        //   newOrderBy = sortOrder === 'asc' ? 'BRAND_ASC' : 'BRAND_DESC';
        //   break;

        // case 'date':
        //   newOrderBy =
        //     sortOrder === 'asc' ? 'CREATED_AT_ASC' : 'CREATED_AT_DESC';
        //   break;
        // case 'id':
        //   newOrderBy = sortOrder === 'asc' ? 'ID_ASC' : 'ID_DESC';
        //   break;
        default:
          setOrderField(sortField);
          setOrderDir(sortOrder);
          return;
      }
    }

    const variables: any = {
      condition: {
        visibility: true,
      },
      orderBy: newOrderBy,
    };

    if (newPage > page) {
      variables.before = undefined;
      variables.after = productResults.products.pageInfo.endCursor;
      variables.first = sizePerPage;
    } else if (newPage < page) {
      variables.after = undefined;
      variables.before = productResults.products.pageInfo.startCursor;
      variables.first = undefined;
      variables.last = sizePerPage;
    } else if (newSize !== sizePerPage || orderField !== newOrderBy) {
      variables.first = newSize;
      variables.after = undefined;
      variables.before = undefined;
      newPage = 1;
    }

    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    }).then(() => {
      setPage(newPage);
      setSizePerPage(newSize);
      setOrderDir(sortOrder);
      setOrderField(sortField);
    });
  };

  if (!categories || categories.length <= 0)
    return (
      <div>
        <div className={styles.adminOnly}>ADMIN ONLY</div>
        <div>
          {productIds && productIds.length > 0
            ? 'The category for the products in the competitive set has not been set. To see the Class DB, set the product categories in Edit Product Page!'
            : 'Add products to the competitive set above!'}
        </div>
      </div>
    );

  if (loading || classDbStatisticsQuery.loading) {
    return <LinearProgress />;
  }

  const getStatistic = (group: string, idValue: any) => {
    try {
      const statistic =
        classDbStatisticsQuery.data.classDbStatistics[group][idValue];
      const { totalProduct, totalReview } =
        classDbStatisticsQuery.data.classDbStatistics;
      const productRatio = (
        (statistic.product_count / totalProduct) *
        100
      ).toFixed(1);
      const reviewRatio = (
        (statistic.review_count / totalReview) *
        100
      ).toFixed(1);
      return `(${statistic.product_count}, ${productRatio}%, ${statistic.review_count}, ${reviewRatio}%)`;
    } catch {
      return '(N/A)';
    }
  };

  const data =
    productResults &&
    productResults.products &&
    productResults.products.nodes.map((node) => ({
      id: node.id,
      name: node.name,
      brand:
        node.brand &&
        `${node.brand} ${getStatistic('brandStatistics', node.brand)}`,
      date: formatDate(parseISO(node.createdAt), DATE_FORMAT),
      workspace: node.producer && node.producer.name,
      category:
        node.productCategory &&
        `${node.productCategory.name} ${getStatistic(
          'categoryStatistics',
          node.productCategory.id,
        )}`,
      feature:
        node.features &&
        node.features.nodes
          .map(
            (f) =>
              `${f.feature.name} ${getStatistic(
                'featureStatistics',
                f.feature.id,
              )}`,
          )
          .join(', '),
      componentBase:
        node.componentBases &&
        node.componentBases.nodes
          .map(
            (cb) =>
              `${cb.componentBase.name} ${getStatistic(
                'componentBaseStatistics',
                cb.componentBase.id,
              )}`,
          )
          .join(', '),
      componentOther:
        node.componentOthers &&
        node.componentOthers.nodes
          .map(
            (co) =>
              `${co.componentOther.name} ${getStatistic(
                'componentOtherStatistics',
                co.componentOther.id,
              )}`,
          )
          .join(', '),
      reviews: node.productReviews.totalCount,
    }));

  data.sort(
    (a, b) =>
      String(a[orderField] || '').localeCompare(b[orderField] || '') *
      (orderDir === 'asc' ? 1 : -1),
  );

  return (
    <div>
      <div className={styles.adminOnly}>ADMIN ONLY</div>
      <div className={styles.resultHeader}>
        <Link
          to={{
            pathname: SUPERADMIN_PRODUCT_FILTER,
            state: {
              categories: categories.map((c) => c.name),
            },
          }}
        >
          <MaterialButton teal soft>
            Product Filter
          </MaterialButton>
        </Link>

        <div className={styles.productFieldsTitle}>Class DB</div>

        <div>
          {t('product.productsFound', {
            count: productResults.products.totalCount,
          })}
        </div>
      </div>

      <div className={styles.statisticsInfo}>
        Products in report are included in Class DB.
        <br />
        The statistics embeded in the table follow the following format:
        <br />
        <i>
          (<br />
          number of products under that category,
          <br />
          percentage of the total Class DB belonging to that category,
          <br />
          number of reviews under that category,
          <br />
          percentage of total reviews in the Class DB belonging to that category
          <br />)
        </i>
      </div>

      <div>
        <DataTable
          columns={columns}
          data={data}
          fixedHeader
          striped
          pagination
          paginationIconFirstPage={<RDTFirstPage />}
          paginationIconLastPage={<RDTLastPage />}
          paginationIconPrevious={<RDTPrevPage />}
          paginationIconNext={<RTDNextPage />}
        />
      </div>
    </div>
  );
};

export default ClassDBTable;
