import { gql } from '@apollo/client';

export default gql`
  query jobsQuery(
    $workspaceID: Int
    $notReportType: String
    $passedQA: Boolean
    $orderBy: [ReportJobsOrderBy!]
  ) {
    allReportJobs(
      condition: { workspaceId: $workspaceID, passedQa: $passedQA }
      filter: { reportType: { distinctFromInsensitive: $notReportType } }
      orderBy: $orderBy
    ) {
      nodes {
        jobId
        startedAt
        reportId
        targetGroupName
        clientName
        projectName
        workspaceId
        passedQa
      }
    }
  }
`;
