import * as React from 'react';
import { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import ReportMarketPreferencesVersionedQuery from '@graphql/queries/ReportMarketPreferencesVersionedQuery';
import { Grid } from '@mui/material';
import useCustomProductNames from 'hooks/useCustomProductNames';

import ReportProductMarketPerferenceOverlay from '../ReportProductMarketPreferencesOverlay/ReportProductMarketPerferenceOverlay';

interface ReportProps {
  projectId: number;
  title: string;
  calculatedMaxPercentPop?: number;
  products: reports.ProductToCompare[];
}

const ReportMarketPreferenceOverlay: React.FC<ReportProps> = (props) => {
  const { projectId, title, calculatedMaxPercentPop, products } = props;

  const [marketPreferenceData, setResults] = useState<
    {
      reportId: string;
      marketPreferenceData: reports.ReportMarketPreferencesResponse;
    }[]
  >([]);

  const customProductNames = useCustomProductNames({ projectId });

  const [queryMarketPreferenceData] =
    useLazyQuery<reports.ReportMarketPreferencesResponse>(
      ReportMarketPreferencesVersionedQuery,
    );
  useEffect(() => {
    const getResults = async () => {
      const results = await Promise.all(
        products.map(async (p) => ({
          reportId: p.reportId,
          marketPreferenceData: await queryMarketPreferenceData({
            variables: {
              reportID: p.reportId,
              productID: p.productId,
              version: p.version,
            },
          }).then((res) => res.data),
        })),
      );
      setResults(results);
    };

    getResults();
  }, [products]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} style={{ width: '100%' }}>
        <ReportProductMarketPerferenceOverlay
          projectId={projectId}
          title={title}
          titleCenter={true}
          marketPreferenceData={marketPreferenceData}
          calculatedMaxPercentPop={calculatedMaxPercentPop}
        />
      </Grid>
    </Grid>
  );
};

export default ReportMarketPreferenceOverlay;
