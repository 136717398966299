import * as React from 'react';

import { Document, Page, StyleSheet, View, Text } from '@react-pdf/renderer';
import moment from 'moment';
import { Moment } from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isAfsWorkspace } from 'utils/afsUtils';

import { COLORS } from '../../styles/theme';
import ProgressBar from '../ProgressBar';
import PdfTable from '../Table/PdfTable';

const styles = StyleSheet.create({
  panelInfo: {
    borderColor: 'rgb(224, 227, 233)',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderCollapse: 'collapse',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
  },
  panelInfoContainer: {
    flexDirection: 'row',
    height: 70,
  },
  panelInfoTitle: {
    //fontFamily: 'sans-serif',
    fontSize: 10,
    color: COLORS.MARINE,
  },
  panelInfoText: {
    //fontFamily: 'sans-serif',
    fontSize: 10,
    color: COLORS.MARINE,
  },
  sectionDivider: {
    height: 28,
    // backgroundImage: 'linear-gradient(to right, #022950, #465dba)',
    backgroundColor: COLORS.MARINE,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sectionTitle: {
    fontSize: 10,
    color: 'white',
    paddingLeft: 12,
  },
  sectionText: {
    color: COLORS.MARINE,
    fontSize: 8,
  },
  sectionContentContainer: {
    padding: 12,
  },
  tableHeader: {
    fontSize: 8,
    color: COLORS.MARINE,
    paddingTop: 8,
    paddingBottom: 8,
    paddingHorizontal: 5,
    width: 60,
    fontWeight: 600,
  },
  tableRow: {
    fontSize: 8,
    color: COLORS.MARINE,
    paddingTop: 8,
    paddingBottom: 8,
    paddingHorizontal: 5,
    width: 60,
  },
  tableHeaderContainer: {
    flexDirection: 'row',
  },
});

interface PanelListCellSubComponentProps extends WithTranslation {
  onPressDelete: () => void;
  producerId: any;
  pin: number;
  id: number;
  timeElapsedSeconds: number;
  timeLimitSeconds: number;
  blind: boolean;
  texture: boolean;
  reviewsCount: number;
  reviewDurationAverageSeconds: number;
  reviewDurationAggregateSeconds: number;
  tags: any[];
  products: any[];
  panelists: any[];
  productRows: JSX.Element[];
  successUpdating: boolean;
  errorUpdating: boolean;
  updating: boolean;
  updateBlindLabel: () => void;
  newPanelEndTime: Moment;
  endTime: Date;
  startTime: Date;
  name: string;
  colorCode?: string;
}

class PanelListCellSubComponent extends React.Component<PanelListCellSubComponentProps> {
  render() {
    const {
      id,
      producerId,
      onPressDelete,
      pin,
      timeElapsedSeconds,
      timeLimitSeconds,
      blind,
      texture,
      reviewsCount,
      reviewDurationAggregateSeconds,
      reviewDurationAverageSeconds,
      tags,
      products,
      panelists,
      productRows,
      successUpdating,
      errorUpdating,
      updating,
      updateBlindLabel,
      newPanelEndTime,
      endTime,
      startTime,
      name,
      colorCode,
      t,
    } = this.props;

    const renderRowItem = (prop: any, index: number) => {
      return (
        <View
          style={[
            styles.tableHeaderContainer,
            {
              backgroundColor: index % 2 === 0 ? 'white' : '#e7f3fa',
            },
          ]}
        >
          <View style={{ ...styles.tableRow, width: 100 }}>
            <Text>{prop[0].props.children}</Text>
          </View>
          <View style={{ ...styles.tableRow, width: 95 }}>
            <Text>{prop[1].props.children}</Text>
          </View>
          <View style={{ ...styles.tableRow }}>
            <Text>{prop[2].props.children}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text>{prop[3].props.children}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text>{prop[4].props.children}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text>{prop[5].props.children}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text>{prop[6].props.children}</Text>
          </View>
          {isAfsWorkspace(producerId) && (
            <View style={styles.tableRow}>
              <Text>{prop[7].props.children}</Text>
            </View>
          )}
          {isAfsWorkspace(producerId) && (
            <View style={styles.tableRow}>
              <Text>{prop[8].props.children}</Text>
            </View>
          )}
          {isAfsWorkspace(producerId) && (
            <View style={styles.tableRow}>
              <Text>{prop[9].props.children}</Text>
            </View>
          )}
        </View>
      );
    };

    return (
      <Document>
        <Page size="A4" style={{ paddingHorizontal: 10, paddingVertical: 30 }}>
          <View
            style={{
              fontSize: 12,
              backgroundColor: COLORS.MARINE_FADED,
              flexDirection: 'row',
              height: 30,
              padding: 5,
            }}
          >
            <Text>
              {pin} {name && '-'} {name}
            </Text>
          </View>
          <ProgressBar value={timeElapsedSeconds / timeLimitSeconds} />
          <View style={styles.panelInfoContainer}>
            <View style={styles.panelInfo}>
              <View style={styles.panelInfoTitle}>
                <Text>{t('panel.blind')}?</Text>
              </View>
              <View style={styles.panelInfoText}>
                <Text>{blind ? 'Yes' : 'No'}</Text>
              </View>
            </View>
            <View style={styles.panelInfo}>
              <View style={styles.panelInfoTitle}>
                <Text>{t('panel.textureMandatory')}?</Text>
              </View>
              <View style={styles.panelInfoText}>
                <Text>{texture ? 'Yes' : 'No'}</Text>
              </View>
            </View>
            <View style={styles.panelInfo}>
              <View style={styles.panelInfoTitle}>
                <Text>{t('panel.startTime')}</Text>
              </View>
              <View style={styles.panelInfoText}>
                <Text>
                  {moment
                    .utc(startTime)
                    .local()
                    .format('MM-DD-YYYY HH:mm:ss zz')}
                </Text>
              </View>
            </View>
            <View style={styles.panelInfo}>
              <View style={styles.panelInfoTitle}>
                <Text>{t('panel.endTime')}</Text>
              </View>
              <View style={styles.panelInfoText}>
                <Text>
                  {moment.utc(endTime).local().format('MM-DD-YYYY HH:mm:ss zz')}
                </Text>
              </View>
            </View>
            <View style={styles.panelInfo}>
              <View style={styles.panelInfoTitle}>
                <Text>{t('panel.averageDuration')}</Text>
              </View>
              <View style={styles.panelInfoText}>
                <Text>
                  {moment
                    .utc(reviewDurationAverageSeconds * 1000)
                    .format('HH:mm:ss')}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View style={styles.sectionDivider}>
              <View style={styles.sectionTitle}>
                <Text>{t('panel.panelTag')}</Text>
              </View>
            </View>
            <View style={styles.sectionContentContainer}>
              {tags.length === 0 ? (
                <View style={styles.sectionText}>
                  <Text>{t('general.noTagSelected')}</Text>
                </View>
              ) : (
                <View style={{ flexDirection: 'row' }}>
                  {tags.map((panelTag, index) => (
                    <View
                      key={index}
                      style={{
                        backgroundColor: COLORS.AQUA_MARINE,
                        width: 60,
                        padding: 5,
                        marginHorizontal: 5,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Text style={{ color: COLORS.MARINE, fontSize: 8 }}>
                        {panelTag.tag.tag.toLowerCase()}
                      </Text>
                    </View>
                  ))}
                </View>
              )}
            </View>
          </View>
          <View>
            <View style={styles.sectionDivider}>
              <View style={styles.sectionTitle}>
                <Text>{t('navigation.products')}</Text>
              </View>
            </View>
            <PdfTable
              renderHeaderRow={() => (
                <View style={styles.tableHeaderContainer}>
                  <View style={{ ...styles.tableHeader, width: 130 }}>
                    <Text>{t('product.productName')}</Text>
                  </View>
                  <View style={{ ...styles.tableHeader, width: 95 }}>
                    <Text>{t('product.attributes')}</Text>
                  </View>
                  <View style={styles.tableHeader}>
                    <Text>{t('panel.expirationDate')}</Text>
                  </View>
                  <View style={styles.tableHeader}>
                    <Text>{t('panel.productionDate')}</Text>
                  </View>
                  <View style={styles.tableHeader}>
                    <Text>{t('panel.totalReviews')}</Text>
                  </View>
                  <View style={styles.tableHeader}>
                    <Text>{t('panel.blindLabel')}</Text>
                  </View>
                  <View style={styles.tableHeader}>
                    <Text>{t('panel.servingVessel')}</Text>
                  </View>

                  {isAfsWorkspace(producerId) && (
                    <View style={styles.tableHeader}>
                      <Text>{t('panel.clientName')}</Text>
                    </View>
                  )}
                  {isAfsWorkspace(producerId) && (
                    <View style={styles.tableHeader}>
                      <Text>{t('panel.projectName')}</Text>
                    </View>
                  )}
                  {isAfsWorkspace(producerId) && (
                    <View style={styles.tableHeader}>
                      <Text>{t('panel.totalCost')}</Text>
                    </View>
                  )}
                </View>
              )}
            >
              {productRows.map((item, index) =>
                renderRowItem(item.props.children, index + 1),
              )}
            </PdfTable>
          </View>
          <View>
            <View style={styles.sectionDivider}>
              <Text style={styles.sectionTitle}>{t('panel.panelists')}</Text>
            </View>
            <View
              style={[styles.sectionContentContainer, { paddingRight: 10 }]}
            >
              {panelists && panelists.length ? (
                <Text style={[styles.sectionText, { lineHeight: 2 }]}>
                  {panelists.map((panelist) => panelist.name).join(', ')}
                </Text>
              ) : (
                <View style={styles.sectionText}>{t('panel.noPanelists')}</View>
              )}
            </View>
          </View>

          <View>
            <View style={styles.sectionDivider}>
              <Text style={styles.sectionTitle}>{t('reviews.reviews')}</Text>
            </View>
            <View style={styles.sectionContentContainer}>
              <Text style={styles.sectionText}>{reviewsCount}</Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  }
}

export default withTranslation()(PanelListCellSubComponent);
