import { gql } from '@apollo/client';

export default gql`
  mutation InitializeBatchMutation($batchState: BatchStateInput!) {
    createBatchState(input: { batchState: $batchState }) {
      batchState {
        id
      }
    }
  }
`;
