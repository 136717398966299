export const ggVars: constants.GGVar[] = [
  {
    label: 'Wet',
    key: 'wet',
    idx: 1,
  },
  {
    label: 'Roasted',
    key: 'roasted',
    idx: 2,
  },
  {
    label: 'Earthy',
    key: 'earthy',
    idx: 3,
  },
  {
    label: 'Astringent',
    key: 'astringent',
    idx: 4,
  },
  {
    label: 'Marine',
    key: 'marine',
    idx: 5,
  },
  {
    label: 'Mineral',
    key: 'mineral',
    idx: 6,
  },
  {
    label: 'Retronasal',
    key: 'retronasal',
    idx: 7,
  },
  {
    label: 'Gamey',
    key: 'gamey',
    idx: 8,
  },
  {
    label: 'Woody',
    key: 'woody',
    idx: 9,
  },
  {
    label: 'Bitter',
    key: 'bitter',
    idx: 10,
  },
  {
    label: 'Meaty',
    key: 'meaty',
    idx: 11,
  },
  {
    label: 'Smoked',
    key: 'smoked',
    idx: 12,
  },
  {
    label: 'Dry',
    key: 'dry',
    idx: 13,
  },
  {
    label: 'Rich',
    key: 'rich',
    idx: 14,
  },
  {
    label: 'Dairy',
    key: 'dairy',
    idx: 15,
  },
  {
    label: 'Mouth Feel',
    key: 'mouthfeel',
    idx: 16,
  },
  {
    label: 'Nuts & Seeds',
    key: 'nutsAndSeeds',
    idx: 17,
  },

  {
    label: 'Herbaceous',
    key: 'herbaceous',
    idx: 18,
  },

  {
    label: 'Cold Finish',
    key: 'coldFinish',
    idx: 19,
  },

  {
    label: 'Floral',
    key: 'floral',
    idx: 20,
  },

  {
    label: 'Spices',
    key: 'spices',
    idx: 21,
  },

  {
    label: 'Sour & Acidity',
    key: 'sourAndAcidity',
    idx: 22,
  },

  {
    label: 'Fruits',
    key: 'fruits',
    idx: 23,
  },

  {
    label: 'Sugar',
    key: 'sugar',
    idx: 24,
  },
];

export interface GgVarAttributes {
  wet: number;
  roasted: number;
  earthy: number;
  astringent: number;
  marine: number;
  mineral: number;
  retronasal: number;
  gamey: number;
  woody: number;
  bitter: number;
  meaty: number;
  smoked: number;
  dry: number;
  rich: number;
  dairy: number;
  mouthfeel: number;
  nutsAndSeeds: number;
  herbaceous: number;
  coldFinish: number;
  floral: number;
  spices: number;
  sourAndAcidity: number;
  fruits: number;
  sugar: number;
}

export interface GGVarLabelMapping {
  [key: string]: string;
}

const labelMapping: GGVarLabelMapping = ggVars.reduce((accu, val) => {
  accu[val.key] = val.label;
  return accu;
}, {});

export const mapLabel = function (val: string) {
  const label = labelMapping[val];
  return typeof label !== 'undefined' && label !== null ? label : val;
};

export const mapFromKey: (key: string) => constants.GGVar = (key: string) => {
  const exactMatch = ggVars.find((ggVar) => ggVar.key === key);
  if (exactMatch) {
    return exactMatch;
  } else {
    switch (key.toLowerCase()) {
      case 'nuts & seeds':
      case 'nutsandseeds':
        return ggVars.find((ggVar) => ggVar.key === 'nutsAndSeeds');
      case 'sour & acidity':
      case 'sourandacidity':
        return ggVars.find((ggVar) => ggVar.key === 'sourAndAcidity');
      default:
        return null;
    }
  }
};

export default ggVars;
