import * as React from 'react';

import { useQuery } from '@apollo/client';
import PairedPreferencesByReportId from '@graphql/queries/ReportPairedPreferences';
import { Box } from '@mui/material';
import { ProductVersion } from 'components/Report/ProductVersion';
import { getProductName } from 'components/Report/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { Data, LabelKeyObject } from 'react-csv/lib/core';

import DataDownloadTile from '../DataDownloadTile';
import { TileType } from '../DataDownloadTile/DataDownloadTile';

interface Props {
  projectId: number;
  reportId: string;
}

const PairedComparisonDownload: React.FC<Props> = (props) => {
  const { projectId, reportId } = props;

  const productNames = useCustomProductNames({
    projectId: Number(projectId),
    reportId: reportId,
  });

  const { loading, error, data } =
    useQuery<reports.ReportPairedPreferenceComparisonResponse>(
      PairedPreferencesByReportId,
      {
        variables: {
          reportID: reportId,
        },
      },
    );

  const uniqueProductVersions = (
    acc: ProductVersion[],
    cur: ProductVersion,
  ): ProductVersion[] => {
    return acc.map((i) => i.equals(cur)).reduce((a, b) => a || b, false)
      ? acc
      : acc.concat(cur);
  };

  const getProducts = (
    data: reports.ReportPairedPreferenceComparisonResponse,
  ) =>
    data.allRpPairedPreferences.nodes
      .map((row) => [
        new ProductVersion(row.productByProductId1.id, row.version1),
        new ProductVersion(row.productByProductId2.id, row.version2),
      ])
      .reduce(
        (acc: ProductVersion[], cur: [ProductVersion, ProductVersion]) =>
          acc.concat(cur[0], cur[1]),
        [],
      )
      .reduce(uniqueProductVersions, [])
      .sort((pv1, pv2) => pv1.productId - pv2.productId);

  const getCsvHeader: (
    productNames: reports.ColorVersionedProductInfo[],
    data: reports.ReportPairedPreferenceComparisonResponse,
  ) => LabelKeyObject[] = () => {
    return [{ label: '', key: 'crossCroduct' }].concat(
      getProducts(data).map((product) => ({
        label: getProductName({
          productNames,
          productId: product.productId,
          version: product.version,
        }),
        key: product.value,
      })),
    );
  };

  const getCsvRows: (
    productNames: reports.ColorVersionedProductInfo[],
    data: reports.ReportPairedPreferenceComparisonResponse,
  ) => Data = (productNames: reports.ColorVersionedProductInfo[]) => {
    const reportProducts = getProducts(data);

    return reportProducts.map((leftProduct) =>
      [
        getProductName({
          productNames,
          productId: leftProduct.productId,
          version: leftProduct.version,
        }),
      ].concat(
        reportProducts.map((topProduct) => {
          if (leftProduct.equals(topProduct)) {
            return null;
          } else {
            const a = data.allRpPairedPreferences.nodes
              .filter(
                (item) =>
                  item.productByProductId1.id === leftProduct.productId &&
                  item.productByProductId2.id === topProduct.productId,
              )
              .map((match) => match.percentPreference.toFixed(3));
            return a.find(Boolean);
          }
        }),
      ),
    );
  };

  if (
    loading ||
    error ||
    data.allRpPairedPreferences.nodes.length === 0 ||
    productNames.length === 0
  ) {
    return <Box />;
  }

  return (
    <DataDownloadTile
      tileType={TileType.CSV}
      headers={getCsvHeader(productNames, data).map((h) => h.label)}
      csvDataRows={getCsvRows(productNames, data)}
      fileName="PairedComparison.csv"
      tileName="Paired Comparisons"
    />
  );
};

export default PairedComparisonDownload;
