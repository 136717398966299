import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import styles from './AddUserToWorkspaceContainer.module.css';
import FieldTextInput from '../../components/FieldTextInput';
import FormInput from '../../components/FormInput';
import FormInputSelect from '../../components/FormInputSelect';
import MaterialButton from '../../components/MaterialButton';
import AllWorkspacesQuery from '../../graphql/queries/AllWorkspacesQuery';
import UserWorkspaceListByUserIdQuery from '../../graphql/queries/UserWorkspaceListByUserIdQuery';
import selectViewerUserId from '../../selectors/viewerUserId';
import ConditionViewerRoleContainer from '../ConditionViewerRole';

const AddUserToWorkspaceContainer = ({
  handleSubmit,
  submitting,
  pristine,
  invalid,
  t
}) => {
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [allWorkspaceOptions, setAllWorkspaceOptions] = useState([]);

  const viewerUserId = useSelector(state => selectViewerUserId(state));

  const {
    loading: loadingWorkspaceOptions,
    error: workspaceError,
    data: workspaceData
  } = useQuery(UserWorkspaceListByUserIdQuery, {
    variables: {
      userId: viewerUserId
    }
  });

  const {
    loading: allWorkspacesLoading,
    error: allWorkspacesError,
    data: allWorkspacesData
  } = useQuery(AllWorkspacesQuery);

  React.useEffect(
    () => {
      if (!loadingWorkspaceOptions) {
        setWorkspaceOptions(
          workspaceData.user.producerUsersByUserId.nodes.map(d => {
            if (d.producerByProducerId.producerPartnerByProducerId) {
              return {
                label: `${d.producerByProducerId.name} (${
                  d.producerByProducerId.producerPartnerByProducerId
                    .partnerByPartnerId.name
                })`,
                value: {
                  producerId: d.producerId,
                  partnerId:
                    d.producerByProducerId.producerPartnerByProducerId
                      .partnerByPartnerId.id
                }
              };
            }
            return {
              label: `${d.producerByProducerId.name}`,
              value: {
                producerId: d.producerId,
                partnerId: null
              }
            };
          })
        );
      }
      if (!allWorkspacesLoading) {
        setAllWorkspaceOptions(
          allWorkspacesData.allProducers.nodes.map(d => {
            if (d.producerPartnerByProducerId) {
              return {
                label: `${d.name} (${
                  d.producerPartnerByProducerId.partnerByPartnerId.name
                })`,
                value: {
                  producerId: d.id,
                  partnerId: d.producerPartnerByProducerId.partnerId
                }
              };
            }
            return {
              label: `${d.name}`,
              value: {
                producerId: d.id,
                partnerId: null
              }
            };
          })
        );
      }
    },
    [loadingWorkspaceOptions, allWorkspacesLoading]
  );

  if (loadingWorkspaceOptions || allWorkspacesLoading) {
    return <LinearProgress />;
  }

  if (workspaceError || allWorkspacesError) {
    return <h4>Can't load workspaces!</h4>;
  }

  return (
    <div>
      <div className={styles.sectionContainer}>
        <Field
          name="email"
          component={FieldTextInput}
          fullWidth
          label={t('users.email')}
          required
        />
        <ConditionViewerRoleContainer
          render={viewerRoles =>
            viewerRoles.viewerRoleIsSuperadmin ? (
              <Field
                name="partnerProducer"
                component={FormInput}
                inputComponent={FormInputSelect}
                key={allWorkspaceOptions.length}
                className={styles.inputSelect}
                options={allWorkspaceOptions}
                hideSelectedOptions={false}
                placeholder={t('workspace.selectProducerName')}
                closeMenuOnSelect={true}
                required
                value={val => val.value}
              />
            ) : (
              <Field
                name="partnerProducer"
                component={FormInput}
                inputComponent={FormInputSelect}
                key={workspaceOptions.length}
                className={styles.inputSelect}
                options={workspaceOptions}
                hideSelectedOptions={false}
                placeholder={t('workspace.selectProducerName')}
                closeMenuOnSelect={true}
                required
                value={val => val.value}
              />
            )
          }
        />
      </div>
      <div className={styles.buttonContainer}>
        <MaterialButton
          variant="outlined"
          disabled={pristine || invalid || submitting}
          onClick={handleSubmit}
          soft
          teal
        >
          Submit
        </MaterialButton>
      </div>
    </div>
  );
};

export default withTranslation()(AddUserToWorkspaceContainer);
