import * as React from 'react';
import { useState } from 'react';

import { DSVRowArray, csvParse } from 'd3';

import { FetchResult, useLazyQuery, useQuery } from '@apollo/client';
import CreateProductMutation from '@graphql/mutations/CreateProduct';
import CreateProductComponentBaseProductMutation from '@graphql/mutations/CreateProductComponentBaseProduct';
import CreateProductFeatureProductMutation from '@graphql/mutations/CreateProductFeatureProduct';
import AllProductCategoriesQuery from '@graphql/queries/AllProductCategoriesQuery';
import AllProductComponentBasesQuery from '@graphql/queries/AllProductComponentBasesQuery';
import AllProductFeaturesQuery from '@graphql/queries/AllProductFeaturesQuery';
import MultiProductSearchQuery, {
  MultiProductSearchQueryType,
} from '@graphql/queries/MultiProductSearchQuery';
import { Description } from '@mui/icons-material';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import MaterialButton from 'components/MaterialButton';
import graphqlClient from 'consumers/graphqlClient';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';
import { md5 } from 'utils/md5';

import { getCategoryId, mapToMutationProduct, validateProduct } from './utils';
import Alert from '../../components/Alert/Alert';
import { PageHeader, PagePaper, PageTitle } from '../../styles/themeComponents';
import { truthyString } from '../../utils/afsUtils';

export interface NewProduct {
  Name: string;
  Brand: string;
  Country: string;
  'Local Name': string;
  Ingredients: string;
  'Serving Size (On Label)': string;
  'SS#': string;
  Calories: string;
  'CA#': string;
  'Calories From Fat': string;
  'CFF#': string;
  'Total Fat': string;
  'TF#': string;
  Cholesterol: string;
  'CH#': string;
  Sodium: string;
  'SOD#': string;
  'Total Carbohydrates': string;
  'TC#': string;
  'Country of Purchase': string;
  Category: string;
  'Product Feature': string;
  'Component Base': string;
  'Dietary Restrictions': string;
  'Physical State': string;
  Allergens: string;
  Prototype: string;
  Public: string;
  Aroma: string;
}

const columns = [
  { label: 'Name', required: true },
  { label: 'Local Name', required: false },
  { label: 'Brand', required: true },
  { label: 'Ingredients', required: false },
  { label: 'Serving Size (On Label)', required: false, nutrition: true },
  { label: 'SS#', required: false, nutrition: true },
  { label: 'Calories', required: false, nutrition: true },
  { label: 'CA#', required: false, nutrition: true },
  { label: 'Calories From Fat', required: false, nutrition: true },
  { label: 'CFF#', required: false, nutrition: true },
  { label: 'Total Fat', required: false, nutrition: true },
  { label: 'TF#', required: false, nutrition: true },
  { label: 'Cholesterol', required: false, nutrition: true },
  { label: 'CH#', required: false, nutrition: true },
  { label: 'Sodium', required: false, nutrition: true },
  { label: 'SOD#', required: false, nutrition: true },
  { label: 'Total Carbohydrates', required: false, nutrition: true },
  { label: 'TC#', required: false, nutrition: true },
  { label: 'Country', required: true },
  { label: 'Country of Purchase', required: true },
  { label: 'Category', required: true },
  { label: 'Product Feature', required: true },
  { label: 'Component Base', required: true },
  { label: 'Dietary Restrictions', required: true },
  { label: 'Physical State', required: true },
  { label: 'Allergens', required: true },
  { label: 'Prototype', required: false },
  { label: 'Public', required: false },
  { label: 'Aroma', required: false },
];

const requiredColumns = columns.filter((i) => i.required).map((i) => i.label);
const expectedColumns = columns.filter((i) => !i.required).map((i) => i.label);

const ProductCreateBulk: React.FC = () => {
  const { t } = useTranslation();
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));
  const [file, setFile] = useState(null);
  const [csvData, setCSV] = useState<DSVRowArray<string>>(null);
  const [validProductsToAdd, setNewProductsToAdd] =
    useState<NewProduct[]>(null);
  const [errorMsg, setErrorMsg] = useState<string>(null); // CSV entirely invalid
  const [warningMsg, setWarningMsg] = useState<string[]>([]); // non-blocking irregularity
  const [productErrorMsg, setProductErrorMsg] = useState<string[]>([]); // product invalid, will be excluded
  const [mutationResults, setMutationResults] = useState<FetchResult[]>(null);

  const { data: categories, loading: categoriesLoading } = useQuery<{
    productCategories: {
      nodes: {
        id: number;
        name: string;
      }[];
    };
  }>(AllProductCategoriesQuery, {
    variables: {
      condition: {
        producerId: producerId,
      },
    },
  });

  const { data: features, loading: featuresLoading } = useQuery<{
    productFeatures: {
      nodes: {
        id: number;
        name: string;
      }[];
    };
  }>(AllProductFeaturesQuery, {
    variables: {
      condition: {
        producerId: producerId,
      },
    },
  });
  const { data: components, loading: componentsLoading } = useQuery(
    AllProductComponentBasesQuery,
    {
      variables: {
        condition: {
          producerId: producerId,
        },
      },
    },
  );

  const fileReader = new FileReader();

  function productWarning(product: NewProduct): string | undefined {
    if (!truthyString(product.Prototype)) {
      const missingNutritionCols = columns
        .filter((c) => c.nutrition)
        .filter((c) => !product[c.label])
        .map((c) => c.label);
      if (missingNutritionCols.length > 0) {
        return `non-prototype product missing nutrition columns: ${missingNutritionCols.join(
          ', ',
        )}`;
      }
    }
    return undefined;
  }

  const [searchProductNames] = useLazyQuery<MultiProductSearchQueryType>(
    MultiProductSearchQuery,
    {
      onCompleted: (productSearchResponse) => {
        let processedData: NewProduct[] = [];
        const existingProducts = productSearchResponse.allProducts.nodes;
        csvData.forEach((csvProduct, i) => {
          const csvProductValidated = validateProduct(
            csvProduct,
            categories,
            features,
            components,
            requiredColumns,
            expectedColumns,
          );
          const validatedProduct = csvProductValidated.product;
          const matchingProducts = existingProducts.filter(
            (p) =>
              p.name === validatedProduct.Name &&
              p.brand === validatedProduct.Brand &&
              p.producer.id === producerId,
          );
          if (!csvProductValidated.valid) {
            setProductErrorMsg((currentMsg) =>
              currentMsg.concat([`Row ${i} - ${csvProductValidated.error}`]),
            );
          } else if (matchingProducts.length > 0) {
            setProductErrorMsg((currentMsg) =>
              currentMsg.concat([
                `Row ${i} - product already exists with name ${validatedProduct.Name} and brand ${validatedProduct.Brand}`,
              ]),
            );
          } else {
            processedData = processedData.concat(validatedProduct);
            const validatedProductWarning = productWarning(validatedProduct);
            if (validatedProductWarning) {
              setWarningMsg((currentMsg) =>
                currentMsg.concat([`Row ${i} - ${validatedProductWarning}`]),
              );
            }
          }
        });
        if (processedData.length > 0) {
          setNewProductsToAdd(processedData);
        }
      },
    },
  );

  async function submitCreateProducts(newProducts: NewProduct[]) {
    let results: FetchResult[] = [];
    for (const product of newProducts) {
      const categoryId = getCategoryId(
        product.Category,
        categories.productCategories.nodes,
      );

      const result = await graphqlClient.mutate({
        mutation: CreateProductMutation,
        variables: {
          product: mapToMutationProduct(product, producerId, categoryId),
        },
      });

      const newProductId: number =
        result &&
        result.data &&
        result.data.createProduct &&
        result.data.createProduct.product &&
        result.data.createProduct.product.id;

      if (product['Product Feature']) {
        const existingFeature = features.productFeatures.nodes.filter(
          (n) => n.name == product['Product Feature'],
        );
        const productFeatureProduct = {
          productId: newProductId,
          productFeatureId: existingFeature[0].id,
        };
        await graphqlClient.mutate({
          mutation: CreateProductFeatureProductMutation,
          variables: {
            productFeatureProduct,
          },
        });
      }
      if (product['Component Base']) {
        const existingComponent = components.productComponentBases.nodes.filter(
          (n) => n.name == product['Component Base'],
        );
        const productComponentBaseProduct = {
          productId: newProductId,
          productComponentBaseId: existingComponent[0].id,
        };
        await graphqlClient.mutate({
          mutation: CreateProductComponentBaseProductMutation,
          variables: {
            productComponentBaseProduct,
          },
        });
      }

      results = results.concat([result]);
    }
    setMutationResults(results);
    graphqlClient.resetStore();
  }

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const csvOutput: string = event.target.result as string;
        const csvData = csvParse(csvOutput);

        // Determine any missing columns
        const missingColumns = requiredColumns.filter(
          (c) => csvData.columns.indexOf(c) < 0,
        );
        if (missingColumns.length > 0) {
          setErrorMsg(`Missing required columns: ${missingColumns.join(',')}`);
          return;
        }

        // Determine any unexpected columns
        const unexpectedColumns = csvData.columns.filter(
          (c) => requiredColumns.concat(expectedColumns).indexOf(c) < 0,
        );
        if (unexpectedColumns.length > 0) {
          setWarningMsg([
            `Unexpected columns will be ignored: ${unexpectedColumns
              .slice(0, 3)
              .join(',')}` +
              (unexpectedColumns.length > 3 &&
                ` + ${unexpectedColumns.length - 3} more`),
          ]);
        } else {
          setWarningMsg([]);
        }

        setCSV(csvData);
        searchProductNames({
          variables: { names: csvData.map((d) => d.Name) },
        });

        setErrorMsg(null);
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <PagePaper>
      <PageHeader>{t('navigation.products')}</PageHeader>
      <PageTitle>{t('product.createProductsBulk')}</PageTitle>
      {categoriesLoading || featuresLoading || componentsLoading ? (
        <LoadingScreen />
      ) : (
        <div>
          <form>
            {!file && (
              <CSVLink
                headers={columns.map((i) => i.label)}
                data={[]}
                filename="BulkProuductUploadTemplate.csv"
                asyncOnClick={true}
              >
                <Description color="primary" />
                Download Template
              </CSVLink>
            )}
            <input
              type={'file'}
              id={'csvFileInput'}
              accept={'.csv'}
              onChange={handleOnChange}
            />
            {file && (
              <button
                onClick={(e) => {
                  handleOnSubmit(e);
                }}
              >
                {t('product.importCsv')}
              </button>
            )}
          </form>
        </div>
      )}
      {errorMsg && (
        <span style={{ color: 'red', maxWidth: '100%' }}>{errorMsg}</span>
      )}
      {warningMsg && (
        <Table>
          <TableHead>
            {warningMsg.map((message) => (
              <TableRow key={md5(message)}>
                <TableCell>
                  <b>Warning</b>
                </TableCell>
                <TableCell>
                  <span style={{ color: 'blue' }}>{message}</span>
                </TableCell>
              </TableRow>
            ))}
            {productErrorMsg.map((message) => (
              <TableRow key={md5(message)}>
                <TableCell>
                  <b>Product Error</b>
                </TableCell>
                <TableCell>
                  <span style={{ color: 'orange' }}>{message}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      )}
      {!errorMsg && validProductsToAdd && (
        <div style={{ width: '70vw' }}>
          <DataTable
            columns={Object.keys(validProductsToAdd[0]).map((key) => ({
              selector: (row: NewProduct) => row[key],
              name: key,
              sortable: true,
            }))}
            data={validProductsToAdd}
            responsive
          />
          <MaterialButton
            variant="outlined"
            soft
            teal
            disabled={!!mutationResults}
            onClick={() => submitCreateProducts(validProductsToAdd)}
          >
            Create Products
          </MaterialButton>
        </div>
      )}
      {mutationResults &&
        mutationResults.filter((r) => !r.errors).length > 0 && (
          <Alert type="success">
            {mutationResults.filter((r) => !r.errors).length} product(s) created
            successfully.
          </Alert>
        )}
      {mutationResults &&
        mutationResults.filter((r) => !!r.errors).length > 0 && (
          <Alert type="error">
            {mutationResults.filter((r) => !!r.errors).length} product(s) could
            not be created.
          </Alert>
        )}
    </PagePaper>
  );
};

export default ProductCreateBulk;
