import { gql } from '@apollo/client';

export default gql`
query ProjectById($projectId: Int!) {
  project: projectById(id: $projectId) {
    id
    name
    workspaceId
    createdDate
    approved
  }
}
`