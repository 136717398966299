type TreeItem<T> = T & { children: TreeItem<T>[] };

export default function buildTree<T>(array: T[], elementKey: keyof T, parentKey: keyof T): TreeItem<T> {
  let dataTree = {} as TreeItem<T>;
  
  // Build lookup hash in case array is not sorted
  const hashTable = Object.create(null);
  array.forEach(item => hashTable[item[elementKey]] = {...item, children: [] });

  // Build tree
  array.forEach(item => {
    if(typeof item[parentKey] !== 'undefined' ) {
      hashTable[item[parentKey]].children.push(hashTable[item[elementKey]])
    } else {
      dataTree = hashTable[item[elementKey]];
    }
  });
  return dataTree;
}