import { gql } from '@apollo/client';

export default gql`
  query OrderOfOperations($reportID: UUID) {
    allRpOptSteps(filter: { reportId: { equalTo: $reportID } }) {
      nodes {
        id
        reportId
        ggvar
        refFlavor
        deltaPq
        deltaSignature
        deltaDecomp
        stepIdx
        productId
        version
        ld1
        ld2
        productByProductId {
          name
        }
      }
    }
  }
`;
