import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuContainer: {
      display: 'flex',
      flexDirection: 'row',

      '&:hover $accessory': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    accessory: {
      width: '3px',
      backgroundColor: 'white',
    },
    menuItem: {
      fontFamily: 'OpenSans, sans-serif;',
      fontSize: '12px',
      lineHeight: '19px',
      color: theme.palette.primary.main,
      padding: '8px',

      '&:hover': {
        fontWeight: 'bold',
      },
    },
    textContainer: {
      flex: '1',
      margin: '0 15px',
      padding: '10px 0',
      borderBottom: `solid 1px ${theme.palette.tableRow.main}`,
    },
    notificationContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: '1',
      margin: '0 15px',
      padding: '10px 0',
      borderBottom: `solid 1px ${theme.palette.tableRow.main}`,
    },
    allNotification: {
      textAlign: 'center',
      flex: '1',
      margin: '0 15px',
      padding: '10px 0',
      borderBottom: 'none',
    },
    noNotification: {
      textAlign: 'center',
      flex: '1',
      margin: '0 15px',
      padding: '10px 0',
      borderBottom: 'none',
      fontFamily: 'OpenSans, sans-serif',
      fontSize: '12px',
      lineHeight: '19px',
      color: theme.palette.primary.main,
    },
  }),
);

export default useStyles;
