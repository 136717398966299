import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import styles from './AppNavigatorAuthenticated.module.css';
import ReportPairedComparisonPreference from '../../components/Report/ReportPairedComparisonPreference';
import {
  ADMIN,
  SUPERADMIN,
  SUPERADMIN_DATA_EXPLORER,
  AUTHENTICATION,
  BATCH_PRODUCT_REVIEWS,
  DASHBOARD,
  HOME,
  PANELS,
  PANELS_EXPIRED,
  PANEL,
  PANEL_CREATE,
  PANEL_EDIT,
  PANEL_PRODUCT_REVIEWS,
  PANEL_SEARCH,
  PRODUCTS,
  PRODUCT_CREATE,
  PRODUCT_CREATE_BULK,
  PRODUCT,
  PRODUCT_REVIEWS,
  PRODUCT_REVIEW,
  USERS,
  USER,
  REQUEST_REPORT,
  REQUEST_REPORT_UPDATE,
  SUPERADMIN_MANAGE_DEVICES,
  SUPERADMIN_ADD_DEVICES,
  LANGUAGES,
  SELECT_WORKSPACE,
  REPORTS_QA,
  REPORT_DASHBOARD,
  NOTIFICATION,
  DATA_QUALITY_DASHBOARD,
  SUPERADMIN_PRODUCT_FILTER,
  SUPERADMIN_GROUP_WORKSPACES,
  SUPERADMIN_EDIT_GROUP_WORKSPACE,
  PANELISTS,
  USERS_DEMOGRAPHIC,
  CUSTOM_LEXICON,
  PROJECTS,
  PROJECT_CREATE,
  PROJECT,
  PROJECT_SUMMARY_BY_ID,
  PROJECT_SUMMARY_WITH_REPORT,
  PROJECT_FLAVOR_SUMMARY_CHART,
  PROJECT_TEXTURE_SUMMARY_CHART,
  PROJECT_EDIT,
  REPORT_PRODUCT_SUMMARY,
  PROJECT_COMPARISON,
  PROJECT_COMPARISON_WITH_PARAMS,
  PAIRED_COMPARISON_PREFERENCE,
  SUPERADMIN_EDIT_USER_ROLE,
  ADMIN_EDIT_USER_ROLE,
  PQ_MODEL_PLAYGROUND,
} from '../../constants/routePaths';
import VerticalNavigationMenuContainer from '../../containers/VerticalNavigationMenu';
import AdminDataExplorerContainer from '../AdminDataExplorer';
import AdminToolsContainer from '../AdminTools';
import AppHeaderContainer from '../AppHeader';
import ConditionViewerRoleContainer from '../ConditionViewerRole';
// Route components
import CustomLexiconContainer from '../CustomLexicon';
import DashboardContainer from '../Dashboard';
import DataQualityDashboardContainer from '../DataQualityDashboard';
import DeviceCreateContainer from '../DeviceCreate';
import ManageDevicesContainer from '../DeviceList';
import EditUserRoleContainer from '../EditUserRoles';
import GroupWorkspaceContainer from '../GroupWorkspace';
import GroupWorkspaceRedux from '../GroupWorkspaceRedux';
import LanguageSelectionContainer from '../LanguageSelection';
import NotFoundContainer from '../NotFound';
import NotificationContainer from '../Notification';
import PanelContainer from '../Panel';
import PanelCreateContainer from '../PanelCreate';
import PanelCurrentContainer from '../PanelCurrent';
import PanelEditContainer from '../PanelEdit';
import PanelPastContainer from '../PanelPast';
import PanelSearchContainer from '../PanelSearchResults';
import PanelUserContainer from '../PanelUser';
import PQModelPlayground from '../PQModelPlayground';
import ProductContainer from '../Product';
import ProductCreateContainer from '../ProductCreate';
import ProductCreateBulkContainer from '../ProductCreateBulk';
import ProductFilterContainer from '../ProductFilter';
import ProductListContainer from '../ProductList';
import ProductReviewContainer from '../ProductReview';
import ProductReviewListContainer from '../ProductReviewList';
import ProjectContainer from '../Project';
import ProjectComparisonContainer from '../ProjectComparison';
import ProjectCreateContainer from '../ProjectCreateEdit';
import ProjectSummaryContainer from '../ProjectSummary';
import ProjectFlavorSummaryChartContainer from '../ProjectSummary/tabs/ProjectFlavorSummaryChart';
import ProjectTextureSummaryChartContainer from '../ProjectSummary/tabs/ProjectTextureSummaryChart';
import ReportDashboardContainer from '../ReportDashboard';
import ReportProductDetailsContainer from '../ReportProjectDetails';
import ReportsQAContainer from '../ReportQAList';
import ReportRequestContainer from '../RequestReport';
import ReportRequestUpdateContainer from '../RequestReportUpdate';
import SelectWorkspace from '../SelectWorkspace';
import SuperadminToolsContainer from '../SuperadminTools';
import UserContainer from '../User';
import UserDemographicList from '../UserDemographicList';
import UserListContainer from '../UserList';

const generalRoutes = [
  <Route
    key="general-authentication"
    path={AUTHENTICATION}
    render={() => <Redirect to={SELECT_WORKSPACE} />}
  />,
  <Route
    key="general-data-quality-dashboard"
    path={DATA_QUALITY_DASHBOARD}
    render={(props) => <DataQualityDashboardContainer {...props} />}
  />,
  <Route
    key="general-dashboard"
    path={DASHBOARD}
    component={DashboardContainer}
  />,
  <Route
    key="general-select-workspace"
    path={SELECT_WORKSPACE}
    component={(props) => <SelectWorkspace />}
  />,
  <Route
    key="general-languages"
    path={LANGUAGES}
    render={(props) => (
      <LanguageSelectionContainer {...props} title="Select Language" />
    )}
  />,
  <Route
    key="general-notification"
    path={NOTIFICATION}
    render={(props) => (
      <NotificationContainer {...props} title="Notification" />
    )}
  />,
  <Route
    key="my-profile"
    path={USER}
    render={(props) => <UserContainer {...props} title="My Profile" />}
  />,
];

const workspaceAdminRoutes = [
  <Route key="workspaceAdmin-user" path={USER} component={UserContainer} />,
  <Route
    key="workspaceAdmin-users"
    path={USERS}
    render={(props) => <UserListContainer {...props} title="All Users" />}
  />,
  <Route
    key="workspaceAdmin-home"
    exact
    path={HOME}
    render={(props) => <UserListContainer {...props} title="All Users" />}
  />,
];

const panelManageRoutes = [
  <Route
    key="panelManage-product-create"
    path={PRODUCT_CREATE}
    render={(props) => <ProductCreateContainer {...props} />}
  />,
  <Route
    key="panelManage-product-create-bulk"
    path={PRODUCT_CREATE_BULK}
    render={(props) => <ProductCreateBulkContainer {...props} />}
  />,
  <Route
    key="panelManage-product"
    path={PRODUCT}
    component={ProductContainer}
  />,
  <Route
    key="panelManage-batch-product-reviews"
    path={BATCH_PRODUCT_REVIEWS}
    render={(props) => (
      <ProductReviewListContainer
        {...props}
        batchId={props.match.batchId}
        title={`Batch ${props.match.batchId} Product Reviews`}
      />
    )}
  />,
  <Route
    key="panelManage-panel-product-reviews"
    path={PANEL_PRODUCT_REVIEWS}
    render={(props) => (
      <ProductReviewListContainer
        {...props}
        panelId={props.match.panelId}
        title={`Panel ${props.match.panelId} Product Reviews`}
      />
    )}
  />,
  <Route
    key="panelManage-product-review"
    path={PRODUCT_REVIEW}
    component={ProductReviewContainer}
  />,
  <Route
    key="panelManage-product-reviews"
    path={PRODUCT_REVIEWS}
    component={ProductReviewListContainer}
  />,
  <Route
    key="panelManage-products"
    path={PRODUCTS}
    render={(props) => <ProductListContainer {...props} title="All Products" />}
  />,
  <Route
    key="panelManage-panel-create"
    path={PANEL_CREATE}
    render={(props) => <PanelCreateContainer {...props} />}
  />,
  <Route
    key="panelManage-panel-edit"
    path={PANEL_EDIT}
    render={(props) => <PanelEditContainer {...props} />}
  />,
  <Route
    key="panelManage-panel"
    path={PANEL}
    render={(props) => <PanelContainer {...props} title="Panel" />}
  />,
  <Route
    key="panelManage-panel-search"
    path={PANEL_SEARCH}
    render={(props) => <PanelSearchContainer {...props} />}
  />,
  <Route
    key="panelManage-panels"
    path={PANELS}
    render={(props) => <PanelCurrentContainer {...props} />}
  />,
  <Route
    key="panelManage-panels-expired"
    path={PANELS_EXPIRED}
    render={(props) => <PanelPastContainer {...props} />}
  />,
  <Route
    key="panelManage-panelists"
    path={PANELISTS}
    render={(props) => <PanelUserContainer {...props} title="All Panelists" />}
  />,
  <Route
    key="panelManage-home"
    exact
    path={HOME}
    render={(props) => <ProductListContainer {...props} title="All Products" />}
  />,
];

const analysisViewRoutes = [
  <Route
    key="analysisView-project-create"
    path={PROJECT_CREATE}
    render={(props) => <ProjectCreateContainer {...props} />}
  />,
  <Route
    key="analysisView-project-edit"
    path={PROJECT_EDIT}
    render={(props) => <ProjectCreateContainer {...props} />}
  />,
  <Route
    key="analysisView-project-flavor-summary"
    path={PROJECT_FLAVOR_SUMMARY_CHART}
    component={(props) => <ProjectFlavorSummaryChartContainer {...props} />}
  />,
  <Route
    key="analysisView-texture-flavor-summary"
    path={PROJECT_TEXTURE_SUMMARY_CHART}
    component={(props) => <ProjectTextureSummaryChartContainer {...props} />}
  />,
  <Route
    key="analysisView-project-summary-with-report"
    path={PROJECT_SUMMARY_WITH_REPORT}
    component={(props) => <ProjectSummaryContainer {...props} />}
  />,
  <Route
    key="analysisView-project-summary-by-id"
    path={PROJECT_SUMMARY_BY_ID}
    component={(props) => <ProjectSummaryContainer {...props} />}
  />,
  <Route
    key="analysisView-project-comparison-with-params"
    path={PROJECT_COMPARISON_WITH_PARAMS}
    component={(props) => <ProjectComparisonContainer {...props} />}
  />,
  <Route
    key="analysisView-paired-comparison-with-params"
    path={PAIRED_COMPARISON_PREFERENCE}
    component={(props) => <ReportPairedComparisonPreference {...props} />}
  />,
  <Route
    key="analysisView-project-comparison"
    path={PROJECT_COMPARISON}
    component={(props) => <ProjectComparisonContainer {...props} />}
  />,
  <Route
    key="analysisView-report-product-summary"
    path={REPORT_PRODUCT_SUMMARY}
    component={(props) => <ReportProductDetailsContainer {...props} />}
  />,
  <Route
    key="analysisView-project"
    path={PROJECT}
    component={NotFoundContainer}
  />,
  <Route
    key="analysisView-projects"
    path={PROJECTS}
    component={ProjectContainer}
  />,
  <Route
    key="analysisView-home"
    exact
    path={HOME}
    render={(props) => <ProjectContainer {...props} />}
  />,
];

const productReviewRoutes = [
  <Route
    key="productReview-panel-product-reviews"
    path={PANEL_PRODUCT_REVIEWS}
    render={(props) => (
      <ProductReviewListContainer
        {...props}
        panelId={props.match.panelId}
        title={`Panel ${props.match.panelId} Product Reviews`}
      />
    )}
  />,
  <Route
    key="productReview-product-review"
    path={PRODUCT_REVIEW}
    component={ProductReviewContainer}
  />,
  <Route
    key="productReview-product-reviews"
    path={PRODUCT_REVIEWS}
    component={ProductReviewListContainer}
  />,
];

const partnerAdminOnlyRoutes = [
  <Route
    key="partnerAdminOnly-request-report"
    path={REQUEST_REPORT}
    component={ReportRequestContainer}
  />,
  <Route
    key="partnerAdminOnly-request-report-update"
    path={REQUEST_REPORT_UPDATE}
    component={ReportRequestUpdateContainer}
  />,
];

const adminOnlyRoutes = [
  <Route
    key="adminOnly-project-create"
    path={PROJECT_CREATE}
    render={(props) => <ProjectCreateContainer {...props} />}
  />,
  <Route
    key="adminOnly-project-edit"
    path={PROJECT_EDIT}
    render={(props) => <ProjectCreateContainer {...props} />}
  />,
  <Route
    key="adminOnly-project-flavor-summary-chart"
    path={PROJECT_FLAVOR_SUMMARY_CHART}
    component={(props) => <ProjectFlavorSummaryChartContainer {...props} />}
  />,
  <Route
    key="adminOnly-project-summary-with-report"
    path={PROJECT_SUMMARY_WITH_REPORT}
    component={(props) => <ProjectSummaryContainer {...props} />}
  />,
  <Route
    key="adminOnly-project-summary-by-id"
    path={PROJECT_SUMMARY_BY_ID}
    component={(props) => <ProjectSummaryContainer {...props} />}
  />,
  <Route
    key="adminOnly-project-comparison-with-params"
    path={PROJECT_COMPARISON_WITH_PARAMS}
    component={(props) => <ProjectComparisonContainer {...props} />}
  />,
  <Route
    key="adminOnly-project-comparison"
    path={PROJECT_COMPARISON}
    component={(props) => <ProjectComparisonContainer {...props} />}
  />,
  <Route
    key="adminOnly-admin-edit-user-role"
    path={ADMIN_EDIT_USER_ROLE}
    component={EditUserRoleContainer}
  />,
  <Route
    key="adminOnly-admin"
    exact
    path={ADMIN}
    component={AdminToolsContainer}
  />,
  <Route
    key="adminOnly-project"
    path={PROJECT}
    component={NotFoundContainer}
  />,
  <Route
    key="adminOnly-projects"
    path={PROJECTS}
    component={ProjectContainer}
  />,
  <Route
    key="adminOnly-home"
    exact
    path={HOME}
    render={(props) => <ProjectContainer {...props} />}
  />,
];

const superAdminOnlyRoutes = [
  <Route
    key="superAdmin-reports-qa"
    path={REPORTS_QA}
    component={ReportsQAContainer}
  />,
  <Route
    key="superAdmin-users-demographic"
    path={USERS_DEMOGRAPHIC}
    component={UserDemographicList}
  />,
  <Route
    key="superAdmin-product-filter"
    path={SUPERADMIN_PRODUCT_FILTER}
    component={ProductFilterContainer}
  />,
  <Route
    key="superAdmin-group-workspaces"
    path={SUPERADMIN_GROUP_WORKSPACES}
    component={GroupWorkspaceContainer}
  />,
  <Route
    key="superAdmin-edit-group-workspace"
    path={SUPERADMIN_EDIT_GROUP_WORKSPACE}
    component={GroupWorkspaceRedux}
  />,
  <Route
    key="superAdmin-edit-user-role"
    path={SUPERADMIN_EDIT_USER_ROLE}
    component={EditUserRoleContainer}
  />,
  <Route
    key="superAdmin-request-report"
    path={REQUEST_REPORT}
    component={ReportRequestContainer}
  />,
  <Route
    key="superAdmin-request-report-update"
    path={REQUEST_REPORT_UPDATE}
    component={ReportRequestUpdateContainer}
  />,
  <Route
    key="superAdmin-custom-lexicon"
    path={CUSTOM_LEXICON}
    component={CustomLexiconContainer}
  />,
  <Route
    key="pq-model-playground"
    path={PQ_MODEL_PLAYGROUND}
    component={PQModelPlayground}
  />,
  <Route
    key="superAdmin-report-dashboard"
    path={REPORT_DASHBOARD}
    component={ReportDashboardContainer}
  />,
  <Route
    key="superAdmin-data-explorer"
    path={SUPERADMIN_DATA_EXPLORER}
    component={AdminDataExplorerContainer}
  />,
  <Route
    key="superAdmin-add-devices"
    exact
    path={SUPERADMIN_ADD_DEVICES}
    render={(props) => <DeviceCreateContainer {...props} />}
  />,
  <Route
    key="superAdmin-manage-devices"
    path={SUPERADMIN_MANAGE_DEVICES}
    render={(props) => (
      <ManageDevicesContainer {...props} title="Manage Devices" />
    )}
  />,
  <Route
    key="superAdmin-tools"
    path={SUPERADMIN}
    component={SuperadminToolsContainer}
  />,
  <Route
    key="superAdmin-home"
    exact
    path={HOME}
    render={(props) => <SuperadminToolsContainer {...props} />}
  />,
];

const miscRoutes = [
  <Route key="miscRoutes-not-found" component={NotFoundContainer} />,
];

const AppNavigatorAuthenticatedContainer = ({ workspaceProducerId }) => {
  return workspaceProducerId === undefined ? (
    <SelectWorkspace />
  ) : (
    <div className={styles.container}>
      <AppHeaderContainer />
      <div className={styles.contentsRow}>
        <ConditionViewerRoleContainer
          render={(viewerRoles) => (
            <VerticalNavigationMenuContainer
              className={styles.verticalNavigationMenu}
              workspaceProducerId={workspaceProducerId}
              viewerRoleIsAdmin={viewerRoles.viewerRoleIsAdmin}
              viewerRoleIsSuperadmin={viewerRoles.viewerRoleIsSuperadmin}
              viewerRoleIsWorkspaceAdmin={
                viewerRoles.viewerRoleIsWorkspaceAdmin
              }
              viewerRoleIsPanelManage={viewerRoles.viewerRoleIsPanelManage}
              viewerRoleIsTextureAnalysis={
                viewerRoles.viewerRoleIsTextureAnalysis
              }
              viewerRoleIsAnalysisView={viewerRoles.viewerRoleIsAnalysisView}
              viewerRoleIsProductReview={viewerRoles.viewerRoleIsProductReview}
              viewerRoleIsPartnerAdmin={viewerRoles.viewerRoleIsPartnerAdmin}
            />
          )}
        />
        <div className={styles.contents}>
          <ConditionViewerRoleContainer
            render={(viewerRoles) => (
              <Switch>
                {[
                  ...generalRoutes,
                  viewerRoles.viewerRoleIsPanelManage
                    ? [...panelManageRoutes]
                    : [],
                  viewerRoles.viewerRoleIsWorkspaceAdmin
                    ? [...workspaceAdminRoutes]
                    : [],
                  viewerRoles.viewerRoleIsAnalysisView
                    ? [...analysisViewRoutes]
                    : [],
                  viewerRoles.viewerRoleIsProductReview
                    ? [...productReviewRoutes]
                    : [],
                  viewerRoles.viewerRoleIsPartnerAdmin ||
                  viewerRoles.viewerRoleIsAdmin
                    ? [...adminOnlyRoutes]
                    : [],
                  viewerRoles.viewerRoleIsPartnerAdmin
                    ? [...partnerAdminOnlyRoutes]
                    : [],
                  viewerRoles.viewerRoleIsSuperadmin
                    ? [...superAdminOnlyRoutes]
                    : [],
                  ...miscRoutes,
                ].flat()}
              </Switch>
            )}
          />
        </div>
      </div>
    </div>
  );
};

AppNavigatorAuthenticatedContainer.displayName =
  'AppNavigatorAuthenticatedContainer';

export default AppNavigatorAuthenticatedContainer;
