import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportPQNormsQuery from '@graphql/queries/ReportPQNormsQuery';
import { Box } from '@mui/material';

import DataDownloadTile from '../DataDownloadTile';
import { TileType } from '../DataDownloadTile/DataDownloadTile';

interface Props {
  reportId: string;
}

const PQNormsDataDownload: React.FC<Props> = (props) => {
  const { reportId } = props;

  const { loading, error, data } = useQuery<reports.ReportPQNormsResponse>(
    ReportPQNormsQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const csvData = (norms: reports.ReportPQNormsResponse) => {
    return norms.allRpStackrankNorms.nodes
      .map((n) => ({
        'Norm Type': 'Stack Rank Norms',
        '25th Percentile': n.q1,
        '50th Percentile': n.median,
        '75th Percentile': n.q3,
      }))
      .concat(
        norms.allRpCategoryNorms.nodes.map((n) => ({
          'Norm Type': 'Category Norms',
          '25th Percentile': n.q1,
          '50th Percentile': n.median,
          '75th Percentile': n.q3,
        })),
      );
  };

  if (loading || error || csvData(data).length == 0) {
    return <Box />;
  }

  return (
    <DataDownloadTile
      tileType={TileType.CSV}
      headers={[
        'Norm Type',
        '25th Percentile',
        '50th Percentile',
        '75th Percentile',
      ]}
      csvDataRows={csvData(data)}
      fileName="PQNorms.csv"
      tileName="PQ Norms"
    />
  );
};
export default PQNormsDataDownload;
