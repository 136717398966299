import { gql } from '@apollo/client';

export default gql`
  mutation NotifyPixelRequest(
    $userId: Int!
    $pixelId: String!
    $projectId: Int!
    $workspaceId: Int!
    $reportId: String!
    $pixelPq: Float!
    $createdAt: Date!
  ) {
    notifyPixelRequest(
      input: {
        userId: $userId
        pixelId: $pixelId
        projectId: $projectId
        workspaceId: $workspaceId
        reportId: $reportId
        pixelPq: $pixelPq
        created_at: $createdAt
      }
    )
  }
`;
