import { gql } from '@apollo/client';

export default gql`
  query GetProjectReports($projectID: Int!) {
    project: projectById(id: $projectID) {
      id
      name
      projectReportsByProjectId {
        nodes {
          displayName
          reportJob: reportJobByReportId {
            reportId
            clientName
            passedQa
            projectName
            startedAt
            pdfEfsUri
            targetGroupName
            reportType
            jobId
            parentJobId
            reportSummaries: reportSummariesByReportId {
              nodes {
                version
                productByProductId {
                  id
                  name
                }
              }
            }
          }
        }
      }
      customPqProductNamesByProjectId {
        nodes {
          id
          projectId
          productId
          productName
          version
        }
      }
    }
  }
`;
