import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import routerHistory from './constants/routerHistory';
import store from './constants/store';
import graphqlClient from './consumers/graphqlClient';
import AppRootContainer from './containers/AppRoot';
import ThemeProvider from './ThemeProvider';

const RootElement = () => (
  <ApolloProvider client={graphqlClient}>
    <Router history={routerHistory}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <DndProvider backend={HTML5Backend} context={window}>
              <AppRootContainer />
            </DndProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </Router>
  </ApolloProvider>
);

export default hot(RootElement);
