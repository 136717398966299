import * as React from 'react';
import { useState, useEffect } from 'react';

import { ReferenceFlavorLanguage } from '@graphql/queries/GetReferenceFlavorLanguage';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';

import styles from './CustomLexiconList.module.css';
import CustomLexiconListTable from './CustomLexiconListTable';

export const reShapeReferenceFlavors = (
  languages: ReferenceFlavorLanguage[],
  refFlavors,
) => {
  if (!refFlavors) return [];

  return refFlavors.map((item: { [key: string]: any }) => {
    const itemMap = {};
    languages.forEach((language: ReferenceFlavorLanguage) => {
      itemMap[language.code + 'Enabled'] = !!(
        item[language.code] && item.visible
      );
    });
    return {
      ...item,
      referenceFlavorDeleted: !!item.visible,
      ...itemMap,
    };
  });
};

interface Props {
  name: string;
  referenceFlavors: any[];
  languages: ReferenceFlavorLanguage[];
  groupAndUpdateLexicons: (referenceFlavorNodes: any) => void;
  getUpdatedReferenceFlavorsByAttribute: (attribute: string) => any;
}

const CustomLexiconList: React.FunctionComponent<Props> = (props) => {
  const {
    name,
    referenceFlavors,
    languages,
    groupAndUpdateLexicons,
    getUpdatedReferenceFlavorsByAttribute,
  } = props;

  const [referenceFlavorList, setReferenceFlavorList] = useState([]);
  const [hasExpanded, setHasExpanded] = useState(false);

  useEffect(() => {
    if (referenceFlavors.length) {
      setReferenceFlavorList(
        reShapeReferenceFlavors(languages, referenceFlavors),
      );
    }
  }, []);

  const toggleExpansion = () => {
    setHasExpanded(!hasExpanded);
    if (!hasExpanded) {
      setReferenceFlavorList(
        reShapeReferenceFlavors(
          languages,
          getUpdatedReferenceFlavorsByAttribute(name),
        ),
      );
    }
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.panelName}>{name}</div>
        </div>
        <div className={styles.minimizeContainer}>
          <IconButton onClick={toggleExpansion} size="large">
            {hasExpanded ? (
              <KeyboardArrowUp color="primary" />
            ) : (
              <KeyboardArrowDown color="primary" />
            )}
          </IconButton>
        </div>
      </div>
      {hasExpanded && (
        <div className={styles.tableContainer}>
          <div className="productsWrapper">
            <div className={styles.tableBox}>
              <CustomLexiconListTable
                producersReferenceFlavors={referenceFlavorList}
                languages={languages}
                groupAndUpdateLexicons={groupAndUpdateLexicons}
              />
            </div>
          </div>
        </div>
      )}
      <br />
    </>
  );
};

CustomLexiconList.displayName = 'CustomLexiconList';

export default CustomLexiconList;
