import i18next from "i18next";

export const validateProjectForm = (values) => {
	const errors: any = {};

	if (values.name == '') {
		errors.projectName = i18next.t('project.projectNameMandatory');
	}
	
	return errors;
};
