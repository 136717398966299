import { mapFromKey } from 'constants/ggVars';
import { REPORT_PRODUCT_SUMMARY } from 'constants/routePaths';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import FpDecompByReportIdAndProductIDQuery from '@graphql/queries/FpDecompByReportIdAndProductIDQuery';
import ReportPositiveNegativeDriversQuery from '@graphql/queries/ReportPositiveNegativeDriversQuery';
import { useTheme, Box } from '@mui/material';
import { green, red } from '@mui/material/colors';
import LoadingScreen from 'components/LoadingScreen';
import {
  HighlightableTableRow,
  hightlightedConditionalRowStyles,
} from 'components/ReactDataTable/highlightedRowStyles';
import { lineBreak } from 'components/ReactDataTable/reactDataTableUtils';
import { tableCustomStyles } from 'components/ReactDataTable/tableCustomStyles';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary, { ProductVersionPq } from 'hooks/useReportSummary';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { cleanUnderscore } from 'utils/afsUtils';

import { ProjectTabs } from '../../../services/customerPreferences';
import { ComponentTitle } from '../../../styles/themeComponents';
import formatPath from '../../../utils/formatPath';
import { ProductVersion } from '../ProductVersion';
import { getProductName, isProductHighlighted } from '../utils';

interface ReportProps {
  reportId: string;
  projectId: number;
}

export interface TableRow extends HighlightableTableRow {
  productName: string;
  productId: number;
  version: string;
  pq: number;
  keyFlavors: reports.ReportFpDecompRow[];
  positiveDrivers: constants.GGVar[];
  negativeDrivers: constants.GGVar[];
}

export const getProductDrivers = (
  productVersion: ProductVersion,
  reportFlavorStructureResponse: reports.ReportPositiveNegativeDriversResponse,
  color: 'red' | 'green',
): constants.GGVar[] => {
  return (reportFlavorStructureResponse?.allRpFlavorStructures?.nodes ?? [])
    .filter(
      (n) => n.product.id === productVersion.productId && n.nodeColor === color,
    )
    .map((i) => mapFromKey(i.label));
};

export const getKeyFlavors = (
  productVersion: ProductVersion,
  keyFlavorData: reports.ReportFpDecompResponse,
): reports.ReportFpDecompRow[] => {
  return (keyFlavorData?.allRpFlavorDecomps?.nodes ?? [])
    .filter((n) => n.productByProductId.id === productVersion.productId)
    .sort((a, b) => parseFloat(b.decomp) - parseFloat(a.decomp))
    .slice(0, 3);
};

export const mapToTableRow = (
  productNames: reports.ColorVersionedProductInfo[],
  productVersionPqs: ProductVersionPq[],
  reportFlavorStructureResponse: reports.ReportPositiveNegativeDriversResponse,
  keyFlavorData: reports.ReportFpDecompResponse,
): TableRow[] => {
  return productVersionPqs.map((pn) => ({
    productName: getProductName({
      productNames,
      productId: pn.productVersion.productId,
      version: pn.productVersion.version,
    }),
    isHighlighted: isProductHighlighted({
      productNames,
      productId: pn.productVersion.productId,
      version: pn.productVersion.version,
    }),
    productId: pn.productVersion.productId,
    version: pn.productVersion.version,
    pq: pn.pq,
    keyFlavors: getKeyFlavors(pn.productVersion, keyFlavorData),
    positiveDrivers: getProductDrivers(
      pn.productVersion,
      reportFlavorStructureResponse,
      'green',
    ),
    negativeDrivers: getProductDrivers(
      pn.productVersion,
      reportFlavorStructureResponse,
      'red',
    ),
  }));
};

export const formatDriver = (driver: constants.GGVar): string => driver.label;

export const formatKeyFlavor = (
  keyFlavor: reports.ReportFpDecompRow,
): string => {
  return (
    (parseFloat(keyFlavor.decomp) * 100).toFixed(0) +
    '% ' +
    cleanUnderscore(keyFlavor.referenceFlavor)
  );
};

const StackRankDriversTable: React.FC<ReportProps> = (props) => {
  const { reportId, projectId } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    productVersionPqs,
  } = useReportSummary(reportId, {
    fetchPolicy: 'no-cache',
  });

  const productNames = useCustomProductNames({
    projectId: Number(projectId),
    reportId,
  });

  const {
    loading: reportFlavorStructureLoading,
    error: reportFlavorStructureError,
    data: reportFlavorStructureResponse,
  } = useQuery<reports.ReportPositiveNegativeDriversResponse>(
    ReportPositiveNegativeDriversQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: keyFlavorsLoad,
    error: keyFlavorsError,
    data: keyFlavorData,
  } = useQuery<reports.ReportFpDecompResponse>(
    FpDecompByReportIdAndProductIDQuery,
    { variables: { reportID: reportId } },
  );

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'productName',
      name: lineBreak(t('reports.prodSummary.product')),
      width: null,
      selector: (row: TableRow) => row.productName,
      cell: (row: TableRow) => (
        <Link
          key={row.productName + '-link'}
          to={{
            pathname: formatPath(REPORT_PRODUCT_SUMMARY, {
              projectId: projectId,
              reportId: reportId,
              productId: row.productId,
              version: row.version,
            }),
            state: { routeBackLocation: ProjectTabs.EXECUTIVE_SUMMARY },
          }}
        >
          {row.productName}
        </Link>
      ),
      sortable: true,
    },
    {
      id: 'pq',
      name: lineBreak(t('stackRankComparison.pq')),
      width: '8%',
      selector: (row: TableRow) => row.pq,
      cell: (row: TableRow) => row.pq.toFixed(2),
      sortable: true,
    },
    {
      id: 'keyFlavors',
      name: lineBreak(t('stackRankComparison.keyFlavors')),
      width: '20%',
      cell: (row: TableRow) => (
        <div>
          {row.keyFlavors.map((keyFlavor) => (
            <div key={formatKeyFlavor(keyFlavor)}>
              {formatKeyFlavor(keyFlavor)}
            </div>
          ))}
        </div>
      ),
    },
    {
      id: 'positiveDrivers',
      name: lineBreak(t('stackRankComparison.positiveDrivers')),
      width: '15%',
      cell: (row: TableRow) => (
        <div style={{ color: green[400] }}>
          {row.positiveDrivers.map((positiveDriver) => (
            <div key={formatDriver(positiveDriver)}>
              {formatDriver(positiveDriver)}
            </div>
          ))}
        </div>
      ),
    },
    {
      id: 'negativeDrivers',
      name: lineBreak(t('stackRankComparison.negativeDrivers')),
      width: '15%',
      cell: (row: TableRow) => (
        <div style={{ color: red[400] }}>
          {row.negativeDrivers.map((negativeDriver) => (
            <div key={formatDriver(negativeDriver)}>
              {formatDriver(negativeDriver)}
            </div>
          ))}
        </div>
      ),
    },
  ];

  if (
    reportSummaryLoading ||
    reportSummaryError ||
    reportFlavorStructureLoading ||
    reportFlavorStructureError ||
    keyFlavorsLoad ||
    keyFlavorsError
  ) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <ComponentTitle>{t('stackRankComparison.title')}</ComponentTitle>
      <DataTable
        columns={columns}
        data={mapToTableRow(
          productNames,
          productVersionPqs,
          reportFlavorStructureResponse,
          keyFlavorData,
        )}
        defaultSortFieldId="pq"
        defaultSortAsc={false}
        customStyles={tableCustomStyles(theme)}
        striped
        conditionalRowStyles={hightlightedConditionalRowStyles}
      />
    </Box>
  );
};

export default StackRankDriversTable;
