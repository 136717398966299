import { ggVars } from 'constants/ggVars';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportFlavorStructureQuery from '@graphql/queries/ReportFlavorStructureQuery';
import ReportGgVarTuningPlot from '@graphql/queries/ReportGgVarTuningPlot';
import { Box, useTheme } from '@mui/material';
import { ProductVersion } from 'components/Report/ProductVersion';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { ReportSummaryDetailNode } from 'hooks/useReportSummary';
import { chain } from 'lodash';
import { tuningPlotService } from 'services/tuningPlotService';

import { getProductName } from '../../Report/utils';
import useStyles from '../useStyles';

interface Props {
  projectId: number;
  reportId: string;
  product: ReportSummaryDetailNode;
}

const DRIVERS = {
  POSITIVE: 'green',
  NEGATIVE: 'red',
  NEUTRAL: 'black',
};

const PositiveNegativeProductInformation: React.FC<Props> = (props) => {
  const { projectId, reportId, product } = props;

  const classes = useStyles(useTheme());
  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const {
    loading: flavorStructureLoading,
    error: flavorStructureError,
    data: flavorStructureData,
  } = useQuery<reports.ReportFlavorStructureResponse>(
    ReportFlavorStructureQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: tuningPlotLoading,
    error: tuningPlotError,
    data: tuningPlotData,
  } = useQuery<reports.GgVarTuningPlotResponse>(ReportGgVarTuningPlot, {
    variables: {
      reportID: reportId,
      productID: product?.node.productByProductId.id,
    },
  });

  const computeDriversReport = (
    data: reports.ReportFlavorStructureResponse,
    productId: number,
  ) => {
    const labelColorData = chain(data.allRpFlavorStructures.nodes)
      .filter(
        (value: reports.ReportFlavorStructure) =>
          value.label != null && value.product?.id === productId,
      )
      .map((item: reports.ReportFlavorStructure) => ({
        color: item.nodeColor,
        label: item.label,
      }))
      .value();

    const deDupe = (acc, curr) => {
      if (!acc.includes(curr)) acc.push(curr);
      return acc;
    };

    return {
      positiveDrivers: labelColorData
        .reduce((acc: string[], item: reports.LabelDriversResult) => {
          item.color === DRIVERS.POSITIVE &&
            acc.push(ggVars.find((f) => f.key == item.label).label);
          return acc;
        }, [])
        .reduce(deDupe, []),
      negativeDrivers: labelColorData
        .reduce((acc: string[], item: reports.LabelDriversResult) => {
          item.color === DRIVERS.NEGATIVE &&
            acc.push(ggVars.find((f) => f.key == item.label).label);
          return acc;
        }, [])
        .reduce(deDupe, []),
      neutralDrivers: labelColorData
        .reduce((acc: string[], item: reports.LabelDriversResult) => {
          item.color === DRIVERS.NEUTRAL &&
            acc.push(ggVars.find((f) => f.key == item.label).label);
          return acc;
        }, [])
        .reduce(deDupe, []),
    };
  };

  const computeImprovements = (
    tuningPlotData: reports.GgVarTuningPlotResponse,
    productId: number,
    version: string,
  ) => {
    const extrema = tuningPlotService.extremaData(
      tuningPlotData,
      new ProductVersion(productId, version),
      true,
    );

    return extrema.map((e) => ({
      ggVar: e.ggvar,
      minCategory: tuningPlotService.categorizeExtrema(e.minExtrema),
      maxCategory: tuningPlotService.categorizeExtrema(e.maxExtrema),
    }));
  };

  if (
    flavorStructureLoading ||
    flavorStructureError ||
    tuningPlotLoading ||
    tuningPlotError
  ) {
    return <div />;
  }

  const productId = product?.node.productByProductId.id;
  const version = product?.node.version;

  const drivers = computeDriversReport(flavorStructureData, productId);
  const improvements = computeImprovements(tuningPlotData, productId, version)
    .filter(
      (ed) =>
        ed.maxCategory === '+' ||
        ed.maxCategory === '++' ||
        ed.maxCategory === '+++',
    )
    .sort((a, b) => b.maxCategory.length - a.maxCategory.length)
    .map((i) => i.ggVar.label);

  return (
    <Box>
      <span className={classes.mainText}>
        {getProductName({
          productNames: productNames,
          productId: productId,
          version: version,
        })}
        :
      </span>
      <ul>
        {drivers.positiveDrivers.length > 0 && (
          <li>
            <span style={{ fontStyle: 'italic' }}>Positive drivers:&nbsp;</span>
            {drivers.positiveDrivers.join(', ')}
          </li>
        )}
        {drivers.negativeDrivers.length > 0 && (
          <li>
            <span style={{ fontStyle: 'italic' }}>Negative drivers:&nbsp;</span>
            {drivers.negativeDrivers.join(', ')}
          </li>
        )}
        {improvements.length > 0 && (
          <li>
            <span style={{ fontStyle: 'italic' }}>
              Improvement recommendations:&nbsp;
            </span>
            {`Increasing ${improvements.join(', ')} could improve preference.`}
          </li>
        )}
      </ul>
    </Box>
  );
};

export default PositiveNegativeProductInformation;
