import {
  CustomerPreferences,
  getLabelArray,
  getVectorLength,
} from 'services/customerPreferences';

import { ProductVersionSet, ProductVersion } from '../ProductVersion';

const numbers = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '70',
  '71',
  '72',
  '73',
  '74',
  '75',
  '76',
  '77',
  '78',
  '79',
  '80',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '90',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
  '100',
];

/**
 * Determine label for versioned productId
 *
 * @param customerPreferences customerPreference object
 * @param productIds array of underlying product ids on the map
 * @param productId specific productId looking to get labeled
 * @returns label for specific productId
 */
export const mapLabelVersion = (
  customerPreferences: CustomerPreferences,
  productKeys: ProductVersionSet,
  productKey: ProductVersion,
  offset?: number,
): string => {
  offset = offset ?? 0;
  if (typeof productKey === 'undefined' || productKey === null) {
    return '?';
  }

  const labelArray = getLabelArray(customerPreferences);

  if (productKeys.size + offset > labelArray.length) {
    return numbers[productKeys.findIndex(productKey) + offset];
  } else {
    return labelArray[productKeys.findIndex(productKey) + offset];
  }
};

export const simpleProductTooltip = (
  productName: string,
  productLabel: string,
): string => {
  return (
    '<div class="prodtext">' +
    productName +
    ' (' +
    productLabel +
    ')' +
    '</div>'
  );
};

/**
 * Tooltip to show when hovering over a product label on the map
 *
 * @param visibleProducts
 * @param productName
 * @param productPq
 * @param meanPq
 * @param colors
 * @returns
 */
export const productTooltip = (
  productsToShow: {
    productName: string;
    productPq: number;
    productLabel: string;
  }[],
  meanPq: number,
  colors: d3.ScaleLinear<string, string, never>,
): string => {
  return productsToShow
    .map((p) => {
      if (typeof p.productPq === 'undefined' || p.productPq === null) {
        return (
          '<div class="prodtext">' +
          p.productName +
          ' (' +
          p.productLabel +
          ')' +
          '</div>'
        );
      } else {
        return (
          '<div class="prodtext">' +
          p.productName +
          ' (' +
          p.productLabel +
          ')' +
          '</div>' +
          '<div class="pq">' +
          '<div class="pqtext">' +
          p.productPq.toFixed(2) +
          '</div>' +
          '<div class="pqcircle" style="background-color: ' +
          colors(p.productPq - meanPq) +
          ';"> </div>' +
          '</div>'
        );
      }
    })
    .join('');
};

/**
 * Tooltip to show when hovering over a vector label
 *
 * @param customerPreferences customerPreference object
 * @param  vectorName
 * @param  vectorLength
 * @param  zoom
 * @returns
 */
export const vectorTooltip = (
  customerPreferences: CustomerPreferences,
  vectorName: string,
  vectorLength: number,
  zoom: number,
): string => {
  return (
    '<div class="prodtext">' +
    vectorName +
    '</div>' +
    '<div class="pq">' +
    '<div class="pqtext">' +
    getVectorLength(customerPreferences, vectorLength, zoom) +
    '</div>' +
    '</div>'
  );
};

/**
 *
 * @param projectId
 * @param reportId
 * @param productVersion
 * @param productLabel
 * @returns
 */
export const productTooltipLink = (
  projectId: number,
  reportId: string,
  productVersion: ProductVersion,
  productLabel: string,
) => {
  if (
    typeof projectId === 'undefined' ||
    projectId === null ||
    productVersion == null
  ) {
    return productLabel;
  } else {
    return (
      '<a class="productLink" href="/productSummary/' +
      projectId +
      '/' +
      reportId +
      '/' +
      productVersion.productId?.toFixed(0) +
      '/' +
      productVersion.version +
      '">' +
      productLabel +
      '</a>'
    );
  }
};
