import ggVars from 'constants/ggVars';

import { ProductVersion } from 'components/Report/ProductVersion';
import {
  ExtremaRow,
  SmoothedRow,
  computeMaxExtrema,
  computeMinExtrema,
  loessSmooth,
  mapJsonB,
} from 'services/utils/tuningPlotUtils';

export const intensities: reports.Intensity[] = [
  {
    label: '---',
    min: Number.NEGATIVE_INFINITY,
    max: -0.5,
    tooltip: 'PQ < -0.5',
  },
  { label: '--', min: -0.5, max: -0.2, tooltip: '-0.5 <= PQ < -0.2' },
  { label: '-', min: -0.2, max: -0.05, tooltip: '-0.2 <= PQ < -0.05' },
  { label: '=', min: -0.05, max: 0.05, tooltip: '-0.05 <= PQ <= 0.05' },
  { label: '+', min: 0.05, max: 0.2, tooltip: '0.05 < PQ <= 0.2' },
  { label: '++', min: 0.2, max: 0.5, tooltip: '0.2 < PQ <= 0.5' },
  {
    label: '+++',
    min: 0.5,
    max: Number.POSITIVE_INFINITY,
    tooltip: '0.5 < PQ',
  },
];

export const tuningPlotService = {
  nonZeroRows,
  extremaData,
  categorizeExtrema,
};

/**
 *
 * @param input data from graphql query
 * @param selectedProduct selected product of interest
 * @returns reports.GgVarTuningPlotRow[] that are non-zero
 */
function nonZeroRows(
  input: reports.GgVarTuningPlotResponse,
  selectedProduct: ProductVersion,
) {
  return (
    input?.allRpGgvarFlavorProfiles.nodes.filter(
      (ggRow) =>
        ggRow.currentIntensity != 0 &&
        ggRow.productId === selectedProduct.productId &&
        ggRow.version === selectedProduct.version,
    ) || []
  );
}

/**
 * Take a row from tuning plots in, select matching GGVar, and smooth data
 * @param row tuning plot data
 * @returns smoothed and typed data
 */
function selectAndSmooth(
  row: reports.GgVarTuningPlotRow,
  ggVars: constants.GGVar[],
) {
  return {
    ggvar: ggVars.find((g) => g.key === row.ggvar),
    productId: row.productId,
    version: row.version,
    reportId: row.reportId,
    currentIntensity: row.currentIntensity,
    data: loessSmooth(mapJsonB(row.dataJsonb), 23, 10),
  };
}

/**
 * Take a smoothed row and calculate the extremas
 * @param row smoothed tuning plot row
 * @param isLocal is the extrema value local or distant
 * @returns ExtremaRow
 */
function calcExtremas(row: SmoothedRow, isLocal: boolean): ExtremaRow {
  return {
    data: row.data,
    currentIntensity: row.currentIntensity,
    ggvar: row.ggvar,
    productId: row.productId,
    version: row.version,
    reportId: row.reportId,
    minExtrema: computeMinExtrema(row, isLocal),
    maxExtrema: computeMaxExtrema(row, isLocal),
  };
}

/**
 * Pipeline function to take in graphql data and return structured extrema data from tuning plots
 * @param input data from graphql query
 * @param selectedProduct selected product of interest
 * @param isLocal is the extrema value local or distant
 * @returns ExtremaRow[]
 */
function extremaData(
  input: reports.GgVarTuningPlotResponse,
  selectedProduct: ProductVersion,
  isLocal: boolean,
): ExtremaRow[] {
  return nonZeroRows(input, selectedProduct)
    .map((row) => selectAndSmooth(row, ggVars))
    .map((row) => calcExtremas(row, isLocal));
}

/**
 * Classify the value into a PqIntensity object
 * @param value input value
 * @returns related PqIntensity object
 */
function categorizeExtrema(value: number): reports.PqIntensity {
  return intensities.find((i) => {
    if (value < 0) {
      return i.min <= value && value < i.max;
    } else {
      return i.min < value && value <= i.max;
    }
  }).label;
}
