import { useState, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';

export interface ClientListOptions extends SelectItem {
  partnerId: number;
}

interface ClientNameRecord {
  name: string;
  partnerId: number;
}

interface ClientResults {
  allClients: {
    nodes: Array<ClientNameRecord>;
  };
}

const query = gql`
  query getClients {
    allClients {
      nodes {
        name
        partnerId
      }
    }
  }
`;

const useClientList = (): ClientListOptions[] => {
  const [clientNames, setClientNames] = useState<ClientListOptions[]>([]);
  const { data, loading, error } = useQuery<ClientResults>(query);

  useEffect(() => {
    if (!loading && !error && data) {
      const names = data.allClients.nodes.map((d: ClientNameRecord) => ({
        label: d.name,
        value: d.name,
        partnerId: d.partnerId,
      }));
      setClientNames(names);
    }
  }, [data, loading, error]);

  return clientNames;
};

export default useClientList;
