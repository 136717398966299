import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import AllReportsQuery from '@graphql/queries/AllReportsQuery';

/** START: Manage the query in scaled increments for performance */
/** END: Manage the query in scaled increments for performance */

const useAllReports = () => {
  const [offset, setOffset] = useState<number>(0);
  const [size, setSize] = useState<number>(2);

  const [loadingMore, setLoadingMore] = useState<boolean>(true);

  const [reportJobsResults, setReportJobsResults] = useState<
    reports.AllReportsQueryRow[]
  >([]);

  const { loading, error, data, fetchMore, refetch } =
    useQuery<reports.AllReportsQueryResponse>(AllReportsQuery, {
      variables: {
        orderBy: 'ID_DESC',
        offset: offset,
        first: size,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (!loading && !error && data) {
      const mergedResults = reportJobsResults.concat(...data.reports.nodes);
      setReportJobsResults(mergedResults);

      if (data.reports.nodes.length > 0) {
        setOffset(mergedResults.length);
        setSize(size * 2);
        fetchMore({
          variables: {
            offset: mergedResults.length,
            first: size * 2,
          },
        });
      } else {
        setLoadingMore(false);
      }
    }
  }, [data]);

  return {
    loading: loadingMore,
    error,
    data: reportJobsResults,
    refetch,
  };
};

export default useAllReports;
