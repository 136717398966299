import * as React from 'react';

import { Close } from '@mui/icons-material';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  handleClose: () => void;
  report: reports.AllReportsQueryRow;
  updateReportJobStatus: (
    id: number,
    status: boolean,
    mapOnly: boolean,
    currentData: any[],
  ) => Promise<void>;
  localData: any;
}

const DisapproveReportMenuItem: React.FC<Props> = ({
  handleClose,
  report,
  updateReportJobStatus,
  localData,
}) => {
  const { t } = useTranslation();

  return (
    <MenuItem onClick={handleClose}>
      <ListItemButton
        onClick={() => updateReportJobStatus(report.id, null, false, localData)}
      >
        <ListItemIcon>
          <Close fontSize="small" color="error" />
        </ListItemIcon>
        <ListItemText primary={t('reportsQa.menu.disappoveReport')} />
      </ListItemButton>
    </MenuItem>
  );
};

export default DisapproveReportMenuItem;
