import * as React from 'react';

import { useQuery } from '@apollo/client';
import OrderOfOperationsQuery from '@graphql/queries/OrderOfOperationsQuery';
import { Box } from '@mui/material';
import { getProductName } from 'components/Report/utils';
import { toNumber } from 'lodash';
import { Data } from 'react-csv/lib/core';

import DataDownloadTile from '../DataDownloadTile';
import { TileType } from '../DataDownloadTile/DataDownloadTile';

interface Props {
  projectId: number;
  reportId: string;
  productNames: reports.ColorVersionedProductInfo[];
}

const OrderOfOperationsDownload: React.FC<Props> = (props) => {
  const { productNames, reportId } = props;

  const { data, loading, error } = useQuery<reports.OrderOfOperationsData>(
    OrderOfOperationsQuery,
    { variables: { reportID: reportId } },
  );

  const getCsvHeader = () => [
    { label: 'Product', key: 'product' },
    { label: 'Order', key: 'order' },
    { label: 'Flavor', key: 'flavor' },
    { label: 'Change', key: 'change' },
    { label: 'PQ Lift', key: 'pqLift' },
  ];

  const getCsvRows = (
    data: reports.OrderOfOperationsData,
    productNames: reports.ColorVersionedProductInfo[],
  ): Data => {
    return [...data.allRpOptSteps.nodes]
      .sort(
        (a, b) =>
          a.productId - b.productId ||
          a.version.localeCompare(b.version) ||
          a.stepIdx - b.stepIdx,
      )
      .map((row) => [
        getProductName({
          productNames,
          productId: row.productId,
          version: row.version,
        }),
        row.stepIdx,
        row.refFlavor,
        toNumber(row.deltaSignature).toFixed(2),
        toNumber(row.deltaPq).toFixed(4),
      ]);
  };

  if (loading || error || data.allRpOptSteps.nodes.length === 0) {
    return <Box />;
  }

  return (
    <DataDownloadTile
      tileType={TileType.CSV}
      headers={getCsvHeader().map((h) => h.label)}
      csvDataRows={getCsvRows(data, productNames)}
      fileName="OrderOfOperations.csv"
      tileName="Order of Operations"
    />
  );
};

export default OrderOfOperationsDownload;
