import React, { Suspense } from 'react';

import { CircularProgress, useTheme } from '@mui/material';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import Alert from 'react-s-alert';

import useStyles from './useStyles';
import ActivityIndicator from '../../components/ActivityIndicator';
import AppFooter from '../../components/AppFooter';
import AppNavigatorAnonymousContainer from '../AppNavigatorAnonymous';
import AppNavigatorAuthenticatedContainer from '../AppNavigatorAuthenticated';
import AppToastContainerContainer from '../AppToastContainer';
import ModalContainer from '../ModalContainer';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

type Props = {
  appPersistenceHasLoaded: boolean;
  appViewerIsAuthenticated: boolean;
  workspaceProducerId?: number;
};

const RenderContent = ({ children }) => {
  const classes = useStyles(useTheme());
  return <div className={classes.container}>{children}</div>;
};

const renderAppContents = (props: Props) =>
  props.appViewerIsAuthenticated ? (
    <AppNavigatorAuthenticatedContainer
      workspaceProducerId={props.workspaceProducerId}
    />
  ) : (
    <AppNavigatorAnonymousContainer />
  );

const AppRoot: React.FC<Props> = (props) => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.ga('send', 'pageview', location.pathname);
  }, [location.pathname]);

  if (!props.appPersistenceHasLoaded) {
    return <ActivityIndicator />;
  }

  return (
    <Suspense fallback={<CircularProgress />}>
      <RenderContent>
        {renderAppContents(props)}
        <AppFooter />
        <AppToastContainerContainer />
        <ModalContainer />
        <Alert stack={{ limit: 3 }} />
      </RenderContent>
    </Suspense>
  );
};

export default AppRoot;
