export type ViewerRoles = {
  viewerRoleIsAdmin: boolean;
  viewerRoleIsSuperadmin: boolean;
  viewerRoleIsWorkspaceAdmin: boolean;
  viewerRoleIsPanelManage: boolean;
  viewerRoleIsAnalysisView: boolean;
  viewerRoleIsProductReview: boolean;
  viewerRoleIsTextureAnalysis: boolean;
  viewerRoleIsPartnerAdmin: boolean;
}

type Props = {
  viewerRoles: ViewerRoles;
  render: (
    viewerRoles: ViewerRoles
  ) => JSX.Element;
};

const ConditionViewerRoleContainer = ({
  viewerRoles,
  render
}: Props) =>
  render(
    viewerRoles
  );

ConditionViewerRoleContainer.displayName = 'ConditionViewerRoleContainer';

export default ConditionViewerRoleContainer;