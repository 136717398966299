import { reduxForm } from 'redux-form';

import FormSectionProductConfiguration from './FormSectionProductConfiguration';
import formSubmit from '../../actions/formSubmit';
import { PRODUCT_FORM } from '../../constants/formNames';

export const validation = (val) =>
  !val || (typeof val === 'object' && val.length === 0);
export const validateBool = (val) => !(val === true || val === false);
export const minLenAndReqAndAllNumVal = (val) =>
  !val ||
  (typeof val === 'object' && val.length === 0) ||
  val.length <= 3 ||
  Number(val)
    ? 'Product names should be longer than 3 characters and not all numeric'
    : false;

export default reduxForm({
  form: PRODUCT_FORM,
  onSubmit: (values, dispatch) => {
    dispatch(formSubmit(PRODUCT_FORM));
  },
  validate: (values) => {
    return {
      name: minLenAndReqAndAllNumVal(values.name),
      brand: validation(values.brand),
      prototype: validateBool(values.prototype),
      productCategory: validation(values.productCategory),
      productFeature: validation(values.productFeature),
      productComponentBase: validation(values.productComponentBase),
      allergens: validation(values.allergens),
      restrictions: validation(values.restrictions),
      physicalState: validation(values.physicalState),
      countryOfPurchase: validation(values.countryOfPurchase),
      country: validation(values.country),
    };
  },
  initialValues: {
    public: false,
    aroma: false,
    productFeature: [],
    productComponentBase: [],
    productComponentOther: [],
    productCategory: [],
    texture: true,
    prototype: false,
    questions: [],
    // definedComponents: 'false',
    // definedComponentNames: null,
    // undefinedComponentTotal: { label: 1, value: 1 },
    // allowCustomTextureComponents: false
  },
})(FormSectionProductConfiguration);
