import * as React from 'react';

import FieldImageInput from 'components/FieldImageInput/FieldImageInput';
import { Field } from 'redux-form';

import styles from './ProductImageUpload.module.css';

const ProductImageUpload = ({ defaultImages, name }) => {
  return (
    <div className={styles.fieldContainer}>
      <Field
        name={name}
        component={FieldImageInput}
        defaultImages={defaultImages}
        allowFileSizeValidation
        maxFileSize="200KB"
      />
    </div>
  );
};

export default ProductImageUpload;
