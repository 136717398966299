import { ggVars } from 'constants/ggVars';

import type { ChartData } from 'chart.js';

import { nullCheck } from '../../../utils/afsUtils';

export const toRadarChartData = (
  ggVarValues: number[],
): ChartData<'radar', number[], string> => {
  return {
    labels: ggVars.map((v) => v.label),
    datasets: [
      {
        label: 'Objective Flavor Profile',
        data: ggVarValues,
        fill: true,
        backgroundColor: 'rgba(70,93,186,0.5)',
        pointBackgroundColor: '#022950',
        pointBorderColor: '#022950',
        pointHoverBackgroundColor: '#fff',
        pointStyle: 'circle',
        pointRadius: 4,
        borderCapStyle: 'round',
        pointHoverBorderColor: '#022950',
        borderColor: 'black',
        borderWidth: 1,
      },
      {
        label: ' ',
        pointBackgroundColor: '#fff',
        fill: false,
        borderColor: 'black',
        borderWidth: 1,
        pointBorderColor: 'black',
        pointRadius: 4,
        pointStyle: 'circle',
        borderCapStyle: 'round',
        pointHoverBorderColor: 'black',
        data: new Array(24).fill(1),
      },
      {
        label: '  ',
        pointBackgroundColor: '#fff',
        fill: false,
        borderColor: 'black',
        borderWidth: 1,
        pointBorderColor: 'black',
        pointRadius: 4,
        pointStyle: 'circle',
        borderCapStyle: 'round',
        pointHoverBorderColor: 'black',
        data: new Array(24).fill(2),
      },
      {
        label: '   ',
        pointBackgroundColor: '#fff',
        fill: false,
        borderColor: 'black',
        borderWidth: 1,
        pointBorderColor: 'black',
        pointRadius: 4,
        pointStyle: 'circle',
        borderCapStyle: 'round',
        pointHoverBorderColor: 'black',
        data: new Array(24).fill(3),
      },
      {
        label: '    ',
        pointBackgroundColor: '#fff',
        fill: false,
        borderColor: 'black',
        borderWidth: 1,
        pointBorderColor: 'black',
        pointRadius: 4,
        pointStyle: 'circle',
        borderCapStyle: 'round',
        pointHoverBorderColor: 'black',
        data: new Array(24).fill(4),
      },
      {
        label: '     ',
        pointBackgroundColor: '#fff',
        fill: false,
        borderColor: 'black',
        borderWidth: 1,
        pointBorderColor: 'black',
        pointRadius: 4,
        pointStyle: 'circle',
        borderCapStyle: 'round',
        pointHoverBorderColor: 'black',
        data: new Array(24).fill(5),
      },
    ],
  };
};

export const sortGGvars = (
  a: reports.ReportGastrographRadarRow,
  b: reports.ReportGastrographRadarRow,
): number => {
  const ggKeys = ggVars.map((v) => v.key);
  return ggKeys.indexOf(a.attribute) < ggKeys.indexOf(b.attribute) ? -1 : 1;
};

export const bankersRound = (n: number, d: number = 2) => {
  const x = n * Math.pow(10, d);
  const r = Math.round(x);
  const br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
  return br / Math.pow(10, d);
};

export const getOFPs = (
  reportGastrographRadarResponse: reports.ReportGastrographRadarReponse,
  productVersion: string,
): number[] => {
  return reportGastrographRadarResponse.allRpOfps.nodes
    .filter((r) => nullCheck(r.version) === productVersion)
    .sort(sortGGvars)
    .map((sortedValue) => bankersRound(sortedValue.intensity, 0));
};

export const fpDecompRowsTotalDecomp = (
  fpDecompRows: reports.ReportFpDecompRow[],
): number => {
  return fpDecompRows
    .filter((r) => !r.referenceFlavor.startsWith('generic'))
    .map((r) => parseFloat(r.decomp))
    .reduce((a, b) => a + b, 0);
};

/**
 * Take flavor profile decomposition data and add up to the total value.  [Max 1.0 / 100%]
 * @param reportFpDecompData decomposition data
 * @returns total signature
 */
export const getTotalDecomp = (
  reportFpDecompData: reports.ReportFpDecompResponse,
): number => {
  return Math.min(
    fpDecompRowsTotalDecomp(reportFpDecompData.allRpFlavorDecomps.nodes),
    1,
  );
};

export const getProfileIntensity = (
  reportGastrographRadarResponse: reports.ReportGastrographRadarReponse,
  productVersion: string,
): number => {
  return getOFPs(reportGastrographRadarResponse, productVersion).reduce(
    (a, b) => a + b,
    0,
  );
};

export const getRawAndReshapeData = (
  reportGastrographRadarResponse: reports.ReportGastrographRadarReponse,
  productVersion: string,
): string[] => {
  return reportGastrographRadarResponse.allRpOfps.nodes
    .filter((r) => nullCheck(r.version) === productVersion)
    .sort(sortGGvars)
    .map((sortedValue) =>
      parseFloat(sortedValue.intensity.toString()).toFixed(1),
    );
};

export const getRawAndReshapeDataRow = (
  reportGastrographRadarRows: [reports.ReportGastrographRadarRow],
  productVersion: string,
): string[] => {
  return reportGastrographRadarRows
    .filter((r) => nullCheck(r.version) === productVersion)
    .sort(sortGGvars)
    .map((sortedValue) =>
      parseFloat(sortedValue.intensity.toString()).toFixed(1),
    );
};
