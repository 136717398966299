import * as React from 'react';

import { Card, CardContent, CardHeader, useTheme } from '@mui/material';
import useReportSummary from 'hooks/useReportSummary';

import PositiveNegativeProductInformation from './PositiveNegativeProductInformation';
import useStyles from '../useStyles';

interface ReportProps {
  projectId: number;
  reportId: string;
}

const PositiveNegativeDrivers: React.FC<ReportProps> = (props) => {
  const { projectId, reportId } = props;

  const classes = useStyles(useTheme());

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    data: reportSummaryData,
    topProduct,
    bottomProduct,
  } = useReportSummary(reportId);

  if (
    reportSummaryLoading ||
    reportSummaryError ||
    topProduct === null ||
    bottomProduct === null
  ) {
    return <div />;
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <CardHeader
          title="Positive and Negative Drivers"
          className={classes.header}
        />
        <PositiveNegativeProductInformation
          projectId={projectId}
          reportId={reportId}
          product={topProduct}
        />
        <PositiveNegativeProductInformation
          projectId={projectId}
          reportId={reportId}
          product={bottomProduct}
        />
      </CardContent>
    </Card>
  );
};

export default PositiveNegativeDrivers;
