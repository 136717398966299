import * as React from 'react';

import { useQuery } from '@apollo/client';
import WorkspaceByProducerUserQuery from '@graphql/queries/WorkspaceByProducerUserQuery';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import FormInput from '../../../components/FormInput';
import FormInputSelect from '../../../components/FormInputSelect';
import selectViewerUserId from '../../../selectors/viewerUserId';

interface Props {
  change: (field: string, value: any) => void;
}

interface WorkspaceOptions {
  id: number;
  label: string;
  value: string;
}

interface WorkspacesByProducerUser {
  allProducerUsers: {
    nodes: [
      {
        producerByProducerId: {
          id: number;
          name: string;
        };
      },
    ];
  };
}

const WorkspaceOptionsPartnerAdmin: React.FC<Props> = ({ change }) => {
  const { t } = useTranslation();
  const currentUserId = useSelector((state) => selectViewerUserId(state));
  const [workspaceOptions, setWorkspaceOptions] = React.useState<
    WorkspaceOptions[]
  >([]);

  const { data, loading } = useQuery<WorkspacesByProducerUser>(
    WorkspaceByProducerUserQuery,
    { variables: { userId: currentUserId } },
  );

  React.useEffect(() => {
    if (data) {
      setWorkspaceOptions(
        data.allProducerUsers.nodes.map((p) => ({
          id: p.producerByProducerId.id,
          label: p.producerByProducerId.name,
          value: p.producerByProducerId.name,
        })),
      );
    }
  }, [loading, data]);

  return (
    <Field
      key={workspaceOptions.length}
      name="workspace_id"
      component={FormInput}
      inputComponent={FormInputSelect}
      customLabel
      labelText={t('reports.targetWorkspaceLabel')}
      options={workspaceOptions}
      isSearchable
      hideSelectedOptions={false}
      placeholder={t('reports.targetWorkspacePlaceholder')}
      closeMenuOnSelect={true}
      required
      value={(val) => val.value}
      onChange={(_, newValue) => {
        change('workspace_id', newValue.value);
      }}
    />
  );
};

export default WorkspaceOptionsPartnerAdmin;
