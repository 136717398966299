import { FetchResult, gql, useLazyQuery, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';

import appToastAdd from '../actions/appToastAdd';

interface SwapProductReviewResult {
  swapProductReviews: { num_reviews_swapped: string };
}

const fetchProducerPanels = gql`
  query allPanels($producerId: Int!) {
    allPanels(filter: { producerId: { equalTo: $producerId } }) {
      nodes {
        id
        producerId
        name
        pin
      }
    }
  }
`;

const fetchPanelProducts = gql`
  query allPanelProducts($panelId: Int!) {
    allPanelProducts(filter: { panelId: { equalTo: $panelId } }) {
      nodes {
        id
        panelId
        productId
        productByProductId {
          id
          name
        }
      }
    }
  }
`;

const swapMutation = gql`
  mutation swapProductReviews(
    $panelId1: Int!
    $productId1: Int!
    $panelId2: Int!
    $productId2: Int!
  ) {
    swapProductReviews(
      input: {
        panelId1: $panelId1
        productId1: $productId1
        panelId2: $panelId2
        productId2: $productId2
      }
    ) {
      num_reviews_swapped
    }
  }
`;

const useSwapPanelProductReview = () => {
  const dispatch = useDispatch();
  const [
    getProducerPanels,
    {
      loading: loadingProducerPanels,
      data: producerPanelsData,
      error: producerPanelsError,
    },
  ] = useLazyQuery(fetchProducerPanels, {
    fetchPolicy: 'no-cache',
  });
  const [
    getPanelProducts,
    {
      loading: loadingPanelProducts,
      data: panelProductsData,
      error: panelProductsError,
    },
  ] = useLazyQuery(fetchPanelProducts, {
    fetchPolicy: 'no-cache',
  });
  const [swapReviews, { data, loading, error }] =
    useMutation<SwapProductReviewResult>(swapMutation);

  const swapProductReviews = async (
    panelId1: number,
    productId1: number,
    panelId2: number,
    productId2: number,
  ) => {
    return await swapReviews({
      variables: {
        panelId1,
        productId1,
        panelId2,
        productId2,
      },
    }).then((res: FetchResult<SwapProductReviewResult>) => {
      const noyOfReviewsSwapped =
        res?.data?.swapProductReviews?.num_reviews_swapped;
      dispatch(
        appToastAdd({
          durationMilliseconds: 4000,
          title: 'Reviews Swapped Successfully',
          message: `${noyOfReviewsSwapped} review(s) swapped`,
          toastKey: `toast_${Date.now()}`,
        }),
      );
      return res;
    });
  };

  return {
    swapProductReviews,
    data,
    loading,
    error,
    getProducerPanels,
    loadingProducerPanels,
    producerPanelsData,
    producerPanelsError,
    getPanelProducts,
    loadingPanelProducts,
    panelProductsData,
    panelProductsError,
  };
};

export default useSwapPanelProductReview;
