import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontFamily: 'AlphaHeadlinePro-Bold',
      fontSize: 20,
      color: theme.palette.primary.main,
      textAlign: 'center',
      marginBottom: 20,
      marginTop: 20,
    },
    primary: {
      fontFamily: 'AlphaHeadlinePro-Bold',
      fontSize: 16,
      color: theme.palette.info.main,
    },
    root: {
      '&:hover': {
        '& $avatar': {
          backgroundColor: theme.palette.secondary.main,
        },
        '& $primary': {
          color: theme.palette.secondary.main,
        },
      },
    },
    avatar: {},
    paper: {
      marginTop: '20%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    list: {
      maxHeight: '50vh',
      overflowY: 'hidden',
      marginRight: 50,
      marginLeft: 50,
      width: '-webkit-fill-available',
      '&:hover': {
        overflowY: 'auto',
      },
    },
  }),
);

export default useStyles;
