import {
  PROJECT_SUMMARY_WITH_REPORT,
  REPORT_DASHBOARD,
} from 'constants/routePaths';

import * as React from 'react';
import { useEffect } from 'react';

import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material';
import { IconButton, Paper } from '@mui/material';
import { Grid } from '@mui/material';
import ReportOfpDescription from 'components/Report/ReportOfpDescription';
import { useTranslation } from 'react-i18next';
import { RouterProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import formatPath from 'utils/formatPath';

import styles from './ReportProductDetails.module.css';
import FlavorDecompositionTable from '../../components/Report/FlavorDecomposition/FlavorDecompositionTable';
import ReportProductDetailsComponent from '../../components/Report/ReportProductDetails';
import { ProjectTabs } from '../../services/customerPreferences';
import { PageHeader } from '../../styles/themeComponents';

interface ReportProps extends RouterProps {
  match: Record<string, any>;
  reportId: string;
  productId: number;
  version?: string;
}

// Clean the projectId param, as it might not be a number
export const cleanProjectId = (projectIdParam: string): number => {
  const parsedProjectId = parseInt(projectIdParam);

  if (!isNaN(parsedProjectId)) {
    return parsedProjectId;
  } else {
    return undefined;
  }
};

// Clean the version param, as it might be the null string
export const cleanVersionParam = (versionParam: string): string => {
  if (!!versionParam && versionParam != 'null') {
    return decodeURI(versionParam);
  } else {
    return null;
  }
};

const ReportProductDetails: React.FC<ReportProps> = (props) => {
  const { match, history } = props;
  const location = useLocation();
  const { state }: any = location;

  const { t } = useTranslation();

  const projectId = cleanProjectId(match.params.projectId);
  const reportId = match.params.reportId;
  const productId = parseInt(match.params.productId);
  const version = cleanVersionParam(match.params.version);

  useEffect(() => {
    return () => {
      history.listen(() => {
        window.scrollTo(0, 0);
      });

      if (history.action === 'POP' && reportId) {
        if (
          state &&
          state.routeBackLocation &&
          state.routeBackLocation == ProjectTabs.EXECUTIVE_SUMMARY
        )
          return history.replace({
            pathname: formatPath(PROJECT_SUMMARY_WITH_REPORT, {
              projectId,
              reportId,
            }),
            state: {
              productId,
              version,
              tabIdx: ProjectTabs.EXECUTIVE_SUMMARY,
            },
          });

        if (
          state &&
          state.routeBackLocation &&
          state.routeBackLocation.includes('/admin/report-dashboard')
        )
          return history.replace({
            pathname: formatPath(REPORT_DASHBOARD, {
              reportId: null,
            }),
            state: {
              reportId,
            },
          });

        return history.replace({
          pathname: formatPath(PROJECT_SUMMARY_WITH_REPORT, {
            projectId,
            reportId,
          }),
          state: {
            productId,
            version,
            tabIdx: ProjectTabs.PROJECT_SUMMARY,
          },
        });
      }
    };
  }, []);

  return (
    <Paper className={styles.container}>
      <Grid key={productId} container justifyContent="center">
        <Grid item xs={12}>
          <div className={styles.header}>
            <IconButton
              onClick={history.goBack}
              size="small"
              style={{ marginLeft: -26 }}
            >
              <KeyboardBackspaceIcon fontSize="small" />
              <PageHeader>{t('reports.reportProductDetails.back')}</PageHeader>
            </IconButton>
          </div>
        </Grid>
        <Grid style={{ marginTop: '30px' }} item xs={12}>
          <ReportProductDetailsComponent
            projectId={projectId}
            reportId={reportId}
            productId={productId}
            productVersion={version}
          />
        </Grid>
        <Grid style={{ marginTop: '30px' }} item xs={12}>
          <ReportOfpDescription
            reportId={reportId}
            productId={productId}
            productVersion={version}
          />
        </Grid>
        <Grid style={{ marginTop: '30px' }} item xs={12}>
          <FlavorDecompositionTable
            projectId={projectId}
            reportId={reportId}
            productId={productId}
            version={version}
            forceNoTuning={false}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReportProductDetails;
