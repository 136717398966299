import * as React from 'react';
import { useRef, useEffect } from 'react';

import * as d3 from 'd3';

import {
  formatComparisonArray,
  longestName,
  PairedPreferenceComparison,
  productNameVersions,
} from './utils';

interface Props {
  productNames: reports.ColorVersionedProductInfo[];
  pairedPreferenceComparisonArray: PairedPreferenceComparison[];
}

const PairedComparisonPreference: React.FC<Props> = (props) => {
  const { productNames, pairedPreferenceComparisonArray } = props;

  const svgRef = useRef(null);

  useEffect(() => {
    // D3 Code
    const visHeight = Math.min(
      Math.sqrt(pairedPreferenceComparisonArray.length) * 60,
      950,
    );
    const visWidth = Math.min(
      Math.sqrt(pairedPreferenceComparisonArray.length) * 60,
      950,
    );
    const marginLength =
      longestName(productNames, pairedPreferenceComparisonArray) * 6;
    const margin = {
      top: marginLength / Math.SQRT2,
      left: marginLength,
      right: marginLength / Math.SQRT2,
      bottom: 50,
    };

    const svg = d3
      .select(svgRef.current)
      .attr('width', visWidth + margin.left + margin.right)
      .attr('height', visHeight + margin.top + margin.bottom);

    // clear all previous content on refresh
    const everything = svg.selectAll('*');
    everything.remove();

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    const yScale = d3
      .scaleBand()
      .range([0, visHeight])
      .domain(
        productNameVersions(productNames, pairedPreferenceComparisonArray),
      );

    const xScale = d3
      .scaleBand()
      .range([0, visWidth])
      .domain(
        productNameVersions(productNames, pairedPreferenceComparisonArray),
      );

    const colorScale = d3
      .scaleLinear<string, number>()
      .domain([0, 0.25, 0.5, 0.75, 1])
      .range(['#ff0000', '#ff9e81', 'white', '#b1ff9a', '#00ff00'])
      .interpolate(d3.interpolateRgb);

    g.selectAll('rect')
      .data(
        formatComparisonArray(productNames, pairedPreferenceComparisonArray),
      )
      .enter()
      .append('rect')
      .attr('y', (d) => yScale(d.nameVersion1))
      .attr('x', (d) => xScale(d.nameVersion2))
      .attr('height', yScale.bandwidth())
      .attr('width', xScale.bandwidth())
      .attr('fill', (d) => colorScale(d.percentPop));

    g.selectAll('.percentLabel')
      .data(
        formatComparisonArray(productNames, pairedPreferenceComparisonArray),
      )
      .enter()
      .append('text')
      .attr('class', 'percentLabel')
      .attr('y', (d) => yScale(d.nameVersion1) + yScale.bandwidth() / 2)
      .attr('x', (d) => xScale(d.nameVersion2) + xScale.bandwidth() / 2)
      .style('text-anchor', 'middle')
      .style('dominant-baseline', 'central')
      .style('font-size', yScale.bandwidth() / 4 + 'px')
      .style('fill', 'black')
      .text((d) => (d.percentPop * 100).toFixed(1) + '%');

    const xAxis = d3.axisTop(xScale);

    svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'start')
      .attr('transform', 'rotate(-45)');

    const yAxis = d3.axisLeft(yScale);

    svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .call(yAxis)
      .selectAll('text')
      .style('text-anchor', 'end');
  }, [pairedPreferenceComparisonArray]); // redraw chart if data changes

  return (
    <div className="paired-pref-comparison">
      {pairedPreferenceComparisonArray.length > 0 && <svg ref={svgRef} />}
    </div>
  );
};

export default PairedComparisonPreference;
