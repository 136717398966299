import { gql } from '@apollo/client';

export default gql`
  mutation CreateWorkspacePartner($workspacePartner: ProducerPartnerInput!) {
    createProducerPartner(input: {producerPartner: $workspacePartner}) {
      producerPartner {
        id
        partnerId
        producerId
      }
    }
  }
`;
