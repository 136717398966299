import * as React from 'react';

import { useQuery } from '@apollo/client';
import OrderOfOperationsQuery from '@graphql/queries/OrderOfOperationsQuery';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { tableCustomStyles } from 'components/ReactDataTable/tableCustomStyles';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { cleanUnderscore } from 'utils/afsUtils';

import { ProductVersion } from '../ProductVersion';
import { getProductName } from '../utils';

interface Props {
  reportID: string;
  customProductNames: reports.ColorVersionedProductInfo[];
  activeProduct: string;
}

interface OrderOfOperationRow {
  refFlavor: string;
  deltaPq: number;
  deltaSignature: number;
  deltaDecomp: string | null;
  stepIdx: number;
  product: ProductVersion;
}

const OrderOfOperations: React.FC<Props> = ({
  activeProduct,
  reportID,
  customProductNames,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { data, loading, error } = useQuery<reports.OrderOfOperationsData>(
    OrderOfOperationsQuery,
    { variables: { reportID } },
  );

  const getRows = (
    data: reports.OrderOfOperationsData,
    activeProduct: string,
  ) => {
    const rows = data.allRpOptSteps.nodes
      .map((step) => ({
        productDisplayName: step.productByProductId.name,
        refFlavor: cleanUnderscore(step.refFlavor),
        deltaPq: Math.round(parseFloat(step.deltaPq) * 100) / 100,
        deltaSignature: Math.round(parseFloat(step.deltaSignature) * 100) / 100,
        deltaDecomp:
          step.deltaDecomp !== null
            ? (parseFloat(step.deltaDecomp) * 100).toFixed(2) + '%'
            : null,
        product: new ProductVersion(step.productId, step.version),
      }))
      .filter(
        (step) =>
          step.product.value == activeProduct &&
          !step.refFlavor.includes('generic'),
      )
      .map((step, idx) => ({
        ...step,
        stepIdx: idx + 1,
      }));
    return rows;
  };

  const columns: TableColumn<OrderOfOperationRow>[] = [
    {
      name: t('projects.projectOptimizationSummary.order'),
      selector: (row: OrderOfOperationRow) => row.stepIdx,
    },
    {
      name: t('product.productName'),
      width: '40%',
      selector: (row: OrderOfOperationRow) =>
        getProductName({
          productNames: customProductNames,
          productId: row.product.productId,
          version: row.product.version,
        }),
    },
    {
      name: t('projects.projectOptimizationSummary.referenceFlavor'),
      width: '25%',
      selector: (row: OrderOfOperationRow) => row.refFlavor,
    },
    {
      name: t('projects.projectOptimizationSummary.deltaDecomp'),
      selector: (row: OrderOfOperationRow) => row.deltaDecomp,
      cell: (row: OrderOfOperationRow) => {
        if (row.deltaDecomp != null) {
          return row.deltaDecomp;
        }
        return row.deltaSignature > 0 ? (
          <AddIcon style={{ color: 'green' }} />
        ) : (
          <RemoveIcon style={{ color: 'red' }} />
        );
      },
    },
  ];

  if (!activeProduct) {
    return <div></div>;
  }

  if (loading || !data) {
    return <LoadingScreen />;
  }

  const rows = getRows(data, activeProduct);

  if (rows.length === 0) {
    return <div>No data</div>;
  }

  if (error) {
    return <h3>Failed to load Order of Operations.</h3>;
  }

  return (
    <DataTable
      columns={columns}
      data={rows}
      customStyles={tableCustomStyles(theme)}
    />
  );
};

export default OrderOfOperations;
