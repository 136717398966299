import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontFamily: 'OpenSans',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 12,
    },
  }),
);

export default useStyles;
