import * as React from 'react';

import { Point, ResponsiveLine, Serie } from '@nivo/line';
import * as d3 from 'd3-shape';
import { meanBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  interpPoint,
  loessSmooth,
  mapJsonB,
  maxX,
} from '../../../../../services/utils/tuningPlotUtils';

interface ChartProps {
  decompData: reports.ReportTextureDecompRow[];
  pq?: number;
  minPq?: number;
  maxPq?: number;
}

const refFlavorChartColors = [
  '#fbb4af',
  '#cd9600',
  '#7cae00',
  '#00be67',
  '#00bfc4',
  '#2b5469',
  '#c77cff',
  '#ff61cc',
];

const circle =
  (referenceFlavorSerieses: reports.NivoDataSeries[]) =>
  ({ data, points, ...info }) => {
    const currentValues = referenceFlavorSerieses.map(
      (series: reports.NivoDataSeries) => ({
        serieId: series.id,
        currentValue: series.currentValue,
        color: series.color,
      }),
    );

    const middlePoints = currentValues
      .map((currentValue) => ({
        mp: interpPoint(
          points.filter((p: Point) => p.serieId === currentValue.serieId),
          currentValue.currentValue.x,
        ),
        color: currentValue.color,
      }))
      .filter((middlePoint) => !!middlePoint.mp);

    const dots: JSX.Element[] = middlePoints.map((middlePoint) => (
      <path
        d={d3.symbol(d3.symbolCircle)()}
        transform={`translate(${middlePoint.mp.x} ${middlePoint.mp.y})`}
        color={middlePoint.color}
      />
    ));

    return dots;
  };

const TextureIntensityPqChart: React.FC<ChartProps> = (props) => {
  const { decompData, pq, minPq, maxPq } = props;
  const { t } = useTranslation();

  const mapData = (row: reports.ReportTextureDecompRow): reports.NivoData[] => {
    return mapJsonB(row.dataJsonb);
  };

  const mapRowToChartData = (
    row: reports.ReportTextureDecompRow,
    decompData: reports.ReportTextureDecompRow[],
  ): reports.NivoDataSeries => {
    return {
      id: 'TC_' + row.tcIdx,
      data: mapData(row),
      currentValue: mapCurrentValue(row, decompData),
    };
  };

  const mapCurrentValue = (
    row: reports.ReportTextureDecompRow,
    decompData: reports.ReportTextureDecompRow[],
  ): reports.NivoData => {
    const matchingDecomp = decompData.find((d) => d.tcIdx === row.tcIdx);

    const deltaPoints = matchingDecomp.dataJsonb.map((d) => ({
      x: d[0][0],
      y: d[1][0],
      currentIntensity: matchingDecomp.currentIntensity,
      delta: Math.abs(d[0][0] - matchingDecomp.currentIntensity),
    }));

    const closestDelta = Math.min(...deltaPoints.map((i) => i.delta));
    const closestPoint = deltaPoints
      .filter((i) => i.delta === closestDelta)
      .map((p) => ({
        x: p.x,
        y: p.y,
      }))
      .find(Boolean);

    return closestPoint;
  };

  const bottomTitle: () => string = () => {
    return 'Signature Decomp';
  };

  const byPeakToPeak = (
    a: reports.NivoDataSeries,
    b: reports.NivoDataSeries,
  ) => {
    const aP2P =
      Math.max(...a.data.map((d) => d.y)) - Math.min(...a.data.map((d) => d.y));
    const bP2P =
      Math.max(...b.data.map((d) => d.y)) - Math.min(...b.data.map((d) => d.y));
    return bP2P - aP2P;
  };

  const alpha = (a: reports.NivoDataSeries, b: reports.NivoDataSeries) => {
    return b.id.localeCompare(a.id);
  };

  // Take the tuning data and map it to chart data, but filter out potentially junk values
  const chartData: reports.NivoDataSeries[] = decompData
    .map((row) => mapRowToChartData(row, decompData))
    .filter(
      (s) => !Number.isNaN(s.currentValue.x) && !Number.isNaN(s.currentValue.y),
    )
    .sort(byPeakToPeak)
    .slice(0, 8)
    .sort(alpha)
    .map((series, i) => ({
      id: series.id,
      currentValue: series.currentValue,
      color: refFlavorChartColors[i],
      data: loessSmooth(series.data, 31, 10, series.currentValue),
    }));

  return (
    <div
      key={
        decompData.find(Boolean).reportId +
        decompData.find(Boolean).productId?.toString()
      }
      style={{ height: '300px' }}
    >
      <ResponsiveLine
        data={chartData as Serie[]}
        margin={{ top: 50, right: 125, bottom: 50, left: 60 }}
        curve="catmullRom" // Make the curve
        markers={[
          {
            axis: 'y', // axis the marker should be perpendicular to
            value: pq ?? meanBy(chartData.map((d) => d.currentValue.y)), // value of the marker in axis
            lineStyle: {
              stroke: 'red',
              strokeWidth: 1,
              strokeDasharray: '12, 6', // Make line dashed
            },
          },
        ]}
        xScale={{
          type: 'linear',
          min: -0.001,
          max: maxX(chartData),
        }}
        yScale={{
          type: 'linear',
          min: minPq || 'auto',
          max: maxPq || 'auto',
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'points',
          'slices',
          'mesh',
          'legends',
          circle(chartData),
        ]}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: bottomTitle(),
          legendOffset: 36,
          legendPosition: 'middle',
          tickValues: 6,
          format: (v: number) => {
            return `${v.toFixed(1)}`;
          },
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t('reports.flavorIntensityPqChart.pq'),
          legendOffset: -40,
          legendPosition: 'middle',
          tickValues: 5,
        }}
        colors={chartData.map((s) => s.color)}
        lineWidth={2}
        enableSlices={'x'}
        enableCrosshair={true}
        enablePoints={false}
        pointSize={6}
        pointColor={{ from: 'color' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            toggleSerie: true,
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 120,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default TextureIntensityPqChart;
