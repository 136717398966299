import * as React from 'react';
import { useState } from 'react';

import { graphql } from '@apollo/client/react/hoc';
import DataQualityQuery from '@graphql/queries/DataQualityQuery';
import LatestAppBuildHistoriesQuery from '@graphql/queries/LatestAppBuildHistoriesQuery';
import PanelDataQualityQuery from '@graphql/queries/PanelDataQualityQuery';
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material';
import { Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import PanelProductDataQualityTable from 'containers/DataQualityDashboard/PanelProductDataQualityTable';
import ProductReviewTable from 'containers/DataQualityDashboard/ProductReviewTable';
import UserCategoryTable from 'containers/DataQualityDashboard/UserCategoryTable';
import UserProductDataQualityTable from 'containers/DataQualityDashboard/UserProductDataQualityTable';
import parseISO from 'date-fns/parseISO';
import { flowRight as compose } from 'lodash';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './DataDashboard.module.css';
import { getPanelQualityData, totalFlagsForReviews } from './dataquality.utils';
import PanelDemographicsTable from './PanelDemographicsTable';
import PanelReviewsTable from './PanelReviewsTable';
import { PANELS } from '../../constants/routePaths';
import ProducerByIdQuery from '../../graphql/queries/ProducerByIdQuery';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import { PageHeader } from '../../styles/themeComponents';
import NotFoundContainer from '../NotFound';

const DataQualityDashboard: React.FC<Props> = (props) => {
  const { loading, panel, workspaceId, appBuild, qualityData } = props;

  const latestAppBuild: string = get(
    appBuild,
    'allAppBuildHistories.nodes[0].build',
  );
  const latestAppVersion: string = get(
    appBuild,
    'allAppBuildHistories.nodes[0].appDisplayVersion',
  );

  const panelQualityData: dataquality.PanelQualityData[] =
    getPanelQualityData(qualityData);

  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] =
    useState<dataquality.PanelQualityData[]>();

  const { t } = useTranslation();

  const getAllSelectedReferenceFlavors = () => {
    const result = {};

    get(qualityData, 'allDataQualityViews.edges', []).forEach((d) => {
      const review = d.node;
      if (!review.referenceFlavors) {
        return;
      }

      const referenceFlavors = JSON.parse(review.referenceFlavors);
      const selectedGgVars = Object.keys(referenceFlavors);

      selectedGgVars.forEach((ggVar) => {
        referenceFlavors[ggVar].forEach((refFlavor) => {
          const key = `${refFlavor.referenceFlavor}`.trim().toLowerCase();
          if (Object.prototype.hasOwnProperty.call(result, key)) {
            result[key]++;
          } else {
            result[key] = 1;
          }
        });
      });
    });
    return result;
  };

  const allSelectedReferenceFlavors = getAllSelectedReferenceFlavors();

  const panelistids = Array.from(
    new Set(panelQualityData.map((d) => d.userId)).values(),
  );
  const data: dataquality.DataQualityDetail[] = panelistids.map(
    (userId: number, i) => {
      const panelReviews = panelQualityData.filter((d) => d.userId == userId);

      const panelReviewCount = panelReviews.length;
      const fastReview = panelReviews.filter(
        (d) => +d.durationMs < 30000,
      ).length;
      const totalTimeTakenMs = panelReviews.reduce(
        (a, b) => a + b.durationMs,
        0,
      );
      const averageTimeTakenSecs =
        totalTimeTakenMs / panelReviews.length / 1000;
      const noReferenceFlavor = panelReviews.filter(
        (d) => d.flags.norefflavor,
      ).length;
      const excessiveReferenceFlavor = panelReviews.filter(
        (d) => d.flags.excessiverefflavor,
      ).length;
      const excessiveTextures = panelReviews.filter(
        (d) => d.flags.excessivetexture,
      ).length;
      const zeroMouthfeelIntensity = panelReviews.filter(
        (d) => d.flags.zeroMouthfeelIntensity,
      ).length;
      const tooMany7s = panelReviews.filter(
        (d) => d.flags.tooManySevens,
      ).length;

      const totalReferenceFlavorCount = panelReviews.reduce(
        (a, b) => a + b.rfcount,
        0,
      );
      const mostRecentSubmission = parseISO(
        panelReviews.sort(
          (a, b) => +new Date(a.endTime) - +new Date(b.endTime),
        )[0].endTime,
      );
      const avgReferenceFlavorPerReview =
        totalReferenceFlavorCount / panelReviewCount;
      const allGgVar = panelReviews.filter((d) => d.flags.allggvar).length;
      const allMarksAtFive = panelReviews.filter(
        (d) => d.flags.allmarksatfive,
      ).length;
      const insufficientGgVar = panelReviews.filter(
        (d) => d.flags.insufficientggvar,
      ).length;
      const excessiveGGVar = panelReviews.filter(
        (d) => d.flags.excessiveggvar,
      ).length;
      const runningPanelReviewCount = panelQualityData.length;
      const runningPanelistCount = panelistids.length;

      let agreeReferenceFlavorCount = 0;
      panelReviews.forEach((review) => {
        const reviewReferenceFlavor = new Set(); // selected reference flavors for a particular review -- use to de-dup similar reference flavors
        const referenceFlavors = JSON.parse(review.referenceFlavors);
        if (!isEmpty(referenceFlavors)) {
          const selectedGgVars = Object.keys(referenceFlavors);
          selectedGgVars.forEach((ggVar) => {
            referenceFlavors[ggVar].forEach((refFlavor) => {
              const key = `${refFlavor.referenceFlavor}`
                .replace(/\s/g, '')
                .toLowerCase();

              // We only want to consider reference flavors that didn't appear in this particular review before
              if (!reviewReferenceFlavor.has(key)) {
                allSelectedReferenceFlavors[key] >= 4 &&
                  agreeReferenceFlavorCount++;
              }
            });
          });
        }
      });

      const agreementScore =
        agreeReferenceFlavorCount / totalReferenceFlavorCount;
      let agreementFlag = 0;
      if (runningPanelistCount > 2 && runningPanelReviewCount > 3) {
        agreementFlag = agreementScore < 0.3 ? 1 : 0;
      }

      const allFlagCount = totalFlagsForReviews(panelReviews);

      const userIdentification =
        panelReviews[0].email || '' + panelReviews[0].userId;

      return {
        rowIndex: i,
        rowId: i + 1,
        userEmail: panelReviews[0].email,
        socioEconomicStatus: panelReviews[0].socioEconomicStatus,
        panelReviewCount,
        totalReviewCount: +panelReviews[0].totalReviews,
        runningPanelReviewCount,
        runningPanelistCount,
        fastReview,
        totalTimeTakenMs,
        interfaceLanguage: panelReviews[0].interfaceLanguage,
        averageTimeTakenSecs: Math.round(averageTimeTakenSecs),
        noReferenceFlavor,
        excessiveReferenceFlavor,
        excessiveTextures,
        excessiveGGVar,
        zeroMouthfeelIntensity,
        tooMany7s,
        totalReferenceFlavorCount,
        mostRecentSubmission,
        averageReferenceFlavor: avgReferenceFlavorPerReview,
        allGgVar,
        allMarksAtFive,
        insufficientGgVar,
        userRef: userIdentification,
        allFlagCount,
        pin: panel?.pin,
        agreement: (agreementScore * 100).toFixed(2),
        gender: panelReviews[0].gender,
        age: panelReviews[0].age,
        userCategory: panelReviews[0].userCategory,
        userIdentification: (
          <a
            onClick={(e) => {
              e.preventDefault();
              const user = { ...panelReviews };
              setSelectedUser(user);
              setShowModal(true);
            }}
            href="#"
          >
            {userIdentification}
          </a>
        ),
      };
    },
  );

  if (loading) {
    return (
      <Paper
        style={{
          width: '100%',
          maxWidth: '100%',
          padding: '10px',
          position: 'relative',
        }}
      >
        <div className={styles.headerContainer}>
          <div className={styles.headerTextContainerTop}>
            <IconButton
              component={Link}
              to={{ pathname: PANELS, state: undefined }}
              size="small"
              style={{ marginLeft: -26 }}
            >
              <KeyboardBackspaceIcon fontSize="small" />
              <PageHeader>{t('navigation.panels')}</PageHeader>
            </IconButton>
          </div>
        </div>

        <div className={styles.headerContainer}>
          <div className={styles.headerTextContainer}>
            <h2 className={styles.panelTitle}>
              Data Quality Dashboard: Loading
            </h2>
          </div>
        </div>

        <LinearProgress />
      </Paper>
    );
  }

  if (panel.producerId !== workspaceId) {
    return <NotFoundContainer />;
  }

  const panelEndTime = new Date(panel.endTime);

  return (
    <Paper
      style={{
        width: '100%',
        maxWidth: '100%',
        padding: '10px',
        position: 'relative',
      }}
    >
      <div className={styles.headerContainer}>
        <div className={styles.headerTextContainerTop}>
          <IconButton
            component={Link}
            to={{ pathname: PANELS, state: undefined }}
            size="small"
            style={{ marginLeft: -26 }}
          >
            <KeyboardBackspaceIcon fontSize="small" />
            <PageHeader>{t('navigation.panels')}</PageHeader>
          </IconButton>
        </div>
      </div>

      <div className={styles.headerContainer}>
        <div className={styles.headerTextContainer}>
          <h2 className={styles.panelTitle}>
            Data Quality Dashboard: {panel.name ? panel.name : panel.pin}
          </h2>
        </div>
      </div>

      <br />
      <h3 className={styles.panelTitle} style={{ paddingLeft: 20 }}>
        Demographic Summary
      </h3>

      <PanelDemographicsTable panelData={data} />

      <br />
      <br />
      <h3 className={styles.panelTitle} style={{ paddingLeft: 20 }}>
        Reviews
      </h3>

      <PanelReviewsTable loading={qualityData.loading} panelData={data} />

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ProductReviewTable panelReviews={selectedUser} />
      </Modal>

      <br />
      <br />
      <h3 className={styles.panelTitle}>Products</h3>
      {panelQualityData && (
        <PanelProductDataQualityTable
          panel={panel}
          panelProducts={panel.panelProducts.nodes}
          panelReviews={panelQualityData}
        />
      )}
      {panelEndTime && panelEndTime < new Date() && (
        <div className={styles.legendContainer}>
          <div className={styles.legendItemContainer}>
            <div className={styles.legendGreenBlock} />
            <span>Flag Percentage ≥ 0.95</span>
          </div>
          <div className={styles.legendItemContainer}>
            <div className={styles.legendYellowBlock} />
            <span>Flag Percentage 0.75 - 0.94</span>
          </div>
          <div className={styles.legendItemContainer}>
            <div className={styles.legendRedBlock} />
            <span>Flag Percentage ≤ 0.74</span>
          </div>
        </div>
      )}
      <h2 className={styles.refreshMsg}>Data Refresh Rate: 30 seconds</h2>

      <br />
      <br />
      <h3 className={styles.panelTitle}>User Flags</h3>
      <UserProductDataQualityTable
        panelProducts={panel.panelProducts.nodes}
        panelReviews={panelQualityData}
        latestAppBuild={latestAppBuild}
      />
      <div className={styles.footerContainer}>
        <span className={styles.refreshMsg}>
          Latest App Version: {latestAppVersion} ({latestAppBuild})
        </span>
        <span className={styles.refreshMsg}>Data Refresh Rate: 30 seconds</span>
      </div>
      <br />
      <br />
      <UserCategoryTable panelUsers={data} />
    </Paper>
  );
};

interface Props {
  workspaceId: any;
  loading?: boolean;
  panel?: any;
  producer?: any;
  data?: any;
  qualityData?: dataquality.PanelDataQualityQueryResponse;
  appBuild?: any;
}

const mapStateToProps = (state) => ({
  workspaceId: selectWorkspaceProducerId(state),
});

export default compose(
  connect(mapStateToProps),
  graphql(ProducerByIdQuery, {
    name: 'producer',
    options: (props: Props) => ({
      pollInterval: 30000,
      variables: {
        id: props.workspaceId,
      },
    }),
  }),
  graphql(DataQualityQuery, {
    options: ({
      match: {
        params: { panelId },
      },
    }: any) => ({
      pollInterval: 30000,
      variables: {
        panelId: Number(panelId),
      },
      fetchPolicy: 'no-cache',
    }),
    props: (props: any) => {
      const {
        data: { loading, panel },
      } = props;
      return {
        loading,
        panel,
      };
    },
  }),
  graphql(PanelDataQualityQuery, {
    options: (props: Props) => {
      return {
        pollInterval: 30000,
        variables: {
          panelPin: get(props, 'panel.pin') ?? '',
        },
      };
    },
    name: 'qualityData',
  }),
  graphql(LatestAppBuildHistoriesQuery, {
    options: (props) => {
      return {
        pollInterval: 30000,
        variables: {
          date: get(props, 'panel.endTime') ?? new Date().toISOString(),
        },
      };
    },
    name: 'appBuild',
  }),
)(DataQualityDashboard);
