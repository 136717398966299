import { Theme } from '@mui/material';
import { TableStyles } from 'react-data-table-component';

export const materialTableStyles = (theme: Theme): TableStyles => {
  return {
    header: {
      style: {
        minHeight: '16px',
      },
    },
    headCells: {
      style: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    headRow: {
      style: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: '14px',
        letterSpacing: '0.01px',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 'bold',
        minHeight: '38px',
      },
    },
    rows: {
      style: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: '0.01px',
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.87)',
        minHeight: '38px',
      },
    },
  };
};
