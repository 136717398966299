import * as React from 'react';

import { useTheme } from '@mui/material';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { useTranslation } from 'react-i18next';

import {} from '../../Maps/mapUtils';
import useStyles from './useStyles';
import { RegressionLineLayer, pqScale, serieIdToolip, xScale } from './utils';
import { COLORS } from '../../../../styles/theme';

interface Props {
  chartData: reports.NivoDataSeries[];
}

const IntensityPqChart: React.FC<Props> = (props) => {
  const { chartData } = props;

  const { t } = useTranslation();
  const classes = useStyles(useTheme());

  return (
    <div style={{ height: '300px', maxWidth: '630px', justifySelf: 'center' }}>
      <ResponsiveScatterPlot
        data={chartData}
        margin={{ top: 60, right: 90, bottom: 70, left: 90 }}
        xScale={xScale(chartData)}
        yScale={pqScale}
        xFormat=" >-.2f"
        yFormat=" >-.2f"
        blendMode="multiply"
        axisTop={null}
        axisRight={null}
        colors={COLORS.MARINE}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t('reports.noveltyIntensityPqChart.intensity'),
          legendPosition: 'middle',
          legendOffset: 46,
          tickValues: 6,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t('reports.noveltyIntensityPqChart.pq'),
          legendPosition: 'middle',
          legendOffset: -60,
          tickValues: 6,
        }}
        layers={[
          'grid',
          'axes',
          RegressionLineLayer,
          'nodes',
          'markers',
          'mesh',
          'legends',
        ]}
        tooltip={(node) => serieIdToolip(node, classes.tooltip)}
      />
    </div>
  );
};

export default IntensityPqChart;
