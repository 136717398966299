import * as React from 'react';
import { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import GetReferenceFlavorForUpdateQuery, {
  GetReferenceFlavorForUpdateResponse,
} from '@graphql/queries/GetReferenceFlavorForUpdateQuery';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from './CustomLexicon.module.css';
import { CsvFields } from './CustomLexiconCsvHelper';
import CustomLexiconList from './CustomLexiconList';
import {
  TranslationType,
  formatReferenceFlavorsList,
  getUpdatedReferenceFlavorsByAttributeLexicon,
  reshapeLexicons,
} from './util';
import LoadingScreen from '../../components/LoadingScreen';
import MaterialButton from '../../components/MaterialButton';
import GetReferenceFlavorLanguage, {
  GetReferenceFlavorLanguageResponse,
  ReferenceFlavorLanguage,
} from '../../graphql/queries/GetReferenceFlavorLanguage';
import i18n from '../../i18n';
import selectWorkspaceProducerId from '../../selectors/workspaceProducerId';
import { PageHeader } from '../../styles/themeComponents';

const CustomLexicon: React.FC = () => {
  const { t } = useTranslation();

  const workspaceProducerId = useSelector((state) =>
    selectWorkspaceProducerId(state),
  );

  const [lexicons, setLexicons] = useState<
    {
      key: string;
      value: any[];
    }[]
  >([]);
  const [retrievedLanguages, setRetrievedLanguages] = useState<
    ReferenceFlavorLanguage[]
  >([]);
  const [csvData, setCsvData] = useState([]);

  const {
    loading,
    error,
    data: workspaceReferenceFlavorForUpdate,
  } = useQuery<GetReferenceFlavorForUpdateResponse>(
    GetReferenceFlavorForUpdateQuery,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const {
    loading: languageLoading,
    error: languageError,
    data: languageData,
  } = useQuery<GetReferenceFlavorLanguageResponse>(GetReferenceFlavorLanguage, {
    fetchPolicy: 'cache-first',
  });

  const groupAndUpdateLexicons = (referenceFlavorNodes: TranslationType[]) => {
    if (referenceFlavorNodes && referenceFlavorNodes.length) {
      setLexicons(reshapeLexicons(referenceFlavorNodes));
    }
  };

  const getUpdatedReferenceFlavorsByAttribute = (attribute: string) => {
    return getUpdatedReferenceFlavorsByAttributeLexicon(lexicons, attribute);
  };

  useEffect(() => {
    if (
      workspaceReferenceFlavorForUpdate &&
      !workspaceReferenceFlavorForUpdate.loading &&
      workspaceReferenceFlavorForUpdate.error === undefined
    ) {
      const groupedReferenceFlavorNodes =
        workspaceReferenceFlavorForUpdate &&
        workspaceReferenceFlavorForUpdate.workspaceReferenceFlavorForUpdate &&
        formatReferenceFlavorsList(
          workspaceReferenceFlavorForUpdate.workspaceReferenceFlavorForUpdate
            .nodes,
        );

      groupAndUpdateLexicons(groupedReferenceFlavorNodes);
    }
  }, [workspaceReferenceFlavorForUpdate]);

  useEffect(() => {
    if (languageData && languageData.languages)
      setRetrievedLanguages(languageData.languages.nodes);
  }, [languageData]);

  const downloadCsv = () => {
    if (
      workspaceReferenceFlavorForUpdate &&
      !workspaceReferenceFlavorForUpdate.loading &&
      workspaceReferenceFlavorForUpdate.error === undefined
    ) {
      const groupedReferenceFlavorNodes =
        workspaceReferenceFlavorForUpdate &&
        workspaceReferenceFlavorForUpdate.workspaceReferenceFlavorForUpdate &&
        formatReferenceFlavorsList(
          workspaceReferenceFlavorForUpdate.workspaceReferenceFlavorForUpdate
            .nodes,
        );
      setCsvData(CsvFields(groupedReferenceFlavorNodes, retrievedLanguages));
    }
  };

  if (loading || error || languageLoading || languageError)
    return <LoadingScreen />;

  if (!workspaceReferenceFlavorForUpdate.error === undefined)
    return <div>Producer Data Not Found!</div>;

  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.headerTextContainer}>
          <PageHeader>{i18n.t('navigation.customLexicons')}</PageHeader>
          <h3 className={styles.reviewTitle}>
            {i18n.t('navigation.customLexicons')}
          </h3>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            paddingBottom: '10px',
          }}
        >
          <CSVLink
            data={csvData}
            filename="All_Reference_Flavors.csv"
            asyncOnClick={true}
          >
            <MaterialButton
              variant="outlined"
              soft
              teal
              disabled={lexicons.length < 1}
              onClick={downloadCsv}
            >
              {loading || error
                ? 'Downloading...'
                : `${t('customLexicon.downloadAll')}`}
            </MaterialButton>
          </CSVLink>
        </div>
      </div>
      <div className={styles.container}>
        {!workspaceReferenceFlavorForUpdate.loading &&
          lexicons &&
          lexicons.map((item) => (
            <CustomLexiconList
              key={item.key}
              name={item.key}
              referenceFlavors={item.value}
              languages={retrievedLanguages}
              groupAndUpdateLexicons={groupAndUpdateLexicons}
              getUpdatedReferenceFlavorsByAttribute={
                getUpdatedReferenceFlavorsByAttribute
              }
            />
          ))}
      </div>
    </div>
  );
};

export default CustomLexicon;
