import { gql } from '@apollo/client';

export default gql`
  query UserPartnerUsersByIdQuery($userId: Int!) {
    user: userById(id: $userId) {
      id
      userPartnersByUserId {
        nodes {
          partnerByPartnerId {
            id
            userPartnersByPartnerId {
              nodes {
                userByUserId {
                  id
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;
