import * as React from 'react';

import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import styles from './HeavyUser.module.css';
import FieldTextInput from '../../components/FieldTextInput';
import MaterialButton from '../../components/MaterialButton';
import { HEAVY_USER_INFO_FORM } from '../../constants/formNames';

interface TableRow {
  id: number;
  tag: string;
  categories: string;
  features: string;
  componentBases: string;
  componentOthers: string;
}

const UserInfo = ({ data }) => {
  const { t } = useTranslation();
  if (!data.user) return <div>User Not Found!</div>;
  if (!data.user.isHeavyUser) return <div>User is not a heavy user yet!</div>;

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: 'Heavy User ID',
      sortable: true,
      wrap: true,
      omit: true,
    },
    {
      id: 'tag',
      selector: (row: TableRow) => row.tag,
      name: <div className={styles.tableHeader}>Tag</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'categories',
      selector: (row: TableRow) => row.categories,
      name: (
        <div className={styles.tableHeader}>{t('product.productCategory')}</div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      id: 'features',
      selector: (row: TableRow) => row.features,
      name: (
        <div className={styles.tableHeader}>{t('product.productFeature')}</div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      id: 'componentBases',
      selector: (row: TableRow) => row.componentBases,
      name: (
        <div className={styles.tableHeader}>
          {t('product.productComponentBase')}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      id: 'componentOthers',
      selector: (row: TableRow) => row.componentOthers,
      name: (
        <div className={styles.tableHeader}>
          {t('product.productComponentOther')}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
  ];

  const tableData = data.user.heavyUsersByUserId.nodes.map((node) => ({
    id: node.id,
    tag: node.tag,
    categories: node.categories && node.categories.join(','),
    features: node.features && node.features.join(','),
    componentBases: node.componentBases && node.componentBases.join(','),
    componentOthers: node.componentOthers && node.componentOthers.join(','),
  }));

  return (
    <div>
      <DataTable
        columns={columns}
        data={tableData}
        fixedHeader
        striped
        pagination
        paginationIconFirstPage={<RDTFirstPage />}
        paginationIconLastPage={<RDTLastPage />}
        paginationIconPrevious={<RDTPrevPage />}
        paginationIconNext={<RTDNextPage />}
      />
    </div>
  );
};

interface Props {
  handleSubmit: () => any;
  submitting: boolean;
  pristine: boolean;
  invalid: boolean;
}

const HeavyUserInfo: React.FC<Props> = ({
  handleSubmit,
  submitting,
  pristine,
  invalid,
}) => {
  const { t } = useTranslation();

  const formValues = useSelector((state) =>
    getFormValues(HEAVY_USER_INFO_FORM)(state),
  );

  return (
    <div>
      <div className={styles.sectionContainer}>
        <Field
          name="email"
          component={FieldTextInput}
          fullWidth
          label={t('users.email')}
        />
        <Field
          name="username"
          component={FieldTextInput}
          fullWidth
          label={t('users.username')}
        />

        <div className={styles.buttonContainer}>
          <MaterialButton
            variant="outlined"
            disabled={pristine || invalid || submitting}
            onClick={handleSubmit}
            soft
            teal
          >
            {t('users.loadUserInfo')}
          </MaterialButton>
        </div>

        {formValues?.data && !submitting ? (
          <UserInfo data={formValues.data} />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default HeavyUserInfo;
