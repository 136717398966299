import { SUPERADMIN_EDIT_GROUP_WORKSPACE } from 'constants/routePaths';

import * as React from 'react';
import { useState } from 'react';

import { Paper } from '@mui/material';
import { Link } from 'react-router-dom';

import AllGroups from './AllGroups';
import styles from './GroupWorkspace.module.css';
import MaterialButton from '../../components/MaterialButton';
import { PageHeader } from '../../styles/themeComponents';

export const validate = (values) => {
  const errors = {};

  if (!(values?.selectedChildWorkspaces?.length || 0 > 0)) {
    errors['selectedChildWorkspaces'] = 'Required';
  }

  if (!(values?.groupName?.length || 0 > 0)) {
    errors['groupName'] = 'Required';
  }

  return errors;
};

interface Props {
  change: (field: string, value: any) => void;
}

const GroupWorkspace: React.FC<Props> = () => {
  const [groupReload, setGroupReload] = useState<boolean>(false);

  return (
    <Paper className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTextContainer}>
          <PageHeader>{'Group Workspaces'}</PageHeader>
          <h3 className={styles.productTitle}>Workspaces</h3>
        </div>
        <div>
          <Link
            className={styles.link}
            to={{ pathname: SUPERADMIN_EDIT_GROUP_WORKSPACE }}
          >
            <MaterialButton variant="outlined" soft teal>
              {'New Group Workspace'}
            </MaterialButton>
          </Link>
        </div>
      </div>

      <div style={{ paddingTop: 35 }}>
        <AllGroups reload={groupReload} />
      </div>
    </Paper>
  );
};

export default GroupWorkspace;
