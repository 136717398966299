import CreateClient from '@graphql/mutations/CreateClient';
import {
  destroy,
  getFormValues,
  startSubmit,
  stopSubmit,
  reset,
} from 'redux-form';
import { put, select, take } from 'redux-saga/effects';

import appToastAdd from '../actions/appToastAdd';
import errorAction from '../actions/error';
import { FORM_SUBMIT } from '../actions/formSubmit';
import { ADD_CLIENT_FORM } from '../constants/formNames';
import graphqlClient from '../consumers/graphqlClient';

export default function* addClientSaga() {
  while (true) {
    yield take(
      ({ type, payload }) =>
        type === FORM_SUBMIT && payload === ADD_CLIENT_FORM,
    );
    yield put(startSubmit(ADD_CLIENT_FORM));

    const formValues = yield select(getFormValues(ADD_CLIENT_FORM));
    try {
      yield graphqlClient.mutate({
        mutation: CreateClient,
        variables: {
          client: {
            name: formValues.clientName,
            partnerId: formValues.partner?.value ?? null,
          },
        },
      });
      yield put(stopSubmit(ADD_CLIENT_FORM));
      yield put(reset(ADD_CLIENT_FORM));
      yield put(destroy(ADD_CLIENT_FORM));
      yield put(
        appToastAdd({
          durationMilliseconds: 4000,
          message: formValues.partner
            ? `${formValues.clientName} created under ${formValues.partner.label}.`
            : `${formValues.clientName} created.`,
          title: 'Client Created',
          toastKey: `toast_${Date.now()}`,
        }),
      );
    } catch (error) {
      yield put(stopSubmit(ADD_CLIENT_FORM));
      yield put(reset(ADD_CLIENT_FORM));
      yield put(destroy(ADD_CLIENT_FORM));
      yield put(
        errorAction({
          error,
          title: 'Failed to create client',
          description: error.message,
        }),
      );
    }
  }
}
