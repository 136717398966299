import * as React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import UserSearchInWorkspaceQuery from '@graphql/queries/UserSearchInWorkspaceQuery';
import Paper from '@mui/material/Paper';
import { flowRight as compose, get } from 'lodash';
import DataTable, { TableColumn } from 'react-data-table-component';
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import styles from './UserSearch.module.css';
import MaterialButton from '../../components/MaterialButton';
import { USER } from '../../constants/routePaths';
import { renderRaceAndEthnicity } from '../../containers/UserList/userTableConfig';
import formatPath from '../../utils/formatPath';

interface Props {
  query: string;
  data?: any;
  producerId?: number;
  first: number;
  hideSearch: () => any;
}

interface TableRow {
  id: number;
  username: string;
  email: string;
  phoneNumber: string;
  firstLanguage: string;
  raceEthnicity: string;
  totalReviews: number;
  lastReview: number;
}

interface State {
  count: number;
}

const RenderTable: React.FC<{ data: TableRow[] }> = ({ data }) => {
  const { t } = useTranslation();

  const columns: TableColumn<TableRow>[] = [
    {
      selector: (row: TableRow) => row.id,
      name: 'User ID',
      sortable: true,
      omit: true,
    },
    {
      selector: (row: TableRow) => row.username,
      name: t('users.username'),
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.email,
      name: t('users.email'),
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.phoneNumber,
      name: t('users.phoneNumber'),
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.firstLanguage,
      name: t('users.firstLanguage'),
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.raceEthnicity,
      name: t('users.raceEthnicity'),
      sortable: true,
    },
    {
      selector: (row: TableRow) => row.totalReviews,
      name: t('users.totalReviews'),
      sortable: true,
    },
  ];

  return <DataTable columns={columns} data={data} fixedHeader striped />;
};

class UserSearch extends React.Component<Props & WithTranslation, State> {
  node = null;

  constructor(props) {
    super(props);
    this.state = {
      count: props.first,
    };
  }

  // Add listener to close the table when it's visible
  componentDidUpdate(prevProps, prevState) {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    // Clicking outside
    if (
      !(this.node && this.node.current && this.node.current.contains(e.target))
    ) {
      this.props.hideSearch();
    }
  };

  getUserData = () => {
    const { data } = this.props;
    if (data && data.user) {
      return data.user.nodes.map((user) => ({
        id: user.id,
        username: (
          <Link key={user.id} to={formatPath(USER, { userId: user.id })}>
            {user.username}
          </Link>
        ),
        email: user.email,
        phoneNumber: user.phoneNumber,
        firstLanguage: user.firstLanguage,
        raceEthnicity: renderRaceAndEthnicity(user.race),
        totalReviews: get(user, 'productReviews.totalCount'),
      }));
    }

    return [];
  };

  onFetchMore = (count) => {
    this.props.data.fetchMore({
      variables: {
        first: count,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        this.setState({
          count: this.state.count + fetchMoreResult.user.nodes.length,
        });
        return Object.assign({}, prev, {
          user: {
            nodes: [
              ...prev.productResults.nodes,
              ...fetchMoreResult.productResults.nodes,
            ],
          },
          ...fetchMoreResult,
        });
      },
    });
  };

  render() {
    const { data, first } = this.props;

    if (data.loading) return <div />;

    return (
      <Paper className={styles.container}>
        <div className={styles.tableContainer}>
          <RenderTable data={this.getUserData()} />
          <div style={{ textAlign: 'center', marginBottom: first }}>
            {data.user.totalCount > data.user.nodes.length && (
              <MaterialButton
                variant="outlined"
                soft
                onClick={() => this.onFetchMore(data.user.nodes.length + first)}
              >
                Load More
              </MaterialButton>
            )}
          </div>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  producerId: selectWorkspaceProducerId(state),
});

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  graphql(UserSearchInWorkspaceQuery, {
    options: ({ query, producerId, first }: Props) => ({
      variables: {
        query,
        producerId,
        first: first,
      },
    }),
  }),
)(UserSearch);
