export interface Partner {
  label: string;
  id: number;
  name: string;
}

export const NonePartner: Partner = {
  label: "None",
  id: null,
  name: "None"
};