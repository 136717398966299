import * as React from 'react';

import { useQuery } from '@apollo/client';
import {
  NoveltyStackRankProductsByReportId,
  ReportNoveltyStackRankProduct,
  ReportNoveltyStackRankProductsResponse,
} from '@graphql/queries/ReportNoveltyStackRankProducts';
import {
  NoveltyStackRankSplitsByReportId,
  ReportNoveltyStackRankSplit,
  ReportNoveltyStackRankSplitsResponse,
} from '@graphql/queries/ReportNoveltyStackRankSplits';
import { Box, Typography } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import { getProductName } from 'components/Report/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { useTranslation } from 'react-i18next';

import NoveltyTree from './NoveltyTree';

export interface ProductNode extends ReportNoveltyStackRankProduct {
  productName: string;
  parentNode: number;
  parentDepth: number;
}

const getSplitNodes = (splitsData: ReportNoveltyStackRankSplitsResponse) =>
  splitsData.allRpNoveltyStackRankingSplits.nodes;

const getProductNodes = (
  splitNodes: ReportNoveltyStackRankSplit[],
  products: ReportNoveltyStackRankProductsResponse,
  productNames: reports.ColorVersionedProductInfo[],
) =>
  products.allRpNoveltyStackRankingProducts.nodes
    .map((n): ProductNode => {
      const parent = splitNodes.filter(
        (d) =>
          d.leftNode == n.noveltyProductNodeId ||
          d.rightNode == n.noveltyProductNodeId,
      )[0];

      return {
        ...n,
        productName: getProductName({
          productNames,
          productId: n.productByProductId.id,
          version: n.version ?? null,
        }),
        parentNode: parent.noveltySplitNodeId,
        parentDepth: parent.depth,
      };
    })
    .sort((b, a) =>
      a.parentDepth == b.parentDepth
        ? b.parentNode - a.parentNode
        : b.parentDepth - a.parentDepth,
    );
interface ReportProps {
  projectId: number;
  reportId: string;
}

const ReportNoveltyTree: React.FC<ReportProps> = (props) => {
  const { projectId, reportId } = props;

  const { t } = useTranslation();

  const productNames = useCustomProductNames({ projectId: Number(projectId) });

  const {
    loading: productsLoading,
    error: productsError,
    data: products,
  } = useQuery<ReportNoveltyStackRankProductsResponse>(
    NoveltyStackRankProductsByReportId,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const {
    loading: splitsLoading,
    error: splitsError,
    data: splitsData,
  } = useQuery<ReportNoveltyStackRankSplitsResponse>(
    NoveltyStackRankSplitsByReportId,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  if (productsLoading || productsError || splitsLoading || splitsError) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <Typography variant="h6" align="center">
        {t('projects.noveltyAnalysis.distanceTree.title')}
      </Typography>
      <NoveltyTree
        productNodes={getProductNodes(
          getSplitNodes(splitsData),
          products,
          productNames,
        )}
        splitNodes={getSplitNodes(splitsData)}
        options={{}}
      />
    </Box>
  );
};

export default ReportNoveltyTree;
