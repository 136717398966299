import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import flexContainer from '../../styles/flexContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      ...flexContainer(theme),
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      alignItems: 'flex-end',
      padding: '40px',
      pointerEvents: 'none',
      zIndex: 1000,
    },
  }),
);

export default useStyles;
