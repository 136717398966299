import { reduxForm } from 'redux-form';

import PartnerBrandingEditContainer from './PartnerBrandingEdit';
import formSubmit from '../../actions/formSubmit';
import { PARTNER_BRANDING_FORM } from '../../constants/formNames';

export default reduxForm({
  form: PARTNER_BRANDING_FORM,
  onSubmit: (values, dispatch) => {
    dispatch(formSubmit(PARTNER_BRANDING_FORM));
  },
})(PartnerBrandingEditContainer);
