import * as React from 'react';

import { ReportNoveltyFpDecompRow } from '@graphql/queries/NoveltyFpDecompByReportIdAndProductIdQuery';
import CSVDownload from 'components/CSVDownload/CSVDownload';

interface Props {
  data: ReportNoveltyFpDecompRow[];
}

const NoveltyFlavorDecompositionTableCsv: React.FC<Props> = ({ data }) => {
  const getCsvHeader = () => [
    { label: 'GG Var', key: 'ggVar' },
    { label: 'Reference Flavor', key: 'referenceFlavor' },
    {
      label: "Flavor Signature's Contribution to Novelty Score ",
      key: 'contributionToNovelPct',
    },
    { label: 'Decomp', key: 'decomp' },
  ];

  const getCsvRow = () => {
    if (data.length < 1) return [];
    return data.map((row) => ({
      ggVar: row.ggVars,
      referenceFlavor: row.referenceFlavor,
      contributionToNovelPct: row.signaturePresent
        ? row.contributionToNovelPct + '% +'
        : row.contributionToNovelPct + '% -',
      decomp: row.signaturePresent ? row.decomp + '% +' : row.decomp + '% -',
    }));
  };

  const getCsvFileName = () => {
    return (
      data
        .map((d) => d.productByProductId)
        .map((p) => p.name + '-')
        .find(Boolean) + 'NoveltyFlavorDecomposition.csv'
    );
  };

  if (data.length < 1) return <></>;

  return (
    <CSVDownload
      headers={getCsvHeader()}
      csvData={getCsvRow()}
      fileName={getCsvFileName()}
    />
  );
};

export default NoveltyFlavorDecompositionTableCsv;
