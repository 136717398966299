import * as React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Divider, IconButton, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import GgVarFlavorIntensityPqChart from 'components/Report/FlavorIntensityCharts/GgVarFlavorIntensityPqChart';
import RefFlavorIntensityPqChart from 'components/Report/FlavorIntensityCharts/RefFlavorIntensityPqChart';
import { useTranslation } from 'react-i18next';

interface Props {
  ggVar: constants.GGVar;
  showDivider: boolean;
  refFlavorTuningData: reports.RefFlavorTuningPlotRow[];
  ggVarTuningData: reports.GgVarTuningPlotRow[];
  decompData: reports.ReportFpDecompRow[];
  minPq: number;
  maxPq: number;
}

const FlavorSummaryChartGroup: React.FC<Props> = (props) => {
  const {
    ggVar,
    showDivider,
    refFlavorTuningData,
    ggVarTuningData,
    decompData,
    minPq,
    maxPq,
  } = props;

  const { t } = useTranslation();

  return (
    <Grid container>
      {showDivider && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}

      <Grid item xs={12}>
        <h5 style={{ textAlign: 'center', paddingTop: '2em' }}>
          <span>
            {t('projects.projectFlavorSummary.chartTitle')} - {ggVar.label}
          </span>
          <Tooltip title={t('projects.projectFlavorSummary.explainer')}>
            <IconButton size="large">
              <InfoOutlinedIcon style={{ fontSize: 24, color: '#777' }} />
            </IconButton>
          </Tooltip>
        </h5>
      </Grid>

      {refFlavorTuningData && refFlavorTuningData.length > 0 && (
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <RefFlavorIntensityPqChart
              refFlavorTuningData={refFlavorTuningData}
              decompData={decompData}
              ggVar={ggVar}
              type="decomp"
              minPq={minPq}
              maxPq={maxPq}
            />
          </Grid>

          <Grid item xs={6}>
            <RefFlavorIntensityPqChart
              refFlavorTuningData={refFlavorTuningData}
              decompData={decompData}
              ggVar={ggVar}
              type="absDecomp"
              minPq={minPq}
              maxPq={maxPq}
            />
          </Grid>
        </Grid>
      )}

      {ggVarTuningData && ggVarTuningData.length > 0 && (
        <Grid container item xs={12}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <GgVarFlavorIntensityPqChart
              ggVarTuningData={ggVarTuningData}
              ggVar={ggVar}
              minPq={minPq}
              maxPq={maxPq}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FlavorSummaryChartGroup;
