import { gql } from '@apollo/client';

export default gql`
  query TextureClustersByReportId($reportID: UUID!, $confidence: BigFloat) {
    allRpTextureClusters(
      condition: { reportId: $reportID, confidence: $confidence }
    ) {
      nodes {
        clusterIdx
        texture
        confidence
      }
    }
  }
`;
