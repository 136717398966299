import { gql } from '@apollo/client';

export default gql`
query GastrographRadarQuery($reportID: UUID!, $productID: Int!) {
  allRpOfps(condition: {reportId: $reportID, productId: $productID, ofpType: GGVAR} ) {
    nodes {
      product: productByProductId {
        id
        name
      }
      ofpType
      attribute
      intensity
      version
    }
  }
}
`;
