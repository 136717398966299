import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportPQNormsQuery from '@graphql/queries/ReportPQNormsQuery';
import { Card, CardContent, CardHeader, useTheme } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useReportSummary, {
  ReportSummaryDetailNode,
} from 'hooks/useReportSummary';

import { getProductName } from '../../Report/utils';
import useStyles from '../useStyles';

interface Props {
  projectId: number;
  reportId: string;
}

const StackRankPqRange: React.FC<Props> = (props) => {
  const { projectId, reportId } = props;

  const productNames = useCustomProductNames({ projectId: Number(projectId) });
  const classes = useStyles(useTheme());

  const {
    loading: reportSummaryLoading,
    error: reportSummaryError,
    data: reportSummaryData,
    pqRange,
    topProduct,
    bottomProduct,
  } = useReportSummary(reportId);

  const {
    loading: normsLoading,
    error: normsError,
    data: normsData,
  } = useQuery<reports.ReportPQNormsResponse>(ReportPQNormsQuery, {
    variables: {
      reportID: reportId,
    },
  });

  if (
    reportSummaryLoading ||
    reportSummaryError ||
    normsLoading ||
    normsError
  ) {
    return <LoadingScreen />;
  }

  const rankHighestText = (
    product: ReportSummaryDetailNode,
    normsData: reports.ReportPQNormsResponse,
  ) => {
    const percentile25 =
      parseFloat(normsData.allRpCategoryNorms.nodes.find(Boolean)?.q1) || 0;
    const percentile75 =
      parseFloat(normsData.allRpCategoryNorms.nodes.find(Boolean)?.q3) || 7;

    if (
      product.node.pq &&
      percentile75 &&
      parseFloat(product.node.pq) > percentile75
    ) {
      return ' ranked highest among all report products and above the 75th percentile of the category.';
    } else if (
      product.node.pq &&
      percentile75 &&
      parseFloat(product.node.pq) < percentile25
    ) {
      return ' ranked highest among all report products, but below the 25th percentile of the category.';
    } else {
      return ' ranked highest among all report products.';
    }
  };

  const rankLowestText = (
    product: ReportSummaryDetailNode,
    normsData: reports.ReportPQNormsResponse,
  ) => {
    const percentile25 =
      parseFloat(normsData.allRpCategoryNorms.nodes.find(Boolean)?.q1) || 0;
    const percentile75 =
      parseFloat(normsData.allRpCategoryNorms.nodes.find(Boolean)?.q3) || 7;

    if (
      product.node.pq &&
      percentile75 &&
      parseFloat(product.node.pq) > percentile75
    ) {
      return ' ranked lowest among all report products, but above the 75th percentile of the category.';
    } else if (
      product.node.pq &&
      percentile75 &&
      parseFloat(product.node.pq) < percentile25
    ) {
      return ' ranked lowest among all report products and below the 25th percentile of the category.';
    } else {
      return ' ranked lowest among all report products.';
    }
  };

  if (!pqRange) {
    return <div></div>;
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <CardHeader title="Stack Rank PQ Range" className={classes.header} />
        <ul>
          {pqRange && (
            <li>{`All products are within ${pqRange.toFixed(2)} PQ range`}</li>
          )}
          {!!topProduct && (
            <li>
              <span className={classes.mainText}>
                {getProductName({
                  productNames,
                  productId: topProduct.node.productByProductId.id,
                  version: topProduct.node.version,
                })}
              </span>
              <span>{rankHighestText(topProduct, normsData)}</span>
            </li>
          )}
          {!!bottomProduct && (
            <li>
              <span className={classes.mainText}>
                {getProductName({
                  productNames,
                  productId: bottomProduct.node.productByProductId.id,
                  version: bottomProduct.node.version,
                })}
              </span>
              <span>{rankLowestText(bottomProduct, normsData)}</span>
            </li>
          )}
        </ul>
      </CardContent>
    </Card>
  );
};

export default StackRankPqRange;
