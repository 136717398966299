import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import useReportSummary, {
  ReportDetailRow,
  ReportSummaryResponse,
} from 'hooks/useReportSummary';
import { useTranslation } from 'react-i18next';

interface ReportProps {
  reportId: string;
  productId: number;
  version: string;
}

const ProductSummaryTable: React.FC<ReportProps> = (props) => {
  const { reportId, productId, version } = props;

  const { t } = useTranslation();

  const { loading, error, data } = useReportSummary(reportId);

  const getProductRow = (
    summary: ReportSummaryResponse,
    productId: number,
    version: string,
  ): ReportDetailRow => {
    return summary.allReportJobs.nodes
      .flatMap((n) => n.reportSummariesByReportId)
      .flatMap((e) => e.edges)
      .map((n) => n.node)
      .find(
        (r) => r.productByProductId.id === productId && r.version === version,
      );
  };

  if (loading || error) {
    return <div />;
  }

  return (
    <div key={`reportProductSummary-${productId}`}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right">
              <Tooltip
                title={
                  <div>
                    <em>{t('reports.prodSummary.pq')}</em>
                    {t('explainers.pqContent')}
                  </div>
                }
                arrow
              >
                <div>{t('reports.prodSummary.pq')}</div>
              </Tooltip>
            </TableCell>
            <TableCell align="right">
              <Tooltip
                title={
                  <div>
                    <em>{t('reports.prodSummary.winRate')}</em>
                    {t('explainers.winRateContent')}
                  </div>
                }
                arrow
              >
                <div>{t('reports.prodSummary.winRate')}</div>
              </Tooltip>
            </TableCell>
            <TableCell align="right">
              <Tooltip
                title={
                  <div>
                    <em>{t('reports.summary.polarization')}</em>
                    {t('explainers.polarizationContent')}
                  </div>
                }
                arrow
              >
                <div>{t('reports.summary.polarization')}</div>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right">
              {parseFloat(getProductRow(data, productId, version).pq).toFixed(
                2,
              )}
            </TableCell>
            <TableCell align="right">
              {parseFloat(
                getProductRow(data, productId, version).pComp,
              ).toFixed(0)}
              %
            </TableCell>
            <TableCell align="right">
              {parseFloat(
                getProductRow(data, productId, version).polarization,
              ).toFixed(2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default ProductSummaryTable;
