import { allRolesLower } from 'constants/userRoles';

import jwt_decode from 'jwt-decode';

export default function sessionToken(state): Set<string> {
  try {
    const sessionToken = state.session && state.session.token;
    const tokeRoles = jwt_decode<IAMToken>(sessionToken)?.roles || [];
    return new Set(tokeRoles.filter((x) => allRolesLower.includes(x)));
  } catch (err) {
    console.warn(err);
    return new Set();
  }
}
