import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Search as SearchIcon } from '@mui/icons-material';
import {
  Paper,
  Input,
  InputAdornment,
  LinearProgress,
  IconButton,
} from '@mui/material';
import RDTFirstPage from 'components/ReactDataTable/RDTFirstPage';
import RDTLastPage from 'components/ReactDataTable/RDTLastPage';
import RTDNextPage from 'components/ReactDataTable/RDTNextPage';
import RDTPrevPage from 'components/ReactDataTable/RDTPrevPage';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import styles from './UserDemographicList.module.css';
import UserDemographicListQuery from '../../graphql/queries/UserDemographicListQuery';
import { PageHeader, PageTitle } from '../../styles/themeComponents';
import { renderRaceAndEthnicity } from '../UserList/userTableConfig';

const columnStyle = (cell) =>
  cell ? {} : { backgroundColor: 'var(--coral-pink)' };

interface TableRow {
  id: number;
  username: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  firstLanguage: string;
  race: string;
  gender: string;
  smoke: string;
  country: string;
  nationalIdentity: string;
  dietaryRestrictions: string;
}

const UserDemographicList: React.FC = () => {
  const [sizePerPage, setSizePerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [orderField, setOrderField] = useState('id');
  const [orderDir, setOrderDir] = useState<'asc' | 'desc'>('desc');
  const [searchString, setSearchString] = useState('');
  const [filterString, setFilterString] = useState('');
  const { t } = useTranslation();

  const columns: TableColumn<TableRow>[] = [
    {
      id: 'id',
      selector: (row: TableRow) => row.id,
      name: 'User ID',
      sortable: true,
    },
    {
      id: 'username',
      selector: (row: TableRow) => row.username,
      name: <div className={styles.tableHeader}>{t('users.username')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'email',
      selector: (row: TableRow) => row.email,
      name: <div className={styles.tableHeader}>{t('users.email')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'phoneNumber',
      selector: (row: TableRow) => row.phoneNumber,
      name: <div className={styles.tableHeader}>{t('users.phoneNumber')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'dateOfBirth',
      selector: (row: TableRow) => row.dateOfBirth,
      name: <div className={styles.tableHeader}>{t('users.dateOfBirth')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'firstLanguage',
      selector: (row: TableRow) => row.firstLanguage,
      name: (
        <div className={styles.tableHeader}>{t('users.firstLanguage')}</div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      id: 'race',
      selector: (row: TableRow) => row.race,
      name: (
        <div className={styles.tableHeader}>{t('users.raceEthnicity')}</div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      id: 'gender',
      selector: (row: TableRow) => row.gender,
      name: <div className={styles.tableHeader}>{t('users.gender')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'smoke',
      selector: (row: TableRow) => row.smoke,
      name: <div className={styles.tableHeader}>{t('users.smoke')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'country',
      selector: (row: TableRow) => row.country,
      name: <div className={styles.tableHeader}>{t('users.country')}</div>,
      sortable: true,
      wrap: true,
    },
    {
      id: 'nationalIdentity',
      selector: (row: TableRow) => row.nationalIdentity,
      name: (
        <div className={styles.tableHeader}>{t('users.nationalIdentity')}</div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      id: 'dietaryRestrictions',
      selector: (row: TableRow) => row.dietaryRestrictions,
      name: (
        <div className={styles.tableHeader}>
          {t('users.dietaryRestrictions')}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
  ];

  const {
    loading,
    data: userResults,
    refetch,
  } = useQuery(UserDemographicListQuery, {
    variables: {
      first: sizePerPage,
      orderBy: 'ID_DESC',
      filter: filterString
        ? isNaN(Number(filterString))
          ? {
              or: [
                {
                  username: {
                    includesInsensitive: filterString,
                  },
                },
                {
                  email: {
                    includesInsensitive: filterString,
                  },
                },
              ],
            }
          : {
              or: [
                {
                  id: {
                    equalTo: parseInt(filterString),
                  },
                },
              ],
            }
        : undefined,
    },
  });

  const handleTableChange = (
    type,
    { page: newPage, sizePerPage: newSize, sortField, sortOrder },
  ) => {
    let newOrderBy = 'ID_DESC';
    if (type === 'sort') {
      switch (sortField) {
        case 'username':
          newOrderBy = sortOrder === 'asc' ? 'USERNAME_ASC' : 'USERNAME_DESC';
          break;
        case 'email':
          newOrderBy = sortOrder === 'asc' ? 'EMAIL_ASC' : 'EMAIL_DESC';
          break;
        case 'phoneNumber':
          newOrderBy =
            sortOrder === 'asc' ? 'PHONE_NUMBER_ASC' : 'PHONE_NUMBER_DESC';
          break;
        case 'id':
          newOrderBy = sortOrder === 'asc' ? 'ID_ASC' : 'ID_DESC';
          break;
        default:
          setOrderField(sortField);
          setOrderDir(sortOrder);
          return;
      }
    }

    const variables: any = {
      orderBy: newOrderBy,
    };

    if (newPage > page) {
      variables.before = undefined;
      variables.after = userResults.allUsers.pageInfo.endCursor;
    } else if (newPage < page) {
      variables.after = undefined;
      variables.before = userResults.allUsers.pageInfo.startCursor;
    } else if (newSize !== sizePerPage || orderField !== newOrderBy) {
      variables.first = newSize;
      variables.after = undefined;
      variables.before = undefined;
      newPage = 1;
    }

    refetch(variables).then(() => {
      setPage(newPage);
      setSizePerPage(newSize);
      setOrderDir(sortOrder);
      setOrderField(sortField);
    });
  };

  if (loading) {
    return <LinearProgress />;
  }

  const data =
    userResults &&
    userResults.allUsers &&
    userResults.allUsers.nodes.map((node) => ({
      id: node.id,
      username: node.username,
      email: node.email,
      name: node.name,
      phoneNumber: node.phoneNumber,
      dateOfBirth: node.dateOfBirth,
      ethnicity: node.ethnicity,
      firstLanguage: node.firstLanguage,
      gender: node.gender,
      race: renderRaceAndEthnicity(node.race),
      smoke: node.smoke,
      hometown: node.hometown,
      country: node.country,
      nationalIdentity: node.nationalIdentity,
      dietaryRestrictions: node.dietaryRestrictions,
      province: node.province,
      city: node.city,
      educationalAttainment: node.educationalAttainment,
    }));

  if (
    orderField !== 'name' &&
    orderField !== 'id' &&
    orderField !== 'username' &&
    orderField !== 'email' &&
    orderField !== 'phoneNumber'
  ) {
    data.sort(
      (a, b) =>
        (a[orderField] || '').localeCompare(b[orderField] || '') *
        (orderDir === 'asc' ? 1 : -1),
    );
  }

  return (
    <Paper className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTextContainer}>
          <PageHeader>{t('navigation.users')}</PageHeader>
          <PageTitle>{t('users.usersAdmin')}</PageTitle>
        </div>
        <Input
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setFilterString(searchString)} size="large">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          onChange={(event) => setSearchString(event.target.value)}
          value={searchString}
          placeholder={t('general.search')}
          onKeyPress={(e) => e.key === 'Enter' && setFilterString(searchString)}
        />
      </div>
      <div>
        <DataTable
          columns={columns}
          data={data}
          fixedHeader
          striped
          pagination
          paginationIconFirstPage={<RDTFirstPage />}
          paginationIconLastPage={<RDTLastPage />}
          paginationIconPrevious={<RDTPrevPage />}
          paginationIconNext={<RTDNextPage />}
        />
      </div>
    </Paper>
  );
};

export default UserDemographicList;
