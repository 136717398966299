import * as React from 'react';
import { useState } from 'react';

import { FetchResult, gql, useQuery } from '@apollo/client';
import AllWorkspacesQuery from '@graphql/queries/AllWorkspacesQuery';
import PreferencesQueryByUserProducerID from '@graphql/queries/PreferencesQueryByUserProducerID';
import MaterialButton from 'components/MaterialButton';
import graphqlClient from 'consumers/graphqlClient';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import selectViewerUserId from 'selectors/viewerUserId';
import {
  CustomerPreferences,
  PreferencesResponse,
  defaultPreferences,
  mergePreferences,
} from 'services/customerPreferences';

import ObjectEditor from '../../components/ObjectEditor';

const updateProducerPreferenceMutation = gql`
  mutation updateProducerPreference(
    $updateProducerPreferencePayload: UpdateProducerPreferenceByProducerIdInput!
  ) {
    updateProducerPreferenceByProducerId(
      input: $updateProducerPreferencePayload
    ) {
      producerPreference {
        producerId
        preference
      }
    }
  }
`;

const ProducerPreference: React.FC = () => {
  const {
    data: allWorkspacesData,
    loading: workspacesLoading,
    error: workspacesError,
  } = useQuery(AllWorkspacesQuery);
  const [workspace, setWorkspace] = useState<{
    label: string;
    value: number;
  } | null>();
  const viewerUserId = useSelector((state) => selectViewerUserId(state));

  const [preferencesToUpdate, setPreferencesToUpdate] =
    useState<CustomerPreferences>(null);
  const [mutationResult, setMutationResult] = useState<FetchResult>(null);

  useQuery<PreferencesResponse>(PreferencesQueryByUserProducerID, {
    variables: {
      userId: viewerUserId,
      producerId: workspace ? workspace.value : null,
    },
    onCompleted(data) {
      setPreferencesToUpdate(mergePreferences(defaultPreferences, data));
    },
    fetchPolicy: 'no-cache',
    skip: !workspace,
  });

  async function updateProducerPreference(
    updatedPreference: CustomerPreferences,
    producerId: number,
  ) {
    const payload = {
      producerId,
      producerPreferencePatch: {
        producerId,
        preference: updatedPreference,
      },
    };

    await graphqlClient
      .mutate({
        mutation: updateProducerPreferenceMutation,
        variables: { updateProducerPreferencePayload: payload },
      })
      .then((result) => setMutationResult(result));
  }

  if (workspacesLoading || workspacesError || !allWorkspacesData) {
    return <div />;
  }

  return (
    <div>
      <Select
        name="workspace"
        placeholder="Select Workspace"
        options={allWorkspacesData.allProducers.nodes.map((item) => ({
          value: item.id,
          label: item.name,
        }))}
        isSearchable
        //isDisabled={isEditing}
        isClearable
        value={workspace}
        onChange={(selected) => setWorkspace(selected)}
      />
      {!!preferencesToUpdate && (
        <div>
          <br />
          <h4>Configure Preferences</h4>
          <ObjectEditor
            objectToEdit={JSON.parse(JSON.stringify(preferencesToUpdate))}
            explicitInputTypes={{
              projectSummary: {
                tabOrder: {
                  inputType: 'select',
                  options: ['default', 'mapFirst'],
                },
              },
              marketMap: {
                heatMapShape: {
                  inputType: 'select',
                  options: ['hex', 'rect'],
                },
                zoomBehavior: {
                  inputType: 'select',
                  options: ['panzoom', 'microscope'],
                },
                heatMapColors: {
                  inputType: 'select',
                  options: ['default', 'bright_green'],
                },
                labelFont: {
                  inputType: 'select',
                  options: [
                    'Arial',
                    'Montserrat',
                    'Times New Roman',
                    'serif',
                    'monospace',
                  ],
                },
                labelColor: {
                  inputType: 'select',
                  options: ['default', 'pdf'],
                },
                labelStroke: {
                  inputType: 'select',
                  options: ['labelColor', 'white', 'clear'],
                },
                labelSize: {
                  inputType: 'select',
                  options: ['default', 'large'],
                },
                labelArrayType: {
                  inputType: 'select',
                  options: [
                    'shortGreek',
                    'longGreek',
                    'shortAlphabet',
                    'longAlphabet',
                  ],
                },
              },
            }}
            onChange={(newValue) =>
              setPreferencesToUpdate(newValue as unknown as CustomerPreferences)
            }
          />
          <MaterialButton
            style={{ marginTop: '1em' }}
            variant="outlined"
            soft
            teal
            onClick={() =>
              updateProducerPreference(preferencesToUpdate, workspace.value)
            }
          >
            Submit
          </MaterialButton>
        </div>
      )}
      {mutationResult && (
        <div>
          {mutationResult.errors && (
            <div style={{ color: 'red' }}>An error occurred.</div>
          )}
          {!mutationResult.errors && (
            <div style={{ color: 'green' }}>Customer preferences updated.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProducerPreference;
