import * as React from 'react';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Box } from '@mui/material';
import { CSVLink } from 'react-csv';
import { Data, Headers } from 'react-csv/lib/core';

interface Props {
  fileName: string;
  csvData: Data;
  headers?: Headers;
}

const CSVDownload: React.FC<Props> = (props) => {
  const { fileName, csvData, headers } = props;

  return (
    <Box display="flex" justifyContent="flex-end">
      <CSVLink
        headers={headers}
        data={csvData}
        filename={fileName}
        asyncOnClick={true}
      >
        <CloudDownloadIcon fontSize="medium" color="primary" />
      </CSVLink>
    </Box>
  );
};

export default CSVDownload;
