import { gql } from '@apollo/client';

export default gql`
  mutation UpdateReportProjectName($jobId: UUID!, $projectName: String!) {
    updateReportJobByJobId(
      input: { jobId: $jobId, reportJobPatch: { projectName: $projectName } }
    ) {
      reportJob {
        reportId
        projectName
      }
    }
  }
`;
