import { useState } from 'react';
import * as React from 'react';

import { useQuery } from '@apollo/client';
import { FormControl, LinearProgress, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

import ProductCategoryTable from '../../components/ProductCategoryTable/ProductCategoryTable';
import AllProductCategoriesQuery from '../../graphql/queries/AllProductCategoriesQuery';

const ViewCategoriesAndProducts: React.FC = () => {
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));

  const [categoryName, setCategoryName] = useState<string>('');

  const { data, loading, error } = useQuery(AllProductCategoriesQuery, {
    variables: {
      condition: {
        producerId: producerId,
      },
      orderBy: 'NAME_ASC',
    },
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <h4>Can't load categories!</h4>;
  }

  return (
    <div style={{ width: '100%' }}>
      <FormControl variant="standard" fullWidth>
        <Select
          variant="standard"
          style={{ width: '100%' }}
          id="categories-select"
          fullWidth
          value={categoryName}
          label="Name"
          aria-label="auto"
          onChange={(e) => setCategoryName(e.target.value.toString())}
        >
          {data.productCategories.nodes.map((item) => (
            <MenuItem value={item.name}>{item.name}</MenuItem>
          ))}
        </Select>
        {categoryName && <ProductCategoryTable categoryName={categoryName} />}
      </FormControl>
    </div>
  );
};

export default ViewCategoriesAndProducts;
