import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportStackRankQuery from '@graphql/queries/ReportStackRankQuery';
import useCustomProductNames from 'hooks/useCustomProductNames';
import { chain, reduce, round, some } from 'lodash';
import { useTranslation } from 'react-i18next';

import BoxPlot from './BoxPlot';
import { getProductName } from '../utils';

interface StackRankProps {
  reportId: string;
}

interface ReportStackRankComputed {
  label: string;
  mean: number;
  variance: number;
  symbol?: string;
  ranking?: number;
}

const ReportStackRanks: React.FC<StackRankProps> = (props) => {
  const { reportId } = props;

  const { t } = useTranslation();

  const pqJitter = 0.2;

  const productNames = useCustomProductNames({ reportId });

  const { loading, error, data } = useQuery<reports.ReportStackRankResponse>(
    ReportStackRankQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const computeStackRanks = (
    data: reports.ReportStackRankResponse,
    productNames: reports.ColorVersionedProductInfo[],
  ): ReportStackRankComputed[] => {
    const symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    const unique_products: reports.VersionedProductInfo[] =
      data.allRpMarketPreferences.nodes
        .map((n) => ({
          name: n.product.name,
          id: n.product.id,
          version: n.version,
        }))
        .reduce((acc, item) => (some(acc, item) ? acc : acc.concat(item)), []);

    const products_aggregated: ReportStackRankComputed[] = unique_products.map(
      (product) => {
        const product_to_aggregate = data.allRpMarketPreferences.nodes.filter(
          (p) => p.product.id === product.id && p.version === product.version,
        );

        // 100 should be replaced with sans rejector threshold where applicable
        const mean_value =
          reduce(
            product_to_aggregate,
            (sum, p) => sum + p.pqRating * p.percentPop,
            0,
          ) / 100;
        const summed_percentPop = reduce(
          product_to_aggregate,
          (sum, p) => sum + p.percentPop,
          0,
        );
        const variance_numerator =
          reduce(
            product_to_aggregate,
            (sum, p) => sum + p.pqRating ** 2 * p.percentPop,
            0,
          ) / summed_percentPop;
        const variance_denominator =
          ((mean_value * 100) / summed_percentPop) ** 2;
        const variance_value = round(
          variance_numerator / variance_denominator,
          3,
        );

        return {
          label: getProductName({
            productNames,
            productId: product.id,
            version: product.version,
          }),
          mean: round(mean_value, 3),
          variance: variance_value,
        };
      },
    );

    return chain(products_aggregated)
      .sortBy(['mean'])
      .reverse()
      .forEach((p, index, products) => {
        p['symbol'] = symbols[index];
        p['ranking'] = index + 1;
        // If the difference in the mean PQ between two products in less than pqJitter, they will be regarded as having the same PQ
        if (index > 0) {
          if (Math.abs(p.mean - products[index - 1].mean) <= pqJitter) {
            p.mean = products[index - 1].mean;
          }
        }
      })
      .value();
  };

  if (loading || error) {
    return <div />;
  }

  return (
    <div>
      <h4>{t('reports.stackRanks.title')}</h4>
      <BoxPlot
        data={computeStackRanks(data, productNames)}
        pqJitter={pqJitter}
      />
      <div>
        {computeStackRanks(data, productNames).map((d) => (
          <div key={d.symbol}>
            {d.symbol}: {d.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportStackRanks;
