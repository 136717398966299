import styled from 'styled-components';

export const menuSectionClassName = 'menuSection';
export const notificationSectionClassName = 'notificationSection';

export const StyledContainerDiv = styled.div`
  align-items: 'stretch';
  display: 'flex';
  flex-direction: 'column';
  z-index: 100;

  > .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    width: 500px;
  }

  > .backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.02);
  }

  > .notificationIcon {
    position: relative;
    right: 60px;
    /* top: 27px; */

    > .toggleNotificationIsOpenButton {
      height: 50px;
      display: flex;
      flex-direction: row;
      margin-right: 15px;
      align-items: center;
    }

    > .notificationWrapper {
      transition: all 0.22s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      position: absolute;
      top: 100%;
      right: 0px;

      opacity: 0;
      visibility: hidden;

      > .notification {
        align-items: 'stretch';
        display: 'flex';
        flex-direction: 'column';
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
        font-size: 14px;
        min-width: 475px;

        > .${notificationSectionClassName} {
          align-items: 'stretch';
          display: 'flex';
          flex-direction: 'column';
          border-bottom: 1px solid #e4ede5;
          padding: 10px 0;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      &.open {
        opacity: 1;
        padding-top: 9px;
        visibility: visible;
      }
    }
  }

  > .relativeWrapper {
    position: relative;
    top: -50px;

    > .toggleMenuIsOpenButton {
      height: 50px;
      display: flex;
      flex-direction: row;
      margin-right: 24px;
      align-items: center;
    }

    > .menuWrapper {
      transition: all 0.22s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      position: absolute;
      top: 100%;
      right: 8px;

      opacity: 0;
      visibility: hidden;

      > .menu {
        align-items: 'stretch';
        display: 'flex';
        flex-direction: 'column';
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
        font-size: 14px;
        min-width: 185px;

        > .${menuSectionClassName} {
          align-items: 'stretch';
          display: 'flex';
          flex-direction: 'column';
          border-bottom: 1px solid #e4ede5;
          padding: 10px 0;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      &.open {
        opacity: 1;
        padding-top: 13px;
        visibility: visible;
      }
    }
  }
`;
