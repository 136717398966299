import React, { Component } from 'react';

import { Select, MenuItem, TextField, InputLabel } from '@mui/material';
import { Formik, Form, FieldArray, FieldProps, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

import styles from './CustomLexiconCreateContainer.module.css';
import MaterialButton from '../../../components/MaterialButton';

const CustomLexiconCreateContainer = ({
  flavor,
  createCustomLexicon,
  closeCreateModal,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.sectionContainer}>
        <Formik
          initialValues={{
            flavorAttribute: flavor,
            value: '',
          }}
          validate={(values) => {
            const errors = {};
            if (!values.value.length) {
              errors.value = 'Value cannot be empty.';
            }
            return errors;
          }}
          onSubmit={async (values) => {
            const { flavorAttribute, value } = values;
            await createCustomLexicon(flavorAttribute, value);
          }}
          render={({ values, handleChange, handleSubmit, errors }) => (
            <Form>
              <div className={styles.mainContainer}>
                <div className={styles.subContainer}>
                  <div className={styles.header}>{flavor}</div>
                  <div className={styles.fieldContainer}>
                    <InputLabel>{t('customLexicon.value')}</InputLabel>
                    <TextField
                      variant="standard"
                      fullWidth
                      name="value"
                      value={values.value}
                      onChange={handleChange}
                    />
                    <span className={styles.error}>
                      {errors && errors.value}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <MaterialButton
                  variant="outlined"
                  soft
                  onClick={closeCreateModal}
                >
                  {'Cancel'}
                </MaterialButton>
                <MaterialButton
                  variant="outlined"
                  soft
                  teal
                  onClick={handleSubmit}
                >
                  {'Save'}
                </MaterialButton>
              </div>
            </Form>
          )}
        />
      </div>
    </div>
  );
};

export default withTranslation()(CustomLexiconCreateContainer);
