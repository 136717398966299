import * as React from 'react';

import { ComputedDatum, DefaultRawDatum } from '@nivo/pie';
import { BasicTooltip } from '@nivo/tooltip';

interface Props {
  datum: ComputedDatum<DefaultRawDatum>;
}

export const ResponsivePieToolTip: React.FC<Props> = ({ datum }) => {
  return (
    <BasicTooltip
      id={
        <span>
          {datum.id}: {datum.value}%
        </span>
      }
      enableChip={true}
      color={datum.color}
    />
  );
};
