import * as React from 'react';

import { useQuery } from '@apollo/client';
import ReportWorkspacesSummaryQuery from '@graphql/queries/ReportWorkspacesSummaryQuery';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../styles/theme';

interface ReportProps {
  reportId: string;
  isModal: boolean;
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: COLORS.MARINE,
    color: 'white',
    fontSize: '14px',
    borderRadius: '8px',
  },
}));

const ReportDataCollectedTable: React.FC<ReportProps> = (props) => {
  const { reportId, isModal } = props;
  const classes = useStyles();

  const { t } = useTranslation();

  const { loading, error, data } =
    useQuery<reports.ReportWorkspacesSummaryResponse>(
      ReportWorkspacesSummaryQuery,
      {
        variables: {
          reportID: reportId,
        },
      },
    );

  if (loading || error) {
    return <div />;
  }

  const getReshapedData = (
    data: reports.ReportWorkspacesSummaryResponse,
  ): reports.WorkspaceSummaryRow[] => {
    return data?.allRpWorkspaceSummaries?.nodes.map((row, idx) => ({
      rowIdx: idx,
      branch: row.branch,
      reviewsConsidered: row.reviewsConsidered,
      reviewsAnalyzed: row.reviewsAnalyzed,
      signaturesConsidered: row.signaturesConsidered,
      signaturesAnalyzed: row.signaturesAnalyzed,
    }));
  };

  return (
    <Table
      size="small"
      style={
        isModal
          ? {
              backgroundColor: 'white',
              width: '60%',
              borderRadius: 10,
            }
          : {}
      }
    >
      <TableHead>
        <TableRow>
          <TableCell>{t('reports.workspaceSummary.dataCollected')}</TableCell>
          <TableCell align="right">
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                'Total number of reviews analyzed for their project. This sets the context for the models to train. For instance, this goes down when we have a custom class DB for example.'
              }
            >
              <div style={{ display: 'inline-block' }}>
                {t('reports.workspaceSummary.reviewsConsidered')}
              </div>
            </Tooltip>
          </TableCell>
          <TableCell align="right">
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                'Total number of individual product reviews within the competitive set.'
              }
            >
              <div style={{ display: 'inline-block' }}>
                {t('reports.workspaceSummary.reviewsAnalyzed')}
              </div>
            </Tooltip>
          </TableCell>
          <TableCell align="right">
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                'Number of distinct identifiable flavor signatures within their trunk for this category.'
              }
            >
              <div style={{ display: 'inline-block' }}>
                {t('reports.workspaceSummary.signaturesConsidered')}
              </div>
            </Tooltip>
          </TableCell>
          <TableCell align="right">
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                'Total number of distinct flavors identified in the decomp tables.'
              }
            >
              <div style={{ display: 'inline-block' }}>
                {t('reports.workspaceSummary.signaturesAnalyzed')}
              </div>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      {
        <TableBody>
          {getReshapedData(data).map((row, i) => (
            <TableRow key={'workspacesummary_' + i}>
              <TableCell>{row.branch}</TableCell>
              <TableCell align="right">{row.reviewsConsidered}</TableCell>
              <TableCell align="right">{row.reviewsAnalyzed}</TableCell>
              <TableCell align="right">{row.signaturesConsidered}</TableCell>
              <TableCell align="right">{row.signaturesAnalyzed}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      }
    </Table>
  );
};

export default ReportDataCollectedTable;
