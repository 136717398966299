import { gql } from '@apollo/client';

export default gql`
  query ReportSummaryByReportIdAndProductIdQuery(
    $productId: Int
    $reportId: UUID
  ) {
    allReportSummaries(
      filter: {
        productId: { equalTo: $productId }
        reportId: { equalTo: $reportId }
      }
    ) {
      nodes {
        version
        pq
        pComp
        polarization
        version
      }
    }
  }
`;
