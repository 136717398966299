export const adminUserRole = process.env.REACT_APP_ADMIN_USER_ROLE || 'GASTROGRAPH_ADMIN';
export const superadminUserRole = process.env.REACT_APP_SUPERADMIN_USER_ROLE || 'GASTROGRAPH_SUPERADMIN';
export const workspaceAdminRole = process.env.REACT_APP_WORKSPACE_ADMIN_ROLE || 'WORKSPACE_ADMIN';
export const panelManageRole = process.env.REACT_APP_PANEL_MANAGE_ROLE || 'PANEL_MANAGE';
export const analysisViewRole = process.env.REACT_APP_ANALYSIS_VIEW_ROLE || 'ANALYSIS_VIEW';
export const productReviewRole = process.env.REACT_APP_PRODUCT_REVIEW_ROLE || 'PRODUCT_REVIEW';
export const textureViewerRole = process.env.REACT_APP_TEXTURE_VIEWER_ROLE || 'TEXTURE_VIEWER';
export const partnerAdminRole = process.env.REACT_APP_PARTNER_ADMIN_ROLE || 'PARTNER_ADMIN';

export const allRoles = [
  adminUserRole,
  superadminUserRole,
  workspaceAdminRole,
  panelManageRole,
  analysisViewRole,
  productReviewRole,
  textureViewerRole,
  partnerAdminRole,
];

export const allRolesLower = allRoles.map((role) => role.toLowerCase());