import * as React from 'react';

import { Card, CardHeader, CardContent } from '@mui/material';
import useStyles from 'containers/ProductCreate/ProductClassAttributes/useStyles';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import RenderProductClassAttributes from './RenderProductClassAttributes';
import { PRODUCT_CLASS_ATTRIBUTES } from '../../../constants/productClassAttributes';


interface Props {
  productClass: string;
}

const ProductClassAttributes: React.FunctionComponent<Props> = ({
  productClass,
}) => {
  const currentProductClassAttributes = pick(PRODUCT_CLASS_ATTRIBUTES, [
    productClass,
  ])[productClass];
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      {currentProductClassAttributes ? (
        <Card className={classes.root}>
          <CardHeader
            classes={{
              title: classes.cardTitle,
              subheader: classes.cardSubheader,
            }}
            title={productClass}
            subheader={t('product.defaultAttributes')}
          />
          <CardContent>
            <RenderProductClassAttributes
              productClass={productClass}
              currentProductClassAttributes={currentProductClassAttributes}
            />
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
};

export default ProductClassAttributes;
