import * as d3hb from 'd3-hexbin';

import {
  CustomerPreferences,
  heatMapCutoff,
  zoomBehavior,
} from 'services/customerPreferences';

import { RectbinBin } from '../RectBin/rectbin';

export const meanPq = (dataArray: marketmap.HeatMapData[]): number => {
  if (dataArray && dataArray.length > 0) {
    return (
      dataArray.map((d) => +d.pq).reduce((a, b) => a + b) / dataArray.length
    );
  } else {
    return 0;
  }
};

export const maxPq = (dataArray: marketmap.HeatMapData[]): number => {
  if (dataArray && dataArray.length > 0) {
    return dataArray.map((d) => +d.pq).reduce((a, b) => (a > b ? a : b));
  } else {
    return 0;
  }
};

export const minPq = (dataArray: marketmap.HeatMapData[]): number => {
  if (dataArray && dataArray.length > 0) {
    return dataArray.map((d) => +d.pq).reduce((a, b) => (a > b ? b : a));
  } else {
    return 0;
  }
};

export const rotateToDiagonal = (ggVarArray: marketmap.LabelData[]): number => {
  if (!ggVarArray) return 0;

  const computed = -longestVectorAngle(ggVarArray) + Math.PI / 4;

  if (computed < 0) {
    return computed + 2 * Math.PI;
  } else if (computed > 2 * Math.PI) {
    return computed - 2 * Math.PI;
  } else {
    return computed;
  }
};

export const computeLabelFontSize = (
  r: number,
  zoom: number,
  labelFactor: number,
) => {
  return (r / 240) * (0.5 * (zoom - 1) + 1) * labelFactor * 14;
};

export const getHoveredProducts = (
  visibleProducts: marketmap.ProductVectorData[],
  d: any,
  fontSize: number,
): { productName: string; productPq: number; productLabel: string }[] => {
  const hoveredProduct: marketmap.ProductVectorData = d.currentTarget.__data__;

  return visibleProducts
    .filter((d) => distance(d, hoveredProduct) < fontSize / 140)
    .map((p) => ({
      productName: p.name,
      productPq: p.pq,
      productLabel: p.label,
    }));
};

export const distance = (
  v1: marketmap.LocationData,
  v2: marketmap.LocationData,
) => {
  if (v1.ld1 && v2.ld1 && v1.ld2 && v2.ld2) {
    return Math.sqrt((v1.ld1 - v2.ld1) ** 2 + (v1.ld2 - v2.ld2) ** 2);
  } else {
    return Number.MAX_VALUE;
  }
};

export const longestVectorAngle = (v: marketmap.LabelData[]): number => {
  if (v && v.length > 0) {
    return (
      v
        .map((i) => ({ label: i.label, angle: i.angle, dist: vectorLen(i) }))
        .reduce((a, c) => (a ? (a.dist > c.dist ? a : c) : c), null).angle ?? 0
    );
  } else {
    return 0;
  }
};

export const vectorLen = (v: marketmap.LocationData) => {
  if (v) {
    return Math.sqrt(v.ld1 ** 2 + v.ld2 ** 2);
  } else {
    return 0;
  }
};

export const computeRMax = (dataArray: marketmap.LocationData[]): number => {
  if (dataArray) {
    return Math.max(...dataArray.map(vectorLen));
  } else {
    return 0;
  }
};

export const computePixelId = (dataArray: marketmap.HeatMapData[]): string => {
  if (dataArray && dataArray.length > 0) {
    return dataArray
      .map((d) => (d.pixelId ? 'r' + d.pixelId : 'p' + d.productId))
      .join(',');
  } else {
    return '';
  }
};

export function rotateScaled<T extends marketmap.HeatMapData>(
  array: T[],
  rotationAngle: number,
  r_cutoff: number = 1,
): T[] {
  return array
    .map((item) => ({
      ...item,
      r: vectorLen(item),
      ld1r: rotateld1(item.ld1 / r_cutoff, item.ld2 / r_cutoff, rotationAngle),
      ld2r: rotateld2(item.ld1 / r_cutoff, item.ld2 / r_cutoff, rotationAngle),
    }))
    .map((item) => rotateLds(item, rotationAngle));
}

export function rotateLabelData<T extends marketmap.LabelData>(
  array: T[],
  rotationAngle: number,
): T[] {
  return array
    .map((item) => rotateLds(item, rotationAngle))
    .map((item) => ({
      ...item,
      angle: item.angle + rotationAngle,
      x: rotateld1(item.ld1, item.ld2, rotationAngle),
      y: rotateld2(item.ld2, item.ld2, rotationAngle),
    }));
}

export function rotate<T extends marketmap.LocationData>(
  array: T[],
  rotationAngle: number,
): T[] {
  return array.map((item) => rotateLds(item, rotationAngle));
}

export function rotateLds<T extends marketmap.LocationData>(
  item: T,
  rotationAngle: number,
): T {
  return {
    ...item,
    ld1: rotateld1(item.ld1, item.ld2, rotationAngle),
    ld2: rotateld2(item.ld1, item.ld2, rotationAngle),
  };
}

export function rotateld1(
  inX: number,
  inY: number,
  rotationAngle: number,
): number {
  return inX * Math.cos(rotationAngle) - inY * Math.sin(rotationAngle);
}

export function rotateld2(
  inX: number,
  inY: number,
  rotationAngle: number,
): number {
  return inY * Math.cos(rotationAngle) + inX * Math.sin(rotationAngle);
}

export function rZoomBehavior(
  r: number,
  zoom: number,
  customerPreferences: CustomerPreferences,
): number {
  switch (zoomBehavior(customerPreferences)) {
    case 'panzoom':
      return r * zoom;
    case 'microscope':
    default:
      return r;
  }
}

export function outerRingClippingPathR(
  r: number,
  zoom: number,
  customerPreferences: CustomerPreferences,
): number {
  return (
    0.98 *
    rZoomBehavior(r, zoom, customerPreferences) *
    heatMapCutoff(customerPreferences)
  );
}

export function rectDist(
  d: RectbinBin<marketmap.HeatMapData>,
  rectEdge: number,
) {
  const largestX = Math.max(Math.abs(d.x), Math.abs(d.x + rectEdge));
  const largestY = Math.max(Math.abs(d.y), Math.abs(d.y + rectEdge));

  return Math.sqrt(largestX ** 2 + largestY ** 2);
}

export function heatmapRectFilter(
  customerPreferences: CustomerPreferences,
  d: RectbinBin<marketmap.HeatMapData>,
  r: number,
  zoom: number,
  rectEdge: number,
): boolean {
  return (
    rectDist(d, rectEdge) <=
    heatMapCutoff(customerPreferences) *
      rZoomBehavior(r, zoom, customerPreferences)
  );
}

export function heatmapHexFilter(
  customerPreferences: CustomerPreferences,
  d: d3hb.HexbinBin<marketmap.HeatMapData>,
  r: number,
  zoom: number,
) {
  return (
    Math.sqrt(d.x ** 2 + d.y ** 2) <=
    heatMapCutoff(customerPreferences) *
      rZoomBehavior(r, zoom, customerPreferences)
  );
}

export function selectGgvarScale(
  customerPreferences: CustomerPreferences,
  r: number,
  zoom: number,
  ggvar_r_max: number,
): number {
  return rZoomBehavior(r, zoom, customerPreferences) / ggvar_r_max;
}
