import { __DEV__ } from '@apollo/client/utilities/globals';
import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import en from './translations/en';

i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    debug: __DEV__,
    lng: 'en',
    fallbackLng: 'en',
    preload: ['en'],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [
        HttpBackend, // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
        resourcesToBackend({ en }),
      ],
      backendOptions: [
        {
          loadPath: `https://gastrograph-translations.s3.amazonaws.com/console/{{lng}}.json`,
        },
      ],
    },
  });

export default i18n;
