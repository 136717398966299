import * as React from 'react';

import { Done } from '@mui/icons-material';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../styles/theme';

interface Props {
  handleClose: () => void;
  report: reports.AllReportsQueryRow;
  setShowApprovalModal: React.Dispatch<React.SetStateAction<boolean>>;
  setReportId: React.Dispatch<React.SetStateAction<string>>;
  setReportRowId: React.Dispatch<React.SetStateAction<number>>;
}

const ApproveReportMenuItem: React.FC<Props> = ({
  handleClose,
  report,
  setShowApprovalModal,
  setReportId,
  setReportRowId,
}) => {
  const { t } = useTranslation();

  return (
    <MenuItem onClick={handleClose} href="">
      <ListItemButton
        onClick={() => {
          setShowApprovalModal(true);
          setReportId(report.reportId);
          setReportRowId(report.id);
        }}
      >
        <ListItemIcon>
          <Done fontSize="small" htmlColor={COLORS.AQUA_MARINE} />
        </ListItemIcon>
        <ListItemText primary={t('reportsQa.menu.approveReport')} />
      </ListItemButton>
    </MenuItem>
  );
};

export default ApproveReportMenuItem;
