import { mapFromKey } from 'constants/ggVars';
import { PROJECT_SUMMARY_WITH_REPORT } from 'constants/routePaths';

import * as React from 'react';
import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import ReportGgVarTuningPlot from '@graphql/queries/ReportGgVarTuningPlot';
import TextureDecompByReportIdAndProductIDQuery from '@graphql/queries/TextureDecompByReportIdAndProductIDQuery';
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material';
import { IconButton, Grid } from '@mui/material';
import { ProductVersion } from 'components/Report/ProductVersion';
import { getProductName } from 'components/Report/utils';
import useCustomProductNames from 'hooks/useCustomProductNames';
import useMinMaxPq from 'hooks/useMixMaxPq';
import useReportSummary from 'hooks/useReportSummary';
import { useTranslation } from 'react-i18next';
import { RouterProps } from 'react-router';
import formatPath from 'utils/formatPath';

import LoadingScreen from '../../../components/LoadingScreen';
import TextureSummaryChartGroup from '../../../components/Report/Texture/TextureIntensityCharts/TextureSummaryChartGroup';
import { ProjectTabs } from '../../../services/customerPreferences';
import { PageHeader } from '../../../styles/themeComponents';
import styles from '../ProjectSummary.module.css';

// Clean the version param, as it might be the null string
export const cleanVersionParam = (versionParam: string): string => {
  if (!!versionParam && versionParam != 'null') {
    return decodeURI(versionParam);
  } else {
    return null;
  }
};

interface Props extends RouterProps {
  match: Record<string, any>;
  location: Record<string, any>;
}

const ProjectTextureSummaryChart: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { match, history } = props;

  useEffect(() => {
    return () => {
      history.listen(() => {
        window.scrollTo(0, 0);
      });

      if (history.action === 'POP' && reportId) {
        history.replace({
          pathname: formatPath(PROJECT_SUMMARY_WITH_REPORT, {
            projectId,
            reportId,
          }),
          state: {
            productId,
            version,
            tabIdx: ProjectTabs.TEXTURE_SUMMARY,
          },
        });
      }
    };
  }, []);

  const projectId: number = parseInt(match.params.projectId);
  const reportId: string = match.params.reportId;
  const productId: number = isNaN(parseInt(match.params.productId))
    ? null
    : parseInt(match.params.productId);
  const version: string = cleanVersionParam(match.params.version);

  const customProductNames = useCustomProductNames({ projectId });
  const { productVersionPqs } = useReportSummary(reportId);

  const {
    data: minMaxPqData,
    loading: minMaxPqLoading,
    error: minMaxPqError,
  } = useMinMaxPq(reportId);

  const {
    data: ggVarData,
    loading: ggVarLoading,
    error: ggVarError,
  } = useQuery<reports.GgVarTuningPlotResponse>(ReportGgVarTuningPlot, {
    variables: {
      reportID: reportId,
      productID: productId,
      ggVar: 'mouthfeel',
    },
  });

  const {
    data: decompData,
    loading: decompLoading,
    error: decompError,
  } = useQuery<reports.ReportTextureDecompResponse>(
    TextureDecompByReportIdAndProductIDQuery,
    {
      variables: {
        reportID: reportId,
        productID: productId,
        version: version,
      },
    },
  );

  if (ggVarLoading || decompLoading || minMaxPqLoading) {
    return <LoadingScreen />;
  }

  function filterDecompData(
    data: reports.ReportTextureDecompResponse,
    version: string,
  ): reports.ReportTextureDecompRow[] {
    return data.allRpTextureDecomps.nodes.filter(
      (row) => row.version === version,
    );
  }

  function filterGgVarTuningData(
    data: reports.GgVarTuningPlotResponse,
    version: string,
  ): reports.GgVarTuningPlotRow[] {
    return data.allRpGgvarFlavorProfiles.nodes.filter(
      (row) => row.version === version,
    );
  }

  return (
    <div>
      <div className={styles.tabContainer}>
        <Grid container>
          <Grid item xs={12}>
            <div className={styles.header}>
              <IconButton
                onClick={history.goBack}
                size="small"
                style={{ marginLeft: -26 }}
              >
                <KeyboardBackspaceIcon fontSize="small" />
                <PageHeader>
                  {t('projects.textureFlavorSummary.back')}
                </PageHeader>
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <h4>
              {getProductName({
                productNames: customProductNames,
                productId: productId,
                version,
              })}
            </h4>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <TextureSummaryChartGroup
              ggVar={mapFromKey('mouthfeel')}
              pq={
                productVersionPqs.find((i) =>
                  i.productVersion.equals(
                    new ProductVersion(productId, version),
                  ),
                )?.pq
              }
              minPq={minMaxPqData.minPq}
              maxPq={minMaxPqData.maxPq}
              ggVarTuningData={filterGgVarTuningData(ggVarData, version)}
              decompData={filterDecompData(decompData, version)}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProjectTextureSummaryChart;
