import * as React from 'react';
import { Component } from 'react';

import { useTheme } from '@mui/material';

import useStyles from './useStyles';
import Toast from '../../components/Toast';

type Props = {
  onRequestRemoveToast: (toastKey: string) => any;
  toasts: object[];
};

const RenderContainer = ({ children }) => {
  const classes = useStyles(useTheme());

  return <div className={classes.container}>{children}</div>;
};

export default class AppToastContainer extends Component {
  props: Props;

  renderToast = (toast) => {
    const { onRequestRemoveToast } = this.props;

    return (
      <Toast
        {...toast}
        key={toast.toastKey}
        onRequestClose={onRequestRemoveToast}
      />
    );
  };

  render() {
    const { toasts } = this.props;

    return <RenderContainer>{toasts.map(this.renderToast)}</RenderContainer>;
  }
}
