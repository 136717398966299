import * as React from 'react';

import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import useStyles from './useStyles';
import ActivityIndicator from '../../components/ActivityIndicator';

type LinkLocation = string | object;

type Props = {
  loading: boolean;
  nextButtonLinkLocation?: LinkLocation;
  previousButtonLinkLocation?: LinkLocation;
  renderPage?: (resultNodes: object[]) => React.ReactNode;
  resultNodes: object[];
};

const QueryPaginationContainer: React.FC<Props> = (props) => {
  const {
    loading,
    renderPage,
    resultNodes,
    previousButtonLinkLocation,
    nextButtonLinkLocation,
  } = props;

  const classes = useStyles(useTheme());

  const renderPagination = (
    previousButtonLinkLocation,
    nextButtonLinkLocation,
  ) => {
    return (
      <div className={classes.pagination}>
        {previousButtonLinkLocation && (
          <Link to={previousButtonLinkLocation} className={classes.button}>
            Previous
          </Link>
        )}
        {nextButtonLinkLocation && (
          <Link to={nextButtonLinkLocation} className={classes.button}>
            Next
          </Link>
        )}
      </div>
    );
  };

  const getContents = (
    loading: boolean,
    resultNodes: object[],
    renderPage: (resultNodes: object[]) => React.ReactNode,
  ) => {
    if (loading) {
      return <ActivityIndicator />;
    } else if (!resultNodes) {
      return <div className={classes.message}>No results</div>;
    } else {
      return renderPage(resultNodes);
    }
  };

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.pageWrapper}>
        {getContents(loading, resultNodes, renderPage)}
      </div>
      {resultNodes &&
        renderPagination(previousButtonLinkLocation, nextButtonLinkLocation)}
    </div>
  );
};

export default QueryPaginationContainer;
