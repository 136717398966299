import { COUNTRIES } from 'constants/country';

import { NewProduct } from './ProductCreateBulk';
import { truthyString } from '../../utils/afsUtils';

/**
 * Definition of the type for the mutation of bulk adding
 */
export interface MutationProduct {
  name: string;
  localName: string;
  brand: string;
  ingredients: string;
  dietaryRestrictions: any;
  country: string;
  countryOfPurchase: string;
  nutritionalInformation: {
    Sodium?: string;
    Calories?: string;
    'Total fat'?: string;
    Cholesterol?: string;
    'Serving size'?: string;
    'Calories from fat'?: string;
    'Total Carbohydrates'?: string;
  };
  restrictions: string;
  physicalState: string;
  producerId: number;
  categoryId: number;
  prototype: boolean;
  public: boolean;
  aroma: boolean;
}

/**
 * Simplified Validation Result
 */
export interface ValidationResult {
  valid: boolean;
  error: string;
  product: NewProduct;
}

/**
 * Get the matching category name from a product's category string
 * @param product product of interest
 * @param workspaceCategories all workspace categories
 * @returns
 */
export function getCategoryId(
  productCategory: string,
  workspaceCategories: {
    id: number;
    name: string;
  }[],
): number {
  if (productCategory) {
    return (
      workspaceCategories
        .filter((c) => c.name === productCategory)
        .find(Boolean)?.id || null
    );
  } else {
    return null;
  }
}

export function checkValidUnitMeasure(
  product: NewProduct,
  unitFieldKey: string,
  valueFieldKey: string,
): ValidationResult | null {
  return (!!product[unitFieldKey] && !product[valueFieldKey]) ||
    (!product[unitFieldKey] && !!product[valueFieldKey])
    ? {
        valid: false,
        error: `${product.Name} - ${unitFieldKey} or ${valueFieldKey} missing or null`,
        product: product,
      }
    : null;
}

export function checkValidCategory(
  product: NewProduct,
  workspaceCategories: any,
): ValidationResult | null {
  return !workspaceCategories.includes(product.Category)
    ? {
        valid: false,
        error: `${product.Category} is not a valid category`,
        product: product,
      }
    : null;
}

export function checkValidFeature(
  product: NewProduct,
  existingFeatures: any,
): ValidationResult | null {
  return !existingFeatures.includes(product['Product Feature'])
    ? {
        valid: false,
        error: `${product['Product Feature']} is not a valid product feature`,
        product: product,
      }
    : null;
}

export function checkValidComponent(
  product: NewProduct,
  existingComponents: any,
): ValidationResult | null {
  return !existingComponents.includes(product['Component Base'])
    ? {
        valid: false,
        error: `${product['Component Base']} is not a valid product component`,
        product: product,
      }
    : null;
}

export function checkValidCountry(
  product: NewProduct,
): ValidationResult | null {
  return !product['Country']
    ? {
        valid: false,
        error: `${product['Country']} is unknown country`,
        product: product,
      }
    : null;
}

export function checkValidCountryOfPurchase(
  product: NewProduct,
): ValidationResult | null {
  return !product['Country of Purchase']
    ? {
        valid: false,
        error: `${product['Country of Purchase']} is unknown country`,
        product: product,
      }
    : null;
}

export function validateProduct(
  product: any,
  categories: any,
  features: any,
  components: any,
  requiredColumns: any,
  expectedColumns: any,
): ValidationResult {
  const newProduct = {};
  for (const col of requiredColumns) {
    if (!product[col] || product[col].trim().length == 0) {
      return { valid: false, error: `missing ${col}`, product: product };
    }
    newProduct[col] = product[col];
  }
  expectedColumns.forEach((col) => {
    newProduct[col] = product[col];
  });

  const validatedProduct = newProduct as NewProduct;

  // Remap countries to country codes
  newProduct['Country'] = COUNTRIES.filter(
    (p) => p.name === newProduct['Country'],
  ).find(Boolean)?.code;
  newProduct['Country of Purchase'] = COUNTRIES.filter(
    (p) => p.name === newProduct['Country of Purchase'],
  ).find(Boolean)?.code;

  const invalidResults = [
    checkValidUnitMeasure(product, 'Serving Size (On Label)', 'SS#'),
    checkValidUnitMeasure(product, 'Cholesterol', 'CH#'),
    checkValidUnitMeasure(product, 'Calories', 'CA#'),
    checkValidUnitMeasure(product, 'Sodium', 'SOD#'),
    checkValidUnitMeasure(product, 'Calories From Fat', 'CFF#'),
    checkValidUnitMeasure(product, 'Total Carbohydrates', 'TC#'),
    checkValidUnitMeasure(product, 'Total Fat', 'TF#'),
    checkValidCategory(
      validatedProduct,
      categories.productCategories.nodes.map((n) => n.name),
    ),
    checkValidFeature(
      validatedProduct,
      features.productFeatures.nodes.map((n) => n.name),
    ),
    checkValidComponent(
      validatedProduct,
      components.productComponentBases.nodes.map((n) => n.name),
    ),
    checkValidCountry(validatedProduct),
    checkValidCountryOfPurchase(validatedProduct),
  ].filter((check) => !!check);

  if (invalidResults.length > 0) {
    return invalidResults[0];
  } else {
    return {
      valid: true,
      error: null,
      product: validatedProduct,
    };
  }
}

export function getNutritionalInformation(input: NewProduct) {
  let nutritionalInformation = {};

  if (input.Sodium && input['SOD#']) {
    nutritionalInformation = {
      ...nutritionalInformation,
      Sodium: `${input.Sodium} ${input['SOD#']}`,
    };
  }

  if (input.Calories && input['CA#']) {
    nutritionalInformation = {
      ...nutritionalInformation,
      Calories: `${input.Calories} ${input['CA#']}`,
    };
  }

  if (input['Total Fat'] && input['TF#']) {
    nutritionalInformation = {
      ...nutritionalInformation,
      'Total fat': `${input['Total Fat']} ${input['TF#']}`,
    };
  }

  if (input.Cholesterol && input['CH#']) {
    nutritionalInformation = {
      ...nutritionalInformation,
      Cholesterol: `${input.Cholesterol} ${input['CH#']}`,
    };
  }

  if (input['Serving Size (On Label)'] && input['SS#']) {
    nutritionalInformation = {
      ...nutritionalInformation,
      'Serving size': `${input['Serving Size (On Label)']} ${input['SS#']}`,
    };
  }

  if (input['Calories From Fat'] && input['CFF#']) {
    nutritionalInformation = {
      ...nutritionalInformation,
      'Calories from fat': `${input['Calories From Fat']} ${input['CFF#']}`,
    };
  }

  if (input['Total Carbohydrates'] && input['TC#']) {
    nutritionalInformation = {
      ...nutritionalInformation,
      'Total Carbohydrates': `${input['Total Carbohydrates']} ${input['TC#']}`,
    };
  }

  return nutritionalInformation;
}

/**
 * Map the NewProduct into a mutation structured output
 * @param input new product information
 * @param producerId workspace identifier to load the product into
 * @param categoryId category identifier to load the product into
 */
export function mapToMutationProduct(
  input: NewProduct,
  producerId: number,
  categoryId: number,
): MutationProduct {
  return {
    name: input.Name,
    localName: input['Local Name'],
    brand: input.Brand,
    ingredients: input.Ingredients.trim(),
    dietaryRestrictions: input.Allergens,
    country: input.Country,
    countryOfPurchase: input['Country of Purchase'],
    nutritionalInformation: getNutritionalInformation(input),
    restrictions: input['Dietary Restrictions'],
    physicalState: input['Physical State'],
    producerId: producerId,
    categoryId: categoryId,
    prototype: truthyString(input.Prototype),
    public: truthyString(input.Public),
    aroma: truthyString(input.Aroma),
  };
}

/**
 *
 * @param input
 * @returns
 */
export function isMutationProductValid(input: MutationProduct): boolean {
  // Country is a proper code
  if (!COUNTRIES.find((c) => c.code === input.country)) return false;
  // Country of Purchase is a proper code
  if (!COUNTRIES.find((c) => c.code === input.countryOfPurchase)) return false;

  // Check all the nutrition items
  for (const [key, value] of Object.entries(input.nutritionalInformation)) {
    if (!/^[0-9]+\s[\w]+$/.test(value)) return false;
  }

  // Otherwise
  return true;
}
