import { REQUEST_REPORT } from 'constants/routePaths';

import * as React from 'react';

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import CloneReportIcon from 'components/Icons/ReportIcons/CloneReportIcon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  handleClose: () => void;
  report: reports.AllReportsQueryRow;
}

const CloneReportMenuItem: React.FC<Props> = ({ handleClose, report }) => {
  const { t } = useTranslation();

  return (
    <MenuItem
      onClick={handleClose}
      component={Link}
      to={{
        pathname: REQUEST_REPORT,
        state: { templateReport: report },
      }}
    >
      <ListItemButton>
        <ListItemIcon>
          <CloneReportIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('reportsQa.menu.cloneReport')} />
      </ListItemButton>
    </MenuItem>
  );
};

export default CloneReportMenuItem;
