import { TableColumn } from 'react-data-table-component';

import CSVDownload from '../CSVDownload';

const dataTableCsv = <T extends object>(
  filename: string,
  columns: TableColumn<T>[],
  data: T[],
) => (
  <CSVDownload
    headers={columns.map((i) => i.id.toString())}
    csvData={data}
    fileName={filename}
  />
);

export default dataTableCsv;
