import { RpNoveltyIntensityCountsResponse } from '@graphql/queries/RpNoveltyIntensityCounts';
import { RpProductTimeSeriesCountsResponse } from '@graphql/queries/RpProductTimeSeriesCounts';

import { OPTIMIZATION } from '../../constants/report';
import { textureViewerRole } from '../../constants/userRoles';
import { ReportSummaryResponse } from '../../hooks/useReportSummary';
import {
  CustomerPreferences,
  dataDownloadEnabled,
  mapEnabled,
  normsEnabled,
} from '../../services/customerPreferences';
import { ProjectTabs } from '../../services/customerPreferences';
import { isAfsWorkspace, isDemoWorkspace } from '../../utils/afsUtils';
import { hasSuperAdminRole } from '../../utils/roleUtils';

/**
 * Show original products toggle.  This toggle only makes sense if it is a optimization report
 * and the parent report is included in the project
 *
 * @param reportId child report id
 * @param parentReportId parent report id
 * @param data reportSummaryResponse
 * @returns if toggle should show
 */
export const showOriginalProductsToggle = (
  reportId: string,
  parentReportId: string,
  data: ReportSummaryResponse,
) => {
  const reportType = data.allReportJobs.nodes.find(
    (r) => r.reportId === reportId,
  )?.reportType;
  return !!parentReportId && reportType === OPTIMIZATION;
};

/**
 * Show product summary only if Map Only was approved.
 *
 * @param reportId child report id
 * @param data reportSummaryResponse
 * @returns if tabs should show
 */
export const showMapOnly = (reportId: string, data: ReportSummaryResponse) => {
  return data.allReportJobs.nodes.find((r) => r.reportId === reportId)?.mapOnly;
};

/**
 * Show flow summary tabs on project. Only if flavor profiles are in database
 *
 * @param marketMapOnly only show market map
 * @param reportFlavorCnt count of flavor profiles
 * @returns if flavor summary tabs should show
 */
export const showFlavor = (
  marketMapOnly: boolean,
  reportFlavorCnt: reports.ReportFlavorCountsResponse,
): boolean =>
  !marketMapOnly &&
  (reportFlavorCnt?.reportJobByReportId?.rpGgvarFlavorProfilesByReportId
    ?.totalCount ?? 0) > 0;

/**
 * Show texture tabs on project
 *
 * @param userRoles user roles for current user
 * @param marketMapOnly only show market map
 * @param reportTextureCnt number of texture rows in database
 * @returns if texture tab should show
 */
export const showTexture = (
  userRoles: Set<string>,
  marketMapOnly: boolean,
  reportTextureCnt: reports.ReportTextureCountResponse,
): boolean =>
  userRoles &&
  !marketMapOnly &&
  (hasSuperAdminRole(userRoles) ||
    userRoles.has(textureViewerRole.toLowerCase())) &&
  (reportTextureCnt?.reportJobByReportId?.rpTextureClustersByReportId
    ?.totalCount ?? 0) > 0;

/**
 * Show data download tabs on project
 *
 * @param customerPreferences relevant customer preferences object
 * @param userRoles relevant user roles
 * @param marketMapOnly only show market map
 * @returns if data download tab should show
 */
export const showDataDownload = (
  customerPreferences: CustomerPreferences,
  userRoles: Set<string>,
  marketMapOnly: boolean,
): boolean =>
  !!customerPreferences &&
  !!userRoles &&
  !marketMapOnly &&
  (dataDownloadEnabled(customerPreferences) || hasSuperAdminRole(userRoles));

/**
 * Show norms tabs on project
 *
 * @param customerPreferences relevant customer preferences object
 * @param userRoles relevant user roles
 * @param marketMapOnly only show market map
 * @returns if norms tab should show
 */
export const showNorms = (
  customerPreferences: CustomerPreferences,
  userRoles: Set<string>,
  marketMapOnly: boolean,
): boolean =>
  !!customerPreferences &&
  !!userRoles &&
  !marketMapOnly &&
  normsEnabled(customerPreferences) &&
  hasSuperAdminRole(userRoles);

/**
 * Show optimziation summary / order of operations tab on project
 *
 * @param isProd boolean for if current environment is prod
 * @param marketMapOnly only show market map
 * @param selectedReportType current report type
 * @param optSummaryCnt number of order of operations rows in database
 * @returns if optimization summary tab should show
 */
export const showOptimizationSummary = (
  isProd: boolean,
  marketMapOnly: boolean,
  selectedReportType: string,
  optSummaryCnt: reports.ReportOrderOfOperationsCountResponse,
): boolean =>
  !marketMapOnly &&
  selectedReportType === OPTIMIZATION &&
  (optSummaryCnt?.allRpOptSteps?.totalCount ?? 0) > 0;

/**
 * Show map tabs on project
 *
 * @param customerPreferences relevant customer preferences object
 * @param marketMapOnly only show market map
 * @returns if map tab should show
 */
export const showMap = (
  customerPreferences: CustomerPreferences,
  marketMapOnly: boolean,
): boolean =>
  !marketMapOnly && !!customerPreferences && mapEnabled(customerPreferences);

/**
 * Show novelty analysis tab on project
 *
 * @param marketMapOnly only show market map
 * @param workspaceId current workspace for project
 * @param noveltyCnt number of novelty rows in the database
 * @returns if novelty analysis tab should show
 */
export const showNoveltyAnalysis = (
  marketMapOnly: boolean,
  workspaceId: number,
  noveltyCnt: RpNoveltyIntensityCountsResponse,
): boolean =>
  !marketMapOnly &&
  (isAfsWorkspace(workspaceId) || isDemoWorkspace(workspaceId)) &&
  (noveltyCnt?.allRpNoveltyIntensityPqs.totalCount ?? 0) > 0;

/**
 * Show executive summary tab on preojct
 *
 * @param marketMapOnly only show market map
 * @returns if executive summary tab should show
 */
export const showExecutiveSummary = (marketMapOnly: boolean): boolean =>
  !marketMapOnly;

/**
 * Show trend analysis tabs on project
 *
 * @param customerPreferences relevant customer preferences object
 * @param marketMapOnly only show market map
 * @param trendAnalysisCnt number of trend analysis rows in database
 * @returns if trend analysis tab should show
 */
export const showTrendAnalysis = (
  customerPreferences: CustomerPreferences,
  marketMapOnly: boolean,
  trendAnalysisCnt: RpProductTimeSeriesCountsResponse,
): boolean =>
  customerPreferences.features.showTrendAnalysis &&
  !marketMapOnly &&
  (trendAnalysisCnt?.allRpProductTimeseries.totalCount ?? 0) > 0;

/**
 * Show category insights tab on project
 *
 * @param isProd boolean for if current environment is prod
 * @returns if category insights tab should show
 */
export const showCategoryInsights = (isProd: boolean): boolean => !isProd;

export const showTab = (
  tab: ProjectTabs,
  customerPreferences: CustomerPreferences,
  userRoles: Set<string>,
  marketMapOnly: boolean,
  workspaceId: number,
  selectedReportType: string,
  reportFlavorCnt: reports.ReportFlavorCountsResponse,
  reportTextureCnt: reports.ReportTextureCountResponse,
  noveltyCnt: RpNoveltyIntensityCountsResponse,
  trendAnalysisCnt: RpProductTimeSeriesCountsResponse,
  optSummaryCnt: reports.ReportOrderOfOperationsCountResponse,
  isProd: boolean,
): boolean => {
  switch (tab) {
    case ProjectTabs.EXECUTIVE_SUMMARY:
      return showExecutiveSummary(marketMapOnly);
    case ProjectTabs.PROJECT_SUMMARY:
      return true;
    case ProjectTabs.FLAVOR_SUMMARY:
      return showFlavor(marketMapOnly, reportFlavorCnt);
    case ProjectTabs.TEXTURE_SUMMARY:
      return showTexture(userRoles, marketMapOnly, reportTextureCnt);
    case ProjectTabs.OPTIMIZATION_SUMMARY:
      return showOptimizationSummary(
        isProd,
        marketMapOnly,
        selectedReportType,
        optSummaryCnt,
      );
    case ProjectTabs.NORMS:
      return showNorms(customerPreferences, userRoles, marketMapOnly);
    case ProjectTabs.DATA_DOWNLOAD:
      return showDataDownload(customerPreferences, userRoles, marketMapOnly);
    case ProjectTabs.PROJECT_MAP:
      return showMap(customerPreferences, marketMapOnly);
    case ProjectTabs.NOVELTY_ANALYSIS:
      return showNoveltyAnalysis(marketMapOnly, workspaceId, noveltyCnt);
    case ProjectTabs.TREND_ANALYSIS:
      return showTrendAnalysis(
        customerPreferences,
        marketMapOnly,
        trendAnalysisCnt,
      );
    case ProjectTabs.CATEGORY_INSIGHTS:
      return showCategoryInsights(isProd);
  }
};
