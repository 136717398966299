import { gql } from '@apollo/client';

export default gql`
  query ReportProductFlavorDecomps($reportID: UUID!, $productID: Int!, $version: String) {
    allRpFlavorDecomps(condition: {reportId: $reportID, productId: $productID, version: $version}) {
      nodes {
        ggVars
        referenceFlavor
        decomp
        absDecomp
        version
      }
    }
  }
`