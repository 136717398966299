import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import flexContainer from '../../styles/flexContainer';
import { COLORS } from '../../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...flexContainer(theme),
      width: '90%',
      zIndex: 100,
    },
    primary: {
      fontFamily: 'AlphaHeadlinePro-Bold',
      fontSize: 16,
      color: theme.palette.info.main,
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '5px 20px',
    },
    selectWorkspaceText: {
      fontFamily: 'AlphaHeadlinePro-Bold, sans-serif',
      color: COLORS.MARINE,
    },
    searchBar: {
      margin: 'auto',
    },
    workspaceOptions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '2px',
      color: '#78909c',
      marginLeft: '15px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    workspaceCurrentText: {
      color: 'white',
      fontSize: '18px',
      fontFamily: 'AlphaHeadlinePro, sans-serif',
      marginLeft: '10px',
    },
    backdrop: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: `rgba(0,0,0,0.03)`,
    },
    relativeWrapper: {
      position: 'relative',
    },
    titleText: {
      fontFamily: 'AlphaHeadlinePro, sans-serif',
      fontSize: '24px',
      color: 'white',
    },
    toggleMenuIsOpenButton: {
      height: '50px',
    },
    menuWrapper: {
      transition: 'all 0.22s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
      position: 'absolute',
      top: '100%',
      left: '0',
      opacity: 0,
      visibility: 'hidden',
      '& .menu': {
        ...flexContainer(theme),
        backgroundColor: 'white',
        border: '1px solid #dae4ed',
        borderRadius: '4px',
        boxShadow: '0 5px 15px -5px rgba(0, 0, 0, 0.1)',
        padding: '10px 10px 0',
        '& .workspaceOption': {
          marginBottom: '10px',
        },
      },
      '& .open': {
        opacity: 1,
        paddingTop: '4px',
        visibility: 'visible',
      },
    },
  }),
);

export default useStyles;
