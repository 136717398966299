import { gql } from '@apollo/client';

export default gql`
query GetReportRpGgvarFlavorProfileCount($reportID: UUID!) {
  reportJobByReportId(reportId: $reportID) {
    id
    rpGgvarFlavorProfilesByReportId {
      totalCount
    }
  }
}`;
